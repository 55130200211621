import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { IStudent } from '../../../../../../../interfaces/IStudent';
import { IThesisCommitteeMemberData, IThesisProcessData } from '../../../../../../../interfaces/IThesisProcess';
import { HelperService } from '../../../../../shared/helper-service/helper.service';
import { appear } from '../../../../../UI/animations/animations';

@Component({
  selector: 'thesis-request-form',
  templateUrl: './thesis-request-form.component.html',
  animations: [appear]
})
export class ThesisRequestFormComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() student: IStudent;
  @Input() data: IThesisProcessData;
  @Input() sendBtnClass = 'primary';
  @Input() sendBtnText = 'send';
  @Input() allowCompendiumDirectorReport = false;

  @Output() onSend = new EventEmitter<IThesisProcessData>();

  constructor(private hs: HelperService) { }

  ngOnInit() {
    if (!this.data) {
      this.data = {
        thesisTitle: '',
        thesisDoc: null,
        thesisSummaryDoc: null,
        cv: null,
        publications: [],
        cotutelle: false,
        compendium: false,
        internationalMention: false,
        industrialMention: false,
        needsEthicsReport: false,
        thesisCommittee: {
          president: this.getInitMember(),
          secretary: this.getInitMember(),
          member: this.getInitMember(),
          alt1: this.getInitMember(),
          alt2: this.getInitMember(),
          alt3: this.getInitMember()
        },
        externalEvaluators: {
          ev1: this.getInitMember(),
          ev2: this.getInitMember(),
          ev3: this.getInitMember()
        }
      }
    }

  }

  private getInitMember(): IThesisCommitteeMemberData {
    return {
      idNum: '',
      firstName: '',
      lastName: '',
      nationality: null,
      email: '',
      university: '',
      cv: null,
      lang: 'es' // TODO change to both when en is available
    }
  }

  send() {
    if (!this.f.valid) {
      setTimeout(() => {
        this.hs.scrollToInvalidFeedback();
      }, 1000);
      return;
    }

    // check lang is set
    this.checkLang();

    this.onSend.emit(this.data);
  }

  private checkLang() {
    if (!this.data.thesisCommittee || !this.data.externalEvaluators) return;

    const arr = [
      this.data.thesisCommittee.president,
      this.data.thesisCommittee.secretary,
      this.data.thesisCommittee.member,
      this.data.thesisCommittee.alt1,
      this.data.thesisCommittee.alt2,
      this.data.thesisCommittee.alt3,
      this.data.externalEvaluators.ev1,
      this.data.externalEvaluators.ev2,
      this.data.externalEvaluators.ev3,
    ]

    for (const m of arr) {
      if (m && !m.lang) {
        m.lang = 'es';
      }
    }
  }

}
