import { Component, OnInit } from '@angular/core';

import { appear } from '../animations/animations';
import { ConfirmationDialogService } from './confirmation-dialog.service';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  animations: [ appear ]
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(public cds: ConfirmationDialogService) { }

  ngOnInit() { }

  accept() {
    this.cds.okAction();
    this.cds.open = false;
  }

  cancel() {
    this.cds.cancelAction();
    this.cds.open = false;
  }

  dismiss() {
    this.cds.dismissAction();
    this.cds.open = false;
  }
}