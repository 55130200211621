import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { dropdownAnimation } from '../../../UI/animations/animations';
import { DropdownService } from '../../../UI/dropdown-service/dropdown.service';
import { UserService } from '../../../user/user.service';

@Component({
  selector: 'roles-dropdown',
  templateUrl: 'roles-dropdown.component.html',
  animations: [dropdownAnimation]
})
export class RolesDropdownComponent implements OnInit, OnDestroy {
  charLimit = 200;

  resizeSub: Subscription;

  constructor(public us: UserService,
    public dds: DropdownService) { }

  ngOnInit() {
    this.calculateCharLimit();
    this.resizeSub = fromEvent(window, 'resize').pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.calculateCharLimit();
    });
  }

  ngOnDestroy() {
    if (this.resizeSub) this.resizeSub.unsubscribe();
  }

  calculateCharLimit() {
    const maxWidth = window.innerWidth - 360;
    const charWidth = 10;
    this.charLimit = Math.floor(maxWidth / charWidth);
  }
}