import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IStudent } from '../../../../../../../interfaces/IStudent';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogService } from '../../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { UserService } from '../../../../../user/user.service';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import * as moment from 'moment-timezone';
import { DADUtils } from '../../../../../../../utils/DADUtils';

@Component({
  selector: 'disability-card',
  templateUrl: './disability-card.component.html',
})
export class DisabilityCardComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() student: IStudent;

  disabilityId: string;
  justification: IDocumentRef[] = [];
  start: Date;
  end: Date;

  open = false;

  DADUtils = DADUtils;

  constructor(public sh: ServiceHttpService,
    public us: UserService,
    private refresh: RefreshService,
    private alerts: PopAlertsService,
    private confirmation: ConfirmationDialogService,
    private ts: TranslateService) { }

  ngOnInit() {
  }

  openEdit(disabilityId) {
    this.reset();
    const disability = this.student.disabilities.find(d => d._id === disabilityId);
    if (disability) {
      this.disabilityId = disabilityId;
      this.justification = disability.justification;
      this.start = disability.start;
      this.end = disability.end;
      this.open = true;
    }
  }

  reset() {
    this.disabilityId = null;
    this.justification = [];
    this.start = null;
    this.end = null;
    this.open = false;
  }

  validDisabilities() {
    let disabilities = [];
    if (this.disabilityId) {
      disabilities = this.student.disabilities.filter(d => d._id !== this.disabilityId);
    } else {
      disabilities = this.student.disabilities;
    }
    return DADUtils.validDisabilityDates(this.start, this.end, disabilities);
  }

  save() {
    if (!this.f.valid
      || !this.validDateRange()) {
      return;
    }

    if (!this.validDisabilities()) {
      return;
    }

    if (this.disabilityId) {
      this.sh.editDisability({
        studentId: this.student._id,
        disabilityId: this.disabilityId,
        justification: this.justification,
        start: this.start,
        end: this.end
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      });
    } else {
      this.sh.addDisability({
        studentId: this.student._id,
        justification: this.justification,
        start: this.start,
        end: this.end
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      });
    }
  }


  remove(disabilityId: string) {
    this.confirmation.confirm({
      title: this.ts.instant('remove-disability'),
      message: this.ts.instant('are-you-sure-to-remove-disability'),
      btnOkText: this.ts.instant('remove')
    }, () => {
      this.sh.removeDisability({
        studentId: this.student._id,
        disabilityId,
      }).subscribe(() => {
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    })
  }

  validDateRange() {
    if (!this.start) {
      return false;
    }

    if (this.end) {
      return moment(this.end).isSameOrAfter(this.start)
    }

    return true;
  }

}
