<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="tutorized-students"
        icon="users-class">
      </section-title>

    </div>
  </div>

  <div class="row">
    <div class="col">
      <students-search #studentsSearch
        [dataFn]="dataFn()"
        [showFilters]="false"></students-search>
    </div>
  </div>

</div>