import { ChangeDetectorRef, Component, forwardRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgModel,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { CdsReason } from '../../../../interfaces/ICDSProcess';
import { IDocumentRef } from '../../../../interfaces/IDocument';
import { COUNTRIES, IUser } from '../../../../interfaces/IUser';
import { UserHttpService } from '../../data-services/user-http.service';
import { HelperService } from '../../shared/helper-service/helper.service';
import { UserService } from '../../user/user.service';
import { ServiceHttpService } from '../../data-services/service-http.service';

@Component({
  selector: 'user-info-selector',
  templateUrl: 'user-info-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserInfoSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserInfoSelectorComponent),
      multi: true
    }
  ]
})
export class UserInfoSelectorComponent implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('emailInput') emailInput: NgModel;
  @ViewChild('idNumInput') idNumInput: NgModel;
  @ViewChild('firstNameInput') firstNameInput: NgModel;
  @ViewChild('lastNameInput') lastNameInput: NgModel;
  @ViewChild('universityInput') universityInput: NgModel;
  @ViewChild('supervisorRequirementSelect') supervisorRequirementSelect: NgModel;

  @Input() showErrors = false;
  @Input() showIdNum = false;
  @Input() showCdsReason = false;
  @Input() showCountry = false;
  @Input() showCV = false;
  @Input() sm = false;
  @Input() externals = false;

  @Input() required = false;

  userInfo: {
    userId?: string,
    email: string,
    idNum: string,
    firstName: string,
    lastName: string,
    university: string,
    nationality?: string,
    reason?: CdsReason[],
    cv?: IDocumentRef,
    lang?: string,
  } = {
      email: null,
      idNum: null,
      firstName: null,
      lastName: null,
      university: null,
      reason: [],
      lang: 'es'
    };

  notFound = false;
  hideIdNum = false;

  onlyCoSupervisor = false;
  cpAgreement: IDocumentRef;

  countries = COUNTRIES;

  constructor(private us: UserHttpService,
    private ss: ServiceHttpService,
    public usr: UserService,
    public hs: HelperService,
    private cd: ChangeDetectorRef,
    private zone: NgZone) { }

  ngOnInit() {
    if (this.userInfo.email) {
      this.search();
    }
  }

  search() {
    if (!this.userInfo.email || this.userInfo.email.length <= 0) return;
    if (!new RegExp(this.hs.EMAIL_REGEX).test(this.userInfo.email)) return;
    this.us.findByEmail(this.userInfo.email, this.externals).subscribe((user: IUser) => {
      if (!user._id) {
        this.notFound = true;
      } else { // TODO no _id but there is data (no in DAD database but it is in DUMA) (notFound = true but automplete data)
        this.userInfo.userId = user._id;
        if (this.usr.isService() && this.userInfo.userId) {
          this.ss.getUserExtraData(this.userInfo.userId).subscribe((data) => {
            this.onlyCoSupervisor = data.onlyCoSupervisor;
            this.cpAgreement = data.cpAgreement;
          });
        }
        this.userInfo.firstName = user.firstName;
        this.userInfo.lastName = user.lastName;
        this.userInfo.idNum = user.idNum;
        if (!this.userInfo.university && user.university)
          this.userInfo.university = user.university;
        this.userInfo.lang = 'es';
        if (!this.showIdNum) {
          this.hideIdNum = true;
        }
      }

      setTimeout(() => {
        this.zone.run(() => {
          this.onChange(this.userInfo);
        });
      }, 0);
    })
  }

  resetFind() {
    this.notFound = false;
    this.userInfo = {
      email: null,
      idNum: null,
      firstName: null,
      lastName: null,
      university: null
    }
    setTimeout(() => {
      this.zone.run(() => {
        this.onChange(this.userInfo);
      });
    }, 0);
  }

  onChangeReason(event) {
    if (event.length > 0) {
      let lastValue = event[event.length - 1];
      if (lastValue && lastValue === CdsReason.none) {
        this.userInfo.reason = [CdsReason.none];
      } else {
        this.userInfo.reason = event;
      }
    } else {
      this.userInfo.reason = [];
    }
    setTimeout(() => {
      this.zone.run(() => {
        this.onChange(this.userInfo);
      });
    }, 0);
  }

  restEmpty() {
    return this.userInfo.idNum
  }

  isRequired(): boolean {
    return this.required
      || (this.userInfo.email !== null && this.userInfo.email.length > 0)
      || (this.userInfo.idNum !== null && this.userInfo.idNum.length > 0)
      || (this.userInfo.firstName !== null && this.userInfo.firstName.length > 0)
      || (this.userInfo.lastName !== null && this.userInfo.lastName.length > 0)
      || (this.userInfo.university !== null && this.userInfo.university.length > 0);
  }

  onChange = (_: any) => { };
  onTouch = () => { };

  writeValue(value: any): void {
    if (value !== undefined) {
      this.userInfo = value;
      // this.onChange(this.userInfo);
      if (this.userInfo && this.userInfo.email && this.userInfo.email.length > 0) {
        this.search();
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors {
    let errors: any = {};
    if (!this.userInfo || !this.userInfo.userId) {
      if (this.emailInput && this.emailInput.invalid) {
        errors.email = this.emailInput.errors;
      }
      if (this.idNumInput && this.idNumInput.invalid) {
        errors.idNum = this.idNumInput.errors;
      }
      if (this.firstNameInput && this.firstNameInput.invalid) {
        errors.firstName = this.firstNameInput.errors;
      }
      if (this.lastNameInput && this.lastNameInput.invalid) {
        errors.lastName = this.lastNameInput.errors;
      }
      if (this.universityInput && this.universityInput.invalid) {
        errors.university = this.universityInput.errors;
      }
    }

    if (this.showCdsReason && this.supervisorRequirementSelect && (!this.userInfo || !this.userInfo.reason || this.userInfo.reason.length <= 0)) {
      errors.supervisorRequirementSelect = { required: true };
    }

    if (this.showCountry && (!this.userInfo || !this.userInfo.cv || !this.userInfo.cv.documentRef)) {
      errors.cv = { required: true };
    }

    if (Object.keys(errors).length > 0) {
      return errors;
    }

    else return null;
  }

}