import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IMessage } from '../../../interfaces/IMessage';

@Injectable()
export class MessageHttpService {

  constructor(private http: HttpClient) { }

  fetchMessages(params: { page?: number, size?: number }): Observable<IMessage[]> {
    return this.http.get<IMessage[]>(`/api/me/messages`, { params });;
  }

  count(): Observable<number> {
    return this.http.get<{ res: number }>(`/api/me/messagesCount`).pipe(
      map(r => r.res)
    );
  }

  unreadCount(): Observable<number> {
    return this.http.get<{ res: number }>(`/api/me/unreadMessagesCount`).pipe(
      map(r => r.res)
    );
  }

  markAsRead(messageId: string): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`/api/me/messages/${messageId}/markAsRead`, {});;
  }

  markAsUnread(messageId: string): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(`/api/me/messages/${messageId}/markAsRead`, {});;
  }

  markAllAsRead(): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>('/api/me/messages/markAllAsRead', {});
  }

}