import { Component, Input, OnInit } from '@angular/core';
import { IThesisProcessData } from '../../../../../../../interfaces/IThesisProcess';
import { IStudent } from '../../../../../../../interfaces/IStudent';
import * as _ from 'lodash';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';

@Component({
  selector: 'thesis-edit-modal',
  templateUrl: 'thesis-edit-modal.component.html'
})
export class ThesisEditModalComponent implements OnInit {

  @Input() open = false;

  @Input() student: IStudent;

  @Input() data: IThesisProcessData;
  clonedData: IThesisProcessData;

  constructor(private ss: ServiceHttpService,
    private alerts: PopAlertsService,
    private rs: RefreshService) { }

  ngOnInit() {
  }

  doOpen() {
    this.clonedData = _.cloneDeep(this.data);
    this.open = true;
  }

  save() {
    this.ss.editThesisProccessing(this.student._id, this.clonedData).subscribe(() => {
      this.rs.onStudentChange.next();
      this.open = false;
      this.alerts.saveSuccess();
    });
  }

}