import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RefreshService {

  onTasksChange = new Subject<any>();
  onMessagesChange = new Subject<any>();
  onStudentChange = new Subject<any>();
  onEvaluationProcessChange = new Subject<string>();

}