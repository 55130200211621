import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

import { EvaluationType, IEvaluation, IStudent } from '../../../../../../../interfaces/IStudent';
import { StudentHttpService } from '../../../../../data-services/student-http.service';
import { UserService } from '../../../../../user/user.service';
import { EvaluationHttpService } from '../../../../../data-services/evaluation-http.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { StudentFileService } from '../../../../routes/student-file/student-file.service';

@Component({
  selector: 'evaluations-list',
  templateUrl: './evaluations-list.component.html',
})
export class EvaluationsListComponent implements OnInit, OnChanges {

  @Input() student: IStudent;
  @Input() evaluations: IEvaluation[];

  @Input() editable = false;

  @Output() onRemove = new EventEmitter<IEvaluation>();
  @Output() onEdit = new EventEmitter<string>();

  details: IEvaluation;
  open = false;

  showRegenerate = false;

  EvaluationType = EvaluationType

  constructor(private ss: StudentHttpService, public us: UserService, private evs: EvaluationHttpService, private sfs: StudentFileService) { }

  ngOnInit() {
  }

  manageRowClick(evaluation) {
    if (!this.editable) {
      this.details = evaluation;
      this.open = true;
      this.showRegenerate = true;
      this.fetchCommittee(evaluation._id)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.evaluations) {
      this.evaluations = _.orderBy(this.evaluations, ['year', 'period'], ['desc', 'desc']);
      if (this.details) {
        this.details = this.evaluations.find(e => e._id === this.details._id);
        this.fetchCommittee(this.details._id);
      }
    }
  }

  fetchCommittee(evaluationId) {
    this.ss.fetchEvaluationCommittee(this.student._id, evaluationId).subscribe((result) => {
      this.details.president = result.president;
      this.details.secretary = result.secretary;
      this.details.member = result.member;
    })
  }

  regenerate(evaluation: IEvaluation) {
    this.evs.regenerate(this.student._id, evaluation._id).subscribe((docRef) => {
      this.sfs.fetch(this.student._id);
      this.showRegenerate = false;
    });
  }

}
