<ng-container *ngIf="email">
  <ng-container *ngIf="us.isService(); else simpleLink">
    <a [routerLink]="null"
      (click)="sems.open(user)">
      {{ email }}
    </a>
  </ng-container>
  <ng-template #simpleLink>
    <a href="mailto:{{email}}">{{ email }}</a>
  </ng-template>
</ng-container>