import { Component, Input, OnInit } from '@angular/core';

import { IProgram } from '../../../../../interfaces/IProgram';
import { ProgramHttpService } from '../../../data-services/program-http.service';
import { PopAlertsService } from '../../../UI/pop-alerts/pop-alerts.service';
import { DADUtils } from '../../../../../utils/DADUtils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'evaluation-periods',
  templateUrl: './evaluation-periods.component.html',
})
export class EvaluationPeriodsComponent implements OnInit {
  @Input() program: IProgram;

  end1month: number;
  end1date: number;
  end2month: number;
  end2date: number;

  edit1 = false;
  edit2 = false;

  dates1: number[] = [];
  dates2: number[] = [];

  isInEvaluationPeriod1 = false;
  isInEvaluationPeriod2 = false;

  constructor(private ps: ProgramHttpService, private alerts: PopAlertsService, private ts: TranslateService) { }

  ngOnInit() {
    this.init1();
    this.init2();

    this.setDates();

    this.isInEvaluationPeriod1 = DADUtils.isInEvaluationPeriod1(this.program, new Date());
    this.isInEvaluationPeriod2 = DADUtils.isInEvaluationPeriod2(this.program, new Date());
  }

  save1() {
    if (this.isInEvaluationPeriod1) {
      // check if the date is before the current evaluationPeriod1End
      const currentYear = new Date().getFullYear();
      const end1month = +this.program.evaluationPeriod1End.substring(0, 2);
      const end1date = +this.program.evaluationPeriod1End.substring(2);
      const currentEvaluationPeriod1End = new Date(`${currentYear}-${end1month}-${end1date}`);
      const newEvaluationPeriod1End = new Date(`${currentYear}-${this.end1month}-${this.end1date}`);
      if (newEvaluationPeriod1End < currentEvaluationPeriod1End) {
        this.alerts.error(this.ts.instant('evaluation-period-date-must-be-after'));
        return;
      }
    }

    let evaluationPeriod1End = `0${this.end1month}`;
    if (this.end1date < 10) {
      evaluationPeriod1End += `0${this.end1date}`;
    } else {
      evaluationPeriod1End += `${this.end1date}`;
    }
    this.ps.saveEvaluationPeriod1End(this.program._id, evaluationPeriod1End).subscribe(() => {
      this.alerts.saveSuccess();
      this.edit1 = false;
    })
  }

  save2() {
    if (this.isInEvaluationPeriod2) {
      // check if the date is before the current evaluationPeriod2End
      const currentYear = new Date().getFullYear();
      const end2month = +this.program.evaluationPeriod2End.substring(0, 2);
      const end2date = +this.program.evaluationPeriod2End.substring(2);
      const currentEvaluationPeriod2End = new Date(`${currentYear}-${end2month}-${end2date}`);
      const newEvaluationPeriod2End = new Date(`${currentYear}-${this.end2month}-${this.end2date}`);
      if (newEvaluationPeriod2End < currentEvaluationPeriod2End) {
        this.alerts.error(this.ts.instant('evaluation-period-date-must-be-after'));
        return;
      }
    }

    let evaluationPeriod2End = '';
    if (this.end2month < 10) {
      evaluationPeriod2End = `0${this.end2month}`
    } else {
      evaluationPeriod2End = `${this.end2month}`
    }
    if (this.end2date < 10) {
      evaluationPeriod2End += `0${this.end2date}`;
    } else {
      evaluationPeriod2End += `${this.end2date}`;
    }
    this.ps.saveEvaluationPeriod2End(this.program._id, evaluationPeriod2End).subscribe(() => {
      this.alerts.saveSuccess();
      this.edit2 = false;
    })
  }

  init1() {
    this.end1month = +this.program.evaluationPeriod1End.substring(0, 2);
    this.end1date = +this.program.evaluationPeriod1End.substring(2);
  }

  init2() {
    this.end2month = +this.program.evaluationPeriod2End.substring(0, 2);
    this.end2date = +this.program.evaluationPeriod2End.substring(2);
  }

  setDates() {
    this.dates1 = [];
    let max = 31;
    if (this.end1month === 4) {
      max = 30;
      if (this.end1date === 31) this.end1date = 30;
    }
    for (let i = 1; i <= max; i++) {
      this.dates1.push(i);
    }

    this.dates2 = [];
    max = 31;
    if (this.end2month === 9) {
      max = 30;
      if (this.end2date === 31) this.end2date = 30;
    }
    for (let i = 1; i <= max; i++) {
      this.dates2.push(i);
    }
  }


}
