import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CDSPState, ICDSProcess } from "../../../interfaces/ICDSProcess";
import { DedicationPState, IDedicationProcess } from "../../../interfaces/IDedicationProcess";
import { DirectionPState, IDirectionProcess } from "../../../interfaces/IDirectionProcess";
import { DischargePState, IDischargeProcess } from "../../../interfaces/IDischargeProcess";
import { EvaluationPState, IEvaluationProcess } from "../../../interfaces/IEvaluationProcess";
import { ExtensionPState, IExtensionProcess } from "../../../interfaces/IExtensionProcess";
import { ILeaveProcess, LeavePState } from "../../../interfaces/ILeaveProcess";
import { IPermanentLeaveProcess, PermanentLeavePState } from "../../../interfaces/IPermanentLeaveProcess";
import { IThesisProcess, ThesisPState } from "../../../interfaces/IThesisProcess";
import { IValidationProcess, VPState } from "../../../interfaces/IValidationProcess";
import { HttpClient } from "@angular/common/http";
import { Processes } from "../../../interfaces/ITask";
import { IProfessorProcess, PPState } from "../../../interfaces/IProfessorProcess";

@Injectable()
export class ProcessesHttpService {

  constructor(private http: HttpClient) { }

  fetchProcesses(params: {
    processType: Processes,
    programsIds?: string[],
    states?: CDSPState[] | VPState[] | EvaluationPState[] | ThesisPState[] | DedicationPState[] | DirectionPState[] | ExtensionPState[] | LeavePState[] | DischargePState[] | PermanentLeavePState[] | PPState[],
    searchText?: string,
    page?: number,
    size?: number
  }): Observable<ICDSProcess[] | IValidationProcess[] | IEvaluationProcess[] | IThesisProcess[] | IDedicationProcess[] | IDirectionProcess[] | IExtensionProcess[] | ILeaveProcess[] | IDischargeProcess[] | IPermanentLeaveProcess[] | IProfessorProcess[]> {
    return this.http.post<ICDSProcess[] | IValidationProcess[] | IEvaluationProcess[] | IThesisProcess[] | IDedicationProcess[] | IDirectionProcess[] | IExtensionProcess[] | ILeaveProcess[] | IDischargeProcess[] | IPermanentLeaveProcess[] | IProfessorProcess[]>
      (`/api/processes/search`, { ...params });
  }

}