<menu-item [link]="['/app/student/home']"
  [linkActiveOptions]="{exact: true}"
  icon="home"
  name="situation"></menu-item>

<menu-item [link]="['/app/student/cds']"
  icon="passport"
  name="cds"
  [dot]="sr.activeProcesses.cds > 0 || sr.activeProcesses.dedication > 0 || sr.activeProcesses.direction > 0"></menu-item>

<menu-item [link]="['/app/student/activities']"
  icon="list"
  name="activities"
  [dot]="sr.activeProcesses.validation > 0"></menu-item>

<menu-item [link]="['/app/student/processes']"
  icon="project-diagram"
  name="processes"
  [dot]="sr.activeProcesses.extension > 0 || sr.activeProcesses.leave > 0"></menu-item>

<menu-item [link]="['/app/student/evaluation']"
  icon="file-search"
  name="evaluation"
  [dot]="sr.activeProcesses.evaluation > 0"></menu-item>

<menu-item [link]="['/app/student/thesis']"
  icon="diploma"
  name="thesis"
  [dot]="sr.activeProcesses.thesis > 0"></menu-item>

<!-- <menu-item [link]="['/app/student/certificates']"
  icon="file-certificate"
  name="certificates"></menu-item> -->