<div *ngIf="show && control && control.invalid && control.errors">
  <!-- {{ control.errors | json }} -->
  <span class="invalid-feedback d-inline">
    <span *ngIf="control.errors.required">
      El campo es obligatorio
    </span>
    <span *ngIf="control.errors.requiredIfNot">
      {{ control.errors.requiredIfNotMsg }}
    </span>
    <span *ngIf="control.errors.di">
      El documento de identidad no es válido
    </span>
    <span *ngIf="control.errors.nifnie">
      El NIF/NIE no es válido
    </span>
    <span *ngIf="control.errors.cif">
      El CIF no es válido
    </span>
    <span *ngIf="control.errors.nif">
      El NIF no es válido
    </span>
    <span *ngIf="control.errors.minlength">
      El tamaño mínimo es {{ control.errors.minlength.requiredLength }}
    </span>
    <span *ngIf="control.errors.uploading">
      El documento se está subiendo al servidor
    </span>
    <span *ngIf="control.errors.email">
      El email no es válido
    </span>
    <span *ngIf="control.errors.pattern">
      Formato no válido
    </span>
    <span *ngIf="control.errors.complexPass">
      La contraseña debe contener minúsculas, mayúsculas, números y símbolos
    </span>
    <span *ngIf="control.errors.min">
      Debe ser un número mayor o igual que {{ control.errors.min.min }}
    </span>
  </span>
</div>