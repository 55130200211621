<div *ngIf="student"
  class="row">
  <div class="col">

    <form>
      <div class="form-group">
        <search-input name="search"
          [ngModel]="params.searchText"
          (ngModelChange)="params.searchText = $event; infiniteList.search()"
          [placeholder]="'search-activity-by-title'"></search-input>
      </div>
    </form>

    <infinite-list #infiniteList
      *ngIf="student"
      [data]="fetchFn()"
      [(array)]="activities">

      <activities-list #activitiesList
        [activities]="activities"
        (onDownloadFile)="ds.download($event)">
      </activities-list>
    </infinite-list>

  </div>
</div>