<ng-container *ngIf="checked">
  <div class="container"
    style="min-height: calc(100vh - 272px)">

    <div class="row">

      <div class="col mb-1">

        <div class="pt-4 pb-4">
          <a href="https://www.uma.es"
            target="_blank">
            <img src="assets/UMA_logo.png"
              alt="Universidad de Málaga"
              height="77">
          </a>

          <div class="d-none d-md-inline-block float-right">
            <a href="https://www.uma.es/doctorado"
              target="_blank">
              <img height="80"
                src="assets/ED-UMA.png" />
            </a>
          </div>
        </div>

      </div>

    </div>


    <div class="row justify-content-center">

      <div class="jumbotron col-lg-6 col-md-8 mt-3"
        style="padding-bottom: 3.7rem;">
        <h2 class="font-weight-normal">Documento de Actividades del Doctorando</h2>
        <p>
          Pulse acceder para gestionar el DAD.
        </p>

        <hr class="my-4" />

        <div class="text-center">
          <button role="button"
            class="btn btn-lg btn-primary"
            (click)="login()">
            {{ 'login' | translate }}
          </button>
        </div>
        <div class="text-right w-100 mt-2">
          <small>
            <a href="https://duma.uma.es/acceso/"
              class="text-secondary">Tengo problemas para acceder</a>
          </small>
        </div>
      </div>


    </div>


    <div class="row mb-5 mt-2">

      <div class="col-md-6 order-2 oder-md-1">
        <div class="card">
          <h5 class="card-header">Recursos</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <a
                href="https://www.uma.es/secretaria-general-uma/navegador_de_ficheros/boletin/descargar/2023/octubre2/20231031_72219.pdf">
                <i class="fas fa-file-pdf fa-lg fa-fw text-danger"></i>
                Reglamento de doctorado
              </a>
            </li>
            <li class="list-group-item">
              <a
                href="https://www.uma.es/secretaria-general-uma/navegador_de_ficheros/boletin/descargar/2023/octubre2/20231031_72221.pdf">
                <i class="fas fa-file-pdf fa-lg fa-fw text-danger"></i>
                Código de Buenas Prácticas de la Escuela de Doctorado
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-6 order-1 order-md-2">
        <div class="card mb-2 mb-md-0">
          <h5 class="card-header">Anuncios</h5>
          <div class="card-body">
            <div class="alert alert-info"
              *ngIf="appServ.env === 'production'">
              Nueva versión de la aplicación DAD. Compruebe que sus datos sean correctos. Si tiene algún problema, puede
              contactar con el Servicio de Doctorado a través de su <a href="https://gp.uma.es/doctorado/">gestor de
                peticiones</a>.
              <br><br>
              Muchas gracias.
            </div>
            <div class="alert alert-info"
              *ngIf="appServ.env === 'certification'">
              <i class="fas fa-info-circle"></i>
              Ha accedido a una versión de prueba de la aplicación. Tenga en cuenta que:

              <ul>
                <li>
                  Esta es una versión de pruebas internas, por lo que solo es posible acceder desde la red interna de la
                  UMA.
                </li>
                <li>
                  Los datos que se muestran fueron copiados el día 06/05/2024, por lo que acciones posteriores no se
                  verán reflejadas en esta versión.
                </li>
                <li>
                  Los cambios que realice en esta versión no tendrán efecto en la
                  versión real.
                </li>
                <li>
                  De forma periódica los datos se restaurarán, por lo que la información o los documentos que adjunte se
                  perderán y
                  no podrán ser recuperados posteriormente.
                </li>
                <li>
                  No se enviará ninguna comunicación por correo electrónico.
                </li>
              </ul>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>

  <public-footer></public-footer>
</ng-container>