<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card">
      <div class="card-body">
        <ng-container *ngIf="!requested">

          <div class="w-100">
            <a [routerLink]="['../../']"
              class="mt-1 mb-3 d-block">
              <i class="far fa-arrow-left mr-1"></i>
              {{ 'back' | translate }}
            </a>
          </div>

          <h5 class="card-title">
            {{ 'process.industrial.request.name' | translate }}
          </h5>

          <p [innerHTML]="'process.industrial.request.desc' | translate">
          </p>

          <form #f="ngForm"
            id="industrialMentionForm"
            (ngSubmit)="send(data)">

            <ng-container *ngIf="data.industrialMention === true">

              <div class="form-group">
                <label for="industrialMentionAgreementDoc">
                  {{ 'industrial-mention-agreement' | translate }}
                </label>
                <validation-errors [show]="f.submitted"
                  [control]="documentsInput"></validation-errors>
                <files-queue name="documents"
                  [required]="true"
                  #documentsInput="ngModel"
                  [(ngModel)]="data.agreementDocs"></files-queue>
              </div>

              <div class="form-group">
                <label for="industrialMentionAgreementDate">
                  {{ 'industrial-mention-agreement-date' | translate }}
                </label>
                <date-picker id="industrialMentionAgreementDate"
                  name="industrialMentionAgreementDate"
                  [placeholder]="'industrial-mention-agreement-date' | translate"
                  [required]="true"
                  [isInvalid]="industrialMentionAgreementDateInput.invalid && f.submitted"
                  #industrialMentionAgreementDateInput="ngModel"
                  [time]="false"
                  [(ngModel)]="data.agreementDate"> </date-picker>
                <validation-errors [control]="industrialMentionAgreementDateInput"
                  [show]="f.submitted"></validation-errors>
              </div>

              <div class="form-group">
                <label for="">
                  {{ 'organization' | translate }}
                </label>
                <input id="organization"
                  name="organization"
                  type="text"
                  class="form-control"
                  [required]="false"
                  [placeholder]="'organization' | translate"
                  #organizationInput="ngModel"
                  required
                  [(ngModel)]="data.organization">
                <validation-errors [control]="organizationInput"
                  [show]="f.submitted"></validation-errors>
              </div>

              <div class="form-group">
                <label>
                  {{ 'organization-contact' | translate}}
                </label>
                <input id="organizationContact"
                  name="organizationContact"
                  type="text"
                  class="form-control"
                  [required]="false"
                  [placeholder]="'organization-contact' | translate"
                  #organizationContactInput="ngModel"
                  required
                  [(ngModel)]="data.organizationContact">
                <validation-errors [control]="organizationContactInput"
                  [show]="f.submitted"></validation-errors>
              </div>
            </ng-container>

            <div class="form-group text-right">
              <div class="d-block invalid-feedback mb-2"
                *ngIf="f.invalid && f.submitted">
                {{ 'check-errors' | translate }}
              </div>

              <button class="btn btn-primary"
                type="submit"
                [disableButton]="disabled || sending"
                [disableButtonIcon]="sending ? 'spin' : 'ban'">
                {{ 'send' | translate}}
              </button>

            </div>
          </form>

        </ng-container>
        <div class="w-100 text-center"
          *ngIf="requested">
          <h5 class="card-title m-0">
            <i class="far fa-check text-success mr-1"></i>
            {{ 'process-launched' | translate }}
          </h5>
          <a [routerLink]="['../../']"
            class="mt-4 d-block">
            <i class="far fa-arrow-left mr-1"></i>
            {{ 'back' | translate }}
          </a>
        </div>
      </div>
    </div>

  </div>
</div>