<div class="container">

  <div class="row">
    <div class="col">
      <section-title text="main-roles"
        icon="user">
      </section-title>
    </div>
  </div>

  <div class="card">
    <div class="card-body"
      *ngIf="ready">
      <form #f="ngForm"
        (ngSubmit)="save()">
        <div class="form-group">
          <label for="pgcsUserSelector">
            Secretario/a de la Comisión de Posgrado
          </label>
          <user-selector #pgcsUserSelector="ngModel"
            [(ngModel)]="postgraduateCommitteeSecretary"
            [required]="true"
            [name]="'pgcsUserSelector'"></user-selector>
          <validation-errors [show]="f.submitted"
            [control]="pgcsUserSelector"></validation-errors>
        </div>

        <div class="form-group">
          <label for="pgcpUserSelector">
            Presidente/a de la Comisión de Posgrado
          </label>
          <user-selector #pgcpUserSelector="ngModel"
            [(ngModel)]="postgraduateCommitteePresident"
            [required]="true"
            [name]="'pgcpUserSelector'"></user-selector>
          <validation-errors [show]="f.submitted"
            [control]="pgcpUserSelector"></validation-errors>
        </div>

        <div class="form-group">
          <label for="pgcpUserSelector">
            Director/a de la Escuela de Posgrado
          </label>
          <user-selector #dpsUserSelector="ngModel"
            [(ngModel)]="doctoralSchoolPrincipal"
            [required]="true"
            [name]="'dpsUserSelector'"></user-selector>
          <validation-errors [show]="f.submitted"
            [control]="dpsUserSelector"></validation-errors>
        </div>

        <div class="form-group">
          <label>
            Servicio de Doctorado
          </label>
          <div class="list-group">
            <div class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let usr of serviceUsers">
              <div>
                <user-file-link [user]="usr"></user-file-link>
              </div>
              <div>
                <a [routerLink]="null"
                  role="button"
                  class="text-muted"
                  *ngIf="us.user._id.toString() !== usr._id.toString()"
                  [tooltip]="'remove' | translate"
                  (click)="removeService(usr)">
                  <i class="far fa-times"></i>
                </a>
              </div>
            </div>
            <div class="list-group-item"
              *ngIf="serviceUsers.length <= 0">
              <i>No hay miembros del Servicio de Doctorado</i>
            </div>
            <div class="list-group-item">
              <user-selector #addUserSelector
                [sm]="true"
                [(ngModel)]="addUser"
                [name]="'addUser'"></user-selector>
              <div class="text-right">
                <button [routerLink]="null"
                  role="button"
                  class="btn btn-sm"
                  [class.btn-outline-primary]="!addUser"
                  [class.btn-primary]="addUser"
                  (click)="addService()"
                  [disabled]="!addUser">
                  <i class="far fa-plus mr-1"></i>
                  {{ 'add' | translate }}
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="form-group">
          <label>
            Departamento de Calidad
          </label>
          <div class="list-group">
            <div class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let usr of qaUsers">
              <div>
                <user-file-link [user]="usr"></user-file-link>
              </div>
              <div>
                <a [routerLink]="null"
                  role="button"
                  class="text-muted"
                  *ngIf="us.user._id.toString() !== usr._id.toString()"
                  [tooltip]="'remove' | translate"
                  (click)="removeQA(usr)">
                  <i class="far fa-times"></i>
                </a>
              </div>
            </div>
            <div class="list-group-item"
              *ngIf="qaUsers.length <= 0">
              <i>No hay miembros del Departamento de Calidad</i>
            </div>
            <div class="list-group-item">
              <user-selector #addQAUserSelector
                [sm]="true"
                [(ngModel)]="addQAUser"
                [name]="'addQAUser'"></user-selector>
              <div class="text-right">
                <button [routerLink]="null"
                  role="button"
                  class="btn btn-sm"
                  [class.btn-outline-primary]="!addQAUser"
                  [class.btn-primary]="addQAUser"
                  (click)="addQA()"
                  [disabled]="!addQAUser">
                  <i class="far fa-plus mr-1"></i>
                  {{ 'add' | translate }}
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="form-group text-right">
          <button type="submit"
            class="btn btn-primary">
            {{ 'save' | translate }}
          </button>
        </div>

      </form>
    </div>
  </div>




</div>