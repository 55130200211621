<div class="card"
  *ngIf="student.evaluations.length > 0 || isEditable()">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <span>
        {{ 'annual-evaluations' | translate }}
      </span>
      <button type="button"
        class="btn btn-link"
        *ngIf="isEditable()"
        (click)="reset(); open = true">
        {{ 'add' | translate }}
      </button>
    </legend>
  </div>

  <evaluations-list [student]="student"
    [evaluations]="student.evaluations"
    [editable]="isEditable()"
    (onRemove)="remove($event)"
    (onEdit)="openEdit($event)"></evaluations-list>
</div>

<modal [(open)]="open"
  [title]="(evaluationId ? 'edit-evaluation' : 'add-evaluation') | translate">
  <form #f="ngForm"
    id="evaluationForm"
    (ngSubmit)="save()"
    *ngIf="open">

    <div class="form-group">
      <label for="yearSelect">
        {{ 'year' | translate }}
      </label>
      <ng-select id="yearSelect"
        name="yearSelect"
        #yearSelect="ngModel"
        [(ngModel)]="year"
        [disabled]="evaluationId"
        required
        [class.is-invalid]="f.submitted && yearSelect.invalid"
        [placeholder]="'select-year' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option *ngFor="let y of years"
          [value]="fullYearString(y)">{{ y }}</ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="yearSelect"></validation-errors>
    </div>

    <div class="form-group">
      <label for="periodSelect">
        {{ 'period' | translate }}
      </label>
      <ng-select id="periodSelect"
        name="periodSelect"
        #periodSelect="ngModel"
        [(ngModel)]="period"
        [disabled]="evaluationId"
        required
        [class.is-invalid]="f.submitted && periodSelect.invalid"
        [placeholder]="'select-period' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="EvaluationPeriodType.march">{{ EvaluationPeriodType.march | translate }}</ng-option>
        <ng-option [value]="EvaluationPeriodType.september">{{ EvaluationPeriodType.september | translate }}</ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="periodSelect"></validation-errors>
    </div>

    <span class="invalid-feedback d-inline mb-2"
      *ngIf="alreadyExists()">
      {{ 'evaluation-already-exists' | translate }}
    </span>



    <div class="form-group">
      <label for="date">
        {{ 'registered-date' | translate }}
      </label>

      <date-picker id="date"
        name="date"
        [placeholder]="'registered-date' | translate"
        required="true"
        [isInvalid]="dateInput.invalid && f.submitted"
        #dateInput="ngModel"
        [time]="false"
        [(ngModel)]="registeredAt"></date-picker>
      <validation-errors [control]="dateInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group mt-4">
      <label for="evaluationSelect">
        {{ 'evaluation' | translate }}
      </label>
      <ng-select id="evaluationSelect"
        name="evaluationSelect"
        #evaluationSelect="ngModel"
        [(ngModel)]="evaluation"
        required
        [class.is-invalid]="f.submitted && evaluationSelect.invalid"
        [placeholder]="'select-evaluation' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="EvaluationType.positive">{{ EvaluationType.positive | translate }}</ng-option>
        <ng-option [value]="EvaluationType.negative">{{ EvaluationType.negative | translate }}</ng-option>
        <ng-option [value]="EvaluationType.noShow">{{ EvaluationType.noShow | translate }}</ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="evaluationSelect"></validation-errors>
    </div>

    <ng-container *ngIf="evaluation !== EvaluationType.noShow">

      <div class="form-group">
        <label>
          {{ 'evaluation-record-file' | translate }}
        </label>
        <file-input name="recordFile"
          [required]="true"
          [invalid]="f.submitted && recordFileInput.invalid"
          #recordFileInput="ngModel"
          [(ngModel)]="recordFile"></file-input>
        <validation-errors [show]="f.submitted"
          [control]="recordFileInput"></validation-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'evaluation-report' | translate }}
          <small class="text-muted ml-2">
            {{ 'optional' | translate }}
          </small>
        </label>
        <file-input name="report"
          [required]="false"
          [invalid]="f.submitted && reportInput.invalid"
          #reportInput="ngModel"
          [(ngModel)]="committeeReport"></file-input>
        <validation-errors [show]="f.submitted"
          [control]="reportInput"></validation-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'direction-team-reports' | translate }}
          <small class="text-muted ml-2">
            {{ 'optional' | translate }}
          </small>
        </label>
        <validation-errors [show]="f.submitted"
          [control]="reportsInput"></validation-errors>
        <files-queue name="reports"
          [required]="false"
          #reportsInput="ngModel"
          [(ngModel)]="reports"></files-queue>
      </div>

      <div class="form-group">
        <label>
          <span *ngIf="student.rd === RDType.rd99Mod">
            {{ 'research-and-formation-plan' | translate }}
          </span>
          <span *ngIf="student.rd === RDType.rd99">
            {{ 'research-plan' | translate }}
          </span>
          <small class="text-muted ml-2">
            {{ 'optional' | translate }}
          </small>
        </label>
        <file-input name="researchPlan"
          [required]="false"
          [invalid]="f.submitted && researchPlanInput.invalid"
          #researchPlanInput="ngModel"
          [(ngModel)]="researchPlan"></file-input>
        <validation-errors [show]="f.submitted"
          [control]="researchPlanInput"></validation-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'president' | translate }}
        </label>
        <user-selector [required]="true"
          [(ngModel)]="president"
          [name]="'evaluationEditPresident'"
          #presidentSelector="ngModel"></user-selector>
        <validation-errors [show]="f.submitted"
          [control]="presidentSelector"></validation-errors>
      </div>
      <div class="form-group">
        <label>
          {{ 'secretary' | translate }}
        </label>
        <user-selector [required]="true"
          [(ngModel)]="secretary"
          [name]="'evaluationEditSecretary'"
          #secretarySelector="ngModel"></user-selector>
        <validation-errors [show]="f.submitted"
          [control]="secretarySelector"></validation-errors>
      </div>
      <div class="form-group">
        <label>
          {{ 'member' | translate }}
        </label>
        <user-selector [required]="true"
          [(ngModel)]="member"
          [name]="'evaluationEditMember'"
          #memberSelector="ngModel"></user-selector>
        <validation-errors [show]="f.submitted"
          [control]="memberSelector"></validation-errors>
      </div>

    </ng-container>
  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="evaluationForm">{{ 'save' | translate }}</button>
  </div>

</modal>