<div class="card mt-3">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <div>
        {{ 'transfer-of-records' | translate }}
      </div>
      <button class="btn btn-sm btn-outline-dark ml-2"
        type="button"
        *ngIf="us.isService() && ss.editMode"
        (click)="doOpen()">
        {{ 'edit' | translate }}
      </button>
    </legend>

    <ng-container *ngIf="student.transfer">
      <div class="my-4"
        *ngIf="student.transferUniversity">
        <div>
          {{ student.transferUniversity }}
        </div>
      </div>
      <div class="mb-4"
        *ngIf="student.transferFirstEnrollment">
        <div class="small text-muted mb-1">
          {{ 'first-enrollment-date' | translate }}
        </div>
        <div>
          {{ student.transferFirstEnrollment | moment:'DD/MM/YYYY' }}
        </div>
      </div>
      <div class="mb-4"
        *ngIf="student.transferDocs && student.transferDocs.length > 0">
        <div class="small text-muted mb-1">
          {{ 'documents' | translate }}
        </div>
        <div *ngFor="let doc of student.transferDocs"
          class="mb-1">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<modal [(open)]="open"
  [title]="'transfer-of-records' | translate">
  <form #f="ngForm"
    id="transferForm"
    (ngSubmit)="save()"
    *ngIf="open">
    <div class="form-group form-check">
      <input type="checkbox"
        class="form-check-input"
        name="transfer"
        id="transfer"
        [(ngModel)]="data.transfer">
      <label class="form-check-label"
        for="transfer">
        {{ 'transfer-of-records' | translate }}
      </label>
    </div>
    <ng-container *ngIf="data.transfer">
      <div class="form-group">
        <label for="university">
          {{ 'university' | translate }}
        </label>
        <input type="text"
          class="form-control"
          [(ngModel)]="data.transferUniversity"
          name="university"
          id="university"
          #universityInput="ngModel"
          [class.is-invalid]="universityInput.invalid && f.submitted"
          [placeholder]="'transfer-university' | translate"
          [required]="true">
        <validation-errors [control]="universityInput"
          [show]="f.submitted"></validation-errors>
      </div>
      <div class="form-group">
        <label>{{ 'first-enrollment-date' | translate }}</label>
        <date-picker [(ngModel)]="data.transferFirstEnrollment"
          name="date"
          #dateInput="ngModel"
          [isInvalid]="f.submitted && dateInput.invalid"
          [placeholder]="'transfer-first-enrollment-date' | translate"
          [required]="true"></date-picker>
        <validation-errors [control]="dateInput"
          [show]="f.submitted"></validation-errors>
      </div>
      <div class="form-group">
        <label>{{ 'documents' | translate }}</label>
        <files-queue [(ngModel)]="data.transferDocs"
          name="justification"
          #justificationInput="ngModel"
          [required]="false"></files-queue>
        <validation-errors [control]="justificationInput"
          [show]="f.submitted"></validation-errors>
      </div>
    </ng-container>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="transferForm">{{ 'save' | translate }}</button>
  </div>

</modal>