import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[disableButton]'
})
export class DisableButtonDirective implements OnChanges {
  @Input('disableButtonIcon') icon: 'ban' | 'spin' = 'ban';
  @Input('disableButton') disableButton;

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  private update() {
    if (this.disableButton) {
      this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
      const span = this.renderer.createElement('span');
      const icon = this.renderer.createElement('i');
      this.renderer.addClass(icon, 'far');
      if (this.icon === 'ban') {
        this.renderer.addClass(icon, 'fa-ban');
      } else if (this.icon === 'spin') {
        this.renderer.addClass(icon, 'fa-circle-notch');
        this.renderer.addClass(icon, 'fa-spin');
      }
      this.renderer.appendChild(span, icon);
      this.renderer.insertBefore(this.el.nativeElement, span, this.el.nativeElement.firstChild);
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
      const span = this.el.nativeElement.firstChild;
      if (span && span.tagName === 'SPAN' && span.firstChild && (span.firstChild.classList.contains('fa-ban') || span.firstChild.classList.contains('fa-spin'))) {
        this.renderer.removeChild(this.el.nativeElement, span);
      }
    }
  }
}