<li class="nav-item dropdown mr-2 messages-dropdown-btn"
  #messagesDropdown>
  <a class="nav-link"
    role="button"
    (click)="open()">
    <span class="fa-layers fa-fw">
      <span class="fa-layers fa-fw">
        <i class="far fa-bell fa-fw"></i>
        <span class="fa-layers-counter"
          *ngIf="unread > 0"
          style="background: #e50000">
          {{ unread }}
        </span>
      </span>
    </span>
  </a>
  <div class="dropdown-menu dropdown-menu-right p-0 messages-dropdown"
    [@dropdownAnimation]="dds.show('messagesDropdown') ? 'in' : 'out'"
    [class.show]="dds.show('messagesDropdown')">
    <h6 class="dropdown-header"
      *ngIf="messages.length <= 0">{{ 'no-messages' | translate }}</h6>
    <div class="dropdown-header d-flex justify-content-between align-items-center"
      *ngIf="messages.length > 0">
      <h6 class="m-0">
        {{ 'messages' | translate }}
      </h6>
      <a role="button"
        class="small"
        (click)="markAllAsRead()">
        {{ 'mark-all-as-read' | translate }}
      </a>
    </div>
    <div style="max-height: 600px; overflow-y: auto !important;">
      <messages-list [messages]="messages"
        (onMarkAsRead)="markAsRead($event)"
        (onMarkAsUnread)="markAsUnread($event)"
        (onGoToTask)="close()"></messages-list>

      <a class="dropdown-item text-center"
        [routerLink]="null"
        [class.disabled]="loadingMore"
        role="button"
        *ngIf="count > messages.length"
        (click)="loadMore()">
        {{ 'load-more' | translate }}
      </a>
    </div>
  </div>
</li>