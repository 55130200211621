import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IActivity } from '../../../../../../../interfaces/IActivity';
import { ITask } from '../../../../../../../interfaces/ITask';
import { ActivityValidationHttpService } from '../../../../../data-services/activity-validation-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'validation-review-task',
  templateUrl: 'validation-review-task.component.html'
})
export class ValidationReviewTaskComponent extends AbstractTaskComponent implements OnInit, OnChanges {

  @Input() task: ITask;

  activity: IActivity;

  constructor(private avs: ActivityValidationHttpService,
    private router: Router) {
    super();
  }

  ngOnInit() { }

  ngOnChanges() {
    if (!this.task) return;
    this.avs.getActivity(this.task._id).subscribe((activity) => {
      this.activity = activity;
    });
  }

  manageSend() {
    this.avs.resendValidation(this.task._id).subscribe(() => {
      void this.router.navigate(['/app/task-completed']);
      this.sendEnd.next();
    })
  }
}