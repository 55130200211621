import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { IActivity } from '../../../../../../../interfaces/IActivity';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { IStudent, RDType } from '../../../../../../../interfaces/IStudent';

@Component({
  selector: 'request-evaluation-form',
  templateUrl: './request-evaluation-form.component.html',
})
export class RequestEvaluationFormComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() disabled = false;
  @Input() sending = false;
  @Input() student: IStudent;

  @Input() researchPlan: IDocumentRef;
  @Input() activitiesIds: string[] = [];

  @Input() activities: IActivity[];

  @Output() onSend = new EventEmitter<{ researchPlan: IDocumentRef, activitiesIds: string[] }>();

  step = 1;

  clickedNext = false;

  RDType = RDType;

  constructor() { }

  ngOnInit() {
  }


  clickNext() {
    this.clickedNext = true;
    if (!this.researchPlan || !this.researchPlan.documentRef) {
      return;
    }

    this.step++;
  }

  send() {
    if (!this.f.valid) return;
    if (this.activitiesIds.length <= 0) return;

    this.onSend.emit({
      researchPlan: this.researchPlan,
      activitiesIds: this.activitiesIds
    })
  }

  toggle(activityId) {
    if (this.activitiesIds.indexOf(activityId) > -1) {
      this.activitiesIds = this.activitiesIds.filter(aId => aId !== activityId);
    } else {
      this.activitiesIds.push(activityId);
    }
  }

  toggleSelectAll() {
    if (this.activitiesIds.length === this.activities.length) {
      this.activitiesIds = [];
    } else {
      this.activitiesIds = this.activities.map(a => a._id);
    }
  }

}
