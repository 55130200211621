import { Component, OnInit, ViewChild } from '@angular/core';
import { IUser } from '../../../../../../interfaces/IUser';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { LoadingIndicatorService } from '../../../../UI/loading-indicator/loading-indicator.service';
import { UserSelectorComponent } from '../../../../UI/user-selector/user-selector.component';
import { NgForm } from '@angular/forms';
import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'main-roles',
  templateUrl: './main-roles.component.html',
})
export class MainRolesComponent implements OnInit {
  @ViewChild('addUserSelector') addUserSelector: UserSelectorComponent;
  @ViewChild('addQAUserSelector') addQAUserSelector: UserSelectorComponent;
  @ViewChild('f') f: NgForm;

  postgraduateCommitteeSecretary: IUser;
  postgraduateCommitteePresident: IUser;
  doctoralSchoolPrincipal: IUser;
  serviceUsers: IUser[] = [];
  qaUsers: IUser[] = [];

  addUser: IUser;
  addQAUser: IUser;

  ready = false;

  constructor(private ss: ServiceHttpService,
    private alerts: PopAlertsService,
    private loading: LoadingIndicatorService,
    public us: UserService) { }

  ngOnInit() {
    this.loading.start();
    this.ss.getRoles().subscribe(res => {
      this.postgraduateCommitteePresident = res.postgraduateCommitteePresident;
      this.postgraduateCommitteeSecretary = res.postgraduateCommitteeSecretary;
      this.doctoralSchoolPrincipal = res.doctoralSchoolPrincipal;
      this.serviceUsers = res.serviceUsers;
      this.qaUsers = res.qaUsers;

      this.ready = true;
      this.loading.stop();
    })
  }

  save() {
    if (!this.f.valid) return;

    this.ss.setRoles({
      postgraduateCommitteePresidentId: this.postgraduateCommitteePresident._id,
      postgraduateCommitteeSecretaryId: this.postgraduateCommitteeSecretary._id,
      doctoralSchoolPrincipalId: this.doctoralSchoolPrincipal._id,
      serviceUsers: this.serviceUsers.map(u => u._id),
      qaUsers: this.qaUsers.map(u => u._id),
    }).subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  removeService(usr: IUser) {
    this.serviceUsers = this.serviceUsers.filter(u => u._id.toString() !== usr._id.toString())
  }

  addService() {
    if (!this.addUser) return;
    this.serviceUsers.push(this.addUser);
    this.addUser = null;
    this.addUserSelector.resetFind();
    this.addUserSelector.email = '';
  }

  removeQA(usr: IUser) {
    this.qaUsers = this.qaUsers.filter(u => u._id.toString() !== usr._id.toString())
  }

  addQA() {
    if (!this.addQAUser) return;
    this.qaUsers.push(this.addQAUser);
    this.addQAUser = null;
    this.addQAUserSelector.resetFind();
    this.addQAUserSelector.email = '';
  }

}
