import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { UserService } from './user.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(private us: UserService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Check loggedIn and fetch user
    return this.us.isLoggedIn().pipe(
      mergeMap((loggedIn) => {
        return iif(() => !loggedIn,
          of(false).pipe(
            tap(() => {
              localStorage.setItem('route', state.url);
              this.us.login();
            })
          ),
          this.us.fetchUser().pipe(
            map((user) => {
              this.us.setUser(user);
              return true;
            })
          )
        )
      })
    )
  }
}