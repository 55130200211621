import 'moment/locale/es.js';

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  constructor(private ts: TranslateService) { }

  transform(value: Date | moment.Moment, ...args: any[]): any {
    if (!value) {
      return ''
    }
    moment.locale(this.ts.currentLang);
    let [format] = args;
    return moment(value).format(format);
  }
}