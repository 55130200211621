import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDirectionProcess, IDirectionRequestData } from '../../../../../../../interfaces/IDirectionProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { DirectionHttpService } from '../../../../../data-services/direction-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'direction-service-direction-team-user-creation',
  templateUrl: './direction-service-direction-team-user-creation.component.html',
})
export class DirectionServiceDirectionTeamUserCreationComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IDirectionProcess;

  data: IDirectionRequestData = <any>{};

  directionIds = {
    tutor: null,
    supervisor1: null,
    supervisor2: null,
    supervisor3: null,
  }

  isNew = {
    supervisor1: false,
    supervisor2: false,
    supervisor3: false
  }

  constructor(private direction: DirectionHttpService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.direction.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
      console.log(process);

      if (process.tutor) {
        this.data.tutor = process.tutor;
        this.directionIds.tutor = this.data.tutor.userId;
      }
      if (process.supervisor1) {
        this.data.supervisor1 = process.supervisor1;
        this.directionIds.supervisor1 = this.data.supervisor1.userId;
      }
      if (process.supervisor2) {
        this.data.supervisor2 = process.supervisor2;
        this.directionIds.supervisor2 = this.data.supervisor2.userId;
      }
      if (process.supervisor3) {
        this.data.supervisor3 = process.supervisor3;
        this.directionIds.supervisor3 = this.data.supervisor3.userId;
      }
    })
  }

  manageSend() {
    if (this.f.invalid) {
      this.sendEnd.next();
      return;
    }

    this.direction.serviceDirectionTeamIds(this.task._id, { directionIds: this.directionIds, isNew: this.isNew }).subscribe(() => {
      this.sendEnd.next();
      void this.router.navigate(['/app/task-completed']);
    })
  }

}
