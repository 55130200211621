import { IDocumentRef } from './IDocument';
import { IProcess } from './IProcess';
import { IStudent } from './IStudent';

export interface IDischargeProcess extends IProcess {
  state: DischargePState,
  studentId: string,

  leaveId: string,
  end: Date,
  justification: IDocumentRef[],

  // Populate
  student?: IStudent;
}

export enum DischargePState {
  PendingAcademicCommitteeValidation = "PendingAcademicCommitteeValidation",
  PendingServiceValidation = "PendingServiceValidation"
}

export enum TKDischarge {
  academicCommitteeValidation = "academicCommitteeValidation",
  serviceValidation = "serviceValidation"
}