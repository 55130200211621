import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ITask } from '../../../../../../../interfaces/ITask';
import { IThesisProcess } from '../../../../../../../interfaces/IThesisProcess';
import { ThesisHttpService } from '../../../../../data-services/thesis-http.service';
import { HelperService } from '../../../../../shared/helper-service/helper.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'thesis-service-validation',
  templateUrl: './thesis-service-validation.component.html'
})
export class ThesisServiceValidation extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IThesisProcess;
  validation: {
    validate: boolean,
    denialMsg: string,
    validationMsg?: string
  } = {
      validate: null,
      denialMsg: null,
      validationMsg: null
    }

  reviewData = false;

  constructor(private thesis: ThesisHttpService,
    public hs: HelperService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.thesis.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  reviewStep(event) {
    this.process.data = event;
    this.reviewData = true;
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    if (!this.validation.validate) {
      this.thesis.serviceDeny(this.task._id, { denialMsg: this.validation.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {

      if (this.process.data.cotutelle && this.process.data.cotutelleDefenceOut) {
        this.process.data.thesisCommittee = null;
        this.process.data.externalEvaluators = null;
      }

      this.thesis.serviceValidate(this.task._id, this.process.data, this.validation.validationMsg).subscribe((res) => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
