<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>
  <br>

  <denial-msgs-view [denialMsgs]="task.thesisProcess.denialMsgs"></denial-msgs-view>

  <form #f="ngForm"
    (ngSubmit)="send()">

    <div class="form-group">
      <label>
        {{ 'thesis-final-doc' | translate }}
      </label>
      <file-input name="thesisDoc"
        required
        #thesisDocInput="ngModel"
        [invalid]="thesisDocInput.invalid && f.submitted"
        [(ngModel)]="thesisFinal"></file-input>
      <validation-errors [show]="f.submitted"
        [control]="thesisDocInput"></validation-errors>
      <!-- <div class="small text-muted">
        {{ 'thesis-final-info' | translate }}
      </div> -->
    </div>

    <div class="form-group">
      <label>
        {{ 'changes-report' | translate }}
      </label>
      <file-input name="changesReport"
        required
        #changesInput="ngModel"
        [invalid]="changesInput.invalid && f.submitted"
        [(ngModel)]="changesReport"></file-input>
      <validation-errors [show]="f.submitted"
        [control]="changesInput"></validation-errors>
    </div>

    <div class="form-group text-right mt-2">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate}}
      </button>
    </div>
  </form>

</ng-container>