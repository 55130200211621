import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ITask, Processes } from '../../../interfaces/ITask';
import { TKThesis } from '../../../interfaces/IThesisProcess';
import { ExternalTaskHttpService } from '../data-services/external-task-http.service';
import { DocumentService } from '../shared/document-service/document.service';

@Component({
  selector: 'external-task',
  templateUrl: 'external-task.component.html'
})
export class ExternalTaskComponent implements OnInit {

  completed = false;

  Processes = Processes;
  TKThesis = TKThesis;

  allowed = [TKThesis.postgraduateCommitteeInfo,
  TKThesis.externalEvaluatorAcceptance,
  TKThesis.externalEvaluatorReport,
  TKThesis.thesisCommitteeAcceptance,
  TKThesis.thesisCommitteeAltAcceptance,
  TKThesis.defenceDate,
  TKThesis.defenceInfo,
  TKThesis.cumLaudeVote,
  TKThesis.secretaryEvaluation];

  task: ITask;

  notFound = false;
  loading = false;

  token: string;

  constructor(private route: ActivatedRoute,
    private ts: ExternalTaskHttpService,
    private ds: DocumentService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const taskId = params.taskId;
      this.token = params.token;
      if (!taskId || !this.token) {
        this.notFound = true;
        return;
      }

      this.loading = true;

      this.ts.findExternal(taskId, this.token).subscribe((task: ITask) => {
        if (!task || !this.allowed.includes(<any>task.taskKey)) {
          this.notFound = true;
        } else {
          this.task = task;
          this.ds.externalTask = {
            _id: this.task._id,
            token: this.token
          }
          this.ds.studentId = this.task.thesisProcess.studentId;
        }

        this.loading = false;
      }, (err) => {
        this.notFound = true;
        this.loading = false;
      });
    });
  }
}