<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-final-doc' | translate }}
    </div>
    <div>
      <document-link [document]="process.thesisFinal"></document-link>
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'changes-report' | translate }}
    </div>
    <div>
      <document-link [document]="process.changesReport"></document-link>
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-publications' | translate }}
    </div>
    <div *ngFor="let doc of process.data.publications"
      class="mb-1">
      <document-link [document]="doc"></document-link>
    </div>
  </div>

  <ng-container *ngIf="process.reports && process.reports.length > 0">
    <h5 class="card-title">{{ 'thesis-evaluation-reports' | translate }}</h5>

    <div class="mb-3"
      *ngFor="let report of process.reports">
      <div>
        {{ report.memberName }}
      </div>
      <div class="d-flex">
        <div class="mr-2">
          <span class="badge"
            [class.badge-success]="report.evaluation === ThesisReportEvaluation.accept"
            [class.badge-danger]="report.evaluation === ThesisReportEvaluation.reject"
            [class.badge-warning]="report.evaluation === ThesisReportEvaluation.acceptMajorChanges || report.evaluation === ThesisReportEvaluation.acceptMinorChanges">
            {{report.evaluation | translate}}
          </span>
        </div>
        <div>
          <document-link [document]="report.docRef"></document-link>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="w-100 mb-4"></div>

  <thesis-committee-view [thesisCommittee]="task.thesisProcess.data.thesisCommittee"
    [showCV]="false"></thesis-committee-view>

  <h5 class="card-title mt-4">{{ 'activities' | translate }}</h5>
  <div class="w-100"
    class="border rounded">
    <div class="list-group">
      <infinite-list #infiniteList
        [data]="fetchFn()"
        [(array)]="activities">

        <div class="list-group-item"
          *ngFor="let activity of activities">
          <activity-view #acView
            [activity]="activity"
            (onTypeClick)="acView.full = !acView.full"
            [full]="false"
            [clickableType]="true"></activity-view>
        </div>
      </infinite-list>
    </div>
  </div>

</ng-container>