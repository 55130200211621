<ng-container *ngIf="sr.ready">
  <div class="container"
    *ngIf="showStatusMessage">
    <div class="row">
      <div class="col">
        <div [class]="'alert alert-' + alertType"
          [smoothHeight]="alertOpen"
          [@listAnimation]="alertOpen">
          <div class="d-flex justify-content-between align-items-center c-pointer"
            (click)="alertOpen = !alertOpen">
            <div *ngIf="sr.student.state !== StudentStateType.initial || sr.cdsRequested === false">
              <strong>{{ sr.student.state + '_INFO' | translate }}</strong>
            </div>
            <div *ngIf="sr.student.state === StudentStateType.initial && sr.cdsRequested === true">
              <strong>
                {{ 'cds-in-process' | translate }}
              </strong>
            </div>
            <div class="text-right">
              <span>
                <span *ngIf="alertOpen">
                  <i class="far fa-caret-up"></i>
                </span>
                <span *ngIf="!alertOpen">
                  <i class="far fa-caret-down"></i>
                </span>
              </span>
            </div>
          </div>

          <div *ngIf="alertOpen && noPermissions.length > 0"
            class="mt-2">
            {{ (liItems > 1 ? 'following-functions-are-limited' : 'following-function-is-limited') | translate }}:
            <ul class="mt-1">
              <li *ngIf="noPermissions.includes(PermissionsType.manageActivities)">
                <span [innerHTML]="'manage-activities' | translate"></span>
              </li>
              <li *ngIf="noPermissions.includes(PermissionsType.completeTasks)">
                <span [innerHTML]="'complete-pending-tasks' | translate"></span>
                <span *ngIf="sr.student.state === StudentStateType.initial">
                  <span [innerHTML]="'except-cds-process-tasks' | translate"></span>
                </span>
              </li>
              <li *ngIf="noPermissions.includes(PermissionsType.requestCDS) 
              || noPermissions.includes(PermissionsType.requestDedication)
              || noPermissions.includes(PermissionsType.requestDirection)
              || noPermissions.includes(PermissionsType.requestEvaluation)
              || noPermissions.includes(PermissionsType.requestExtension)
              || noPermissions.includes(PermissionsType.requestMedicalLeave)
              || noPermissions.includes(PermissionsType.requestTemporalLeave)
              || noPermissions.includes(PermissionsType.requestThesis)">
                <span [innerHTML]="'request-processes' | translate"></span>
                <span *ngIf="sr.student.state === StudentStateType.initial">
                  <span [innerHTML]="'except-cds-process' | translate"></span>
                </span>
                <span
                  *ngIf="sr.student.state === StudentStateType.temporalLeave || sr.student.state === StudentStateType.medicalLeave">
                  <span [innerHTML]="'except-evaluation-process' | translate"></span>
                </span>
              </li>
            </ul>

            <div *ngIf="sr.student.state === StudentStateType.initial && sr.cdsRequested === false">
              <div [innerHTML]="'request-cds-as-soon-as-possible' | translate"></div>
              <button class="btn btn-sm btn-outline-secondary mt-2 mb-3"
                type="button"
                [routerLink]="['/app/student/cds/request']"
                (click)="alertOpen = false">
                {{ 'request-cds' | translate }}
              </button>
            </div>
            <div *ngIf="sr.student.state === StudentStateType.initial && sr.cdsRequested === true">
              <button class="btn btn-link"
                type="button"
                [routerLink]="['/app/student/cds']"
                (click)="alertOpen = false">
                {{ 'cds-in-process' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</ng-container>