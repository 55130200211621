<div *ngIf="student"
  class="row">
  <div class="col-12">
    <div [class]="'alert mb-4 text-center ' + deadlineClass()"
      *ngIf="![StudentStateType.processingRequested, StudentStateType.processingPendingRectification, StudentStateType.processing, StudentStateType.processingAccepted, StudentStateType.defenceRequested, StudentStateType.defenceAccepted, StudentStateType.defended].includes(student.state)">
      <div>{{ 'thesis-deadline' | translate }}</div>
      <h4>
        {{ student.deadline | moment:'DD / MM / YYYY'}}
      </h4>
    </div>
  </div>

  <ng-container *ngIf="student.rd === RDType.rd1393">
    <div class="col-12">
      <personal-data [student]="student"></personal-data>
    </div>
  </ng-container>

  <ng-container *ngIf="student.rd !== RDType.rd1393">
    <div class="col-12 col-lg-6">

      <personal-data [student]="student"></personal-data>

      <div class="mb-3"></div>

      <state-info [student]="student"></state-info>

      <div class="mb-3"></div>

      <ng-container
        *ngIf="(student.disabilities.length > 0 && (us.isService() || (us.isStudent() && us.user._id === student.userId))) || (us.isService() && ss.editMode)">
        <disability-card [student]="student"></disability-card>

        <div class="mb-3"></div>
      </ng-container>

      <scholarship-card [student]="student"></scholarship-card>

      <div class="mb-3"></div>

      <extra-extensions-list [student]="student"
        [extensions]="student.extraExtensions"></extra-extensions-list>

      <div class="mb-3"></div>

      <extensions-card [student]="student"></extensions-card>

      <div class="mb-3"></div>

      <leaves-card [student]="student"></leaves-card>

      <div class="mb-3"></div>

      <cotutelle-card [student]="student"></cotutelle-card>

      <div class="mb-3"></div>

      <industrial-mention-card [student]="student"></industrial-mention-card>
    </div>

    <div class="col-12 col-lg-6">
      <permissions-info [student]="student"></permissions-info>

      <academic-info [student]="student"></academic-info>

      <div class="mb-3"></div>

      <div class="card mt-3">
        <div class="card-body">
          <legend class="m-0 d-flex justify-content-between align-items-center"
            style="border: 0">
            <span>
              {{ 'enrollments' | translate }}
              <button class="btn btn-sm btn-outline-secondary ml-2"
                type="button"
                *ngIf="us.isService() && ss.editMode"
                [disabled]="checkEnrollmentsLoading"
                (click)="checkEnrollments()">
                <span *ngIf="!checkEnrollmentsLoading">
                  {{ 'check-from-minerva' | translate }}
                </span>
                <span *ngIf="checkEnrollmentsLoading">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
              </button>
            </span>
            <span class="small"
              *ngIf="student.recordNum">
              {{ student.recordNum }}
            </span>
          </legend>
        </div>
        <enrollments-list [enrollments]="student.enrollments"></enrollments-list>
      </div>

      <div class="mb-3"></div>

      <ng-container *ngIf="student.transfer || (ss.editMode && us.isService())">
        <transfer-card [student]="student"></transfer-card>
        <div class="mb-3"></div>
      </ng-container>

      <evaluations-card [student]="student"></evaluations-card>
    </div>
  </ng-container>
</div>