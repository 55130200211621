import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractTaskComponent } from '../../root/components/process/abstract-task.component';
import { ITask } from '../../../../interfaces/ITask';
import { Router } from '@angular/router';
import { ExternalTaskHttpService } from '../../data-services/external-task-http.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'thesis-cum-laude-vote',
  templateUrl: './thesis-cum-laude-vote.component.html',
})
export class ThesisCumLaudeVoteComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;
  @Input() token: string;

  @Output() onComplete = new EventEmitter<any>();

  cumLaude: boolean = null;

  confirmation = false;

  constructor(private et: ExternalTaskHttpService, private router: Router) {
    super();
  }

  ngOnInit() {
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }
    this.et.thesisCumLaudeVote(this.task._id, this.token, { cumLaude: this.cumLaude }).subscribe(() => {
      this.sendEnd.next();
      this.onComplete.emit();
    });

  }

}
