import { Component, Input, OnInit } from '@angular/core';

import { HelperService } from '../../shared/helper-service/helper.service';

@Component({
  selector: 'request-disabled-reasons',
  templateUrl: './request-disabled-reasons.component.html',
})
export class RequestDisabledReasonsComponent implements OnInit {

  @Input() requestEnabled: { enabled: boolean, reasons: string[] };

  constructor(public hs: HelperService) { }

  ngOnInit() {
  }

}
