import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import { appear } from '../animations/animations';

@Component({
  selector: 'modal',
  templateUrl: 'modal.component.html',
  animations: [appear]
})
export class ModalComponent implements OnInit, OnChanges {
  @Input('title') title: string;

  @Input() open = false;
  @Output() openChange = new EventEmitter<boolean>();
  @Output() onClose = new EventEmitter<any>();

  @Input() closable = true;

  @Input() size = 'md';
  @Input() centered = true;

  @Input() showFooter = true;

  constructor(private renderer: Renderer2) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    const open: SimpleChange = changes.open;
    if (open && open.previousValue !== open.currentValue) {
      if (open.currentValue) {
        this.renderer.addClass(document.body, 'modal-open');
      } else {
        this.renderer.removeClass(document.body, 'modal-open');
      }
    }
  }

  close() {
    this.open = false;
    this.openChange.emit(this.open);
    this.onClose.emit();
  }
}