import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { IDocumentRef } from '../../../../interfaces/IDocument';
import { DocumentService } from '../../shared/document-service/document.service';

@Component({
  selector: 'document-link',
  templateUrl: 'document-link.component.html',
  styles: [
    `
    .doc-link {
      max-width: 100%;
      width: fit-content;
    }
    `
  ]
})
export class DocumentLinkComponent implements OnInit {
  @ViewChild('link') link: ElementRef;
  @Input() document: IDocumentRef;
  @Input() styleClass: string;

  cut = false;

  constructor(public ds: DocumentService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.link) return;

      this.cut = this.link.nativeElement.offsetWidth < this.link.nativeElement.scrollWidth
    }, 0)
  }

}