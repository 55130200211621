import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'public-footer',
  templateUrl: './public-footer.component.html',
  styles: [
    `
    .footer-link:hover {
      color: #212529;
    }

    .footer-link {
      color: #6c757d;
    }

    .footer {
      width: 100%;
      border-top: 1px solid #eee;
      padding-top: 35px;
    }
    `
  ]
})
export class PublicFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
