import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ITask } from '../../../../interfaces/ITask';
import { NgForm } from '@angular/forms';
import { ThesisFinalEvaluation } from '../../../../interfaces/IThesisProcess';
import { AbstractTaskComponent } from '../../root/components/process/abstract-task.component';
import { ExternalTaskHttpService } from '../../data-services/external-task-http.service';
import { languages_list } from '../../../../utils/Languages';

@Component({
  selector: 'thesis-secretary-evaluation',
  templateUrl: './thesis-secretary-evaluation.component.html',
})
export class ThesisSecretaryEvaluationComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;
  @Input() token: string;

  @Output() onComplete = new EventEmitter<any>();

  evaluation: ThesisFinalEvaluation;
  defenceLanguage: string;

  ThesisFinalEvaluation = ThesisFinalEvaluation;

  languages = languages_list;

  constructor(private et: ExternalTaskHttpService) {
    super();
  }

  ngOnInit() {
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }
    this.et.secretaryEvaluation(this.task._id, this.token, { evaluation: this.evaluation, defenceLanguage: this.defenceLanguage }).subscribe(() => {
      this.sendEnd.next();
      this.onComplete.emit();
    });

  }

}
