<modal [(open)]="open"
  [title]="'video-conference-defence' | translate">
  <form #f="ngForm"
    id="videoconferenceForm"
    (ngSubmit)="save()"
    *ngIf="open">
    <div class="form-group">
      <div class="mb-2 form-check">
        <input type="checkbox"
          class="form-check-input"
          name="videoConferenceCheck"
          id="videoConferenceCheck"
          [(ngModel)]="videoConference">
        <label class="form-check-label"
          for="videoConferenceCheck">
          {{ 'video-conference-defence' | translate }}
        </label>
      </div>
    </div>
    <div class="form-group"
      *ngIf="videoConference">
      <label>{{ 'justification' | translate }}</label>
      <files-queue [(ngModel)]="videoConferenceDocs"
        name="justification"
        #justificationInput="ngModel"
        [required]="true"></files-queue>
      <validation-errors [control]="justificationInput"
        [show]="f.submitted"></validation-errors>
    </div>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="videoconferenceForm">{{ 'save' | translate }}</button>
  </div>

</modal>