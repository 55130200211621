<div class="card"
  *ngIf="student.disabilities.length > 0 || (us.isService() && sh.editMode)">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <span>
        {{ 'disability' | translate }}
      </span>
      <button type="button"
        class="btn btn-link"
        *ngIf="us.isService() && sh.editMode"
        (click)="reset(); open = true">
        {{ 'add' | translate }}
      </button>
    </legend>
  </div>

  <disability-list [disabilities]="student.disabilities"
    [editable]="us.isService() && sh.editMode"
    (onRemove)="remove($event)"
    (onEdit)="openEdit($event)"></disability-list>
</div>

<modal [(open)]="open"
  [title]="(disabilityId ? 'edit-disability' : 'add-disability') | translate">
  <form #f="ngForm"
    id="disabilitiesForm"
    (ngSubmit)="save()"
    *ngIf="open">

    <div class="form-group">
      <label for="startDate">
        {{ 'start-date' | translate }}
      </label>

      <date-picker id="startDate"
        name="startDate"
        [placeholder]="'start-date' | translate"
        required="true"
        [isInvalid]="startDateInput.invalid && f.submitted"
        #startDateInput="ngModel"
        [time]="false"
        [(ngModel)]="start"> </date-picker>
      <validation-errors [control]="startDateInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="endDate">
        {{ 'end-date' | translate }}
        ({{ 'optional' | translate }})
      </label>

      <date-picker id="endDate"
        name="endDate"
        required="false"
        [placeholder]="'end-date' | translate"
        [isInvalid]="endDateInput.invalid && f.submitted"
        #endDateInput="ngModel"
        [time]="false"
        [required]="false"
        [(ngModel)]="end"> </date-picker>
      <validation-errors [control]="endDateInput"
        [show]="f.submitted"></validation-errors>
      <span class="invalid-feedback d-inline"
        *ngIf="start && end && !validDateRange()">
        {{ 'end-date-must-be-after-start-date' | translate }}
      </span>
    </div>

    <div class="form-group">
      <label>
        {{ 'justification' | translate }}
        ({{ 'optional' | translate }})
      </label>
      <validation-errors [show]="f.submitted"
        [control]="justificationInput"></validation-errors>
      <files-queue name="justification"
        [required]="false"
        #justificationInput="ngModel"
        [(ngModel)]="justification"></files-queue>
    </div>

    <span class="invalid-feedback d-inline-block"
      *ngIf="start && !validDisabilities()">
      {{ 'already-exists-disability-dates' | translate }}
    </span>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="disabilitiesForm">{{ 'save' | translate }}</button>
  </div>

</modal>