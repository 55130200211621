import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IStudent } from '../../../../../../interfaces/IStudent';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';
import { StudentsSearchService } from './students-search.service';
import { InfiniteListComponent } from '../../../../UI/infinite-list/infinite-list.component';

@Component({
  selector: 'students-search',
  templateUrl: './students-search.component.html',
})
export class StudentsSearchComponent implements OnInit {
  @ViewChild('list') list: InfiniteListComponent;
  @Input() dataFn: (params: any) => Promise<IStudent[]>;
  @Input() showFilters = true;
  @Input() program = true;
  @Input() idNum = true;
  @Input() email = false;
  @Input() state = true;
  @Input() rd = false;
  @Input() deadline = true;

  @Input() reportURL = '';

  students: IStudent[] = [];

  search: string;

  constructor(public ps: ProgramHttpService,
    public hs: HelperService,
    private router: Router,
    private route: ActivatedRoute,
    public sss: StudentsSearchService) { }

  doSearch() {
    this.list.search();
  }

  fetchFn() {
    return (params) => {
      // combine params
      params = {
        ...params,
        ...this.sss.filters
      }
      return this.dataFn(params);
    }
  }

  processStudents(students: IStudent[]) {
    this.students = students.map((student) => {
      if (this.ps.programsMap[student.programId])
        student.program = this.ps.programsMap[student.programId];
      return student;
    })
  }

  ngOnInit() {
  }

  gotToStudent(studentId) {
    void this.router.navigate([studentId], { relativeTo: this.route });
  }

}
