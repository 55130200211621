import { Component, Input, OnInit } from '@angular/core';

import { IStudent } from '../../../../../../../interfaces/IStudent';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { UserService } from '../../../../../user/user.service';

@Component({
  selector: 'academic-info',
  templateUrl: 'academic-info.component.html'
})

export class AcademicInfoComponent implements OnInit {
  @Input() student: IStudent;

  editProgramOpen = false;

  constructor(public ss: ServiceHttpService, public us: UserService) { }

  ngOnInit() { }
}