import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDedicationProcess, IDedicationRequestData } from '../../../interfaces/IDedicationProcess';
import { IDocumentRef } from '../../../interfaces/IDocument';

@Injectable()
export class DedicationHttpService {

  constructor(private http: HttpClient) { }

  request(studentId: string, data: IDedicationRequestData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/dedication/request/${studentId}`, data);
  }

  directionTeamValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/dedication/directionTeamValidate/${taskId}`, {});
  }

  directionTeamDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/dedication/directionTeamDeny/${taskId}`, data);
  }

  acValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/dedication/acValidate/${taskId}`, {});
  }

  acDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/dedication/acDeny/${taskId}`, data);
  }

  pgcValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/dedication/pgcValidate/${taskId}`, {});
  }

  pgcDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/dedication/pgcDeny/${taskId}`, data);
  }

  findProcess(taskId: string): Observable<IDedicationProcess> {
    return this.http.get<IDedicationProcess>(`/api/dedication/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<IDedicationProcess> {
    return this.http.get<IDedicationProcess>(`/api/dedication/process/${studentId}`);
  }

}