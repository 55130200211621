import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDocumentRef } from '../../../interfaces/IDocument';
import { IEvaluationProcess } from '../../../interfaces/IEvaluationProcess';
import { EvaluationType } from '../../../interfaces/IStudent';

@Injectable()
export class EvaluationHttpService {
  constructor(private http: HttpClient) { }

  request(studentId: string, data: { researchPlan: IDocumentRef, activitiesIds: string[] }): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/evaluation/request/${studentId}`, data);
  }

  modify(studentId: string, data: { processId: string, researchPlan: IDocumentRef, activitiesIds: string[] }): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/evaluation/modify/${studentId}`, data);
  }

  directionTeamReport(taskId: string, report: IDocumentRef): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluation/directionTeamReport/${taskId}`, { report });
  }

  evaluationCommitteeConfirmation(taskId: string, evaluationCommitteeId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluation/evaluationCommitteeConfirmation/${taskId}`, { evaluationCommitteeId });
  }

  evaluate(taskId: string, data: {
    evaluation: EvaluationType,
    presidentId: string,
    secretaryId: string,
    memberId: string,
    report: IDocumentRef
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluation/evaluate/${taskId}`, data);
  }

  confirmEvaluation(taskId: string, data: {
    evaluation: EvaluationType,
    presidentId: string,
    secretaryId: string,
    memberId: string,
    report: IDocumentRef
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/evaluation/confirmEvaluation/${taskId}`, data);
  }

  findProcess(taskId: string): Observable<IEvaluationProcess> {
    return this.http.get<IEvaluationProcess>(`/api/evaluation/findProcess/${taskId}`);
  }

  findProcessById(processId: string): Observable<IEvaluationProcess> {
    return this.http.get<IEvaluationProcess>(`/api/evaluation/findProcessById/${processId}`);
  }

  getTables(studentId: string): Observable<IEvaluationProcess[]> {
    return this.http.get<IEvaluationProcess[]>(`/api/evaluation/process/${studentId}`);
  }

  regenerate(studentId: string, evaluationId: string): Observable<IDocumentRef> {
    return this.http.get<IDocumentRef>(`/api/evaluation/regenerate/${studentId}/${evaluationId}`);
  }

}