import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IStudent, RDType } from '../../../../../../../interfaces/IStudent';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { UserService } from '../../../../../user/user.service';
import { NgForm } from '@angular/forms';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { ConfirmationDialogService } from '../../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'extensions-card',
  templateUrl: './extensions-card.component.html',
})
export class ExtensionsCardComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() student: IStudent;

  extensionId: string;
  justification: IDocumentRef[];
  date: Date;

  open = false;

  RDType = RDType;

  constructor(public sh: ServiceHttpService,
    public us: UserService,
    private refresh: RefreshService,
    private alerts: PopAlertsService,
    private confirmation: ConfirmationDialogService,
    private ts: TranslateService) { }

  ngOnInit() {
  }

  reset() {
    this.extensionId = null;
    this.justification = [];
    this.date = null;
  }

  openEdit(extensionId) {
    this.reset();
    const extension = this.student.extensions.find(e => e._id === extensionId);
    if (extension) {
      this.extensionId = extensionId;
      this.justification = [...extension.justification];
      this.date = extension.date;
      this.open = true;
    }
  }

  save() {
    if (!this.f.valid) {
      return;
    }
    if (this.extensionId) {
      this.sh.editExtension({
        studentId: this.student._id,
        extensionId: this.extensionId,
        justification: this.justification,
        date: this.date
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    } else {
      this.sh.addExtension({
        studentId: this.student._id,
        justification: this.justification,
        date: this.date
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    }
  }

  remove(extensionId: string) {
    this.confirmation.confirm({
      title: this.ts.instant('remove-extension'),
      message: this.ts.instant('are-you-sure-to-remove-extension'),
      btnOkText: this.ts.instant('remove')
    }, () => {
      this.sh.removeExtension({
        studentId: this.student._id,
        extensionId,
      }).subscribe(() => {
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    })
  }

}
