import { Pipe, PipeTransform } from '@angular/core';

import { DADUtils } from '../../../../utils/DADUtils';

@Pipe({
  name: 'evaluationYear'
})
export class EvaluationYearPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    if (!value || value.length !== 8) {
      return ""
    };

    return DADUtils.getEvaluationYear(value);
  }
}