import { IDocumentRef } from "./IDocument";
import { IProcess } from "./IProcess";
import { IStudent } from "./IStudent";

export interface IRenewalProcess extends IProcess {
  state: RenewalPState,
  studentId: string,

  leaveId: string,
  newEstimatedEnd?: Date,
  justification?: IDocumentRef[],

  // Populate
  student?: IStudent;
}

export enum RenewalPState {
  PendingServiceValidation = "PendingServiceValidation"
}

export enum TKRenewal {
  serviceValidation = "serviceValidation"
}

