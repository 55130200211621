<ng-container *ngIf="process">
  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'student' | translate }}
    </div>
    <div>
      {{ process.student.user.fullName }}
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'program' | translate }}
    </div>
    <div>
      {{ process.student.program.name }}
    </div>
  </div>

  <div *ngIf="process.student.program.coordinator"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'coordinator' | translate }}
    </div>
    <user-info-view [userInfo]="process.student.program.coordinator"></user-info-view>
  </div>

  <div *ngIf="process.student.tutor"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'tutor' | translate }}
    </div>
    <user-info-view [userInfo]="process.student.tutor"></user-info-view>
  </div>

  <div *ngIf="process.student.supervisor1"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.student.supervisor1"></user-info-view>
  </div>

  <div *ngIf="process.student.supervisor2"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'second-supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.student.supervisor2"></user-info-view>
  </div>

  <div *ngIf="process.student.supervisor3"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'third-supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.student.supervisor3"></user-info-view>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'industrial-phd' | translate }}
    </div>
    <div>
      {{ process.industrial ? ('yes' | translate) : ('no' | translate) }}
    </div>
  </div>

  <ng-container *ngIf="process.industrial">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'industrial-mention-agreement' | translate }}
      </div>
      <div class="mb-1">
        <ng-container *ngIf="process.agreementDocs">
          <document-link *ngFor="let doc of process.agreementDocs"
            [document]="doc"></document-link>
        </ng-container>
      </div>
    </div>

    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'industrial-mention-agreement-date' | translate }}
      </div>
      <div class="mb-1">
        <span *ngIf="process.agreementDate">
          {{ process.agreementDate | moment:'D/M/YY' }}
        </span>
      </div>
    </div>

    <div class="mb-4"
      *ngIf="process.organization">
      <div class="small text-muted mb-1">
        {{ 'organization' | translate }}
      </div>
      <div>
        {{ process.organization }}
      </div>
    </div>

    <div class="mb-4"
      *ngIf="process.organizationContact">
      <div class="small text-muted mb-1">
        {{ 'organization-contact' | translate}}
      </div>
      <div>
        {{ process.organizationContact }}
      </div>
    </div>

  </ng-container>

</ng-container>