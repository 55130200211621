import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';

import { IProgram } from '../../../../../../interfaces/IProgram';
import { IStudent, RDType, StudentStateType } from '../../../../../../interfaces/IStudent';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'students-list',
  templateUrl: 'students-list.component.html'
})
export class StudentsListComponent implements OnInit {
  @Input() students: IStudent[] = [];
  @Input() totalCount;
  @Output() onClick = new EventEmitter<IStudent>();

  @Input() filters: { programs: string[], states: StudentStateType[], searchText: string, rd: RDType, deadline: number } = {
    programs: [],
    states: [],
    searchText: '',
    rd: null,
    deadline: null // months for deadline
  }
  @Output() filtersChange = new EventEmitter<{ programs: string[], states: StudentStateType[], searchText: string, rd: RDType }>();

  @Input() program = true;
  @Input() idNum = true;
  @Input() email = false;
  @Input() state = true;
  @Input() rd = false;
  @Input() deadline = true;

  @Input() reportURL = '';

  @Input() showFilters = true;
  @Input() programs: IProgram[];

  StudentStateType = StudentStateType;
  RDType = RDType;

  states: StudentStateType[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.states = Object.keys(StudentStateType).map(k => StudentStateType[k]);
  }

  emitFilters() {
    this.filtersChange.emit(this.filters)
  }

  deadlineColor(date: Date) {
    // calc diff between now and date
    if (!date) return '';

    const diff = moment(date).diff(moment(), 'month', true);
    if (diff <= 0) {
      return 'text-danger';
    } else if (diff <= 6) {
      return 'text-warning';
    } else {
      return '';
    }

  }

  downloadReport() {
    this.http.post(`/api/me/saveFiltersInSession`, { ... this.filters }).subscribe(() => {
      const url = `${window.location.origin}/api/${this.reportURL}?format=excel`
      window.location.href = url;
    })
  }
}