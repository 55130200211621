<div class="row"
  *ngIf="student">

  <div class="col-12 col-md-6">

    <div class="card">
      <div class="card-body"
        *ngIf="ready">

        <div *ngFor="let p of processes">
          <h5 class="card-title m-0">
            <strong>
              {{ p.year | evaluationYear }}
            </strong>
            - {{ p.period | translate }}

            <span *ngIf="!p.active"
              class="badge badge-danger ml-1">
              {{ 'process-inactive' | translate}}
            </span>

            <button class="btn btn-sm ml-2 btn-outline-dark"
              type="button"
              *ngIf="sh.editMode && us.isService()"
              (click)="evaluationProcessEditModal.doOpen(p)">
              <i class="far fa-edit mr-1"></i>
              {{ 'edit' | translate }}
            </button>
          </h5>
          <tasks-table [tasks]="p.tasks"
            [process]="p"></tasks-table>

          <hr>

          <div class="mb-3">
            <div class="mb-1 small text-muted">
              {{ 'request-date' | translate }}
            </div>
            {{ p.createdAt | moment:'DD/MM/YYYY HH:mm' }}
          </div>

          <div *ngIf="p.requestDoc"
            class="mb-3">
            <div class="mb-1 small text-muted">
              {{ 'request-documents' | translate }}
            </div>
            <document-link [document]="p.requestDoc"></document-link>
            <document-link [document]="doc"
              styleClass="mt-1"
              *ngFor="let doc of p.olderRequestDocs"></document-link>
          </div>

          <div class="mb-3">
            <div class="small text-muted mb-1">
              <span *ngIf="student.rd === RDType.rd99Mod">
                {{ 'research-and-formation-plan' | translate }}
              </span>
              <span *ngIf="student.rd === RDType.rd99">
                {{ 'research-plan' | translate }}
              </span>
            </div>
            <document-link [document]="p.researchPlan"></document-link>
          </div>

          <div class="mb-3">
            <div class="small text-muted mb-1">
              {{ 'reports' | translate }}
            </div>
            <div *ngFor="let doc of p.reports"
              class="mt-1">
              <document-link [document]="doc"></document-link>
            </div>
          </div>

          <div class="mb-3">
            <div class="small text-muted mb-1">
              {{ 'evaluation-committee' | translate }}
            </div>
            <span *ngIf="p.evaluationCommittee">
              {{ hs.ecCode(p.evaluationCommittee) }}
              <ng-template #membersInfo>
                <ul class="mb-0">
                  <li *ngFor="let m of p.evaluationCommittee.members">
                    {{ m | fullname }}
                  </li>
                </ul>
              </ng-template>
              <span class="ml-2 text-info"
                [tooltip]="membersInfo"
                [tooltipClass]="'text-left'"
                contentType="template">
                <i class="far fa-info-circle"></i>
              </span>
            </span>
            <span *ngIf="!p.evaluationCommittee">
              {{ 'no-evaluation-committee' | translate }}
            </span>
          </div>

          <div class="small text-muted mb-1">
            {{ 'activities' | translate }}
          </div>
          <div style="width: 100%; max-height: 300px; overflow-y: auto"
            class="border rounded">
            <div class="list-group">
              <div class="list-group-item"
                *ngFor="let activity of p.activities">
                <activity-view [activity]="activity"
                  [full]="false"></activity-view>
              </div>
            </div>
          </div>

          <end-process-button [processId]="p._id"
            [processType]="Processes.evaluation"></end-process-button>

          <button class="btn btn-block btn-outline-secondary"
            *ngIf="us.isStudent() && canModify(p)"
            [routerLink]="['modify/' + p._id]"
            [disableButton]="!student.permissions[PermissionsType.completeTasks]">
            {{ 'modify-evaluation-request' | translate }}
          </button>
        </div>

        <ng-container
          *ngIf="(DADUtils.isInEvaluation(student.program) || sr.procStates?.evaluation.enabled) && !isStarted()">
          <div class="alert"
            [class.alert-info]="mustEvaluate === false"
            [class.alert-warning]="mustEvaluate === true">
            <div class="d-flex justify-content-start align-items-top">
              <div class="mr-3">
                <span *ngIf="mustEvaluate">
                  <i class="far fa-exclamation-triangle fa-2x"></i>
                </span>
                <span *ngIf="!mustEvaluate">
                  <i class="far fa-info-circle fa-2x"></i>
                </span>
              </div>
              <div>
                <h5>
                  {{ DADUtils.lastEvaluation().period | translate }}
                  {{ DADUtils.lastEvaluation().year | evaluationYear }}
                </h5>
                <h6 *ngIf="DADUtils.isInEvaluation(student.program)">
                  {{ 'evaluation-period-opened' | translate }}
                </h6>
                <h6 *ngIf="!DADUtils.isInEvaluation(student.program) && sr.procStates?.evaluation.enabled">
                  {{ 'authorized-to-request-evaluation' | translate }}
                </h6>
                <ng-container *ngIf="DADUtils.isInEvaluation(student.program)">
                  <span *ngIf="mustEvaluate">
                    {{ 'must-evaluate-in-the-current-evaluation-period' | translate }}
                  </span>
                  <span *ngIf="!mustEvaluate">
                    {{ 'must-not-evaluate-in-the-current-evaluation-period' | translate }}
                  </span>
                </ng-container>
                <div class="mt-3">
                  {{ 'evaluation-deadline' | translate }}:
                  <strong *ngIf="DADUtils.isInEvaluation(student.program)">
                    {{ DADUtils.evaluationDeadline(student.program, DADUtils.lastEvaluation().period) |
                    moment:'DD/MM/YYYY' }}
                  </strong>
                  <strong
                    *ngIf="!DADUtils.isInEvaluation(student.program) && sr.procStates?.evaluation.enabled && student.customPermissions && student.customPermissions.endDate">
                    {{ student.customPermissions.endDate | moment:'DD/MM/YYYY'}}
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="us.isStudent()"
            class="w-100 text-right mt-4">
            <button class="btn btn-outline-primary btn-block"
              [routerLink]="['request']"
              [disableButton]="!requestEnabled?.enabled">
              {{ 'request-evaluation' | translate }}
            </button>
            <request-disabled-reasons [requestEnabled]="requestEnabled"></request-disabled-reasons>
          </div>
        </ng-container>
        <ng-container *ngIf="!DADUtils.isInEvaluation(student.program) && processes.length <= 0">
          <div class="alert alert-info mt-4">
            {{ 'outOfEvaluationPeriod' | translate }}
          </div>
        </ng-container>

      </div>
    </div>

  </div>


  <div class="col-12 col-md-6">

    <evaluations-card [student]="student"></evaluations-card>

  </div>


</div>

<evaluation-process-edit-modal [student]="student"
  #evaluationProcessEditModal></evaluation-process-edit-modal>