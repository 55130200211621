<div *ngIf="user"
  class="container-fluid container-md">
  <div class="mb-2">
    <a class="text-muted"
      [routerLink]="['../']">
      <i class="far fa-angle-left"></i>
      {{ 'go-back' | translate }}
    </a>
  </div>

  <h2 class="mb-4 d-flex justify-content-between align-items-center">
    <span>
      <i class="far fa-user mr-2 text-secondary"></i>
      {{ user | fullname }}
      <small *ngIf="user.onlyCoSupervisor">
        <span class="badge badge-danger">
          Solo co-director
        </span>
      </small>
    </span>
    <div>
      <button class="btn btn-sm btn-outline-primary"
        type="button"
        (click)="impersonate(user._id)">
        {{ 'impersonate-user' | translate }}
      </button>
    </div>
  </h2>

  <div class="row">

    <div class="col-12 col-lg-6">
      <div class="card">
        <div class="card-body">
          <legend>
            {{ 'personal-data' | translate }}
          </legend>

          <text-info icon="user">
            {{ user | fullname }}
          </text-info>

          <text-info icon="id-card"
            mb="3">
            {{ user.idNum }}
          </text-info>

          <div class="mt-2 mb-4 pl-4">
            <input type="checkbox"
              id="onlyCoSupervisor"
              class="form-check-input"
              [(ngModel)]="user.onlyCoSupervisor"
              (click)="toggleOnlyCoSupervisor()">
            <label class="mb-0"
              for="onlyCoSupervisor">
              Solo co-director
            </label>
          </div>

          <div class="w-100 text-right">
            <button class="btn btn-sm btn-outline-primary"
              (click)="updateName()">
              Actualizar nombre desde DUMA
            </button>
          </div>

          <legend>
            {{ 'contact' | translate }}
          </legend>

          <text-info icon="envelope">
            <email-link [user]="user"></email-link>
          </text-info>

          <text-info icon="envelope"
            *ngIf="user.favEmail">
            <a href="mailto:{{user.favEmail.address}}">{{ user.favEmail.address }}</a>
          </text-info>

          <legend class="mt-4">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                Identidad DUMA
              </div>
              <div>
                <button class="btn btn-sm btn-link text-muted btn-icon"
                  type="button"
                  (click)="newUid = user.uid; open = true">
                  <i class="far fa-edit"></i>
                </button>
              </div>
            </div>
          </legend>

          <ul>
            <li>
              {{ user.uid }}
            </li>
            <li *ngFor="let uid of user.altUids">
              {{ uid }}
            </li>
          </ul>
        </div>
      </div>

      <div class="p-4"
        *ngIf="enableDirectionTeamAll()">
        <a [routerLink]="null"
          (click)="generateDirectionTeamAll()">
          <i class="fad fa-file-word fa-inverse fa-fw mr-1"
            style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
          Certificado tutor/director tesis
        </a>
      </div>

      <!-- <div class="p-4"
        *ngIf="enableCDSAll()">
        <a [routerLink]="null"
          (click)="generateCDSAll()">
          <i class="fad fa-file-word fa-inverse fa-fw mr-1"
            style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
          Certificado tutor/director CDS
        </a>
      </div> -->

      <div class="card mt-4">
        <div class="card-body">
          <legend class="d-flex justify-content-between align-items-center">
            <span>
              {{ 'documents' | translate }}
            </span>
            <span class="btn-icon"
              (click)="doOpenEditDocuments()">
              <i class="far fa-edit"></i>
            </span>
          </legend>

          <div class="mb-4">
            <div class="small text-muted mb-1">
              {{ 'cp-agreement' | translate }}
            </div>
            <div *ngIf="user.cpAgreement">
              <document-link [document]="user.cpAgreement"></document-link>
            </div>
          </div>

        </div>
      </div>


    </div>

    <div class="col-12 col-lg-6">
      <div class="card"
        *ngIf="globalRelations && globalRelations.length > 0">
        <div class="card-header">
          Roles generales
        </div>
        <div class="list-group">
          <div class="list-group-item"
            *ngFor="let r of globalRelations">
            {{ r.role | translate }}
          </div>
        </div>
      </div>

      <div class="card"
        [class.mt-4]="globalRelations && globalRelations.length > 0"
        *ngIf="programRelations && programRelations.length > 0">
        <div class="card-header">
          Programas
        </div>
        <table class="table table-sm mb-0">
          <tbody>
            <tr *ngFor="let r of programRelations">
              <td>
                {{ r.program }}
              </td>
              <td>
                <span *ngIf="r.role !== Role.student">
                  {{ r.role | translate }}
                </span>
                <span *ngIf="r.role === Role.student">
                  <a [routerLink]="['/app/service/students/' + r.studentId]">
                    {{ r.role | translate }}
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card"
        [class.mt-4]="(globalRelations && globalRelations.length > 0) || (programRelations && programRelations.length > 0)"
        *ngIf="facultyRelations && facultyRelations.length > 0">
        <div class="card-header">
          Centros
        </div>
        <table class="table table-sm mb-0">
          <tbody>
            <tr *ngFor="let r of facultyRelations">
              <td>
                {{ r.role | translate }}
              </td>
              <td>
                {{ r.name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div class="col-12"
      *ngIf="studentRelations && studentRelations.length > 0">
      <div class="card mt-4">
        <div class="card-header">
          Estudiantes
        </div>
        <table class="table table-sm mb-0">
          <tbody>
            <tr *ngFor="let r of studentRelations">
              <td class="text-right">
                <student-state [state]="r.state"></student-state>
                <student-state *ngFor="let state of r.altStates"
                  [state]="state"
                  [ml]="true"></student-state>
              </td>
              <td>
                <a [routerLink]="['/app/service/students/' + r.studentId]">
                  {{ r.name }}
                </a>
              </td>
              <td>
                <span class="mr-2"
                  *ngFor="let role of r.roles">
                  {{ role | translate }}
                  <a [routerLink]="null"
                    (click)="generateThesisCert(r.studentId, role)"
                    class="ml-1"
                    [tooltip]="'Generar certificado'"
                    *ngIf="r.state === StudentStateType.defended && isThesisCommittee(role)">
                    <i class="fad fa-file-word fa-inverse fa-fw"
                      style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
                  </a>
                  <a [routerLink]="null"
                    (click)="generateCDS(r.studentId)"
                    class="ml-1"
                    [tooltip]="'Generar certificado'"
                    *ngIf="isCDS(role) && r.state !== StudentStateType.defended">
                    <i class="fad fa-file-word fa-inverse fa-fw"
                      style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
                  </a>
                  <a [routerLink]="null"
                    (click)="generateDirectionTeam(r.studentId)"
                    class="ml-1"
                    [tooltip]="'Generar certificado'"
                    *ngIf="isCDS(role) && r.state === StudentStateType.defended">
                    <i class="fad fa-file-word fa-inverse fa-fw"
                      style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
                  </a>
                  <a [routerLink]="null"
                    (click)="generateExternalReviewerCert(r.studentId)"
                    class="ml-1"
                    [tooltip]="'Generar certificado'"
                    *ngIf="isExternalReviewer(role)">
                    <i class="fad fa-file-word fa-inverse fa-fw"
                      style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <modal [(open)]="open"
    [title]="'Modificar Identidad DUMA'">
    <form #f="ngForm"
      id="dumaForm"
      (ngSubmit)="saveNewUid()">
      <div class="form-group">
        <label>Nuevo Identificador DUMA</label>
        <input type="text"
          [(ngModel)]="newUid"
          id="newUid"
          name="newUid"
          #newUidInput="ngModel"
          class="form-control"
          required>
        <validation-errors [control]="newUidInput"
          [show]="f.submitted"></validation-errors>
      </div>

    </form>

    <div footer>
      <button class="btn btn-outline-dark mr-1"
        type="button"
        (click)="open = false">{{ 'cancel' | translate }}</button>
      <button class="btn btn-primary"
        type="submit"
        form="dumaForm">{{ 'save' | translate }}</button>
    </div>

  </modal>

  <modal [(open)]="openEditDocuments"
    [title]="'edit-documentation' | translate">
    <form #f="ngForm"
      id="documentsForm"
      (ngSubmit)="saveDocuments()">
      <div class="form-group">
        <label for="cpAgreement">
          {{ 'cp-agreement' | translate }} ({{ 'optional' | translate }})
        </label>
        <file-input [(ngModel)]="cpAgreement"
          [required]="false"
          #cpAgreementInput="ngModel"
          name="cpAgreement"
          id="cpAgreement"></file-input>
        <validation-errors [control]="cpAgreementInput"
          [show]="f.submitted"></validation-errors>
      </div>

    </form>

    <div footer>
      <button class="btn btn-outline-dark mr-1"
        type="button"
        (click)="openEditDocuments = false">{{ 'cancel' | translate }}</button>
      <button class="btn btn-primary"
        type="submit"
        form="documentsForm">{{ 'save' | translate }}</button>
    </div>

  </modal>