import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { zip } from 'rxjs';
import * as _ from 'lodash';

import { IDedicationProcess } from '../../../../../../interfaces/IDedicationProcess';
import { IDirectionProcess } from '../../../../../../interfaces/IDirectionProcess';
import { PermissionsType } from '../../../../../../interfaces/IPermissions';
import { ICDS, ICDSChange, IStudent } from '../../../../../../interfaces/IStudent';
import { Processes } from '../../../../../../interfaces/ITask';
import { CDSHttpService } from '../../../../data-services/cds-http.service';
import { DedicationHttpService } from '../../../../data-services/dedication-http.service';
import { DirectionHttpService } from '../../../../data-services/direction-http.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { UserService } from '../../../../user/user.service';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { IIndustrialProcess } from '../../../../../../interfaces/IIndustrialProcess';
import { IndustrialMentionHttpService } from '../../../../data-services/industrial-mention-http.service';

@Component({
  selector: 'student-cds',
  templateUrl: 'student-cds.component.html'
})
export class StudentCDSComponent implements OnInit, OnChanges {
  @Input() student: IStudent;

  @Input() cdsRequestEnabled: { enabled: boolean, reasons: string[] };
  @Input() directionRequestEnabled: { enabled: boolean, reasons: string[] };
  @Input() dedicationRequestEnabled: { enabled: boolean, reasons: string[] };

  dedicationProcess: IDedicationProcess;
  directionProcess: IDirectionProcess;
  cdsProcess;
  industrialProcess: IIndustrialProcess;

  changes: ICDSChange[] = [];
  currentCDS: IDocumentRef;
  currentCDSDate: Date;

  loading = true;

  PermissionsType = PermissionsType;
  Processes = Processes;

  constructor(public ds: DocumentService,
    private cds: CDSHttpService,
    private dedication: DedicationHttpService,
    private direction: DirectionHttpService,
    private industrial: IndustrialMentionHttpService,
    public us: UserService) { }

  ngOnInit() {
    this.loading = true;
  }

  ngOnChanges() {
    if (this.student) {
      if (this.student.cds && this.student.cds.changes) {
        this.changes = _.orderBy(this.student.cds.changes, 'date', 'desc');
      }

      if (this.changes.length > 0) {
        this.currentCDS = this.changes[0].document;
        this.currentCDSDate = this.changes[0].date;
      } else if (this.student.cds) {
        this.currentCDS = this.student.cds.document;
        this.currentCDSDate = this.student.cds.date;
      }

      zip(
        this.cds.getTasksTable(this.student._id),
        this.dedication.getTasksTable(this.student._id),
        this.direction.getTasksTable(this.student._id),
        this.industrial.getTasksTable(this.student._id)
      ).subscribe((result) => {
        this.cdsProcess = result[0];
        this.dedicationProcess = result[1];
        this.directionProcess = result[2];
        this.industrialProcess = result[3];
        this.loading = false;
      });
    }
  }

}