<h5 class="card-title">{{ 'thesis-committee' | translate }}</h5>

<div class="row">
  <div class="col-md-4 pr-md-0">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'president' | translate }}
      </div>
      <div>
        <user-info-view [showCountry]="true"
          [showCV]="showCV"
          [sm]="true"
          [userInfo]="thesisCommittee.president"></user-info-view>
      </div>
    </div>
  </div>
  <div class="col-md-4 px-md-2">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'secretary' | translate }}
      </div>
      <div>
        <user-info-view [showCountry]="true"
          [showCV]="showCV"
          [sm]="true"
          [userInfo]="thesisCommittee.secretary"></user-info-view>
      </div>
    </div>
  </div>
  <div class="col-md-4 pl-md-0">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'member' | translate }}
      </div>
      <div>
        <user-info-view [showCountry]="true"
          [showCV]="showCV"
          [sm]="true"
          [userInfo]="thesisCommittee.member"></user-info-view>
      </div>
    </div>
  </div>
</div>

<h5 class="card-title">{{ 'thesis-committee-alt' | translate }}</h5>

<div class="row">
  <div class="col-md-4 pr-md-0">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'first-alternate' | translate }}
      </div>
      <div>
        <user-info-view [showCountry]="true"
          [showCV]="showCV"
          [sm]="true"
          [userInfo]="thesisCommittee.alt1"></user-info-view>
      </div>
    </div>
  </div>
  <div class="col-md-4 px-md-2">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'second-alternate' | translate }}
      </div>
      <div>
        <user-info-view [showCountry]="true"
          [showCV]="showCV"
          [sm]="true"
          [userInfo]="thesisCommittee.alt2"></user-info-view>
      </div>
    </div>
  </div>
  <div class="col-md-4 pl-md-0">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'third-alternate' | translate }}
      </div>
      <div>
        <user-info-view [showCountry]="true"
          [showCV]="showCV"
          [sm]="true"
          [userInfo]="thesisCommittee.alt3"></user-info-view>
      </div>
    </div>
  </div>
</div>