<modal [(open)]="open"
  [size]="'lg'"
  [title]="'edit-thesis-processing' | translate">

  <thesis-request-form *ngIf="open"
    [student]="student"
    [data]="clonedData"
    [sendBtnText]="'save'"
    [allowCompendiumDirectorReport]="true"
    (onSend)="save()"></thesis-request-form>

</modal>