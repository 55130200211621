<div class="card"
  *ngIf="student.extensions.length > 0 || (us.isService() && sh.editMode)">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <span *ngIf="student.rd === RDType.rd99">
        {{ 'extensions' | translate }}
      </span>
      <span *ngIf="student.rd !== RDType.rd99">
        {{ 'extension' | translate }}
      </span>
      <button type="button"
        class="btn btn-link"
        *ngIf="us.isService() && sh.editMode"
        [disabled]="student.extensions.length >= 2"
        [tooltip]="'max-extensions-reached' | translate"
        [display]="student.extensions.length >= 2"
        (click)="reset(); open = true">
        {{ 'add' | translate }}
      </button>
    </legend>
  </div>

  <extensions-list [extensions]="student.extensions"
    [showOrder]="student.rd === RDType.rd99"
    [editable]="us.isService() && sh.editMode"
    (onRemove)="remove($event)"
    (onEdit)="openEdit($event)"></extensions-list>
</div>

<modal [(open)]="open"
  [title]="(extensionId ? 'edit-extension' : 'add-extension') | translate">
  <form #f="ngForm"
    id="extensionsForm"
    (ngSubmit)="save()"
    *ngIf="open">
    <div class="form-group">
      <label>{{ 'date' | translate }}</label>
      <date-picker [(ngModel)]="date"
        name="date"
        #dateInput="ngModel"
        [isInvalid]="f.submitted && dateInput.invalid"
        [required]="true"></date-picker>
      <validation-errors [control]="dateInput"
        [show]="f.submitted"></validation-errors>
    </div>
    <div class="form-group">
      <label>{{ 'justification' | translate }}</label>
      <files-queue [(ngModel)]="justification"
        name="justification"
        #justificationInput="ngModel"
        [required]="true"></files-queue>
      <validation-errors [control]="justificationInput"
        [show]="f.submitted"></validation-errors>
    </div>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="extensionsForm">{{ 'save' | translate }}</button>
  </div>

</modal>