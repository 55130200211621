import { Component, OnInit } from '@angular/core';

import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';

@Component({
  selector: 'service-students',
  templateUrl: './service-students.component.html',
})
export class ServiceStudentsComponent implements OnInit {

  constructor(public ss: ServiceHttpService, public hs: HelperService) { }

  ngOnInit() {
  }

  dataFn() {
    return (params) => {
      return this.ss.fetchStudents(params);
    }
  }

}
