<span class="navbar-text d-block text-truncate"
  *ngIf="us.selectableRoles.length <= 1">
  <span class="d-none d-md-inline">
    {{ us.selectedRole.role | translate }}
    <span class="mx-1"
      *ngIf="us.selectedRole.groupName && us.selectedRole.groupName.length > 0">
      / {{ us.selectedRole.groupName }}
    </span>
  </span>
  <span class="d-inline d-md-none">
    {{ us.selectedRole.role | translate }}
  </span>
</span>

<ul class="navbar-nav d-inline text-truncate"
  *ngIf="us.selectableRoles.length > 1">
  <li #roleDropdown
    class="nav-item dropdown roles-dropdown-btn">
    <a class="nav-link dropdown-toggle"
      role="button"
      (click)="dds.toggle('roleDropdown', roleDropdown)">
      <span class="d-inline d-md-none">
        {{ us.selectedRole.role | translate | truncate:charLimit}}
      </span>
      <span class="d-none d-md-inline">
        {{ ((us.selectedRole.role | translate) + (us.selectedRole.groupName ? ' / ' + us.selectedRole.groupName : '')) |
        truncate:charLimit }}
      </span>
    </a>
    <div class="dropdown-menu roles-dropdown"
      [@dropdownAnimation]="dds.show('roleDropdown') ? 'in' : 'out'"
      [class.show]="dds.show('roleDropdown')">
      <ng-container *ngFor="let role of us.selectableRoles">
        <a class="dropdown-item"
          [routerLink]="null"
          [class.active]="role == us.selectedRole"
          role="button"
          (click)="us.selectRole(role, true); dds.toggle('roleDropdown', roleDropdown)">
          {{ role.role | translate }}
          <span *ngIf="role.groupName && role.groupName.length > 0">
            / {{ role.groupName }}
          </span>
        </a>
      </ng-container>
    </div>
  </li>
</ul>