import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { IActivity } from '../../../../../../interfaces/IActivity';


@Component({
  selector: 'new-version',
  templateUrl: 'new-version.component.html'
})
export class NewVersionComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() activity: IActivity;
  @Input() document: { documentRef: string, fileName: string };
  @Input() active = false;

  @Output() onSave = new EventEmitter<{ activityId: string, documentId: string, versionId: string }>();

  docRef: {
    documentRef: string,
    fileName: string
  };

  constructor() { }

  ngOnInit() {
  }

  reset() {
    this.docRef = null;
  }

  save() {
    if (!this.f.valid) return;

    this.onSave.emit({ activityId: this.activity._id, documentId: this.document.documentRef, versionId: this.docRef.documentRef })

  }

}