import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { IActiveProcesses, IStudent } from '../../../../../interfaces/IStudent';
import { StudentHttpService } from '../../../data-services/student-http.service';
import { DocumentService } from '../../../shared/document-service/document.service';

@Injectable()
export class StudentFileService {
  activeProcesses: IActiveProcesses;

  student: IStudent;
  studentObs = new BehaviorSubject<IStudent>(null);

  constructor(private ss: StudentHttpService, private ds: DocumentService) { }

  fetch(studentId) {
    this.ss.fetchStudent(studentId).subscribe((student) => {
      this.student = student;
      this.ds.studentId = student._id;
      this.ss.fetchActiveProcesses(student._id).subscribe((activeProcesses) => {
        this.activeProcesses = activeProcesses;
        this.studentObs.next(student);
      });
    }, (err) => {
      this.studentObs.error(err);
    });
  }


}