<div class="container">

  <div class="row">
    <div class="col">
      <section-title text="programs"
        icon="book-alt">
      </section-title>
    </div>
  </div>

  <div class="row">
    <div class="col mb-3">

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            {{ 'professor-process-status' | translate }}

            <button class="btn btn-sm ml-2 btn-outline-dark"
              (click)="openPpModal()">
              <i class="fas fa-edit mr-1"></i>
              {{ 'edit' | translate}}
            </button>
            <button class="btn btn-sm ml-2 btn-outline-danger"
              *ngIf="ps.isProfessorProcessOpen"
              (click)="closeProfessorProcess()">
              <i class="fas fa-times mr-1"></i>
              Cerrar plazo
            </button>
          </h5>
          <div class="mb-3">
            <span class="ml-2"
              *ngIf="ps.isProfessorProcessOpen === true">
              <i class="far fa-circle text-success mr-1"></i>
              {{ 'open' | translate}}
            </span>
            <span class="ml-2"
              *ngIf="ps.isProfessorProcessOpen === false">
              <i class="far fa-circle text-danger mr-1"></i>
              {{ 'closed' | translate}}
            </span>
          </div>
          <ng-container *ngIf="ps.isProfessorProcessOpen">
            <div class="mb-3">
              <span class="ml-2">
                {{ 'start-date' | translate }}:
                {{ ps.professorProcessStart | moment: 'DD/MM/YYYY' }}
              </span>
            </div>
            <div class="mb-3">
              <span class="ml-2">
                {{ 'end-date' | translate }}:
                {{ ps.professorProcessEnd | moment: 'DD/MM/YYYY' }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="list-group">

      <div class="list-group-item list-group-item-action c-pointer"
        *ngFor="let program of ps.programs"
        [routerLink]="[program._id]">
        <span class="d-inline-block mr-2 text-secondary text-center"
          style="width: 80px">
          {{ program.code }}
        </span>

        <span>
          {{ program.name }}
        </span>

      </div>

    </div>
  </div>

</div>

<modal [title]="'Proceso de incorporación de profesorado'"
  [(open)]="ppModalOpen">
  <form id="ppForm"
    (ngSubmit)="saveProfessorProcess()"
    #f="ngForm">

    <div class="form-group">
      <label for="startDate">
        {{ 'start-date' | translate }}
      </label>

      <date-picker id="startDate"
        name="startDate"
        [placeholder]="'start-date' | translate"
        required="true"
        [isInvalid]="startDateInput.invalid && f.submitted"
        #startDateInput="ngModel"
        [time]="false"
        [(ngModel)]="start"> </date-picker>
      <validation-errors [control]="startDateInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="endDate">
        {{ 'end-date' | translate }}
      </label>

      <date-picker id="endDate"
        name="endDate"
        required="false"
        [placeholder]="'end-date' | translate"
        [isInvalid]="endDateInput.invalid && f.submitted"
        #endDateInput="ngModel"
        [time]="false"
        [required]="true"
        [(ngModel)]="end"> </date-picker>
      <validation-errors [control]="endDateInput"
        [show]="f.submitted"></validation-errors>
      <span class="invalid-feedback d-inline"
        *ngIf="start && end && !validDateRange()">
        {{ 'end-date-must-be-after-start-date' | translate }}
      </span>
    </div>


    <div class="alert alert-info">
      Al pulsar "Guardar" la aplicación enviará el siguiente correo electrónico a todos los coordinadores:
      <br>
      <br>
      <strong>{{ 'reminders.coordinatorsProfessorProcessOpened.sbj' | translate }}</strong>
      <br>
      <div
        [innerHTML]="'reminders.coordinatorsProfessorProcessOpened.msg' | translate: { start: moment(start).format('DD/MM/YYYY'), end: moment(end).format('DD/MM/YYYY'), program: '[NOMBRE DEL PROGRAMA]' }">
      </div>
    </div>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="ppModalOpen = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="ppForm">{{ 'save' | translate }}</button>
  </div>

</modal>