<ng-container *ngIf="process">

  <task-header [task]="task"></task-header>

  <br>

  <denial-msgs-view [denialMsgs]="task.cdsProcess.denialMsgs"></denial-msgs-view>

  <br>

  <cds-process-data-view [process]="process"></cds-process-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()"
    *ngIf="canComplete">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <ng-container *ngIf="validation.validate">

      <div class="form-group">
        <label for="tutorRequirementSelect">
          {{ process.tutor | fullname }}
        </label>
        <ng-select id="tutorRequirementSelect"
          name="tutorRequirementSelect"
          #tutorRequirementSelect="ngModel"
          [ngModel]="data.tutorReason"
          (ngModelChange)="onChangeReason($event, 'tutorReason')"
          [required]="true"
          [class.is-invalid]="f.submitted && tutorRequirementSelect.invalid"
          [placeholder]="'supervisor-requirement' | translate"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
          <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
          <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
          <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
        </ng-select>
        <small class="form-text text-muted"
          *ngIf="data.tutorReason && data.tutorReason.includes('sixyearPeriod')"
          [innerHTML]="'sixyearPeriod-note' | translate">
        </small>
        <small class="form-text text-muted"
          *ngIf="data.tutorReason && data.tutorReason.includes('none')"
          [innerHTML]="'none-requirement-note' | translate">
        </small>
        <validation-errors [show]="f.submitted"
          [control]="tutorRequirementSelect"></validation-errors>
      </div>

      <div class="form-group"
        *ngIf="!tutorIsSupervisor1">
        <label for="supervisor1RequirementSelect">
          {{ process.supervisor1 | fullname }}
        </label>
        <ng-select id="supervisor1RequirementSelect"
          name="supervisor1RequirementSelect"
          #supervisor1RequirementSelect="ngModel"
          [ngModel]="data.supervisor1Reason"
          (ngModelChange)="onChangeReason($event, 'supervisor1Reason')"
          [required]="true"
          [class.is-invalid]="f.submitted && supervisor1RequirementSelect.invalid"
          [placeholder]="'supervisor-requirement' | translate"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
          <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
          <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
          <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
        </ng-select>
        <small class="form-text text-muted"
          *ngIf="data.supervisor1Reason && data.supervisor1Reason.includes('sixyearPeriod')"
          [innerHTML]="'sixyearPeriod-note' | translate">
        </small>
        <small class="form-text text-muted"
          *ngIf="data.supervisor1Reason && data.supervisor1Reason.includes('none')"
          [innerHTML]="'none-requirement-note' | translate">
        </small>
        <validation-errors [show]="f.submitted"
          [control]="supervisor1RequirementSelect"></validation-errors>
      </div>

      <div class="form-group"
        *ngIf="enableSupervisor2 && !tutorIsSupervisor2">
        <label for="supervisor2RequirementSelect">
          {{ process.supervisor2 | fullname }}
        </label>
        <ng-select id="supervisor2RequirementSelect"
          name="supervisor2RequirementSelect"
          #supervisor2RequirementSelect="ngModel"
          [ngModel]="data.supervisor2Reason"
          (ngModelChange)="onChangeReason($event, 'supervisor2Reason')"
          [required]="true"
          [class.is-invalid]="f.submitted && supervisor2RequirementSelect.invalid"
          [placeholder]="'supervisor-requirement' | translate"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
          <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
          <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
          <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
        </ng-select>
        <small class="form-text text-muted"
          *ngIf="data.supervisor2Reason && data.supervisor2Reason.includes('sixyearPeriod')"
          [innerHTML]="'sixyearPeriod-note' | translate">
        </small>
        <small class="form-text text-muted"
          *ngIf="data.supervisor2Reason && data.supervisor2Reason.includes('none')"
          [innerHTML]="'none-requirement-note' | translate">
        </small>
        <validation-errors [show]="f.submitted"
          [control]="supervisor2RequirementSelect"></validation-errors>
      </div>

      <div class="form-group"
        *ngIf="enableSupervisor3 && !tutorIsSupervisor3">
        <label for="supervisor3RequirementSelect">
          {{ process.supervisor3 | fullname }}
        </label>
        <ng-select id="supervisor3RequirementSelect"
          name="supervisor3RequirementSelect"
          #supervisor3RequirementSelect="ngModel"
          [ngModel]="data.supervisor3Reason"
          (ngModelChange)="onChangeReason($event, 'supervisor3Reason')"
          [required]="true"
          [class.is-invalid]="f.submitted && supervisor3RequirementSelect.invalid"
          [placeholder]="'supervisor-requirement' | translate"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
          <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
          <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
          <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
        </ng-select>
        <small class="form-text text-muted"
          *ngIf="data.supervisor3Reason && data.supervisor3Reason.includes('sixyearPeriod')"
          [innerHTML]="'sixyearPeriod-note' | translate">
        </small>
        <small class="form-text text-muted"
          *ngIf="data.supervisor3Reason && data.supervisor3Reason.includes('none')"
          [innerHTML]="'none-requirement-note' | translate">
        </small>
        <validation-errors [show]="f.submitted"
          [control]="supervisor3RequirementSelect"></validation-errors>
      </div>

      <div class="form-group">
        <label for="lineOfResearchSelect">
          {{ 'line-of-research' | translate }}
        </label>
        <ng-select id="lineOfResearchSelect"
          name="lineOfResearchSelect"
          #lineOfResearchSelect="ngModel"
          [items]="linesOfResearch"
          bindValue="_id"
          bindLabel="name"
          required
          [(ngModel)]="data.lineOfResearchId"
          [class.is-invalid]="f.submitted && lineOfResearchSelect.invalid"
          [placeholder]="'select-line-of-research' | translate"
          [searchable]="false"
          [clearable]="false">
        </ng-select>
        <validation-errors [show]="f.submitted"
          [control]="lineOfResearchSelect"></validation-errors>
      </div>

      <div class="form-group">
        <label for="needSupplementSelect">
          {{ 'needs-supplement' | translate }}
        </label>
        <ng-select id="needSupplementSelect"
          name="needSupplementSelect"
          #needSupplementSelect="ngModel"
          [(ngModel)]="data.needsSupplement"
          required
          [class.is-invalid]="f.submitted && needSupplementSelect.invalid"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="false">{{ 'no' | translate }}</ng-option>
          <ng-option [value]="true">{{ 'yes' | translate }}</ng-option>
        </ng-select>
        <validation-errors [show]="f.submitted"
          [control]="needSupplementSelect"></validation-errors>
      </div>

      <ng-container *ngIf="data.needsSupplement">
        <div class="form-group">
          <label for="creditsInput">
            {{ 'Credits' | translate }}
          </label>
          <input type="number"
            name="creditsInput"
            id="creditsInput"
            class="form-control"
            [(ngModel)]="data.supplement.credits"
            #creditsInput="ngModel"
            required
            [placeholder]="'number-of-credits' | translate"
            [class.is-invalid]="f.submitted && creditsInput.invalid">
          <validation-errors [show]="f.submitted"
            [control]="creditsInput"></validation-errors>
        </div>


        <div class="form-group">
          <label>
            {{ 'supplement-document' | translate }}
            <small class="text-muted ml-2">
              {{ 'optional' | translate }}
            </small>
          </label>
          <file-input name="supplementDocumentInput"
            #supplementDocumentInput="ngModel"
            [required]="false"
            [(ngModel)]="data.supplement.document"></file-input>
          <validation-errors [show]="f.submitted"
            [control]="supplementDocumentInput"></validation-errors>
        </div>

        <div class="form-group">
          <label for="supplementNotesInput">
            {{ 'supplement-notes' | translate }}
            <small class="text-muted ml-2">
              {{ 'optional' | translate }}
            </small>
          </label>
          <textarea name="supplementNotesInput"
            id="supplementNotesInput"
            class="form-control"
            [(ngModel)]="data.supplement.notes"
            #supplementNotesInput="ngModel"
            [placeholder]="'supplement-notes' | translate"
            [class.is-invalid]="f.submitted && supplementNotesInput.invalid"></textarea>
          <validation-errors [show]="f.submitted"
            [control]="supplementNotesInput"></validation-errors>
        </div>
      </ng-container>

    </ng-container>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>

  <div *ngIf="canComplete === false"
    class="alert alert-info">
    <i class="far fa-info"></i>
    {{ 'only-coordinator-can-complete' | translate }}
  </div>

</ng-container>