<h6 *ngIf="!canComplete"
  class="mb-4">
  <i class="far fa-info-circle mr-1 text-info"></i>
  {{ cantCompleteMsg | translate }}
</h6>

<ng-container *ngIf="showTitle">
  <h5 class="card-title">
    {{ 'process.cds.request.name' | translate }}
  </h5>

  <p [innerHTML]="'process.cds.request.desc' | translate">
  </p>
</ng-container>

<form #f="ngForm"
  (ngSubmit)="send()">

  <div class="form-group">
    <label for="dedicacionSelect">
      {{ 'dedication' | translate }}
    </label>
    <ng-select id="dedicacionSelect"
      name="dedicacionSelect"
      #dedicationSelect="ngModel"
      [(ngModel)]="data.dedication"
      required
      [class.is-invalid]="f.submitted && dedicationSelect.invalid"
      [placeholder]="'select-dedicacion' | translate"
      [searchable]="false"
      [clearable]="false">
      <ng-option [value]="'FULL'">{{ 'FULL' | translate }}</ng-option>
      <ng-option [value]="'PART'">{{ 'PART' | translate }}</ng-option>
    </ng-select>
    <validation-errors [show]="f.submitted"
      [control]="dedicationSelect"></validation-errors>
  </div>

  <div class="form-group"
    *ngIf="data.dedication === 'PART'">
    <label>
      {{ 'part-time-justification' | translate }}
    </label>
    <validation-errors [show]="f.submitted"
      [control]="partTimeDocInput"></validation-errors>
    <files-queue name="partTimeDocInput"
      #partTimeDocInput="ngModel"
      required
      [(ngModel)]="data.partialTimeDoc"></files-queue>
    <small class="form-text text-muted">
      {{ 'part-time-justification-note' | translate }}
    </small>
  </div>

  <label>{{ 'tutor' | translate }}</label>
  <user-info-selector [(ngModel)]="data.tutor"
    [showErrors]="f.submitted"
    [showCdsReason]="full"
    [required]="directionRequired"
    name="tutor"></user-info-selector>

  <label>{{ 'supervisor' | translate }}</label>
  <user-info-selector [(ngModel)]="data.supervisor1"
    [showErrors]="f.submitted"
    [showCdsReason]="full"
    [required]="directionRequired"
    name="supervisor1"></user-info-selector>

  <div class="form-group form-check"
    *ngIf="!enableSupervisor2">
    <input type="checkbox"
      class="form-check-input"
      name="enableSupervisor2"
      id="enableSupervisor2"
      [ngModel]="enableSupervisor2"
      (ngModelChange)="toggleSupervisor2()">
    <label class="form-check-label"
      for="enableSupervisor2">
      {{ 'enable-second-supervisor' | translate }}
    </label>
  </div>

  <ng-container *ngIf="enableSupervisor2">
    <div class="mb-2 form-check">
      <input type="checkbox"
        class="form-check-input"
        name="secondSupervisor"
        id="secondSupervisor"
        [ngModel]="enableSupervisor2"
        (ngModelChange)="toggleSupervisor2()">
      <label class="form-check-label"
        for="secondSupervisor">
        {{ 'second-supervisor' | translate }}
      </label>
    </div>
    <user-info-selector [(ngModel)]="data.supervisor2"
      [showErrors]="f.submitted"
      [showCdsReason]="full"
      [required]="directionRequired"
      name="supervisor2"></user-info-selector>

    <div class="form-group form-check"
      *ngIf="!enableSupervisor3">
      <input type="checkbox"
        class="form-check-input"
        name="enableSupervisor3"
        id="enableSupervisor3"
        [ngModel]="enableSupervisor3"
        (ngModelChange)="toggleSupervisor3()">
      <label class="form-check-label"
        for="enableSupervisor3">
        {{ 'enable-third-supervisor' | translate }}
      </label>
    </div>

    <ng-container *ngIf="enableSupervisor3">
      <div class="mb-2 form-check">
        <input type="checkbox"
          class="form-check-input"
          name="thirdSupervisor"
          id="thirdSupervisor"
          [ngModel]="enableSupervisor3"
          (ngModelChange)="toggleSupervisor3()">
        <label class="form-check-label"
          for="thirdSupervisor">
          {{ 'third-supervisor' | translate }}
        </label>
      </div>
      <user-info-selector [(ngModel)]="data.supervisor3"
        [showErrors]="f.submitted"
        [showCdsReason]="full"
        [required]="directionRequired"
        name="supervisor3"></user-info-selector>

      <div class="form-group">
        <label>
          {{ 'third-supervisor-justification' | translate }}
          <a target="_blank"
            class="ml-2"
            href="https://www.uma.es/doctorado/navegador_de_ficheros/DocDoctorado/descargar/FORMULARIOS%20JULIO%202023/Solicitud%20de%20triple%20direccion%20de%20tesis%20doctoral.docx">{{
            'download-form' | translate }}</a>
        </label>
        <file-input name="thirdSupervisorJustification"
          #thirdSupervisorJustificationInput="ngModel"
          required
          [(ngModel)]="data.thirdSupervisorDoc"></file-input>
        <validation-errors [show]="f.submitted"
          [control]="thirdSupervisorJustificationInput"></validation-errors>
        <small class="form-text text-muted">
          {{ 'third-supervisor-justification-note' | translate }}
        </small>
      </div>
    </ng-container>
  </ng-container>

  <p class="form-text"
    [innerHTML]="'do-not-include-foreign-university-supervisor' | translate">
  </p>

  <div class="card form-group"
    *ngIf="us.isStudent()">
    <div class="card-body">
      <div class="form-group mb-0 form-check">
        <input type="checkbox"
          class="form-check-input"
          name="noConflictAgreement"
          id="noConflictAgreement"
          [(ngModel)]="data.noConflictAgreement"
          #noConflictAgreementCheckbox="ngModel"
          required>
        <label class="form-check-label"
          [class.text-danger]="f.submitted && noConflictAgreementCheckbox.invalid"
          for="noConflictAgreement">
          {{ 'cds-no-conflict-agreement-text' | translate }}
        </label>
      </div>
      <validation-errors [show]="f.submitted"
        [control]="noConflictAgreementCheckbox"></validation-errors>
    </div>
  </div>

  <ng-container *ngIf="full">

    <div class="form-group">
      <label for="lineOfResearchSelect">
        {{ 'line-of-research' | translate }}
      </label>
      <ng-select id="lineOfResearchSelect"
        name="lineOfResearchSelect"
        #lineOfResearchSelect="ngModel"
        required
        [items]="linesOfResearch"
        bindValue="_id"
        bindLabel="name"
        [(ngModel)]="data.lineOfResearchId"
        [class.is-invalid]="f.submitted && lineOfResearchSelect.invalid"
        [placeholder]="'select-line-of-research' | translate"
        [searchable]="false"
        [clearable]="false">
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="lineOfResearchSelect"></validation-errors>
    </div>

    <div class="form-group">
      <label for="needSupplementSelect">
        {{ 'needs-supplement' | translate }}
      </label>
      <ng-select id="needSupplementSelect"
        name="needSupplementSelect"
        #needSupplementSelect="ngModel"
        [(ngModel)]="data.needsSupplement"
        required
        [class.is-invalid]="f.submitted && needSupplementSelect.invalid"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="false">{{ 'no' | translate }}</ng-option>
        <ng-option [value]="true">{{ 'yes' | translate }}</ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="needSupplementSelect"></validation-errors>
    </div>

    <ng-container *ngIf="data.needsSupplement">
      <div class="form-group">
        <label for="creditsInput">
          {{ 'Credits' | translate }}
        </label>
        <input type="number"
          name="creditsInput"
          id="creditsInput"
          class="form-control"
          [(ngModel)]="data.supplement.credits"
          #creditsInput="ngModel"
          required
          [placeholder]="'number-of-credits' | translate"
          [class.is-invalid]="f.submitted && creditsInput.invalid">
        <validation-errors [show]="f.submitted"
          [control]="creditsInput"></validation-errors>
      </div>


      <div class="form-group">
        <label>
          {{ 'supplement-document' | translate }}
          <small class="text-muted ml-2">
            {{ 'optional' | translate }}
          </small>
        </label>
        <file-input name="supplementDocumentInput"
          #supplementDocumentInput="ngModel"
          [required]="false"
          [(ngModel)]="data.supplement.document"></file-input>
        <validation-errors [show]="f.submitted"
          [control]="supplementDocumentInput"></validation-errors>
      </div>

      <div class="form-group">
        <label for="supplementNotesInput">
          {{ 'supplement-notes' | translate }}
          <small class="text-muted ml-2">
            {{ 'optional' | translate }}
          </small>
        </label>
        <textarea name="supplementNotesInput"
          id="supplementNotesInput"
          class="form-control"
          [(ngModel)]="data.supplement.notes"
          #supplementNotesInput="ngModel"
          [placeholder]="'supplement-notes' | translate"
          [class.is-invalid]="f.submitted && supplementNotesInput.invalid"></textarea>
        <validation-errors [show]="f.submitted"
          [control]="supplementNotesInput"></validation-errors>
      </div>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="askValidation">

    <div class="form-group text-center">
      <div class="btn-group btn-block">
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-danger]="data.validate === null || data.validate === true"
          [class.btn-danger]="data.validate === false"
          (click)="data.validate = false">
          {{ 'deny' | translate }}
        </button>
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-success]="data.validate === null || data.validate === false"
          [class.btn-success]="data.validate === true"
          (click)="data.validate = true">
          {{ 'validate' | translate }}
        </button>
      </div>
    </div>

    <div class="form-group"
      *ngIf="data.validate === false">
      <label for="denialMsg">
        {{ 'denial-reason' | translate }}
      </label>
      <textarea id="denialMsg"
        name="denialMsg"
        #denialMsgInput="ngModel"
        [(ngModel)]="data.denialMsg"
        [required]="true"
        [placeholder]="'denial-reason-placeholder' | translate"
        [class.is-invalid]="f.submitted && denialMsgInput.invalid"
        class="form-control"></textarea>
      <div class="text-right small text-muted">
        {{ data.denialMsg ? data.denialMsg.length : 0}}/400
      </div>
      <validation-errors [show]="f.submitted"
        [control]="denialMsgInput"></validation-errors>
    </div>

  </ng-container>


  <div class="form-group text-right"
    *ngIf="!askValidation || data.validate !== null">
    <div class="d-block invalid-feedback mb-2"
      *ngIf="f.invalid && f.submitted">
      {{ 'check-errors' | translate }}
    </div>

    <button class="btn btn-primary"
      type="submit"
      *ngIf="canComplete"
      [disableButton]="disabled || sending"
      [disableButtonIcon]="sending ? 'spin' : 'ban'">
      {{ 'send' | translate}}
    </button>

    <div *ngIf="!canComplete">
      <i class="far fa-info-circle mr-1 text-info"></i>
      {{ cantCompleteMsg | translate }}
    </div>
  </div>

</form>