import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { IProgram } from '../../../../../../interfaces/IProgram';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'coordinator-periods',
  templateUrl: './coordinator-periods.component.html',
})
export class CoordinatorPeriodsComponent implements OnInit, OnDestroy {
  program: IProgram;

  sub: Subscription;

  constructor(private ps: ProgramHttpService, private us: UserService) { }

  ngOnInit() {
    this.sub = this.ps.programsObs.subscribe(() => {
      this.program = this.ps.programsMap[this.us.selectedRole.groupId];
    })
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

}
