<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <denial-msgs-view [denialMsgs]="process.denialMsgs"></denial-msgs-view>

  <professor-request-form [linesOfResearch]="linesOfResearch"
    [sending]="sending"
    [data]="data"
    [programId]="process.programId"
    (onSend)="send($event)"></professor-request-form>

</ng-container>