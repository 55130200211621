<div class="container-lg container-fluid px-0 px-md-2"
  *ngIf="process">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card">
      <div class="card-body"
        *ngIf="sr.student">
        <ng-container *ngIf="!requested">

          <h5 class="card-title">
            {{ 'process.evaluation.modify.name' | translate }}
          </h5>

          <p [innerHTML]="'process.evaluation.modify.desc' | translate">
          </p>

          <request-evaluation-form [activitiesIds]="process.activitiesIds"
            [researchPlan]="process.researchPlan"
            [activities]="activities"
            (onSend)="send($event)"
            [disabled]="disabled"
            [student]="sr.student"
            [sending]="sending"></request-evaluation-form>

        </ng-container>
        <div class="w-100 text-center"
          *ngIf="requested">
          <h5 class="card-title m-0">
            <i class="far fa-check text-success mr-1"></i>
            {{ 'request-sended' | translate }}
          </h5>

          <div *ngIf="doc"
            class="mt-4 w-100 text-left">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>