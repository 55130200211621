<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <ng-container *ngIf="!(process.data.cotutelle && process.data.cotutelleDefenceOut)">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'thesis-title' | translate }}
      </div>
      <div>
        {{ process.data.thesisTitle }}
      </div>
    </div>

    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'thesis-final-doc' | translate }}
      </div>
      <div>
        <document-link [document]="process.thesisFinal"></document-link>
      </div>
    </div>

    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'changes-report' | translate }}
      </div>
      <div>
        <document-link [document]="process.changesReport"></document-link>
      </div>
    </div>

    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'thesis-reports' | translate }}
      </div>
      <div *ngFor="let report of process.reports"
        class="mb-1">
        <document-link [document]="report.docRef"></document-link>
      </div>
    </div>
  </ng-container>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <div class="form-group">
      <label for="evaluationSelect">
        {{ 'thesis-final-evaluation' | translate }}
      </label>
      <ng-select id="evaluationSelect"
        name="evaluationSelect"
        #evaluationSelect="ngModel"
        [(ngModel)]="thesisFinalEvaluation"
        required
        [class.is-invalid]="f.submitted && evaluationSelect.invalid"
        [placeholder]="'select-thesis-final-evaluation' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="ThesisFinalEvaluation.fail">
          {{ ThesisFinalEvaluation.fail | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.pass">
          {{ ThesisFinalEvaluation.pass | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.bgrade">
          {{ ThesisFinalEvaluation.bgrade | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.agrade">
          {{ ThesisFinalEvaluation.agrade | translate }}
        </ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="evaluationSelect"></validation-errors>
    </div>

    <div class="form-group"
      *ngIf="thesisFinalEvaluation === ThesisFinalEvaluation.agrade">
      <label for="cumlaudeMention">
        {{ 'cum-laude-mention' | translate }}
      </label>
      <ng-select id="cumlaudeMention"
        name="cumlaudeMention"
        #cumlaudeMentionSelect="ngModel"
        [(ngModel)]="cumlaudeMention"
        [placeholder]="'select-thesis-final-cum-laude-mention' | translate"
        required
        [class.is-invalid]="f.submitted && evaluationSelect.invalid"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="true">
          {{ 'yes' | translate }}
        </ng-option>
        <ng-option [value]="false">
          {{ 'no' | translate }}
        </ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="cumlaudeMentionSelect"></validation-errors>
    </div>

    <div class="form-group">
      <label>{{ 'thesis-defence-record' | translate }}</label>
      <file-input [(ngModel)]="thesisDefenceRecord"
        required
        name="thesisDefenceRecord"
        id="thesisDefenceRecord"
        #thesisDefenceRecordInput="ngModel"
        [invalid]="f.submitted && thesisDefenceRecordInput.invalid"></file-input>
      <validation-errors [control]="thesisDefenceRecordInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="defenceDate">
        {{ 'defence-date' | translate }}
      </label>
      <date-picker id="defenceDate"
        name="defenceDate"
        [(ngModel)]="defenceDate"
        #defenceDateInput="ngModel"
        [isInvalid]="defenceDateInput.invalid && f.submitted"
        [time]="false"
        required="true"></date-picker>
      <validation-errors [show]="f.submitted"
        [control]="defenceDateInput"></validation-errors>
    </div>

    <ng-container *ngIf="!(process.data.cotutelle && process.data.cotutelleDefenceOut)">
      <div class="form-group">
        <label for="internationalMention">
          {{ 'international-mention' | translate }}
        </label>
        <ng-select id="internationalMention"
          name="internationalMention"
          #internationalMentionSelect="ngModel"
          [(ngModel)]="internationalMention"
          [placeholder]="'select-thesis-final-international-mention' | translate"
          required
          [class.is-invalid]="f.submitted && evaluationSelect.invalid"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="true">
            {{ 'yes' | translate }}
          </ng-option>
          <ng-option [value]="false">
            {{ 'no' | translate }}
          </ng-option>
        </ng-select>
        <validation-errors [show]="f.submitted"
          [control]="internationalMentionSelect"></validation-errors>
      </div>

      <div class="form-group">
        <label for="industrialMention">
          {{ 'industrial-mention' | translate }}
        </label>
        <ng-select id="industrialMention"
          name="industrialMention"
          #industrialMentionSelect="ngModel"
          [(ngModel)]="industrialMention"
          [placeholder]="'select-thesis-final-industrial-mention' | translate"
          required
          [class.is-invalid]="f.submitted && evaluationSelect.invalid"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="true">
            {{ 'yes' | translate }}
          </ng-option>
          <ng-option [value]="false">
            {{ 'no' | translate }}
          </ng-option>
        </ng-select>
        <validation-errors [show]="f.submitted"
          [control]="industrialMentionSelect"></validation-errors>
      </div>

      <div class="form-group">
        <label for="president">
          {{ 'president' | translate }}
        </label>
        <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="president"
          name="president"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-president' | translate"
          #presidentSelect="ngModel"
          [class.is-invalid]="presidentSelect.invalid && f.submitted"
          [ngModel]="presidentId"
          (ngModelChange)="assignPresident($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="presidentSelect"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="secretary">
          {{ 'secretary' | translate }}
        </label>
        <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="secretary"
          name="secretary"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-secretary' | translate"
          #secretarySelect="ngModel"
          [class.is-invalid]="secretarySelect.invalid && f.submitted"
          [(ngModel)]="secretaryId"
          (ngModelChange)="assignSecretary($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="secretarySelect"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="member">
          {{ 'member' | translate }}
        </label>
        <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="member"
          name="member"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-member' | translate"
          #memberSelect="ngModel"
          [class.is-invalid]="memberSelect.invalid && f.submitted"
          [(ngModel)]="memberId"
          (ngModelChange)="assignMember($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="memberSelect"
          [show]="f.submitted"></validation-errors>
      </div>
    </ng-container>

    <div class="form-group text-right">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>


</ng-container>