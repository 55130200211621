<div class="list-group">

  <div class="list-group-item"
    *ngFor="let activity of activities; trackBy:tbFn"
    [@listAnimation]="activities.length"
    [smoothHeight]="open[activity._id]">

    <div class="d-flex justify-content-between align-items-center c-pointer">
      <!-- Closed -->
      <div *ngIf="!open[activity._id]"
        class="flex-fill text-nowrap text-truncate mr-1"
        (click)="open[activity._id] = true">
        <span class="text-primary mr-1">
          {{ activity.order }}. {{ activity.activityType | translate }}
        </span>
        <span class="small">
          {{ activity.title }}
        </span>
      </div>
      <div *ngIf="!open[activity._id]"
        class="text-nowrap text-muted small"
        (click)="open[activity._id] = false">
        <ng-container *ngIf="activity.startDate">
          {{ activity.startDate | moment:'DD/MM/YY'}}
        </ng-container>
        <span class="mx-1"
          *ngIf="activity.startDate && activity.endDate">
          -
        </span>
        <ng-container *ngIf="activity.endDate">
          {{ activity.endDate | moment:'DD/MM/YY'}}
        </ng-container>
        <ng-container *ngIf="!isAutomaticType(activity) && activity.validated">
          <span class="ml-2 text-success"
            [tooltip]="'validated-by-tutor' | translate"
            *ngIf="!activity.evaluated">
            <i class="fas fa-check fa-fw"></i>
          </span>
          <span class="ml-2 text-info"
            [tooltip]="'evaluated' | translate"
            *ngIf="activity.evaluated">
            <i class="fas fa-check-double fa-fw"></i>
          </span>
        </ng-container>
        <span class="badge badge-dark ml-2"
          *ngIf="!activity.validated && activity.validationState == 'Draft'">
          {{ 'draft-not-validated' | translate }}
        </span>
        <span class="badge badge-warning ml-2"
          *ngIf="!activity.validated && activity.validationState == 'PendingTutorValidation'">
          {{ 'pending' | translate }}
        </span>
        <span class="badge badge-danger ml-2"
          *ngIf="!activity.validated && activity.validationState == 'PendingStudentReview'">
          {{ 'review' | translate }}
        </span>
      </div>


      <!-- Opened -->
      <div class="flex-fill text-primary mr-1"
        *ngIf="open[activity._id]"
        (click)="open[activity._id] = false">
        {{ activity.order }}. {{ activity.activityType | translate }}
      </div>

      <div
        *ngIf="editable && open[activity._id] && !activity.validated && (activity.validationState == 'Draft' || activity.validationState == 'PendingStudentReview')">
        <span class="text-muted mr-3 btn-icon"
          [tooltip]="'add-evidences' | translate"
          (click)="onAddDocuments.emit(activity)">
          <i class="far fa-file-plus"></i>
        </span>
        <span class="text-muted mr-3 btn-icon"
          [tooltip]="'edit-activity' | translate"
          (click)="onEditActivity.emit(activity)">
          <i class="far fa-edit"></i>
        </span>
        <span class="text-muted btn-icon"
          [tooltip]="'remove' | translate"
          (click)="onRemoveActivity.emit(activity)">
          <i class="far fa-trash"></i>
        </span>
        <span class="badge badge-dark ml-2"
          style="cursor: default !important"
          *ngIf="activity.validationState == 'Draft'">
          {{ 'draft-not-validated-pending' | translate }}
        </span>
      </div>
      <div class="badge badge-success"
        style="cursor: default !important"
        *ngIf="open[activity._id] && activity.validated && !activity.evaluated && !isAutomaticType(activity)">
        <span>
          <i class="fas fa-check mr-1"></i>
          {{ 'validated-by-tutor' | translate }}
        </span>
      </div>
      <div class="badge badge-info"
        style="cursor: default !important"
        *ngIf="open[activity._id] && activity.validated && activity.evaluated && !isAutomaticType(activity)">
        <span>
          <i class="fas fa-check-double mr-1"></i>
          {{ 'evaluated' | translate }}
        </span>
      </div>
      <div class="badge badge-warning"
        style="cursor: default !important"
        *ngIf="open[activity._id] && activity.validationState == 'PendingTutorValidation'">
        <i class="far fa-hourglass mr-1"></i>
        {{ 'pending-tutor-validation' | translate }}
      </div>
      <div class="badge badge-danger ml-3"
        style="cursor: default !important"
        *ngIf="open[activity._id] && activity.validationState == 'PendingStudentReview'">
        <i class="fas fa-exclamation mr-1"></i>
        {{ 'needs-review' | translate }}
      </div>
    </div>
    <ng-container *ngIf="open[activity._id]">
      <div class="text-nowrap text-muted small mb-1 mt-2">
        <ng-container *ngIf="activity.startDate">
          {{ activity.startDate | moment:'DD/MM/YY'}}
        </ng-container>
        <span class="mx-1"
          *ngIf="activity.startDate && activity.endDate">
          -
        </span>
        <ng-container *ngIf="activity.endDate">
          {{ activity.endDate | moment:'DD/MM/YY'}}
        </ng-container>
      </div>
      <div class="mb-1"
        style="text-decoration: underline;">
        {{ activity.title }}
      </div>
      <div class="pre">
        <span [innerHTML]="activity.description"></span>
      </div>
      <div class="list-group list-group-flush mt-2"
        *ngIf="activity.attached.length > 0">
        <div class="list-group-item list-group-item-action small d-flex justify-content-between align-items-center"
          [@listAnimation]="activity.attached.length"
          *ngFor="let document of activity.attached; trackBy:tbFnAttached">
          <a [routerLink]="null"
            class="text-nowrap text-truncate"
            (click)="onDownloadFile.emit(document.documentRef)">
            <file-icon [fileName]="document.fileName"></file-icon>
            {{ document.fileName }}
          </a>
          <div class="text-nowrap">
            <span class="text-muted mr-3 btn-icon"
              (click)="onDetails.emit({ activity: activity, document: document })"
              [tooltip]="'details' | translate">
              <i class="far fa-info-circle fa-fw"></i>
            </span>
            <span class="text-muted mr-3 btn-icon"
              (click)="onVersionHistoric.emit({ activity: activity, document: document })"
              [tooltip]="'version-historic' | translate">
              <i class="far fa-history fa-fw"></i>
            </span>
            <span class="text-muted mr-3 btn-icon"
              *ngIf="editable && (activity.validationState === 'Draft' || activity.validationState === 'PendingStudentReview')"
              (click)="onUploadVersion.emit({ activity: activity, document: document })"
              [tooltip]="'change-document' | translate">
              <i class="far fa-file-edit fa-fw"></i>
            </span>
            <span class="text-muted mr-3 btn-icon"
              *ngIf="editable && (activity.validationState === 'Draft' || activity.validationState === 'PendingStudentReview')"
              (click)="onRemoveDocument.emit({ activity: activity, document: document })"
              [tooltip]="'remove' | translate">
              <i class="far fa-trash fa-fw"></i>
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="activity.validationMsg"
        class="mt-3">
        <div>{{ 'tutor-commentary' | translate }}:</div>

        <div class="pre alert alert-light"
          [innerHTML]="activity.validationMsg"></div>
      </div>
      <div class="text-right"
        *ngIf="editable && showRequestValidation">
        <button type="button"
          class="btn btn-sm btn-outline-primary mt-3 mb-1"
          (click)="onRequestValidation.emit(activity)"
          *ngIf="activity.validationState === 'Draft'">
          <i class="far fa-user-check mr-1"></i>
          {{ 'request-validation-by-tutor' | translate }}
        </button>
        <button type="button"
          class="btn btn-sm btn-outline-primary mt-3 mb-1"
          (click)="onResendValidation.emit(activity)"
          *ngIf="activity.validationState === 'PendingStudentReview'">
          <i class="far fa-user-check mr-1"></i>
          {{ 'resend-validation' | translate }}
        </button>
        <button type="button"
          class="btn btn-sm btn-link text-muted mt-3 mb-1"
          (click)="onModify.emit(activity)"
          *ngIf="canModify(activity)">
          <i class="far fa-edit mr-1"></i>
          {{ 'modify-activity' | translate }}
        </button>
      </div>
    </ng-container>
  </div>

</div>