import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { NgForm } from '@angular/forms';
import { ITask } from '../../../../../../../interfaces/ITask';
import { IPermanentLeaveProcess } from '../../../../../../../interfaces/IPermanentLeaveProcess';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { PermanentLeaveHttpService } from '../../../../../data-services/permanent-leave-http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'permanent-leave-coordinator-report',
  templateUrl: './permanent-leave-coordinator-report.component.html',
})
export class PermanentLeaveCoordinatorReportComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  process: IPermanentLeaveProcess;

  report: IDocumentRef;

  constructor(private pls: PermanentLeaveHttpService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.pls.findProcess(this.task._id).subscribe((process) => {
      console.log(process);
      this.process = process;
    })
  }

  manageSend() {
    if (this.f.invalid) {
      this.sendEnd.next();
      return;
    }

    this.pls.coordinatorReport(this.task._id, this.report).subscribe(() => {
      this.sendEnd.next();
      void this.router.navigate(['/app/task-completed']);
    });
  }

}
