import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDocumentRef } from '../../../interfaces/IDocument';
import { IThesisProcess, IThesisProcessData, ThesisDefenceModality, ThesisFinalEvaluation } from '../../../interfaces/IThesisProcess';
import { IProcessHistory } from '../../../interfaces/IProcessHistory';

@Injectable()
export class ThesisHttpService {
  constructor(private http: HttpClient) { }

  request(studentId: string, data: IThesisProcessData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/thesis/request/${studentId}`, { data });
  }

  getTable(studentId: string): Observable<IThesisProcess> {
    return this.http.get<IThesisProcess>(`/api/thesis/process/${studentId}`);
  }

  findHistory(studentId: string): Observable<IProcessHistory> {
    return this.http.get<IProcessHistory>(`/api/thesis/history/${studentId}`);
  }

  findProcess(taskId: string): Observable<IThesisProcess> {
    return this.http.get<IThesisProcess>(`/api/thesis/findProcess/${taskId}`);
  }

  secretaryValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/secretaryValidate/${taskId}`, {});
  }

  secretaryDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/secretaryDeny/${taskId}`, data);
  }

  directionTeamValidate(taskId: string, compendiumDirectorReport: IDocumentRef[]): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/directionTeamValidate/${taskId}`, { compendiumDirectorReport });
  }

  directionTeamDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/directionTeamDeny/${taskId}`, data);
  }

  studentRequestReview(taskId: string, data: IThesisProcessData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/thesis/studentRequestReview/${taskId}`, { data });
  }

  acValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/acValidate/${taskId}`, {});
  }

  acDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/acDeny/${taskId}`, data);
  }

  dsPrincipalValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/dspValidate/${taskId}`, {});
  }

  dsPrincipalDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/dspDeny/${taskId}`, data);
  }

  serviceValidate(taskId: string, data: IThesisProcessData, valiationMsg?: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/serviceValidate/${taskId}`, { data, valiationMsg });
  }

  serviceDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/serviceDeny/${taskId}`, data);
  }

  pgcsValidate(taskId: string, data: {
    firstEvaluator: boolean, secondEvaluator: boolean, thirdEvaluator: boolean
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/pgcsValidate/${taskId}`, data);
  }

  pgcsDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/pgcsDeny/${taskId}`, data);
  }

  directionRejectReportDecision(taskId: string, doContinue: boolean): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/directionRejectReportDecision/${taskId}`, { doContinue });
  }

  studentDefenceRequest(taskId: string, thesisFinal: IDocumentRef, changesReport: IDocumentRef): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/thesis/studentDefenceRequest/${taskId}`, { thesisFinal, changesReport });
  }

  defenceDirectionTeamValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/defenceDirectionTeamValidate/${taskId}`, {});
  }

  defenceDirectionTeamDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/defenceDirectionTeamDeny/${taskId}`, data);
  }

  defencePgcValidate(taskId: string, pgcApprobalDate): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/defencePgcValidate/${taskId}`, { pgcApprobalDate });
  }

  defencePgcDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/defencePgcDeny/${taskId}`, data);
  }

  serviceAskForCumLaudeVotes(processId: string, data: {
    presidentId: string,
    presidentModality: ThesisDefenceModality,
    secretaryId: string,
    secretaryModality: ThesisDefenceModality,
    memberId: string,
    memberModality: ThesisDefenceModality
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/serviceAskForCumLaudeVotes/${processId}`, data);
  }

  serviceStopCumLaudeVotes(processId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/serviceStopCumLaudeVotes/${processId}`, {});
  }

  serviceRegisterDefence(taskId: string, data: {
    defenceDate: Date,
    thesisFinalEvaluation: ThesisFinalEvaluation,
    cumlaudeMention: boolean,
    thesisDefenceRecord: IDocumentRef,
    internationalMention: boolean,
    industrialMention: boolean,
    presidentId: string,
    secretaryId: string,
    memberId: string
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/thesis/serviceRegisterDefence/${taskId}`, data);
  }

}