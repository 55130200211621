<div class="container-fluid">

  <div class="row">
    <div class="col">

      <section-title text="service"
        icon="home">
      </section-title>

    </div>
  </div>

</div>