import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IDirectionProcess, IDirectionRequestData } from '../../../../../../../interfaces/IDirectionProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { DirectionHttpService } from '../../../../../data-services/direction-http.service';
import { ProgramHttpService } from '../../../../../data-services/program-http.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { UserService } from '../../../../../user/user.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'direction-service-validation-task',
  templateUrl: './direction-service-validation-task.component.html',
})
export class DirectionServiceValidationTaskComponent extends AbstractTaskComponent implements OnInit {

  @Input() task: ITask;

  process: IDirectionProcess;

  denialMsg = '';

  data: IDirectionRequestData = {
    validate: null,
    tutor: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor1: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor2: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor3: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
  };

  enableSupervisor2 = false;
  enableSupervisor3 = false;

  constructor(private direction: DirectionHttpService, private ps: ProgramHttpService,
    private us: UserService,
    private rs: RefreshService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.direction.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      if (process.tutor) {
        this.data.tutor = process.tutor;
      }
      if (process.supervisor1) {
        this.data.supervisor1 = process.supervisor1;
      }
      if (process.supervisor2) {
        this.data.supervisor2 = process.supervisor2;
        this.enableSupervisor2 = true;
      }
      if (process.supervisor3) {
        this.data.supervisor3 = process.supervisor3;
        this.enableSupervisor3 = true;
      }
      if (process.thirdSupervisorDoc) {
        this.data.thirdSupervisorDoc = process.thirdSupervisorDoc;
      }

    })
  }

  manageSend(data: IDirectionRequestData) {
    if (!data.validate) {
      this.direction.serviceDenyValidation(this.task._id, { denialMsg: data.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.direction.serviceValidation(this.task._id, data).subscribe((res) => {
        this.sendEnd.next();
        if (res.followUpTaskId) {
          void this.router.navigate([`/app/task/${res.followUpTaskId}`]);
          this.rs.onMessagesChange.next();
          this.rs.onTasksChange.next();
        } else {
          void this.router.navigate(['/app/task-completed']);
        }
      });
    }
  }

}
