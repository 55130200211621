import { Component, OnInit } from '@angular/core';

import { IExternalUser } from '../../../../../../interfaces/IExternalUser';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';

@Component({
  selector: 'external-users',
  templateUrl: './external-users.component.html',
})
export class ExternalUsersComponent implements OnInit {
  users: IExternalUser[] = [];

  params = {
    searchText: ''
  }

  constructor(private sh: ServiceHttpService, public hs: HelperService) { }

  fetchFn() {
    return (params) => {
      params = {
        ...params,
        ...this.params
      }
      return this.sh.fetchExternals(params);
    }
  }

  ngOnInit() {
  }

}
