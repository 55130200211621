<ng-container *ngIf="process">
  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'student' | translate }}
    </div>
    <div>
      {{ process.student.user.fullName }}
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'program' | translate }}
    </div>
    <div>
      {{ process.student.program.name }}
    </div>
  </div>

  <div *ngIf="process.tutor"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'tutor' | translate }}
    </div>
    <user-info-view [userInfo]="process.tutor"></user-info-view>
  </div>

  <div *ngIf="process.supervisor1"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.supervisor1"></user-info-view>
  </div>

  <div *ngIf="process.supervisor2"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'second-supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.supervisor2"></user-info-view>
  </div>

  <div *ngIf="process.supervisor3"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'third-supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.supervisor3"></user-info-view>
  </div>

  <div *ngIf="process.supervisor3 && process.thirdSupervisorDoc"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'third-supervisor-justification' | translate }}
    </div>
    <div>
      <document-link [document]="process.thirdSupervisorDoc"></document-link>
    </div>
  </div>

</ng-container>