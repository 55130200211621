<div class="card"
  *ngIf="student.extraExtensions.length > 0 || (sh.editMode && us.isService())">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <span>
        {{ 'extra-extensions' | translate }}
      </span>
      <button type="button"
        class="btn btn-link"
        *ngIf="us.isService() && sh.editMode"
        (click)="reset(); open = true">
        {{ 'add' | translate }}
      </button>
    </legend>
  </div>
  <div class="list-group"
    *ngIf="extensions && extensions.length > 0">
    <div class="list-group-item d-flex justify-content-between align-items-center"
      *ngFor="let ext of extensions">
      <div>
        <div>
          <strong>
            {{ ext.description | translate }}
          </strong>
        </div>
        <div *ngIf="ext.justification">
          <div *ngFor="let doc of ext.justification">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>
      <div>
        {{ ext.start | moment:'D/M/YY' }}
        <i class="far fa-angle-double-right mx-2"></i>
        {{ ext.end | moment:'D/M/YY' }}

        <a role="button"
          [routerLink]="null"
          class="text-muted ml-2"
          (click)="openEdit(ext._id)"
          *ngIf="us.isService() && sh.editMode">
          <i class="far fa-edit"></i>
        </a>

        <a role="button"
          [routerLink]="null"
          class="text-muted ml-2"
          (click)="remove(ext._id)"
          *ngIf="us.isService() && sh.editMode">
          <i class="far fa-trash"></i>
        </a>
      </div>
    </div>
  </div>
  <div *ngIf="extensions && extensions.length <= 0"
    class="card-body pt-0">
    <i>
      {{ 'no-extra-extensions' | translate }}
    </i>
  </div>
</div>

<modal [(open)]="open"
  [title]="(extensionId ? 'edit-extension' : 'add-extension') | translate">
  <form #f="ngForm"
    id="extraExtensionsForm"
    (ngSubmit)="save()"
    *ngIf="open">
    <div class="form-group">
      <label>{{ 'start-date' | translate }}</label>
      <date-picker [(ngModel)]="startDate"
        name="startDate"
        #startDateInput="ngModel"
        [isInvalid]="f.submitted && startDateInput.invalid"
        [required]="true"></date-picker>
      <validation-errors [control]="startDateInput"
        [show]="f.submitted"></validation-errors>
    </div>
    <div class="form-group">
      <label>{{ 'end-date' | translate }}</label>
      <date-picker [(ngModel)]="endDate"
        name="endDate"
        #endDateInput="ngModel"
        [isInvalid]="f.submitted && endDateInput.invalid"
        [required]="true"></date-picker>
      <validation-errors [control]="endDateInput"
        [show]="f.submitted"></validation-errors>
    </div>
    <div class="form-group">
      <label>{{ 'description' | translate }}</label>
      <input type="text"
        name="description"
        #descriptionInput="ngModel"
        [(ngModel)]="description"
        class="form-control"
        [placeholder]="'description' | translate"
        required>
      <validation-errors [control]="descriptionInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label>{{ 'justification' | translate }}</label>
      <files-queue [(ngModel)]="justification"
        name="justification"
        #justificationInput="ngModel"
        [required]="false"></files-queue>
      <validation-errors [control]="justificationInput"
        [show]="f.submitted"></validation-errors>
    </div>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="extraExtensionsForm">{{ 'save' | translate }}</button>
  </div>

</modal>