<div class="wrapper">
  <nav class="sidebar"
    [class.active]="active">
    <div class="sidebar-header">
      <h4 class="logo text-white">
        DAD
      </h4>
    </div>

    <div class="user-info mb-2">
      <span class="user-badge rounded-circle depth2">
        <i class="fas fa-user"></i>
      </span>
      <div class="mt-2">
        {{ us.name() }}
      </div>
      <div class="mt-2 small text-info"
        *ngIf="is.impersonating">
        {{ is.impersonating | fullname }}
        <div class="mt-1">
          <button type="button"
            class="btn btn-sm btn-warning"
            (click)="stopImpersonate()">
            {{ 'stop-impersonating' | translate }}
          </button>
        </div>
      </div>

    </div>

    <dad-menu></dad-menu>

    <div class="sidebar-footer text-center">
      <img src="assets/uma_logo_inverted.png"
        width="130" />
    </div>

  </nav>

  <div class="content">
    <navbar [active]="active"
      (activeChange)="toggle($event)"></navbar>

    <div class="page-outlet">
      <div *ngIf="active"
        class="overlay"
        [class.active]="active"
        (click)="deactivate()"></div>

      <router-outlet></router-outlet>

    </div>
  </div>

</div>

<pop-alerts></pop-alerts>

<loading-indicator></loading-indicator>

<confirmation-dialog></confirmation-dialog>

<accept-good-practice-code-modal #acceptGPCModal></accept-good-practice-code-modal>

<send-email-modal></send-email-modal>