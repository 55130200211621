import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'text-info',
  templateUrl: 'text-info.component.html'
})

export class TextInfoComponent implements OnInit {

  @Input() icon;
  @Input() iconType = 'r';
  @Input() mb = 2;

  constructor() { }

  ngOnInit() { }
}