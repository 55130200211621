<div class="list-group"
  *ngIf="evaluations && evaluations.length > 0">
  <div class="list-group-item d-flex justify-content-between align-items-center"
    [class.list-group-item-action]="!editable"
    *ngFor="let evaluation of evaluations"
    (click)="manageRowClick(evaluation)">

    <div>
      <strong>
        {{ evaluation.year | evaluationYear }}
      </strong>
      - {{ evaluation.period | translate }}
    </div>

    <div class="text-right">
      <span class="badge"
        [class.badge-success]="evaluation.evaluation === EvaluationType.positive"
        [class.badge-danger]="evaluation.evaluation !== EvaluationType.positive">
        {{ evaluation.evaluation | translate }}
      </span>
      <span class="ml-1 text-muted"
        *ngIf="!editable">
        <i class="far fa-file-search"></i>
      </span>
      <a role="button"
        [routerLink]="null"
        class="text-muted ml-2"
        (click)="onEdit.emit(evaluation._id)"
        *ngIf="editable">
        <i class="far fa-edit"></i>
      </a>

      <a role="button"
        [routerLink]="null"
        class="text-muted ml-2"
        (click)="onRemove.emit(evaluation)"
        *ngIf="editable">
        <i class="far fa-trash"></i>
      </a>
    </div>
  </div>
</div>
<div class="card-body pt-0"
  *ngIf="evaluations && evaluations.length <= 0">
  <i>
    {{ 'no-evaluations' | translate }}
  </i>
</div>

<modal [(open)]="open"
  [title]="'annual-evaluation' | translate">

  <ng-container *ngIf="details">
    <div>
      <strong>
        {{ details.year | evaluationYear }}
      </strong>
      - {{ details.period | translate }}
    </div>

    <div class="mt-2 mb-2">
      {{ details.evaluation | translate }}
    </div>

    <ul class="list-unstyled">
      <li *ngIf="details.recordFile"
        class="mb-1">
        <document-link [document]="details.recordFile"></document-link>
        <ng-container *ngIf="us.isService() && showRegenerate">
          <button class="btn btn-sm btn-outline-info mt-1 mb-3"
            type="button"
            (click)="regenerate(details)">
            Volver a generar el acta de evaluación
          </button>
        </ng-container>
      </li>
      <li *ngIf="details.committeeReport"
        class="mb-1">
        <document-link [document]="details.committeeReport"></document-link>
      </li>
      <li *ngIf="details.researchPlan"
        class="mb-1">
        <document-link [document]="details.researchPlan"></document-link>
      </li>
      <li *ngFor="let doc of details.reports"
        class="mb-1">
        <document-link [document]="doc"></document-link>
      </li>
    </ul>

    <ng-container *ngIf="details.oldRecordFiles && details.oldRecordFiles.length > 0">
      <div class="mt-2 mb-2">
        Actas anteriormente generadas
      </div>
      <ul class="list-unstyled">
        <li *ngFor="let doc of details.oldRecordFiles"
          class="mb-1">
          <document-link [document]="doc"></document-link>
        </li>
      </ul>
    </ng-container>

    <div class="mb-2"
      *ngIf="details.presidentId && details.president">
      <div class="small text-muted">
        {{ 'president' | translate }}
      </div>
      <user-file-link [user]="details.president"></user-file-link>
    </div>
    <div class="mb-2"
      *ngIf="details.secretaryId && details.secretary">
      <div class="small text-muted">
        {{ 'secretary' | translate }}
      </div>
      <user-file-link [user]="details.secretary"></user-file-link>
    </div>
    <div class="mb-2"
      *ngIf="details.memberId && details.member">
      <div class="small text-muted">
        {{ 'member' | translate }}
      </div>
      <user-file-link [user]="details.member"></user-file-link>
    </div>
  </ng-container>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'close' | translate }}</button>
  </div>

</modal>