<div class="container"
  *ngIf="sr.student">

  <div class="row">
    <div class="col">

      <section-title [text]="'situation' | translate"
        [icon]="'home'"></section-title>

    </div>
  </div>

  <student-info [student]="sr.student"></student-info>

</div>