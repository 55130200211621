<div *ngIf="faculty"
  class="container-fluid container-md">

  <div class="mb-2">
    <a class="text-muted"
      [routerLink]="['../']">
      <i class="far fa-angle-left"></i>
      {{ 'go-back' | translate }}
    </a>
  </div>
  <h2 class="mb-4 text-nowrap text-truncate">
    <i class="far fa-building mr-2 text-secondary"></i>
    {{ faculty.name }}
  </h2>

  <div class="row">

    <div class="col-12 col-lg-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title d-flex justify-content-between align-items-center">
            <div>
              {{ faculty.name }}
            </div>
            <div>
              <a [routerLink]="null"
                role="button"
                class="text-muted mr-2"
                [tooltip]="'edit' | translate"
                (click)="openEditModal()">
                <i class="far fa-edit"></i>
              </a>
            </div>
          </h5>

          <legend>
            Director
          </legend>

          <text-info icon="user"
            *ngIf="faculty.director">
            <user-file-link [user]="faculty.director"></user-file-link>
          </text-info>

          <div *ngIf="!faculty.director">
            <i>No hay director asignado</i>
          </div>

          <legend>
            {{ 'chiefSecretary' | translate }}
          </legend>

          <text-info icon="user"
            *ngIf="chiefSecretary">
            <user-file-link [user]="chiefSecretary"></user-file-link>
          </text-info>

          <div *ngIf="!chiefSecretary">
            <i>No hay secretario administrador asignado</i>
          </div>

        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header">
          {{ 'programs' | translate }}
        </div>
        <div class="list-group">
          <div class="list-group-item list-group-item-action c-pointer"
            *ngFor="let program of programs"
            [routerLink]="['/app/service/programs/' + program._id]">
            {{ program.name }}
          </div>
          <div class="list-group-item"
            *ngIf="programs.length <= 0">
            <i>
              No hay programas de doctorado para este centro
            </i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div class="card">
        <div class="card-header">
          Secretaría
        </div>
        <div class="list-group">
          <div class="list-group-item d-flex justify-content-between align-items-center"
            *ngFor="let secretary of faculty.secretaries">
            <div>
              <user-file-link [user]="secretary"></user-file-link>
            </div>
            <div>
              <div>
                <a [routerLink]="null"
                  role="button"
                  class="text-muted"
                  *ngIf="!chiefSecretary || secretary._id !== chiefSecretary._id"
                  [tooltip]="'remove' | translate"
                  (click)="removeSecretary(secretary)">
                  <i class="far fa-times"></i>
                </a>
                <span class="badge badge-info"
                  *ngIf="chiefSecretary && secretary._id === chiefSecretary._id">
                  {{ 'chiefSecretary' | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="list-group-item"
            *ngIf="faculty.secretaries.length <= 0">
            <i>
              No hay usuarios asignados
            </i>
          </div>
          <div class="list-group-item"
            *ngIf="us.isService()">
            <user-selector #addUserSelector
              [sm]="true"
              [(ngModel)]="addUser"
              [name]="'addUser'"></user-selector>
            <div class="text-right">
              <button [routerLink]="null"
                role="button"
                class="btn btn-sm"
                [class.btn-outline-primary]="!addUser"
                [class.btn-primary]="addUser"
                (click)="addSecretary()"
                [disabled]="!addUser">
                <i class="far fa-plus mr-1"></i>
                {{ 'add' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>

<modal [title]="'edit-faculty' | translate"
  [(open)]="editOpen">
  <form id="editForm"
    (ngSubmit)="save()"
    *ngIf="facultyEdit"
    #editForm="ngForm">
    <div class="form-group">
      <label for="programName">
        {{ 'name' | translate}}
      </label>
      <input type="text"
        class="form-control"
        [placeholder]="'program-name' | translate"
        id="programName"
        name="programName"
        #nameInput="ngModel"
        required
        [class.is-invalid]="editForm.submitted && nameInput.invalid"
        [(ngModel)]="facultyEdit.name">
      <validation-errors [show]="editForm.submitted"
        [control]="nameInput"></validation-errors>
    </div>
    <div class="form-group">
      <label for="directorUserSelector">
        Director
      </label>
      <user-selector #directorUserSelector="ngModel"
        [sm]="true"
        [(ngModel)]="director"
        [required]="false"
        [name]="'directorUserSelector'"></user-selector>
      <validation-errors [show]="editForm.submitted"
        [control]="directorUserSelector"></validation-errors>
    </div>
    <div class="form-group">
      <label for="chiefSecretarySelect">
        {{ 'chiefSecretary' | translate }}
      </label>
      <ng-select id="chiefSecretarySelect"
        name="chiefSecretarySelect"
        #chiefSecretarySelect
        [items]="faculty.secretaries"
        bindValue="_id"
        bindLabel="name"
        [(ngModel)]="facultyEdit.chiefSecretaryId"
        [required]="true"
        [searchable]="true"
        [clearable]="false">
        <ng-template ng-option-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
      </ng-select>
      <validation-errors [show]="editForm.submitted"
        [control]="chiefSecretarySelect"></validation-errors>
    </div>
  </form>
  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="editOpen = false">
      {{ 'cancel' | translate }}
    </button>
    <button class="btn btn-primary"
      type="submit"
      form="editForm">
      {{ 'save' | translate }}
    </button>

  </div>
</modal>