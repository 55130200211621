import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../user/user.service';
import { AppService } from '../app.service';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {

  checked = false;

  constructor(private us: UserService, private router: Router, public appServ: AppService) { }

  ngOnInit() {
    this.us.isLoggedIn().subscribe((loggedIn) => {
      if (loggedIn) {
        void this.router.navigate(['/app']);
      } else {
        this.checked = true;
      }
    });
  }

  login() {
    this.us.login();
  }
}