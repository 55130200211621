import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

import { IStudent, RDType, StudentStateType } from '../../../../../../interfaces/IStudent';
import { RefreshService } from '../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'student-info',
  templateUrl: 'student-info.component.html'
})
export class StudentInfoComponent implements OnInit {
  @Input() student: IStudent;

  StudentStateType = StudentStateType;
  RDType = RDType;

  checkEnrollmentsLoading = false;

  constructor(public us: UserService, public ss: ServiceHttpService,
    private refresh: RefreshService) { }

  ngOnInit() {
  }

  deadlineClass() {
    if (!this.student.deadline) return '';
    let res = 'alert-info';
    let days = moment(this.student.deadline).diff(moment(), 'days');
    if (days > 0 && days < 60) {
      res = 'alert-warning';
    } else if (days <= 0) {
      res = 'alert-danger'
    }
    return res;
  }

  checkEnrollments() {
    this.checkEnrollmentsLoading = true;
    this.ss.checkStudentEnrollments(this.student._id).subscribe(() => {
      this.refresh.onStudentChange.next();
      this.checkEnrollmentsLoading = false;
    })
  }

}