import { Component, Input, OnInit } from '@angular/core';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { ITask } from '../../../../../../../interfaces/ITask';
import { IProfessorProcess, IProfessorProcessData } from '../../../../../../../interfaces/IProfessorProcess';
import { ProfessorHttpService } from '../../../../../data-services/professor-http.service';
import { Router } from '@angular/router';
import { ILineOfResearch } from '../../../../../../../interfaces/ILineOfResearch';
import { ProgramHttpService } from '../../../../../data-services/program-http.service';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { DocumentService } from '../../../../../shared/document-service/document.service';

@Component({
  selector: 'professor-coordinator-review',
  templateUrl: './professor-coordinator-review.component.html',
})
export class ProfessorCoordinatorReviewComponent extends AbstractTaskComponent implements OnInit {

  @Input() task: ITask;
  process: IProfessorProcess;
  data: IProfessorProcessData;

  doc: IDocumentRef;

  linesOfResearch: ILineOfResearch[] = [];

  constructor(private pfs: ProfessorHttpService,
    private ps: ProgramHttpService,
    private router: Router,
    private ds: DocumentService
  ) {
    super();
  }

  ngOnInit() {
    this.pfs.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
      this.data = {
        external: process.external,
        idNum: process.idNum,
        firstName: process.firstName,
        lastName: process.lastName,
        email: process.email,
        category: process.category,
        lineOfResearchId: process.lineOfResearchId,
        curriculumLink: process.curriculumLink,
        reason: process.reason,
        sixyearDoc: process.sixyearDoc,
        thesisCount: process.thesisCount,
        orcid: process.orcid,
        otherProfile: process.otherProfile,
        university: process.university,
        otherProgram: process.otherProgram,
        otherProgramName: process.otherProgramName,
        otherProgramUniversity: process.otherProgramUniversity,
      }
      this.ps.linesOfResearch(process.programId).subscribe((lines) => {
        this.linesOfResearch = lines;
      });
    })
  }

  manageSend(data: IProfessorProcessData) {
    this.pfs.coordinatorReview(this.task._id, data).subscribe((docRef) => {
      this.sendEnd.next();
      this.doc = docRef;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
      void this.router.navigate(['/app/task-completed'], { queryParams: { docRef: this.doc.documentRef } });
    });
  }

}
