import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IResultList } from '../../../../interfaces/IResultList';
import { LoadingIndicatorService } from '../loading-indicator/loading-indicator.service';

@Component({
  selector: 'infinite-list',
  templateUrl: 'infinite-list.component.html'
})
export class InfiniteListComponent implements OnInit, OnDestroy {
  @Input() container: ElementRef;

  @Input() data: (params) => Observable<Array<any> | IResultList>;
  @Input() params: { [key: string]: any } = {};

  @Input() elementsHeight = 50;

  @Input() array: Array<any> = [];
  @Output() arrayChange = new EventEmitter<Array<any>>();

  scrollSub: Subscription;
  resizeSub: Subscription;
  showLoadMoreButton = false;

  size: number;
  page = 1;

  allLoaded = false;
  loadingMore = false;

  totalCount = null;

  element;
  scrollElement;
  scrollReference;

  constructor(private loading: LoadingIndicatorService) { }

  ngOnInit() {
    if (!this.container) {
      this.element = document.documentElement;
      this.scrollElement = window;
      this.scrollReference = document.body;
    } else {
      this.element = this.container;
      this.scrollElement = this.element;
      this.scrollReference = this.element;
    }
    this.size = Math.ceil((this.element.clientHeight - 150) / this.elementsHeight) + 5;
    if (this.size > 100) this.size = 100;
    this.page = 1;
    this.allLoaded = false;

    let params = {
      ...this.params,
      size: this.size,
      page: this.page
    };

    this.loading.start();
    this.data(params).subscribe((result) => {
      if (result instanceof Array) {
        this.array = result;
      } else {
        this.array = result.list;
        this.totalCount = result.totalCount;
      }
      this.arrayChange.emit(this.array);
      if (this.array.length < this.size) {
        this.allLoaded = true;
      }
      this.loading.stop();
    })

    this.scrollSub = fromEvent(this.scrollElement, 'scroll').pipe(debounceTime(200)).subscribe(($event) => {
      let h = this.element,
        b = this.scrollReference,
        st = 'scrollTop',
        sh = 'scrollHeight';

      let percent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;

      if (percent >= 75) {
        this.loadMore();
      }
    });

    this.resizeSub = fromEvent(this.scrollElement, 'resize').pipe(debounceTime(500)).subscribe(($event) => {
      // if there is not a scrollbar show load more
      this.showLoadMoreButton = this.scrollReference.scrollHeight <= this.scrollReference.clientHeight;
    });
  }

  ngOnDestroy() {
    if (this.scrollSub) this.scrollSub.unsubscribe();
  }

  search() {
    this.page = 1;
    const params = {
      ...this.params,
      size: this.size,
      page: this.page
    };
    this.loading.start();
    this.data(params).subscribe((result) => {
      this.allLoaded = false;
      if (result instanceof Array) {
        this.array = result;
      } else {
        this.array = result.list;
        this.totalCount = result.totalCount;
      }
      this.arrayChange.emit(this.array);
      if (this.array.length < this.size) {
        this.allLoaded = true;
      }

      this.loading.stop();
    });
  }

  loadMore() {
    if (this.allLoaded || this.loadingMore) return;

    this.loadingMore = true;
    this.page = this.page + 1;

    let params = {
      ...this.params,
      size: this.size,
      page: this.page
    };

    this.data(params).subscribe((result) => {
      let array;
      if (result instanceof Array) {
        array = result;
      } else {
        array = result.list;
        this.totalCount = result.totalCount;
      }
      if (!array || array.length <= 0) {
        this.allLoaded = true;
      } else {
        this.array = this.array.concat(array);
        this.arrayChange.emit(this.array);
      }
      this.loading.stop();
      this.loadingMore = false;
    });
  }
}