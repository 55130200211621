import { Component, Input, OnInit } from '@angular/core';
import { IUser } from '../../../../../interfaces/IUser';
import { IStudent } from '../../../../../interfaces/IStudent';
import { IThesisCommitteeMemberData } from '../../../../../interfaces/IThesisProcess';
import { UserService } from '../../../user/user.service';
import { SendEmailModalService } from '../service/send-email-modal/send-email-modal.service';

@Component({
  selector: 'email-link',
  templateUrl: './email-link.component.html'
})
export class EmailLinkComponent implements OnInit {

  @Input() user: IUser | IStudent | IThesisCommitteeMemberData | any;

  email;

  constructor(public us: UserService, public sems: SendEmailModalService) { }

  ngOnInit() {
    // set email depending on user interface
    if (this.user.email && (<IUser>this.user).email.address) {
      this.email = (<IUser>this.user).email.address;
    } else if (this.user.email) {
      this.email = <string>this.user.email;
    }
  }

}
