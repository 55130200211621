import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { NgForm } from '@angular/forms';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { FinalLeaveReason, IStudent } from '../../../../../../interfaces/IStudent';
import { RefreshService } from '../../../../data-services/refresh.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { FilesQueueComponent } from '../../../../UI/files-queue/files-queue.component';

@Component({
  selector: 'final-leave-modal',
  templateUrl: './final-leave-modal.component.html',
})
export class FinalLeaveModalComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @ViewChild('justificationInput') justificationInput: FilesQueueComponent;
  @Input() open = false;
  @Output() openChange = new EventEmitter<boolean>();
  @Input() student: IStudent;

  justification: IDocumentRef[];
  start: Date;
  reason: FinalLeaveReason;

  FinalLeaveReason = FinalLeaveReason;
  // sendMail = false;
  // mailText = null;

  // modules = {
  //   toolbar: [
  //     ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  //     [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  //     [{ 'align': [] }],
  //     ['link']                         // link and image, video
  //   ]
  // };

  constructor(private sh: ServiceHttpService, private refresh: RefreshService, private alerts: PopAlertsService) { }

  ngOnInit() {
  }

  reset() {
    this.justification = [];
    this.start = null;
    this.reason = null;
    // this.justificationInput.reset();
  }

  close() {
    this.open = false;
    this.openChange.emit(this.open);
  }

  save() {
    if (!this.f.valid) {
      return;
    }

    this.sh.saveFinalLeave({ studentId: this.student._id, justification: this.justification, start: this.start, reason: this.reason }).subscribe(() => {
      this.close();
      this.refresh.onStudentChange.next()
      this.alerts.saveSuccess();
    })

  }

}
