<div class="title d-flex justify-content-between align-items-center">
  <h2>
    <i class="fa{{ iconType }} fa-{{ icon }} text-secondary mr-1"></i>
    {{ text | translate }}
    <span *ngIf="additionalText">
      - {{ additionalText | translate }}
    </span>
  </h2>
  <button *ngIf="(btnText || btnIcon) && btnShow"
    type="button"
    [class]="'btn ' + btnClasses"
    (click)="btnClick.emit()">
    <span *ngIf="btnIcon">
      <i [class]="btnIcon"></i>
    </span>
    <span *ngIf="btnText">
      {{ btnText | translate }}
    </span>
  </button>
</div>