import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDischargeProcess } from '../../../../../../../interfaces/IDischargeProcess';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { ILeaves } from '../../../../../../../interfaces/IStudent';
import { ITask } from '../../../../../../../interfaces/ITask';
import { DischargeHttpService } from '../../../../../data-services/discharge-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'discharge-ac-validation',
  templateUrl: './discharge-ac-validation.component.html'
})
export class DischargeAcValidationComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  process: IDischargeProcess;

  justification: IDocumentRef[] = [];
  end: Date;
  leaveId: string;
  leave: ILeaves;

  denialMsg = '';
  validate = null;

  constructor(private discharge: DischargeHttpService,
    private router: Router) {
    super();
  }

  ngOnInit() {

    this.discharge.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      this.justification = this.process.justification;
      this.end = this.process.end;
      this.leaveId = this.process.leaveId;
      this.leave = this.process.student.leaves.find(l => l._id === this.leaveId);
    })
  }

  manageSend() {
    if (this.f.invalid) {
      this.sendEnd.next();
      return;
    }

    if (this.validate) {
      this.discharge.academicCommitteeValidation(this.task._id).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.discharge.academiccommitteeDeny(this.task._id, { denialMsg: this.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
