<div class="small text-muted mb-1">
  {{ 'student-info' | translate }}
</div>

<text-info icon="user">
  {{ student.user | fullname }}
</text-info>

<text-info icon="id-card"
  mb="4"
  *ngIf="showAll">
  {{ student.user.idNum }}
</text-info>

<direction-team-view [student]="student"></direction-team-view>

<div class="mb-4">
  <div class="small text-muted mb-1">
    {{ 'student-cv' | translate }}
  </div>
  <div>
    <document-link [document]="data.cv"></document-link>
  </div>
</div>

<div class="mb-4">
  <div class="small text-muted mb-1">
    {{ 'thesis-title' | translate }}
  </div>
  <div>
    {{ data.thesisTitle }}
  </div>
</div>

<div class="row mb-4">
  <div class="col-md-6">
    <div class="small text-muted mb-1">
      {{ 'thesis-doc' | translate }}
    </div>
    <div>
      <document-link [document]="data.thesisDoc"></document-link>
    </div>
  </div>
  <div class="col-md-6">
    <div class="small text-muted mb-1">
      {{ 'thesis-summary' | translate }}
    </div>
    <div>
      <document-link [document]="data.thesisSummaryDoc"></document-link>
    </div>
  </div>
</div>

<div class="mb-4">
  <div class="small text-muted mb-1">
    {{ 'thesis-publications' | translate }}
  </div>
  <div *ngFor="let doc of data.publications"
    class="mb-1">
    <document-link [document]="doc"></document-link>
  </div>
</div>

<ng-container *ngIf="showAll">
  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-cotutelle' | translate }}
    </div>
    <div>
      {{ (data.cotutelle ? 'Yes' : 'No') | translate }}
    </div>
  </div>

  <div *ngIf="data.cotutelle"
    class="row mb-4">
    <div class="col-md-6">
      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-university' | translate }}
        </div>
        <div class="mb-1">
          <span *ngIf="data.cotutelleUniversity">
            {{ data.cotutelleUniversity }}
          </span>
        </div>
      </div>
      <div class="mb-4"
        *ngIf="data.cotutelleStayEvidence && data.cotutelleStayEvidence.length > 0">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-stay-evidence' | translate }}
        </div>
        <div *ngFor="let doc of data.cotutelleStayEvidence"
          class="mb-1">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
      <div class="mb-4"
        *ngIf="data.cotutelleStayEvidenceOut && data.cotutelleStayEvidenceOut.length > 0">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-stay-evidence-out' | translate }}
        </div>
        <div *ngFor="let doc of data.cotutelleStayEvidenceOut"
          class="mb-1">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-defence-place' | translate }}
        </div>
        <div class="mb-1">
          <span *ngIf="data.cotutelleDefenceOut">
            {{ 'foreign-university' | translate }}
          </span>
          <span *ngIf="!data.cotutelleDefenceOut">
            {{ 'university-of-malaga' | translate }}
          </span>
        </div>
      </div>
      <div class="mb-4"
        *ngIf="data.cotutelleDefenceOut && data.cotutelleComposition">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-thesis-committe-composition' | translate }}
        </div>
        <div class="mb-1">
          <document-link [document]="data.cotutelleComposition"></document-link>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-compendium' | translate }}
    </div>
    <div>
      {{ (data.compendium ? 'Yes' : 'No') | translate }}
    </div>
  </div>

  <div *ngIf="data.compendium && data.coauthorsAutorization && data.coauthorsAutorization.length > 0"
    class="row mb-4">
    <div class="col-md-6">
      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'compendium-coauthors-autorization' | translate }}
        </div>
        <div *ngFor="let doc of data.coauthorsAutorization"
          class="mb-1">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
    </div>
    <div class="col-md-6"
      *ngIf="!hideCompendiumDirectorReport">
      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'compendium-director-report' | translate }}
        </div>
        <div *ngFor="let doc of data.compendiumDirectorReport"
          class="mb-1">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-md-6">

      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'international-mention' | translate }}
        </div>
        <div>
          {{ (data.internationalMention ? 'Yes' : 'No') | translate }}
        </div>
      </div>

      <div
        *ngIf="data.internationalMention && data.internationalStayEvidence && data.internationalStayEvidence.length > 0"
        class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'international-stay-evidence' | translate }}
        </div>
        <div *ngFor="let doc of data.internationalStayEvidence"
          class="mb-1">
          <document-link [document]="doc"></document-link>
        </div>
      </div>

    </div>
    <div class="col-md-6">

      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'industrial-mention' | translate }}
        </div>
        <div>
          {{ (data.industrialMention ? 'Yes' : 'No') | translate }}
        </div>
      </div>

      <div *ngIf="data.industrialMention && data.industrialMentionEvidence && data.industrialMentionEvidence.length > 0"
        class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'industrial-mention-evidence' | translate }}
        </div>
        <div *ngFor="let doc of data.industrialMentionEvidence"
          class="mb-1">
          <document-link [document]="doc"></document-link>
        </div>
      </div>

    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'ethics-report' | translate }}
    </div>
    <div>
      {{ (data.needsEthicsReport ? 'Yes' : 'No') | translate }}
    </div>
  </div>

  <div *ngIf="data.needsEthicsReport && data.ethicsReport"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'ethics-committee-report' | translate }}
    </div>
    <div>
      <document-link [document]="data.ethicsReport"></document-link>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showThesisCommittee && !(data.cotutelle && data.cotutelleDefenceOut)">
  <thesis-committee-view [thesisCommittee]="data.thesisCommittee"></thesis-committee-view>

  <h5 class="card-title">{{ 'external-evaluators' | translate }}</h5>

  <div class="row">
    <div class="col-md-4 pr-md-0">
      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'first-evaluator' | translate }}
        </div>
        <div>
          <user-info-view [showCountry]="true"
            [showCV]="true"
            [sm]="true"
            [userInfo]="data.externalEvaluators.ev1"></user-info-view>
        </div>
      </div>
    </div>
    <div class="col-md-4 px-md-2">
      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'second-evaluator' | translate }}
        </div>
        <div>
          <user-info-view [showCountry]="true"
            [showCV]="true"
            [sm]="true"
            [userInfo]="data.externalEvaluators.ev2"></user-info-view>
        </div>
      </div>
    </div>
    <div class="col-md-4 pl-md-0">
      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'third-evaluator' | translate }}
        </div>
        <div>
          <user-info-view [showCountry]="true"
            [showCV]="true"
            [sm]="true"
            [userInfo]="data.externalEvaluators.ev3"></user-info-view>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="selectedEvaluators">
  <h5 class="card-title">{{ 'selected-evaluators' | translate }}</h5>

  <div class="mb-2">
    <div>
      {{ selectedEvaluators.ev1 | fullname }}
    </div>
    <div *ngIf="us.isService()">
      <ng-container *ngIf="sh.editMode">
        <div class="small mt-2 text-muted">Fecha recepción hoja de gastos:</div>
        <div>
          <date-picker [(ngModel)]="selectedEvaluators.ev1.expenseDocDate"
            [sm]="true"
            name="expenseDocDate1">
          </date-picker>
        </div>
        <div>
          <textarea [(ngModel)]="selectedEvaluators.ev1.expenseDocNote"
            class="form-control form-control-sm"
            placeholder="Anotación"
            name="expenseDocNote1"></textarea>
        </div>
        <div class="text-right">
          <button class="btn btn-link btn-sm"
            role="button"
            (click)="onSaveExpenseDocDate.emit({ ev: 1, evId: selectedEvaluators.ev1._id, expenseDocDate: selectedEvaluators.ev1.expenseDocDate, expenseDocNote: selectedEvaluators.ev1.expenseDocNote})">
            {{ 'save' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!sh.editMode">
        <div *ngIf="selectedEvaluators.ev1.expenseDocDate">
          <span class="small mt-2 text-muted">Fecha recepción hoja de gastos:</span>
          {{ selectedEvaluators.ev1.expenseDocDate | moment:'DD/MM/YYYY' }}
          <span *ngIf="selectedEvaluators.ev1.expenseDocNote"
            [tooltip]="selectedEvaluators.ev1.expenseDocNote"
            [tooltipClass]="'pre text-left'">
            <i class="far fa-sticky-note"></i>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="mb-4">
    <div>
      {{ selectedEvaluators.ev2 | fullname }}
    </div>
    <div *ngIf="us.isService()">
      <ng-container *ngIf="sh.editMode">
        <div class="small mt-2 text-muted">Fecha recepción hoja de gastos:</div>
        <date-picker [(ngModel)]="selectedEvaluators.ev2.expenseDocDate"
          [sm]="true"
          name="expenseDocDate2">
        </date-picker>
        <div>
          <textarea [(ngModel)]="selectedEvaluators.ev2.expenseDocNote"
            class="form-control form-control-sm"
            placeholder="Anotación"
            name="expenseDocNote2"></textarea>
        </div>
        <div class="text-right">
          <button class="btn btn-link btn-sm"
            role="button"
            (click)="onSaveExpenseDocDate.emit({ ev: 2, evId: selectedEvaluators.ev2._id, expenseDocDate: selectedEvaluators.ev2.expenseDocDate, expenseDocNote: selectedEvaluators.ev2.expenseDocNote})">
            {{ 'save' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!sh.editMode">
        <div *ngIf="selectedEvaluators.ev2.expenseDocDate">
          <span class="small mt-2 text-muted">Fecha recepción hoja de gastos:</span>
          {{ selectedEvaluators.ev2.expenseDocDate | moment:'DD/MM/YYYY' }}
          <span *ngIf="selectedEvaluators.ev2.expenseDocNote"
            [tooltip]="selectedEvaluators.ev2.expenseDocNote"
            [tooltipClass]="'pre text-left'">
            <i class="far fa-sticky-note"></i>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="reports && reports.length > 0">
  <h5 class="card-title">
    {{ 'thesis-evaluation-reports' | translate }}
    <span *ngIf="us.isService() && sh.editMode">
      <button class="btn btn-sm ml-2 btn-outline-dark"
        type="button"
        *ngIf="sh.editMode && us.isService()"
        (click)="onEditReports.emit()">
        <i class="far fa-edit mr-1"></i>
        {{ 'edit' | translate }}
      </button>
    </span>
  </h5>

  <div class="mb-3"
    *ngFor="let report of reports">
    <div>
      {{ report.memberName }}
    </div>
    <div class="d-flex">
      <div class="mr-2">
        <span class="badge"
          [class.badge-success]="report.evaluation === ThesisReportEvaluation.accept"
          [class.badge-danger]="report.evaluation === ThesisReportEvaluation.reject"
          [class.badge-warning]="report.evaluation === ThesisReportEvaluation.acceptMajorChanges || report.evaluation === ThesisReportEvaluation.acceptMinorChanges">
          {{ report.evaluation | translate }}
        </span>
      </div>
      <div>
        <document-link [document]="report.docRef"></document-link>
      </div>
    </div>
  </div>
</ng-container>