import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IExternalUser } from '../../../../../../../interfaces/IExternalUser';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { Role } from '../../../../../../../interfaces/IUser';
import * as _ from 'lodash';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { HelperService } from '../../../../../shared/helper-service/helper.service';

@Component({
  selector: 'external-users-file',
  templateUrl: 'external-users-file.component.html'
})
export class ExternalUsersFileComponent implements OnInit {
  user: IExternalUser;
  relations: { studentId: string, name: string, program: string, role: string }[] = [];
  userId: string;

  Role = Role;

  userEdit: IExternalUser;
  open = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private ss: ServiceHttpService,
    private alerts: PopAlertsService,
    public hs: HelperService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.userId = params.externalId;
      if (!this.userId) {
        void this.router.navigate(['../']);
        return;
      }

      this.ss.fetchExternal(this.userId).subscribe((res) => {
        this.user = res.user;
        this.relations = res.relations;
      })

    })
  }

  isExternalReviewer(role) {
    return [
      Role.thesisExternalReviewer,
      Role.thesisExternalReviewer1,
      Role.thesisExternalReviewer2,
      Role.thesisExternalReviewer3,
    ]
      .includes(role);
  }

  isThesisCommittee(role) {
    return [
      Role.thesisCommitteeMember,
      Role.thesisCommitteePresident,
      Role.thesisCommitteeSecretary,
      Role.thesisCommitteeAlt]
      .includes(role);
  }

  generateExternalReviewerCert(studentId) {
    window.location.href = `${window.location.origin}/api/service/externalReviewerCert/${this.user._id}/${studentId}`
  }

  isAnyExternalReviewer() {
    return this.relations.findIndex(r => this.isExternalReviewer(r.role)) > -1;
  }

  isAnyThesisCommittee() {
    return this.relations.findIndex(r => this.isThesisCommittee(r.role)) > -1;
  }

  generateThesisCert(studentId, role) {
    window.location.href = `${window.location.origin}/api/service/thesisCommitteeCert/${this.user._id}/${studentId}/${role}`
  }

  openEdit() {
    this.userEdit = _.cloneDeep(this.user);
    this.open = true;
  }

  update() {
    this.ss.updateExternal(this.userEdit).subscribe(() => {
      this.alerts.saveSuccess();
      this.user = this.userEdit;
      this.open = false;
    })
  }


}