import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IActivity } from '../../../../../../../interfaces/IActivity';
import { ITask } from '../../../../../../../interfaces/ITask';
import { ActivityValidationHttpService } from '../../../../../data-services/activity-validation-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'validation-validation-task',
  templateUrl: 'validation-validation-task.component.html'
})
export class ValidationValidationTaskComponent extends AbstractTaskComponent implements OnInit, OnChanges {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  activity: IActivity;

  validationMsg = '';

  validate = null;

  constructor(private avs: ActivityValidationHttpService,
    private router: Router) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (!this.task) return;
    this.avs.getActivity(this.task._id).subscribe((activity) => {
      this.activity = activity;
    });
  }

  manageSend() {
    if (this.f.invalid) {
      this.sendEnd.next();
      return;
    }

    if (this.validate) {
      this.avs.validate(this.task._id).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.avs.deny(this.task._id, this.validationMsg).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}