import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'validation-errors',
  templateUrl: 'validation-errors.component.html'
})
export class ValidationErrorsComponent implements OnInit {

  @Input() control: FormControl;
  @Input() show = true;

  constructor() { }

  ngOnInit() {
  }
}