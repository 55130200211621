<!-- <menu-item [link]="['/app']"
  [linkActiveOptions]="{exact: true}"
  icon="home"
  name="start"></menu-item> -->

<menu-item [link]="['/app/tutor/students']"
  (onClick)="ss.reset()"
  icon="users-class"
  name="students"></menu-item>

<!-- <menu-item [link]="['/app/certificates']"
  icon="file-certificate"
  name="certificates"></menu-item> -->