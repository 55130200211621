import { Injectable } from '@angular/core';

import { RDType, StudentStateType } from '../../../../../../interfaces/IStudent';

@Injectable()
export class StudentsSearchService {

  filters: { programs: string[], states: StudentStateType[], deadline?: number, rd?: RDType };

  constructor() {
    this.reset();
  }

  reset() {
    this.filters = {
      programs: [],
      states: [],
      rd: null,
      deadline: null
    }
  }

}