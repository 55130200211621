import { Injectable, NgZone } from '@angular/core';

@Injectable()
export class LoadingIndicatorService {

  msg: string = 'LOADING';

  visible = false;

  private timeout = null;

  constructor(private zone: NgZone) { }

  start() {
    if (this.timeout) {
      return;
    }
    this.timeout = setTimeout(() => {
      this.zone.run(() => {
        this.visible = true;
      });
    }, 100);
  }

  stop() {
    this.zone.run(() => {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.visible = false;
    });
  }

}