import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SendEmailModalService } from '../../../components/service/send-email-modal/send-email-modal.service';
import { IMailTimeEmail } from '../../../../../../interfaces/IMailTimeEmail';
import { InfiniteListComponent } from '../../../../UI/infinite-list/infinite-list.component';
import { Subscription } from 'rxjs';
import { StudentFileService } from '../student-file.service';
import { EmailsHttpService } from '../../../../data-services/emails-http.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';

@Component({
  selector: 'student-file-emails',
  templateUrl: './student-file-emails.component.html',
})
export class StudentFileEmailsComponent implements OnInit {

  @ViewChild('list') list: InfiniteListComponent;
  emails: IMailTimeEmail[] = [];

  params = {
    searchText: ''
  }

  sub: Subscription;

  constructor(private us: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public sems: SendEmailModalService,
    private es: EmailsHttpService,
    private alerts: PopAlertsService,
    private sfs: StudentFileService) { }

  ngOnInit() {
    if (!this.us.isService()) {
      void this.router.navigate(['../'], { relativeTo: this.route });
    }

    this.params.searchText = this.sfs.student.lastName + ', ' + this.sfs.student.firstName;

    this.sub = this.sems.onSent.subscribe(() => {
      this.list.search();
    });
  }

  forward(mailId) {
    this.es.forward(mailId).subscribe(() => {
      this.alerts.pop({
        msg: 'Correo reenviado',
        styleClass: 'success'
      })
    })
  }

  fetchFn() {
    return (params) => {
      params = {
        ...params,
        ...this.params
      }
      return this.es.fetch(params);
    }
  }

  tbFn(index, item) {
    return item._id
  }

}
