import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { PermissionsType } from '../../../../../../interfaces/IPermissions';
import { StudentStateType } from '../../../../../../interfaces/IStudent';
import { CDSHttpService } from '../../../../data-services/cds-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { listAnimation, smoothHeight } from '../../../../UI/animations/animations';
import { UserService } from '../../../../user/user.service';
import { StudentRootService } from './student-root.service';

@Component({
  selector: 'student-root',
  templateUrl: './student-root.component.html',
  animations: [smoothHeight, listAnimation]
})
export class StudentRootComponent implements OnInit, OnDestroy {

  userSub: Subscription;
  studentSub: Subscription;

  showStatusMessage = false;
  alertType;
  noPermissions: PermissionsType[] = [];
  alertOpen = true;
  liItems = 0;

  StudentStateType = StudentStateType;
  PermissionsType = PermissionsType;

  refreshSub: Subscription;

  constructor(public sr: StudentRootService,
    private us: UserService,
    private cds: CDSHttpService,
    private refresh: RefreshService) { }

  ngOnInit() {
    this.userSub = this.us.roleObs.subscribe(() => {
      this.studentSub = this.sr.readyObs.subscribe(() => {
        // Set status messages
        this.showStatusMessage = this.sr.student.state !== StudentStateType.active;
        this.alertType = this.alertTypeFn(this.sr.student.state);
        this.noPermissions = Object.keys(this.sr.student.permissions).filter(k => this.sr.student.permissions[k] === false).map(k => PermissionsType[k]);

        if (this.noPermissions.includes(PermissionsType.manageActivities)) {
          this.liItems++;
        }

        if (this.noPermissions.includes(PermissionsType.completeTasks)) {
          this.liItems++;
        }

        if (this.noPermissions.includes(PermissionsType.requestCDS)
          || this.noPermissions.includes(PermissionsType.requestDedication)
          || this.noPermissions.includes(PermissionsType.requestDirection)
          || this.noPermissions.includes(PermissionsType.requestEvaluation)
          || this.noPermissions.includes(PermissionsType.requestExtension)
          || this.noPermissions.includes(PermissionsType.requestMedicalLeave)
          || this.noPermissions.includes(PermissionsType.requestTemporalLeave)
          || this.noPermissions.includes(PermissionsType.requestThesis)) {
          this.liItems++;
        }

        // check if CDS is already requested
        if (this.sr.student.state === StudentStateType.initial) {
          this.cds.getTasksTable(this.sr.student._id).subscribe((cdsProcess) => {
            this.sr.cdsRequested = cdsProcess ? true : false
          });
        }
      })
      this.sr.fetch();
    })

    this.refreshSub = this.refresh.onStudentChange.subscribe(() => {
      this.sr.fetch();
    })
  }

  ngOnDestroy() {
    if (this.userSub) this.userSub.unsubscribe();
    if (this.studentSub) this.studentSub.unsubscribe();
    if (this.refreshSub) this.refreshSub.unsubscribe();
  }

  private alertTypeFn(state: StudentStateType) {
    switch (state) {
      case StudentStateType.deadline:
      case StudentStateType.negativeEvaluations:
      case StudentStateType.missingEnrollments:
      case StudentStateType.permanentLeave:
      case StudentStateType.finalLeave:
        return 'danger'
      case StudentStateType.initial:
        return 'secondary'
      case StudentStateType.defended:
        return 'success'
      case StudentStateType.temporalLeave:
      case StudentStateType.medicalLeave:
        return 'warning'
      default:
        return 'info'
    }

  }


}
