<div *ngIf="user"
  class="container-fluid container-md">
  <div class="mb-2">
    <a class="text-muted"
      [routerLink]="['../']">
      <i class="far fa-angle-left"></i>
      {{ 'go-back' | translate }}
    </a>
  </div>
  <h2 class="mb-4 text-nowrap text-truncate">
    <i class="far fa-user mr-2 text-secondary"></i>
    {{ user | fullname }}
  </h2>


  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="card">
        <div class="card-body">
          <legend class="d-flex justify-content-between align-items-center">
            <span>
              {{ 'personal-data' | translate }}
            </span>
            <span class="btn-icon"
              (click)="openEdit()">
              <i class="far fa-edit"></i>
            </span>
          </legend>

          <text-info icon="user">
            {{ user | fullname }}
          </text-info>

          <text-info icon="id-card"
            mb="3">
            {{ user.idNum }}
          </text-info>

          <legend>
            {{ 'contact' | translate }}
          </legend>

          <text-info icon="envelope"
            mb="3">
            <email-link [user]="user"></email-link>
          </text-info>

          <text-info icon="school"
            *ngIf="user.university">
            {{ user.university }}
          </text-info>



        </div>
      </div>

    </div>

    <div class="col-12 col-lg-6">
      <div class="card">
        <div class="card-header">
          Roles generales
        </div>
        <div class="list-group">
          <div class="list-group-item"
            *ngIf="isAnyExternalReviewer()">
            {{ Role.thesisExternalReviewer | translate }}
          </div>
          <div class="list-group-item"
            *ngIf="isAnyThesisCommittee()">
            {{ Role.thesisCommittee | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-12"
      *ngIf="relations && relations.length > 0">
      <div class="card mt-4">
        <div class="card-header">
          Estudiantes
        </div>
        <table class="table table-sm mb-0">
          <tbody>
            <tr *ngFor="let r of relations">
              <!-- <td class="text-right">
                <student-state [state]="r.state"></student-state>
              </td> -->
              <td>
                <a [routerLink]="['/app/service/students/' + r.studentId]">
                  {{ r.name }}
                </a>
              </td>
              <td>
                {{ r.role | translate }}
                <a [routerLink]="null"
                  (click)="generateExternalReviewerCert(r.studentId)"
                  class="ml-1"
                  [tooltip]="'Generar certificado'"
                  *ngIf="isExternalReviewer(r.role)">
                  <i class="fad fa-file-word fa-inverse fa-fw"
                    style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
                </a>
                <a [routerLink]="null"
                  (click)="generateThesisCert(r.studentId, r.role)"
                  class="ml-1"
                  [tooltip]="'Generar certificado'"
                  *ngIf="isThesisCommittee(r.role)">
                  <i class="fad fa-file-word fa-inverse fa-fw"
                    style="color: #0078d7; --fa-secondary-opacity: 0.5;"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>

  </div>
</div>

<modal [title]="'edit-external' | translate"
  [(open)]="open">
  <form id="editForm"
    (ngSubmit)="update()"
    *ngIf="open"
    #editForm="ngForm">

    <div class="form-group">
      <label for="idNum">
        {{ 'idNum' | translate}}
      </label>
      <input type="text"
        class="form-control"
        [placeholder]="'idNum' | translate"
        name="idNum"
        [(ngModel)]="userEdit.idNum"
        [required]="true"
        #idNumInput="ngModel"
        [class.is-invalid]="editForm.submitted && idNumInput.invalid">
      <validation-errors [show]="editForm.submitted"
        [control]="idNumInput"></validation-errors>
    </div>
    <div class="form-group">
      <label for="firstName">
        {{ 'firstName' | translate}}
      </label>
      <input type="text"
        class="form-control"
        [placeholder]="'firstName' | translate"
        name="firstName"
        [(ngModel)]="userEdit.firstName"
        [required]="true"
        #firstNameInput="ngModel"
        [class.is-invalid]="editForm.submitted && firstNameInput.invalid">
      <validation-errors [show]="editForm.submitted"
        [control]="firstNameInput"></validation-errors>
    </div>
    <div class="form-group">
      <label for="idNum">
        {{ 'lastName' | translate}}
      </label>
      <input type="text"
        class="form-control"
        [placeholder]="'lastName' | translate"
        name="lastName"
        [(ngModel)]="userEdit.lastName"
        [required]="true"
        #lastNameInput="ngModel"
        [class.is-invalid]="editForm.submitted && lastNameInput.invalid">
      <validation-errors [show]="editForm.submitted"
        [control]="lastNameInput"></validation-errors>
    </div>
    <div class="form-group">
      <label for="email">
        {{ 'email' | translate}}
      </label>
      <input type="text"
        class="form-control"
        [placeholder]="'search-by-email' | translate"
        name="email"
        [(ngModel)]="userEdit.email"
        [required]="true"
        [pattern]="hs.EMAIL_REGEX"
        #emailInput="ngModel"
        [class.is-invalid]="editForm.submitted && emailInput.invalid">
      <validation-errors [show]="editForm.submitted"
        [control]="emailInput"></validation-errors>
    </div>
    <div class="form-group">
      <label for="university">
        {{ 'university' | translate}}
      </label>
      <input type="text"
        class="form-control"
        [placeholder]="'university' | translate"
        name="university"
        [(ngModel)]="userEdit.university"
        [required]="true"
        #universityInput="ngModel"
        [class.is-invalid]="editForm.submitted && universityInput.invalid">
      <validation-errors [show]="editForm.submitted"
        [control]="universityInput"></validation-errors>
    </div>
  </form>
  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">
      {{ 'cancel' | translate }}
    </button>
    <button class="btn btn-primary"
      type="submit"
      form="editForm">
      {{ 'save' | translate }}
    </button>

  </div>
</modal>