import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ITask } from '../../../interfaces/ITask';
import { ExternalTaskHttpService } from '../data-services/external-task-http.service';

@Component({
  selector: 'old-task-component',
  template: '',
})
export class OldTaskComponentComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private ts: ExternalTaskHttpService) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const taskId = params.taskId;
      const token = params.token;
      if (!taskId || !token) {
        void this.router.navigate(['/']);
        return;
      }

      this.ts.findOld(taskId, token).subscribe((task: ITask) => {
        if (task.external) {
          void this.router.navigate([`/task/${task._id}/${token}`])
        } else {
          void this.router.navigate([`/app/task/${task._id}`])
        }
      });
    })
  }

}
