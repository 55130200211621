import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ICDSProcess } from '../../../../../../../interfaces/ICDSProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { CDSHttpService } from '../../../../../data-services/cds-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'cds-pgc-validation',
  templateUrl: './cds-pgc-validation.component.html'
})
export class CDSPgcValidationComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: ICDSProcess;
  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  constructor(private cds: CDSHttpService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.cds.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    if (!this.validation.validate) {
      this.cds.pgcDenyValidation(this.task._id, { denialMsg: this.validation.denialMsg }).subscribe(() => {
        void this.router.navigate(['/app/task-completed']);
        this.sendEnd.next();
      });
    } else {
      this.cds.pgcValidation(this.task._id).subscribe((res) => {
        void this.router.navigate(['/app/task-completed']);
        this.sendEnd.next();
      });
    }
  }

}
