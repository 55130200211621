import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { HelperService } from '../../shared/helper-service/helper.service';

@Component({
  selector: 'validation-buttons',
  templateUrl: './validation-buttons.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ValidationButtonsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ValidationButtonsComponent),
      multi: true
    }
  ]
})
export class ValidationButtonsComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() showErrors = false;
  @Input() validateButtonText = 'validate';
  @Input() denyButtonText = 'deny';
  @Input() showValidationText = false;
  @Input() showDenyText = true;

  validation: {
    validate: boolean,
    denialMsg: string,
    validationMsg?: string
  }

  constructor(private hs: HelperService) { }

  ngOnInit() {
  }

  scrollToBottom() {
    setTimeout(() => {
      this.hs.scrollToBottom();
    }, 300)
  }

  writeValue(value: any): void {
    this.validation = value;
    if (this.validation && !this.validation.validate) {
      this.validation.denialMsg = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  propagateChange() {
    this.onChange(this.validation);
    this.onTouch();
  }

  onChange = (_: any) => { };
  onTouch = () => { };

  validate(control: AbstractControl): ValidationErrors {
    if (!this.validation || this.validation.validate === null || this.validation.validate === undefined) {
      return { required: true };
    }

    if (this.validation.validate && this.validation.denialMsg && this.validation.denialMsg.length > 0) {
      return { denialMsgMustBeEmpty: true };
    }

    if (!this.validation.validate && (!this.validation.denialMsg || this.validation.denialMsg.length <= 0)) {
      return { denialMsgRequired: true };
    }

    return null;
  }

}
