import { Component, OnInit } from '@angular/core';
import { IStudent } from '../../../../../../interfaces/IStudent';
import { StudentHttpService } from '../../../../data-services/student-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../../user/user.service';
import { SecretaryService } from '../secretary.service';
import { IFaculty } from '../../../../../../interfaces/IFaculty';
import { LoadingIndicatorService } from '../../../../UI/loading-indicator/loading-indicator.service';
import { ProgramHttpService } from '../../../../data-services/program-http.service';

@Component({
  selector: 'faculty-students',
  templateUrl: './faculty-students.component.html',
})
export class FacultyStudentsComponent implements OnInit {

  ready = false;
  faculty: IFaculty;
  students: IStudent[] = [];

  constructor(public ss: SecretaryService,
    private ps: ProgramHttpService,
    public us: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private loading: LoadingIndicatorService) { }

  ngOnInit() {
    this.loading.start();
    this.ss.fetchFaculty(this.us.selectedRole.groupId).subscribe((faculty: IFaculty) => {
      this.faculty = faculty;
      this.ps.programs = this.ps.programs.filter(p => p.facultyId === this.faculty._id)
      this.loading.stop();
      this.ready = true
    });
  }

  dataFn() {
    return (params) => {
      return this.ss.fetchFacultyStudents(this.us.selectedRole.groupId, params);
    }
  }

  goToStudent(studentId) {
    void this.router.navigate([`${studentId}`], { relativeTo: this.route });
  }
}
