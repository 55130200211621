<div class="input-group"
  [class.input-group-sm]="sm"
  dropFiles
  (filesChangeEmitter)="fileChange($event)">
  <input type="text"
    class="form-control selected-file-info"
    [class.is-invalid]="invalid"
    readonly
    [value]="selectedFileName"
    [placeholder]="placeholder">
  <div class="input-group-append">
    <button class="btn btn-outline-secondary"
      type="button"
      *ngIf="state === 'EMPTY'"
      (click)="inputTypeFile.click()">
      <span *ngIf="!sm">
        {{ 'choose' | translate }}
      </span>
      <span *ngIf="sm">
        <i class="far fa-file-search"></i>
      </span>
    </button>
    <button class="btn btn-outline-secondary"
      type="button"
      disabled
      *ngIf="state === 'UPLOADING'">
      <span *ngIf="!sm">
        {{ 'uploading' | translate }}
      </span>
      <span *ngIf="sm">
        <i class="far fa-circle-notch fa-spin"></i>
      </span>
    </button>
    <button class="btn btn-outline-danger"
      type="button"
      (click)="abort()"
      *ngIf="state === 'UPLOADING'">
      <i class="far fa-stop-circle"></i>
    </button>
    <button class="btn btn-outline-dark"
      type="button"
      *ngIf="state === 'COMPLETED'"
      (click)="download()">
      <i class="far fa-download"></i>
    </button>
    <button class="btn btn-outline-dark"
      type="button"
      *ngIf="state === 'COMPLETED'"
      (click)="reset()">
      <i class="far fa-trash"></i>
    </button>
  </div>
</div>

<progress-bar height="3"
  [visibility]="state === 'UPLOADING' ? 'visible' : 'hidden'"
  [progress]="progress"
  color="secondary">
</progress-bar>

<div class="invalid-feedback"
  *ngIf="invalid && invalidMsg && invalidMsg.length > 0"
  [style.display]="invalid ? 'inline-block' : 'none'">
  {{ invalidMsg }}
</div>

<div class="wrapper">
  <input #inputTypeFile
    type="file"
    name="file"
    (change)="fileChange($event)" />
</div>