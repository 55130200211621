import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ITask } from '../../../../interfaces/ITask';
import { ExternalTaskHttpService } from '../../data-services/external-task-http.service';
import { AbstractTaskComponent } from '../../root/components/process/abstract-task.component';

@Component({
  selector: 'thesis-committee-alt-acceptance',
  templateUrl: './thesis-committee-alt-acceptance.component.html',
})
export class ThesisCommitteeAltAcceptanceComponent extends AbstractTaskComponent implements OnInit {

  @Input() task: ITask;
  @Input() token: string;

  @Output() onComplete = new EventEmitter<any>();

  constructor(private et: ExternalTaskHttpService, private router: Router) {
    super();
  }

  ngOnInit() {
  }

  manageSend() {
    this.et.thesisAcceptance(this.task._id, this.token).subscribe(({ taskId, token }) => {
      this.sendEnd.next();
      if (taskId != null) {
        void this.router.navigate(['/task/' + taskId + '/' + token]);
      } else {
        this.onComplete.emit();
      }
    })
  }

}
