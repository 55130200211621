import { IDocumentRef } from './IDocument';
import { ILineOfResearch } from './ILineOfResearch';
import { IValidationMsgs, IProcess } from './IProcess';
import { IProgram } from './IProgram';
import { DedicationType, IStudent } from './IStudent';
import { IUser } from './IUser';

export interface IUserCDSInfo {
  userId?: string,
  email: string,
  idNum: string,
  firstName: string,
  lastName: string,
  university: string,
  reason?: CdsReason[],

  // Populate
  user?: IUser
}

export enum CdsReason {
  sixyearPeriod = "sixyearPeriod",
  supervisedThesis = "supervisedThesis",
  authorizedByPC = "authorizedByPC",
  none = "none"
}

export interface ICDSProcess extends IProcess {
  state: CDSPState,
  studentId: string,
  programId: string,
  dedication: DedicationType,
  partialTimeDoc?: IDocumentRef[],
  tutor?: IUserCDSInfo,
  supervisor1?: IUserCDSInfo,
  supervisor2?: IUserCDSInfo,
  supervisor3?: IUserCDSInfo,
  lineOfResearchId?: string,
  thirdSupervisorDoc?: IDocumentRef,
  needsSupplement: boolean,
  supplement?: {
    credits: number,
    document?: IDocumentRef,
    notes?: string
  },
  denialMsgs?: IValidationMsgs,
  noConflictAgreement?: boolean,

  // Populate
  lineOfResearch?: ILineOfResearch,
  student?: IStudent;
  program?: IProgram;
}


export enum CDSPState {
  PendingStudentRequestReview = "PendingStudentRequestReview",
  PendingAcademicCommitteeValidation = "PendingAcademicCommitteeValidation",
  PendingServiceValidation = "PendingServiceValidation",
  PendingPostgraduateCommitteeValidation = "PendingPostgraduateCommitteeValidation",
  PendingDirectionTeamUserCreation = "PendingDirectionTeamUserCreation",
  PendingSigns = "PendingSigns"
}

export enum TKCDS {
  studentReviewRequest = "studentReviewRequest",
  academicCommitteeValidation = "academicCommitteeValidation",
  serviceValidation = "serviceValidation",
  postgraduateCommitteeValidation = "postgraduateCommitteeValidation",
  directionTeamUserCreation = "directionTeamUserCreation",
  directionTeamSign = "directionTeamSign",
  studentSign = "studentSign",
  postgraduateCommitteePresidentSign = "postgraduateCommitteePresidentSign",
  doctoralSchoolPrincipalSign = "doctoralSchoolPrincipalSign"
}

export interface ICDSRequestData {
  dedication: DedicationType,
  partialTimeDoc?: IDocumentRef[],
  tutor?: IUserCDSInfo,
  supervisor1?: IUserCDSInfo,
  supervisor2?: IUserCDSInfo,
  supervisor3?: IUserCDSInfo,
  thirdSupervisorDoc?: IDocumentRef,
  lineOfResearchId?: string,
  needsSupplement?: boolean,
  supplement?: {
    credits: number,
    document?: IDocumentRef,
    notes?: string
  }
  denialMsg?: string,
  validate?: boolean,
  noConflictAgreement?: boolean,
}
