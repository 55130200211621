import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qa-home',
  templateUrl: './qa-home.component.html',
})
export class QaHomeComponent implements OnInit {

  format: "csv" | "json" | "excel" = "excel";

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  activitiesReport() {
    window.location.href = `${window.location.origin}/api/qa/activitiesReport?format=${this.format}`
  }

  studentsReport() {
    window.location.href = `${window.location.origin}/api/qa/studentsReport?format=${this.format}`
  }

  studentsSingleLineReport() {
    window.location.href = `${window.location.origin}/api/qa/studentsSingleLineReport?format=${this.format}`
  }

  defendedThesisReport() {
    window.location.href = `${window.location.origin}/api/qa/defendedThesisReport?format=${this.format}`
  }

  studentsLinksReport() {
    window.location.href = `${window.location.origin}/api/qa/studentsLinksReport?format=${this.format}`
  }

  studentsThesisProcessesReport() {
    window.location.href = `${window.location.origin}/api/qa/studentsThesisProcessesReport?format=${this.format}`
  }

}
