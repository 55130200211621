import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../user/user.service';
import { Role } from '../../../../../interfaces/IUser';

@Component({
  selector: 'st-link',
  template: ''
})
export class StLinkComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private us: UserService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (!params.studentId) {
        void this.router.navigate(['/']);
        return;
      }

      let tab = params.tab;
      if (!tab) {
        tab = '';
      }

      let url = null;

      let roles = [];
      if (this.us.selectedRole) {
        roles.push(this.us.selectedRole.role);
      }

      if (this.us.selectableRoles) {
        roles = roles.concat(this.us.selectableRoles.map(r => r.role));
      }


      for (const role of roles) {
        switch (role) {
          case Role.student:
            break;

          case Role.qa:
            url = '/app/qa/students/' + params.studentId;
            break;

          case Role.service:
            url = '/app/service/students/' + params.studentId;
            break;

          case Role.tutor:
            url = '/app/tutor/students/' + params.studentId;
            break;

          case Role.evaluationCommitteeMember:
            url = '/app/ecMember/students/' + params.studentId;
            break;

          case Role.supervisor:
            url = '/app/supervisor/students/' + params.studentId;
            break;

          case Role.coordinator:
            url = '/app/coordinator/students/' + params.studentId;
            break;

          case Role.academicCommittee:
            url = '/app/academic-committee/students/' + params.studentId;
            break;

          case Role.secretary:
          case Role.chiefSecretary:
            url = '/app/secretary/students/' + params.studentId;
            break;

          default:
            break;
        }
        if (url) break;
      }

      if (!url) {
        url = '/';
      } else if (tab) {
        url += '/' + tab;
      }


      void this.router.navigate([url], { replaceUrl: true });

    });
  }
}