import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IStudent } from '../../../../../../interfaces/IStudent';
import { StudentHttpService } from '../../../../data-services/student-http.service';
import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'supervisor-students',
  templateUrl: './supervisor-students.component.html',
})
export class SupervisorStudentsComponent implements OnInit {

  students: IStudent[] = [];

  constructor(public ss: StudentHttpService,
    public us: UserService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

  dataFn() {
    return (params) => {
      return this.ss.fetchSupervisorStudents(params);
    }
  }

  goToStudent(studentId) {
    void this.router.navigate([`${studentId}`], { relativeTo: this.route });
  }
}
