import { Component, Input, OnInit } from '@angular/core';

import { IDedicationProcess } from '../../../../../../../interfaces/IDedicationProcess';

@Component({
  selector: 'dedication-process-data-view',
  templateUrl: './dedication-process-data-view.component.html',
})
export class DedicationProcessDataViewComponent implements OnInit {

  @Input() process: IDedicationProcess;

  constructor() { }

  ngOnInit() {
  }

}
