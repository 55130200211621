import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { IDirectionRequestData } from '../../../../../../../interfaces/IDirectionProcess';
import { IValidationMsgs } from '../../../../../../../interfaces/IProcess';
import { IStudent } from '../../../../../../../interfaces/IStudent';
import { UserService } from '../../../../../user/user.service';

@Component({
  selector: 'direction-request-form',
  templateUrl: 'direction-request-form.component.html',
  styles: [
    `
    .cantCompleteBox {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 10000;
    }
    `
  ]
})
export class DirectionRequestFormComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() student: IStudent;
  @Input() denialMsgs: IValidationMsgs[];
  @Input() directionRequired = false;
  @Input() askValidation = false;
  @Input() full = false;
  @Input() showTitle = true;
  @Input() canComplete = true;
  @Input() cantCompleteMsg;

  @Input() disabled = false;
  @Input() sending = false;

  @Output() onSend = new EventEmitter<IDirectionRequestData>();

  @Input() data: IDirectionRequestData = {
    validate: null,
    tutor: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor1: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor2: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor3: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    noConflictAgreement: false,
  };

  @Input() enableSupervisor2 = false;
  @Input() enableSupervisor3 = false;

  constructor(public us: UserService) { }

  ngOnInit() { }

  send() {
    if (this.f.invalid) return;
    if (!this.canComplete) return;

    let res = { ...this.data };
    if (!res.tutor.email || res.tutor.email.length <= 0) {
      res.tutor = null;
    }
    if (!res.supervisor1.email || res.supervisor1.email.length <= 0) {
      res.supervisor1 = null;
    }
    if (!res.supervisor2.email || res.supervisor2.email.length <= 0) {
      res.supervisor2 = null;
    }
    if (!res.supervisor3.email || res.supervisor3.email.length <= 0) {
      res.supervisor3 = null;
    }

    this.onSend.emit(res);
  }

  toggleSupervisor2() {
    this.data.supervisor2 = {
      email: null,
      idNum: null,
      firstName: null,
      lastName: null,
      university: null
    }
    setTimeout(() => {
      this.enableSupervisor2 = !this.enableSupervisor2;
    }, 0);
  }

  toggleSupervisor3() {
    this.data.supervisor3 = {
      email: null,
      idNum: null,
      firstName: null,
      lastName: null,
      university: null
    }

    setTimeout(() => {
      this.enableSupervisor3 = !this.enableSupervisor3;
    }, 0);
  }
}