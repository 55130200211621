import { Component, OnInit } from '@angular/core';

import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { ProfessorHttpService } from '../../../../data-services/professor-http.service';

@Component({
  selector: 'check',
  templateUrl: './check.component.html',
})
export class CheckComponent implements OnInit {

  results = [];
  params: { firstName?: string, lastName?: string, idNum?: string, uid?: string, email?: string } = {
    firstName: '',
    lastName: '',
    idNum: '',
    uid: '',
    email: ''
  }

  start: Date = new Date();
  recordNum: number;
  enrollmentsData = {};
  importData = {};

  testEmailTo = '';

  fromStudentId: string;
  toStudentId: string;

  professorsData: IDocumentRef;

  constructor(private ss: ServiceHttpService,
    private pfs: ProfessorHttpService,
    private alerts: PopAlertsService,
    private cds: ConfirmationDialogService) { }

  ngOnInit() {
  }

  searchLdap() {
    this.ss.findLdap(this.params).subscribe((entries) => {
      this.results = entries;
    });
  }

  getEnrollments() {
    this.ss.getEnrollments(this.start).subscribe((data) => {
      this.enrollmentsData = data;
    })
  }

  getStudentEnrollments() {
    this.ss.getStudentEnrollments(this.recordNum).subscribe((data) => {
      this.enrollmentsData = data;
    })
  }

  importEnrollments() {
    this.ss.importEnrollments(this.start).subscribe((data) => {
      this.importData = data;
    })
  }

  // deadlinesReminders() {
  //   this.ss.deadlinesReminders().subscribe(() => {
  //   })
  // }

  // deadlinesRemindersTest() {
  //   this.ss.deadlinesRemindersTest().subscribe(() => {
  //   })
  // }

  updateStudentsStates() {
    this.launchClicked = true;
    this.ss.updateStudentsStates().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  leavesReminders() {
    this.ss.leavesReminders().subscribe(() => {
    })
  }

  sendTestEmail() {
    this.ss.sendTestEmail(this.testEmailTo).subscribe(() => {

    })
  }

  evaluateNoShow() {
    this.launchClicked = true;
    this.ss.evaluateNoShow().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }
  launchClicked = false;
  launchEvaluationList() {
    this.launchClicked = true;
    this.ss.launchEvaluationList().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  sendMidEvaluationReminders() {
    this.launchClicked = true;
    this.ss.sendMidEvaluationReminders().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  sendPendingEvaluationReminders() {
    this.launchClicked = true;
    this.ss.sendPendingEvaluationReminders().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  renewExternalTasksToken() {
    this.launchClicked = true;
    this.ss.renewExternalTasksToken().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  checkDeadlinesAndUpdate() {
    this.launchClicked = true;
    this.ss.checkDeadlinesAndUpdate().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  checkAnnualEnrollments() {
    this.launchClicked = true;
    this.ss.checkAnnualEnrollments().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  copyStudentsData() {
    this.launchClicked = true;
    this.ss.copyStudentsData({ from: this.fromStudentId, to: this.toStudentId }).subscribe(() => {
      this.alerts.saveSuccess();
    })
  }


  processId: string;
  regenerateThesisProcessRequestDoc() {
    this.launchClicked = true;
    this.ss.regenerateThesisProcessRequestDoc(this.processId).subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  permanentLeaveToNegativeEvaluationsStudents() {
    this.launchClicked = true;
    this.ss.permanentLeaveToNegativeEvaluationsStudents().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  updateTUTroles() {
    this.launchClicked = true;
    this.ss.updateTUTroles().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  deadlinesRemindersDate: Date;
  deadlinesRemindersLaunch() {
    if (!this.deadlinesRemindersDate) return;
    this.launchClicked = true;
    this.ss.deadlinesRemindersLaunch(this.deadlinesRemindersDate).subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  deadlineStudentsToPermanentLeave() {
    this.launchClicked = true;
    this.ss.deadlineStudentsToPermanentLeave().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  deactivatePermanentLeaveStudentsProcesses() {
    this.launchClicked = true;
    this.ss.deactivatePermanentLeaveStudentsProcesses().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  importProfessors() {
    this.cds.confirm({
      title: 'Importar profesores',
      message: '¿Estás seguro de que quieres importar los profesores? Se borrarán todos los profesores actuales.',
    }, () => {
      this.launchClicked = true;
      this.ss.importProfessors(this.professorsData).subscribe((res) => {
        console.log(res);
        this.alerts.saveSuccess();
      })
    })
  }

  setProfessorsUsers() {
    this.launchClicked = true;
    this.pfs.setProfessorsUsers().subscribe(() => {
      this.alerts.saveSuccess();
    })

  }

  launchCheckLeavesRenewals() {
    this.launchClicked = true;
    this.ss.launchCheckLeavesRenewals().subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

}
