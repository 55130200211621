import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IDocumentRef } from '../../../interfaces/IDocument';
import { DocumentService } from '../shared/document-service/document.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'document-verification',
  templateUrl: './document-verification.component.html',
})
export class DocumentVerificationComponent implements OnInit, OnDestroy {
  @ViewChild('f') f: NgForm

  notFound = false;
  verified = false;
  doc: IDocumentRef;

  csv: string;

  loading = false;

  constructor(private ds: DocumentService) { }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ds.csv = null;
  }

  verify() {
    if (this.f.invalid) return;

    this.loading = true;
    this.csv = this.csv.trim();
    this.ds.csv = this.csv;
    this.ds.verify(this.csv).subscribe((doc) => {
      if (doc) {
        this.doc = doc;
        this.verified = true;
      } else {
        this.notFound = true;
      }
      this.loading = false;
    }, (err) => {
      this.loading = false;
    })
  }

}
