<ng-container *ngIf="task">
  <task-header [task]="task"></task-header>

  <br>

  <thesis-committee-view [thesisCommittee]="task.thesisProcess.data.thesisCommittee"
    [showCV]="false"></thesis-committee-view>

  <div class="w-100 mb-4"></div>

  <form #f="ngForm">

    <div class="form-group">
      <label for="defenceDate">
        {{ 'defence-date' | translate }}
      </label>
      <date-picker id="defenceDate"
        name="defenceDate"
        [(ngModel)]="defenceDate"
        #defenceDateInput="ngModel"
        [isInvalid]="(defenceDateInput.invalid || dateError) && f.submitted"
        [closeOnSelect]="false"
        [time]="true"
        required="true"></date-picker>
      <validation-errors [show]="f.submitted"
        [control]="defenceDateInput"></validation-errors>
      <small [class.text-muted]="!dateError() || !f.submitted"
        [class.text-danger]="dateError() && f.submitted">
        {{ 'cant-be-earlier-than' | translate }}
        {{ minDefenceDate | moment:'DD/MM/YYYY' }}
      </small>
    </div>

    <div class="form-group">
      <label for="defencePlace">
        {{ 'defence-place' | translate }}
      </label>
      <textarea class="form-control"
        name="defencePlace"
        id="defencePlace"
        #defencePlaceInput="ngModel"
        rows="3"
        maxlength="400"
        required="true"
        [placeholder]="'defence-place-description' | translate"
        [(ngModel)]="defencePlace"
        [class.is-invalid]="defencePlaceInput.invalid && f.submitted"></textarea>
      <div class="text-right small text-muted">
        {{ defencePlace ? defencePlace.length : 0}}/400
      </div>
      <validation-errors [control]="defencePlaceInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="w-100 text-right">
      <button (click)="send()"
        class="btn btn-primary"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'accept' | translate }}
      </button>

    </div>
  </form>
</ng-container>