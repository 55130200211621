<div class="list-group"
  *ngIf="leaves && leaves.length > 0">
  <div class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
    *ngFor="let leave of leaves">
    <div>
      <div>
        <span class="badge"
          [class.badge-dark]="leave.type === LeaveType.permanent || leave.type === LeaveType.final"
          [class.badge-warning]="leave.type === LeaveType.temporal || leave.type === LeaveType.medical">
          <span *ngIf="leave.type === LeaveType.medical">
            <i class="fas fa-notes-medical"></i>
          </span>
          <span *ngIf="leave.type === LeaveType.temporal">
            <i class="fas fa-pause"></i>
          </span>
          <span *ngIf="leave.type === LeaveType.permanent || leave.type === LeaveType.final">
            <i class="fas fa-times-circle"></i>
          </span>
          {{ leave.type | translate }}
          <span *ngIf="leave.type === LeaveType.final && leave.reason">
            - {{ leave.reason | translate }}
          </span>
        </span>
      </div>
      <div *ngIf="leave.justification"
        style="max-width: 250px;">
        <div *ngFor="let doc of leave.justification">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
    </div>
    <div class="nowrap mt-1">
      {{ leave.start | moment:'D/M/YY' }}
      <span *ngIf="leave.end">
        <i class="far fa-angle-double-right mx-2"></i>
        {{ leave.end | moment:'D/M/YY' }}
      </span>
      <span *ngIf="leave.estimatedEnd && !leave.end"
        [tooltip]="'estimated-end-date' | translate">
        <i class="far fa-angle-right mx-2"></i>
        {{ leave.estimatedEnd | moment:'D/M/YY' }}
        <i class="far fa-info-circle ml-1 text-info"></i>
      </span>
      <span *ngIf="!leave.end"
        class="ml-2">
        <button class="btn btn-sm btn-outline-secondary mr-1"
          [routerLink]="['/app/student/processes/request-discharge/' + leave._id]"
          *ngIf="us.isStudent() && leave.type === LeaveType.medical && !leaveDischargeProcMap[leave._id]">
          {{ 'discharge' | translate }}
        </button>
        <button class="btn btn-sm btn-outline-secondary"
          [routerLink]="['/app/student/processes/request-renewal/' + leave._id]"
          *ngIf="us.isStudent() && leave.type === LeaveType.medical && !leaveRenewalProcMap[leave._id]">
          {{ 'renew' | translate }}
        </button>
        <span *ngIf="leaveDischargeProcMap[leave._id]">
          {{ 'discharge-requested' | translate }}
        </span>
        <span *ngIf="leaveRenewalProcMap[leave._id]">
          {{ 'renewal-requested' | translate }}
        </span>
      </span>

      <a role="button"
        [routerLink]="null"
        class="text-muted ml-2"
        (click)="onEdit.emit(leave._id)"
        *ngIf="editable && leave.type !== LeaveType.permanent && leave.type !== LeaveType.final">
        <i class="far fa-edit"></i>
      </a>

      <a role="button"
        [routerLink]="null"
        class="text-muted ml-2"
        (click)="onRemove.emit(leave._id)"
        *ngIf="editable && leave.type !== LeaveType.permanent && leave.type !== LeaveType.final">
        <i class="far fa-trash"></i>
      </a>
    </div>
  </div>
</div>
<div class="card-body pt-0"
  *ngIf="leaves && leaves.length <= 0">
  <i>
    {{ 'no-leaves' | translate }}
  </i>
</div>