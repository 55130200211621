<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="processes"
        icon="project-diagram">
      </section-title>

    </div>
  </div>

  <student-processes-view [student]="sr.student"
    [extensionRequestEnabled]="sr.procStates?.extension"
    [leaveRequestEnabled]="sr.procStates?.leave"></student-processes-view>

</div>