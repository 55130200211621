import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { ITask, Processes } from '../../../../../interfaces/ITask';
import { RefreshService } from '../../../data-services/refresh.service';
import { TaskHttpService } from '../../../data-services/task-http.service';
import { dropdownAnimation } from '../../../UI/animations/animations';
import { DropdownService } from '../../../UI/dropdown-service/dropdown.service';
import { LoadingIndicatorService } from '../../../UI/loading-indicator/loading-indicator.service';
import { UserService } from '../../../user/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tasks-dropdown',
  templateUrl: 'tasks-dropdown.component.html',
  animations: [dropdownAnimation]
})
export class TasksDropdownComponent implements OnInit, OnDestroy {
  @ViewChild('tasksDropdown') tasksDropdown: ElementRef;

  count = 0;
  tasks: ITask[] = [];
  size = 5;
  page = 0;

  loadingMore = false;

  firstTimeLoaded = false;

  processKey: Processes = null;
  role: string = null;

  roles: { value: string, label: string }[] = [];
  processKeys: { value: Processes, label: string }[] = [];

  rolesFilterCount: { [key: string]: number } = {};
  processKeysFilterCount: { [key: string]: number } = {};

  sub: Subscription;

  constructor(public dds: DropdownService,
    private ts: TaskHttpService,
    public translate: TranslateService,
    private loading: LoadingIndicatorService,
    private zone: NgZone,
    private rotuer: Router,
    private tcs: RefreshService,
    private us: UserService) { }

  ngOnInit() {
    this.roles = []

    this.us.user.roles.forEach((role) => {
      let roleValue = role.split('-')[0];
      if (roleValue.includes('supervisor')) roleValue = 'supervisor';
      if (this.roles.find(r => r.value === roleValue)) return;

      this.roles.push({ value: roleValue, label: this.translate.instant(roleValue) });
    });

    this.roles.push({
      value: 'assignedToMe',
      label: this.translate.instant('assignedToMe')
    })

    this.roles = _.sortBy(this.roles, 'label');


    this.processKeys = Object.values(Processes).filter(p => p !== Processes.defence).map(process => {
      return { value: process, label: this.translate.instant(`process.${process}.name`) };
    });

    this.processKeys = _.sortBy(this.processKeys, 'label');

    this.processKeys.forEach((process) => {
      this.ts.count({ processKey: process.value }).subscribe((count) => {
        this.processKeysFilterCount[process.value] = count;
      });
    });

    this.roles.forEach((role) => {
      this.ts.count({ role: role.value }).subscribe((count) => {
        this.rolesFilterCount[role.value] = count;
      });
    });

    this.ts.count({ processKey: this.processKey, role: this.role }).subscribe((count) => {
      this.count = count;
    });

    this.tcs.onTasksChange.subscribe(() => {
      this.ts.count({ processKey: this.processKey, role: this.role }).subscribe((count) => {
        this.count = count;
      });
      this.firstTimeLoaded = false;
    })
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  open() {
    if (!this.firstTimeLoaded) {
      this.page = 1;
      this.loading.start();
      this.ts.fetchTasks({ page: this.page, size: this.size, processKey: this.processKey, role: this.role }).subscribe((tasks) => {
        this.tasks = tasks;
        this.firstTimeLoaded = true;
        this.loading.stop();
        this.zone.run(() => {
          this.dds.toggle('tasksDropdown', this.tasksDropdown);
        });
      });
    } else {
      this.dds.toggle('tasksDropdown', this.tasksDropdown);
    }
  }

  filter() {
    this.page = 1;
    this.loading.start();
    this.ts.fetchTasks({ page: this.page, size: this.size, processKey: this.processKey, role: this.role }).subscribe((tasks) => {
      this.tasks = tasks;
      this.loading.stop();
      this.tcs.onTasksChange.next();
      this.zone.run(() => {
        this.dds.open('tasksDropdown');
      });
    });
  }

  loadMore() {
    if (this.loadingMore) return;
    this.page += 1;
    this.loadingMore = true;
    this.ts.fetchTasks({ page: this.page, size: this.size, processKey: this.processKey, role: this.role }).subscribe((tasks) => {
      this.tasks = this.tasks.concat(tasks);
      this.loadingMore = false;
    });
  }

  goToTask(task: ITask) {
    void this.rotuer.navigate([`/app/task/${task._id}`]);
    this.dds.close('tasksDropdown');
  }

}