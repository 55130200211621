import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'academicYear'
})
export class AcademicYearPipe implements PipeTransform {
  transform(value: String, ...args: any[]): any {
    if (!value || value.length !== 8) {
      return ""
    };

    return `${value.substring(0, 4)}/${value.substring(4)}`;
  }
}