<div class="w-100">
  <ng-content></ng-content>
</div>
<div class="w-100 text-center"
  *ngIf="loadingMore || !allLoaded">
  <div class="mt-2"
    [style.visibility]="loadingMore && !allLoaded ? 'visible' : 'hidden'">
    <i class="fas fa-circle-notch fa-spin text-primary"></i>
    {{'loading' | translate}}
  </div>
  <div class="mt-2"
    *ngIf="!loadingMore && !allLoaded && showLoadMoreButton">
    <a [routerLink]="null"
      class="text-muted"
      role="button"
      (click)="loadMore()">
      {{ 'load-more' | translate}}...
    </a>
  </div>
</div>