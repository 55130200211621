import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AppService {

  env: "production" | "certification" | "development";

  constructor(private http: HttpClient) {
    this.getEnvironment().subscribe((res) => {
      this.env = res.env;
    })
  }

  private getEnvironment(): Observable<{ env: "production" | "certification" | "development" }> {
    return this.http.get<{ env: "production" | "certification" | "development" }>("/api/config/env");
  }

}