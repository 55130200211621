import { Component, OnInit } from '@angular/core';
import { ProgramFileService } from '../program-file.service';
import { ProgramHttpService } from '../../../../../data-services/program-http.service';

@Component({
  selector: 'program-file-students',
  templateUrl: './program-file-students.component.html',
})
export class ProgramFileStudentsComponent implements OnInit {

  constructor(public pfs: ProgramFileService, private ps: ProgramHttpService) { }

  ngOnInit(): void {
  }

  dataFn() {
    return (params) => {
      return this.ps.fetchProgramStudents(this.pfs.program._id, params);
    }
  }

}
