<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <industrial-process-data-view [process]="process"></industrial-process-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [validateButtonText]="'validate'"
      [showErrors]="f.submitted"></validation-buttons>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn"
        [class.btn-primary]="validation.validate"
        [class.btn-danger]="!validation.validate"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        <span *ngIf="validation.validate">
          {{ 'validate' | translate }}
        </span>
        <span *ngIf="!validation.validate">
          {{ 'deny' | translate }}
        </span>
      </button>
    </div>

  </form>

</ng-container>