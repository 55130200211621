import { Injectable } from '@angular/core';
import { ICotutelleInfo } from '../../../interfaces/IStudent';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CotutelleHttpService {
  constructor(private http: HttpClient) { }

  saveCotutelleInfo(studentId: string, cotutelleInfo: ICotutelleInfo): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cotutelle/${studentId}`, cotutelleInfo);
  }

}