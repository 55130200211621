import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IStudent, RDType } from '../../../../../interfaces/IStudent';
import { ImpersonatingService } from '../../../data-services/impersonating.service';
import { RefreshService } from '../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../data-services/service-http.service';
import { UserService } from '../../../user/user.service';
import { StudentFileService } from './student-file.service';

@Component({
  selector: 'student-file',
  templateUrl: 'student-file.component.html'
})
export class StudentFileComponent implements OnInit, OnDestroy {
  student: IStudent;

  RDType = RDType

  sub: Subscription;
  refreshSub: Subscription;

  notFound = false;

  constructor(private route: ActivatedRoute,
    public sfs: StudentFileService,
    private is: ImpersonatingService,
    public us: UserService,
    private platformLocation: PlatformLocation,
    public ss: ServiceHttpService,
    private refresh: RefreshService) { }

  ngOnInit() {
    this.sfs.student = null;
    this.sfs.studentObs.next(null);
    this.route.params.subscribe((params) => {
      this.sfs.fetch(params.studentId);
    });

    this.refreshSub = this.refresh.onStudentChange.subscribe(() => {
      if (this.student) {
        this.sfs.fetch(this.student._id);
      }
    })

    this.sub = this.sfs.studentObs.pipe(filter(x => x !== null)).subscribe((student) => {
      this.student = student;
    }, () => {
      this.notFound = true;
    });

  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.refreshSub) this.refreshSub.unsubscribe();
  }

  impersonate() {
    this.is.impersonate(this.student.userId).subscribe(() => {
      let url = (this.platformLocation as any).location.origin;
      location.href = url;
    })
  }
}