<nav class="navbar navbar-expand navbar-light bg-white fixed-top">

  <ul class="navbar-nav d-inline-block d-md-none">
    <li class="nav-item">
      <a class="nav-link"
        role="button"
        (click)="toggleActive()">
        <i class="far fa-bars"></i>
      </a>
    </li>
  </ul>

  <a class="navbar-brand"
    role="button">
    <h4 class="text-primary logo">DAD</h4>
  </a>

  <roles-dropdown style="min-width: 0;"></roles-dropdown>

  <ul class="navbar-nav ml-auto">
    <tasks-dropdown></tasks-dropdown>

    <messages-dropdown></messages-dropdown>

    <user-dropdown></user-dropdown>
  </ul>

</nav>