import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, NgZone, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { zip } from 'rxjs';

import { IEvaluationCommittee } from '../../../../../interfaces/IEvaluationCommittee';
import { IStudent, StudentStateType } from '../../../../../interfaces/IStudent';
import { IUser } from '../../../../../interfaces/IUser';
import { DADUtils } from '../../../../../utils/DADUtils';
import { StringUtils } from '../../../../../utils/StringUtils';
import { ProgramHttpService } from '../../../data-services/program-http.service';
import { HelperService } from '../../../shared/helper-service/helper.service';
import { LoadingIndicatorService } from '../../../UI/loading-indicator/loading-indicator.service';
import { PopAlertsService } from '../../../UI/pop-alerts/pop-alerts.service';

@Component({
  selector: 'evaluation-committees',
  templateUrl: './evaluation-committees.component.html',
})
export class EvaluationCommitteesComponent implements OnInit {
  @ViewChildren('scrollViewport') private cdkVirtualScrollViewports: QueryList<CdkVirtualScrollViewport>;
  @Input() programId: string;

  committees: IEvaluationCommittee[];
  showCommittees: IEvaluationCommittee[];
  committeesMap: { [id: string]: IEvaluationCommittee };
  clonedCommitteesMap: { [id: string]: IEvaluationCommittee };
  editCommitteesMap: { [id: string]: boolean } = {};

  addMembersMap: { [ecId: string]: IUser } = {};

  studentsMap: { [ecId: string]: IStudent[] } = {};
  students: IStudent[] = [];
  searchStudentMap: { [ecId: string]: string } = {};

  searchResultsMap: { [ecId: string]: IStudent[] } = {};

  Math = Math;

  page = 0;
  elementsPerPage = 8;
  pages = [0];

  constructor(private ps: ProgramHttpService,
    public hs: HelperService,
    private alerts: PopAlertsService,
    private ts: TranslateService,
    private loading: LoadingIndicatorService,
    private zone: NgZone) { }

  ngOnInit() {
    this.fetch(() => {
      this.committees.forEach(com => {
        this.addMembersMap[com._id] = null;
      })
    });
  }

  fetch(cb?) {
    this.loading.start();
    zip(
      this.ps.evaluationCommittees(this.programId),
      this.ps.fetchAllProgramStudents(this.programId)
    ).subscribe((res) => {
      this.committees = res[0];
      this.committeesMap = {};

      const students = res[1];
      this.studentsMap = {};
      students.forEach(st => {
        this.students.push(st);
        if (!st.evaluationCommitteeId || [
          StudentStateType.defended,
          StudentStateType.permanentLeave,
          StudentStateType.finalLeave,
          StudentStateType.defenceAccepted,
          StudentStateType.defenceRequested,
          StudentStateType.processing,
          StudentStateType.processingAccepted,
          StudentStateType.processingRequested,
          StudentStateType.processingPendingRectification,
        ].includes(st.state)) {
          return;
        }
        if (!this.studentsMap[st.evaluationCommitteeId]) {
          this.studentsMap[st.evaluationCommitteeId] = [];
        }

        this.studentsMap[st.evaluationCommitteeId].push(st);
      })

      this.clonedCommitteesMap = {};
      this.committees.forEach(c => {
        this.committeesMap[c._id] = c;
        this.editCommitteesMap[c._id] = false;
        if (this.studentsMap[c._id]) {
          this.studentsMap[c._id] = _.orderBy(this.studentsMap[c._id], ['lastName', 'firstName'])
        }
      })

      // this.committees = _.orderBy(this.committees, c => {
      //   if (!this.studentsMap[c._id]) return 0;
      //   return this.studentsMap[c._id].length;
      // }, 'desc')

      this.students = _.orderBy(this.students, ['lastName', 'firstName'])

      this.updateShowCommittees();
      const numPages = Math.ceil(this.committees.length / this.elementsPerPage)
      this.pages = [];
      for (let i = 0; i < numPages; i++) {
        this.pages.push(i + 1);
      }


      if (cb) cb();
      this.loading.stop();
    })
  }

  editCommittee(com) {
    this.editCommitteesMap[com._id] = true;
    this.clonedCommitteesMap[com._id] = _.cloneDeep(com);
  }

  saveCommittee(com) {
    this.editCommitteesMap[com._id] = false;
    this.ps.updateEvaluationCommittee(this.programId, com._id, com.year, com.number).subscribe(() => {
      this.committeesMap[com._id] = com;
      this.committees.find(ec => ec._id === com._id).year = com.year;
      this.committees.find(ec => ec._id === com._id).number = com.number;
      this.alerts.pop({
        msg: this.ts.instant('evaluation-committee-updated', {
          ec: this.hs.ecCode(com)
        }),
        styleClass: 'success',
      })
    })
  }

  private updateShowCommittees() {
    this.showCommittees = this.committees.slice(this.page * this.elementsPerPage, this.page * this.elementsPerPage + this.elementsPerPage);
  }

  prev() {
    this.page--;
    if (this.page < 0) {
      this.page = 0;
    }
    this.updateShowCommittees();
    this.hs.scrollToTop();
  }

  next() {
    this.page++;
    if (this.page > this.pages.length - 1) {
      this.page = this.pages.length - 1;
    }
    this.updateShowCommittees();
    this.hs.scrollToTop();
  }

  setPage(p) {
    this.page = p;
    this.updateShowCommittees();
    this.hs.scrollToTop();
  }

  add() {
    this.ps.addEvaluationCommittee(this.programId).subscribe((com) => {
      this.fetch(() => {
        let find = this.showCommittees.find(ec => com._id == ec._id)
        if (find) {
          find.added = true;
        } else {
          this.setPage(0);
          find = this.showCommittees.find(ec => com._id == ec._id)
          if (find) find.added = true;
        }
      })
    })
  }

  restore(com: IEvaluationCommittee) {
    this.ps.restoreEvaluationCommittee(this.programId, com._id).subscribe(() => {
      com.deleted = false;
      com.added = true;
    })
  }

  remove(com: IEvaluationCommittee) {
    this.ps.removeEvaluationCommittee(this.programId, com._id).subscribe(() => {
      com.deleted = true;
      this.alerts.pop({
        msg: this.ts.instant('evaluation-committee-removed', {
          ec: this.hs.ecCode(com)
        }),
        styleClass: 'warning',
        dismissTime: 20000,
        action: {
          id: com._id,
          label: this.ts.instant('undo'),
          fn: () => {
            this.restore(com)
          }
        }
      })
    })
  }

  addMember(com: IEvaluationCommittee, member: IUser) {
    this.ps.addMemberToEvaluationCommittee(this.programId, com._id, member._id).subscribe(() => {
      this.fetch(() => {
        this.committees.find(ec => ec._id === com._id).members.find(m => m._id === member._id).added = true;
        this.alerts.pop({
          msg: this.ts.instant('evaluation-committee-member-added', {
            member: DADUtils.fullname(member),
            ec: this.hs.ecCode(com)
          }),
          styleClass: 'info',
        });
        if (this.addMembersMap[com._id]) {
          this.addMembersMap[com._id] = null;
        }
      });
    })
  }

  removeMember(com: IEvaluationCommittee, member: IUser) {
    this.ps.removeMemberFromEvaluationCommittee(this.programId, com._id, member._id).subscribe(() => {
      this.fetch(() => {
        this.alerts.pop({
          msg: this.ts.instant('evaluation-committee-member-removed', {
            member: DADUtils.fullname(member),
            ec: this.hs.ecCode(com)
          }),
          styleClass: 'warning',
          dismissTime: 20000,
          action: {
            id: com._id,
            label: this.ts.instant('undo'),
            fn: () => {
              this.addMember(com, member)
            }
          }
        })
      })
    })
  }

  unassignStudent(st: IStudent, com: IEvaluationCommittee) {
    this.ps.unassignStudentEvaluationCommittee(this.programId, com._id, st._id).subscribe(() => {
      this.studentsMap[com._id] = this.studentsMap[com._id].filter(student => student._id !== st._id);
      st.evaluationCommitteeId = null;
      this.alerts.pop({
        msg: this.ts.instant('evaluation-committee-student-unassigned', {
          student: DADUtils.fullname(st),
          ec: this.hs.ecCode(com)
        }),
        styleClass: 'warning',
        dismissTime: 20000,
        action: {
          id: com._id,
          label: this.ts.instant('undo'),
          fn: () => {
            this.assignStudent(st, com)
          }
        }
      })
    })
  }

  assignStudent(st: IStudent, com: IEvaluationCommittee) {
    this.ps.assignStudentEvaluationCommittee(this.programId, com._id, st._id).subscribe(() => {
      if (!this.studentsMap[com._id]) {
        this.studentsMap[com._id] = [];
      }
      this.studentsMap[com._id].push(st);
      this.studentsMap[com._id] = _.orderBy(this.studentsMap[com._id], ['lastName', 'firstName'])
      if (st.evaluationCommitteeId && this.studentsMap[st.evaluationCommitteeId]) {
        this.studentsMap[st.evaluationCommitteeId] = this.studentsMap[st.evaluationCommitteeId].filter(s => s._id !== st._id);
      }
      st.evaluationCommitteeId = com._id;
      this.search(com);
      this.alerts.pop({
        msg: this.ts.instant('evaluation-committee-student-assigned', {
          student: DADUtils.fullname(st),
          ec: this.hs.ecCode(com)
        }),
        styleClass: 'info',
      });
      setTimeout(() => {
        this.zone.run(() => {
          this.cdkVirtualScrollViewports.forEach(viewport => {
            viewport.checkViewportSize();
          })
        })
      }, 0);
    })
  }

  search(com: IEvaluationCommittee) {
    const max = 20;
    let added = 0;
    this.searchResultsMap[com._id] = this.students.filter(st => {
      let search = this.searchStudentMap[com._id];
      search = '^' + StringUtils.diacriticsRegex(search.toLowerCase());
      let stName = DADUtils.fullname(st);
      if (added < max && (!st.evaluationCommitteeId || st.evaluationCommitteeId !== com._id)
        && new RegExp(search).test(stName.toLowerCase())) {
        added++
        return true;
      } else {
        return false;
      }
    })
  }

}
