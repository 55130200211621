import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IPermanentLeaveProcess } from "../../../interfaces/IPermanentLeaveProcess";
import { Observable } from "rxjs";
import { IDocumentRef } from "../../../interfaces/IDocument";

@Injectable()
export class PermanentLeaveHttpService {

  constructor(private http: HttpClient) { }

  findProcess(taskId: string): Observable<IPermanentLeaveProcess> {
    return this.http.get<IPermanentLeaveProcess>(`/api/permanentLeave/findProcess/${taskId}`);
  }

  coordinatorReport(taskId: string, report: IDocumentRef): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/permanentLeave/coordinatorReport/${taskId}`, { report });
  }

}