<ng-container *ngIf="task">
  <task-header [task]="task"></task-header>

  <br>

  <div class="small text-muted mb-1">
    {{ 'program' | translate }}
  </div>
  <div class="mb-4">
    {{ task.thesisProcess.student.program.name }}
  </div>

  <div class="small text-muted mb-1">
    {{ 'student' | translate }}
  </div>
  <text-info icon="user"
    [mb]="4">
    {{ task.thesisProcess.student | fullname }}
  </text-info>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-title' | translate }}
    </div>
    <div>
      {{ task.thesisProcess.data.thesisTitle }}
    </div>
  </div>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <div class="form-group">
      <label for="evaluationSelect">
        {{ 'thesis-final-evaluation' | translate }}
      </label>
      <ng-select id="evaluationSelect"
        name="evaluationSelect"
        #evaluationSelect="ngModel"
        [(ngModel)]="evaluation"
        required
        [class.is-invalid]="f.submitted && evaluationSelect.invalid"
        [placeholder]="'select-thesis-final-evaluation' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="ThesisFinalEvaluation.fail">
          {{ ThesisFinalEvaluation.fail | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.pass">
          {{ ThesisFinalEvaluation.pass | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.bgrade">
          {{ ThesisFinalEvaluation.bgrade | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.agrade">
          {{ ThesisFinalEvaluation.agrade | translate }}
        </ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="evaluationSelect"></validation-errors>
    </div>

    <div class="form-group"
      *ngIf="task.thesisProcess.data.internationalMention === true">
      <label for="defenceLanguageSelect">
        {{ 'defence-language' | translate}}
      </label>
      <ng-select id="defenceLanguageSelect"
        name="defenceLanguageSelect"
        #defenceLanguageSelect="ngModel"
        [items]="languages"
        bindLabel="name"
        bindValue="code"
        [(ngModel)]="defenceLanguage"
        [required]="false"
        [class.is-invalid]="f.submitted && defenceLanguageSelect.invalid"
        [placeholder]="'select-defence-language' | translate"
        [searchable]="true"
        [clearable]="true">
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="defenceLanguageSelect"></validation-errors>
    </div>



    <div class="w-100 text-right">
      <button type="submit"
        class="btn btn-primary"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>


  </form>
</ng-container>