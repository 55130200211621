import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IDedicationRequestData } from '../../../../../../interfaces/IDedicationProcess';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { DedicationType } from '../../../../../../interfaces/IStudent';
import { DedicationHttpService } from '../../../../data-services/dedication-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'dedication-request',
  templateUrl: './dedication-request.component.html'
})
export class DedicationRequestComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('f') f: NgForm;

  requested = false;
  doc: IDocumentRef;

  data: IDedicationRequestData = {
    dedication: null
  };

  constructor(public sr: StudentRootService,
    private dedication: DedicationHttpService,
    private ds: DocumentService,
    private ts: TranslateService,
    private cds: ConfirmationDialogService,
    private refresh: RefreshService) {
    super();
  }

  ngOnInit() {
    if (this.sr.student.dedication == DedicationType.fullTime) {
      this.data.dedication = DedicationType.partTime;
    } else {
      this.data.dedication = DedicationType.fullTime
    }
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    this.dedication.request(this.sr.student._id, this.data).subscribe((doc: IDocumentRef) => {
      this.sendEnd.next();
      this.refresh.onStudentChange.next();
      this.requested = true;
      this.doc = doc;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
    });
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

}
