import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProfessor } from '../../../interfaces/IProfessor';
import { IDocumentRef } from '../../../interfaces/IDocument';
import { IProfessorProcess, IProfessorProcessData } from '../../../interfaces/IProfessorProcess';
import { StudentStateType } from '../../../interfaces/IStudent';

@Injectable()
export class ProfessorHttpService {
  constructor(private http: HttpClient) { }

  fetchProfessors(programId: string, params: { searchText?: string, page?: number, size?: number }): Observable<IProfessor[]> {
    return this.http.post<IProfessor[]>(`/api/professors/find/${programId}`, params);
  }

  updateProfessor(programId: string, professorId: string, professor: IProfessor): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/${professorId}/update`, { professor });
  }

  addProfessor(programId: string, professor: IProfessor): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/add/${programId}`, { professor });
  }

  markAsConfirmed(professorId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/${professorId}/markAsConfirmed`, {});
  }

  markAsNotConfirmed(professorId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/${professorId}/markAsNotConfirmed`, {});
  }

  deleteProfessor(professorId: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(`/api/professors/${professorId}/delete`);
  }

  setProfessorsUsers(): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/setProfessorsUsers`, {});
  }

  request(programId: string, data: IProfessorProcessData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/professors/request/${programId}`, { data });
  }

  findProcess(taskId: string): Observable<IProfessorProcess> {
    return this.http.get<IProfessorProcess>(`/api/professors/process/${taskId}`);
  }

  serviceDeny(taskId: string, denialMsg: string, end: boolean): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/process/serviceDeny/${taskId}`, { denialMsg, end });
  }

  serviceValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/process/serviceValidate/${taskId}`, {});
  }

  coordinatorReview(taskId: string, data: IProfessorProcessData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/professors/process/coordinatorReview/${taskId}`, { data });
  }

  postgraduateCommitteeDeny(taskId: string, denialMsg: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/process/postgraduateCommitteeDeny/${taskId}`, { denialMsg });
  }

  postgraduateCommitteeValidate(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/process/postgraduateCommitteeValidate/${taskId}`, {});
  }

  getProcesses(programId?: string): Observable<IProfessorProcess[]> {
    return this.http.get<IProfessorProcess[]>(`/api/professors/processes/${programId}`);
  }

  professorsStudentsRatio(programId: string): Observable<{ ratio: number, professorsCount: number, studentsCount: number }> {
    return this.http.get<{ ratio: number, professorsCount: number, studentsCount: number }>(`/api/professors/professorRatio/${programId}`);
  }

  professorCheckIdNum(idNum: string, programId: string): Observable<IProfessor[]> {
    return this.http.post<IProfessor[]>(`/api/professors/checkIdNum/${programId}`, { idNum });
  }

  professorCheckEmail(email: string, programId: string): Observable<IProfessor[]> {
    return this.http.post<IProfessor[]>(`/api/professors/checkEmail/${programId}`, { email });
  }

  relations(programId: string, professorId: string): Observable<{ studentId: string, name: string, program: string, role: string, group: string, groupId?: string, state: StudentStateType, altStates: StudentStateType[] }[]> {
    return this.http.get<{ studentId: string, name: string, program: string, role: string, group: string, groupId?: string, state: StudentStateType, altStates: StudentStateType[] }[]>(`/api/professors/relations/${programId}/${professorId}`);
  }

  professorEnd(programId: string, professorId: string, endDate: Date): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/professorEnd/${programId}/${professorId}`, { endDate });
  }


}