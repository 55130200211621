import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IEvaluationCommittee } from '../../../../../../../interfaces/IEvaluationCommittee';
import { IEvaluationProcess } from '../../../../../../../interfaces/IEvaluationProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { EvaluationHttpService } from '../../../../../data-services/evaluation-http.service';
import { ProgramHttpService } from '../../../../../data-services/program-http.service';
import { HelperService } from '../../../../../shared/helper-service/helper.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'evaluation-committee-confirmation',
  templateUrl: './evaluation-committee-confirmation.component.html',
})
export class EvaluationCommitteeConfirmationComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;
  @Input() task: ITask;
  @Input() redirect = true;
  @Output() onComplete = new EventEmitter<string>();

  process: IEvaluationProcess;

  evaluationCommittees: IEvaluationCommittee[] = [];
  selected: IEvaluationCommittee;
  original: IEvaluationCommittee;

  constructor(private evs: EvaluationHttpService,
    private ps: ProgramHttpService,
    private hs: HelperService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    if (!this.task) return;
    this.evs.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
      this.ps.evaluationCommittees(process.student.programId).subscribe((res) => {
        this.evaluationCommittees = res.map(r => {
          return {
            ...r,
            code: this.hs.ecCode(r)
          }
        });

        if (process.student.evaluationCommitteeId) {
          this.selected = this.evaluationCommittees.find(ec => ec._id === process.student.evaluationCommitteeId);
          this.original = this.evaluationCommittees.find(ec => ec._id === process.student.evaluationCommitteeId);
        }
      })
    })
  }

  manageSend() {
    if (!this.f.valid || this.selected.membersIds.length <= 0) {
      this.sendEnd.next();
      return;
    }

    this.evs.evaluationCommitteeConfirmation(this.task._id, this.selected._id).subscribe(() => {
      this.sendEnd.next();
      if (this.redirect) {
        void this.router.navigate(['/app/task-completed']);
      }
      this.onComplete.emit();
    })
  }

}
