<div class="list-group"
  *ngIf="enrollments && enrollments.length > 0">
  <div class="list-group-item"
    *ngFor="let enrollment of enrollments">
    <div class="d-flex w-100 justify-content-between">
      <span><strong>{{ enrollment.year | academicYear }}</strong></span>
      <span>{{ enrollment.date | moment:'D/M/YY'}}</span>
    </div>
  </div>
</div>
<div *ngIf="enrollments && enrollments.length <= 0"
  class="mb-4">
  <i>
    {{ 'no-enrollments' | translate }}
  </i>
</div>