import { Component, Input, OnInit } from '@angular/core';

import { ITask } from '../../../../interfaces/ITask';
import { IThesisProcess, ThesisReportEvaluation } from '../../../../interfaces/IThesisProcess';
import { IActivity } from '../../../../interfaces/IActivity';
import { ExternalTaskHttpService } from '../../data-services/external-task-http.service';

@Component({
  selector: 'thesis-defence-info',
  templateUrl: './thesis-defence-info.component.html',
})
export class ThesisDefenceInfoComponent implements OnInit {

  @Input() task: ITask;
  @Input() token: string;
  process: IThesisProcess;
  activities: IActivity[];

  ThesisReportEvaluation = ThesisReportEvaluation;

  constructor(private ts: ExternalTaskHttpService) { }

  ngOnInit() {
    this.process = this.task.thesisProcess;
    this.activities = [];
  }

  fetchFn() {
    return (params) => {
      params = {
        ...params
      }
      return this.ts.fetchStudentActivities(this.task._id, this.token, params);
    }
  }
}
