<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="annual-evaluation"
        icon="file-search">
      </section-title>

    </div>
  </div>

  <ng-container *ngIf="sr.ready">
    <student-evaluation-view [student]="sr.student"
      [requestEnabled]="sr.procStates?.evaluation"></student-evaluation-view>
  </ng-container>

</div>