import { IDocumentRef } from './IDocument';
import { IEvaluationCommittee } from './IEvaluationCommittee';
import { IFaculty } from './IFaculty';
import { LeaveType } from './ILeaveProcess';
import { ILineOfResearch } from './ILineOfResearch';
import { IPermissions } from './IPermissions';
import { IProgram } from './IProgram';
import { Processes } from './ITask';
import { IThesisCommitteeMemberData, ThesisFinalEvaluation, ThesisReportEvaluation } from './IThesisProcess';
import { IUser } from './IUser';

export type IActiveProcesses = {
  [key in Processes]: number
}

export interface IExtraExtension {
  _id?: string,
  description: string,
  justification: IDocumentRef[],
  start: Date,
  end: Date
}

export interface ILeaves {
  _id?: string,
  type: LeaveType,
  justification: IDocumentRef[],
  start: Date,
  end?: Date,
  estimatedEnd?: Date,
  mustEvaluate: boolean,
  processId?: string,
  reason?: FinalLeaveReason
}

export enum FinalLeaveReason {
  NEGATIVE_EVALUATIONS = "FINAL_LEAVE_REASON_NEGATIVE_EVALUATIONS",
  MISSING_ENROLLMENTS = "FINAL_LEAVE_REASON_MISSING_ENROLLMENTS",
  DEADLINE = "FINAL_LEAVE_REASON_DEADLINE",
  STUDENT_DECISION = "FINAL_LEAVE_REASON_STUDENT_DECISION",
}

export interface IDisability {
  _id?: string,
  justification: IDocumentRef[],
  start: Date,
  end?: Date,
}

export interface IExtensions {
  _id?: string,
  date: Date,
  justification: IDocumentRef[],
  order: "first" | "second";
}

export interface ICDS {
  document: IDocumentRef,
  date?: Date,
  startDedication: DedicationType,
  needsSupplement: boolean,
  supplement?: {
    credits: number,
    document?: IDocumentRef,
    notes?: string
  },
  changes: ICDSChange[],
  partialTimeDoc?: IDocumentRef[],
  thirdSupervisorDoc?: IDocumentRef
}

export interface ICDSChange {
  _id?: string,
  date: Date,
  dedicationChanged: boolean,
  dedicationChangedTo?: DedicationType,
  partialTimeDoc?: IDocumentRef[],
  directionChanged: boolean,
  thirdSupervisorDoc?: IDocumentRef,
  document?: IDocumentRef,
  industrialChanged?: boolean,
  industrialInfo?: IIndustrialMentionInfo
}

export interface IEvaluation {
  _id?: string,
  requestedAt?: Date,
  registeredAt: Date,
  year: string,
  period: EvaluationPeriodType,
  evaluation: EvaluationType,
  presidentId?: string,
  secretaryId?: string,
  memberId?: string,
  reports: IDocumentRef[],
  committeeReport?: IDocumentRef,
  researchPlan?: IDocumentRef,
  recordFile?: IDocumentRef,
  oldRecordFiles?: IDocumentRef[],
  activityId: string,
  processId?: string

  // populate
  president?: IUser,
  secretary?: IUser,
  member?: IUser
}

export interface IThesis {
  thesisTitle: string,
  thesisDoc: IDocumentRef,
  thesisSummaryDoc: IDocumentRef,
  cv: IDocumentRef,
  publications: IDocumentRef[],
  cotutelle: boolean,
  cotutelleStayEvidence?: IDocumentRef[],
  cotutelleStayEvidenceOut?: IDocumentRef[],
  cotutelleDefenceOut?: boolean,
  cotutelleComposition?: IDocumentRef,
  cotutelleUniversity?: string,
  compendium: boolean,
  coauthorsAutorization?: IDocumentRef[],
  compendiumDirectorReport?: IDocumentRef[],
  internationalMention: boolean,
  internationalStayEvidence?: IDocumentRef[],
  industrialMention: boolean,
  industrialMentionEvidence?: IDocumentRef[],
  needsEthicsReport: boolean,
  ethicsReport?: IDocumentRef,
  thesisCommittee?: {
    president: IThesisCommitteeMemberData,
    secretary: IThesisCommitteeMemberData,
    member: IThesisCommitteeMemberData,
    alt1: IThesisCommitteeMemberData,
    alt2: IThesisCommitteeMemberData,
    alt3: IThesisCommitteeMemberData
  },
  externalEvaluators?: {
    ev1: IThesisCommitteeMemberData,
    ev2: IThesisCommitteeMemberData,
    ev3: IThesisCommitteeMemberData
  },
  selectedEvaluators?: {
    ev1: IThesisCommitteeMemberData,
    ev2: IThesisCommitteeMemberData
  }
  reports: {
    memberId: string,
    memberName: string,
    evaluation: ThesisReportEvaluation,
    docRef: IDocumentRef
  }[]

  thesisFinal: IDocumentRef,
  changesReport: IDocumentRef,

  pgcDefenceApprobalDate?: Date,
  acceptedForProcessingDate: Date,

  defenceDate: Date,
  defencePlace: string,

  secretaryEvaluation?: ThesisFinalEvaluation;
  defenceLanguage?: string,

  thesisFinalEvaluation: ThesisFinalEvaluation;
  cumlaudeMention: boolean;
  cumlaudeVotes?: boolean[];
  thesisDefenceRecord: IDocumentRef;
  internationalMentionFinal: boolean;
  industrialMentionFinal: boolean;
  finalThesisCommittee?: {
    president: IThesisCommitteeMemberData,
    secretary: IThesisCommitteeMemberData,
    member: IThesisCommitteeMemberData,
  },
  specialAdward?: boolean;
  specialAdwardDocs?: IDocumentRef[];
  videoConference?: boolean;
  videoConferenceDocs?: IDocumentRef[];
  hybridDefence?: boolean;
  hybridDefenceDocs?: IDocumentRef[];
  riuma?: IDocumentRef;
  teseo?: IDocumentRef;
  designation?: IDocumentRef;
  embargo?: IDocumentRef;
  additionalReports?: IDocumentRef[];
}

export interface ICotutelleInfo {
  cotutelle: boolean,
  cotutelleUniversity?: string,
  cotutelleDirectors?: string,
  cotutelleAgreementDate?: Date,
  cotutelleAgreementDoc?: IDocumentRef[]
}

export interface IIndustrialMentionInfo {
  industrialMention: boolean,
  agreementDocs?: IDocumentRef[],
  agreementDate?: Date,
  organization?: string,
  organizationContact?: string
}

export interface IScholarship {
  _id?: string,
  type: string,
  otherType?: string,
  startDate?: Date,
  endDate?: Date,
  documents: IDocumentRef[],
}

export interface IStudent {
  _id?: string,
  userId: string,
  rd: RDType,
  idNum: string,
  firstName: string,
  lastName: string,
  email: string,
  programId?: string,
  lineOfResearchId?: string,
  tutorId?: string,
  supervisor1Id?: string,
  supervisor2Id?: string,
  supervisor3Id?: string,
  facultyId?: string,
  enrollmentDate?: Date,
  deadline?: Date,
  deadlineLog?: string,
  legacyDeadlineCalc: boolean,
  dedication: DedicationType,
  state: StudentStateType,
  altStates: StudentStateType[],
  enrollments: IEnrollment[],
  recordNum?: number,
  extensions: IExtensions[],
  leaves: ILeaves[],
  evaluations: IEvaluation[],
  extraExtensions: IExtraExtension[],
  disabilities: IDisability[],
  disability: boolean,
  evaluationCommitteeId?: string,
  cds?: ICDS,
  thesis?: IThesis,
  customPermissions?: {
    endDate: Date,
    permissions: Partial<IPermissions>
  },
  transfer?: boolean,
  transferUniversity?: string,
  transferDocs?: IDocumentRef[],
  transferFirstEnrollment?: Date,

  cotutelleInfo?: ICotutelleInfo,
  industrialMentionInfo?: IIndustrialMentionInfo,
  scholarships?: IScholarship[],

  // Populate
  user?: IUser,
  program?: IProgram,
  lineOfResearch?: ILineOfResearch,
  tutor?: IUser,
  supervisor1?: IUser,
  supervisor2?: IUser,
  supervisor3?: IUser,
  faculty?: IFaculty,
  evaluationCommittee?: IEvaluationCommittee,
  fullName?: string,
  permissions?: IPermissions
}

export interface IEnrollment {
  year: string,
  date: Date,
  online: boolean
}

export enum DedicationType {
  fullTime = "FULL",
  partTime = "PART"
}

export enum StudentStateType {
  missingEnrollments = "A_MISSING_ENROLLMENTS",
  negativeEvaluations = "B_NEGATIVE_EVALUATIONS",
  deadline = "C_DEADLINE",
  processingRequested = "D_PROCESSING_REQUESTED",
  processing = "E_PROCESSING",
  processingPendingRectification = "F_PROCESSING_PENDING_RECTIFICATION",
  processingAccepted = "F_PROCESSING_ACCEPTED",
  defenceRequested = "G_DEFENCE_REQUESTED",
  defenceAccepted = "H_DEFENCE_ACCEPTED",
  initial = "I_INITIAL",
  temporalLeave = "J_TEMP_LEAVE",
  medicalLeave = "K_MEDICAL_LEAVE",
  active = "L_ACTIVE",
  defended = "M_DEFENDED",
  permanentLeave = "N_PERM_LEAVE",
  finalLeave = "O_FINAL_LEAVE"
}

export enum EvaluationPeriodType {
  march = "MARCH",
  september = "SEPTEMBER"
}

export enum EvaluationType {
  noShow = "NO_SHOW",
  positive = "POSITIVE",
  negative = "NEGATIVE"
}

export enum RDType {
  rd1393 = "RD13932007",
  rd99 = "RD992011",
  rd99Mod = "RD992011MOD"
}