import { Pipe, PipeTransform } from '@angular/core';

import { IStudent } from '../../../../interfaces/IStudent';
import { IUser } from '../../../../interfaces/IUser';
import { DADUtils } from '../../../../utils/DADUtils';

@Pipe({
  name: 'fullname'
})

export class FullnamePipe implements PipeTransform {
  transform(value: IUser | IStudent, ...args: any[]): any {
    return DADUtils.fullname(value);
  }
}