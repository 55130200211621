import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LoadingIndicatorService } from '../loading-indicator/loading-indicator.service';
import { PopAlertsService } from '../pop-alerts/pop-alerts.service';
import { HttpErrorObservable } from './http-error-observable';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private pas: PopAlertsService,
    private loading: LoadingIndicatorService,
    private translate: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        this.loading.stop();
        if (error.status === 401) {
          this.pas.info(this.translate.instant('not-authorized'));
        } else {
          this.pas.error(this.translate.instant('unexpected-error'));
        }
        HttpErrorObservable.getInstance().errors.next(error);
        return throwError(error);
      })
    )
  }

}