import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDedicationProcess } from '../../../../../../../interfaces/IDedicationProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { DedicationHttpService } from '../../../../../data-services/dedication-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { UserService } from '../../../../../user/user.service';

@Component({
  selector: 'dedication-ac-validation',
  templateUrl: './dedication-ac-validation.component.html',
})
export class DedicationAcValidationComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IDedicationProcess;
  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  canComplete: boolean = null;

  constructor(private dedication: DedicationHttpService,
    private us: UserService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.canComplete = this.us.canComplete(this.task);
    this.dedication.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return
    }

    if (!this.validation.validate) {
      this.dedication.acDeny(this.task._id, { denialMsg: this.validation.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.dedication.acValidate(this.task._id).subscribe((res) => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
