import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IStudent } from '../../../../../../../interfaces/IStudent';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogService } from '../../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { UserService } from '../../../../../user/user.service';
import { LeaveType } from '../../../../../../../interfaces/ILeaveProcess';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import * as moment from 'moment-timezone';
import { DADUtils } from '../../../../../../../utils/DADUtils';
import { IDischargeProcess } from '../../../../../../../interfaces/IDischargeProcess';
import { DischargeHttpService } from '../../../../../data-services/discharge-http.service';
import { IRenewalProcess } from '../../../../../../../interfaces/IRenewalProcess';
import { RenewalHttpService } from '../../../../../data-services/renewal-http.service';

@Component({
  selector: 'leaves-card',
  templateUrl: './leaves-card.component.html',
})
export class LeavesCardComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() student: IStudent;

  private _temporalLeavesLimitReached = false;
  public get temporalLeavesLimitReached() {
    this.temporalLeavesLimitReached = this.student.leaves.filter(l => l.type === LeaveType.temporal).length >= 2;
    return this._temporalLeavesLimitReached;
  }
  public set temporalLeavesLimitReached(value) {
    this._temporalLeavesLimitReached = value;
  }

  leaveId: string;
  type: LeaveType;
  justification: IDocumentRef[];
  start: Date;
  end: Date;
  estimatedEnd: Date;

  open = false;

  LeaveType = LeaveType;

  DADUtils = DADUtils;

  leaveDischargeProcMap: { [leaveId: string]: IDischargeProcess } = {};
  leaveRenewalProcMap: { [leaveId: string]: IRenewalProcess } = {};

  constructor(public sh: ServiceHttpService,
    public us: UserService,
    private refresh: RefreshService,
    private alerts: PopAlertsService,
    private confirmation: ConfirmationDialogService,
    private ts: TranslateService,
    private discharge: DischargeHttpService,
    private renewal: RenewalHttpService) { }

  ngOnInit() {
    this.discharge.getTasksTable(this.student._id).subscribe((dischargeProcesses) => {
      dischargeProcesses.forEach(dp => {
        this.leaveDischargeProcMap[dp.leaveId] = dp;
      })
    })

    this.renewal.getTasksTable(this.student._id).subscribe((renewalProcesses) => {
      renewalProcesses.forEach(rp => {
        this.leaveRenewalProcMap[rp.leaveId] = rp;
      })
    })
  }

  openEdit(leaveId) {
    this.reset();
    const leave = this.student.leaves.find(e => e._id === leaveId);
    if (leave) {
      this.leaveId = leaveId;
      this.type = leave.type;
      this.justification = [...leave.justification];
      this.start = leave.start;
      this.end = leave.end;
      this.estimatedEnd = leave.estimatedEnd;
      this.open = true;
    }
  }

  reset() {
    this.leaveId = null;
    this.justification = [];
    this.start = null;
    this.end = null;
    this.estimatedEnd = null;
  }

  save() {
    if (!this.f.valid
      || (this.type === LeaveType.temporal && !this.leaveId && this.temporalLeavesLimitReached)
      || !this.validDateRange()) {
      return;
    }

    if (this.leaveId) {
      this.sh.editLeave({
        studentId: this.student._id,
        leaveId: this.leaveId,
        justification: this.justification,
        start: this.start,
        end: this.end,
        estimatedEnd: this.estimatedEnd,
        mustEvaluate: false
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    } else {
      this.sh.addLeave({
        studentId: this.student._id,
        type: this.type,
        justification: this.justification,
        start: this.start,
        end: this.end,
        estimatedEnd: this.estimatedEnd,
        mustEvaluate: false
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    }
  }

  remove(leaveId: string) {
    this.confirmation.confirm({
      title: this.ts.instant('remove-leave'),
      message: this.ts.instant('are-you-sure-to-remove-leave'),
      btnOkText: this.ts.instant('remove')
    }, () => {
      this.sh.removeLeave({
        studentId: this.student._id,
        leaveId,
      }).subscribe(() => {
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    })
  }

  validDateRange() {
    if (this.type === LeaveType.temporal && this.start && this.end) {
      return moment(this.end).diff(this.start, 'months', true) >= 6 && moment(this.end).diff(this.start, 'months', true) <= 12
    } else if (this.type === LeaveType.medical && this.start && this.end) {
      return moment(this.end).isSameOrAfter(this.start)
    } else if (this.type === LeaveType.medical && this.start) {
      return true;
    }

    return false;
  }


}
