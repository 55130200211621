<div class="mb-3"
  [class.small]="sm"
  *ngIf="user">

  <div class="d-flex justify-content-between align-items-center"
    [class.small]="sm">
    <text-info icon="user">
      {{ user.firstName }} {{ user.lastName }}
    </text-info>
    <a [routerLink]="null"
      role="button"
      [queryParamsHandling]="'merge'"
      class="text-muted ml-2"
      (click)="resetFind()">
      <small class="nowrap">
        <i class="far fa-times"></i>
        <span class="d-none d-md-inline">
          {{ 'modify' | translate }}
        </span>
      </small>
    </a>
  </div>

  <text-info icon="envelope"
    *ngIf="user.email">
    <email-link [user]="user"></email-link>
  </text-info>

</div>

<ng-container *ngIf="!user">
  <div [class.mb-1]="notFound"
    [class.form-group]="!notFound">
    <div class="input-group"
      [class.input-group-sm]="sm">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="far fa-envelope fa-fw"></i>
        </span>
      </div>
      <input type="text"
        class="form-control"
        [placeholder]="'search-by-email' | translate"
        name="email"
        [(ngModel)]="email"
        (ngModelChange)="notFound = false"
        [required]="required"
        [pattern]="hs.EMAIL_REGEX"
        #emailInput="ngModel"
        (keydown.enter)="$event.preventDefault()"
        (keyup.enter)="$event.preventDefault(); search()"
        [class.is-invalid]="showErrors && emailInput.invalid">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary"
          type="button"
          (click)="search()">
          <i class="far fa-search fa-fw"></i>
        </button>
      </div>
    </div>
    <small class="form-text text-info"
      *ngIf="notFound && !invalid">
      <i class="far fa-info-circle mr-1"></i>
      {{ 'email-not-found' | translate }}
    </small>
    <small class="form-text text-danger"
      *ngIf="notFound && invalid">
      <i class="far fa-info-circle mr-1"></i>
      Formato no válido
    </small>
  </div>

</ng-container>