import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { ActivityType, ActivityValidationStateType, IActivity } from '../../../../../../interfaces/IActivity';


@Component({
  selector: 'edit-activity',
  templateUrl: 'edit-activity.component.html'
})
export class EditActivityComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() activity: IActivity;
  @Input() active = false;

  @Output() onInsert = new EventEmitter<IActivity>();
  @Output() onUpdate = new EventEmitter<IActivity>();

  activityTypes = [];

  attachedFiles: {
    documentRef: string,
    fileName: string
  }[] = [];

  constructor(public translate: TranslateService, private zone: NgZone) { }

  ngOnInit() {
    this.activityTypes = [];
    const forbidden = [ActivityType.ThesisDefence, ActivityType.AnnualEvaluation, ActivityType.AcceptanceForProcessing];
    Object.values(ActivityType).forEach(v => {
      if (!_.includes(forbidden, v)) {
        this.activityTypes.push({
          value: v,
          name: this.translate.instant(v)
        });
      }
    });
  }

  reset() {
    this.zone.run(() => {
      if (!this.editing()) {
        this.activity = {
          studentId: null,
          order: -1,
          createdAt: null,
          validated: false,
          evaluated: false,
          validationState: ActivityValidationStateType.Draft,
          deleted: false,
          title: '',
          description: null,
          activityType: null,
          startDate: null,
          endDate: null,
          attached: []
        }
        this.attachedFiles = [];
      } else {

      }
    });
  }

  save() {
    if (!this.f.valid) return;

    if (this.editing()) {
      this.onUpdate.emit(this.activity);
    } else {
      // convert attachedFiles
      for (let file of this.attachedFiles) {
        this.activity.attached.push(file);
      }
      this.onInsert.emit(this.activity);
    }
  }

  editing(): boolean {
    if (this.activity && this.activity._id) {
      return true;
    } else {
      return false;
    }
  }
}