<ng-container *ngIf="us.isService() || (hasCustomPermissions() && us.isStudent())">
  <div class="card">

    <div class="card-body">
      <legend class="m-0 d-flex justify-content-between align-items-center"
        style="border: 0">
        <span>
          {{ 'custom-permissions' | translate }}
        </span>
        <button type="button"
          class="btn btn-link"
          (click)="doOpen();"
          *ngIf="us.isService() && sh.editMode">
          {{ 'edit' | translate }}
        </button>
      </legend>
    </div>
    <div class="list-group">
      <ng-container *ngIf="hasCustomPermissions(); else noCustomPermissions">
        <ng-container *ngFor="let permission of permissionsTypeArray">
          <div class="list-group-item"
            *ngIf="student.customPermissions.permissions[permission] === true">
            {{ permission | translate }}
          </div>
        </ng-container>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          <div>
            {{ 'end-date' | translate }}:
            <strong>{{ student.customPermissions.endDate | moment: 'DD/MM/YYYY' }}</strong>
          </div>
          <button type="button"
            class="btn btn-link"
            (click)="remove();"
            *ngIf="us.isService() && sh.editMode">
            {{ 'remove' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-template #noCustomPermissions>
        <div class="list-group-item">
          <i>
            {{ 'no-custom-permissions' | translate }}
          </i>
        </div>
      </ng-template>
    </div>


  </div>

  <div class="mb-3"></div>

</ng-container>

<modal [(open)]="open"
  [title]="'edit-permissions' | translate">

  <form #f="ngForm"
    id="permissionsForm"
    (ngSubmit)="save()"
    *ngIf="open">

    <div class="form-group">
      <label for="endDate">
        {{ 'end-date' | translate }}
      </label>

      <date-picker id="endDate"
        name="endDate"
        required="false"
        [placeholder]="'end-date' | translate"
        [isInvalid]="endDateInput.invalid && f.submitted"
        #endDateInput="ngModel"
        [future]="true"
        [time]="false"
        [(ngModel)]="customPermissions.endDate"></date-picker>
      <validation-errors [control]="endDateInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group form-check"
      *ngFor="let p of permissionsTypeArray">
      <input type="checkbox"
        class="form-check-input"
        [name]="p"
        [id]="p"
        [disabled]="alreadyInStudentsPermissions(p) || alreadyActive(p) || DADUtils.countProcesses(activeProcesses) > 0"
        [(ngModel)]="customPermissions.permissions[p]">
      <label class="form-check-label"
        [for]="p">
        {{ p | translate }}

        <span *ngIf="alreadyActive(p)"
          class="small text-muted ml-2">
          ({{ 'already-active' | translate }})
        </span>
        <span
          *ngIf="p === PermissionsType.requestThesis && DADUtils.countProcesses(activeProcesses) > 0 && !alreadyActive(p)"
          class="small text-muted ml-2">
          ({{ 'there-are-active-processes' | translate }})
        </span>

      </label>
    </div>
  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="permissionsForm">{{ 'save' | translate }}</button>
  </div>


</modal>