import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgToggleModule } from 'ng-toggle-button';

import { AppService } from '../app.service';
import { ExternalTaskHttpService } from '../data-services/external-task-http.service';
import {
  ExternalEvaluatorReportComponent,
} from '../external-task/external-evaluator-report/external-evaluator-report.component';
import {
  ThesisCommitteeAcceptanceComponent,
} from '../external-task/thesis-committee-acceptance/thesis-committee-acceptance.component';
import {
  ThesisCommitteeAltAcceptanceComponent,
} from '../external-task/thesis-committee-alt-acceptance/thesis-committee-alt-acceptance.component';
import { ThesisDefenceDateComponent } from '../external-task/thesis-defence-date/thesis-defence-date.component';
import { ThesisDefenceInfoComponent } from '../external-task/thesis-defence-info/thesis-defence-info.component';
import {
  ThesisExternalAcceptanceComponent,
} from '../external-task/thesis-external-acceptance/thesis-external-acceptance.component';
import { ThesisPgcInfoComponent } from '../external-task/thesis-pgc-info/thesis-pgc-info.component';
import { PublicFooterComponent } from '../public-footer/public-footer.component';
import {
  ThesisCommitteeViewComponent,
} from '../root/components/process/thesis-process/thesis-committee-view/thesis-committee-view.component';
import {
  ThesisDataViewComponent,
} from '../root/components/process/thesis-process/thesis-data-view/thesis-data-view.component';
import { DirectionTeamViewComponent } from '../root/components/students/direction-team-view/direction-team-view.component';
import { TaskHeaderComponent } from '../root/components/task-header/task-header.component';
import { AcademicYearPipe } from '../UI/academic-year-pipe/academic-year.pipe';
import { AlertIconComponent } from '../UI/alert-icon/alert-icon.component';
import { SmoothHeightAnimDirective } from '../UI/animations/smooth-height-directive/smooth-height.directive';
import { CanDeactivateGuard } from '../UI/can-deactivate-guard/can-deactivate-guard.service';
import { CapitalizeFirstPipe } from '../UI/capitalize-first/capitalizefirst.pipe';
import { ConfirmationDialogComponent } from '../UI/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from '../UI/confirmation-dialog/confirmation-dialog.service';
import { DatePickerComponent } from '../UI/date-picker/date-picker.component';
import { DisableButtonDirective } from '../UI/disable-button-directive/disable-button.directive';
import { DocumentLinkComponent } from '../UI/document-link/document-link.component';
import { DropFileDirective } from '../UI/drop-file-directive/drop-file.directive';
import { DropdownService } from '../UI/dropdown-service/dropdown.service';
import { EvaluationYearPipe } from '../UI/evaluation-year-pipe/evaluation-year.pipe';
import { FileIconComponent } from '../UI/file-icon/file-icon.component';
import { FileInputComponent } from '../UI/file-input/file-input.component';
import { FilesQueueComponent } from '../UI/files-queue/files-queue.component';
import { FullnamePipe } from '../UI/fullname-pipe/fullname.pipe';
import { InfiniteListComponent } from '../UI/infinite-list/infinite-list.component';
import { LoadingIndicatorComponent } from '../UI/loading-indicator/loading-indicator.component';
import { LoadingIndicatorService } from '../UI/loading-indicator/loading-indicator.service';
import { MenuItemComponent } from '../UI/menu-item/menu-item.component';
import { ModalComponent } from '../UI/modal/modal.component';
import { PopAlertsComponent } from '../UI/pop-alerts/pop-alerts.component';
import { PopAlertsService } from '../UI/pop-alerts/pop-alerts.service';
import { ProgressBarComponent } from '../UI/progress-bar/progress-bar.component';
import { SafePipe } from '../UI/safe-pipe/safe.pipe';
import { SectionTitleComponent } from '../UI/section-title/section-title.component';
import { TextInfoComponent } from '../UI/text-info/text-info.component';
import { TooltipModule } from '../UI/tooltip-module/tooltip.module';
import { TruncatePipe } from '../UI/truncate-pipe/truncate.pipe';
import { UserInfoSelectorComponent } from '../UI/user-info-selector/user-info-selector.component';
import { UserInfoViewComponent } from '../UI/user-info-view/user-info-view.component';
import { UserSelectorComponent } from '../UI/user-selector/user-selector.component';
import { ValidationErrorsComponent } from '../UI/validation-errors/validation-errors.component';
import { LoggedInGuard } from '../user/logged-in.guard';
import { UserService } from '../user/user.service';
import { DocumentService } from './document-service/document.service';
import { HelperService } from './helper-service/helper.service';
import { TranslationLoader } from './translation-loader/translation-loader.class';
import { MomentPipe } from './utils/moment-pipe/moment.pipe';
import { MomentFromNowPipe } from './utils/moment-pipe/momentFromNow.pipe';
import { MomentToNowPipe } from './utils/moment-pipe/momentToNow.pipe';
import { EmptyValidatorDirective } from './validators/empty-validator.directive';
import { ServiceHttpService } from '../data-services/service-http.service';
import { MailTimeEmailComponent } from '../root/components/mail-time-email/mail-time-email.component';
import { QuillModule } from 'ngx-quill';
import { EmailLinkComponent } from '../root/components/email-link/email-link.component';
import { SendEmailModalService } from '../root/components/service/send-email-modal/send-email-modal.service';
import { ActivityViewComponent } from '../root/components/activities/activity-view/activity-view.component';
import { ThesisCumLaudeVoteComponent } from '../external-task/thesis-cum-laude-vote/thesis-cum-laude-vote.component';
import { ValidationButtonsComponent } from '../UI/validation-buttons/validation-buttons.component';
import { ThesisSecretaryEvaluationComponent } from '../external-task/thesis-secretary-evaluation/thesis-secretary-evaluation.component';
import { ProfessorHttpService } from '../data-services/professor-http.service';
import { CotutelleHttpService } from '../data-services/cotutelle-http.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslationLoader(http);
}

@NgModule({
  declarations: [
    FileInputComponent,
    ProgressBarComponent,
    MenuItemComponent,
    ConfirmationDialogComponent,
    PopAlertsComponent,
    SectionTitleComponent,
    TextInfoComponent,
    MomentPipe,
    MomentFromNowPipe,
    MomentToNowPipe,
    FileIconComponent,
    SmoothHeightAnimDirective,
    DatePickerComponent,
    DropFileDirective,
    FilesQueueComponent,
    LoadingIndicatorComponent,
    ModalComponent,
    ValidationErrorsComponent,
    CapitalizeFirstPipe,
    SafePipe,
    InfiniteListComponent,
    EmptyValidatorDirective,
    AlertIconComponent,
    DocumentLinkComponent,
    UserInfoSelectorComponent,
    TruncatePipe,
    AcademicYearPipe,
    EvaluationYearPipe,
    FullnamePipe,
    ThesisPgcInfoComponent,
    ThesisDataViewComponent,
    ThesisCommitteeViewComponent,
    DirectionTeamViewComponent,
    TaskHeaderComponent,
    UserInfoViewComponent,
    PublicFooterComponent,
    ThesisCommitteeAcceptanceComponent,
    ThesisCommitteeAltAcceptanceComponent,
    ThesisExternalAcceptanceComponent,
    ExternalEvaluatorReportComponent,
    ThesisDefenceDateComponent,
    ThesisDefenceInfoComponent,
    UserSelectorComponent,
    DisableButtonDirective,
    MailTimeEmailComponent,
    EmailLinkComponent,
    ActivityViewComponent,
    ThesisCumLaudeVoteComponent,
    ValidationButtonsComponent,
    ThesisSecretaryEvaluationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularDraggableModule,
    NgSelectModule,
    TranslateModule,
    RouterModule,
    TooltipModule.forRoot({
      theme: 'light',
      displayTouchscreen: false,
      showDelay: 500
    }),
    NgToggleModule.forRoot(),
    ScrollingModule,
    QuillModule.forRoot()
  ],
  exports: [
    NgSelectModule,
    AngularDraggableModule,
    CommonModule,
    FormsModule,
    TooltipModule,
    NgToggleModule,
    ScrollingModule,
    QuillModule,
    FileInputComponent,
    ProgressBarComponent,
    MenuItemComponent,
    ConfirmationDialogComponent,
    PopAlertsComponent,
    SectionTitleComponent,
    TextInfoComponent,
    MomentPipe,
    MomentFromNowPipe,
    MomentToNowPipe,
    FileIconComponent,
    SmoothHeightAnimDirective,
    DatePickerComponent,
    DropFileDirective,
    FilesQueueComponent,
    LoadingIndicatorComponent,
    ModalComponent,
    ValidationErrorsComponent,
    SafePipe,
    InfiniteListComponent,
    EmptyValidatorDirective,
    AlertIconComponent,
    DocumentLinkComponent,
    UserInfoSelectorComponent,
    TruncatePipe,
    AcademicYearPipe,
    EvaluationYearPipe,
    FullnamePipe,
    ThesisPgcInfoComponent,
    ThesisDataViewComponent,
    ThesisCommitteeViewComponent,
    DirectionTeamViewComponent,
    TaskHeaderComponent,
    UserInfoViewComponent,
    PublicFooterComponent,
    ThesisCommitteeAcceptanceComponent,
    ThesisCommitteeAltAcceptanceComponent,
    ThesisExternalAcceptanceComponent,
    ExternalEvaluatorReportComponent,
    ThesisDefenceDateComponent,
    ThesisDefenceInfoComponent,
    UserSelectorComponent,
    DisableButtonDirective,
    MailTimeEmailComponent,
    EmailLinkComponent,
    ActivityViewComponent,
    ThesisCumLaudeVoteComponent,
    ValidationButtonsComponent,
    ThesisSecretaryEvaluationComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        UserService,
        DocumentService,
        LoggedInGuard,
        ConfirmationDialogService,
        PopAlertsService,
        DropdownService,
        MomentPipe,
        MomentFromNowPipe,
        MomentToNowPipe,
        LoadingIndicatorService,
        CapitalizeFirstPipe,
        SafePipe,
        AcademicYearPipe,
        EvaluationYearPipe,
        FullnamePipe,
        HelperService,
        ExternalTaskHttpService,
        CanDeactivateGuard,
        AppService,
        ServiceHttpService,
        SendEmailModalService,
        ProfessorHttpService,
        CotutelleHttpService
      ]
    }
  }
}