import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDisability } from '../../../../../../../interfaces/IStudent';
import { UserService } from '../../../../../user/user.service';

@Component({
  selector: 'disability-list',
  templateUrl: './disability-list.component.html',
})
export class DisabilityListComponent implements OnInit {
  @Input() disabilities: IDisability[];

  @Input() editable = false;

  @Output() onRemove = new EventEmitter<string>();
  @Output() onEdit = new EventEmitter<string>();

  constructor(public us: UserService) { }

  ngOnInit() {
  }

}
