import { Component, Input, OnInit } from '@angular/core';

import { IStudent } from '../../../../../../interfaces/IStudent';

@Component({
  selector: 'direction-team-view',
  templateUrl: './direction-team-view.component.html',
})
export class DirectionTeamViewComponent implements OnInit {

  @Input() student: IStudent;

  constructor() { }

  ngOnInit() {
  }

}
