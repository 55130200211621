import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Processes } from '../../../../../../interfaces/ITask';
import { RefreshService } from '../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'end-process-button',
  templateUrl: './end-process-button.component.html',
})
export class EndProcessButtonComponent implements OnInit {

  @Input() processId: string;
  @Input() processType: Processes;

  constructor(public ss: ServiceHttpService,
    public us: UserService,
    private confirmation: ConfirmationDialogService,
    private ts: TranslateService,
    private alerts: PopAlertsService,
    private refresh: RefreshService) { }

  ngOnInit() {
  }

  end() {
    this.confirmation.confirm({
      title: this.ts.instant('end-process'),
      message: this.ts.instant('are-you-sure-to-end-process'),
      btnOkClass: 'danger',
      btnOkText: this.ts.instant('end-process')
    }, () => {
      this.ss.manuallyEndProcess(this.processId, this.processType).subscribe(() => {
        this.alerts.pop({
          styleClass: 'success',
          msg: this.ts.instant('process-ended')
        })
        this.refresh.onStudentChange.next();
      })
    })
  }

}
