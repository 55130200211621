import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ITask } from '../../../../../../../interfaces/ITask';
import { IThesisProcess } from '../../../../../../../interfaces/IThesisProcess';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { ThesisHttpService } from '../../../../../data-services/thesis-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'thesis-direction-team-decision-reject-report',
  templateUrl: './thesis-direction-team-decision-reject-report.component.html',
})
export class ThesisDirectionTeamDecisionRejectReportComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IThesisProcess;

  doContinue = null;

  constructor(
    private thesis: ThesisHttpService,
    private router: Router,
    private refresh: RefreshService
  ) {
    super();
  }

  ngOnInit() {
    this.thesis.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    this.thesis.directionRejectReportDecision(this.task._id, this.doContinue).subscribe((res) => {
      this.sendEnd.next();
      void this.router.navigate(['/app/task-completed']);
      this.refresh.onMessagesChange.next();
      this.refresh.onTasksChange.next();
    })
  }

}
