import { Component, Input, OnInit } from '@angular/core';
import { IProfessorProcess, ProfessorReason } from '../../../../../../../interfaces/IProfessorProcess';
import { ProfessorHttpService } from '../../../../../data-services/professor-http.service';

@Component({
  selector: 'professor-process-data-view',
  templateUrl: './professor-process-data-view.component.html',
})
export class ProfessorProcessDataViewComponent implements OnInit {

  @Input() process: IProfessorProcess;

  ProfessorReason = ProfessorReason;

  ratioInfo: {
    ratio: number,
    studentsCount: number,
    professorsCount: number
  } = { ratio: 0, studentsCount: 0, professorsCount: 0 };

  constructor(private pfs: ProfessorHttpService) { }

  ngOnInit() {
    this.pfs.professorsStudentsRatio(this.process.programId).subscribe((res) => {
      this.ratioInfo = res;
    });
  }

}
