import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ActivityType, IActivity } from '../../../interfaces/IActivity';
import { IDocument } from '../../../interfaces/IDocument';

@Injectable()
export class ActivityHttpService {

  constructor(private http: HttpClient) { }

  fetchMyActivities(params: { programId: string, searchText?: string, page?: number, size?: number }): Observable<IActivity[]> {
    return this.http.get<IActivity[]>(`/api/me/${params.programId}/activities`, { params });
  }

  addActivity(programId, activity: IActivity): Observable<IActivity> {
    return this.http.post<IActivity>(`/api/me/${programId}/activities`, { activity });
  }

  saveActivity(programId, activityId: string, params: { activityType: ActivityType, title: string, description: string, startDate: Date, endDate: Date }) {
    return this.http.put<IActivity>(`/api/me/${programId}/activities/${activityId}`, params);
  }

  removeActivity(programId, activityId: string): Observable<IActivity> {
    return this.http.delete<IActivity>(`/api/me/${programId}/activities/${activityId}`);
  }

  restoreActivity(programId, activityId: string): Observable<IActivity> {
    return this.http.put<IActivity>(`/api/me/${programId}/activities/${activityId}/restore`, {});
  }

  modifyActivity(programId, activityId: string): Observable<IActivity> {
    return this.http.put<IActivity>(`/api/me/${programId}/activities/${activityId}/modify`, {});
  }

  addDocuments(programId, activityId: string, documents: { documentRef: string, fileName: string }[]): Observable<any> {
    return this.http.post<any>(`/api/me/${programId}/activities/${activityId}/documents`, { documents });
  }

  removeDocument(programId, activityId: string, documentId: string): Observable<any> {
    return this.http.delete<any>(`/api/me/${programId}/activities/${activityId}/documents/${documentId}`);
  }

  newVersion(programId, activityId: string, documentId: string, versionId: string): Observable<IDocument> {
    return this.http.post<IDocument>(`/api/me/${programId}/activities/${activityId}/documents/${documentId}/version/${versionId}`, {});
  }

}