<modal [(open)]="open"
  (openChange)="openChange.emit($event)"
  [title]="'PERM_LEAVE' | translate">
  <form #f="ngForm"
    id="permanentLeaveForm"
    (ngSubmit)="save()">
    <div class="form-group">
      <label>{{ 'date' | translate }}</label>
      <date-picker [(ngModel)]="start"
        name="start"
        #startInput="ngModel"
        [isInvalid]="f.submitted && startInput.invalid"
        [required]="true"></date-picker>
      <validation-errors [control]="startInput"
        [show]="f.submitted"></validation-errors>
    </div>
    <div class="form-group">
      <label>{{ 'justification' | translate }}</label>
      <files-queue [(ngModel)]="justification"
        name="justification"
        #justificationInput="ngModel"
        [required]="true"></files-queue>
      <validation-errors [control]="justificationInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group form-check">
      <input class="form-check-input"
        type="checkbox"
        id="sendMail"
        name="sendMail"
        [(ngModel)]="sendMail">
      <label class="form-check-label"
        for="sendMail">{{ 'send-mail-checkbox' | translate }}</label>
    </div>

    <div class="form-group"
      *ngIf="sendMail">
      <label>{{ 'mail-text' | translate }}</label>
      <quill-editor id="mailText"
        #mailTextInput="ngModel"
        name="mailText"
        [(ngModel)]="mailText"
        [placeholder]="'mail-text-placeholder' | translate"
        required
        [modules]="modules"></quill-editor>
      <validation-errors [control]="mailTextInput"
        [show]="f.submitted"></validation-errors>
    </div>


  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="close()">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="permanentLeaveForm">{{ 'save' | translate }}</button>
  </div>

</modal>