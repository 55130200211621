<div class="row"
  *ngIf="student && !loading">

  <div class="col-12"
    *ngIf="!process && !student.thesis && us.isStudent()">
    <div class="w-100 text-right mt-2">
      <button class="btn btn-outline-primary btn-block"
        [routerLink]="['request']"
        [disableButton]="!requestEnabled?.enabled">
        {{ 'request-thesis' | translate }}
      </button>

      <request-disabled-reasons [requestEnabled]="requestEnabled"></request-disabled-reasons>
    </div>
  </div>

  <div class="col-12"
    *ngIf="!process && !student.thesis && !us.isStudent()">
    <div class="w-100 mt-2">
      <div class="alert alert-info d-flex justify-content-start align-items-top">
        <div class="mr-3">
          <i class="far fa-info-circle fa-2x"></i>
        </div>
        <div>
          <h5 class="mb-3">
            {{ 'thesis-deadline' | translate }}:
            {{ student.deadline | moment:'DD / MM / YYYY'}}
          </h5>
          {{ 'acceptance-for-processing-not-requested-yet' | translate }}
        </div>

      </div>
    </div>
  </div>

  <div class="col-12"
    *ngIf="process">
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">
          {{ 'ongoing-process' | translate }}
          <span *ngIf="!process.active"
            class="badge badge-danger ml-1">
            {{ 'process-inactive' | translate}}
          </span>
        </h5>
        <div>
          <tasks-table [tasks]="process.tasks"
            [process]="process"
            (onExternalLink)="task = $event; generated = false; open = true;"
            (onChangeExternalEvaluator)="changeExternalEvaluator($event)"></tasks-table>

          <div class="text-right m-2"
            *ngIf="us.isService() && process.state === ThesisPState.PendingServiceRegisterDefence && process.data && process.data.thesisCommittee">

            <button class="btn btn-outline-primary"
              type="button"
              *ngIf="process.cumlaudeVotesSent !== true"
              (click)="doOpenAskForCumLaudeVote()">
              Solicitar voto Cum Laude
            </button>

            <button class="btn btn-outline-warning ml-2"
              type="button"
              *ngIf="process.cumlaudeVotesSent === true && pendingCumLaudeVotes"
              (click)="stopCumLaudeVotes()">
              Detener votaciones Cum Laude
            </button>

          </div>
          <end-process-button [processId]="process._id"
            [processType]="Processes.thesis"></end-process-button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 text-right mb-3"
    *ngIf="thesis && us.isService() && sh.editMode">

    <button class="btn btn-sm btn-outline-primary mr-1"
      (click)="generateProposal()">
      <i class="far fa-file-word mr-1"></i>
      {{ 'thesis-committee-proposal' | translate }}
    </button>

    <button class="btn btn-sm btn-outline-primary mr-1"
      (click)="generateDesignation()">
      <i class="far fa-file-word mr-1"></i>
      {{ 'thesis-committee-designation' | translate }}
    </button>

    <button class="btn btn-sm btn-outline-primary mr-1"
      (click)="generateDefenceRecord()">
      <i class="far fa-file-word mr-1"></i>
      {{ 'thesis-defence-record' | translate }}
    </button>

    <button class="btn btn-sm btn-outline-primary"
      (click)="generateContentRelation()">
      <i class="far fa-file-word mr-1"></i>
      {{ 'thesis-content-relation' | translate }}
    </button>
  </div>

  <div class="col-12"
    *ngIf="thesis">

    <div class="card mb-3"
      *ngIf="thesis.thesisFinal || thesis.thesisFinalEvaluation">
      <div class="card-body">
        <h5 class="card-title">
          {{ 'thesis-defence' | translate }}
          <button class="btn btn-sm ml-2 btn-outline-dark"
            type="button"
            *ngIf="sh.editMode && us.isService()"
            (click)="thesisDefenceEditModal.doOpen()">
            <i class="far fa-edit mr-1"></i>
            {{ 'edit' | translate }}
          </button>
        </h5>

        <div class="small text-muted mb-1"
          *ngIf="thesis.thesisFinalEvaluation">
          {{ 'thesis-final-evaluation' | translate }}
        </div>
        <div class="alert mb-4"
          [class.alert-danger]="thesis.thesisFinalEvaluation === ThesisFinalEvaluation.fail"
          [class.alert-success]="thesis.thesisFinalEvaluation !== ThesisFinalEvaluation.fail"
          *ngIf="thesis.thesisFinalEvaluation">
          <h4 class="mb-0">
            {{ thesis.thesisFinalEvaluation | translate }}
            <span class="badge badge-dark"
              *ngIf="thesis.cumlaudeMention">
              {{ 'cum-laude-mention' | translate }}
            </span>
            <span class="badge badge-dark ml-2"
              *ngIf="thesis.internationalMentionFinal">
              {{ 'international-mention' | translate }}
            </span>
            <span class="badge badge-dark ml-2"
              *ngIf="thesis.industrialMentionFinal">
              {{ 'industrial-mention' | translate }}
            </span>
          </h4>
        </div>

        <div class="mb-4"
          *ngIf="thesis.cumlaudeVotesSent === true && us.isService()">
          <div class="small text-muted mb-1">
            Votos para la Mención Cum Laude
          </div>
          <ol>
            <li *ngFor="let vote of thesis.cumlaudeVotes">
              <strong *ngIf="vote === true">
                {{ 'yes' | translate }}
              </strong>
              <strong *ngIf="vote === false">
                {{ 'no' | translate }}
              </strong>
            </li>
          </ol>
        </div>
        <div class="mb-4"
          *ngIf="thesis.thesisDefenceRecord && us.isService()">
          <div class="small text-muted mb-1">
            {{ 'thesis-defence-record' | translate }}
          </div>
          <div>
            <document-link [document]="thesis.thesisDefenceRecord"></document-link>
          </div>
        </div>
        <div class="mb-4"
          *ngIf="thesis.secretaryEvaluation && us.isService()">
          <div class="small text-muted mb-1">
            {{ 'thesis-secretary-evaluation' | translate }}
          </div>
          <div>
            {{ thesis.secretaryEvaluation | translate }}
          </div>
        </div>
        <div class="mb-4"
          *ngIf="defenceLanguage && us.isService()">
          <div class="small text-muted mb-1">
            {{ 'defence-language' | translate }}
          </div>
          <div>
            {{ defenceLanguage.name }}
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.pgcDefenceApprobalDate">
          <div class="small text-muted mb-1">
            {{ 'pgc-approbal-date' | translate }}
          </div>
          <div>
            {{ thesis.pgcDefenceApprobalDate | moment:'DD/MM/YYYY' }}
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.defenceDate">
          <div class="small text-muted mb-1">
            {{ 'defence-date' | translate }}
          </div>
          <div>
            {{ thesis.defenceDate | moment:'DD/MM/YYYY HH:mm' }}
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.defencePlace">
          <div class="small text-muted mb-1">
            {{ 'defence-place' | translate }}
          </div>
          <div>
            {{ thesis.defencePlace }}
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.finalThesisCommittee && thesis.finalThesisCommittee.president">
          <div class="small text-muted mb-1">
            {{ 'thesiscommitteePresident' | translate }}
          </div>
          <div>
            {{ thesis.finalThesisCommittee.president | fullname }}
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.finalThesisCommittee && thesis.finalThesisCommittee.secretary">
          <div class="small text-muted mb-1">
            {{ 'thesiscommitteeSecretary' | translate }}
          </div>
          <div>
            {{ thesis.finalThesisCommittee.secretary | fullname }}
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.finalThesisCommittee && thesis.finalThesisCommittee.member">
          <div class="small text-muted mb-1">
            {{ 'thesiscommitteeMember' | translate }}
          </div>
          <div>
            {{ thesis.finalThesisCommittee.member | fullname }}
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.thesisFinal">
          <div class="small text-muted mb-1">
            {{ 'thesis-final-doc' | translate }}
          </div>
          <div>
            <document-link [document]="thesis.thesisFinal"></document-link>
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.changesReport">
          <div class="small text-muted mb-1">
            {{ 'changes-report' | translate }}
          </div>
          <div>
            <document-link [document]="thesis.changesReport"></document-link>
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.videoConference">
          <div class="small text-muted mb-1">
            {{ 'video-conference-defence' | translate }}
          </div>
          <div>
            <document-link [document]="doc"
              styleClass="mb-2"
              *ngFor="let doc of thesis.videoConferenceDocs"></document-link>
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.hybridDefence">
          <div class="small text-muted mb-1">
            Defensa híbrida (presencial/videoconferencia)
          </div>
          <div>
            <document-link [document]="doc"
              styleClass="mb-2"
              *ngFor="let doc of thesis.hybridDefenceDocs"></document-link>
          </div>
        </div>

        <div class="mb-4"
          *ngIf="thesis.specialAdward">
          <div class="small text-muted mb-1">
            {{ 'special-adward' | translate }}
          </div>
          <div>
            <document-link [document]="doc"
              styleClass="mb-2"
              *ngFor="let doc of thesis.specialAdwardDocs"></document-link>
          </div>
        </div>

        <ng-container *ngIf="!thesis.thesisDoc && thesis.cotutelle">
          <div class="mb-4">
            <div class="small text-muted mb-1">
              {{ 'thesis-cotutelle' | translate }}
            </div>
            <div>
              {{ (thesis.cotutelle ? 'Yes' : 'No') | translate }}
            </div>
          </div>

          <div *ngIf="thesis.cotutelle"
            class="row mb-4">
            <div class="col-md-6">
              <div class="mb-4">
                <div class="small text-muted mb-1">
                  {{ 'cotutelle-university' | translate }}
                </div>
                <div class="mb-1">
                  <span *ngIf="thesis.cotutelleUniversity">
                    {{ thesis.cotutelleUniversity }}
                  </span>
                </div>
              </div>
              <div class="mb-4"
                *ngIf="thesis.cotutelleStayEvidence && thesis.cotutelleStayEvidence.length > 0">
                <div class="small text-muted mb-1">
                  {{ 'cotutelle-stay-evidence' | translate }}
                </div>
                <div *ngFor="let doc of thesis.cotutelleStayEvidence"
                  class="mb-1">
                  <document-link [document]="doc"></document-link>
                </div>
              </div>
              <div class="mb-4"
                *ngIf="thesis.cotutelleStayEvidenceOut && thesis.cotutelleStayEvidenceOut.length > 0">
                <div class="small text-muted mb-1">
                  {{ 'cotutelle-stay-evidence-out' | translate }}
                </div>
                <div *ngFor="let doc of thesis.cotutelleStayEvidenceOut"
                  class="mb-1">
                  <document-link [document]="doc"></document-link>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-4">
                <div class="small text-muted mb-1">
                  {{ 'cotutelle-defence-place' | translate }}
                </div>
                <div class="mb-1">
                  <span *ngIf="thesis.cotutelleDefenceOut">
                    {{ 'foreign-university' | translate }}
                  </span>
                  <span *ngIf="!thesis.cotutelleDefenceOut">
                    {{ 'university-of-malaga' | translate }}
                  </span>
                </div>
              </div>
              <div class="mb-4"
                *ngIf="thesis.cotutelleDefenceOut && thesis.cotutelleComposition">
                <div class="small text-muted mb-1">
                  {{ 'cotutelle-thesis-committe-composition' | translate }}
                </div>
                <div class="mb-1">
                  <document-link [document]="thesis.cotutelleComposition"></document-link>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="us.isService()">

          <div class="mb-4">
            <div class="small text-muted mb-1">
              Informe RIUMA
            </div>
            <div>
              <document-link [document]="thesis.riuma"></document-link>
            </div>
          </div>

          <div class="mb-4">
            <div class="small text-muted mb-1">
              Ficha TESEO
            </div>
            <div>
              <document-link [document]="thesis.teseo"></document-link>
            </div>
          </div>

          <div class="mb-4">
            <div class="small text-muted mb-1">
              Nombramiento
            </div>
            <div>
              <document-link [document]="thesis.designation"></document-link>
            </div>
          </div>

          <div class="mb-4">
            <div class="small text-muted mb-1">
              Solicitud de embargo
            </div>
            <div>
              <document-link [document]="thesis.embargo"></document-link>
            </div>
          </div>

          <div class="mb-4">
            <div class="small text-muted mb-1">
              Informes miembros del tribunal
            </div>
            <div *ngFor="let doc of thesis.additionalReports"
              class="mb-1">
              <document-link [document]="doc"></document-link>
            </div>
          </div>

        </ng-container>

      </div>
    </div>

    <div class="card"
      *ngIf="thesis.thesisDoc">
      <div class="card-body">
        <h5 class="card-title">
          {{ 'acceptance-for-processing' | translate }}
          <button class="btn btn-sm ml-2 btn-outline-dark"
            type="button"
            *ngIf="sh.editMode && us.isService()"
            (click)="thesisEditModal.doOpen()">
            <i class="far fa-edit mr-1"></i>
            {{ 'edit' | translate }}
          </button>
        </h5>
        <div class="mb-2"
          *ngIf="acceptedForProcessing">
          <span class="badge badge-info">
            {{ 'thesis-accepted-for-processing' | translate }}
          </span>
          <div class="mb-4"
            *ngIf="thesis.acceptedForProcessingDate">
            <div class="small text-muted mb-1">
              {{ 'accepted-for-processing-date' | translate }}
            </div>
            <div>
              {{ thesis.acceptedForProcessingDate | moment:'DD/MM/YYYY' }}
            </div>
          </div>
        </div>
        <thesis-data-view [data]="thesis"
          [reports]="thesis.reports"
          [selectedEvaluators]="thesis.selectedEvaluators"
          [student]="student"
          (onSaveExpenseDocDate)="saveExpenseDocDate($event)"
          (onEditReports)="openThesisReportsModal();"></thesis-data-view>

      </div>
    </div>
  </div>

  <div class="col-12 mt-4"
    *ngIf="requestDoc || olderRequestDocs.length > 0">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">
          {{ 'request-documents' | translate }}
        </h5>

        <document-link [document]="requestDoc"
          styleClass="mb-2"></document-link>
        <document-link [document]="doc"
          styleClass="mb-2"
          *ngFor="let doc of olderRequestDocs"></document-link>
      </div>
    </div>
  </div>

</div>

<modal [(open)]="open"
  [title]="'check-external-link' | translate">
  <ng-container *ngIf="task">
    <h5>
      {{ hs.name(task) | translate }}
    </h5>

    <div *ngIf="!task.assigneeName">
      <ul *ngIf="task.roles"
        class="list-unstyled m-0">
        <li *ngFor="let role of task.roles">
          {{ hs.roleName(role) | translate }}
        </li>
      </ul>
      <span *ngIf="task.assigneeId && task.assignee">
        {{ task.assignee | fullname }}
      </span>
    </div>
    <div *ngIf="task.assigneeName">
      <span *ngFor="let role of task.roles">
        {{ hs.roleName(role) | translate }} -
      </span>
      {{ task.assigneeName }}
    </div>

    <ng-container *ngIf="!generated">
      <div class="alert alert-warning mt-2">
        <i class="fa fa-exclamation-triangle mr-1"></i>
        {{ 'generate-new-link-warning' | translate }}
      </div>

      <button class="btn btn-warning btn-block"
        type="button"
        (click)="generate()">
        {{ 'get-new-link' | translate }}
      </button>
    </ng-container>
    <ng-container *ngIf="generated">
      <div class="alert alert-success mt-2">
        <h5 class="text-center mb-0">
          <a [href]="url"
            target="_blank">
            {{ 'link' | translate | uppercase }}
          </a>
        </h5>
      </div>
    </ng-container>

  </ng-container>
  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'close' | translate }}</button>
  </div>

</modal>

<modal [(open)]="openAskForCumLaudeVote"
  [title]="'Solicitar voto Cum Laude'">

  <form id="askForCumLaudeVoteForm"
    #f="ngForm"
    (ngSubmit)="askForCumLaudeVote()"
    *ngIf="openAskForCumLaudeVote">
    <div class="form-group">
      <label for="president">
        {{ 'president' | translate }}
      </label>
      <ng-select [items]="members"
        required="true"
        bindValue="_id"
        bindLabel="firstName"
        [multiple]="false"
        [searchable]="false"
        id="president"
        name="president"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-thesis-committee-president' | translate"
        #presidentSelect="ngModel"
        [class.is-invalid]="presidentSelect.invalid && f.submitted"
        [ngModel]="presidentId"
        (ngModelChange)="assignPresident($event)">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item | fullname }}
          <span class="small text-muted"
            *ngIf="presidentId === item._id">
            {{ 'president' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="secretaryId === item._id">
            {{ 'secretary' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="memberId === item._id">
            {{ 'member' | translate }}
          </span>
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
      </ng-select>
      <validation-errors [control]="presidentSelect"
        [show]="f.submitted"></validation-errors>

      <ng-select id="presidentModalitySelect"
        name="presidentModalitySelect"
        #presidentModalitySelect="ngModel"
        [(ngModel)]="presidentModality"
        [required]="true"
        [class.is-invalid]="f.submitted && presidentModalitySelect.invalid"
        [placeholder]="'Modalidad'"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="ThesisDefenceModality.inPerson">PRESENCIAL</ng-option>
        <ng-option [value]="ThesisDefenceModality.online">ONLINE</ng-option>
      </ng-select>
      <validation-errors [control]="presidentModalitySelect"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="secretary">
        {{ 'secretary' | translate }}
      </label>
      <ng-select [items]="members"
        required="true"
        bindValue="_id"
        bindLabel="firstName"
        [multiple]="false"
        [searchable]="false"
        id="secretary"
        name="secretary"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-thesis-committee-secretary' | translate"
        #secretarySelect="ngModel"
        [class.is-invalid]="secretarySelect.invalid && f.submitted"
        [(ngModel)]="secretaryId"
        (ngModelChange)="assignSecretary($event)">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item | fullname }}
          <span class="small text-muted"
            *ngIf="presidentId === item._id">
            {{ 'president' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="secretaryId === item._id">
            {{ 'secretary' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="memberId === item._id">
            {{ 'member' | translate }}
          </span>
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
      </ng-select>
      <validation-errors [control]="secretarySelect"
        [show]="f.submitted"></validation-errors>

      <ng-select id="secretaryModalitySelect"
        name="secretaryModalitySelect"
        #secretaryModalitySelect="ngModel"
        [(ngModel)]="secretaryModality"
        [required]="true"
        [class.is-invalid]="f.submitted && secretaryModalitySelect.invalid"
        [placeholder]="'Modalidad'"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="ThesisDefenceModality.inPerson">PRESENCIAL</ng-option>
        <ng-option [value]="ThesisDefenceModality.online">ONLINE</ng-option>
      </ng-select>
      <validation-errors [control]="secretaryModalitySelect"
        [show]="f.submitted"></validation-errors>

    </div>

    <div class="form-group">
      <label for="member">
        {{ 'member' | translate }}
      </label>
      <ng-select [items]="members"
        required="true"
        bindValue="_id"
        bindLabel="firstName"
        [multiple]="false"
        [searchable]="false"
        id="member"
        name="member"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-thesis-committee-member' | translate"
        #memberSelect="ngModel"
        [class.is-invalid]="memberSelect.invalid && f.submitted"
        [(ngModel)]="memberId"
        (ngModelChange)="assignMember($event)">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item | fullname }}
          <span class="small text-muted"
            *ngIf="presidentId === item._id">
            {{ 'president' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="secretaryId === item._id">
            {{ 'secretary' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="memberId === item._id">
            {{ 'member' | translate }}
          </span>
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
      </ng-select>
      <validation-errors [control]="memberSelect"
        [show]="f.submitted"></validation-errors>

      <ng-select id="memberModalitySelect"
        name="memberModalitySelect"
        #memberModalitySelect="ngModel"
        [(ngModel)]="memberModality"
        [required]="true"
        [class.is-invalid]="f.submitted && memberModalitySelect.invalid"
        [placeholder]="'Modalidad'"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="ThesisDefenceModality.inPerson">PRESENCIAL</ng-option>
        <ng-option [value]="ThesisDefenceModality.online">ONLINE</ng-option>
      </ng-select>
      <validation-errors [control]="memberModalitySelect"
        [show]="f.submitted"></validation-errors>
    </div>
  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="openAskForCumLaudeVote = false">{{ 'close' | translate }}</button>

    <button class="btn btn-primary"
      form="askForCumLaudeVoteForm"
      type="submit">
      {{ 'send' | translate }}
    </button>

  </div>

</modal>


<video-conference-thesis-modal #videoConferenceModal
  [studentId]="student._id"
  [videoConference]="thesis?.videoConference"></video-conference-thesis-modal>

<thesis-edit-modal #thesisEditModal
  [student]="student"
  [data]="process ? process.data : thesis"></thesis-edit-modal>

<thesis-defence-edit-modal #thesisDefenceEditModal
  [student]="student"
  [data]="process ? process : thesis"
  [proccesingData]="process ? process.data : thesis"></thesis-defence-edit-modal>

<thesis-reports-modal #thesisReportsModal
  [studentId]="student._id"
  [reports]="thesis?.reports"></thesis-reports-modal>