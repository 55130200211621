<ng-container *ngIf="process">

  <task-header [task]="task"></task-header>

  <br>

  <form #f="ngForm"
    (submit)="send()">

    <label>{{ 'tutor' | translate }}</label>
    <assing-user-id [userInfo]="data.tutor"
      [(ngModel)]="directionIds.tutor"
      name="tutor"></assing-user-id>

    <label>{{ 'supervisor' | translate }}</label>
    <assing-user-id [userInfo]="data.supervisor1"
      [(ngModel)]="directionIds.supervisor1"
      [(isNew)]="isNew.supervisor1"
      [favEmail]="data.supervisor1.email"
      name="supervisor1"></assing-user-id>

    <ng-container *ngIf="data.supervisor2">
      <label>{{ 'second-supervisor' | translate }}</label>
      <assing-user-id [userInfo]="data.supervisor2"
        [(ngModel)]="directionIds.supervisor2"
        [(isNew)]="isNew.supervisor2"
        [favEmail]="data.supervisor2.email"
        name="supervisor2"></assing-user-id>
    </ng-container>

    <ng-container *ngIf="data.supervisor3">
      <label>{{ 'third-supervisor' | translate }}</label>
      <assing-user-id [userInfo]="data.supervisor3"
        [(ngModel)]="directionIds.supervisor3"
        [(isNew)]="isNew.supervisor3"
        [favEmail]="data.supervisor3.email"
        name="supervisor3"></assing-user-id>
    </ng-container>

    <div class="form-group text-right">
      <div class="d-block invalid-feedback mb-2"
        *ngIf="f.invalid && f.submitted">
        {{ 'all-members-must-have-duma-user' | translate }}
      </div>

      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate}}
      </button>
    </div>

  </form>

</ng-container>