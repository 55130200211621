<ng-container *ngIf="validation">
  <div class="form-group text-center">
    <div class="btn-group btn-block">
      <button type="button"
        class="btn btn-sm"
        [class.btn-outline-danger]="validation.validate === null || validation.validate === true"
        [class.btn-danger]="validation.validate === false"
        (click)="validation.validate = false; propagateChange(); scrollToBottom()">
        {{ denyButtonText | translate }}
      </button>
      <button type="button"
        class="btn btn-sm"
        [class.btn-outline-success]="validation.validate === null || validation.validate === false"
        [class.btn-success]="validation.validate === true"
        (click)="validation.validate = true; validation.denialMsg = null; propagateChange();  scrollToBottom()">
        {{ validateButtonText | translate }}
      </button>
    </div>
  </div>

  <div class="form-group"
    *ngIf="showDenyText && validation.validate === false">
    <label for="denialMsg">
      {{ 'denial-reason' | translate }}
    </label>
    <textarea id="denialMsg"
      name="denialMsg"
      #denialMsgInput="ngModel"
      [(ngModel)]="validation.denialMsg"
      (ngModelChange)="propagateChange()"
      [required]="true"
      [placeholder]="'denial-reason-placeholder' | translate"
      [class.is-invalid]="showErrors && denialMsgInput.invalid"
      class="form-control"></textarea>
    <div class="text-right small text-muted">
      {{ validation.denialMsg ? validation.denialMsg.length : 0}}
    </div>
    <validation-errors [show]="showErrors"
      [control]="denialMsgInput"></validation-errors>
  </div>

  <div class="form-group"
    *ngIf="showValidationText && validation.validate === true">
    <label for="valiationMsg">
      {{ 'info-note' | translate }}
      <small class="text-muted">
        {{ 'optional' | translate }}
      </small>
    </label>
    <textarea id="valiationMsg"
      name="valiationMsg"
      #valiationMsgInput="ngModel"
      [(ngModel)]="validation.validationMsg"
      (ngModelChange)="propagateChange()"
      [required]="false"
      [placeholder]="'info-note-placeholder' | translate"
      [class.is-invalid]="showErrors && valiationMsgInput.invalid"
      class="form-control"></textarea>
    <div class="text-right small text-muted">
      {{ validation.validationMsg ? validation.validationMsg.length : 0}}
    </div>
    <validation-errors [show]="showErrors"
      [control]="valiationMsgInput"></validation-errors>
  </div>
</ng-container>