<div class="card"
  *ngIf="student.scholarships && student.scholarships.length > 0 || (us.isService() && sh.editMode)">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <span>
        {{ 'scholarships' | translate }}
      </span>
      <button type="button"
        class="btn btn-link"
        *ngIf="us.isService() && sh.editMode"
        (click)="doOpen()">
        {{ 'add' | translate }}
      </button>
    </legend>
  </div>

  <div class="list-group"
    *ngIf="student.scholarships && student.scholarships.length > 0">
    <div class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
      *ngFor="let scholarship of student.scholarships">

      <div *ngIf="scholarship.documents"
        style="max-width: 250px;">
        <div class="mb-2">
          <strong *ngIf="scholarship.type !== 'OTHER' && scholarship.type !== 'STAY'">
            {{ scholarship.type }}
          </strong>
          <strong *ngIf="scholarship.type === 'OTHER'">
            {{ scholarship.otherType }}
          </strong>
          <strong *ngIf="scholarship.type === 'STAY'">
            {{ 'stay-scholarship' | translate }}
          </strong>
        </div>
        <div *ngFor="let doc of scholarship.documents">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
      <div class="nowrap mt-1">
        {{ scholarship.startDate | moment:'D/M/YY' }}
        <span *ngIf="scholarship.endDate">
          <i class="far fa-angle-double-right mx-2"></i>
          {{ scholarship.endDate | moment:'D/M/YY' }}
        </span>

        <a role="button"
          *ngIf="us.isService() && sh.editMode"
          [routerLink]="null"
          class="text-muted ml-2"
          (click)="doOpen(scholarship._id)">
          <i class="far fa-edit"></i>
        </a>

        <a role="button"
          [routerLink]="null"
          *ngIf="us.isService() && sh.editMode"
          class="text-muted ml-2"
          (click)="remove(scholarship._id)">
          <i class="far fa-trash"></i>
        </a>
      </div>
    </div>
  </div>
</div>

<modal [(open)]="open"
  [title]="'scholarship' | translate">
  <form #f="ngForm"
    id="scholarshipForm"
    (ngSubmit)="save()"
    *ngIf="open">

    <div class="form-group">
      <label for="scholarshipType">
        {{ 'scholarship-type' | translate }}
      </label>

      <ng-select id="scholarshipType"
        name="scholarshipType"
        style="width: 100%"
        [required]="true"
        [searchable]="false"
        [clearable]="false"
        [(ngModel)]="scholarship.type">
        <ng-option [value]="'FPU'">
          {{ 'FPU' }}
        </ng-option>
        <ng-option [value]="'FPI'">
          {{ 'FPI' }}
        </ng-option>
        <ng-option [value]="'STAY'">
          {{ 'stay-scholarship' | translate }}
        </ng-option>
        <ng-option [value]="'OTHER'">
          {{ 'Others' | translate }}
        </ng-option>
      </ng-select>

      <ng-container *ngIf="scholarship.type === 'OTHER'">
        <input id="scholarshipOtherType"
          name="scholarshipOtherType"
          type="text"
          class="form-control"
          [placeholder]="'scholarship-other-type-placeholder' | translate"
          #scholarshipOtherTypeInput="ngModel"
          required
          [(ngModel)]="scholarship.otherType">
        <validation-errors [control]="scholarshipOtherTypeInput"
          [show]="f.submitted"></validation-errors>
      </ng-container>
    </div>

    <div class="form-group">
      <label for="scholarshipStart">
        {{ 'start-date' | translate }}
      </label>
      <date-picker id="scholarshipStart"
        name="scholarshipStart"
        [placeholder]="'scholarship-start' | translate"
        [required]="false"
        [isInvalid]="scholarshipStartInput.invalid && f.submitted"
        #scholarshipStartInput="ngModel"
        [time]="false"
        [(ngModel)]="scholarship.startDate"> </date-picker>
      <validation-errors [control]="scholarshipStartInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="scholarshipEnd">
        {{ 'end-date' | translate }}
      </label>
      <date-picker id="scholarshipEnd"
        name="scholarshipEnd"
        [placeholder]="'scholarship-end' | translate"
        [required]="false"
        [isInvalid]="scholarshipEndInput.invalid && f.submitted"
        #scholarshipEndInput="ngModel"
        [time]="false"
        [(ngModel)]="scholarship.endDate"> </date-picker>
      <validation-errors [control]="scholarshipEndInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="scholarshipDocuments">
        {{ 'documents' | translate }}
      </label>
      <validation-errors [show]="f.submitted"
        [control]="documentsInput"></validation-errors>
      <files-queue name="documents"
        [required]="false"
        #documentsInput="ngModel"
        [(ngModel)]="scholarship.documents"></files-queue>
    </div>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="scholarshipForm">{{ 'save' | translate }}</button>
  </div>

</modal>