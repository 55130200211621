import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IProfessorProcessData, ProfessorReason } from '../../../../../../../interfaces/IProfessorProcess';
import { NgForm } from '@angular/forms';
import { HelperService } from '../../../../../shared/helper-service/helper.service';
import { ILineOfResearch } from '../../../../../../../interfaces/ILineOfResearch';
import { ProfessorHttpService } from '../../../../../data-services/professor-http.service';
import { IProfessor } from '../../../../../../../interfaces/IProfessor';
import { UserService } from '../../../../../user/user.service';

@Component({
  selector: 'professor-request-form',
  templateUrl: './professor-request-form.component.html',
})
export class ProfessorRequestFormComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() linesOfResearch: ILineOfResearch[] = [];
  @Input() sending;
  @Output() onSend = new EventEmitter<IProfessorProcessData>();

  @Input() data: IProfessorProcessData;

  @Input() programId: string;

  ProfessorReason = ProfessorReason;

  idNumExists = false;
  idNumExistsInAnotherProgram = false;
  idNumOtherProgramName = '';
  idNumEndYear = '';
  emailExists = false;
  emailExistsInAnotherProgram = false;
  emailOtherProgramName = '';
  emailEndYear = '';


  constructor(public hs: HelperService,
    private ps: ProfessorHttpService,
    public us: UserService) { }

  ngOnInit() {
    if (!this.data) {
      this.data = {
        external: false,
        idNum: '',
        firstName: '',
        lastName: '',
        email: '',
        category: '',
        lineOfResearchId: '',
        curriculumLink: '',
        reason: null,
        sixyearDoc: undefined,
        thesisCount: null,
        orcid: '',
        otherProfile: '',
        university: '',
        otherProgram: false,
        otherProgramName: '',
        otherProgramUniversity: '',
      }
    } else {
      this.checkEmail();
      this.checkIdNum();
    }
  }

  send() {
    if (this.f.invalid || this.emailExists || this.idNumExists) {
      return;
    }
    this.onSend.emit(this.data);
  }

  checkIdNum() {
    if (!this.data.idNum || this.data.idNum.length < 0) {
      return;
    }
    this.idNumExists = false;
    this.idNumExistsInAnotherProgram = false;
    this.ps.professorCheckIdNum(this.data.idNum, this.programId).subscribe((res: IProfessor[]) => {
      if (res && res.length > 0) {
        // check if the professor is already in the program
        for (const p of res) {
          if (p.programId === this.programId) {
            this.idNumExists = true
          } else {
            this.idNumExistsInAnotherProgram = true;
            this.idNumOtherProgramName = p.program.name;
          }
          this.idNumEndYear = p.endYear;
        }
      }
    });
  }

  checkEmail() {
    if (!this.data.email || this.data.email.length < 0) {
      return;
    }
    this.emailExists = false;
    this.emailExistsInAnotherProgram = false;
    this.ps.professorCheckEmail(this.data.email, this.programId).subscribe((res: IProfessor[]) => {
      if (res && res.length > 0) {
        // check if the professor is already in the program
        for (const p of res) {
          if (p.programId === this.programId) {
            this.emailExists = true
          } else {
            this.emailExistsInAnotherProgram = true;
            this.emailOtherProgramName = p.program.name;
          }
          this.emailEndYear = p.endYear;
        }
      }
    });
  }

}
