<ng-container *ngIf="activity">
  <task-header [task]="task"
    [shorDesc]="false"></task-header>

  <div class="border border-dark rounded mt-2">
    <div class="p-3">
      <activity-view [activity]="activity"
        [showEdit]="true"></activity-view>
    </div>
  </div>

  <div *ngIf="task.validationProcess.validationMsg"
    class="mt-3">
    <div>{{ 'tutor-commentary' | translate }}:</div>

    <div class="pre alert alert-light"
      [innerHTML]="task.validationProcess.validationMsg"></div>
  </div>

  <form (ngSubmit)="send()">

    <div class="form-group text-right">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'resend' | translate }}
      </button>
    </div>

  </form>

</ng-container>