import { PlatformLocation } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { ImpersonatingService } from '../data-services/impersonating.service';
import { dropdownAnimation } from '../UI/animations/animations';
import { UserService } from '../user/user.service';
import { MenuService } from './menu/menu.service';
import { Observable, Subscription } from 'rxjs';
import { AcceptGoodPracticeCodeModalComponent } from './components/accept-good-practice-code-modal/accept-good-practice-code-modal.component';
import { filter } from 'rxjs/operators';
import { Role } from '../../../interfaces/IUser';

@Component({
  selector: 'root',
  templateUrl: 'root.component.html',
  styleUrls: ['root.component.scss'],
  animations: [dropdownAnimation],
  encapsulation: ViewEncapsulation.None
})
export class RootComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('acceptGPCModal') acceptGPCModal: AcceptGoodPracticeCodeModalComponent;

  active = false;

  sub: Subscription;
  sub2: Subscription;

  constructor(public us: UserService,
    private router: Router,
    public is: ImpersonatingService,
    private platformLocation: PlatformLocation,
    private ms: MenuService,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.us.roleObs.subscribe((role) => {
      let redirectRoute = localStorage.getItem('route');
      if (redirectRoute) {
        void this.router.navigate([redirectRoute]);
        localStorage.removeItem('route');
      } else if (this.router.url == '/app') {
        this.us.navigateRoleHome(role);
      }
    })

    this.sub = this.ms.onItemClick.subscribe(() => {
      this.deactivate();
    })

  }

  ngAfterViewInit() {
    this.sub2 = this.is.ready.pipe(filter(v => v === true)).subscribe(() => {
      let userRoleMustAccept = false;
      const mustAcceptRoles = Object.keys(Role).filter((key) => key !== Role.qa && key !== Role.secretary);
      for (const role of this.us.selectableRoles) {
        if (mustAcceptRoles.includes(role.role)) {
          userRoleMustAccept = true;
          break;
        }
      }

      if (this.us.user && !this.us.user.goodPracticeCodeAccepted && !this.is.impersonating && userRoleMustAccept) {
        this.acceptGPCModal.open = true;
      }
    })
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.sub2) this.sub2.unsubscribe();
  }

  stopImpersonate() {
    this.is.stopImpersonate().subscribe(() => {
      let url = (this.platformLocation as any).location.origin;
      location.href = url;
    })
  }

  toggle(active) {
    if (active) {
      this.activate();
    } else {
      this.deactivate();
    }
  }

  activate() {
    this.active = true;
    this.renderer.addClass(document.body, 'overflow-sm-hidden');
  }

  deactivate() {
    this.active = false;
    this.renderer.removeClass(document.body, 'overflow-sm-hidden');
  }

}