import { IActivity } from './IActivity';
import { IProcess } from './IProcess';
import { IStudent } from './IStudent';

export interface IValidationProcess extends IProcess {
  activityId: string,
  studentId: string,
  state: VPState,
  validationMsg?: string,

  // Populate
  activity?: IActivity,
  student?: IStudent
}

export enum VPState {
  PendingTutorValitation = "PendingTutorValidation",
  PendingStudentReview = "PendingStudentReview"
}

export enum TKValidation {
  validation = "validation",
  review = "review"
}