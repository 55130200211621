<div class="card">
  <div class="card-body">

    <legend class="d-flex justify-content-between">
      <span>
        {{ 'doctoral-program' | translate }}
      </span>
      <span *ngIf="ss.editMode"
        class="btn-icon"
        (click)="editProgramOpen = true">
        <i class="far fa-edit"></i>
      </span>
    </legend>

    <text-info icon="book-alt">
      <a [routerLink]="['/app/service/programs/' + student.programId]"
        *ngIf="us.isService()">
        {{ student.program.name }}
      </a>
      <span *ngIf="!us.isService()">
        {{ student.program.name }}
      </span>
    </text-info>

    <text-info icon="book"
      *ngIf="student.lineOfResearch">
      {{ student.lineOfResearch.name }}
    </text-info>

    <text-info icon="school"
      mb="3">
      <a [routerLink]="['/app/service/faculties/' + student.facultyId]"
        *ngIf="us.isService()">
        {{ student.faculty.name }}
      </a>
      <span *ngIf="!us.isService()">
        {{ student.faculty.name }}
      </span>
    </text-info>

    <legend>
      {{ 'tutor' | translate }}
    </legend>

    <text-info icon="user"
      mb="3"
      *ngIf="student.tutor">
      <user-file-link [user]="student.tutor"></user-file-link>
    </text-info>
    <div class="mb-3"
      *ngIf="!student.tutor">
      <i>{{ 'no-tutor' | translate }}</i>
    </div>

    <legend>
      <span *ngIf="student.supervisor2 || student.supervisor3; else supervisorSingular">
        {{ 'supervisors' | translate }}
      </span>
      <ng-template #supervisorSingular>
        <span>
          {{ 'supervisor' | translate }}
        </span>
      </ng-template>
    </legend>
    <text-info icon="user"
      *ngIf="student.supervisor1">
      <user-file-link [user]="student.supervisor1"></user-file-link>
    </text-info>
    <text-info icon="user"
      *ngIf="student.supervisor2">
      <user-file-link [user]="student.supervisor2"></user-file-link>
    </text-info>
    <text-info icon="user"
      *ngIf="student.supervisor3">
      <user-file-link [user]="student.supervisor3"></user-file-link>
    </text-info>
    <div class="mb-1"
      *ngIf="!student.supervisor1 && !student.supervisor2 && !student.supervisor3">
      <i>{{ 'no-supervisors' | translate }}</i>
    </div>

  </div>
</div>

<program-edit-modal *ngIf="ss.editMode"
  [student]="student"
  [(open)]="editProgramOpen"></program-edit-modal>