import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

import { IEnrollment } from '../../../../../../../interfaces/IStudent';

@Component({
  selector: 'enrollments-list',
  templateUrl: 'enrollments-list.component.html'
})

export class EnrollmentListComponent implements OnInit, OnChanges {

  @Input() enrollments: IEnrollment[];

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enrollments) {
      this.enrollments = _.orderBy(this.enrollments, 'year', 'desc');
    }
  }
}