import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';

import { IMessage } from '../../../../../interfaces/IMessage';
import { MessageHttpService } from '../../../data-services/message-http.service';
import { dropdownAnimation } from '../../../UI/animations/animations';
import { DropdownService } from '../../../UI/dropdown-service/dropdown.service';
import { LoadingIndicatorService } from '../../../UI/loading-indicator/loading-indicator.service';

@Component({
  selector: 'messages-dropdown',
  templateUrl: 'messages-dropdown.component.html',
  animations: [dropdownAnimation]
})
export class MessagesDropdownComponent implements OnInit {
  @ViewChild('messagesDropdown') messagesDropdown: ElementRef;

  count = 0;
  unread = 0;
  messages: IMessage[] = [];
  size: number = 5;
  page: number = 1;

  loadingMore = false;

  firstLoad = false;

  constructor(public dds: DropdownService,
    private ms: MessageHttpService,
    private loading: LoadingIndicatorService,
    private zone: NgZone) { }

  ngOnInit() {
    this.ms.unreadCount().subscribe((count) => {
      this.unread = count;
    });

    this.ms.count().subscribe((count) => {
      this.count = count;
    });
  }

  open() {
    if (!this.firstLoad) {
      this.page = 1;
      this.loading.start();
      this.ms.fetchMessages({ page: this.page, size: this.size }).subscribe((messages) => {
        this.messages = this.messages.concat(messages);
        this.firstLoad = true;
        this.loading.stop();
        this.zone.run(() => {
          this.dds.toggle('messagesDropdown', this.messagesDropdown);
        });
      });
    } else {
      this.dds.toggle('messagesDropdown', this.messagesDropdown);
    }
  }

  close() {
    this.dds.close('messagesDropdown');
  }

  loadMore() {
    this.page += 1;
    this.loadingMore = true;
    this.ms.fetchMessages({ page: this.page, size: this.size }).subscribe((messages) => {
      this.messages = this.messages.concat(messages);
      this.loadingMore = false;
    });
  }

  markAllAsRead() {
    this.loading.start();
    this.ms.markAllAsRead().subscribe(() => {
      this.unread = 0;
      this.messages = this.messages.map(m => {
        return {
          ...m,
          read: true
        }
      })
      this.loading.stop();
    })
  }

  markAsRead(messageId) {
    this.ms.markAsRead(messageId).subscribe(() => {
      const index = this.messages.findIndex(m => m._id == messageId);
      if (index > -1) {
        this.messages[index].read = true;
      }

      this.unread -= 1;
    });
  }

  markAsUnread(messageId) {
    this.ms.markAsUnread(messageId).subscribe(() => {
      const index = this.messages.findIndex(m => m._id == messageId);
      if (index > -1) {
        this.messages[index].read = false;
      }

      this.unread += 1;
    });
  }
}