import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IDocument } from '../../../../../../interfaces/IDocument';


@Component({
  selector: 'version-historic',
  templateUrl: 'version-historic.component.html'
})
export class VersionHistoricComponent implements OnInit {

  @Input() document: IDocument;

  @Output() onDownloadVersion = new EventEmitter<{ documentId: string, vn: string }>();
  @Output() onDownload = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

}