<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="mb-0">
      <div class="card-body"
        *ngIf="sr.student && !requested">

        <h5 class="card-title">
          {{ 'process.thesis.request.name' | translate }}
        </h5>

        <p [innerHTML]="'process.thesis.request.desc' | translate">
        </p>
      </div>
    </div>

    <ng-container *ngIf="sr.student && !requested && !reviewData">
      <thesis-request-form [student]="sr.student"
        [data]="data"
        [sendBtnText]="'validate-and-review'"
        [sendBtnClass]="'outline-primary'"
        (onSend)="reviewStep($event); hs.scrollToTop()"></thesis-request-form>
    </ng-container>

    <div class="card"
      *ngIf="sr.student && !requested && reviewData">
      <div class="card-body">

        <thesis-data-view [student]="sr.student"
          [data]="data"
          [hideCompendiumDirectorReport]="true"></thesis-data-view>

        <div class="form-group text-right mt-2">
          <button class="btn btn-outline-dark mr-2"
            type="button"
            (click)="reviewData = false; hs.scrollToTop()"
            [disableButton]="disabled">
            {{ 'modify' | translate}}
          </button>
          <button class="btn btn-primary"
            type="button"
            (click)="send()"
            [disableButton]="disabled || sending"
            [disableButtonIcon]="sending ? 'spin' : 'ban'">
            {{ 'send' | translate}}
          </button>
        </div>

      </div>
    </div>

    <div class="card"
      *ngIf="sr.student && requested">
      <div class="card-body">
        <div class="w-100 text-center"
          *ngIf="requested">
          <h5 class="card-title m-0">
            <i class="far fa-check text-success mr-1"></i>
            {{ 'request-sended' | translate }}
          </h5>
          <div *ngIf="doc"
            class="mt-4 w-100 text-left">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>