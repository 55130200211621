import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IStudent } from '../../../../../../../interfaces/IStudent';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { IThesisCommitteeMemberData, IThesisProcessData, ThesisFinalEvaluation } from '../../../../../../../interfaces/IThesisProcess';
import * as _ from 'lodash';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { NgForm } from '@angular/forms';

interface IDefenceData {
  thesisFinal: IDocumentRef,
  changesReport: IDocumentRef,

  pgcDefenceApprobalDate?: Date,
  acceptedForProcessingDate?: Date,

  defenceDate?: Date,
  defencePlace?: string,

  thesisFinalEvaluation?: ThesisFinalEvaluation;
  cumlaudeMention?: boolean;
  thesisDefenceRecord?: IDocumentRef;
  internationalMentionFinal?: boolean;
  industrialMentionFinal?: boolean;
  finalThesisCommittee?: {
    president: IThesisCommitteeMemberData,
    secretary: IThesisCommitteeMemberData,
    member: IThesisCommitteeMemberData,
  },
  specialAdward?: boolean;
  specialAdwardDocs?: IDocumentRef[];
  videoConference?: boolean;
  videoConferenceDocs?: IDocumentRef[];
  hybridDefence?: boolean;
  hybridDefenceDocs?: IDocumentRef[];

  riuma?: IDocumentRef;
  teseo?: IDocumentRef;
  designation?: IDocumentRef;
  embargo?: IDocumentRef;
  additionalReports?: IDocumentRef[];
}

@Component({
  selector: 'thesis-defence-edit-modal',
  templateUrl: 'thesis-defence-edit-modal.component.html'
})
export class ThesisDefenceEditModalComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() open = false;
  @Input() student: IStudent;

  @Input() proccesingData: IThesisProcessData;

  @Input() data: IDefenceData;

  members: IThesisCommitteeMemberData[] = [];
  presidentId: string;
  secretaryId: string;
  memberId: string;

  clonedData: IDefenceData;

  sending = false;

  ThesisFinalEvaluation = ThesisFinalEvaluation;

  constructor(private ss: ServiceHttpService,
    private alerts: PopAlertsService,
    private rs: RefreshService) { }

  ngOnInit() {

  }

  doOpen() {
    this.members = [];
    if (!(this.proccesingData.cotutelle && this.proccesingData.cotutelleDefenceOut)) {
      this.members.push(this.proccesingData.thesisCommittee.president);
      this.members.push(this.proccesingData.thesisCommittee.secretary);
      this.members.push(this.proccesingData.thesisCommittee.member);
      this.members.push(this.proccesingData.thesisCommittee.alt1);
      this.members.push(this.proccesingData.thesisCommittee.alt2);
      this.members.push(this.proccesingData.thesisCommittee.alt3);

      let committee;
      if (this.data.finalThesisCommittee) {
        committee = this.data.finalThesisCommittee;
      } else {
        committee = this.proccesingData.thesisCommittee;
      }

      if (committee) {
        const president = this.members.find(m => m.idNum === committee.president.idNum);
        if (president) {
          this.presidentId = president._id;
        }
        const secretary = this.members.find(m => m.idNum === committee.secretary.idNum);
        if (secretary) {
          this.secretaryId = secretary._id;
        }
        const member = this.members.find(m => m.idNum === committee.member.idNum);
        if (member) {
          this.memberId = member._id;
        }
      }
    }
    this.clonedData = _.cloneDeep(this.data);
    this.open = true;
    this.sending = false;
  }

  assignPresident(userId) {
    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.presidentId = userId;

    // find member
    const member = this.members.find(m => m._id === userId);
    this.clonedData.finalThesisCommittee.president = member;
  }

  assignSecretary(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.secretaryId = userId;

    // find member
    const member = this.members.find(m => m._id === userId);
    this.clonedData.finalThesisCommittee.secretary = member;
  }

  assignMember(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    this.memberId = userId;

    // find member
    const member = this.members.find(m => m._id === userId);
    this.clonedData.finalThesisCommittee.member = member;
  }

  save() {
    if (this.f && this.f.valid) {
      this.sending = true;
      this.ss.editThesisDefence(this.student._id, this.clonedData).subscribe(() => {
        this.rs.onStudentChange.next();
        this.open = false;
        this.alerts.saveSuccess();
        this.sending = false;
      }, () => {
        this.sending = false;
      });
    }
  }

}