import { Component, forwardRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgModel,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { IUser } from '../../../../interfaces/IUser';
import { UserHttpService } from '../../data-services/user-http.service';
import { HelperService } from '../../shared/helper-service/helper.service';

@Component({
  selector: 'user-selector',
  templateUrl: 'user-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => UserSelectorComponent),
      multi: true
    }
  ]
})
export class UserSelectorComponent implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('emailInput') emailInput: NgModel;
  email = '';

  @Input() showErrors = false;
  @Input() sm = false;

  @Input() required = false;

  user: IUser;

  notFound = false;
  invalid = false;

  constructor(private us: UserHttpService,
    public hs: HelperService,
    private zone: NgZone) { }

  ngOnInit() {
  }

  search() {
    if (!this.email
      || this.email.length <= 0
      || !new RegExp(this.hs.EMAIL_REGEX).test(this.email)) {
      this.notFound = true;
      this.invalid = true;
      return;
    }
    this.invalid = false;
    this.us.findByEmail(this.email).subscribe((user: IUser) => {
      this.user = user;
      if (!user._id && !user.uid) {
        this.notFound = true;
        this.user = null;
        this.propagateChange();
      } else if (!user._id && user.uid) {
        // Not in DAD DB -> create
        this.us.createUserFromUID(user.uid).subscribe((userId) => {
          if (userId) {
            this.user._id = userId;
          } else {
            this.notFound = true;
            this.user = null;
          }
          this.propagateChange();
        })
      } else {
        this.propagateChange();
      }
    })
  }

  private propagateChange() {
    setTimeout(() => {
      this.zone.run(() => {
        this.onChange(this.user);
      });
    }, 0);
  }

  resetFind() {
    this.notFound = false;
    this.user = null;
    setTimeout(() => {
      this.zone.run(() => {
        this.onChange(this.user);
      });
    }, 0);
  }

  onChange = (_: any) => { };
  onTouch = () => { };

  writeValue(value: any): void {
    this.user = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.required && (!this.user || !this.user._id)) {
      return { required: true };
    } else {
      return null;
    }
  }

}