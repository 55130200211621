<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <denial-msgs-view [denialMsgs]="task.thesisProcess.denialMsgs"></denial-msgs-view>

  <ng-container *ngIf="process.student && !reviewData">
    <thesis-request-form [student]="process.student"
      [data]="process.data"
      [sendBtnText]="'validate-and-review'"
      [sendBtnClass]="'outline-primary'"
      (onSend)="reviewStep($event); hs.scrollToTop()"></thesis-request-form>
  </ng-container>

  <div class="card"
    *ngIf="process.student && reviewData">
    <div class="card-body">

      <thesis-data-view [student]="process.student"
        [data]="process.data"
        [hideCompendiumDirectorReport]="true"></thesis-data-view>

      <div class="form-group text-right mt-2">
        <button class="btn btn-outline-dark mr-2"
          type="button"
          (click)="reviewData = false; hs.scrollToTop()"
          [disableButton]="disabled">
          {{ 'modify' | translate}}
        </button>
        <button class="btn btn-primary"
          type="button"
          (click)="send()"
          [disableButton]="disabled || sending"
          [disableButtonIcon]="sending ? 'spin' : 'ban'">
          {{ 'send' | translate}}
        </button>
      </div>

    </div>
  </div>

</ng-container>