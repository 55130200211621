<div class="list-group list-group-flush"
  *ngIf="document">
  <div class="list-group-item small"
    *ngFor="let v of document.olderVersions">

    {{ v.vn }}.
    <a [routerLink]="null"
      (click)="onDownloadVersion.emit({ documentId: document._id, vn: v.vn })">
      <file-icon [fileName]="v.fileName"></file-icon>
      {{ v.fileName }}
    </a>

  </div>
  <div class="list-group-item small">
    {{ document.lastVersion.vn }}.
    <a [routerLink]="null"
      (click)="onDownload.emit(document._id)">
      <file-icon [fileName]="document.name"></file-icon>
      {{ document.name }}
    </a>
  </div>
</div>