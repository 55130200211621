import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { IMailTimeEmail } from '../../../../../../interfaces/IMailTimeEmail';
import { EmailsHttpService } from '../../../../data-services/emails-http.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { SendEmailModalService } from '../../../components/service/send-email-modal/send-email-modal.service';
import { Subscription } from 'rxjs';
import { InfiniteListComponent } from '../../../../UI/infinite-list/infinite-list.component';

@Component({
  selector: 'sent-emails',
  templateUrl: 'sent-emails.component.html'
})
export class SentEmailsComponent implements OnInit, OnDestroy {
  @ViewChild('list') list: InfiniteListComponent;
  emails: IMailTimeEmail[] = [];

  params = {
    searchText: ''
  }

  sub: Subscription;

  constructor(private es: EmailsHttpService,
    private alerts: PopAlertsService,
    public sems: SendEmailModalService) { }

  ngOnInit() {
    this.sub = this.sems.onSent.subscribe(() => {
      this.list.search();
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  forward(mailId) {
    this.es.forward(mailId).subscribe(() => {
      this.alerts.pop({
        msg: 'Correo reenviado',
        styleClass: 'success'
      })
    })
  }

  fetchFn() {
    return (params) => {
      params = {
        ...params,
        ...this.params
      }
      return this.es.fetch(params);
    }
  }

  tbFn(index, item) {
    return item._id
  }
}