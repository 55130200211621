import { Component, OnInit } from '@angular/core';
import { IProgram } from '../../../../../../interfaces/IProgram';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { ProgramHttpService } from '../../../../data-services/program-http.service';

@Component({
  selector: 'service-programs',
  templateUrl: './service-programs.component.html',
})
export class ServiceProgramsComponent implements OnInit {

  constructor(public ps: ProgramHttpService) { }

  ngOnInit() {
  }

}
