import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDocumentRef } from '../../../interfaces/IDocument';
import { ILeaveProcess, LeaveType } from '../../../interfaces/ILeaveProcess';

@Injectable()
export class LeaveHttpService {

  constructor(private http: HttpClient) { }

  request(studentId: string, data: { justification: IDocumentRef[], type: LeaveType, start: Date, end?: Date, estimatedEnd?: Date }): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/leave/request/${studentId}`, data);
  }

  academicCommitteeValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/leave/academicCommitteeValidation/${taskId}`, {});
  }

  academiccommitteeDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/leave/academiccommitteeDeny/${taskId}`, data);
  }

  serviceValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/leave/serviceValidation/${taskId}`, {});
  }

  serviceDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/leave/serviceDeny/${taskId}`, data);
  }

  findProcess(taskId: string): Observable<ILeaveProcess> {
    return this.http.get<ILeaveProcess>(`/api/leave/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<ILeaveProcess> {
    return this.http.get<ILeaveProcess>(`/api/leave/process/${studentId}`);
  }

}