import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'alert-icon',
  template: `
  
    <div [class]="'alert alert-' + type">
      <div class="d-flex justify-content-start align-items-center">
        <div class="p-2 mr-2">
          <i [class]="icon + ' fa-3x'"></i>
        </div>
        <div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  
  `

})

export class AlertIconComponent implements OnInit {
  @Input() icon: string;
  @Input() type: "primary" | "secondary" | "info" | "warning" | "danger" | "dark" | "light" = "primary";

  constructor() { }

  ngOnInit() { }
}