import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';

export class HttpErrorObservable {

  errors = new Subject<HttpErrorResponse>();

  static instance: HttpErrorObservable;

  private constructor() {

  }

  static getInstance() {
    if (!this.instance) this.instance = new HttpErrorObservable();
    return this.instance;
  }
}