import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip } from 'rxjs';

import { IEvaluationCommittee } from '../../../interfaces/IEvaluationCommittee';
import { ILineOfResearch } from '../../../interfaces/ILineOfResearch';
import { IProgram } from '../../../interfaces/IProgram';
import { IStudent, StudentStateType } from '../../../interfaces/IStudent';
import { UserService } from '../user/user.service';

import * as _ from 'lodash';
import { IUser } from '../../../interfaces/IUser';

// import { Observable } from 'rxjs';
// import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ProgramHttpService {

  programs: IProgram[] = [];
  programsMap: { [programId: string]: IProgram } = {};
  programsObs = new BehaviorSubject<IProgram[]>([]);

  isProfessorProcessOpen: boolean;
  professorProcessStart: Date;
  professorProcessEnd: Date;

  constructor(private http: HttpClient, private us: UserService) {
    zip(this.fetchPrograms(), this.isProfessorProcessOpened()).subscribe((res) => {
      const programs = res[0];
      this.isProfessorProcessOpen = res[1].opened;
      this.professorProcessStart = res[1].start;
      this.professorProcessEnd = res[1].end;
      this.programs = _.sortBy(programs, ['name']);
      this.programs.forEach(p => this.programsMap[p._id.toString()] = p);
      this.programsObs.next(this.programs);
    });
  }

  updateProfessorProcess() {
    this.isProfessorProcessOpened().subscribe((res) => {
      this.isProfessorProcessOpen = res.opened;
      this.professorProcessStart = res.start;
      this.professorProcessEnd = res.end;
    });
  }

  edit(programId: string, name: string, facultyId: string, coordinatorId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/edit`, {
      name, facultyId, coordinatorId
    });
  }

  programInfo(programId: string): Observable<IProgram> {
    return this.http.get<IProgram>(`/api/program/${programId}/info`);
  }

  linesOfResearch(programId: string): Observable<ILineOfResearch[]> {
    return this.http.get<ILineOfResearch[]>(`/api/program/${programId}/linesOfResearch`);
  }

  academicCommitteeMembers(programId: string): Observable<IUser[]> {
    return this.http.get<IUser[]>(`/api/program/${programId}/academicCommitteeMembers`);
  }

  modifyEvaluationsAuth(programId: string): Observable<IUser[]> {
    return this.http.get<IUser[]>(`/api/program/${programId}/modifyEvaluationsAuth`);
  }

  fetchCoordinator(programId: string): Observable<IUser> {
    return this.http.get<IUser>(`/api/program/${programId}/coordinator`);
  }

  addACUser(programId: string, userId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/academicCommittee/add`, {
      userId
    });
  }

  removeACUser(programId: string, userId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/academicCommittee/remove`, {
      userId
    });
  }

  authModifyEvaluations(programId: string, userId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/authModifyEvaluations/add`, {
      userId
    });
  }

  removeAuthModifyEvaluations(programId: string, userId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/authModifyEvaluations/remove`, {
      userId
    });
  }

  evaluationCommittees(programId: string): Observable<IEvaluationCommittee[]> {
    return this.http.get<IEvaluationCommittee[]>(`/api/program/${programId}/evaluationCommittees`);
  }

  addEvaluationCommittee(programId: string): Observable<IEvaluationCommittee> {
    return this.http.post<IEvaluationCommittee>(`/api/program/${programId}/evaluationCommittees`, {});
  }

  addMemberToEvaluationCommittee(programId: string, evaluationCommitteeId: string, userId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/evaluationCommittees/addMember`, {
      evaluationCommitteeId,
      userId
    });
  }

  removeMemberFromEvaluationCommittee(programId: string, evaluationCommitteeId: string, userId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/evaluationCommittees/removeMember`, {
      evaluationCommitteeId,
      userId
    });
  }

  removeEvaluationCommittee(programId: string, evaluationCommitteeId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/evaluationCommittees/${evaluationCommitteeId}/remove`, {});
  }

  restoreEvaluationCommittee(programId: string, evaluationCommitteeId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/evaluationCommittees/${evaluationCommitteeId}/restore`, {});
  }

  unassignStudentEvaluationCommittee(programId: string, evaluationCommitteeId: string, studentId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/evaluationCommittees/${evaluationCommitteeId}/unassign/${studentId}`, {});
  }

  assignStudentEvaluationCommittee(programId: string, evaluationCommitteeId: string, studentId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/evaluationCommittees/${evaluationCommitteeId}/assign/${studentId}`, {});
  }

  fetchPrograms(): Observable<IProgram[]> {
    return this.http.get<IProgram[]>(`/api/program/all`);
  }

  fetchProgramStudents(programId: string, params: { states?: StudentStateType[], searchText?: string, page?: number, size?: number }): Observable<IStudent[]> {
    return this.http.post<IStudent[]>(`/api/program/${programId}/students`, { ...params });
  }

  fetchAllProgramStudents(programId: string): Observable<IStudent[]> {
    return this.http.get<IStudent[]>(`/api/program/${programId}/students/all`);
  }


  saveEvaluationPeriod1End(programId: string, evaluationPeriod1End: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/saveEvaluationPeriod1End`, { evaluationPeriod1End });
  }

  saveEvaluationPeriod2End(programId: string, evaluationPeriod2End: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/saveEvaluationPeriod2End`, { evaluationPeriod2End });
  }

  addLineOfResearch(programId: string, name: string): Observable<{ lineOfResearchId: string }> {
    return this.http.post<{ lineOfResearchId: string }>(`/api/program/${programId}/addLineOfResearch`, { name });
  }

  removeLineOfResearch(programId: string, lineOfResearchId: string, lineOfResearchToSwitchId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/removeLineOfResearch`, { lineOfResearchId, lineOfResearchToSwitchId });
  }

  editLineOfResearch(programId: string, lineOfResearchId: string, name: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/editLineOfResearch`, { name, lineOfResearchId });
  }

  updateEvaluationCommittee(programId: string, evaluationCommitteeId: string, year: string, number: number): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/program/${programId}/evaluationCommittees/${evaluationCommitteeId}/update`, { year, number });
  }

  isProfessorProcessOpened(): Observable<{ opened: boolean, start: Date, end: Date }> {
    return this.http.get<{ opened: boolean, start: Date, end: Date }>('/api/program/professorProcessStatus');
  }

}