import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IMailTimeEmail, IMailTimeEmailOptions } from '../../../../../interfaces/IMailTimeEmail';

@Component({
  selector: 'mail-time-email',
  templateUrl: './mail-time-email.component.html',
  styles: [
    `
    .one-line {
      width: 100%;
      overflow:hidden;
      text-overflow: ellipsis;
    }
    
    `
  ]
})
export class MailTimeEmailComponent implements OnInit {
  @Input() email: IMailTimeEmail;
  @Input() printMode = false;
  @Output() onForward = new EventEmitter<IMailTimeEmailOptions>();

  expanded = false;

  constructor() { }

  ngOnInit() {
    if (this.printMode === true) {
      this.expanded = true;
    }
  }

  plain(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, " ");
  }

  printUrl(mailId) {
    return `${window.location.origin}/print-email/${mailId}`
  }

}
