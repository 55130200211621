import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IMailTimeEmailOptions } from '../../../../../../interfaces/IMailTimeEmail';
import { IUser } from '../../../../../../interfaces/IUser';
import { IStudent } from '../../../../../../interfaces/IStudent';
import { IThesisCommitteeMemberData } from '../../../../../../interfaces/IThesisProcess';

@Injectable()
export class SendEmailModalService {

  openSendEmailModal = new Subject<IUser | IStudent | IThesisCommitteeMemberData>();
  forwardSendEmailModal = new Subject<IMailTimeEmailOptions>();

  onSent = new Subject<string>();

  constructor() { }

  forward(mail: IMailTimeEmailOptions) {
    this.forwardSendEmailModal.next(mail);
  }

  open(user: IUser | IStudent | IThesisCommitteeMemberData) {
    this.openSendEmailModal.next(user);
  }

}