<ng-container *ngIf="programId && ps.programsMap[programId] && selected && prev && next">
  <div class="row"
    *ngIf="showProgramName">
    <div class="col text-center mb-4">

      <ng-container *ngIf="programsIds.length === 1">
        <h4>
          {{ ps.programsMap[programId].name }}
        </h4>
      </ng-container>
      <ng-container *ngIf="programsIds.length > 1">
        <ng-select id="programFilter"
          name="programFilter"
          [items]="ps.programs"
          bindValue="_id"
          bindLabel="name"
          style="width: 100%;"
          [ngModel]="programId"
          (ngModelChange)="programId = $event; fetch();"
          [placeholder]="'program' | translate"
          [searchable]="true"
          [clearable]="false"
          [multiple]="false">
        </ng-select>
      </ng-container>

    </div>
  </div>

  <div class="row">
    <div class="col text-center mb-3">

      <button class="btn btn-link btn-sm mr-2 d-inline-block"
        (click)="selectPrev()">
        <i class="far fa-angle-left mr-1"></i>
        {{ 'previous' | translate }}
      </button>

      <h5 class="d-inline-block"
        style="width: 180px">
        {{ selected.period | translate }}
        {{ selected.date | moment:'YYYY' }}
      </h5>

      <button class="btn btn-link btn-sm ml-2 d-inline-block"
        (click)="selectNext()"
        [disabled]="!showNext()">
        {{ 'next' | translate }}
        <i class="far fa-angle-right ml-1"></i>
      </button>

    </div>
  </div>

  <div class="row"
    *ngIf="localLoading && !loaded">
    <div class="col">
      <div class="card">
        <div class="card-body text-center">

          <i class="far fa-circle-notch fa-spin mr-1 text-secondary"></i>
          Recuperando listas...

        </div>
      </div>
    </div>
  </div>

  <div class="row"
    *ngIf="control">
    <div class="col-12 col-xl-4 col-md-6">

      <div class="card mb-4">
        <div class="card-header">
          {{ 'evaluation-list' | translate }}
          <span *ngIf="control.included.length > 0">
            ({{control.included.length}})
          </span>
        </div>
        <div class="list-group list-group-sm">
          <cdk-virtual-scroll-viewport #scrollViewport
            [style.height]="Math.min(500, control.included.length * 50) + 'px'"
            itemSize="50">
            <div class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let ctr of control.included; trackBy:hs.trackById">
              <div>
                <a [routerLink]="null"
                  (click)="goToStudent(ctr.student)">
                  {{ ctr.student | fullname }}
                </a>
              </div>
              <div class="text-right nowrap">
                <span [tooltip]="ctr.reason | translate"
                  class="text-info p-1 c-pointer"
                  *ngIf="ctr.reason !== EvaluationReason.oneYear && ctr.reason !== EvaluationReason.lastEvaluationNegative">
                  <i class="far fa-info-circle"></i>
                </span>
                <span [tooltip]="ctr.reason | translate"
                  class="text-danger p-1 c-pointer"
                  *ngIf="ctr.reason === EvaluationReason.lastEvaluationNegative">
                  <i class="far fa-exclamation-circle"></i>
                </span>
                <a [routerLink]="null"
                  role="button"
                  class="ml-1 text-muted p-1"
                  [tooltip]="'remove-from-evaluation-control' | translate"
                  (click)="removeFromEvaluation(ctr.student)">
                  <i class="far fa-trash"></i>
                </a>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
          <div class="list-group-item"
            *ngIf="control.included.length <= 0">
            <i>{{ 'no-included-to-evaluation' | translate }}</i>
          </div>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-header d-flex justify-content-between align-items-center">
          <div>
            {{ 'excluded-from-evaluation' | translate }}
            <span *ngIf="control.excluded.length > 0">
              ({{control.excluded.length}})
            </span>
          </div>
          <div>
            <button role="button"
              type="button"
              class="btn btn-sm btn-outline-dark"
              (click)="showExcluded = !showExcluded"
              *ngIf="control.excluded.length > 0">
              <span *ngIf="showExcluded">
                Ocultar
              </span>
              <span *ngIf="!showExcluded">
                Mostrar
              </span>
            </button>
          </div>
        </div>
        <div class="list-group list-group-sm"
          *ngIf="showExcluded">
          <cdk-virtual-scroll-viewport #scrollViewport
            [style.height]="Math.min(500, control.excluded.length * 50) + 'px'"
            itemSize="50">
            <div class="list-group-item list-group-item-dark d-flex justify-content-between align-items-center"
              *ngFor="let ctr of control.excluded; trackBy:hs.trackById">
              <div>
                <a [routerLink]="null"
                  (click)="goToStudent(ctr.student)">
                  {{ ctr.student | fullname }}
                </a>
              </div>
              <div class="text-right nowrap">
                <span [tooltip]="ctr.reason | translate"
                  class="text-info p-1 c-pointer">
                  <i class="far fa-info-circle"></i>
                </span>
                <a [routerLink]="null"
                  role="button"
                  class="ml-1 text-muted p-1"
                  [tooltip]="'add-to-evaluation-control' | translate"
                  (click)="addToEvaluation(ctr.student)">
                  <i class="far fa-plus"></i>
                </a>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
          <div class="list-group-item"
            *ngIf="control.excluded.length <= 0">
            <i>{{ 'no-excluded-from-evaluation' | translate }}</i>
          </div>
        </div>
      </div>


    </div>

    <div class="col-12 col-xl-4 px-xl-0 col-md-6 pl-md-0">

      <div class="card mb-4">
        <div class="card-header">
          {{ 'confirm-committee' | translate }}
          <span *ngIf="confirmTasks.length > 0">
            ({{confirmTasks.length}})
          </span>
        </div>
        <div class="list-group list-group-sm">
          <cdk-virtual-scroll-viewport #scrollViewport
            [style.height]="Math.min(500, confirmTasks.length * 50) + 'px'"
            itemSize="50">
            <div class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let t of confirmTasks; trackBy:hs.trackById">
              <div>
                <a [routerLink]="null"
                  (click)="goToStudent(t.evaluationProcess.student)">
                  {{ t.evaluationProcess.student | fullname }}
                </a>
              </div>
              <div>
                <a [routerLink]="null"
                  role="button"
                  class="p-1"
                  [tooltip]="'open-task' | translate"
                  (click)="confirmation.task = t; confirmation.init(); open = true">
                  <i class="far fa-external-link-square"></i>
                </a>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
        <div class="list-group-item"
          *ngIf="confirmTasks.length <= 0">
          <i>{{ 'no-pending-confirm-committee-tasks' | translate }}</i>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-header">
          {{ 'ongoing-evaluations' | translate }}
          <span *ngIf="processes.length > 0">
            ({{processes.length}})
          </span>
        </div>
        <div class="list-group list-group-sm">
          <cdk-virtual-scroll-viewport #scrollViewport
            [style.height]="Math.min(500, processes.length * 50) + 'px'"
            itemSize="50">
            <div class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let p of processes; trackBy:hs.trackById">
              <a [routerLink]="null"
                (click)="goToStudent(p.student)">
                {{ p.student | fullname }}
              </a>
              <div>
                <span class="badge badge-dark"
                  [tooltip]="p.state + 'Info' | translate">
                  {{ p.state | translate }}
                </span>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
          <div class="list-group-item"
            *ngIf="processes.length <= 0">
            <i>{{ 'no-ongoing-evaluations-yet' | translate }}</i>
          </div>
        </div>
      </div>

    </div>

    <div class="col-12 col-xl-4">

      <div class="card mb-4">
        <div class="card-header">
          {{ 'evaluated-students' | translate }}
          <span *ngIf="evaluated.length > 0">
            ({{evaluated.length}})
          </span>
        </div>
        <div class="list-group list-group-sm">
          <cdk-virtual-scroll-viewport #scrollViewport
            [style.height]="Math.min(500, evaluated.length * 50) + 'px'"
            itemSize="50">
            <div class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let student of evaluated; trackBy:hs.trackById">
              <div>
                <a [routerLink]="null"
                  (click)="goToStudent(student)">
                  {{ student | fullname }}
                </a>
              </div>
              <div>
                <span class="badge"
                  [class.badge-success]="evaluatedMap[student._id].evaluation === EvaluationType.positive"
                  [class.badge-danger]="evaluatedMap[student._id].evaluation === EvaluationType.negative"
                  [class.badge-warning]="evaluatedMap[student._id].evaluation === EvaluationType.noShow">
                  {{ evaluatedMap[student._id].evaluation | translate }}
                </span>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
          <div class="list-group-item"
            *ngIf="evaluated.length <= 0">
            <i>{{ 'no-evaluated-students-yet' | translate }}</i>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div class="row"
    *ngIf="!control && loaded && showNext()">
    <div class="col">
      <div class="card">
        <div class="card-body">
          {{ 'no-evaluation-control-data' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="row"
    *ngIf="!control && !showNext()">
    <div class="col"
      *ngIf="!projectedLoaded">
      <div class="card">
        <div class="card-body">

          <button class="btn btn-primary"
            (click)="getProjected()">
            <span *ngIf="loadingProjected">
              <i class="far fa-circle-notch fa-spin mr-1"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="col"
      *ngIf="projectedLoaded">
      <div class="card mb-4">
        <div class="card-header">
          Alumnos que tendrían la obligación de evaluarse
          <span *ngIf="projected.included.length > 0">
            ({{projected.included.length}})
          </span>
        </div>
        <div class="card-body">
          <div class="alert alert-info">
            <i class="far fa-info-circle mr-1"></i>
            <span>
              La siguiente lista es una proyección usando los datos a día de hoy de los alumnos que tendrían la
              obligación de presentarse a la evaluación. Esta lista no es definitiva. El cálculo final se realizará al
              comienzo de la evaluación, usando los datos que se tengan en ese momento.
            </span>
          </div>
        </div>
        <div class="list-group list-group-sm">
          <cdk-virtual-scroll-viewport #scrollViewport
            [style.height]="Math.min(500, projected.included.length * 50) + 'px'"
            itemSize="50">
            <div class="list-group-item d-flex justify-content-between align-items-center"
              *ngFor="let ctr of projected.included; trackBy:hs.trackById">
              <div>
                <a [routerLink]="null"
                  (click)="goToStudent(ctr.student)">
                  {{ ctr.student | fullname }}
                </a>
              </div>
              <div class="text-right nowrap">
                <span class="text-info p-1 c-pointer"
                  *ngIf="ctr.reason !== EvaluationReason.lastEvaluationNegative">
                  <i class="far fa-info-circle mr-1"></i>
                  {{ ctr.reason | translate }}
                </span>
                <span [tooltip]="ctr.reason | translate"
                  class="text-danger p-1 c-pointer"
                  *ngIf="ctr.reason === EvaluationReason.lastEvaluationNegative">
                  <i class="far fa-exclamation-circle mr-1"></i>
                  {{ ctr.reason | translate }}
                </span>
              </div>
            </div>
          </cdk-virtual-scroll-viewport>
          <div class="list-group-item"
            *ngIf="projected.included.length <= 0">
            <i>{{ 'no-included-to-evaluation' | translate }}</i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal [(open)]="open"
    [showFooter]="false">
    <evaluation-committee-confirmation #confirmation
      [redirect]="false"
      (onComplete)="fetch(); open = false;"></evaluation-committee-confirmation>
  </modal>

</ng-container>