import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ProgramHttpService } from '../../../../data-services/program-http.service';

@Component({
  selector: 'service-evaluation',
  templateUrl: './service-evaluation.component.html'
})
export class ServiceEvaluationComponent implements OnInit, OnDestroy {

  programsIds = [];
  sub: Subscription;

  constructor(public ps: ProgramHttpService) { }

  ngOnInit() {
    this.sub = this.ps.programsObs.subscribe(() => {
      this.programsIds = this.ps.programs.map(p => p._id);
    })
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

}
