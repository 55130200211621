import { Component, OnInit } from '@angular/core';

import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'thesis-view',
  templateUrl: './thesis-view.component.html',
})
export class ThesisViewComponent implements OnInit {

  constructor(public sr: StudentRootService) { }

  ngOnInit() {
  }

}
