<modal [(open)]="open"
  (openChange)="openChange.emit($event)"
  [title]="'edit-program' | translate">
  <form #f="ngForm"
    id="editProgramForm"
    (ngSubmit)="save()">
    <div class="form-group">
      <label>{{ 'program' | translate }}</label>
      <ng-select name="program"
        [items]="ps.programs"
        style="width: 100%;"
        required="true"
        bindLabel="name"
        #programInput="ngModel"
        [(ngModel)]="program"
        (ngModelChange)="resetFaculties(); resetLinesOfResearch()"
        [placeholder]="'program' | translate"
        [searchable]="true"
        [clearable]="false"
        [multiple]="false">
      </ng-select>
      <validation-errors [control]="programInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="lineOfResearchSelect">
        {{ 'line-of-research' | translate }}
      </label>
      <ng-select id="lineOfResearchSelect"
        name="lineOfResearchSelect"
        #lineOfResearchSelect="ngModel"
        [items]="linesOfResearch"
        bindValue="_id"
        bindLabel="name"
        [(ngModel)]="lineOfResearchId"
        [class.is-invalid]="f.submitted && lineOfResearchSelect.invalid"
        [placeholder]="'select-line-of-research' | translate"
        [searchable]="false"
        [clearable]="true">
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="lineOfResearchSelect"></validation-errors>
    </div>

    <div class="form-group">
      <label>{{ 'faculty' | translate }}</label>
      <ng-select name="faculty"
        [items]="faculties"
        bindValue="_id"
        bindLabel="name"
        style="width: 100%;"
        required="true"
        #facultyInput="ngModel"
        [(ngModel)]="facultyId"
        [placeholder]="'faculty' | translate"
        [disabled]="faculties.length <= 1"
        [searchable]="true"
        [clearable]="false"
        [multiple]="false">
      </ng-select>
      <validation-errors [control]="facultyInput"
        [show]="f.submitted"></validation-errors>
    </div>
  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="close()">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="editProgramForm">{{ 'accept' | translate }}</button>
  </div>

</modal>