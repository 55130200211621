import { Component, Input, OnInit } from '@angular/core';
import { ICDSProcess, ICDSRequestData } from '../../../../../../../interfaces/ICDSProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { CDSHttpService } from '../../../../../data-services/cds-http.service';
import { ProgramHttpService } from '../../../../../data-services/program-http.service';
import { UserService } from '../../../../../user/user.service';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { Router } from '@angular/router';
import { DocumentService } from '../../../../../shared/document-service/document.service';

@Component({
  selector: 'cds-student-request-review',
  templateUrl: 'cds-student-request-review.component.html'
})
export class CDSStudentRequestReviewComponent extends AbstractTaskComponent implements OnInit {
  @Input() task: ITask;

  process: ICDSProcess;

  denialMsg = '';

  data: ICDSRequestData = {
    dedication: null,
    validate: null,
    tutor: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor1: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor2: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor3: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    needsSupplement: false,
    supplement: {
      credits: null,
      document: null,
      notes: ''
    }
  };

  enableSupervisor2 = false;
  enableSupervisor3 = false;

  canComplete = true;

  constructor(private cds: CDSHttpService,
    private us: UserService,
    private router: Router,
    private ds: DocumentService) {
    super();
  }

  ngOnInit() {
    this.canComplete = this.us.canComplete(this.task);
    this.cds.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      this.data.dedication = process.dedication;
      if (process.tutor) {
        this.data.tutor = process.tutor;
      }
      if (process.partialTimeDoc) {
        this.data.partialTimeDoc = process.partialTimeDoc;
      }
      if (process.supervisor1) {
        this.data.supervisor1 = process.supervisor1;
      }
      if (process.supervisor2) {
        this.data.supervisor2 = process.supervisor2;
        this.enableSupervisor2 = true;
      }
      if (process.supervisor3) {
        this.data.supervisor3 = process.supervisor3;
        this.enableSupervisor3 = true;
      }
      if (process.thirdSupervisorDoc) {
        this.data.thirdSupervisorDoc = process.thirdSupervisorDoc;
      }
      if (process.needsSupplement) {
        this.data.needsSupplement = process.needsSupplement;
      }
      if (process.supplement) {
        this.data.supplement = process.supplement;
      }
      if (process.lineOfResearchId) {
        this.data.lineOfResearchId = process.lineOfResearchId;
      }

    })
  }

  manageSend(data: ICDSRequestData) {
    this.cds.studentRequestReview(this.task._id, data).subscribe((docRef) => {
      this.sendEnd.next();
      setTimeout(() => {
        if (docRef) {
          this.ds.download(docRef.documentRef);
        }
      }, 1000);

      void this.router.navigate(['/app/task-completed'], { queryParams: { docRef: docRef.documentRef } });
    });
  }
}