<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <div class="form-group">
      <label for="ec">
        {{ 'evaluation-committee' | translate }}
      </label>
      <ng-select [items]="evaluationCommittees"
        bindLabel="code"
        required="true"
        [multiple]="false"
        [searchable]="false"
        id="ec"
        name="ec"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-evaluation-committee' | translate"
        #ec="ngModel"
        [class.is-invalid]="ec.invalid && f.submitted"
        [(ngModel)]="selected">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item.code }}
          <span class="small text-muted"
            *ngIf="original && original._id == item._id">
            asignado actualmente
          </span>
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item.code }}
          <span class="small text-muted"
            *ngIf="original && original._id == item._id">
            asignado actualmente
          </span>
        </ng-template>
      </ng-select>
      <validation-errors [control]="ec"
        [show]="f.submitted"></validation-errors>
    </div>

    <div *ngIf="selected"
      class="list-group">
      <div *ngIf="selected.membersIds.length <= 0"
        class="list-group-item">
        <span class="d-block invalid-feedback">
          {{ 'empty-evaluation-committee' | translate }}
        </span>
      </div>
      <div *ngFor="let member of selected.members"
        class="list-group-item">
        {{ member | fullname }}
        <span class="badge badge-info mr-1"
          *ngIf="member._id === process.student.tutorId">
          {{ 'student-tutor' | translate }}
        </span>
        <span class="badge badge-info"
          *ngIf="member._id === process.student.supervisor1Id || member._id === process.student.supervisor2Id || member._id === process.student.supervisor3Id">
          {{ 'student-supervisor' | translate }}
        </span>
      </div>
    </div>

    <div class="form-group text-right mt-2">
      <div class="d-block invalid-feedback mb-2"
        *ngIf="f.invalid && f.submitted">
        {{ 'check-errors' | translate }}
      </div>

      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'accept' | translate}}
      </button>
    </div>


  </form>

</ng-container>