<ng-container *ngIf="task">
  <task-header [task]="task"></task-header>

  <br>

  <div class="small text-muted mb-1">
    {{ 'program' | translate }}
  </div>
  <div class="mb-4">
    {{ task.thesisProcess.student.program.name }}
  </div>

  <thesis-data-view [data]="task.thesisProcess.data"
    [student]="task.thesisProcess.student"
    [showThesisCommittee]="false"
    [showAll]="false"></thesis-data-view>

  <form #f="ngForm">

    <div class="alert alert-info">
      <i class="far fa-info mr-2"></i>
      <span [innerHTML]="'evaluation-report-info' | translate"></span>
    </div>

    <div class="form-group">
      <label>{{ 'thesis-evaluation-report' | translate }}</label>
      <file-input [(ngModel)]="docRef"
        required
        name="docRef"
        id="docRef"
        #docRefInput="ngModel"
        [invalid]="f.submitted && docRefInput.invalid"></file-input>
      <validation-errors [control]="docRefInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="evaluationSelect">
        {{ 'evaluation' | translate }}
      </label>
      <ng-select id="evaluationSelect"
        name="evaluationSelect"
        #evaluationSelect="ngModel"
        [(ngModel)]="evaluation"
        required
        [class.is-invalid]="f.submitted && evaluationSelect.invalid"
        [placeholder]="'select-evaluation' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="ThesisReportEvaluation.accept">
          {{ ThesisReportEvaluation.accept | translate }}
        </ng-option>
        <ng-option [value]="ThesisReportEvaluation.acceptMinorChanges">
          {{ ThesisReportEvaluation.acceptMinorChanges | translate }}
        </ng-option>
        <ng-option [value]="ThesisReportEvaluation.acceptMajorChanges">
          {{ ThesisReportEvaluation.acceptMajorChanges | translate }}
        </ng-option>
        <ng-option [value]="ThesisReportEvaluation.reject">
          {{ ThesisReportEvaluation.reject | translate }}
        </ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="evaluationSelect"></validation-errors>
    </div>

    <div class="w-100 text-right">
      <button (click)="send()"
        class="btn btn-primary"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>

    </div>
  </form>
</ng-container>