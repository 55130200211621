import { IUserCDSInfo } from './ICDSProcess';
import { IDocumentRef } from './IDocument';
import { IValidationMsgs, IProcess } from './IProcess';
import { IProgram } from './IProgram';
import { IStudent } from './IStudent';

export interface IDirectionProcess extends IProcess {
  state: DirectionPState,
  studentId: string,
  programId: string,
  tutor?: IUserCDSInfo,
  supervisor1?: IUserCDSInfo,
  supervisor2?: IUserCDSInfo,
  supervisor3?: IUserCDSInfo,
  thirdSupervisorDoc?: IDocumentRef,
  denialMsgs?: IValidationMsgs,
  noConflictAgreement?: boolean,

  // Populate
  student?: IStudent;
  program?: IProgram;
}


export enum DirectionPState {
  PendingStudentRequestReview = "PendingStudentRequestReview",
  PendingAcademicCommitteeValidation = "PendingAcademicCommitteeValidation",
  PendingServiceValidation = "PendingServiceValidation",
  PendingPostgraduateCommitteeValidation = "PendingPostgraduateCommitteeValidation",
  PendingDirectionTeamUserCreation = "PendingDirectionTeamUserCreation",
  PendingSigns = "PendingSigns"
}

export enum TKDirection {
  studentReviewRequest = "studentReviewRequest",
  academicCommitteeValidation = "academicCommitteeValidation",
  serviceValidation = "serviceValidation",
  postgraduateCommitteeValidation = "postgraduateCommitteeValidation",
  directionTeamUserCreation = "directionTeamUserCreation",
  directionTeamSign = "directionTeamSign",
  studentSign = "studentSign",
  postgraduateCommitteePresidentSign = "postgraduateCommitteePresidentSign",
  doctoralSchoolPrincipalSign = "doctoralSchoolPrincipalSign"
}

export interface IDirectionRequestData {
  tutor?: IUserCDSInfo,
  supervisor1?: IUserCDSInfo,
  supervisor2?: IUserCDSInfo,
  supervisor3?: IUserCDSInfo,
  thirdSupervisorDoc?: IDocumentRef,
  denialMsg?: string,
  validate?: boolean,
  noConflictAgreement?: boolean,
}
