<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <thesis-data-view [data]="process.data"
    [student]="process.student"></thesis-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <ng-container *ngIf="validation.validate === true">

      <div class="form-group"
        *ngIf="process.data.compendium && isSupervisor()">
        <label>
          {{ 'compendium-director-report' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="compendiumDirectorReportInput"></validation-errors>
        <files-queue name="compendiumDirectorReport"
          [required]="true"
          #compendiumDirectorReportInput="ngModel"
          [(ngModel)]="process.data.compendiumDirectorReport"></files-queue>
      </div>

      <div class="alert alert-warning">
        <i class="far fa-exclamation-triangle mr-2"></i>
        {{ 'process.thesis.tasks.directionTeamValidation.info1' | translate }}
      </div>

    </ng-container>


    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        <span *ngIf="validation.validate === true">
          {{ 'accept' | translate }}
        </span>
        <span *ngIf="validation.validate === false">
          {{ 'send' | translate }}
        </span>
      </button>
    </div>

  </form>

</ng-container>