import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { CdsReason, ICDSProcess } from '../../../../../../../interfaces/ICDSProcess';
import { ILineOfResearch } from '../../../../../../../interfaces/ILineOfResearch';
import { ITask } from '../../../../../../../interfaces/ITask';
import { CDSHttpService } from '../../../../../data-services/cds-http.service';
import { ProgramHttpService } from '../../../../../data-services/program-http.service';
import { UserService } from '../../../../../user/user.service';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'cds-academic-committee-validation-task',
  templateUrl: 'cds-academic-committee-validation-task.component.html'
})
export class CDSAcademicCommitteeValidationTaskComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  linesOfResearch: ILineOfResearch[] = [];

  process: ICDSProcess;

  denialMsg = '';

  data: {
    lineOfResearchId: string;
    tutorReason: CdsReason[];
    supervisor1Reason: CdsReason[];
    supervisor2Reason?: CdsReason[];
    supervisor3Reason?: CdsReason[];
    needsSupplement: boolean;
    supplement: {
      credits: number;
      document?: IDocumentRef;
      notes?: string;
    }
  } = {
      lineOfResearchId: null,
      tutorReason: null,
      supervisor1Reason: null,
      supervisor2Reason: null,
      supervisor3Reason: null,
      needsSupplement: false,
      supplement: {
        credits: null,
        document: null,
        notes: ''
      }
    };

  enableSupervisor2 = false;
  enableSupervisor3 = false;

  tutorIsSupervisor1 = false;
  tutorIsSupervisor2 = false;
  tutorIsSupervisor3 = false;

  canComplete = true;

  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  constructor(private cds: CDSHttpService, private ps: ProgramHttpService,
    private us: UserService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.canComplete = this.us.canComplete(this.task);
    this.cds.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      if (process.tutor) {
        this.data.tutorReason = process.tutor.reason;
      }
      if (process.supervisor1) {
        this.data.supervisor1Reason = process.supervisor1.reason;
      }
      if (process.supervisor2) {
        this.data.supervisor2Reason = process.supervisor2.reason;
        this.enableSupervisor2 = true;
      }
      if (process.supervisor3) {
        this.data.supervisor3Reason = process.supervisor3.reason;
        this.enableSupervisor3 = true;
      }
      if (process.needsSupplement) {
        this.data.needsSupplement = process.needsSupplement;
      }
      if (process.supplement) {
        this.data.supplement = process.supplement;
      }
      if (process.lineOfResearchId) {
        this.data.lineOfResearchId = process.lineOfResearchId;
      }

      // check if process.tutor.userId is equal to any of the supervisors userId
      if (process.tutor && process.supervisor1 && process.tutor.userId && process.supervisor1.userId && process.tutor.userId === process.supervisor1.userId) {
        this.tutorIsSupervisor1 = true;
      }
      if (process.tutor && process.supervisor2 && process.tutor.userId && process.supervisor2.userId && process.tutor.userId === process.supervisor2.userId) {
        this.tutorIsSupervisor2 = true;
      }
      if (process.tutor && process.supervisor3 && process.tutor.userId && process.supervisor3.userId && process.tutor.userId === process.supervisor3.userId) {
        this.tutorIsSupervisor3 = true;
      }

      this.ps.linesOfResearch(process.student.programId).subscribe((lines) => {
        this.linesOfResearch = lines;
      });

    })
  }

  manageSend() {
    if (this.f.invalid) {
      this.sendEnd.next();
      return;
    }

    if (this.validation.validate) {
      if (this.tutorIsSupervisor1) {
        this.data.supervisor1Reason = [...this.data.tutorReason];
      }
      if (this.tutorIsSupervisor2) {
        this.data.supervisor2Reason = [...this.data.tutorReason];
      }
      if (this.tutorIsSupervisor3) {
        this.data.supervisor3Reason = [...this.data.tutorReason];
      }

      this.cds.academicCommitteeValidation(this.task._id, this.data).subscribe(() => {
        void this.router.navigate(['/app/task-completed']);
        this.sendEnd.next();
      });
    } else {
      this.cds.academicCommitteeDeny(this.task._id, { denialMsg: this.validation.denialMsg }).subscribe(() => {
        void this.router.navigate(['/app/task-completed']);
        this.sendEnd.next();
      });
    }
  }

  onChangeReason(event, member: string) {
    let reason;
    if (event.length > 0) {
      const lastValue = event[event.length - 1];
      if (lastValue && lastValue === CdsReason.none) {
        reason = [CdsReason.none];
      } else {
        reason = event;
      }
    } else {
      reason = [];
    }

    this.data[member] = reason;
  }

}