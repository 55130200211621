<div class="container"
  *ngIf="ready">

  <div class="row">
    <div class="col">

      <section-title [text]="faculty.name"
        icon="users-class">
      </section-title>

    </div>
  </div>

  <div class="row">
    <div class="col">
      <students-search [dataFn]="dataFn()"
        [showFilters]="true"
        [reportURL]="'secretary/' + us.selectedRole.groupId + '/students/report'"
        [program]="true"></students-search>
    </div>
  </div>

</div>