<ng-container *ngIf="process">
  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'student' | translate }}
    </div>
    <div>
      {{ process.student.user.fullName }}
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'program' | translate }}
    </div>
    <div>
      {{ process.student.program.name }}
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'dedication' | translate }}
    </div>
    <div>
      {{ process.dedication | translate }}
    </div>
  </div>

  <div *ngIf="process.dedication === 'PART' && process.partialTimeDoc"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'part-time-justification' | translate }}
    </div>
    <div *ngFor="let doc of process.partialTimeDoc"
      class="mb-1">
      <document-link [document]="doc"></document-link>
    </div>
  </div>

  <div *ngIf="process.tutor"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'tutor' | translate }}
    </div>
    <user-info-view [userInfo]="process.tutor"></user-info-view>
  </div>

  <div *ngIf="process.supervisor1"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.supervisor1"></user-info-view>
  </div>

  <div *ngIf="process.supervisor2"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'second-supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.supervisor2"></user-info-view>
  </div>

  <div *ngIf="process.supervisor3"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'third-supervisor' | translate }}
    </div>
    <user-info-view [userInfo]="process.supervisor3"></user-info-view>
  </div>

  <div *ngIf="process.supervisor3 && process.thirdSupervisorDoc"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'third-supervisor-justification' | translate }}
    </div>
    <div>
      <document-link [document]="process.thirdSupervisorDoc"></document-link>
    </div>
  </div>

  <div *ngIf="process.lineOfResearch"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'line-of-research' | translate }}
    </div>
    <div>
      {{ process.lineOfResearch.name }}
    </div>
  </div>

  <ng-container *ngIf="process.needsSupplement === true && process.supplement">
    <div class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'education-supplement' | translate }}
      </div>
      <div *ngIf="process.supplement.credits">
        <strong>
          {{ process.supplement.credits }}
        </strong>
        {{ 'credits' | translate }}
      </div>
    </div>


    <div *ngIf="process.supplement.document"
      class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'supplement-document' | translate }}
      </div>
      <div>
        <document-link [document]="process.supplement.document"></document-link>
      </div>
    </div>

    <div *ngIf="process.supplement.notes"
      class="mb-4">
      <div class="small text-muted mb-1">
        {{ 'supplement-notes' | translate }}
      </div>
      <div>
        {{ process.supplement.notes }}
      </div>
    </div>

  </ng-container>

</ng-container>