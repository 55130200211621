<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card">
      <div class="card-body"
        *ngIf="sr.student">
        <ng-container *ngIf="!requested">

          <ng-container>
            <h5 class="card-title">
              {{ 'process.dedication.request.name' | translate }}
            </h5>

            <p [innerHTML]="'process.dedication.request.desc' | translate">
            </p>
          </ng-container>

          <form #f="ngForm"
            (ngSubmit)="send()">

            <div class="form-group">
              <label for="dedicacionSelect">
                {{ 'dedication-change-to' | translate }}
              </label>
              <ng-select id="dedicacionSelect"
                name="dedicacionSelect"
                #dedicationSelect="ngModel"
                [(ngModel)]="data.dedication"
                required
                [readonly]="true"
                [class.is-invalid]="f.submitted && dedicationSelect.invalid"
                [placeholder]="'select-dedicacion' | translate"
                [searchable]="false"
                [clearable]="false">
                <ng-option [value]="'FULL'">{{ 'FULL' | translate }}</ng-option>
                <ng-option [value]="'PART'">{{ 'PART' | translate }}</ng-option>
              </ng-select>
              <validation-errors [show]="f.submitted"
                [control]="dedicationSelect"></validation-errors>
            </div>

            <div class="form-group"
              *ngIf="data.dedication === 'PART'">
              <label>
                {{ 'part-time-justification' | translate }}
              </label>
              <validation-errors [show]="f.submitted"
                [control]="partTimeDocInput"></validation-errors>
              <files-queue name="partTimeDocInput"
                #partTimeDocInput="ngModel"
                required
                [(ngModel)]="data.partialTimeDoc"></files-queue>
              <small class="form-text text-muted">
                {{ 'part-time-justification-note' | translate }}
              </small>
            </div>

            <div class="form-group text-right">
              <div class="d-block invalid-feedback mb-2"
                *ngIf="f.invalid && f.submitted">
                {{ 'check-errors' | translate }}
              </div>

              <button class="btn btn-primary"
                type="submit"
                [disableButton]="disabled || sending"
                [disableButtonIcon]="sending ? 'spin' : 'ban'">
                {{ 'send' | translate}}
              </button>
            </div>
          </form>

        </ng-container>
        <div class="w-100 text-center"
          *ngIf="requested">
          <h5 class="card-title m-0">
            <i class="far fa-check text-success mr-1"></i>
            {{ 'request-sended' | translate }}
          </h5>

          <div *ngIf="doc"
            class="mt-4 w-100 text-left">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>