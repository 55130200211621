import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ImpersonatingService } from '../../../data-services/impersonating.service';
import { dropdownAnimation } from '../../../UI/animations/animations';
import { DropdownService } from '../../../UI/dropdown-service/dropdown.service';

@Component({
  selector: 'user-dropdown',
  templateUrl: 'user-dropdown.component.html',
  animations: [dropdownAnimation]
})
export class UserDropdownComponent implements OnInit {
  constructor(public is: ImpersonatingService,
    public dds: DropdownService,
    private http: HttpClient,
    private platformLocation: PlatformLocation,
    private router: Router) { }

  ngOnInit() { }

  logout() {
    if (this.is.impersonating) {
      this.is.stopImpersonate().subscribe(() => {
        let url = (this.platformLocation as any).location.origin;
        location.href = url;
      })
    } else {
      this.http.post<{ url: string }>('/api/login/logout', {}).subscribe(({ url }) => {
        if (url) {
          location.href = url;
        } else {
          void this.router.navigate(['/'])
        }
      });
    }
  }
}