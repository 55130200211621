import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IDocumentRef } from '../../../../../interfaces/IDocument';
import { DocumentService } from '../../../shared/document-service/document.service';

@Component({
  selector: 'download',
  templateUrl: './download.component.html',
})
export class DownloadComponent implements OnInit {

  doc: IDocumentRef;
  documentId: string;
  notFound = false;
  loading = false;

  constructor(private ds: DocumentService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe((params) => {
      this.documentId = params.documentId;
      if (!this.documentId) {
        this.notFound = true;
        this.loading = false;
        return;
      }

      this.ds.getDocument(this.documentId).subscribe((doc) => {
        this.doc = {
          documentRef: doc._id,
          fileName: doc.name
        }
        this.ds.download(this.documentId);
        this.loading = false;
      }, (err) => {
        this.notFound = true;
        this.loading = false;
      })

    })
  }

}
