import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IDischargeProcess } from '../../../../../../../interfaces/IDischargeProcess';
import { LeaveType } from '../../../../../../../interfaces/ILeaveProcess';
import { ILeaves } from '../../../../../../../interfaces/IStudent';
import { UserService } from '../../../../../user/user.service';
import { IRenewalProcess } from '../../../../../../../interfaces/IRenewalProcess';

@Component({
  selector: 'leaves-list',
  templateUrl: 'leaves-list.component.html'
})

export class LeavesListComponent implements OnInit {

  @Input() leaves: ILeaves[];
  @Input() leaveDischargeProcMap: { [leaveId: string]: IDischargeProcess } = {};
  @Input() leaveRenewalProcMap: { [leaveId: string]: IRenewalProcess } = {};

  @Input() editable = false;

  @Output() onRemove = new EventEmitter<string>();
  @Output() onEdit = new EventEmitter<string>();

  LeaveType = LeaveType;

  constructor(public us: UserService) { }

  ngOnInit() { }
}