import { Component, Input, OnInit } from '@angular/core';

import { DedicationType, ILeaves, IStudent, StudentStateType } from '../../../../../../../interfaces/IStudent';
import { UserService } from '../../../../../user/user.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { LeaveType } from '../../../../../../../interfaces/ILeaveProcess';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { ConfirmationDialogService } from '../../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { StudentRootService } from '../../../../routes/student/student-root/student-root.service';

@Component({
  selector: 'state-info',
  templateUrl: 'state-info.component.html'
})
export class StateInfoComponent implements OnInit {
  StudentStateType = StudentStateType;

  @Input() student: IStudent;

  DedicationType = DedicationType;

  openPermLeaveModal = false;
  openFinalLeaveModal = false;

  private _permLeave: ILeaves;
  public get permLeave(): ILeaves {
    this._permLeave = this.student.leaves.find(l => l.type === LeaveType.permanent);
    return this._permLeave;
  }
  public set permLeave(value: ILeaves) {
    this._permLeave = value;
  }

  private _finalLeave: ILeaves;
  public get finalLeave(): ILeaves {
    this._finalLeave = this.student.leaves.find(l => l.type === LeaveType.final);
    return this._finalLeave;
  }
  public set finalLeave(value: ILeaves) {
    this._finalLeave = value;
  }

  constructor(public us: UserService,
    public sr: StudentRootService,
    public sh: ServiceHttpService,
    private alerts: PopAlertsService,
    private confirmation: ConfirmationDialogService,
    private ts: TranslateService,
    private refresh: RefreshService) { }

  ngOnInit() {
  }

  removePermLeave() {
    this.confirmation.confirm({
      title: this.ts.instant('remove-permanent-leave'),
      message: this.ts.instant('remove-permanent-leave-confirmation')
    }, () => {
      this.sh.removePermanentLeave(this.student._id).subscribe(() => {
        this.refresh.onStudentChange.next();
        this.alerts.saveSuccess();
      })
    })
  }

  removeFinalLeave() {
    this.confirmation.confirm({
      title: this.ts.instant('remove-final-leave'),
      message: this.ts.instant('remove-final-leave-confirmation')
    }, () => {
      this.sh.removeFinalLeave(this.student._id).subscribe(() => {
        this.refresh.onStudentChange.next();
        this.alerts.saveSuccess();
      })
    })
  }

  generateThesisDefenceCert() {
    window.location.href = `${window.location.origin}/api/service/thesisDefenceCert/${this.student._id}`
  }

  generateFinalLeaveEvaluations() {
    window.location.href = `${window.location.origin}/api/service/finalLeaveNegativeEvaluationsDoc/${this.student._id}`
  }

  generateFinalLeaveDeadline() {
    window.location.href = `${window.location.origin}/api/service/finalLeaveDeadlineDoc/${this.student._id}`
  }

  generateFinalLeaveEnrollment() {
    window.location.href = `${window.location.origin}/api/service/finalLeaveEnrollmentDoc/${this.student._id}`
  }

  generateFinalLeaveDecision() {
    window.location.href = `${window.location.origin}/api/service/finalLeaveDecisionDoc/${this.student._id}`
  }

}