import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IActivity } from '../../../interfaces/IActivity';
import { IActiveProcesses, IStudent, StudentStateType } from '../../../interfaces/IStudent';
import { IUser } from '../../../interfaces/IUser';

@Injectable()
export class StudentHttpService {


  constructor(private http: HttpClient) {

  }

  fetchMe(programId: string): Observable<IStudent> {
    return this.http.get<IStudent>(`/api/me/student/${programId}`);
  }

  fetchActiveProcesses(studentId: string): Observable<IActiveProcesses> {
    return this.http.get<IActiveProcesses>(`/api/student/${studentId}/activeProcesses`)
  }

  fetchTutorStudents(programId: string, params: { states?: StudentStateType[], searchText?: string, page?: number, size?: number }): Observable<IStudent[]> {
    return this.http.post<IStudent[]>(`/api/tutor/${programId}/students`, { ...params });
  }

  fetchSupervisorStudents(params: { states?: StudentStateType[], searchText?: string, page?: number, size?: number }): Observable<IStudent[]> {
    return this.http.post<IStudent[]>(`/api/supervisor/students`, { ...params });
  }

  fetchStudent(studentId: string): Observable<IStudent> {
    return this.http.get<IStudent>(`/api/student/${studentId}`);
  }

  fetchActivities(params: { studentId: string, searchText?: string, page?: number, size?: number }): Observable<IActivity[]> {
    return this.http.get<IActivity[]>(`/api/student/${params.studentId}/activities`, { params });
  }

  fetchAssessableActivities(studentId: string): Observable<IActivity[]> {
    return this.http.get<IActivity[]>(`/api/student/${studentId}/assessableActivities`, {});
  }

  fetchEvaluationCommittee(studentId: string, evaluationId: string): Observable<{ president: IUser, secretary: IUser, member: IUser }> {
    return this.http.get<{ president: IUser, secretary: IUser, member: IUser }>(`/api/student/${studentId}/evaluationCommittee/${evaluationId}`, {});
  }

}