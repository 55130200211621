import { Component, OnInit } from '@angular/core';

import { StudentsSearchService } from '../../components/students/students-search/students-search.service';

@Component({
  selector: 'academic-committee-menu',
  templateUrl: './academic-committee-menu.component.html'
})
export class AcademicCommitteeMenuComponent implements OnInit {

  constructor(public ss: StudentsSearchService) { }

  ngOnInit() {
  }

}
