<div class="dropdown"
  #dateDropdown>

  <div class="input-group"
    [class.input-group-sm]="sm"
    *ngIf="!inline">
    <input class="form-control"
      [ngModel]="date | moment:getFormat()"
      (ngModelChange)="tryParse($event)"
      [placeholder]="placeholder"
      [class.is-invalid]="isInvalid"
      #dateInput
      name="date">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary"
        type="button"
        (click)="dds.toggle(id, dateDropdown)">
        <i class="far fa-calendar"></i>
      </button>
    </div>
  </div>

  <div class="dropdown-menu dropdown-menu-right"
    [class.show]="inline || dds.show(id)">

    <div class="dp-calendar"
      *ngIf="inline || dds.show(id)">

      <div class="dp-month-selector text-center">
        <a role="button"
          (click)="prev()"
          class="dp-month-btn float-left">
          <i class="far fa-angle-left"></i>
        </a>
        <a role="button"
          class="dp-month-btn"
          *ngIf="view == 'month'"
          (click)="yearView()">
          {{ month.name | capitalizefirst }} {{ month.year }}
        </a>
        <span *ngIf="view == 'year'">
          {{ year.name }}
        </span>
        <a role="button"
          (click)="next();"
          class="dp-month-btn float-right">
          <i class="far fa-angle-right"></i>
        </a>
      </div>

      <table class="dp-calendar-table text-center"
        *ngIf="view == 'month'">

        <thead class="dp-calendar-table-header">
          <tr class="dp-weekdays">
            <th *ngFor="let weekday of weekdays"
              class="dp-weekday"
              scope="col">
              <span>{{weekday}}</span>
            </th>
          </tr>
          <tr>
            <th class="dp-calendar-table-divider"
              colspan="7">
              <hr />
            </th>
          </tr>
        </thead>

        <tbody>

          <tr *ngFor="let week of month.weeks">
            <td class="dp-day-cell"
              [class.dp-today]="isToday(day)"
              [class.dp-selected]="isSelected(day)"
              [class.dp-not-this-month]="!isSameMonth(day)"
              [class.dp-range]="isInside(day)"
              [class.dp-not-selectable]="notSelectable(day)"
              (click)="selectDate(day)"
              (mouseenter)="onMouseenter(day)"
              (mouseleave)="onMouseleave(day)"
              *ngFor="let day of week.days">
              <div>
                {{ day | moment:'D' }}
              </div>
            </td>
          </tr>



        </tbody>

      </table>

      <div class="dp-year-view text-center"
        *ngIf="view == 'year'">

        <div class="dp-year"
          [class.dp-today]="isSameMonthThanToday(month)"
          [class.dp-not-selectable]="notSelectable(month)"
          *ngFor="let month of year.months"
          (click)="selectMonth(month)">
          <div class="dp-year-content">
            {{ month | moment:'MMM'}}
          </div>
        </div>

      </div>

      <div class="dp-time text-center"
        *ngIf="time">
        <hr>
        <input type="number"
          class="form-control"
          name="hours"
          [min]="0"
          [max]="23"
          [(ngModel)]="hours"
          (ngModelChange)="updateHour()"
          style="display: inline-block; width: 65px;">
        <span>:</span>
        <input type="number"
          class="form-control"
          name="minutes"
          [min]="0"
          [max]="59"
          [(ngModel)]="minutes"
          (ngModelChange)="updateHour()"
          style="display: inline-block; width: 65px;">
      </div>

    </div>

  </div>
</div>