<modal [title]="'send-email' | translate"
  [(open)]="open"
  [size]="'lg'">

  <form #f="ngForm"
    id="sendEmailForm"
    (ngSubmit)="send()"
    *ngIf="open">

    <div class="alert alert-info mb-3">
      <i class="far fa-info-circle mr-1"></i>
      <span [innerHTML]="'send-email-info' | translate"></span>
    </div>

    <div class="form-group">
      <label for="to-name">
        {{ 'to-name' | translate }}
        <small class="text-muted ml-1">
          {{ 'optional' | translate }}
        </small>
      </label>
      <input type="text"
        class="form-control"
        id="to-name"
        name="to-name"
        #toNameInput="ngModel"
        [(ngModel)]="toName"
        [placeholder]="'to-name' | translate"
        [required]="false">
      <validation-errors [control]="toNameInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="to-email">{{ 'to-email' | translate }}</label>
      <input type="email"
        class="form-control"
        id="to-email"
        name="to-email"
        #toEmailInput="ngModel"
        [(ngModel)]="to"
        [placeholder]="'Dirección de correo electrónico'"
        required>
      <validation-errors [control]="toEmailInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="subject">{{ 'subject' | translate }}</label>
      <input type="text"
        class="form-control"
        id="subject"
        name="subject"
        #subjectInput="ngModel"
        [(ngModel)]="subject"
        [placeholder]="'Asunto del mensaje'"
        required>
      <validation-errors [control]="subjectInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="body">{{ 'body' | translate }}</label>
      <quill-editor id="body"
        #htmlInput="ngModel"
        name="html"
        [(ngModel)]="html"
        [placeholder]="'Escriba el cuerpo del mensaje aquí...'"
        [modules]="modules"></quill-editor>
    </div>
  </form>

  <div footer>
    <button type="button"
      class="btn btn-outline-dark mr-1"
      (click)="open = false">
      {{ 'cancel' | translate }}
    </button>

    <button type="submit"
      form="sendEmailForm"
      class="btn btn-primary">
      {{ 'send' | translate }}
    </button>
  </div>
</modal>