import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'denial-msgs-view',
  templateUrl: './denial-msgs-view.component.html',
})
export class DenialMsgsViewComponent implements OnInit, OnChanges {

  @Input() denialMsgs: { [role: string]: string } = {};

  msgs: { role: string, msg: string }[] = []

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.msgs = [];
    if (!this.denialMsgs) return;
    Object.keys(this.denialMsgs).forEach(role => {
      this.msgs.push({
        role,
        msg: this.denialMsgs[role]
      });
    })
  }

}
