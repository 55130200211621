import { Component, forwardRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { DocumentService } from '../../shared/document-service/document.service';
import { PopAlertsService } from '../pop-alerts/pop-alerts.service';

@Component({
  selector: 'files-queue',
  templateUrl: 'files-queue.component.html',
  styles: [`
    .wrapper {
      position: relative;
      overflow: hidden;
      display: none;
    }

    .wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }

  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilesQueueComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FilesQueueComponent),
      multi: true
    }
  ]
})
export class FilesQueueComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  @Input() required = false;

  files: {
    id: number,
    data: any,
    state: string,
    progress: number,
    document: {
      documentRef: string,
      fileName: string
    },
    sub: Subscription,
  }[] = [];

  completed: {
    documentRef: string,
    fileName: string
  }[] = [];

  index = -1;

  constructor(private ds: DocumentService, private alerts: PopAlertsService, private translate: TranslateService) { }

  ngOnInit() { }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    for (const file of this.files) {
      if (file.sub) file.sub.unsubscribe();
      if (file.state === 'UPLOADING') {
        this.remove(file.id);
      }
    }
  }

  startNext() {
    const current = this.files.find(f => f.state === 'UPLOADING');
    if (current) return;

    const next = this.files.find(f => f.state === 'WAITING');
    if (!next) return;
    if (next.data.size > 512 * 1024 * 2014) {
      this.alerts.error(this.translate.instant('maxFileSizeExceeded'));
      return;
    }

    next.state = 'UPLOADING';
    const formData: FormData = new FormData();
    formData.append('file', next.data, next.data.name);
    this.onChange(this.completed);
    next.sub = this.ds.upload(formData).subscribe(event => {
      switch (event.status) {
        case 'none':
          break;
        case 'uploading':
          next.progress = event.progress;
          break;
        case 'uploaded':
          next.state = "COMPLETED";
          next.document = {
            documentRef: event.res.documentId,
            fileName: event.res.name
          }

          this.completed.push(next.document);
          this.onChange(this.completed);

          this.startNext();
          break;
        default: {
          this.remove(next.id);
          this.alerts.error(this.translate.instant('unexpedtedError'));
        }
      }
    });
  }

  remove(id: number) {
    const find = this.files.find(f => f.id === id);
    if (!find) return;
    if (find.state === 'UPLOADING') {
      // abort
      if (find.sub) find.sub.unsubscribe();
      find.state = 'ABORTED';
    }
    this.files = this.files.filter(f => f.id !== find.id);
    if (find.document) {
      this.completed = this.completed.filter(d => d.documentRef !== find.document.documentRef);
    }
    this.onChange(this.completed);
  }

  push(event) {
    let fileList: FileList;
    if (event.target)
      fileList = event.target.files;
    else
      fileList = event;
    for (let i = 0; i < fileList.length; i++) {
      const f = fileList.item(i);
      this.index++;
      this.files.push({
        id: this.index,
        data: f,
        progress: 0,
        state: 'WAITING',
        document: null,
        sub: null
      });
    }
    this.onChange(this.completed);
    this.startNext();
  }

  onChange = (_: any) => { };
  onTouch = () => { };

  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      this.completed = value;
      if (this.completed && this.completed.length > 0) {
        for (let c of this.completed) {
          this.index++;
          this.files.push({
            id: this.index,
            data: { name: c.fileName },
            progress: 100,
            state: 'COMPLETED',
            document: c,
            sub: null
          })
        }
      } else {
        this.index = -1;
        this.files = [];
      }
      // this.onChange(this.completed);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.required && this.files.length <= 0) {
      return { required: true };
    }
    for (const file of this.files) {
      if (file.state === 'UPLOADING' || file.state === 'WAITING') {
        return { uploading: true };
      }
    }
    return null;
  }

}