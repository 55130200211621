<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card">
      <div class="card-body"
        *ngIf="sr.student">
        <ng-container *ngIf="!requested">

          <h5 class="card-title">
            {{ 'process.extension.request.name' | translate }}
          </h5>

          <p [innerHTML]="'process.extension.request.desc' | translate">
          </p>

          <div class="mt-2 mb-3"
            *ngIf="sr.student.extensions.length > 0">
            <div class="small text-muted mb-1 mt-2">{{ 'previously-granted-extensions' | translate }}</div>
            <extensions-list [extensions]="sr.student.extensions"
              [showOrder]="sr.student.rd === RDType.rd99"></extensions-list>
          </div>

          <form #f="ngForm"
            (ngSubmit)="send()">
            <div class="form-group">
              <label>
                {{ 'justification' | translate }}
              </label>
              <validation-errors [show]="f.submitted"
                [control]="justificationInput"></validation-errors>
              <files-queue name="justification"
                #justificationInput="ngModel"
                [(ngModel)]="justification"></files-queue>
              <small class="form-text text-muted">
                {{ 'extension-justification-note' | translate }}
              </small>
            </div>

            <div class="form-group text-right">
              <div class="d-block invalid-feedback mb-2"
                *ngIf="f.invalid && f.submitted">
                {{ 'check-errors' | translate }}
              </div>

              <button class="btn btn-primary"
                type="submit"
                [disableButton]="disabled || sending"
                [disableButtonIcon]="sending ? 'spin' : 'ban'">
                {{ 'send' | translate}}
              </button>
            </div>
          </form>
        </ng-container>
        <div class="w-100 text-center"
          *ngIf="requested">
          <h5 class="card-title m-0">
            <i class="far fa-check text-success mr-1"></i>
            {{ 'request-sended' | translate }}
          </h5>

          <div *ngIf="doc"
            class="mt-4 w-100 text-left">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>