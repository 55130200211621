<form #f="ngForm"
  (ngSubmit)="send()">


  <div class="alert alert-info"
    *ngIf="step === 1"
    [innerHTML]="(student.rd === RDType.rd99 ? 'request-evaluation-step-1' : 'request-evaluation-step-1-bis') | translate">
  </div>

  <div class="form-group"
    *ngIf="step === 1">
    <label>
      <span *ngIf="student.rd === RDType.rd99Mod">
        {{ 'research-and-formation-plan' | translate }}
      </span>
      <span *ngIf="student.rd === RDType.rd99">
        {{ 'research-plan' | translate }}
      </span>
    </label>
    <file-input name="researchPlan"
      required
      #researchPlanInput="ngModel"
      [(ngModel)]="researchPlan"></file-input>
    <validation-errors [show]="f.submitted || clickedNext"
      [control]="researchPlanInput"></validation-errors>
  </div>
  <ng-container *ngIf="step===2">
    <div class="alert alert-info mt-4"
      [innerHTML]="'request-evaluation-step-2' | translate">
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <label>
        {{ 'select-activities-to-evaluate' | translate }}
      </label>
      <a role="button"
        class="small text-right"
        (click)="toggleSelectAll()"
        *ngIf="activities.length > 0"
        [routerLink]="null">
        <span *ngIf="activitiesIds.length < activities.length">
          {{ 'select-all' | translate }}
        </span>
        <span *ngIf="activitiesIds.length == activities.length">
          {{ 'deselect-all' | translate }}
        </span>
      </a>
    </div>
    <div style="width: 100%; max-height: 600px; overflow-y: auto"
      class="border rounded">
      <div class="list-group">
        <div class="list-group-item"
          *ngIf="activities.length <= 0"
          [innerHTML]="'no-assessable-activites' | translate">
        </div>
        <div class="list-group-item d-flex justify-content-start"
          *ngFor="let activity of activities">
          <div class="c-pointer"
            (click)="toggle(activity._id)">
            <span *ngIf="!activitiesIds.includes(activity._id)">
              <i class="far fa-square text-muted mr-2"></i>
            </span>
            <span *ngIf="activitiesIds.includes(activity._id)">
              <i class="fas fa-check-square text-primary mr-2"></i>
            </span>
          </div>
          <div class="flex-fill">
            <activity-view [activity]="activity"
              [clickableType]="true"
              (onTypeClick)="toggle(activity._id)"></activity-view>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="f.submitted && activitiesIds.length <= 0"
      class="invalid-feedback d-block mb-2">
      {{ 'must-select-at-least-one-activity' | translate }}
    </div>

  </ng-container>

  <div class="form-group text-right mt-2">
    <div class="d-block invalid-feedback mb-2"
      *ngIf="f.invalid && f.submitted">
      {{ 'check-errors' | translate }}
    </div>

    <button *ngIf="step === 2"
      class="btn btn-outline-primary mr-2"
      type="button"
      [disableButton]="disabled"
      (click)="step = 1">
      {{ 'back' | translate}}
    </button>
    <button *ngIf="step === 2"
      class="btn btn-primary"
      type="submit"
      [disableButton]="disabled || sending"
      [disableButtonIcon]="sending ? 'spin' : 'ban'">
      {{ 'send' | translate}}
    </button>
    <button *ngIf="step === 1"
      class="btn btn-outline-primary"
      type="button"
      (click)="clickNext()"
      [disableButton]="disabled">
      {{ 'next' | translate }}
    </button>
  </div>

</form>