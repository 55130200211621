import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProfessor } from '../../../interfaces/IProfessor';

@Injectable()
export class ProfessorHttpService {
  constructor(private http: HttpClient) { }

  fetchProfessors(programId: string, params: { searchText?: string, page?: number, size?: number }): Observable<IProfessor[]> {
    return this.http.post<IProfessor[]>(`/api/professors/find/${programId}`, params);
  }

  updateProfessor(programId: string, professorId: string, professor: IProfessor): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/${professorId}/update`, { professor });
  }

  addProfessor(programId: string, professor: IProfessor): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/add/${programId}`, { professor });
  }

  markAsConfirmed(professorId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/${professorId}/markAsConfirmed`, {});
  }

  markAsNotConfirmed(professorId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/${professorId}/markAsNotConfirmed`, {});
  }

  deleteProfessor(professorId: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(`/api/professors/${professorId}/delete`);
  }

  setProfessorsUsers(): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/professors/setProfessorsUsers`, {});
  }

}