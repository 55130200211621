<div class="row">
  <div class="col text-right mb-3">
    <button class="btn btn-outline-info"
      type="button"
      (click)="add()">
      {{ 'new-committee' | translate }}
    </button>
  </div>
</div>

<div class="row">
  <ng-container *ngFor="let com of showCommittees; trackBy:hs.trackById">
    <div class="col-12 mb-4 mt-4"
      *ngIf="!com.deleted">
      <div [class.added]="com.added">
        <h5 class="d-flex justify-content-between align-items-center mb-2">
          <span *ngIf="!editCommitteesMap[com._id]">
            {{ hs.ecCode(com) }}
            <span class="ml-1"
              (click)="editCommittee(com)">
              <i class="far fa-edit"></i>
            </span>
          </span>
          <span *ngIf="editCommitteesMap[com._id]">
            <form class="form-inline">
              CE -
              <input type="text"
                class="form-control form-control-sm d-inline-block mr-1"
                [(ngModel)]="clonedCommitteesMap[com._id].year"
                [name]="com._id + 'year'">
              - {{ com.program.code }}
              <input type="number"
                class="form-control form-control-sm d-inline-block mr-1"
                [(ngModel)]="clonedCommitteesMap[com._id].number"
                [name]="com._id + 'number'">
              <button class="btn btn-link text-success d-inline-block mr-1"
                (click)="saveCommittee(clonedCommitteesMap[com._id])">
                <i class="far fa-check"></i>
              </button>
              <button class="btn btn-link text-dark d-inline-block mr-1"
                (click)="editCommitteesMap[com._id] = false">
                <i class="far fa-times"></i>
              </button>
            </form>
          </span>
          <a [routerLink]="null"
            role="button"
            class="text-muted"
            [tooltip]="'remove-evaluation-committee' | translate"
            (click)="remove(com)">
            <i class="far fa-trash"></i>
          </a>
        </h5>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 pr-md-0">
          <div class="card">
            <div class="card-header">
              <strong>
                {{ 'members' | translate }}
              </strong>
            </div>
            <div class="list-group">
              <div class="list-group-item d-flex justify-content-between align-items-center"
                [class.added]="member.added"
                *ngFor="let member of com.members; trackBy:hs.trackById">
                <div>
                  {{ member | fullname }}
                </div>
                <div>
                  <a [routerLink]="null"
                    role="button"
                    class="text-muted"
                    [tooltip]="'remove-member-from-evaluation-committee' | translate"
                    (click)="removeMember(com, member)">
                    <i class="far fa-times"></i>
                  </a>
                </div>
              </div>
              <div class="list-group-item"
                *ngIf="com.members.length <= 0">
                <i>
                  {{ 'empty-evaluation-committee' | translate }}
                </i>
              </div>
              <div class="list-group-item">
                <h6>
                  <u>
                    {{ 'add-member' | translate }}
                  </u>
                </h6>
                <user-selector [sm]="true"
                  [(ngModel)]="addMembersMap[com._id]"
                  [name]="com._id"></user-selector>
                <div class="text-right">
                  <button [routerLink]="null"
                    role="button"
                    class="btn btn-sm"
                    [class.btn-outline-primary]="!addMembersMap[com._id]"
                    [class.btn-primary]="addMembersMap[com._id]"
                    (click)="addMember(com, addMembersMap[com._id])"
                    [disabled]="!addMembersMap[com._id]">
                    <i class="far fa-plus mr-1"></i>
                    {{ 'add' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 pl-md-0">
          <div class="card">
            <div class="card-header">
              <strong>
                {{ 'students' | translate }}
              </strong>
            </div>

            <div class="list-group">
              <cdk-virtual-scroll-viewport #scrollViewport
                [style.height]="Math.min(500, (studentsMap[com._id] ? studentsMap[com._id].length : 0) * 50) + 'px'"
                itemSize="50">
                <div class="list-group-item d-flex justify-content-between align-items-center"
                  *cdkVirtualFor="let st of studentsMap[com._id]; trackBy:hs.trackById">
                  <span>
                    {{ st | fullname }}
                  </span>
                  <span class="nowrap">
                    <student-state [state]="st.state"></student-state>
                    <student-state *ngFor="let state of st.altStates"
                      [state]="state"
                      [ml]="true"></student-state>

                    <a [routerLink]="null"
                      role="button"
                      class="text-muted d-inline ml-3"
                      [tooltip]="'remove-student-from-evaluation-committee' | translate"
                      (click)="unassignStudent(st, com)">
                      <i class="far fa-times"></i>
                    </a>
                  </span>
                </div>
              </cdk-virtual-scroll-viewport>
              <div class="list-group-item"
                *ngIf="!studentsMap[com._id] || studentsMap[com._id].length <= 0">
                <i>{{ 'no-students-assigned-to-ec' | translate }}</i>
              </div>
              <div class="list-group-item">
                <h6>
                  <u>
                    {{ 'add-student' | translate }}
                  </u>
                </h6>
                <input type="text"
                  class="form-control form-control-sm"
                  [placeholder]="'search-student-to-add-by-name' | translate"
                  [(ngModel)]="searchStudentMap[com._id]"
                  (keyup)="search(com)"
                  [name]="com._id + 'search'">
                <div class="list-group depth2"
                  style="position: absolute; z-index: 1;"
                  *ngIf="searchResultsMap[com._id] && searchStudentMap[com._id].length > 0">
                  <div class="list-group-item d-flex justify-content-between align-items-center"
                    *ngFor="let st of searchResultsMap[com._id]; trackBy:hs.trackById">
                    <span>
                      {{ st | fullname }}
                      <span *ngIf="st.evaluationCommitteeId">
                        ({{ hs.ecCode(committeesMap[st.evaluationCommitteeId]) }})
                      </span>
                    </span>
                    <span class="nowrap ml-2">
                      <student-state [state]="st.state"></student-state>
                      <student-state *ngFor="let state of st.altStates"
                        [state]="state"
                        [ml]="true"></student-state>

                      <a [routerLink]="null"
                        role="button"
                        class="text-info d-inline ml-3"
                        [tooltip]="'add-student-to-evaluation-committee' | translate"
                        (click)="assignStudent(st, com)">
                        <i class="far fa-plus"></i>
                      </a>
                    </span>
                  </div>
                  <div class="list-group-item"
                    *ngIf="searchResultsMap[com._id].length <=0">
                    <i>
                      {{ 'no-results' | translate }}
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </ng-container>
</div>
<div class="row"
  *ngIf="committees && showCommittees && committees.length > showCommittees.length && pages.length > 1">
  <div class="col">
    <nav>
      <ul class="pagination justify-content-center">
        <li class="page-item"
          [class.disabled]="page <= 0"><a class="page-link"
            role="button"
            (click)="prev()">{{ 'previous' | translate}}</a></li>
        <li class="page-item"
          [class.active]="p === page + 1"
          *ngFor="let p of pages"><a class="page-link"
            role="button"
            (click)="setPage(p - 1)">{{p}}</a></li>
        <li class="page-item"
          [class.disabled]="page >= pages.length - 1"><a class="page-link"
            role="button"
            (click)="next()">{{ 'next' | translate }}</a></li>
      </ul>
    </nav>
  </div>
</div>