import { Injectable } from "@angular/core";
import { UserService } from "../../../user/user.service";
import { Observable } from "rxjs";
import { StudentStateType, IStudent } from "../../../../../interfaces/IStudent";
import { HttpClient } from "@angular/common/http";
import { IFaculty } from "../../../../../interfaces/IFaculty";

@Injectable()
export class SecretaryService {

  constructor(private http: HttpClient) { }

  fetchFaculty(facultyId): Observable<IFaculty> {
    return this.http.get<IFaculty>(`/api/secretary/${facultyId}`);
  }

  fetchFacultyStudents(facultyId: string, params: { states?: StudentStateType[], searchText?: string, page?: number, size?: number }): Observable<IStudent[]> {
    return this.http.post<IStudent[]>(`/api/secretary/${facultyId}/students`, { ...params });
  }

}