import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { IIndustrialProcess } from '../../../../../../../interfaces/IIndustrialProcess';
import { NgForm } from '@angular/forms';
import { ITask } from '../../../../../../../interfaces/ITask';
import { IndustrialMentionHttpService } from '../../../../../data-services/industrial-mention-http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'industrial-sign',
  templateUrl: './industrial-sign.component.html',
})
export class IndustrialSignComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  process: IIndustrialProcess;

  constructor(private industrial: IndustrialMentionHttpService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.industrial.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    if (!this.validation.validate) {
      this.industrial.signDeny(this.task._id, this.validation.denialMsg).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.industrial.sign(this.task._id).subscribe((res) => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
