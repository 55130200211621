import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evaluation-committee-menu',
  templateUrl: './evaluation-committee-menu.component.html'
})
export class EvaluationCommitteeMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
