import { IDocumentRef } from "./IDocument";
import { IProcess } from "./IProcess";
import { IStudent } from "./IStudent";

export interface IPermanentLeaveProcess extends IProcess {
  state: PermanentLeavePState,
  studentId: string,

  reason: PermanentLeaveReason,
  justification: IDocumentRef[],

  // Populate
  student?: IStudent;
}

export enum PermanentLeavePState {
  PendingCoordinatorReport = "PendingCoordinatorReport",
}

export enum TKPermanentLeave {
  coordinatorReport = "coordinatorReport",
}

export enum PermanentLeaveReason {
  twoNegativeEvaluations = "TWO_NEGATIVE_EVALUATIONS",
}