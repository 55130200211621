import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import * as moment from 'moment-timezone';

import { StringUtils } from '../../../../utils/StringUtils';
import { DropdownService } from '../dropdown-service/dropdown.service';


@Component({
  selector: 'date-picker',
  templateUrl: 'date-picker.component.html',
  styleUrls: ['date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('dateInput') dateInput: ElementRef;

  @Input() inline: boolean = false;
  @Input() future: boolean = false;
  @Input() time: boolean = false;
  @Input() closeOnSelect: boolean = true;
  @Input() placeholder: string = '';
  @Input() sm = false;

  hours: number;
  minutes: number;

  @Input() isInvalid = false;

  // Selected date
  @Input() date: Date;
  @Input() range: {
    start: Date,
    end: Date
  } = {
      start: undefined,
      end: undefined
    }
  @Input() mode: string = "single";

  @Output() onSelect = new EventEmitter<any>();

  show: boolean = false;

  view: string = "month";

  hoveredEnd: Date;

  // current viewing date
  viewDate: Date = new Date();

  month: {
    name: string,
    year: string,
    weeks: {
      days: Date[];
    }[];
  };

  year: {
    name: string,
    months: Date[]
  }

  weekdays = ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'];

  id: string;

  constructor(public dds: DropdownService) { }

  ngOnInit() {
    this.weekdays = moment.weekdaysShort(true);
    if (!this.id) {
      this.id = StringUtils.guidGenerator();
    }
    if (!this.placeholder) {
      this.placeholder = this.getFormat();
    }
    if (this.date) {
      this.viewDate = new Date(this.date);
    } else if (this.range && this.range.start) {
      this.viewDate = new Date(this.range.start);
    } else {
      this.viewDate = new Date();
    }

    if (this.mode == "range" && (!this.range || this.range == null)) {
      this.range = { start: undefined, end: undefined };
    }
    this.computeMonth();

    if (this.time) {
      let m = moment(this.date);
      this.hours = m.hours();
      this.minutes = m.minutes();
    }
  }

  writeValue(value: any) {
    if (value === null) {
      this.date = null;
    } else if (value !== undefined && value instanceof Date) {
      this.date = value;
      if (this.time) {
        let m = moment(this.date);
        this.hours = m.hours();
        this.minutes = m.minutes();
      }
    } else if (value !== undefined && !value.start) {
      this.date = new Date(<string>value);
    } else if (value !== undefined) {
      this.range = value;
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }

  private computeMonth() {
    let weeks: {
      days: Date[]
    }[] = [];

    let day = moment(this.viewDate).startOf('month').startOf('week');
    let endOfMonth = moment(this.viewDate).endOf('month').endOf('week');
    while (day.isSameOrBefore(endOfMonth)) {
      let week: Date[] = [];
      let endOfWeek = moment(day).endOf('week');
      while (day.isSameOrBefore(endOfWeek)) {
        week.push(day.toDate());
        day = day.add(1, 'day');
      }
      weeks.push({
        days: week
      });
    }

    this.month = {
      name: moment(this.viewDate).format('MMMM'),
      year: moment(this.viewDate).format('YYYY'),
      weeks: weeks
    }
  }

  next() {
    if (this.view == "month") {
      this.viewDate = moment(this.viewDate).add(1, 'month').toDate();
      this.computeMonth();
    } else if (this.view == "year") {
      this.viewDate = moment(this.viewDate).add(1, 'year').toDate();
      this.yearView();
    }
  }

  prev() {
    if (this.view == "month") {
      this.viewDate = moment(this.viewDate).subtract(1, 'month').toDate();
      this.computeMonth();
    } else if (this.view == "year") {
      this.viewDate = moment(this.viewDate).subtract(1, 'year').toDate();
      this.yearView();
    }
  }

  selectDate(day: Date) {
    if (this.notSelectable(day)) {
      return;
    }

    if (this.mode == "single") {
      let m = moment(day);
      if (this.time) {
        m.hours(this.hours);
        m.minutes(this.minutes);
      }
      this.date = m.toDate();
      this.propagateChange(this.date);
      this.onSelect.emit(this.date);
    } else if (this.mode == "range") {
      if (!this.range.start) {
        this.range.start = moment(day).startOf('day').toDate();
      } else if (!this.range.end && moment(this.range.start).isBefore(moment(day).endOf('day'))) {
        this.range.end = moment(day).endOf('day').toDate();
      } else {
        this.range.start = moment(day).startOf('day').toDate();
        this.range.end = undefined;
      }

      this.hoveredEnd = undefined;
      this.propagateChange(this.range);
      this.onSelect.emit(this.range);
    }

    if (this.closeOnSelect) this.show = false;
  }

  onMouseenter(day: Date) {
    if (this.mode == "range" && this.range.start && !this.range.end && moment(day).isAfter(this.range.start)) {
      this.hoveredEnd = new Date(day);
    }
  }

  onMouseleave(day: Date) {
    if (this.mode == "range" && this.range.start && !this.range.end && moment(day).isAfter(this.range.start)) {
      this.hoveredEnd = undefined;
    }
  }

  isToday(day: Date): boolean {
    return moment().isSame(day, 'day');
  }

  isSelected(day: Date): boolean {
    if (this.mode == "single" && this.date) {
      return moment(this.date).isSame(day, 'day');
    } else if (this.mode == "range" && this.range) {
      return (this.range.start && moment(this.range.start).isSame(day, 'day'))
        || (this.range.end && moment(this.range.end).isSame(day, 'day'));
    }

    return false;
  }

  isInside(day: Date): boolean {
    if (this.mode == "range" && this.range && this.range.start && this.range.end) {
      return moment(day).isBetween(this.range.start, this.range.end, 'day', '()');
    }

    if (this.mode == "range" && this.range && this.range.start && this.hoveredEnd) {
      return moment(day).isBetween(this.range.start, this.hoveredEnd, 'day', '()');
    }

    return false;
  }

  isSameMonth(day: Date): boolean {
    return moment(this.viewDate).isSame(day, 'month');
  }

  // Year VIEW
  yearView() {
    this.view = "year";
    let months: Date[] = [];
    let month = moment(this.viewDate).startOf('year');
    let lastMonth = moment(this.viewDate).endOf('year').startOf('month');

    while (month.isBefore(lastMonth)) {
      months.push(month.toDate());
      month = month.add(1, 'month');
    }
    months.push(month.toDate());

    this.year = {
      name: moment(this.viewDate).year().toString(),
      months: months
    }
  }

  selectMonth(month: Date) {
    if (this.notSelectable(month)) {
      return;
    }
    this.viewDate = month;
    this.view = "month";
    this.computeMonth();
  }

  isSameMonthThanToday(month: Date) {
    return moment().isSame(month, 'month');
  }

  notSelectable(date: Date) {
    if (!this.future)
      return false;

    if (this.view == "year" && moment(date).isBefore(new Date(), 'month')) {
      return true;
    }
    if (this.view == "month" && moment(date).isBefore(new Date(), 'day')) {
      return true;
    }
  }

  updateHour() {
    if (this.hours < 0) {
      this.hours = 0;
    }

    if (this.minutes < 0) {
      this.minutes = 0;
    }

    if (this.hours > 23) {
      this.hours = 23;
    }

    if (this.minutes > 59) {
      this.minutes = 59;
    }

    let m = moment(this.date);
    m.hours(this.hours);
    m.minutes(this.minutes);
    this.date = m.toDate();

    this.propagateChange(this.date);
  }

  tryParse(event) {
    if (!this.time && event.length === 10 && moment(event, 'DD/MM/YYYY').isValid()) {
      this.date = moment(event, 'DD/MM/YYYY').toDate();
      this.selectMonth(this.date);
      this.selectDate(this.date);
    } else if (this.time && event.length === 16 && moment(event, 'DD/MM/YYYY HH:mm').isValid()) {
      this.date = moment(event, 'DD/MM/YYYY HH:mm').toDate();
      this.hours = moment(event, 'DD/MM/YYYY HH:mm').hours();
      this.minutes = moment(event, 'DD/MM/YYYY HH:mm').minutes();
      this.selectMonth(this.date);
      this.selectDate(this.date);
    } else {
      this.date = null;
      this.propagateChange(this.date);
    }
  }

  getFormat() {
    if (!this.time) {
      return 'DD/MM/YYYY'
    } else {
      return 'DD/MM/YYYY HH:mm'
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.date === null && this.dateInput && this.dateInput.nativeElement && this.dateInput.nativeElement.value && this.dateInput.nativeElement.value.length > 0) {
      return { dateFormat: true };
    }
    return null;
  }


}