import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IStudent } from '../../../../../../interfaces/IStudent';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'coordinator-students',
  templateUrl: './coordinator-students.component.html'
})
export class CoordinatorStudentsComponent implements OnInit {
  students: IStudent[] = [];

  constructor(public ps: ProgramHttpService,
    public us: UserService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

  dataFn() {
    return (params) => {
      return this.ps.fetchProgramStudents(this.us.selectedRole.groupId, params);
    }
  }

  goToStudent(studentId) {
    void this.router.navigate([`${studentId}`], { relativeTo: this.route });
  }

  downloadReport() {
    window.location.href = `${window.location.origin}/api/program/studentsReport/${this.us.selectedRole.groupId}?format=excel`
  }

}
