import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IEmail } from '../../../../../interfaces/IUser';
import { UserHttpService } from '../../../data-services/user-http.service';
import { HelperService } from '../../../shared/helper-service/helper.service';
import { CanComponentDeactivate } from '../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { ConfirmationDialogService } from '../../../UI/confirmation-dialog/confirmation-dialog.service';
import { PopAlertsService } from '../../../UI/pop-alerts/pop-alerts.service';
import { UserService } from '../../../user/user.service';

@Component({
  selector: 'preferences',
  templateUrl: './preferences.component.html',
})
export class PreferencesComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('f') f: NgForm;

  emailNotifications: boolean;
  favEmail: IEmail;

  constructor(public us: UserService,
    public hs: HelperService,
    private uhs: UserHttpService,
    private alerts: PopAlertsService,
    private ts: TranslateService,
    private confirmation: ConfirmationDialogService) { }

  ngOnInit() {
    this.favEmail = this.us.user.favEmail;
    this.emailNotifications = this.us.user.emailNotifications;
  }

  editFavEmail() {
    this.favEmail = {
      address: this.us.user.email.address,
      validated: true
    }
  }

  defaultEmail() {
    this.favEmail = null;
  }

  save() {
    if (!this.f.valid) return;

    this.uhs.savePreferences({
      emailNotifications: true,
      favEmailAddress: this.favEmail ? this.favEmail.address : null
    }).subscribe(() => {
      this.alerts.saveSuccess();
    })
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (!this.f.touched) return true;
    return confirm(this.ts.instant('unsaved-changes'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.f.touched) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.confirmation.confirm({
        title: this.ts.instant('unsaved-changes-title'),
        message: this.ts.instant('unsaved-changes'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

}
