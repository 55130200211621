import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ITask } from '../../../../../interfaces/ITask';
import { HelperService } from '../../../shared/helper-service/helper.service';

@Component({
  selector: 'tasks-list',
  templateUrl: 'tasks-list.component.html'
})
export class TasksListComponent implements OnInit {
  @Input() tasks: ITask[] = [];

  @Output() onClick = new EventEmitter<string>();

  constructor(public hs: HelperService) { }

  ngOnInit() { }

}