import { IDocumentRef } from "./IDocument";
import { ILineOfResearch } from "./ILineOfResearch";
import { IProcess, IValidationMsgs } from "./IProcess";
import { IProgram } from "./IProgram";

export interface IProfessorProcess extends IProcess {
  programId: string,
  external: boolean,
  idNum: string,
  firstName: string,
  lastName: string,
  email: string,
  category: string,
  lineOfResearchId: string,
  curriculumLink: string,
  reason: ProfessorReason,
  sixyearDoc?: IDocumentRef,
  thesisCount: number,
  orcid: string,
  otherProfile?: string,
  university: string,
  otherProgram: boolean,
  otherProgramName?: string,
  otherProgramUniversity?: string,

  state: PPState,
  denialMsgs?: IValidationMsgs,

  // POPULATE
  lineOfResearch?: ILineOfResearch,
  program?: IProgram
}

export interface IProfessorProcessData {
  external: boolean,
  idNum: string,
  firstName: string,
  lastName: string,
  email: string,
  category: string,
  lineOfResearchId: string,
  curriculumLink: string,
  reason: ProfessorReason,
  sixyearDoc?: IDocumentRef,
  thesisCount: number,
  orcid: string,
  otherProfile?: string,
  university: string,
  otherProgram: boolean,
  otherProgramName?: string,
  otherProgramUniversity?: string,
}

export enum ProfessorReason {
  sixyearPeriod = "sixyearPeriod",
  other = "other",
}

export enum PPState {
  PendingServiceValidation = "PendingServiceValidation",
  PendingPostgraduateCommitteeValidation = "PendingPostgraduateCommitteeValidation",
  PendingCoordinatorReview = "PendingCoordinatorReview",
}

export enum TKProfessor {
  serviceValidation = "serviceValidation",
  coordinatorReview = "coordinatorReview",
  postgraduateCommitteeValidation = "postgraduateCommitteeValidation"
}