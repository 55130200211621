import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';

import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { LeaveType } from '../../../../../../interfaces/ILeaveProcess';
import { LeaveHttpService } from '../../../../data-services/leave-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { StudentRootService } from '../student-root/student-root.service';
import { DADUtils } from '../../../../../../utils/DADUtils';

@Component({
  selector: 'leave-request',
  templateUrl: './leave-request.component.html'
})
export class LeaveRequestComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('f') f: NgForm;

  requested = false;
  justification: IDocumentRef[] = [];
  type: LeaveType = null;
  start: Date;
  end: Date;
  estimatedEnd: Date;

  doc: IDocumentRef;

  LeaveType = LeaveType;
  DADUtils = DADUtils;

  temporalLeavesLimitReached = false;

  constructor(public sr: StudentRootService,
    private leave: LeaveHttpService,
    private ds: DocumentService,
    private ts: TranslateService,
    private cds: ConfirmationDialogService,
    private refresh: RefreshService) {
    super();
  }

  ngOnInit() {
    this.temporalLeavesLimitReached = this.sr.student.leaves.filter(l => l.type === LeaveType.temporal).length >= 2;
  }

  manageSend() {
    if (this.type === LeaveType.medical && this.end) {
      this.end = null;
    }
    if (!this.f.valid
      || (this.type === LeaveType.temporal && (this.temporalLeavesLimitReached || !this.validDateRange()))
      || (this.type === LeaveType.medical && (!this.estimatedEnd || moment(this.start).isAfter(this.estimatedEnd)))) {
      this.sendEnd.next();
      return;
    }

    this.leave.request(this.sr.student._id, {
      justification: this.justification,
      type: this.type,
      start: this.start,
      end: this.end,
      estimatedEnd: this.estimatedEnd
    }).subscribe((doc: IDocumentRef) => {
      this.sendEnd.next();
      this.refresh.onStudentChange.next();
      this.requested = true;
      this.doc = doc;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
    });
  }

  validDateRange() {
    if (this.start && this.end) {
      return moment(this.end).diff(this.start, 'months', true) >= 6 && moment(this.end).diff(this.start, 'months', true) <= 12
    }

    return false;
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

  isPastStart() {
    return moment(this.start).isBefore(moment(), 'd');
  }

}
