<ng-container *ngIf="process">

  <task-header [task]="task"></task-header>

  <br>

  <denial-msgs-view [denialMsgs]="task.directionProcess.denialMsgs"></denial-msgs-view>

  <br>

  <cds-process-data-view [process]="process"></cds-process-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()"
    *ngIf="canComplete">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <ng-container *ngIf="validation.validate">

      <div class="form-group">
        <label for="tutorRequirementSelect">
          {{ process.tutor | fullname }}
        </label>
        <ng-select id="tutorRequirementSelect"
          name="tutorRequirementSelect"
          #tutorRequirementSelect="ngModel"
          [ngModel]="data.tutorReason"
          (ngModelChange)="onChangeReason($event, 'tutorReason')"
          [required]="true"
          [class.is-invalid]="f.submitted && tutorRequirementSelect.invalid"
          [placeholder]="'supervisor-requirement' | translate"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
          <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
          <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
          <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
        </ng-select>
        <small class="form-text text-muted"
          *ngIf="data.tutorReason && data.tutorReason.includes('sixyearPeriod')"
          [innerHTML]="'sixyearPeriod-note' | translate">
        </small>
        <small class="form-text text-muted"
          *ngIf="data.tutorReason && data.tutorReason.includes('none')"
          [innerHTML]="'none-requirement-note' | translate">
        </small>
        <validation-errors [show]="f.submitted"
          [control]="tutorRequirementSelect"></validation-errors>
      </div>

      <div class="form-group"
        *ngIf="!tutorIsSupervisor1">
        <label for="supervisor1RequirementSelect">
          {{ process.supervisor1 | fullname }}
        </label>
        <ng-select id="supervisor1RequirementSelect"
          name="supervisor1RequirementSelect"
          #supervisor1RequirementSelect="ngModel"
          [ngModel]="data.supervisor1Reason"
          (ngModelChange)="onChangeReason($event, 'supervisor1Reason')"
          [required]="true"
          [class.is-invalid]="f.submitted && supervisor1RequirementSelect.invalid"
          [placeholder]="'supervisor-requirement' | translate"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
          <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
          <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
          <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
        </ng-select>
        <small class="form-text text-muted"
          *ngIf="data.supervisor1Reason && data.supervisor1Reason.includes('sixyearPeriod')"
          [innerHTML]="'sixyearPeriod-note' | translate">
        </small>
        <small class="form-text text-muted"
          *ngIf="data.supervisor1Reason && data.supervisor1Reason.includes('none')"
          [innerHTML]="'none-requirement-note' | translate">
        </small>
        <validation-errors [show]="f.submitted"
          [control]="supervisor1RequirementSelect"></validation-errors>
      </div>

      <div class="form-group"
        *ngIf="enableSupervisor2 && !tutorIsSupervisor2">
        <label for="supervisor2RequirementSelect">
          {{ process.supervisor2 | fullname }}
        </label>
        <ng-select id="supervisor2RequirementSelect"
          name="supervisor2RequirementSelect"
          #supervisor2RequirementSelect="ngModel"
          [ngModel]="data.supervisor2Reason"
          (ngModelChange)="onChangeReason($event, 'supervisor2Reason')"
          [required]="true"
          [class.is-invalid]="f.submitted && supervisor2RequirementSelect.invalid"
          [placeholder]="'supervisor-requirement' | translate"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
          <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
          <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
          <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
        </ng-select>
        <small class="form-text text-muted"
          *ngIf="data.supervisor2Reason && data.supervisor2Reason.includes('sixyearPeriod')"
          [innerHTML]="'sixyearPeriod-note' | translate">
        </small>
        <small class="form-text text-muted"
          *ngIf="data.supervisor2Reason && data.supervisor2Reason.includes('none')"
          [innerHTML]="'none-requirement-note' | translate">
        </small>
        <validation-errors [show]="f.submitted"
          [control]="supervisor2RequirementSelect"></validation-errors>
      </div>

      <div class="form-group"
        *ngIf="enableSupervisor3 && !tutorIsSupervisor3">
        <label for="supervisor3RequirementSelect">
          {{ process.supervisor3 | fullname }}
        </label>
        <ng-select id="supervisor3RequirementSelect"
          name="supervisor3RequirementSelect"
          #supervisor3RequirementSelect="ngModel"
          [ngModel]="data.supervisor3Reason"
          (ngModelChange)="onChangeReason($event, 'supervisor3Reason')"
          [required]="true"
          [class.is-invalid]="f.submitted && supervisor3RequirementSelect.invalid"
          [placeholder]="'supervisor-requirement' | translate"
          [multiple]="true"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
          <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
          <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
          <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
        </ng-select>
        <small class="form-text text-muted"
          *ngIf="data.supervisor3Reason && data.supervisor3Reason.includes('sixyearPeriod')"
          [innerHTML]="'sixyearPeriod-note' | translate">
        </small>
        <small class="form-text text-muted"
          *ngIf="data.supervisor3Reason && data.supervisor3Reason.includes('none')"
          [innerHTML]="'none-requirement-note' | translate">
        </small>
        <validation-errors [show]="f.submitted"
          [control]="supervisor3RequirementSelect"></validation-errors>
      </div>
    </ng-container>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>

  <div *ngIf="canComplete === false"
    class="alert alert-info">
    <i class="far fa-info"></i>
    {{ 'only-coordinator-can-complete' | translate }}
  </div>


</ng-container>