<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <div class="mt-2 mb-3">
      <div class="small text-muted mb-1 mt-2">
        <span *ngIf="process.student.rd === RDType.rd99Mod">
          {{ 'research-and-formation-plan' | translate }}
        </span>
        <span *ngIf="process.student.rd === RDType.rd99">
          {{ 'research-plan' | translate }}
        </span>
      </div>
      <document-link [document]="process.researchPlan"></document-link>
    </div>

    <div class="small text-muted mb-1 mt-2">
      {{ 'reports' | translate }}
    </div>
    <div *ngFor="let doc of process.reports"
      class="mb-1">
      <document-link [document]="doc"></document-link>
    </div>

    <div class="w-100 mb-3"></div>

    <div class="small text-muted mb-1 mt-2">
      {{ 'activities' | translate }}
    </div>
    <div style="width: 100%; max-height: 600px; overflow-y: auto"
      class="border rounded">
      <div class="list-group">
        <div class="list-group-item"
          *ngFor="let activity of process.activities">
          <activity-view [activity]="activity"></activity-view>
        </div>
      </div>
    </div>

    <div class="form-group mt-4">
      <label for="evaluationSelect">
        {{ 'evaluation' | translate }}
      </label>
      <ng-select id="evaluationSelect"
        name="evaluationSelect"
        #evaluationSelect="ngModel"
        [(ngModel)]="evaluation"
        required
        [class.is-invalid]="f.submitted && evaluationSelect.invalid"
        [placeholder]="'select-evaluation' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="EvaluationType.positive">{{ EvaluationType.positive | translate }}</ng-option>
        <ng-option [value]="EvaluationType.negative">{{ EvaluationType.negative | translate }}</ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="evaluationSelect"></validation-errors>
    </div>

    <div class="form-group">
      <label>
        {{ 'evaluation-report' | translate }}
      </label>
      <file-input name="report"
        required
        [invalid]="f.submitted && reportInput.invalid"
        #reportInput="ngModel"
        [(ngModel)]="report"></file-input>
      <validation-errors [show]="f.submitted"
        [control]="reportInput"></validation-errors>
    </div>

    <div class="form-group">
      <label for="president">
        {{ 'president' | translate }}
      </label>
      <ng-select [items]="process.evaluationCommittee.members"
        required="true"
        bindValue="_id"
        bindLabel="firstName"
        [multiple]="false"
        [searchable]="false"
        id="president"
        name="president"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-evaluation-committee-president' | translate"
        #presidentSelect="ngModel"
        [class.is-invalid]="presidentSelect.invalid && f.submitted"
        [ngModel]="presidentId"
        (ngModelChange)="assignPresident($event)">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item | fullname }}
          <span class="small mx-1"
            *ngIf="item._id === process.student.tutorId">
            ({{ 'tutor' | translate }})
          </span>
          <span class="small mx-1"
            *ngIf="item._id === process.student.supervisor1Id || item._id === process.student.supervisor2Id || item._id === process.student.supervisor3Id">
            ({{ 'supervisor' | translate }})
          </span>
          <span class="small text-muted"
            *ngIf="presidentId === item._id">
            {{ 'president' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="secretaryId === item._id">
            {{ 'secretary' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="memberId === item._id">
            {{ 'member' | translate }}
          </span>
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
      </ng-select>
      <validation-errors [control]="presidentSelect"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="secretary">
        {{ 'secretary' | translate }}
      </label>
      <ng-select [items]="process.evaluationCommittee.members"
        required="true"
        bindValue="_id"
        bindLabel="firstName"
        [multiple]="false"
        [searchable]="false"
        id="secretary"
        name="secretary"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-evaluation-committee-secretary' | translate"
        #secretarySelect="ngModel"
        [class.is-invalid]="secretarySelect.invalid && f.submitted"
        [(ngModel)]="secretaryId"
        (ngModelChange)="assignSecretary($event)">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item | fullname }}
          <span class="small mx-1"
            *ngIf="item._id === process.student.tutorId">
            ({{ 'tutor' | translate }})
          </span>
          <span class="small mx-1"
            *ngIf="item._id === process.student.supervisor1Id || item._id === process.student.supervisor2Id || item._id === process.student.supervisor3Id">
            ({{ 'supervisor' | translate }})
          </span>
          <span class="small text-muted"
            *ngIf="presidentId === item._id">
            {{ 'president' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="secretaryId === item._id">
            {{ 'secretary' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="memberId === item._id">
            {{ 'member' | translate }}
          </span>
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
      </ng-select>
      <validation-errors [control]="secretarySelect"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="member">
        {{ 'member' | translate }}
      </label>
      <ng-select [items]="process.evaluationCommittee.members"
        required="true"
        bindValue="_id"
        bindLabel="firstName"
        [multiple]="false"
        [searchable]="false"
        id="member"
        name="member"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-evaluation-committee-member' | translate"
        #memberSelect="ngModel"
        [class.is-invalid]="memberSelect.invalid && f.submitted"
        [(ngModel)]="memberId"
        (ngModelChange)="assignMember($event)">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item | fullname }}
          <span class="small mx-1"
            *ngIf="item._id === process.student.tutorId">
            ({{ 'tutor' | translate }})
          </span>
          <span class="small mx-1"
            *ngIf="item._id === process.student.supervisor1Id || item._id === process.student.supervisor2Id || item._id === process.student.supervisor3Id">
            ({{ 'supervisor' | translate }})
          </span>
          <span class="small text-muted"
            *ngIf="presidentId === item._id">
            {{ 'president' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="secretaryId === item._id">
            {{ 'secretary' | translate }}
          </span>
          <span class="small text-muted"
            *ngIf="memberId === item._id">
            {{ 'member' | translate }}
          </span>
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | fullname }}
        </ng-template>
      </ng-select>
      <validation-errors [control]="memberSelect"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="alert alert-warning"
      *ngIf="secretaryId && secretaryId !== us.user._id">
      {{ 'evaluation-must-be-confirmed-by-secretary' | translate }}
    </div>


    <div class="form-group text-right mt-2">
      <div class="d-block invalid-feedback mb-2"
        *ngIf="f.invalid && f.submitted">
        {{ 'check-errors' | translate }}
      </div>

      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate}}
      </button>
    </div>


  </form>
</ng-container>