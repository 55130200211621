<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <div class="small text-muted mb-1">
    {{ 'student-info' | translate }}
  </div>

  <text-info icon="user">
    {{ process.student.user | fullname }}
  </text-info>

  <text-info icon="id-card">
    {{ process.student.user.idNum }}
  </text-info>

  <text-info icon="envelope"
    mb="4">
    {{ process.student.email }}
  </text-info>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'request-date' | translate }}
    </div>
    <div>
      {{ process.createdAt | date: 'dd/MM/yyyy HH:mm' }}
    </div>
  </div>

  <denial-msgs-view [denialMsgs]="task.thesisProcess.denialMsgs"></denial-msgs-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <ng-container *ngIf="validation.validate === true">
      <div class="alert alert-info">
        <i class="far fa-info-circle mr-2"></i>
        {{ 'process.thesis.tasks.secretaryValidation.info1' | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="validation.validate === false">
      <div class="alert alert-info">
        <i class="far fa-info-circle mr-2"></i>
        {{ 'process.thesis.tasks.secretaryValidation.info2' | translate }}
      </div>
    </ng-container>


    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        <span *ngIf="validation.validate === true">
          {{ 'accept' | translate }}
        </span>
        <span *ngIf="validation.validate === false">
          {{ 'send' | translate }}
        </span>
      </button>
    </div>

  </form>

</ng-container>