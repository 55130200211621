import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment-timezone';

import { ITask } from '../../../../interfaces/ITask';
import { DADUtils } from '../../../../utils/DADUtils';
import { ExternalTaskHttpService } from '../../data-services/external-task-http.service';
import { AbstractTaskComponent } from '../../root/components/process/abstract-task.component';

@Component({
  selector: 'thesis-defence-date',
  templateUrl: './thesis-defence-date.component.html',
})
export class ThesisDefenceDateComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;
  @Input() token: string;

  @Output() onComplete = new EventEmitter();

  defenceDate: Date;
  defencePlace: string;

  minDefenceDate: Date;

  constructor(private et: ExternalTaskHttpService) {
    super();
  }

  ngOnInit() {
    this.minDefenceDate = DADUtils.add16DaysForDefence(this.task.thesisProcess.pgcDefenceApprobalDate).toDate();
  }

  manageSend() {
    if (this.f.invalid || this.dateError()) {
      this.sendEnd.next();
      return;
    };

    this.defenceDate = moment.tz(moment(this.defenceDate).format('YYYY-MM-DD HH:mm'), 'Europe/Madrid').toDate();

    this.et.defenceDate(this.task._id, this.token, { defenceDate: this.defenceDate, defencePlace: this.defencePlace }).subscribe(() => {
      this.sendEnd.next();
      this.onComplete.emit();
    })
  }

  dateError() {
    if (!this.defenceDate) return false;
    return moment(this.defenceDate).isBefore(this.minDefenceDate, 'd')
  }

}
