<li class="nav-item"
  routerLinkActive="active"
  [routerLinkActiveOptions]="linkActiveOptions">
  <a class="nav-link"
    role="button"
    *ngIf="!disabled"
    (click)="active=false; onClick.emit(name); ms.onItemClick.next(name)"
    [routerLink]="link">
    <i class="fa{{iconType}} fa-{{icon}} fa-fw mr-2"></i>
    {{ name | translate }}
    <span *ngIf="dot"
      class="ml-2 text-info"
      style="position: relative; top: -2px">
      <i class="fas fa-circle fa-xs"></i>
    </span>
  </a>
  <span class="nav-link text-muted"
    style="font-size: 14px"
    role="button"
    *ngIf="disabled">
    <i class="fa{{iconType}} fa-{{icon}} fa-fw mr-2"></i>
    {{ name | translate }}
  </span>
</li>