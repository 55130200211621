<div class="input-group">
  <input type="text"
    class="form-control"
    name="searchText"
    [(ngModel)]="searchText"
    (keyup)="keyupObs.next(searchText)"
    [placeholder]="placeholder | translate">
  <div class="input-group-append">
    <button class="btn btn-secondary"
      type="button"
      (click)="keyupObs.next(searchText)">
      <i class="far fa-search mr-1"></i>
      {{ 'search' | translate }}
    </button>
  </div>
</div>