import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { ActivityValidationStateType, IActivity } from '../../../../../../interfaces/IActivity';
import { IDocument } from '../../../../../../interfaces/IDocument';
import { PermissionsType } from '../../../../../../interfaces/IPermissions';
import { ActivityHttpService } from '../../../../data-services/activity-http.service';
import { ActivityValidationHttpService } from '../../../../data-services/activity-validation-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { InfiniteListComponent } from '../../../../UI/infinite-list/infinite-list.component';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { UserService } from '../../../../user/user.service';
import { ActivitiesListComponent } from '../../../components/activities/activities-list/activities-list.component';
import { DocumentDetailsComponent } from '../../../components/activities/document-details/document-details.component';
import { EditActivityComponent } from '../../../components/activities/edit-activity/edit-activity.component';
import { VersionHistoricComponent } from '../../../components/activities/version-historic/version-historic.component';
import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'activities',
  templateUrl: 'activities.component.html'
})
export class ActivitiesComponent implements OnInit {
  @ViewChild('editActivity') editActivity: EditActivityComponent;
  @ViewChild('activitiesList') activitiesList: ActivitiesListComponent;
  @ViewChild('versionHistoric') versionHistoric: VersionHistoricComponent;
  @ViewChild('documentDetails') documentDetails: DocumentDetailsComponent;
  @ViewChild('infiniteList') infiniteList: InfiniteListComponent;

  activities: IActivity[];

  activityModalOpen = false;
  addDocumentsModalOpen = false;
  newVersionModalOpen = false;
  versionHistoricModalOpen = false;
  documentDetailsModalOpen = false;

  openId: string;

  params = {
    searchText: ''
  }

  editable;

  constructor(public us: UserService, private as: ActivityHttpService,
    public ds: DocumentService,
    private route: ActivatedRoute,
    private alerts: PopAlertsService,
    private confirmServ: ConfirmationDialogService,
    private translate: TranslateService,
    private avs: ActivityValidationHttpService,
    private rs: RefreshService,
    public sr: StudentRootService) { }

  ngOnInit() {
    this.editable = this.sr.student.permissions[PermissionsType.manageActivities];
    this.route.queryParams.subscribe((params) => {
      if (params.id) this.openId = params.id;
    })
  }

  fetchFn() {
    return (params) => {
      params = {
        ...params,
        ...this.params,
        programId: this.us.selectedRole.groupId
      }
      return this.as.fetchMyActivities(params);
    }
  }

  openEditActivity(activity: IActivity) {
    this.editActivity.activity = _.cloneDeep(activity);
    this.activityModalOpen = true;
  }

  addActivity(activity: IActivity) {
    activity.studentId = this.sr.student._id;
    activity.createdAt = new Date();
    this.as.addActivity(this.us.selectedRole.groupId, activity).subscribe((activity: IActivity) => {
      if (activity) {
        this.activityModalOpen = false;
        this.activities.unshift(activity);
        this.editActivity.reset();

        this.activitiesList.doOpen(activity._id);

        this.alerts.saveSuccess();
      }
    });
  }

  saveActivity(event: IActivity) {
    this.as.saveActivity(this.us.selectedRole.groupId, event._id, {
      activityType: event.activityType,
      title: event.title,
      description: event.description,
      startDate: event.startDate,
      endDate: event.endDate
    }).subscribe((activity: IActivity) => {
      if (activity) {
        this.activityModalOpen = false;

        const index = this.activities.findIndex(a => a._id === activity._id);
        if (index > -1) {
          this.activities[index] = activity;
        }

        this.alerts.saveSuccess();
      }
    })
  }

  removeActivity(event: IActivity) {
    let message = this.translate.instant('sure-to-remove-activity');
    if (event.validationState === ActivityValidationStateType.PendingStudentReview) {
      message += ' ' + this.translate.instant('validation-process-will-end');
    }

    this.confirmServ.confirm({
      title: this.translate.instant('remove-activity'),
      message,
      btnOkText: this.translate.instant('remove')
    }, () => {
      this.as.removeActivity(this.us.selectedRole.groupId, event._id).subscribe(() => {
        this.rs.onTasksChange.next();
        const index = this.activities.findIndex(a => a._id === event._id);
        if (index > -1) {
          this.activities.splice(index, 1);
        }
        this.alerts.pop({
          styleClass: 'info',
          msg: this.translate.instant('activity-removed'),
          dismissTime: 20000,
          action: {
            id: 'undo-activity',
            label: this.translate.instant('undo'),
            fn: () => {
              this.as.restoreActivity(this.us.selectedRole.groupId, event._id).subscribe(() => {
                this.infiniteList.search();
                this.alerts.pop({
                  styleClass: 'success',
                  msg: this.translate.instant('activity-restored')
                });
              });
            }
          }
        });
      });
    });
  }

  doAddDocumentsToActivity(activityId: string, documents: { documentRef: string, fileName: string }[]) {
    this.as.addDocuments(this.us.selectedRole.groupId, activityId, documents).subscribe((ok: any) => {
      const index = this.activities.findIndex(a => a._id === activityId);
      if (index > -1) {
        if (!this.activities[index].attached) this.activities[index].attached = [];
        documents.forEach(d => this.activities[index].attached.push(d));
      }

      this.alerts.saveSuccess();
      this.addDocumentsModalOpen = false;
    });
  }

  removeDocument(activityId: string, document: { documentRef: string, fileName: string }) {
    this.confirmServ.confirm({
      title: this.translate.instant('remove-evidence'),
      message: this.translate.instant('sure-to-remove-evidence', { fileName: document.fileName }),
      btnOkText: this.translate.instant('remove')
    }, () => {
      this.as.removeDocument(this.us.selectedRole.groupId, activityId, document.documentRef).subscribe((ok: any) => {
        const index = this.activities.findIndex(a => a._id === activityId);
        if (index > -1 && this.activities[index].attached) {
          const dIndex = this.activities[index].attached.findIndex(at => at.documentRef === document.documentRef);
          if (dIndex > -1) {
            this.activities[index].attached.splice(dIndex, 1);
          }
        }
        this.alerts.pop({
          styleClass: 'info',
          msg: this.translate.instant('evidence-removed')
        });
      });
    });
  }

  addNewVersion(activityId: string, documentId: string, versionId: string) {
    this.as.newVersion(this.us.selectedRole.groupId, activityId, documentId, versionId).subscribe((document: IDocument) => {
      // Find attached and update
      const index = this.activities.findIndex(a => a._id === activityId);
      if (index > -1 && this.activities[index].attached) {
        const dIndex = this.activities[index].attached.findIndex(at => at.documentRef === documentId);
        if (dIndex > -1) {
          this.activities[index].attached[dIndex].fileName = document.name
        }
      }
      this.alerts.saveSuccess();
      this.newVersionModalOpen = false;
    });
  }

  openVersionHistoric(documentId: string) {
    this.ds.metadata(documentId).subscribe((document: IDocument) => {
      this.versionHistoric.document = document;
      this.versionHistoricModalOpen = true;
    });
  }

  openDocumentDetails(documentId: string) {
    this.ds.metadata(documentId).subscribe((document: IDocument) => {
      this.documentDetails.document = document;
      this.documentDetailsModalOpen = true;
    });
  }

  saveDocumentDetails(documentId: string, location: string, description: string) {
    this.ds.saveDetails(documentId, location, description).subscribe((res) => {
      this.alerts.saveSuccess();
      this.documentDetailsModalOpen = false;
    });
  }

  requestValidation(activity: IActivity) {
    this.confirmServ.confirm({
      title: this.translate.instant('request-validation-by-tutor'),
      message: this.translate.instant('sure-to-request-validation', { activity: activity.title }),
      btnOkText: this.translate.instant('send'),
      btnOkClass: 'primary'
    }, () => {
      this.avs.requestValidation(activity._id).subscribe(() => {
        const index = this.activities.findIndex(a => a._id === activity._id);
        if (index > -1) {
          this.activities[index].validationState = ActivityValidationStateType.PendingTutorValitation;
        }

        this.alerts.pop({
          styleClass: 'success',
          msg: this.translate.instant('activity-validation-requested')
        });
      });
    });
  }

  resendValidation(activity: IActivity) {
    this.confirmServ.confirm({
      title: this.translate.instant('request-validation-by-tutor'),
      message: this.translate.instant('sure-to-request-validation', { activity: activity.title }),
      btnOkText: this.translate.instant('send'),
      btnOkClass: 'primary'
    }, () => {
      this.avs.resendValidationActivity(activity._id).subscribe(() => {
        const index = this.activities.findIndex(a => a._id === activity._id);
        if (index > -1) {
          this.activities[index].validationState = ActivityValidationStateType.PendingTutorValitation;
        }

        this.rs.onTasksChange.next();

        this.alerts.pop({
          styleClass: 'success',
          msg: this.translate.instant('activity-validation-requested')
        });
      });
    });
  }

  modify(activity: IActivity) {
    this.confirmServ.confirm({
      title: this.translate.instant('modify-activity'),
      message: this.translate.instant('sure-to-modify-activity', { activity: activity.title }),
      btnOkText: this.translate.instant('accept'),
      btnOkClass: 'primary'
    }, () => {
      this.as.modifyActivity(this.us.selectedRole.groupId, activity._id).subscribe(() => {
        const index = this.activities.findIndex(a => a._id === activity._id);
        if (index > -1) {
          this.activities[index].validationState = ActivityValidationStateType.Draft;
          this.activities[index].validated = false;
        }
      });
    });
  }

}