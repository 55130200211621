<form #f="ngForm"
  (keydown.enter)="$event.preventDefault()"
  autocomplete="off"
  id="documentDetailsForm"
  (ngSubmit)="save()"
  *ngIf="active && document">

  <div class="form-group">
    <file-icon [fileName]="document.name"></file-icon>
    {{ document.name }}
  </div>

  <div class="form-group row">
    <label for="title"
      class="col-md-3 col-form-label">{{ 'location' | translate }}</label>

    <div class="col-md-9">
      <input class="form-control"
        name="location"
        id="location"
        required="false"
        [class.is-invalid]="locationInput.invalid && f.submitted"
        #locationInput="ngModel"
        [(ngModel)]="document.location"
        [disabled]="!editable"
        [placeholder]="'current-location-of-physical-copy' | translate">
      <validation-errors [control]="locationInput"
        [show]="f.submitted"></validation-errors>
    </div>
  </div>

  <div class="form-group row">
    <label for="description"
      class="col-md-3 col-form-label">
      {{ 'description' | translate }}
    </label>
    <div class="col-md-9">
      <textarea class="form-control"
        name="description"
        id="description"
        #descriptionInput="ngModel"
        rows="3"
        maxlength="400"
        [placeholder]="'document-description' | translate"
        [disabled]="!editable"
        [(ngModel)]="document.description"></textarea>
      <div class="text-right small text-muted">
        {{ document.description ? document.description.length : 0}}/400
      </div>
      <validation-errors [control]="descriptionInput"
        [show]="f.submitted"></validation-errors>
    </div>
  </div>

</form>