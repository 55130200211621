import { animate, AnimationTriggerMetadata, query, stagger, state, style, transition, trigger } from '@angular/animations';

export const appear = trigger('appear', [
  state('in', style({ opacity: 1 })),
  state('void', style({ opacity: 0 })),
  transition('void => *', [
    style({ opacity: 0 }),
    animate(250)
  ]),
  transition('* => void', [
    animate(200, style({ opacity: 0 }))
  ])
]);

export const slideInOut = trigger('slideInOut', [
  state('out', style({
    overflow: 'hidden',
    height: '50px'
  })),
  state('in', style({
    overflow: 'hidden',
    height: '*',
  })),
  transition('in => out', animate('400ms ease-in-out')),
  transition('out => in', animate('400ms ease-in-out'))
]);

export const dropdownAnimation = trigger('dropdownAnimation', [
  state('in', style({
    height: '*',
    overflowY: 'hidden'
  })),
  state('out', style({
    height: '0px',
    overflowY: 'hidden'
  })),
  transition('in => out', animate('200ms ease-in-out')),
  transition('out => in', animate('200ms ease-in-out'))
]);

export function heightAnimation(name: string, finalHeight: string): AnimationTriggerMetadata {
  return trigger(name, [
    state('in', style({
      height: finalHeight,
      opacity: 1,
    })),
    state('out', style({
      height: '0px',
      opacity: 0
    })),
    transition('in => out', animate('200ms ease-in-out')),
    transition('out => in', animate('100ms ease-in-out'))
  ]);
}

export const reservationState = trigger('reservationState', [
  state('todo', style({
    background: '#fff',
    padding: '15px'
  })),
  state('completed', style({
    background: 'green',
    padding: '5px 15px'
  })),
  state('rejected', style({
    background: 'red',
    padding: '5px 15px'
  })),
  transition('todo => *', animate('2000ms ease-in-out')),
]);

export const collapse = trigger('collapse', [
  state('open', style({
    display: 'block',
    transform: 'translate3d(0, 0, 0)'
  })),
  state('closed', style({
    display: 'none',
    transform: 'translate3d(0, -100%, 0)'
  })),
  transition('closed => open', animate('200ms ease-in')),
  transition('open => closed', animate('100ms ease-out'))]);

export const translateInOut: AnimationTriggerMetadata =
  trigger('transition', [
    state('*', style({ transform: 'translateY(0)' })),
    state('void', style({ transform: 'translateY(0)' })),

    state('bottomOut', style({ transform: 'translateY(100%)' })),
    state('topOut', style({ transform: 'translateY(-100%)' })),

    transition('void => topIn', [
      style({ transform: 'translateY(-100%)' }),
      animate('1000ms ease-in-out'),
    ]),

    transition('void => bottomIn', [
      style({ transform: 'translateY(100%)' }),
      animate('1000ms ease-in-out'),
    ]),

    transition('* => *', animate('1000ms ease-out')),
  ]);

export const smoothHeight = trigger('grow', [
  transition('void <=> *', []),
  transition('* <=> *', [style({ height: '{{startHeight}}px' }), animate('.4s ease-in-out')], {
    params: { startHeight: 0 }
  })
]);

export const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    )
  ])
]);