import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ITask, Processes } from '../../../interfaces/ITask';

@Injectable()
export class TaskHttpService {

  constructor(private http: HttpClient) { }

  findTask(taskId: string): Observable<ITask> {
    return this.http.get<ITask>(`/api/task/${taskId}`);
  }

  fetchTasks(params: { page?: number, size?: number, processKey?: Processes, role?: string }): Observable<ITask[]> {
    return this.http.get<ITask[]>(`/api/me/tasks`, { params });
  }

  count(params: { processKey?: Processes, role?: string }): Observable<number> {
    return this.http.get<{ res: number }>(`/api/me/tasksCount`, { params }).pipe(
      map(r => r.res)
    );
  }

}