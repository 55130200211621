import { IDocumentRef } from './IDocument';
import { IValidationMsgs, IProcess } from './IProcess';
import { IProgram } from './IProgram';
import { DedicationType, IStudent } from './IStudent';

export interface IDedicationProcess extends IProcess {
  state: DedicationPState,
  studentId: string,
  programId: string,
  dedication: DedicationType,
  partialTimeDoc?: IDocumentRef[],
  denialMsgs?: IValidationMsgs,

  // Populate
  student?: IStudent;
  program?: IProgram;
}


export enum DedicationPState {
  PendingDirectionTeamValidation = "PendingDirectionTeamValidation",
  PendingAcademicCommitteeValidation = "PendingAcademicCommitteeValidation",
  PendingPostgraduateCommitteeValidation = "PendingPostgraduateCommitteeValidation",
}

export enum TKDedication {
  academicCommitteeValidation = "academicCommitteeValidation",
  directionTeamValidation = "directionTeamValidation",
  postgraduateCommitteeValidation = "postgraduateCommitteeValidation",
}

export interface IDedicationRequestData {
  dedication: DedicationType,
  partialTimeDoc?: IDocumentRef[],
  denialMsg?: string,
  validate?: boolean
}
