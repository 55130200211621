import { ICDSProcess, TKCDS } from './ICDSProcess';
import { IDedicationProcess, TKDedication } from './IDedicationProcess';
import { IDirectionProcess, TKDirection } from './IDirectionProcess';
import { IDischargeProcess, TKDischarge } from './IDischargeProcess';
import { IEvaluationProcess, TKEvaluation } from './IEvaluationProcess';
import { IExtensionProcess, TKExtension } from './IExtensionProcess';
import { IIndustrialProcess, TKIndustrial } from './IIndustrialProcess';
import { ILeaveProcess, TKLeave } from './ILeaveProcess';
import { IPermanentLeaveProcess, TKPermanentLeave } from './IPermanentLeaveProcess';
import { IRenewalProcess, TKRenewal } from './IRenewalProcess';
import { IThesisProcess, TKThesis } from './IThesisProcess';
import { IUser } from './IUser';
import { IValidationProcess, TKValidation } from './IValidationProcess';

export interface ITask {
  _id?: string,
  processId: string,
  validationProcessId?: string,
  cdsProcessId?: string,
  dedicationProcessId?: string,
  directionProcessId?: string,
  extensionProcessId?: string,
  leaveProcessId?: string,
  permanentLeaveProcessId?: string,
  dischargeProcessId?: string,
  evaluationProcessId?: string,
  thesisProcessId?: string,
  renewalProcessId?: string,
  industrialProcessId?: string,
  processKey: Processes,
  taskKey: TKValidation | TKCDS | TKDedication | TKDirection | TKExtension | TKLeave | TKDischarge | TKEvaluation | TKThesis | TKPermanentLeave | TKRenewal | TKIndustrial,
  createdAt: Date,
  active: boolean,
  assigneeId?: string, // user id
  assigneeName?: string,
  roles: string[], // roles which can complete the task
  completeRoles?: string[], // if there are values, only the roles can complete the task, other in roles can visualize it
  external?: boolean,
  token?: string,
  tokenDate?: Date,
  old?: {
    taskId: string,
    token: string
  }

  // Populate
  validationProcess?: IValidationProcess,
  cdsProcess?: ICDSProcess,
  dedicationProcess?: IDedicationProcess,
  directionProcess?: IDirectionProcess,
  extensionProcess?: IExtensionProcess,
  leaveProcess?: ILeaveProcess,
  permanentLeaveProcess?: IPermanentLeaveProcess,
  dischargeProcess?: IDischargeProcess,
  evaluationProcess?: IEvaluationProcess,
  thesisProcess?: IThesisProcess,
  renewalProcess?: IRenewalProcess,
  industrialProcess?: IIndustrialProcess,
  assignee?: IUser // user id
}

export enum Processes {
  cds = "cds",
  validation = "validation",
  evaluation = "evaluation",
  thesis = "thesis",
  defence = "defence",
  dedication = "dedication",
  direction = "direction",
  extension = "extension",
  leave = "leave",
  discharge = "discharge",
  permanentLeave = "permanentLeave",
  renewal = "renewal",
  industrial = "industrial"
}