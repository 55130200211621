import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { IUser } from '../../../../interfaces/IUser';
import { ServiceHttpService } from '../../data-services/service-http.service';
import { UserHttpService } from '../../data-services/user-http.service';
import { HelperService } from '../../shared/helper-service/helper.service';

@Component({
  selector: 'assing-user-id',
  templateUrl: './assing-user-id.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssingUserIdComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AssingUserIdComponent),
      multi: true
    }
  ]
})
export class AssingUserIdComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() showErrors = false;
  @Input() showIdNum = false;

  @Input() required = true;


  @Input() userInfo: {
    userId?: string,
    email: string,
    idNum: string,
    firstName: string,
    lastName: string,
    university: string,
    uid: string
  } = {
      email: null,
      idNum: null,
      firstName: null,
      lastName: null,
      university: null,
      uid: null
    };

  @Input() isNew = false;
  @Output() isNewChange = new EventEmitter<boolean>();

  @Input() favEmail;

  userId: string;
  searchText: string;

  foundUser: {
    userId?: string,
    email: string,
    idNum: string,
    firstName: string,
    lastName: string,
    university: string,
    uid: string
  };

  userInfoBackup: {
    userId?: string,
    email: string,
    idNum: string,
    firstName: string,
    lastName: string,
    university: string,
    uid: string
  };

  notFound = false;

  constructor(private us: UserHttpService, public hs: HelperService, private ss: ServiceHttpService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userInfo) {
      this.userId = this.userInfo.userId;
      this.onChange(this.userId);
    }
  }

  ngOnInit() {
    this.searchText = this.userInfo.email;
    if (!this.userInfo.userId) {
      this.search();
    } else {
      this.userId = this.userInfo.userId;
      this.onChange(this.userId);
    }
  }

  resetFind() {
    this.userId = null;
    this.onChange(this.userId);
    if (this.userInfoBackup) {
      this.userInfo = { ...this.userInfoBackup };
    }
    this.foundUser = null;
    this.notFound = false;
    this.searchText = this.userInfo.email;
    this.isNew = false;
    this.search();
  }

  search() {
    if (!this.searchText || this.searchText.length <= 0) return;
    this.us.findByEmail(this.searchText).subscribe((user: IUser) => {
      if (!user.uid) {
        this.notFound = true;
      } else {
        this.notFound = false;
        this.foundUser = {
          userId: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: (<any>user.email).address ? (<any>user.email).address : user.email,
          idNum: user.idNum,
          university: user.university,
          uid: (<any>user).uid
        };
      }
    })
  }

  assign() {
    if (!this.foundUser.userId && this.foundUser.uid) {
      // Call create user in DB if not exists
      this.ss.createUser({ firstName: this.foundUser.firstName, lastName: this.foundUser.lastName, idNum: this.foundUser.idNum, uid: this.foundUser.uid, email: this.foundUser.email }).subscribe((res) => {
        this.foundUser.userId = res.userId;
        this.doAssign();
      })
    } else {
      this.doAssign();
    }
  }

  private doAssign() {
    this.userId = this.foundUser.userId;
    this.userInfoBackup = { ...this.userInfo };
    this.userInfo = this.foundUser;
    this.notFound = false;
    this.onChange(this.userId);
  }

  onChange = (_: any) => { };
  onTouch = () => { };

  writeValue(value: any): void {
    if (value !== undefined) {
      this.userId = value;
      // this.onChange(this.userId);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.required && !this.userId) return { required: true };

    return null;
  }

}
