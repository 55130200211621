<div class="container-lg container-fluid px-0 px-md-2"
  style="min-height: calc(100vh - 273px);">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 py-3 px-0 px-md-2">

    <div class="d-flex justify-content-between align-items-center mb-4">
      <a [routerLink]="['/']">
        <img src="assets/UMA_logo.png"
          alt="Universidad de Málaga"
          height="77">
      </a>

      <a [routerLink]="['/']">
        <img height="80"
          src="assets/ED-UMA.png" />
      </a>
    </div>


    <div class="card mb-4"
      *ngIf="(!task || notFound) && !loading">
      <div class="card-body">
        <i class="fas fa-info-circle text-info mr-1"></i>
        {{ 'external-task-not-found-or-completed' | translate }}

        <div class="mt-4 text-right">
          <a [routerLink]="['/']">
            {{ 'go-to-login' | translate }}
          </a>
        </div>
      </div>
    </div>

    <div class="card mb-4"
      *ngIf="task">
      <div class="card-body"
        *ngIf="!completed">

        <ng-container [ngSwitch]="task.processKey">

          <ng-container *ngSwitchCase="Processes.thesis">

            <div [ngSwitch]="task.taskKey">
              <thesis-pgc-info *ngSwitchCase="TKThesis.postgraduateCommitteeInfo"
                [task]="task"></thesis-pgc-info>
              <thesis-committee-acceptance (onComplete)="completed = true"
                *ngSwitchCase="TKThesis.thesisCommitteeAcceptance"
                [task]="task"
                [token]="token">
              </thesis-committee-acceptance>
              <thesis-committee-alt-acceptance *ngSwitchCase="TKThesis.thesisCommitteeAltAcceptance"
                [task]="task"
                [token]="token"
                (onComplete)="completed = true">
              </thesis-committee-alt-acceptance>
              <external-evaluator-report *ngSwitchCase="TKThesis.externalEvaluatorReport"
                [task]="task"
                [token]="token"
                (onComplete)="completed = true">
              </external-evaluator-report>
              <thesis-external-acceptance *ngSwitchCase="TKThesis.externalEvaluatorAcceptance"
                [task]="task"
                [token]="token">
              </thesis-external-acceptance>
              <thesis-defence-date *ngSwitchCase="TKThesis.defenceDate"
                [task]="task"
                [token]="token"
                (onComplete)="completed = true">
              </thesis-defence-date>
              <thesis-cum-laude-vote *ngSwitchCase="TKThesis.cumLaudeVote"
                [task]="task"
                [token]="token"
                (onComplete)="completed = true"></thesis-cum-laude-vote>
              <thesis-secretary-evaluation *ngSwitchCase="TKThesis.secretaryEvaluation"
                [task]="task"
                [token]="token"
                (onComplete)="completed = true"></thesis-secretary-evaluation>
              <thesis-defence-info *ngSwitchCase="TKThesis.defenceInfo"
                [task]="task"
                [token]="token">
              </thesis-defence-info>
            </div>

          </ng-container>

        </ng-container>

      </div>
      <div class="card-body"
        *ngIf="completed">
        <h5 class="card-title m-0">
          <i class="far fa-check text-success mr-1"></i>
          {{ 'task-completed' | translate }}
        </h5>
      </div>
    </div>

  </div>

</div>

<public-footer></public-footer>