import { Component, OnInit, ViewChild } from '@angular/core';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment-timezone';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';

@Component({
  selector: 'service-programs',
  templateUrl: './service-programs.component.html',
})
export class ServiceProgramsComponent implements OnInit {
  @ViewChild('f') f: NgForm

  ppModalOpen = false;

  start: Date;
  end: Date;

  moment = moment;

  constructor(public ps: ProgramHttpService,
    private ss: ServiceHttpService,
    private alerts: PopAlertsService) { }

  ngOnInit() {

  }

  openPpModal() {
    this.ppModalOpen = true;
    this.start = this.ps.professorProcessStart;
    this.end = this.ps.professorProcessEnd;
  }

  saveProfessorProcess() {
    if (this.f.invalid) return;

    this.ss.professorProcessDates(this.start, this.end).subscribe(() => {
      this.ps.updateProfessorProcess();
      this.ppModalOpen = false;
      this.alerts.saveSuccess();
    });
  }

  closeProfessorProcess() {
    this.ss.closeProfessorProcess().subscribe(() => {
      this.ps.isProfessorProcessOpen = false;
    });
  }

  validDateRange() {
    if (!this.start) {
      return false;
    }

    if (this.end) {
      return moment(this.end).isSameOrAfter(this.start)
    }

    return true;
  }

}
