import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { ITask } from '../../../../../../../interfaces/ITask';
import { IThesisProcess } from '../../../../../../../interfaces/IThesisProcess';
import { ThesisHttpService } from '../../../../../data-services/thesis-http.service';
import { DocumentService } from '../../../../../shared/document-service/document.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'thesis-defence-request',
  templateUrl: './thesis-defence-request.component.html',
})
export class ThesisDefenceRequestComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  doc: IDocumentRef;

  process: IThesisProcess;

  thesisFinal: IDocumentRef;
  changesReport: IDocumentRef;

  constructor(private thesis: ThesisHttpService,
    private router: Router,
    private ds: DocumentService) {
    super();
  }

  ngOnInit() {
    this.thesis.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
      this.thesisFinal = process.thesisFinal;
      this.changesReport = process.changesReport;
    })
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    this.thesis.studentDefenceRequest(this.task._id, this.thesisFinal, this.changesReport).subscribe((docRef) => {
      this.sendEnd.next();
      this.doc = docRef;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);

      void this.router.navigate(['/app/task-completed'], { queryParams: { docRef: this.doc.documentRef } });
    })
  }

}
