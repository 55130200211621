<ng-container *ngIf="task">
  <task-header [task]="task"></task-header>

  <br>

  <div class="small text-muted mb-1">
    {{ 'program' | translate }}
  </div>
  <div class="mb-4">
    {{ task.thesisProcess.student.program.name }}
  </div>

  <div class="small text-muted mb-1">
    {{ 'student' | translate }}
  </div>
  <text-info icon="user"
    [mb]="4">
    {{ task.thesisProcess.student | fullname }}
  </text-info>

  <direction-team-view [student]="task.thesisProcess.student"></direction-team-view>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-title' | translate }}
    </div>
    <div>
      {{ task.thesisProcess.data.thesisTitle }}
    </div>
  </div>

  <div class="w-100 text-right">
    <button (click)="send()"
      class="btn btn-primary"
      [disableButton]="disabled || sending"
      [disableButtonIcon]="sending ? 'spin' : 'ban'">
      {{ 'accept-my-participation' | translate }}
    </button>

  </div>
</ng-container>