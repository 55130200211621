<div class="alert alert-{{alert.styleClass}} pop-alert"
  role="alert"
  [@appear]="'in'"
  *ngIf="alert">
  {{alert.msg}}

  <button class="btn btn-link btn-sm"
    type="button"
    *ngIf="alert.action"
    style="padding: 0px; margin: 0 10px 0 20px; position: relative; top: -2px;"
    (click)="emitAction()">
    {{ alert.action.label }}
  </button>

  <button type="button"
    class="close"
    style="margin-left: 15px;"
    (click)="alert = undefined"
    *ngIf="alert.dismissable"
    aria-label="Close">
    <span aria-hidden="true">
      <i class="far fa-times"></i>
    </span>
  </button>
</div>