import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IIndustrialMentionInfo, IStudent } from '../../../../../../../interfaces/IStudent';
import { IndustrialMentionHttpService } from '../../../../../data-services/industrial-mention-http.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { NgForm } from '@angular/forms';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { UserService } from '../../../../../user/user.service';
import * as _ from 'lodash';

@Component({
  selector: 'industrial-mention-card',
  templateUrl: './industrial-mention-card.component.html',
})
export class IndustrialMentionCardComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() student: IStudent;

  industrialMentionInfo: IIndustrialMentionInfo = {
    industrialMention: false,
    agreementDocs: [],
    agreementDate: new Date(),
    organization: '',
    organizationContact: ''
  }

  open = false;

  constructor(private is: IndustrialMentionHttpService,
    private refresh: RefreshService,
    private alerts: PopAlertsService,
    public us: UserService,
    public sh: ServiceHttpService
  ) { }

  ngOnInit() {
  }

  doOpen() {
    this.industrialMentionInfo = _.cloneDeep(this.student.industrialMentionInfo);
    if (!this.industrialMentionInfo) {
      this.industrialMentionInfo = {
        industrialMention: false,
        agreementDocs: [],
        agreementDate: new Date(),
        organization: '',
        organizationContact: ''
      }
    }
    this.open = true;
  }

  save() {
    if (this.f.invalid) return;

    this.is.saveIndustrialMentionInfo(this.student._id, this.industrialMentionInfo).subscribe(() => {
      this.refresh.onStudentChange.next();
      this.alerts.saveSuccess();
      this.open = false;
    })
  }

}
