<div class="top-banner badge badge-warning">
  <span *ngIf="appService.env === 'certification'">
    VERSIÓN DE PRUEBA
  </span>
  <span *ngIf="appService.env === 'development'">
    DESARROLLO
  </span>
</div>

<div class="watermark"
  [class.cert]="appService.env === 'certification'"
  [class.dev]="appService.env === 'development'"></div>