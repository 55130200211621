import { Component, OnInit } from '@angular/core';
import { IProgram } from '../../../../../../interfaces/IProgram';
import { Subscription } from 'rxjs';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'coordinator-professors',
  templateUrl: './coordinator-professors.component.html',
})
export class CoordinatorProfessorsComponent implements OnInit {

  program: IProgram;

  sub: Subscription;

  constructor(private ps: ProgramHttpService, private us: UserService) { }

  ngOnInit() {
    this.sub = this.ps.programsObs.subscribe(() => {
      this.program = this.ps.programsMap[this.us.selectedRole.groupId];
    })
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

}
