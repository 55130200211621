<infinite-list #list
  [data]="fetchFn()"
  [array]="processes"
  (arrayChange)="setProcesses($event)">

  <form>
    <div class="row mb-2">
      <div class="col-12 col-md pr-md-0">
        <ng-select id="selecteableProcesses"
          name="selecteableProcesses"
          [items]="selecteableProcesses"
          style="width: 100%;"
          [ngModel]="processType"
          (ngModelChange)="processType = $event; updateStates(); processTypeChange.emit($event); list.search();"
          [placeholder]="'processes' | translate"
          [searchable]="false"
          [clearable]="false"
          [multiple]="false">

          <ng-template ng-label-tmp
            let-item="item">
            {{ hs.processName(item) | translate }}
          </ng-template>

          <ng-template ng-option-tmp
            let-item="item">
            {{ hs.processName(item) | translate }}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-12 col-md-2 mt-2 m-md-auto text-right"
        *ngIf="list.totalCount !== undefined && list.totalCount !== null">
        <span *ngIf="list.totalCount === 0">
          {{ 'no-results' | translate }}
        </span>
        <span *ngIf="list.totalCount > 0">
          {{ list.totalCount }} {{ 'processes' | translate | lowercase }}
        </span>
      </div>
    </div>
    <ng-container *ngIf="showFilters">
      <div class="row mb-2">
        <div class="col">
          <search-input name="search"
            [sm]="true"
            [ngModel]="filters.searchText"
            (ngModelChange)="filters.searchText = $event; list.search();"
            [placeholder]="'search-student-by-name-or-id-num'"></search-input>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12"
          *ngIf="program">
          <ng-select id="programFilter"
            name="programFilter"
            [items]="ps.programs"
            bindValue="_id"
            bindLabel="name"
            style="width: 100%;"
            [ngModel]="filters.programs"
            (ngModelChange)="filters.programs = $event; list.search();"
            [placeholder]="'program' | translate"
            [searchable]="true"
            [clearable]="true"
            [multiple]="true">

            <ng-template ng-label-tmp
              let-item="item"
              let-clear="clear">
              <span class="ng-value-label">
                {{ item.code }}
              </span>
              <span class="ng-value-icon right"
                (click)="clear(item)"
                aria-hidden="true">x</span>
            </ng-template>

            <ng-template ng-option-tmp
              let-item="item">
              {{ item.code }} - {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md pr-md-0">
          <ng-select id="stateFilter"
            name="stateFilter"
            [items]="states"
            [ngModel]="filters.states"
            (ngModelChange)="filters.states = $event; list.search();"
            [placeholder]="'process-state' | translate"
            style="width: 100%;"
            [searchable]="false"
            [clearable]="true"
            [multiple]="true">

            <ng-template ng-label-tmp
              let-item="item"
              let-clear="clear">
              <span class="ng-value-label">
                {{ item | translate}}
              </span>
              <span class="ng-value-icon right"
                (click)="clear(item)"
                aria-hidden="true">x</span>
            </ng-template>

            <ng-template ng-option-tmp
              let-item="item">
              {{ item | translate}}
            </ng-template>

          </ng-select>
        </div>
        <div class="col-12 col-md pl-md-0"
          *ngIf="rd">
          <ng-select id="rdFilter"
            name="rdFilter"
            [ngModel]="filters.rd"
            (ngModelChange)="filters.rd = $event; list.search();"
            [placeholder]="'filter-rd' | translate"
            style="width: 100%;"
            [searchable]="false"
            [clearable]="true">
            <ng-option [value]="RDType.rd99Mod">
              {{ RDType.rd99Mod | translate }}
            </ng-option>
            <ng-option [value]="RDType.rd99">
              {{ RDType.rd99 | translate }}
            </ng-option>
            <ng-option [value]="RDType.rd1393">
              {{ RDType.rd1393 | translate }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </ng-container>
  </form>
  <div class="card">
    <table class="table table-hover m-0 table-sm"
      *ngIf="processes.length > 0">
      <thead>
        <tr class="small">
          <th class="text-center">
            {{ 'request-date' | translate }}
          </th>
          <th *ngIf="processType === Processes.evaluation">
          </th>
          <th *ngIf="processType === Processes.leave">
          </th>
          <th class="text-center">
            {{ 'program' | translate}}
          </th>
          <th>
            {{ 'student' | translate }}
          </th>
          <th>
            {{ 'process-state' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let process of processes"
          class="c-pointer"
          (click)="gotToStudent(process.studentId)">
          <td class="text-center">
            {{ process.createdAt | moment:'DD/MM/YYYY HH:mm' }}
          </td>
          <td class="text-center"
            *ngIf="processType === Processes.evaluation">
            <strong>
              {{ process.year | evaluationYear }}
            </strong>
            - {{ process.period | translate }}
          </td>
          <td *ngIf="processType === Processes.leave">
            {{ process.type | translate}}
          </td>
          <td *ngIf="program && process.student.program"
            class="text-center">
            <span class="text-secondary">
              {{ process.student.program.code }}
            </span>
          </td>
          <td *ngIf="program && process.student.rd === RDType.rd1393"
            class="text-center">
            <span class="text-info">
              RD1393
            </span>
          </td>
          <td>
            {{ process.student | fullname }}
          </td>
          <td>
            {{ process.state | translate }}
            <span class="badge badge-danger ml-1"
              *ngIf="!process.active">
              {{ 'process-inactive' | translate }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <span *ngIf="list.totalCount === 0 || (list.allLoaded && processes.length <= 0)">
    <i>{{ 'no-results' | translate }}</i>
  </span>

</infinite-list>