import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ThesisReportEvaluation } from '../../../../../../../interfaces/IThesisProcess';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';

@Component({
  selector: 'thesis-reports-modal',
  templateUrl: './thesis-reports-modal.component.html',
})
export class ThesisReportsModalComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() open = false;
  @Input() studentId: string;
  @Input() reports: {
    memberId: string,
    memberName: string,
    evaluation: ThesisReportEvaluation,
    docRef: IDocumentRef
  }[]

  ThesisReportEvaluation = ThesisReportEvaluation;

  sending = false;

  constructor(private ss: ServiceHttpService,
    private alerts: PopAlertsService,
    private rs: RefreshService) { }

  ngOnInit() {
  }

  save() {
    if (this.f && this.f.valid) {
      this.sending = true;
      this.ss.editReports(this.studentId, this.reports).subscribe(() => {
        this.open = false;
        this.sending = false;
        this.rs.onStudentChange.next();
        this.alerts.saveSuccess();
      }, () => {
        this.sending = false;
      })
    }
  }

}
