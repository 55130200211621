<li #userDropdown
  class="nav-item dropdown">
  <a class="nav-link dropdown-toggle"
    role="button"
    (click)="dds.toggle('userDropdown', userDropdown)">

    <span class="fa-layers fa-fw">
      <span class="fa-layers fa-fw">
        <i class="far fa-user fa-fw"></i>
      </span>
    </span>

  </a>
  <div class="dropdown-menu dropdown-menu-right user-dropdown"
    [@dropdownAnimation]="dds.show('userDropdown') ? 'in' : 'out'"
    [class.show]="dds.show('userDropdown')">
    <a class="dropdown-item"
      role="button"
      [routerLink]="['/app/preferences']"
      (click)="dds.close('userDropdown')">
      <i class="far fa-cogs"></i>
      {{ 'preferences' | translate }}
    </a>
    <div class="dropdown-divider"></div>
    <a class="dropdown-item c-pointer"
      role="button"
      (click)="logout()">
      <i class="far fa-power-off"></i>
      Cerrar sesión
    </a>
  </div>
</li>