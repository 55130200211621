import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanDeactivateGuard } from '../UI/can-deactivate-guard/can-deactivate-guard.service';
import { RootComponent } from './root.component';
import {
  AcademicCommitteeStudentsComponent,
} from './routes/academic-committee/academic-committee-students/academic-committee-students.component';
import {
  CoordinatorCommitteesComponent,
} from './routes/coordinator/coordinator-committees/coordinator-committees.component';
import {
  CoordinatorEvaluationComponent,
} from './routes/coordinator/coordinator-evaluation/coordinator-evaluation.component';
import { CoordinatorPeriodsComponent } from './routes/coordinator/coordinator-periods/coordinator-periods.component';
import { CoordinatorStudentsComponent } from './routes/coordinator/coordinator-students/coordinator-students.component';
import { DownloadComponent } from './routes/download/download.component';
import { PreferencesComponent } from './routes/preferences/preferences.component';
import { CheckComponent } from './routes/service/check/check.component';
import { ExternalUsersComponent } from './routes/service/external-users/external-users.component';
import { SentEmailsComponent } from './routes/service/sent-emails/sent-emails.component';
import { ServiceEvaluationComponent } from './routes/service/service-evaluation/service-evaluation.component';
import { ServiceHomeComponent } from './routes/service/service-home/service-home.component';
import { ServiceStudentsComponent } from './routes/service/service-students/service-students.component';
import { ServiceUsersListComponent } from './routes/service/service-users-list/service-users-list.component';
import {
  StudentFileActivitiesComponent,
} from './routes/student-file/student-file-activities/student-file-activities.component';
import { StudentFileCDSViewComponent } from './routes/student-file/student-file-cds-view/student-file-cds-view.component';
import {
  StudentFileEvaluationComponent,
} from './routes/student-file/student-file-evaluation/student-file-evaluation.component';
import { StudentFileHomeComponent } from './routes/student-file/student-file-home/student-file-home.component';
import {
  StudentFileProcessesComponent,
} from './routes/student-file/student-file-processes/student-file-processes.component';
import {
  StudentFileThesisViewComponent,
} from './routes/student-file/student-file-thesis-view/student-file-thesis-view.component';
import { StudentFileComponent } from './routes/student-file/student-file.component';
import { ActivitiesComponent } from './routes/student/activities/activities.component';
import { CDSRequestComponent } from './routes/student/cds-request/cds-request.component';
import { CDSViewComponent } from './routes/student/cds-view/cds-view.component';
import { DedicationRequestComponent } from './routes/student/dedication-request/dedication-request.component';
import { DirectionRequestComponent } from './routes/student/direction-request/direction-request.component';
import { DischargeRequestComponent } from './routes/student/discharge-request/discharge-request.component';
import { EvaluationRequestComponent } from './routes/student/evaluation-request/evaluation-request.component';
import { ExtensionRequestComponent } from './routes/student/extension-request/extension-request.component';
import { LeaveRequestComponent } from './routes/student/leave-request/leave-request.component';
import {
  ModifyEvaluationRequestComponent,
} from './routes/student/modify-evaluation-request/modify-evaluation-request.component';
import { StudentProcessesComponent } from './routes/student/procesess/student-processes.component';
import { StudentEvaluationComponent } from './routes/student/student-evaluation/student-evaluation.component';
import { StudentHomeComponent } from './routes/student/student-home/student-home.component';
import { StudentRootComponent } from './routes/student/student-root/student-root.component';
import { ThesisRequestComponent } from './routes/student/thesis-request/thesis-request.component';
import { ThesisViewComponent } from './routes/student/thesis-view/thesis-view.component';
import { SupervisorStudentsComponent } from './routes/supervisor/supervisor-students/supervisor-students.component';
import { TaskCompletedComponent } from './routes/task/task-completed/task-completed.component';
import { TaskComponent } from './routes/task/task.component';
import { TutorStudentsComponent } from './routes/tutor/tutor-students/tutor-students.component';
import { FacultyStudentsComponent } from './routes/secretary/faculty-students/faculty-students.component';
import { EvaluationCommitteeStudentsComponent } from './routes/evaluation-committee/evaluation-committee-students/evaluation-committee-students.component';
import { ExternalUsersFileComponent } from './routes/service/external-users/external-users-file/external-users-file.component';
import { ServiceProgramsComponent } from './routes/service/service-programs/service-programs.component';
import { ProgramFileComponent } from './routes/service/program-file/program-file.component';
import { ProgramFileHomeComponent } from './routes/service/program-file/program-file-home/program-file-home.component';
import { ProgramFileStudentsComponent } from './routes/service/program-file/program-file-students/program-file-students.component';
import { ProgramFileEvaluationComponent } from './routes/service/program-file/program-file-evaluation/program-file-evaluation.component';
import { ProgramFileCommitteesComponent } from './routes/service/program-file/program-file-committees/program-file-committees.component';
import { ServiceFacultiesComponent } from './routes/service/service-faculties/service-faculties.component';
import { FacultyFileComponent } from './routes/service/faculty-file/faculty-file.component';
import { MainRolesComponent } from './routes/service/main-roles/main-roles.component';
import { UserFileComponent } from './routes/service/service-users-list/user-file/user-file.component';
import { QaHomeComponent } from './routes/qa/qa-home/qa-home.component';
import { StLinkComponent } from './routes/st/st-link.component';
import { ServiceProcessesComponent } from './routes/service/service-processes/service-processes.component';
import { StudentFileEmailsComponent } from './routes/student-file/student-file-emails/student-file-emails.component';
import { ProgramFileProfessorsComponent } from './routes/service/program-file/program-file-professors/program-file-professors.component';
import { CoordinatorProfessorsComponent } from './routes/coordinator/coordinator-professors/coordinator-professors.component';
import { RenewalRequestComponent } from './routes/student/renewal-request/renewal-request.component';
import { IndustrialMentionLaunchComponent } from './routes/student-file/industrial-mention-launch/industrial-mention-launch.component';
import { CoordinatorProfessorRequestComponent } from './routes/coordinator/coordinator-professor-request/coordinator-professor-request.component';

const studentFileRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: StudentFileHomeComponent
  },
  {
    path: 'activities',
    component: StudentFileActivitiesComponent
  },
  {
    path: 'cds',
    component: StudentFileCDSViewComponent,
  },
  {
    path: 'cds/industrial/launch',
    component: IndustrialMentionLaunchComponent,
  },
  {
    path: 'thesis',
    component: StudentFileThesisViewComponent
  },
  {
    path: 'processes',
    component: StudentFileProcessesComponent
  },
  {
    path: 'evaluation',
    component: StudentFileEvaluationComponent
  },
  {
    path: 'emails',
    component: StudentFileEmailsComponent
  }
];

const studentRoutes: Routes = [
  {
    path: '',
    component: StudentRootComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: StudentHomeComponent
      },
      {
        path: 'activities',
        component: ActivitiesComponent
      },
      {
        path: 'cds',
        component: CDSViewComponent
      },
      {
        path: 'cds/request',
        component: CDSRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'cds/dedication/request',
        component: DedicationRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'cds/direction/request',
        component: DirectionRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'processes',
        component: StudentProcessesComponent
      },
      {
        path: 'processes/request-extension',
        component: ExtensionRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'processes/request-leave',
        component: LeaveRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'processes/request-discharge/:leaveId',
        component: DischargeRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'processes/request-renewal/:leaveId',
        component: RenewalRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'evaluation',
        component: StudentEvaluationComponent
      },
      {
        path: 'evaluation/request',
        component: EvaluationRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'evaluation/modify/:processId',
        component: ModifyEvaluationRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'thesis',
        component: ThesisViewComponent
      },
      {
        path: 'thesis/request',
        component: ThesisRequestComponent,
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  },
]

const serviceRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: ServiceHomeComponent
  },
  {
    path: 'processes',
    component: ServiceProcessesComponent
  },
  {
    path: 'emails',
    component: SentEmailsComponent
  },
  {
    path: 'students',
    component: ServiceStudentsComponent
  },
  {
    path: 'students/:studentId',
    component: StudentFileComponent,
    children: studentFileRoutes
  },
  {
    path: 'users',
    component: ServiceUsersListComponent
  },
  {
    path: 'users/:userId',
    component: UserFileComponent
  },
  {
    path: 'externals',
    component: ExternalUsersComponent
  },
  {
    path: 'externals/:externalId',
    component: ExternalUsersFileComponent
  },
  {
    path: 'roles',
    component: MainRolesComponent
  },
  {
    path: 'evaluation',
    component: ServiceEvaluationComponent
  },
  {
    path: 'check',
    component: CheckComponent
  },
  {
    path: 'programs',
    component: ServiceProgramsComponent
  },
  {
    path: 'programs/:programId',
    component: ProgramFileComponent,
    children: [
      {
        path: '',
        redirectTo: 'home'
      },
      {
        path: 'home',
        component: ProgramFileHomeComponent
      },
      {
        path: 'students',
        component: ProgramFileStudentsComponent
      },
      {
        path: 'students/:studentId',
        component: StudentFileComponent,
        children: studentFileRoutes
      },
      {
        path: 'evaluation',
        component: ProgramFileEvaluationComponent
      },
      {
        path: 'committees',
        component: ProgramFileCommitteesComponent
      },
      {
        path: 'professors',
        component: ProgramFileProfessorsComponent
      }
    ]
  },
  {
    path: 'faculties',
    component: ServiceFacultiesComponent
  },
  {
    path: 'faculties/:facultyId',
    component: FacultyFileComponent
  }
]

const coordinatorRoutes: Routes = [
  {
    path: '',
    redirectTo: 'students',
    pathMatch: 'full'
  },
  {
    path: 'students',
    component: CoordinatorStudentsComponent
  },
  {
    path: 'students/:studentId',
    component: StudentFileComponent,
    children: studentFileRoutes
  },
  {
    path: 'evaluation',
    component: CoordinatorEvaluationComponent
  },
  {
    path: 'committees',
    component: CoordinatorCommitteesComponent
  },
  {
    path: 'periods',
    component: CoordinatorPeriodsComponent
  },
  {
    path: 'professors',
    component: CoordinatorProfessorsComponent,
  },
  {
    path: 'professors/request',
    component: CoordinatorProfessorRequestComponent,
    canDeactivate: [CanDeactivateGuard]
  }
]

const academicCommitteeRoutes: Routes = [
  {
    path: '',
    redirectTo: 'students',
    pathMatch: 'full'
  },
  {
    path: 'students',
    component: AcademicCommitteeStudentsComponent
  },
  {
    path: 'students/:studentId',
    component: StudentFileComponent,
    children: studentFileRoutes
  },
]

const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    children: [
      {
        path: 'task/:taskId',
        component: TaskComponent
      },
      {
        path: 'task-completed',
        component: TaskCompletedComponent
      },
      {
        path: 'download/:documentId',
        component: DownloadComponent
      },
      {
        path: 'st/:studentId',
        component: StLinkComponent
      },
      {
        path: 'st/:studentId/:tab',
        component: StLinkComponent
      },
      {
        path: 'student',
        children: studentRoutes
      },
      {
        path: 'tutor/students',
        component: TutorStudentsComponent
      },
      {
        path: 'tutor/students/:studentId',
        component: StudentFileComponent,
        children: studentFileRoutes
      },
      {
        path: 'ecMember/students',
        component: EvaluationCommitteeStudentsComponent
      },
      {
        path: 'ecMember/students/:studentId',
        component: StudentFileComponent,
        children: studentFileRoutes
      },
      {
        path: 'supervisor/students',
        component: SupervisorStudentsComponent
      },
      {
        path: 'supervisor/students/:studentId',
        component: StudentFileComponent,
        children: studentFileRoutes
      },
      {
        path: 'service',
        children: serviceRoutes
      },
      {
        path: 'qa',
        component: QaHomeComponent
      },
      {
        path: 'qa/students/:studentId',
        component: StudentFileComponent,
        children: studentFileRoutes
      },
      {
        path: 'coordinator',
        children: coordinatorRoutes
      },
      {
        path: 'academic-committee',
        children: academicCommitteeRoutes
      },
      {
        path: 'preferences',
        component: PreferencesComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'secretary/students',
        component: FacultyStudentsComponent
      },
      {
        path: 'secretary/students/:studentId',
        component: StudentFileComponent,
        children: studentFileRoutes
      },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RootRoutingModule { }