import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IStudent } from '../../../../../../interfaces/IStudent';
import { StudentHttpService } from '../../../../data-services/student-http.service';
import { UserService } from '../../../../user/user.service';
import { UserHttpService } from '../../../../data-services/user-http.service';
import { IEvaluationCommittee } from '../../../../../../interfaces/IEvaluationCommittee';
import { HelperService } from '../../../../shared/helper-service/helper.service';

@Component({
  selector: 'evaluation-committee-students',
  templateUrl: './evaluation-committee-students.component.html'
})
export class EvaluationCommitteeStudentsComponent implements OnInit {
  committees: IEvaluationCommittee[] = [];
  studentsMap: { [cId: string]: IStudent[] } = {};

  Math = Math;

  constructor(public uhs: UserHttpService,
    public us: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public hs: HelperService) { }

  ngOnInit(): void {
    this.uhs.fetchMyEvaluationCommittees(this.us.selectedRole.groupId).subscribe((res) => {
      this.studentsMap = res.students;
      this.committees = res.committees.map(c => {
        return {
          ...c,
          students: this.studentsMap[c._id]
        }
      });

    })
  }

  goToStudent(studentId) {
    void this.router.navigate([`${studentId}`], { relativeTo: this.route });
  }

}
