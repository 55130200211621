<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <ng-container *ngIf="process.justification">
    <div class="small text-muted mb-1 mt-2">
      {{ 'justification' | translate }}
    </div>
    <div *ngFor="let doc of process.justification"
      class="mb-1">
      <document-link [document]="doc"></document-link>
    </div>
  </ng-container>

  <div *ngIf="process.student.extensions.length > 0">
    <div class="small text-muted mb-1 mt-2">{{ 'previously-granted-extensions' | translate }}</div>
    <extensions-list [extensions]="process.student.extensions"
      [showOrder]="process.student.rd === RDType.rd99"></extensions-list>
  </div>

  <div class="w-100 my-4"></div>

  <form #f="ngForm"
    (ngSubmit)="send()"
    *ngIf="canComplete">

    <div class="form-group text-center">
      <div class="btn-group btn-block">
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-danger]="validate === null || validate === true"
          [class.btn-danger]="validate === false"
          (click)="validate = false">
          {{ 'deny' | translate }}
        </button>
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-success]="validate === null || validate === false"
          [class.btn-success]="validate === true"
          (click)="validate = true">
          {{ 'validate' | translate }}
        </button>
      </div>
    </div>

    <div class="form-group"
      *ngIf="validate === false">
      <label for="denialMsg">
        {{ 'denial-reason' | translate }}
      </label>
      <textarea id="denialMsg"
        name="denialMsg"
        #denialMsgInput="ngModel"
        [(ngModel)]="denialMsg"
        [required]="true"
        [placeholder]="'denial-reason-placeholder' | translate"
        [class.is-invalid]="f.submitted && denialMsgInput.invalid"
        class="form-control"></textarea>
      <div class="text-right small text-muted">
        {{ denialMsg ? denialMsg.length : 0}}/400
      </div>
      <validation-errors [show]="f.submitted"
        [control]="denialMsgInput"></validation-errors>
    </div>

    <div class="form-group text-right"
      *ngIf="validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>
  </form>

  <div *ngIf="canComplete === false"
    class="alert alert-info">
    <i class="far fa-info"></i>
    {{ 'only-coordinator-can-complete' | translate }}
  </div>
</ng-container>