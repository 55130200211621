<div class="container-lg container-fluid">
  <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-12 mt-3">

    <div class="card"
      *ngIf="!loading">
      <div class="card-body text-center">

        <div *ngIf="doc"
          class="w-100 text-left">
          <document-link [document]="doc"></document-link>
        </div>

        <div *ngIf="notFound">
          <i class="fa fa-exclamation-triangle text-warning mr-1"></i>
          {{ 'document-not-found' | translate }}
        </div>

      </div>

    </div>
  </div>