import { Component, Input, OnInit } from '@angular/core';

import { IDirectionProcess } from '../../../../../../../interfaces/IDirectionProcess';

@Component({
  selector: 'direction-process-data-view',
  templateUrl: './direction-process-data-view.component.html'
})
export class DirectionProcessDataViewComponent implements OnInit {
  @Input() process: IDirectionProcess;

  constructor() { }

  ngOnInit() {
  }

}
