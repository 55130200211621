import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { StringUtils } from '../../../../utils/StringUtils';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchInputComponent),
      multi: true
    }
  ]
})
export class SearchInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
  searchText: string;

  @Input() placeholder = 'search';
  @Input() sm = false;
  @Input() ignoreComma = true;

  keyupObs = new Subject<string>();
  keyupSub: Subscription;

  constructor() { }

  ngOnInit() {
    this.keyupSub = this.keyupObs.pipe(debounceTime(300)).subscribe((searchText) => {
      if (this.ignoreComma)
        this.onChange(StringUtils.replaceAll(searchText, ',', ''));
      else
        this.onChange(searchText);
    })
  }

  ngOnDestroy(): void {
    if (this.keyupSub) this.keyupSub.unsubscribe();
  }

  writeValue(value: string): void {
    this.searchText = value;
  }

  onChange = (_: any) => { };
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouch = () => { };
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

}
