import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IExternalUser } from '../../../interfaces/IExternalUser';
import { FinalLeaveReason, IStudent, StudentStateType } from '../../../interfaces/IStudent';
import { Processes } from '../../../interfaces/ITask';
import { IUser } from '../../../interfaces/IUser';
import { IDocumentRef } from '../../../interfaces/IDocument';
import { LeaveType } from '../../../interfaces/ILeaveProcess';
import { IThesisProcessData, ThesisReportEvaluation } from '../../../interfaces/IThesisProcess';
import { PermissionsType } from '../../../interfaces/IPermissions';

@Injectable()
export class ServiceHttpService {

  editMode = false;

  constructor(private http: HttpClient) {
  }

  fetchStudents(params: { programsIds?: string[], states?: StudentStateType[], searchText?: string, page?: number, size?: number }): Observable<IStudent[]> {
    return this.http.post<IStudent[]>(`/api/service/students`, { ...params });
  }

  fetchUsers(params: { searchText?: string, page?: number, size?: number }): Observable<IUser[]> {
    return this.http.get<IUser[]>(`/api/service/users`, { params });
  }

  fetchUser(userId: string): Observable<{ user: IUser, relations: { studentId: string, name: string, program: string, role: string, group: string, groupId?: string, state: StudentStateType; altStates: StudentStateType[]; }[] }> {
    return this.http.get<{ user: IUser, relations: { studentId: string, name: string, program: string, role: string, group: string, groupId?: string, state: StudentStateType; altStates: StudentStateType[]; }[] }>(`/api/service/users/${userId}`);
  }

  refreshToken(taskId: string): Observable<{ token: string }> {
    return this.http.get<{ token: string }>(`/api/service/refreshToken/${taskId}`);
  }

  fetchExternals(params: { searchText?: string, page?: number, size?: number }): Observable<IExternalUser[]> {
    return this.http.get<IExternalUser[]>(`/api/service/externals`, { params });
  }

  fetchExternal(userId: string): Observable<{ user: IExternalUser, relations: { studentId: string, name: string, program: string, role: string }[] }> {
    return this.http.get<{ user: IExternalUser, relations: { studentId: string, name: string, program: string, role: string }[] }>(`/api/service/externals/${userId}`);
  }

  updateExternal(user: IExternalUser): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/service/externals/${user._id}`, { user });
  }

  editProgram(studentId: string, programId: string, facultyId: string, lineOfResearchId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: true }>(`/api/service/editProgram`, { studentId, programId, facultyId, lineOfResearchId })
  }

  removePermanentLeave(studentId): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: true }>(`/api/service/removePermanentLeave`, { studentId })
  }

  savePermanentLeave(data: { studentId: string, justification: IDocumentRef[], start: Date, sendMail: boolean, mailText?: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: true }>(`/api/service/savePermanentLeave`, data)
  }

  removeFinalLeave(studentId): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: true }>(`/api/service/removeFinalLeave`, { studentId })
  }

  saveFinalLeave(data: { studentId: string, justification: IDocumentRef[], start: Date, reason: FinalLeaveReason }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: true }>(`/api/service/saveFinalLeave`, data)
  }

  findLdap(params: { firstName?: string, lastName?: string, idNum?: string, uid?: string, email?: string }): Observable<any[]> {
    return this.http.post<any[]>('/api/service/findLdap', { params });
  }

  createUser(user: { firstName: string, lastName: string, idNum: string, uid: string, email: string }): Observable<{ userId: string }> {
    return this.http.post<{ userId: string }>('/api/service/createUser', { user });
  }

  getEnrollments(date: Date) {
    return this.http.post('/api/service/getEnrollments', { date });
  }

  getStudentEnrollments(recordNum: number) {
    return this.http.post('/api/service/getStudentEnrollments', { recordNum });
  }

  importEnrollments(date: Date) {
    return this.http.post('/api/service/importEnrollments', { date });
  }

  deadlinesReminders() {
    return this.http.post('/api/service/deadlinesReminders', {});
  }

  deadlinesRemindersTest() {
    return this.http.post('/api/service/deadlinesRemindersTest', {});
  }

  updateStudentsStates() {
    return this.http.post('/api/service/updateStudentsStates', {});
  }

  leavesReminders() {
    return this.http.post('/api/service/leavesReminders', {});
  }

  sendTestEmail(testEmailTo: string) {
    return this.http.post('/api/service/sendTestEmail', { to: testEmailTo });
  }

  evaluateNoShow() {
    return this.http.post('/api/service/evaluateNoShow', {});
  }

  launchEvaluationList() {
    return this.http.post('/api/service/launchEvaluationList', {});
  }

  sendMidEvaluationReminders() {
    return this.http.post('/api/service/sendMidEvaluationReminders', {});
  }

  sendPendingEvaluationReminders() {
    return this.http.post('/api/service/sendPendingEvaluationReminders', {});
  }

  renewExternalTasksToken() {
    return this.http.post('/api/service/renewExternalTasksToken', {});
  }

  checkDeadlinesAndUpdate() {
    return this.http.post('/api/service/checkDeadlinesAndUpdate', {});
  }

  regenerateThesisProcessRequestDoc(processId: string) {
    return this.http.post('/api/service/regenerateThesisProcessRequestDoc', { processId });
  }

  manuallyEndProcess(processId: string, processType: Processes): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/manuallyEndProcess', {
      processId, processType
    });
  }

  checkStudentEnrollments(studentId: string) {
    return this.http.post('/api/service/checkStudentEnrollments', { studentId });
  }

  saveExpenseDocDate(studentId: string, data: {
    ev: number,
    evId: string,
    expenseDocDate: Date,
    expenseDocNote: Date
  }): Observable<{ ok: true }> {
    return this.http.put<{ ok: true }>(`/api/service/saveExpensesDocDate/${studentId}`, data);
  }

  addExtension(data: {
    studentId: string,
    justification: IDocumentRef[],
    date: Date
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/extensions/add', data);
  }

  editExtension(data: {
    studentId: string,
    extensionId: string,
    justification: IDocumentRef[],
    date: Date
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/extensions/edit', data);
  }

  removeExtension(data: {
    studentId: string,
    extensionId: string,
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/extensions/remove', data);
  }

  addLeave(data: {
    studentId: string,
    type: LeaveType,
    justification: IDocumentRef[],
    start: Date,
    end: Date,
    estimatedEnd: Date,
    mustEvaluate: boolean
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/leaves/add', data);
  }

  editLeave(data: {
    studentId: string,
    leaveId: string,
    justification: IDocumentRef[],
    start: Date,
    end: Date,
    estimatedEnd: Date,
    mustEvaluate: boolean
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/leaves/edit', data);
  }

  removeLeave(data: {
    studentId: string,
    leaveId: string,
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/leaves/remove', data);
  }

  addDisability(data: {
    studentId: string,
    justification: IDocumentRef[],
    start: Date,
    end: Date,
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/disability/add', data);
  }

  editDisability(data: {
    studentId: string,
    disabilityId: string,
    justification: IDocumentRef[],
    start: Date,
    end: Date,
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/disability/edit', data);
  }

  removeDisability(data: {
    studentId: string,
    disabilityId: string,
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/disability/remove', data);
  }

  editThesisProccessing(studentId: string, data: IThesisProcessData): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/service/editThesisProccessing/${studentId}`, { data });
  }

  editThesisDefence(studentId: string, data): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/service/editThesisDefence/${studentId}`, { data });
  }

  editReports(studentId: string, reports: {
    memberId: string,
    memberName: string,
    evaluation: ThesisReportEvaluation,
    docRef: IDocumentRef
  }[]): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/service/editThesisReports/${studentId}`, { reports });
  }

  saveVideoConference(data: {
    studentId: string,
    videoConference: boolean,
    videoConferenceDocs: IDocumentRef[]
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/videoconferece/save', data);
  }

  addExtraExtension(data: {
    studentId: string,
    start: Date,
    end: Date,
    description: string,
    justification: IDocumentRef[],
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/extraExtensions/add', data);
  }

  editExtraExtension(data: {
    studentId: string,
    extensionId: string,
    start: Date,
    end: Date,
    description: string,
    justification: IDocumentRef[],
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/extraExtensions/edit', data);
  }

  removeExtraExtension(data: {
    studentId: string,
    extensionId: string,
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/extraExtensions/remove', data);
  }

  editEvaluationProcess(processId: string, data: {
    researchPlan: IDocumentRef,
    activitiesIds: string[],
    evaluationCommitteeId?: string,
    reports: IDocumentRef[],
  }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>(`/api/service/editEvaluationProcess/${processId}`, data);
  }

  getRoles(): Observable<{
    postgraduateCommitteeSecretary: IUser,
    postgraduateCommitteePresident: IUser,
    doctoralSchoolPrincipal: IUser,
    serviceUsers: IUser[],
    qaUsers: IUser[]
  }> {
    return this.http.get<{
      postgraduateCommitteeSecretary: IUser,
      postgraduateCommitteePresident: IUser,
      doctoralSchoolPrincipal: IUser,
      serviceUsers: IUser[],
      qaUsers: IUser[]
    }>('/api/service/roles');
  }

  setRoles(data: {
    postgraduateCommitteeSecretaryId: string,
    postgraduateCommitteePresidentId: string,
    doctoralSchoolPrincipalId: string,
    serviceUsers: string[]
    qaUsers: string[]
  }): Observable<{ ok: boolean }> {
    return this.http.post<{
      ok: boolean
    }>('/api/service/roles', data);
  }

  setCustomPermissions(studentId: string, endDate: Date, permissions: PermissionsType[]): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>('/api/service/setCustomPermissions/' + studentId, { endDate, permissions });
  }

  removeCustomPermissions(studentId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>('/api/service/removeCustomPermissions/' + studentId, {});
  }

  checkAnnualEnrollments() {
    return this.http.post('/api/service/checkAnnualEnrollments', {});
  }

  changeExternalEvaluator(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>('/api/service/changeExternalEvaluator/' + taskId, {});
  }

  editTransfer(data: { studentId: string, transfer: boolean, transferDocs?: IDocumentRef[], transferFirstEnrollment?: Date, transferUniversity?: string }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/transfer/edit', data);
  }

  copyStudentsData(data: { from: string, to: string }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/copyStudentsData', data);
  }

  permanentLeaveToNegativeEvaluationsStudents(): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/permanentLeaveToNegativeEvaluationsStudents', {});
  }

  updateTUTroles(): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/updateTUTroles', {});
  }

  deadlinesRemindersLaunch(date: Date): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/deadlinesRemindersLaunch', { date });
  }

  deadlineStudentsToPermanentLeave(): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/deadlineStudentsToPermanentLeave', {});
  }

  deactivatePermanentLeaveStudentsProcesses(): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/deactivatePermanentLeaveStudentsProcesses', {});
  }

  saveOnlyCoSupervisor(data: { userId: string, onlyCoSupervisor: boolean }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/saveOnlyCoSupervisor', data);
  }

  getUserExtraData(userId: string): Observable<{ onlyCoSupervisor: boolean, cpAgreement: IDocumentRef }> {
    return this.http.get<{ onlyCoSupervisor: boolean, cpAgreement: IDocumentRef }>(`/api/service/getUserExtraData/${userId}`);
  }

  saveNewUid(data: { userId: string, newUid: string }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/saveNewUid', data);
  }

  saveDocuments(data: { userId: string, cpAgreement: IDocumentRef }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/saveDocuments', data);
  }

  importProfessors(csv: IDocumentRef): Observable<{ notFoundRows: any[] }> {
    return this.http.post<{ notFoundRows: any[] }>('/api/professors/importProfessors', { csv });
  }

  launchCheckLeavesRenewals(): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/launchCheckLeavesRenewals', {});
  }

  updateUserName(data: { userId: string }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/updateUserName', data);
  }

  professorProcessDates(start: Date, end: Date): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/professorProcessDates', { start, end });
  }

  closeProfessorProcess(): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('/api/service/closeProfessorProcess', {});
  }

}