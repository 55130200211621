<div class="container mt-4">
  <div class="row">
    <div class="col">

      <mail-time-email *ngIf="email"
        [email]="email"
        [printMode]="true"></mail-time-email>

    </div>
  </div>
</div>