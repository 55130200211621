<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card">
      <div class="card-body"
        *ngIf="sr.student">
        <ng-container *ngIf="!requested">

          <h5 class="card-title">
            {{ 'process.discharge.request.name' | translate }}
          </h5>

          <p [innerHTML]="'process.discharge.request.desc' | translate">
          </p>

          <div class="mt-2 mb-3"
            *ngIf="leave">
            <div class="small text-muted mb-1 mt-2">{{ 'MEDICAL' | translate }}</div>
            <div class="list-group-item d-flex justify-content-between align-items-center">
              <div *ngIf="leave.justification">
                <div *ngFor="let doc of leave.justification">
                  <document-link [document]="doc"></document-link>
                </div>
              </div>
              <div>
                {{ leave.start | moment:'D/M/YY' }}
              </div>
            </div>
          </div>

          <form #f="ngForm"
            (ngSubmit)="send()">

            <div class="form-group">
              <label for="endDate">
                {{ 'end-date' | translate }}
              </label>

              <date-picker id="endDate"
                name="endDate"
                required="true"
                [placeholder]="'end-date' | translate"
                [isInvalid]="endDateInput.invalid && f.submitted"
                #endDateInput="ngModel"
                [time]="false"
                [(ngModel)]="end"> </date-picker>
              <validation-errors [control]="endDateInput"
                [show]="f.submitted"></validation-errors>
              <span class="invalid-feedback d-inline"
                *ngIf="end && !validDateRange()">
                {{ 'end-date-must-be-later-start-date' | translate }}
              </span>
            </div>

            <div class="form-group">
              <label>
                {{ 'justification' | translate }}
              </label>
              <validation-errors [show]="f.submitted"
                [control]="justificationInput"></validation-errors>
              <files-queue name="justification"
                required
                #justificationInput="ngModel"
                [(ngModel)]="justification"></files-queue>
            </div>

            <div class="form-group text-right">
              <div class="d-block invalid-feedback mb-2"
                *ngIf="f.invalid && f.submitted">
                {{ 'check-errors' | translate }}
              </div>

              <button class="btn btn-primary"
                type="submit"
                [disableButton]="disabled || sending"
                [disableButtonIcon]="sending ? 'spin' : 'ban'">
                {{ 'send' | translate}}
              </button>
            </div>
          </form>
        </ng-container>
        <div class="w-100 text-center"
          *ngIf="requested">
          <h5 class="card-title m-0">
            <i class="far fa-check text-success mr-1"></i>
            {{ 'request-sended' | translate }}
          </h5>

          <div *ngIf="doc"
            class="mt-4 w-100 text-left">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>