<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="program-professors"
        icon="users">
      </section-title>

    </div>
  </div>

  <program-professors *ngIf="program"
    [program]="program"></program-professors>
</div>