import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IRenewalProcess } from '../../../../../../../interfaces/IRenewalProcess';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { ILeaves } from '../../../../../../../interfaces/IStudent';
import { ITask } from '../../../../../../../interfaces/ITask';
import { RenewalHttpService } from '../../../../../data-services/renewal-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'renewal-service-validation',
  templateUrl: './renewal-service-validation.component.html'
})
export class RenewalServiceValidationComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  process: IRenewalProcess;

  justification: IDocumentRef[] = [];
  newEstimatedEnd: Date;
  leaveId: string;
  leave: ILeaves;

  denialMsg = '';
  validate = null;

  constructor(private renewal: RenewalHttpService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.renewal.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      this.justification = this.process.justification;
      this.newEstimatedEnd = this.process.newEstimatedEnd;
      this.leaveId = this.process.leaveId;
      this.leave = this.process.student.leaves.find(l => l._id === this.leaveId);
    })
  }

  manageSend() {
    if (this.f.invalid) {
      this.sendEnd.next();
      return;
    }

    if (this.validate) {
      this.renewal.serviceValidation(this.task._id).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.renewal.serviceDeny(this.task._id, { denialMsg: this.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
