import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { ITask } from '../../../../../../../interfaces/ITask';
import { IThesisProcess } from '../../../../../../../interfaces/IThesisProcess';
import { ThesisHttpService } from '../../../../../data-services/thesis-http.service';
import { DocumentService } from '../../../../../shared/document-service/document.service';
import { HelperService } from '../../../../../shared/helper-service/helper.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'thesis-student-review-request',
  templateUrl: './thesis-student-review-request.component.html'
})
export class ThesisStudentReviewRequestComponent extends AbstractTaskComponent implements OnInit {

  @Input() task: ITask;

  doc: IDocumentRef;
  reviewData = false;


  process: IThesisProcess;

  constructor(
    private thesis: ThesisHttpService,
    private router: Router,
    private ds: DocumentService,
    public hs: HelperService) {
    super();
  }

  ngOnInit() {
    this.thesis.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  reviewStep(event) {
    this.process.data = event;
    this.reviewData = true;
  }

  manageSend() {
    if (this.process.data.cotutelle && this.process.data.cotutelleDefenceOut) {
      this.process.data.thesisCommittee = null;
      this.process.data.externalEvaluators = null;
    }

    this.thesis.studentRequestReview(this.task._id, this.process.data).subscribe((docRef) => {
      this.sendEnd.next();
      this.doc = docRef;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);

      void this.router.navigate(['/app/task-completed'], { queryParams: { docRef: this.doc.documentRef } });
    })
  }

}
