import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IActivity } from '../../../../../../interfaces/IActivity';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { IEvaluationProcess } from '../../../../../../interfaces/IEvaluationProcess';
import { EvaluationHttpService } from '../../../../data-services/evaluation-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { StudentHttpService } from '../../../../data-services/student-http.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'modify-evaluation-request',
  templateUrl: './modify-evaluation-request.component.html',
})
export class ModifyEvaluationRequestComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {

  processId: string;
  process: IEvaluationProcess;

  requested = false;

  activities: IActivity[] = [];

  doc: IDocumentRef;

  constructor(private ss: StudentHttpService,
    private route: ActivatedRoute,
    public sr: StudentRootService,
    private ds: DocumentService,
    private evs: EvaluationHttpService,
    private ts: TranslateService,
    private cds: ConfirmationDialogService,
    private refresh: RefreshService) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {

      this.processId = params.processId;
      this.evs.findProcessById(this.processId).subscribe((p) => {
        this.process = p;

      });

      this.ss.fetchAssessableActivities(this.sr.student._id).subscribe((activities) => {
        this.activities = activities;
      })
    });
  }

  manageSend(event) {
    this.evs.modify(this.sr.student._id, { ...event, processId: this.process._id }).subscribe((docRef) => {
      this.sendEnd.next();
      this.refresh.onStudentChange.next();
      this.requested = true;
      this.doc = docRef;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
    })
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

}
