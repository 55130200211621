import { Component, OnInit } from '@angular/core';

import { StudentsSearchService } from '../../components/students/students-search/students-search.service';

@Component({
  selector: 'coordinator-menu',
  templateUrl: './coordinator-menu.component.html'
})
export class CoordinatorMenuComponent implements OnInit {

  constructor(public ss: StudentsSearchService) { }

  ngOnInit() {
  }

}
