import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IMessage } from '../../../../../interfaces/IMessage';

@Component({
  selector: 'messages-list',
  templateUrl: 'messages-list.component.html'
})
export class MessagesListComponent implements OnInit {
  @Input() messages: IMessage[] = [];

  @Output() onMarkAsRead = new EventEmitter<string>();
  @Output() onMarkAsUnread = new EventEmitter<string>();
  @Output() onGoToTask = new EventEmitter<string>();

  expanded: { [id: string]: boolean } = {};

  constructor() { }

  ngOnInit() { }

  expand(messageId) {
    this.expanded[messageId] = true;
  }

  toggleRead(message: IMessage) {
    if (message.read) {
      this.onMarkAsUnread.emit(message._id);
    } else {
      this.onMarkAsRead.emit(message._id);
    }
  }
}