<!-- <br>
UserID: {{ userId }}
<br>
notFound: {{ notFound ? 'true' : 'false' }}
<br>
foundUser: {{ foundUser }}
<br>
userInfo: {{ userInfo | json }} -->
<user-info-view [userInfo]="userInfo"
  [showReason]="false"
  [showIdNum]="true"></user-info-view>
<text-info icon="envelope"
  *ngIf="userId && isNew && favEmail && favEmail !== foundUser.email">
  <a class="ml-1"
    href="mailto:{{favEmail}}">{{ favEmail }}</a>
</text-info>

<div *ngIf="userId"
  class="mb-3">
  <span class="badge badge-success">
    <i class="far fa-check mr-1"></i>
    {{ 'duma-user-assigned' | translate }}
  </span>
  <span class="badge badge-info ml-1"
    *ngIf="isNew">
    {{ 'new' | translate }}
  </span>
  <a [routerLink]="null"
    role="button"
    [queryParamsHandling]="'merge'"
    class="text-muted"
    (click)="resetFind()">
    <small class="ml-2">
      <i class="far fa-times"></i>
      <span class="d-none d-md-inline">
        {{ 'select-again' | translate }}
      </span>
    </small>
  </a>
</div>

<div *ngIf="!userId"
  class="mb-3">
  <div class="input-group input-group-sm">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <i class="far fa-envelope fa-fw"></i>
      </span>
    </div>
    <input type="text"
      class="form-control"
      [placeholder]="'search-by-email' | translate"
      name="email"
      [(ngModel)]="searchText"
      #emailInput="ngModel"
      (blur)="search()"
      (keydown.enter)="$event.preventDefault()"
      (keyup.enter)="$event.preventDefault(); search()">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary"
        type="button"
        (click)="search()">
        <i class="far fa-search fa-fw"></i>
      </button>
    </div>
  </div>

  <div *ngIf="notFound"
    class="mt-2 text-danger small">
    {{ 'duma-user-not-found' | translate }}
  </div>

  <div *ngIf="!notFound && foundUser">
    <div class="text-success">
      {{ 'duma-user-found' | translate:{email:foundUser.email} }}
    </div>

    <user-info-view [userInfo]="foundUser"
      [showReason]="false"></user-info-view>

    <div class="form-group form-check mb-1">
      <input type="checkbox"
        class="form-check-input"
        name="isNew"
        id="isNew"
        [(ngModel)]="isNew"
        (ngModelChange)="isNewChange.emit($event)">
      <label class="form-check-label"
        for="isNew">
        {{ 'new-duma-user' | translate }}
      </label>
    </div>
    <div class="text-muted small">
      <span *ngIf="favEmail !== foundUser.email">
        {{ 'new-duma-user-help1' | translate:{email:favEmail} }}
      </span>
      <span *ngIf="favEmail === foundUser.email">
        {{ 'new-duma-user-help2' | translate }}
      </span>
    </div>

    <div class="mt-2">
      <button class="btn btn-outline-secondary btn-sm"
        type="button"
        (click)="assign()">{{ 'assign' | translate }}</button>
    </div>

  </div>
</div>

<user-info-view *ngIf="foundUser && userId && notFound"
  [userInfo]="foundUser"
  [showIdNum]="true"
  [showReason]="false"></user-info-view>