import { Component, OnInit } from '@angular/core';
import { StudentsSearchService } from '../../components/students/students-search/students-search.service';

@Component({
  selector: 'secretary-menu',
  templateUrl: './secretary-menu.component.html',
})
export class SecretaryMenuComponent implements OnInit {

  constructor(public ss: StudentsSearchService) { }

  ngOnInit() {
  }

}
