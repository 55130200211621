<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <div class="mt-2 mb-3"
    *ngIf="leave">
    <div class="small text-muted mb-1 mt-2">{{ 'MEDICAL' | translate }}</div>
    <div class="list-group-item d-flex justify-content-between align-items-center">
      <div *ngIf="leave.justification">
        <div *ngFor="let doc of leave.justification">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
      <div>
        {{ leave.start | moment:'D/M/YY' }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="process.justification">
    <ng-container *ngIf="newEstimatedEnd">
      <div class="small text-muted mb-1 mt-2">
        {{ 'new-estimated-end-date' | translate }}
      </div>
      <div class="mb-1">
        {{ newEstimatedEnd | moment:'DD/MM/YYYY' }}
      </div>
    </ng-container>

    <div class="small text-muted mb-1 mt-2">
      {{ 'justification' | translate }}
    </div>
    <div *ngFor="let doc of process.justification"
      class="mb-1">
      <document-link [document]="doc"></document-link>
    </div>

  </ng-container>

  <div class="w-100 my-4"></div>

  <form #f="ngForm"
    (ngSubmit)="send()">

    <div class="form-group text-center">
      <div class="btn-group btn-block">
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-danger]="validate === null || validate === true"
          [class.btn-danger]="validate === false"
          (click)="validate = false">
          {{ 'deny' | translate }}
        </button>
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-success]="validate === null || validate === false"
          [class.btn-success]="validate === true"
          (click)="validate = true">
          {{ 'validate' | translate }}
        </button>
      </div>
    </div>

    <div class="form-group"
      *ngIf="validate === false">
      <label for="denialMsg">
        {{ 'denial-reason' | translate }}
      </label>
      <textarea id="denialMsg"
        name="denialMsg"
        #denialMsgInput="ngModel"
        [(ngModel)]="denialMsg"
        [required]="true"
        [placeholder]="'denial-reason-placeholder' | translate"
        [class.is-invalid]="f.submitted && denialMsgInput.invalid"
        class="form-control"></textarea>
      <div class="text-right small text-muted">
        {{ denialMsg ? denialMsg.length : 0}}/400
      </div>
      <validation-errors [show]="f.submitted"
        [control]="denialMsgInput"></validation-errors>
    </div>

    <div class="form-group text-right"
      *ngIf="validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>
  </form>
</ng-container>