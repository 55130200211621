import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { IEvaluationProcess, TKEvaluation } from '../../../../../../../interfaces/IEvaluationProcess';
import { EvaluationType, RDType } from '../../../../../../../interfaces/IStudent';
import { ITask } from '../../../../../../../interfaces/ITask';
import { EvaluationHttpService } from '../../../../../data-services/evaluation-http.service';
import { UserService } from '../../../../../user/user.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'evaluation-committee-evaluation',
  templateUrl: './evaluation-committee-evaluation.component.html',
})
export class EvaluationCommitteeEvaluationComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  process: IEvaluationProcess;

  evaluation: EvaluationType;

  presidentId: string;
  secretaryId: string;
  memberId: string;

  report: IDocumentRef = null;

  EvaluationType = EvaluationType;
  RDType = RDType;

  constructor(private evs: EvaluationHttpService,
    public us: UserService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.evs.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      if (this.task.taskKey === TKEvaluation.confirmationBySecretary) {
        this.evaluation = process.evaluation;
        this.presidentId = process.presidentId;
        this.secretaryId = process.secretaryId;
        this.memberId = process.memberId;
        this.report = process.committeeReport;
      }
    })
  }

  assignPresident(userId) {
    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.presidentId = userId;
  }

  assignSecretary(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.secretaryId = userId;
  }

  assignMember(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    this.memberId = userId;
  }



  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    if (this.task.taskKey === TKEvaluation.evaluationCommitteeEvaluation) {
      this.evs.evaluate(this.task._id, {
        evaluation: this.evaluation,
        presidentId: this.presidentId,
        secretaryId: this.secretaryId,
        memberId: this.memberId,
        report: this.report
      }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else if (this.task.taskKey === TKEvaluation.confirmationBySecretary) {
      this.evs.confirmEvaluation(this.task._id, {
        evaluation: this.evaluation,
        presidentId: this.presidentId,
        secretaryId: this.secretaryId,
        memberId: this.memberId,
        report: this.report
      }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }


  }
}
