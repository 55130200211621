import { ElementRef, Injectable } from '@angular/core';

@Injectable()
export class DropdownService {

  data = {};

  constructor() { }

  register(id: string, dropdown: ElementRef, dontClose?: ElementRef[]) {
    if (!dontClose) dontClose = [];
    this.data[id] = { dropdown, open: false, dontClose };
  }

  open(id: string) {
    if (this.data[id])
      this.data[id].open = true;
  }

  close(id: string) {
    if (this.data[id])
      this.data[id].open = false;
  }

  toggle(id: string, dropwdown: ElementRef) {
    if (!this.data[id]) {
      this.register(id, dropwdown);
    }

    this.data[id].open = !this.data[id].open;
  }

  show(id: string) {
    if (!this.data[id]) return false;

    return this.data[id].open;
  }

  closeDropdowns(event) {
    Object.keys(this.data).forEach((id) => {
      const d = this.data[id];
      if (d.dropdown && !d.dropdown.contains) {
        d.dropdown = d.dropdown.nativeElement;
      }
      if (d.dropdown && !d.dropdown.contains(event.target)) {
        let doClose = true;
        for (let el of d.dontClose) {
          if (el.contains(event.target)) {
            doClose = false;
          }
        }
        if (doClose) {
          d.open = false;
        }
      }
    });
  }


}