<modal [(open)]="open"
  [closable]="false"
  [showFooter]="false">

  <form #f="ngForm"
    id="acceptGoodPracticeCodeForm"
    *ngIf="open"
    (ngSubmit)="save()">

    <div class="alert alert-info"
      style="margin-bottom: 40px;">
      <i class="far fa-info-circle text-info mr-1"></i>
      {{ 'gpcInfo' | translate }}
    </div>

    <div class="form-group my-4">
      <h5>
        <a href="https://www.uma.es/secretaria-general-uma/navegador_de_ficheros/boletin/descargar/2023/octubre2/20231031_72221.pdf"
          target="_blank"
          (click)="gpcPDFLinkClicked = true">
          <i class="fad fa-file-pdf fa-inverse fa-fw"
            style="color: #ff0000; --fa-secondary-opacity: 0.5;"></i>
          Código de Buenas Prácticas.pdf
        </a>
      </h5>
      <div *ngIf="f.submitted && !gpcPDFLinkClicked">
        <span class="invalid-feedback d-inline ml-4"
          *ngIf="!gpcPDFLinkClicked">
          {{ 'gpcPDFLinkInfo' | translate }}
        </span>
      </div>
    </div>

    <div class="form-group form-check ml-2">
      <input type="checkbox"
        class="form-check-input"
        name="acceptGoodPracticeCodeCheckbox"
        id="acceptGoodPracticeCodeCheckbox"
        #acceptGoodPracticeCodeCheckbox="ngModel"
        [required]="true"
        [(ngModel)]="accept">
      <label class="form-check-label"
        for="acceptGoodPracticeCodeCheckbox">
        {{ 'iHaveReadAndAcceptGPC' | translate }}
      </label>
      <validation-errors [control]="acceptGoodPracticeCodeCheckbox"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="text-right">
      <button class="btn btn-primary"
        type="submit"
        form="acceptGoodPracticeCodeForm">
        {{ 'accept' | translate }}
      </button>
    </div>
  </form>

</modal>