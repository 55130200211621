<modal [(open)]="open"
  [title]="'edit-thesis-reports' | translate">

  <form #f="ngForm"
    (ngSubmit)="save()"
    id="editThesisReportsForm"
    *ngIf="open">

    <ng-container *ngFor="let report of reports; let i=index">

      <div>
        <strong>
          {{ report.memberName }}
        </strong>
      </div>

      <div class="form-group">
        <label>{{ 'thesis-evaluation-report' | translate }}</label>
        <file-input [(ngModel)]="report.docRef"
          required
          [name]="'docRef' + i"
          [id]="'docRef' + i"
          #docRefInput="ngModel"
          [invalid]="f.submitted && docRefInput.invalid"></file-input>
        <validation-errors [control]="docRefInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="evaluationSelect">
          {{ 'evaluation' | translate }}
        </label>

        {{ report.evaluation}}
        <ng-select [id]="'evaluationSelect' + i"
          [name]="'evaluationSelect' + i"
          #evaluationSelect="ngModel"
          [(ngModel)]="report.evaluation"
          required
          [class.is-invalid]="f.submitted && evaluationSelect.invalid"
          [placeholder]="'select-evaluation' | translate"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="ThesisReportEvaluation.accept">
            {{ ThesisReportEvaluation.accept | translate }}
          </ng-option>
          <ng-option [value]="ThesisReportEvaluation.acceptMinorChanges">
            {{ ThesisReportEvaluation.acceptMinorChanges | translate }}
          </ng-option>
          <ng-option [value]="ThesisReportEvaluation.acceptMajorChanges">
            {{ ThesisReportEvaluation.acceptMajorChanges | translate }}
          </ng-option>
          <ng-option [value]="ThesisReportEvaluation.reject">
            {{ ThesisReportEvaluation.reject | translate }}
          </ng-option>
        </ng-select>
        <validation-errors [control]="evaluationSelect"
          [show]="f.submitted"></validation-errors>
      </div>


    </ng-container>

  </form>

  <div footer>
    <button class="btn btn-primary"
      type="submit"
      form="editThesisReportsForm"
      [disableButton]="sending"
      [disableButtonIcon]="sending ? 'spin' : 'ban'">
      {{ 'save' | translate}}
    </button>
  </div>

</modal>