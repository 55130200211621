<modal [(open)]="open"
  [title]="'edit-thesis-defence' | translate">

  <form #f="ngForm"
    (ngSubmit)="save()"
    id="editDefenceForm"
    *ngIf="open">

    <div class="form-group">
      <label>
        {{ 'thesis-final-doc' | translate }}
      </label>
      <file-input name="thesisDoc"
        required
        #thesisDocInput="ngModel"
        [invalid]="thesisDocInput.invalid && f.submitted"
        [(ngModel)]="clonedData.thesisFinal"></file-input>
      <validation-errors [show]="f.submitted"
        [control]="thesisDocInput"></validation-errors>
    </div>

    <div class="form-group">
      <label>
        {{ 'changes-report' | translate }}
      </label>
      <file-input name="changesReport"
        required
        #changesInput="ngModel"
        [invalid]="changesInput.invalid && f.submitted"
        [(ngModel)]="clonedData.changesReport"></file-input>
      <validation-errors [show]="f.submitted"
        [control]="changesInput"></validation-errors>
    </div>

    <div class="form-group">
      <label for="evaluationSelect">
        {{ 'thesis-final-evaluation' | translate }}
      </label>
      <ng-select id="evaluationSelect"
        name="evaluationSelect"
        #evaluationSelect="ngModel"
        [(ngModel)]="clonedData.thesisFinalEvaluation"
        [required]="false"
        [class.is-invalid]="f.submitted && evaluationSelect.invalid"
        [placeholder]="'select-thesis-final-evaluation' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="ThesisFinalEvaluation.fail">
          {{ ThesisFinalEvaluation.fail | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.pass">
          {{ ThesisFinalEvaluation.pass | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.bgrade">
          {{ ThesisFinalEvaluation.bgrade | translate }}
        </ng-option>
        <ng-option [value]="ThesisFinalEvaluation.agrade">
          {{ ThesisFinalEvaluation.agrade | translate }}
        </ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="evaluationSelect"></validation-errors>
    </div>

    <div class="form-group"
      *ngIf="clonedData.thesisFinalEvaluation === ThesisFinalEvaluation.agrade">
      <label for="cumlaudeMention">
        {{ 'cum-laude-mention' | translate }}
      </label>
      <ng-select id="cumlaudeMention"
        name="cumlaudeMention"
        #cumlaudeMentionSelect="ngModel"
        [(ngModel)]="clonedData.cumlaudeMention"
        [placeholder]="'select-thesis-final-cum-laude-mention' | translate"
        required
        [class.is-invalid]="f.submitted && evaluationSelect.invalid"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="true">
          {{ 'yes' | translate }}
        </ng-option>
        <ng-option [value]="false">
          {{ 'no' | translate }}
        </ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="cumlaudeMentionSelect"></validation-errors>
    </div>

    <div class="form-group">
      <label>{{ 'thesis-defence-record' | translate }}</label>
      <file-input [(ngModel)]="clonedData.thesisDefenceRecord"
        [required]="false"
        name="thesisDefenceRecord"
        id="thesisDefenceRecord"
        #thesisDefenceRecordInput="ngModel"
        [invalid]="f.submitted && thesisDefenceRecordInput.invalid"></file-input>
      <validation-errors [control]="thesisDefenceRecordInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="defenceDate">
        {{ 'defence-date' | translate }}
      </label>
      <date-picker id="defenceDate"
        name="defenceDate"
        [(ngModel)]="clonedData.defenceDate"
        #defenceDateInput="ngModel"
        [isInvalid]="defenceDateInput.invalid && f.submitted"
        [time]="true"
        [required]="false"></date-picker>
      <validation-errors [show]="f.submitted"
        [control]="defenceDateInput"></validation-errors>
    </div>

    <div class="form-group">
      <label for="acceptedForProcessingDate">
        {{ 'accepted-for-processing-date' | translate }}
      </label>
      <date-picker id="acceptedForProcessingDate"
        name="acceptedForProcessingDate"
        [(ngModel)]="clonedData.acceptedForProcessingDate"
        #acceptedForProcessingDateInput="ngModel"
        [isInvalid]="acceptedForProcessingDateInput.invalid && f.submitted"
        [time]="false"
        [required]="false"></date-picker>
      <validation-errors [show]="f.submitted"
        [control]="acceptedForProcessingDateInput"></validation-errors>
    </div>

    <div class="form-group">
      <label for="pgcDefenceApprobalDate">
        {{ 'pgc-approbal-date' | translate }}
      </label>
      <date-picker id="pgcDefenceApprobalDate"
        name="pgcDefenceApprobalDate"
        [(ngModel)]="clonedData.pgcDefenceApprobalDate"
        #pgcDefenceApprobalDateInput="ngModel"
        [isInvalid]="pgcDefenceApprobalDateInput.invalid && f.submitted"
        [time]="false"
        [required]="false"></date-picker>
      <validation-errors [show]="f.submitted"
        [control]="pgcDefenceApprobalDateInput"></validation-errors>
    </div>

    <ng-container *ngIf="!(proccesingData.cotutelle && proccesingData.cotutelleDefenceOut)">
      <div class="form-group">
        <label for="internationalMention">
          {{ 'international-mention' | translate }}
        </label>
        <ng-select id="internationalMention"
          name="internationalMention"
          #internationalMentionSelect="ngModel"
          [(ngModel)]="clonedData.internationalMentionFinal"
          [placeholder]="'select-thesis-final-international-mention' | translate"
          [required]="false"
          [class.is-invalid]="f.submitted && evaluationSelect.invalid"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="true">
            {{ 'yes' | translate }}
          </ng-option>
          <ng-option [value]="false">
            {{ 'no' | translate }}
          </ng-option>
        </ng-select>
        <validation-errors [show]="f.submitted"
          [control]="internationalMentionSelect"></validation-errors>
      </div>

      <div class="form-group">
        <label for="industrialMention">
          {{ 'industrial-mention' | translate }}
        </label>
        <ng-select id="industrialMention"
          name="industrialMention"
          #industrialMentionSelect="ngModel"
          [(ngModel)]="clonedData.industrialMentionFinal"
          [placeholder]="'select-thesis-final-industrial-mention' | translate"
          [required]="false"
          [class.is-invalid]="f.submitted && evaluationSelect.invalid"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="true">
            {{ 'yes' | translate }}
          </ng-option>
          <ng-option [value]="false">
            {{ 'no' | translate }}
          </ng-option>
        </ng-select>
        <validation-errors [show]="f.submitted"
          [control]="industrialMentionSelect"></validation-errors>
      </div>

      <div class="form-group">
        <label for="president">
          {{ 'president' | translate }}
        </label>
        <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="president"
          name="president"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-president' | translate"
          #presidentSelect="ngModel"
          [class.is-invalid]="presidentSelect.invalid && f.submitted"
          [ngModel]="presidentId"
          (ngModelChange)="assignPresident($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="presidentSelect"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="secretary">
          {{ 'secretary' | translate }}
        </label>
        <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="secretary"
          name="secretary"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-secretary' | translate"
          #secretarySelect="ngModel"
          [class.is-invalid]="secretarySelect.invalid && f.submitted"
          [(ngModel)]="secretaryId"
          (ngModelChange)="assignSecretary($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="secretarySelect"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="member">
          {{ 'member' | translate }}
        </label>
        <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="member"
          name="member"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-member' | translate"
          #memberSelect="ngModel"
          [class.is-invalid]="memberSelect.invalid && f.submitted"
          [(ngModel)]="memberId"
          (ngModelChange)="assignMember($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="memberSelect"
          [show]="f.submitted"></validation-errors>
      </div>


      <div class="form-group">
        <div class="mb-2 form-check">
          <input type="checkbox"
            class="form-check-input"
            name="videoConferenceCheck"
            id="videoConferenceCheck"
            [(ngModel)]="clonedData.videoConference">
          <label class="form-check-label"
            for="videoConferenceCheck">
            {{ 'video-conference-defence' | translate }}
          </label>
        </div>
      </div>
      <div class="form-group"
        *ngIf="clonedData.videoConference">
        <label>Documentación defensa videoconferencia</label>
        <files-queue [(ngModel)]="clonedData.videoConferenceDocs"
          name="videoConferenceDocs"
          #videoConferenceDocsInput="ngModel"
          [required]="true"></files-queue>
        <validation-errors [control]="videoConferenceDocsInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <div class="mb-2 form-check">
          <input type="checkbox"
            class="form-check-input"
            name="hybridDefenceCheck"
            id="hybridDefenceCheck"
            [(ngModel)]="clonedData.hybridDefence">
          <label class="form-check-label"
            for="hybridDefenceCheck">
            Defensa híbrida (presencial/videconferencia)
          </label>
        </div>
      </div>
      <div class="form-group"
        *ngIf="clonedData.hybridDefence">
        <label>Documentación defensa híbrida</label>
        <files-queue [(ngModel)]="clonedData.hybridDefenceDocs"
          name="hybridDefenceDocs"
          #hybridDefenceDocsInput="ngModel"
          [required]="true"></files-queue>
        <validation-errors [control]="hybridDefenceDocsInput"
          [show]="f.submitted"></validation-errors>
      </div>
    </ng-container>

    <div class="form-group">
      <label>Informe RIUMA</label>
      <file-input [(ngModel)]="clonedData.riuma"
        [required]="false"
        name="riuma"
        id="riuma"
        #riumaInput="ngModel"
        [invalid]="f.submitted && riumaInput.invalid"></file-input>
      <validation-errors [control]="riumaInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label>Ficha TESEO</label>
      <file-input [(ngModel)]="clonedData.teseo"
        [required]="false"
        name="teseo"
        id="teseo"
        #teseoInput="ngModel"
        [invalid]="f.submitted && teseoInput.invalid"></file-input>
      <validation-errors [control]="teseoInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label>Nombramiento</label>
      <file-input [(ngModel)]="clonedData.designation"
        [required]="false"
        name="designation"
        id="designation"
        #designationInput="ngModel"
        [invalid]="f.submitted && designationInput.invalid"></file-input>
      <validation-errors [control]="designationInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label>Solicitud de embargo</label>
      <file-input [(ngModel)]="clonedData.embargo"
        [required]="false"
        name="embargo"
        id="embargo"
        #embargoInput="ngModel"
        [invalid]="f.submitted && embargoInput.invalid"></file-input>
      <validation-errors [control]="embargoInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label>Informes miembros del tribunal</label>
      <files-queue [(ngModel)]="clonedData.additionalReports"
        name="additionalReports"
        #additionalReportsInput="ngModel"
        [required]="false"></files-queue>
      <validation-errors [control]="additionalReportsInput"
        [show]="f.submitted"></validation-errors>
    </div>

  </form>

  <div footer>
    <button class="btn btn-primary"
      type="submit"
      form="editDefenceForm"
      [disableButton]="sending"
      [disableButtonIcon]="sending ? 'spin' : 'ban'">
      {{ 'save' | translate}}
    </button>
  </div>

</modal>