import { Component, Input, OnInit } from '@angular/core';
import { zip } from 'rxjs';

import { IDischargeProcess } from '../../../../../../interfaces/IDischargeProcess';
import { IExtensionProcess } from '../../../../../../interfaces/IExtensionProcess';
import { ILeaveProcess } from '../../../../../../interfaces/ILeaveProcess';
import { PermissionsType } from '../../../../../../interfaces/IPermissions';
import { IStudent, RDType } from '../../../../../../interfaces/IStudent';
import { Processes } from '../../../../../../interfaces/ITask';
import { DischargeHttpService } from '../../../../data-services/discharge-http.service';
import { ExtensionHttpService } from '../../../../data-services/extension-http.service';
import { LeaveHttpService } from '../../../../data-services/leave-http.service';
import { UserService } from '../../../../user/user.service';
import { IRenewalProcess } from '../../../../../../interfaces/IRenewalProcess';
import { RenewalHttpService } from '../../../../data-services/renewal-http.service';

@Component({
  selector: 'student-processes-view',
  templateUrl: './student-processes-view.component.html'
})
export class StudentProcessesViewComponent implements OnInit {

  @Input() student: IStudent;

  @Input() extensionRequestEnabled: { enabled: boolean, reasons: string[] };
  @Input() leaveRequestEnabled: { enabled: boolean, reasons: string[] };

  extensionProcess: IExtensionProcess;
  leaveProcess: ILeaveProcess;
  dischargeProcesses: IDischargeProcess[] = [];
  leaveDischargeProcMap: { [leaveId: string]: IDischargeProcess } = {};
  renewalProcesses: IRenewalProcess[] = [];
  leaveRenewalProcMap: { [leaveId: string]: IRenewalProcess } = {};

  loading = true;

  PermissionsType = PermissionsType;
  Processes = Processes;
  RDType = RDType;

  constructor(private extension: ExtensionHttpService,
    private leave: LeaveHttpService,
    private discharge: DischargeHttpService,
    private renewal: RenewalHttpService,
    public us: UserService) { }

  ngOnInit() {
    this.loading = true;
  }

  ngOnChanges() {
    if (this.student) {
      zip(
        this.extension.getTasksTable(this.student._id),
        this.leave.getTasksTable(this.student._id),
        this.discharge.getTasksTable(this.student._id),
        this.renewal.getTasksTable(this.student._id)
      ).subscribe((result) => {
        this.extensionProcess = result[0];
        this.leaveProcess = result[1];
        this.dischargeProcesses = result[2];
        this.dischargeProcesses.forEach(dp => {
          this.leaveDischargeProcMap[dp.leaveId] = dp;
        })
        this.renewalProcesses = result[3];
        this.renewalProcesses.forEach(rp => {
          this.leaveRenewalProcMap[rp.leaveId] = rp;
        })
        this.loading = false;
      });
    }
  }
}
