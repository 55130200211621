<div *ngIf="pfs.program"
  class="container-fluid container-md">

  <div class="mb-2">
    <a class="text-muted"
      [routerLink]="['../']">
      <i class="far fa-angle-left"></i>
      {{ 'go-back' | translate }}
    </a>
  </div>
  <h2 class="mb-4 text-nowrap text-truncate">
    <i class="far fa-book-alt mr-2 text-secondary"></i>
    {{ pfs.program.name }}
  </h2>

  <ng-template #navItems>
    <li class="nav-item">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./home']">
        <i class="far fa-home"></i>
        {{ 'info' | translate }}
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./professors']">
        <i class="far fa-users"></i>
        {{ 'professors' | translate }}
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./students']">
        <i class="far fa-users-class"></i>
        {{ 'students' | translate }}
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./evaluation']">
        <i class="far fa-file-search"></i>
        {{ 'evaluation' | translate }}
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./committees']">
        <i class="far fa-users"></i>
        {{ 'committees' | translate }}
      </a>
    </li>

  </ng-template>

  <ul class="nav nav-tabs nav-justified mb-4 d-none d-lg-flex">
    <ng-container *ngTemplateOutlet="navItems"></ng-container>
  </ul>

  <ul class="nav nav-pills nav-justified mb-4 d-flex d-lg-none">
    <ng-container *ngTemplateOutlet="navItems"></ng-container>
  </ul>

  <router-outlet></router-outlet>

</div>