<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'request-date' | translate }}
  </div>
  <div>
    {{ process.createdAt | date: 'dd/MM/yyyy HH:mm' }}
  </div>
</div>

<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'Ratio' | translate }}
  </div>
  <div>
    {{ ratioInfo.studentsCount }} estudiantes / {{ ratioInfo.professorsCount }} profesores =
    {{ ratioInfo.ratio | number: '1.2-2' }} estudiantes por profesor
  </div>
</div>

<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'external' | translate}}
  </div>
  <div>
    {{ (process.external ? 'Yes' : 'No') | translate }}
  </div>
</div>

<div class="mb-3"
  *ngIf="process.external">
  <div class="small text-muted mb-1">
    {{ 'university' | translate}}
  </div>
  <div>
    {{ process.university }}
  </div>
</div>

<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'idNum' | translate}}
  </div>
  <div>
    {{ process.idNum }}
  </div>
</div>

<!-- process.firstName -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'firstName' | translate}}
  </div>
  <div>
    {{ process.firstName }}
  </div>
</div>

<!-- process.lastName -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'lastName' | translate}}
  </div>
  <div>
    {{ process.lastName }}
  </div>
</div>

<!-- process.email -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'email' | translate}}
  </div>
  <div>
    {{ process.email }}
  </div>
</div>

<!-- process.category-->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'category' | translate}}
  </div>
  <div>
    {{ process.category | translate }}
  </div>
</div>

<!-- process.lineOfResearch.name -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'line-of-research' | translate}}
  </div>
  <div>
    {{ process.lineOfResearch.name }}
  </div>
</div>

<!-- process.curriculumLink -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'cv' | translate}}
  </div>
  <div>
    <a [href]="process.curriculumLink"
      target="_blank">
      {{ process.curriculumLink }}
    </a>
  </div>
</div>

<!-- process.professorReason -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'professor-reason' | translate}}
  </div>
  <div>
    {{ process.reason | translate }}
  </div>
</div>

<!-- process.sixyearDoc -->
<div class="mb-3"
  *ngIf="process.external === true && process.reason === ProfessorReason.sixyearPeriod">
  <div class="small text-muted mb-1">
    {{ 'sixyear-period-justification' | translate}}
  </div>
  <div>
    <document-link [document]="process.sixyearDoc"></document-link>
  </div>
</div>

<!-- process.numThesis -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'num-thesis' | translate}}
  </div>
  <div>
    {{ process.thesisCount }}
  </div>
</div>

<!-- process.orcid -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'orcid-profile' | translate}}
  </div>
  <div>
    {{ process.orcid }}
  </div>
</div>

<!-- process.otherProfile -->
<div class="mb-3"
  *ngIf="process.otherProfile">
  <div class="small text-muted mb-1">
    {{ 'other-profile' | translate}}
  </div>
  <div>
    {{ process.otherProfile }}
  </div>
</div>

<!-- process.otherProgram -->
<div class="mb-3">
  <div class="small text-muted mb-1">
    {{ 'other-program' | translate}}
  </div>
  <div>
    {{ process.otherProgram ? 'Yes' : 'No' | translate }}
  </div>
</div>

<!-- process.otherProgramName -->
<div class="mb-3"
  *ngIf="process.otherProgram">
  <div class="small text-muted mb-1">
    {{ 'other-program-name' | translate}}
  </div>
  <div>
    {{ process.otherProgramName }}
  </div>
</div>

<!-- process.otherProgramUniversity-->
<div class="mb-3"
  *ngIf="process.otherProgram">
  <div class="small text-muted mb-1">
    {{ 'other-program-university' | translate}}
  </div>
  <div>
    {{ process.otherProgramUniversity }}
  </div>
</div>