import { Component, OnInit } from '@angular/core';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Group, IUser, Role } from '../../../../../../../interfaces/IUser';
import * as _ from 'lodash';
import { LoadingIndicatorService } from '../../../../../UI/loading-indicator/loading-indicator.service';
import { StudentStateType } from '../../../../../../../interfaces/IStudent';
import { ImpersonatingService } from '../../../../../data-services/impersonating.service';
import { PlatformLocation } from '@angular/common';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';

@Component({
  selector: 'user-file',
  templateUrl: './user-file.component.html',
})
export class UserFileComponent implements OnInit {

  user: IUser;
  relations: {
    studentId: string;
    name: string;
    program: string;
    role: string;
    group: string;
    groupId?: string;
    state: StudentStateType;
    altStates: StudentStateType[];
  }[];

  studentRelations: {
    studentId: string;
    name: string;
    program: string;
    roles: string[];
    group: string;
    groupId?: string;
    state: StudentStateType;
    altStates: StudentStateType[];
  }[] = [];

  globalRelations: {
    studentId: string;
    name: string;
    program: string;
    role: string;
    group: string;
    groupId?: string;
    state: StudentStateType;
    altStates: StudentStateType[];
  }[];

  programRelations: {
    studentId: string;
    name: string;
    program: string;
    role: string;
    group: string;
    groupId?: string;
    state: StudentStateType;
    altStates: StudentStateType[];
  }[];

  facultyRelations: {
    studentId: string;
    name: string;
    program: string;
    role: string;
    group: string;
    groupId?: string;
    state: StudentStateType;
    altStates: StudentStateType[];
  }[];

  Role = Role;
  StudentStateType = StudentStateType;

  open = false;
  newUid = '';

  constructor(private ss: ServiceHttpService,
    private route: ActivatedRoute,
    private router: Router,
    private loading: LoadingIndicatorService,
    private alerts: PopAlertsService,
    private is: ImpersonatingService,
    private platformLocation: PlatformLocation) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (!params.userId) {
        void this.router.navigate(['..'], { relativeTo: this.route });
      }
      this.loading.start();
      this.fetchUser(params.userId);
    })
  }

  private fetchUser(userId) {
    this.ss.fetchUser(userId).subscribe((res) => {
      this.user = res.user;
      this.relations = res.relations;

      const strs = _.groupBy(this.relations.filter(r => r.group === Group.student)
        .map(r => {
          return { ...r, roles: [] }
        }), 'studentId');
      for (const studentId in strs) {
        const st = strs[studentId];
        const obj = { ...st[0] };
        for (const sr of st) {
          obj.roles.push(sr.role);
        }
        this.studentRelations.push(obj);
      }

      this.studentRelations = _.sortBy(this.studentRelations, 'name')
      this.globalRelations = this.relations.filter(r => r.group === Group.global);
      this.facultyRelations = this.relations.filter(r => r.group === Group.faculty);
      this.programRelations = this.relations.filter(r => r.group === Group.program);

      this.loading.stop();
    })
  }

  isThesisCommittee(role) {
    return [
      Role.thesisCommitteeMember,
      Role.thesisCommitteePresident,
      Role.thesisCommitteeSecretary,
      Role.thesisCommitteeAlt]
      .includes(role);
  }

  generateThesisCert(studentId, role) {
    window.location.href = `${window.location.origin}/api/service/thesisCommitteeCert/${this.user._id}/${studentId}/${role}`
  }

  isExternalReviewer(role) {
    return [
      Role.thesisExternalReviewer,
      Role.thesisExternalReviewer1,
      Role.thesisExternalReviewer2,
      Role.thesisExternalReviewer3,
    ]
      .includes(role);
  }

  generateExternalReviewerCert(studentId) {
    window.location.href = `${window.location.origin}/api/service/externalReviewerCert/${this.user._id}/${studentId}`
  }

  isCDS(role) {
    return [
      Role.tutor,
      Role.supervisor1,
      Role.supervisor2,
      Role.supervisor3]
      .includes(role);
  }

  generateCDS(studentId) {
    window.location.href = `${window.location.origin}/api/service/cdsCert/${this.user._id}/${studentId}`
  }

  generateCDSAll() {
    window.location.href = `${window.location.origin}/api/service/cdsCertAll/${this.user._id}`
  }

  generateDirectionTeam(studentId) {
    window.location.href = `${window.location.origin}/api/service/directionTeamCert/${this.user._id}/${studentId}`
  }

  generateDirectionTeamAll() {
    window.location.href = `${window.location.origin}/api/service/directionTeamCertAll/${this.user._id}`
  }

  enableDirectionTeamAll() {
    return this.relations.findIndex(r => r.state === StudentStateType.defended && this.isCDS(r.role)) > -1;
  }

  enableCDSAll() {
    return this.relations.findIndex(r => this.isCDS(r.role)) > -1;
  }

  impersonate(userId) {
    this.is.impersonate(userId).subscribe(() => {
      let url = (this.platformLocation as any).location.origin;
      location.href = url;
    })
  }

  toggleOnlyCoSupervisor() {
    let onlyCoSupervisor = this.user.onlyCoSupervisor;
    if (onlyCoSupervisor === undefined || onlyCoSupervisor === null) {
      onlyCoSupervisor = false;
    }
    onlyCoSupervisor = !onlyCoSupervisor;
    this.ss.saveOnlyCoSupervisor({ userId: this.user._id, onlyCoSupervisor }).subscribe(() => {
      this.alerts.saveSuccess();
    });
  }

  saveNewUid() {
    this.ss.saveNewUid({ userId: this.user._id, newUid: this.newUid }).subscribe(() => {
      this.alerts.saveSuccess();
      this.user.uid = this.newUid;
      this.newUid = '';
      this.open = false;
    });
  }

  openEditDocuments = false;
  cpAgreement: IDocumentRef;

  doOpenEditDocuments() {
    this.openEditDocuments = true;
    this.cpAgreement = _.cloneDeep(this.user.cpAgreement);
  }

  saveDocuments() {
    this.ss.saveDocuments({ userId: this.user._id, cpAgreement: this.cpAgreement }).subscribe(() => {
      this.alerts.saveSuccess();
      this.user.cpAgreement = this.cpAgreement;
      this.openEditDocuments = false;
    });
  }

  updateName() {
    this.ss.updateUserName({ userId: this.user._id }).subscribe(() => {
      this.fetchUser(this.user._id);
      this.alerts.saveSuccess();
    });
  }

}
