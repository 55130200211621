<div class="list-group mb-1"
  *ngIf="files.length > 0">
  <div class="list-group-item"
    *ngFor="let file of files">
    <div class="d-flex justify-content-between align-items-center">
      <div *ngIf="file.state !== 'COMPLETED'"
        class="text-nowrap text-truncate"
        style="max-width: 85%;">
        <span *ngIf="file.state === 'WAITING'"
          class="text-muted mr-2">
          <i class="far fa-ellipsis-h"></i>
        </span>
        <span *ngIf="file.state === 'UPLOADING'"
          class="text-secondary mr-2">
          <i class="far fa-circle-notch fa-spin"></i>
        </span>
        {{ file.data.name }}
      </div>
      <div *ngIf="file.state === 'COMPLETED'"
        style="max-width: 85%;">
        <document-link [document]="file.document"></document-link>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-dark"
          (click)="remove(file.id)"
          type="button">
          <i class="far fa-trash"></i>
        </button>
      </div>
    </div>
    <progress-bar height="3"
      [visibility]="file.state === 'UPLOADING' ? 'visible' : 'hidden'"
      [progress]="file.progress"
      color="secondary">
    </progress-bar>
  </div>
</div>

<div dropFiles
  (filesChangeEmitter)="push($event)"
  class="p-4 text-center"
  style="border: 2px dashed #ccc;">
  {{ 'drag-files-here' | translate }}
  <button type="button"
    class="btn btn-sm btn-outline-secondary ml-2"
    (click)="inputTypeFile.click()">{{ 'choose' | translate }}</button>
</div>

<div class="wrapper">
  <input #inputTypeFile
    type="file"
    name="file"
    multiple
    (change)="push($event)"
    (click)="$event.target.value = null" />
</div>