<div class="container">

  <div class="row">
    <div class="col">
      <section-title text="users"
        icon="users">
      </section-title>
    </div>
  </div>

  <form>
    <div class="form-group">
      <search-input name="search"
        [ngModel]="params.searchText"
        (ngModelChange)="params.searchText = $event; list.search()"
        [placeholder]="'search-user-by-name-or-id-num'"></search-input>
    </div>
  </form>

  <infinite-list #list
    [data]="fetchFn()"
    [(array)]="users">

    <div class="card"
      *ngIf="users.length > 0">
      <div class="table-responsive">
        <table class="table table-hover mb-0">
          <tbody>
            <tr *ngFor="let user of users; trackBy:hs.trackById"
              [routerLink]="[user._id]">
              <td class="text-center">
                {{ user.idNum }}
              </td>
              <td>
                {{ user | fullname }}
              </td>
              <td class="text-center">
                <email-link [user]="user"></email-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </infinite-list>


</div>