<ng-container *ngIf="task">
  <task-header [task]="task"></task-header>

  <br>

  <div class="small text-muted mb-1">
    {{ 'program' | translate }}
  </div>
  <div class="mb-4">
    {{ task.thesisProcess.student.program.name }}
  </div>

  <div class="small text-muted mb-1">
    {{ 'student' | translate }}
  </div>
  <text-info icon="user"
    [mb]="4">
    {{ task.thesisProcess.student | fullname }}
  </text-info>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-title' | translate }}
    </div>
    <div>
      {{ task.thesisProcess.data.thesisTitle }}
    </div>
  </div>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <h5>Marque su voto para la mención Cum Laude</h5>

    <div class="form-group text-center">
      <div class="btn-group btn-block">
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-primary]="cumLaude === null || cumLaude === true"
          [class.btn-primary]="cumLaude === false"
          (click)="cumLaude = false; confirmation = false">
          {{ 'no' | translate }}
        </button>
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-primary]="cumLaude === null || cumLaude === false"
          [class.btn-primary]="cumLaude === true"
          (click)="cumLaude = true; confirmation = false">
          {{ 'yes' | translate }}
        </button>
      </div>
    </div>

    <div *ngIf="cumLaude === null && f.submitted">
      <span class="invalid-feedback d-inline">
        Debe elegir su voto
      </span>
    </div>

    <div class="form-group form-check"
      *ngIf="cumLaude !== null">
      <input type="checkbox"
        class="form-check-input"
        name="confirmation"
        id="confirmation"
        [required]="true"
        [(ngModel)]="confirmation">
      <label class="form-check-label"
        for="confirmation">
        Confirmo que mi voto para la mención Cum Laude es <strong>{{ cumLaude ? 'SÍ' : 'NO' }}</strong>
      </label>
    </div>

    <div *ngIf="!confirmation && f.submitted">
      <span class="invalid-feedback d-inline">
        Debe confirmar su voto
      </span>
    </div>

    <ng-container *ngIf="cumLaude !== null && confirmation === true">
      <div class="w-100 text-right">
        <button type="submit"
          class="btn btn-primary"
          [disableButton]="disabled || sending"
          [disableButtonIcon]="sending ? 'spin' : 'ban'">
          {{ 'send' | translate }}
        </button>
      </div>


    </ng-container>
  </form>
</ng-container>