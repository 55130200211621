import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { IDocument } from '../../../../../../interfaces/IDocument';

@Component({
  selector: 'document-details',
  templateUrl: 'document-details.component.html'
})
export class DocumentDetailsComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() document: IDocument;
  @Input() editable = true;

  @Input() active = false;

  @Output() onSave = new EventEmitter<{ documentId: string, location: string, description: string }>();

  constructor() { }

  ngOnInit() { }

  save() {
    if (!this.f.valid) return;

    this.onSave.emit({ documentId: this.document._id, location: this.document.location, description: this.document.description });
  }

  reset() {
    this.document = null;
  }
}