import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDirectionProcess, IDirectionRequestData } from '../../../interfaces/IDirectionProcess';
import { IDocumentRef } from '../../../interfaces/IDocument';
import { CdsReason } from '../../../interfaces/ICDSProcess';

@Injectable()
export class DirectionHttpService {

  constructor(private http: HttpClient) { }

  request(studentId: string, data: IDirectionRequestData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/direction/request/${studentId}`, data);
  }

  academicCommitteeValidation(taskId: string, data: {
    tutorReason: CdsReason[];
    supervisor1Reason: CdsReason[];
    supervisor2Reason?: CdsReason[];
    supervisor3Reason?: CdsReason[];
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/direction/academicCommitteeValidation/${taskId}`, data);
  }

  academicCommitteeDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/direction/academicCommitteeDeny/${taskId}`, data);
  }

  studentRequestReview(taskId: string, data: IDirectionRequestData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/direction/studentRequestReview/${taskId}`, data);
  }

  serviceDenyValidation(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/direction/serviceDenyValidation/${taskId}`, data);
  }

  serviceValidation(taskId: string, data: IDirectionRequestData): Observable<{ ok: boolean, followUpTaskId?: string }> {
    return this.http.post<{ ok: boolean, followUpTaskId?: string }>(`/api/direction/serviceValidation/${taskId}`, data);
  }

  pgcDenyValidation(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/direction/pgcDenyValidation/${taskId}`, data);
  }

  pgcValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/direction/pgcValidation/${taskId}`, {});
  }

  serviceDirectionTeamIds(taskId: string, data: {
    directionIds:
    {
      tutor: string,
      supervisor1: string,
      supervisor2?: string,
      supervisor3?: string
    },
    isNew: {
      supervisor1: boolean,
      supervisor2: boolean,
      supervisor3: boolean
    }
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/direction/serviceDirectionTeamIds/${taskId}`, data);
  }

  signDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/direction/signDeny/${taskId}`, data);
  }

  sign(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/direction/sign/${taskId}`, {});
  }

  findProcess(taskId: string): Observable<IDirectionProcess> {
    return this.http.get<IDirectionProcess>(`/api/direction/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<IDirectionProcess> {
    return this.http.get<IDirectionProcess>(`/api/direction/process/${studentId}`);
  }

}