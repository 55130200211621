import { IDocumentRef } from './IDocument';
import { IProcess } from './IProcess';
import { IStudent } from './IStudent';

export interface IExtensionProcess extends IProcess {
  state: ExtensionPState,
  studentId: string,
  order: "first" | "second",
  justification: IDocumentRef[],

  // POPULATE
  student?: IStudent
}

export enum ExtensionPState {
  PendingAcademicCommitteeValidation = "PendingAcademicCommitteeValidation"
}

export enum TKExtension {
  academicCommitteeValidation = "academicCommitteeValidation"
}