<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="activities"
        icon="list"
        [btnIcon]="'far fa-plus'"
        [btnText]="'new-activity'"
        [btnClasses]="'btn-outline-info'"
        [btnShow]="editable"
        (btnClick)="editActivity.activity= null; editActivity.reset(); activityModalOpen = true;">
      </section-title>

    </div>
  </div>

  <form>
    <div class="form-group">
      <search-input name="search"
        [ngModel]="params.searchText"
        (ngModelChange)="params.searchText = $event; infiniteList.search()"
        [placeholder]="'search-activity-by-title'"></search-input>
    </div>
  </form>

  <infinite-list #infiniteList
    *ngIf="sr.student"
    [data]="fetchFn()"
    [(array)]="activities">

    <activities-list #activitiesList
      [activities]="activities"
      [showRequestValidation]="sr.student.tutorId !== undefined && sr.student.tutorId !== null"
      [openId]="openId"
      [editable]="editable"
      (onDownloadFile)="ds.download($event)"
      (onEditActivity)="openEditActivity($event)"
      (onRemoveActivity)="removeActivity($event)"
      (onAddDocuments)="addDocuments.activity = $event; addDocuments.reset(); addDocumentsModalOpen = true"
      (onRemoveDocument)="removeDocument($event.activity._id, $event.document)"
      (onUploadVersion)="newVersion.activity = $event.activity; newVersion.document = $event.document; newVersionModalOpen = true;"
      (onVersionHistoric)="openVersionHistoric($event.document.documentRef)"
      (onDetails)="openDocumentDetails($event.document.documentRef)"
      (onRequestValidation)="requestValidation($event)"
      (onResendValidation)="resendValidation($event)"
      (onModify)="modify($event)">
    </activities-list>

  </infinite-list>

</div>

<modal [(open)]="activityModalOpen"
  [title]="editActivity.editing() ? 'Editar actividad' : 'Nueva actividad'"
  [size]="'lg'">
  <edit-activity #editActivity
    [active]="activityModalOpen"
    (onInsert)="addActivity($event)"
    (onUpdate)="saveActivity($event)"></edit-activity>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="activityModalOpen = false; editActivity.reset();">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      form="activityEditForm"
      type="submit">{{ 'accept' | translate }}</button>
  </div>
</modal>

<modal [(open)]="addDocumentsModalOpen"
  [title]="'add-evidences' | translate">

  <add-documents #addDocuments
    [active]="addDocumentsModalOpen"
    (onSave)="doAddDocumentsToActivity($event.activityId, $event.documents)"></add-documents>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="addDocumentsModalOpen = false; addDocuments.reset();">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      form="addDocumentsForm"
      type="submit">{{ 'accept' | translate }}</button>
  </div>
</modal>

<modal [(open)]="newVersionModalOpen"
  [title]="'change-document' | translate">

  <new-version #newVersion
    [active]="newVersionModalOpen"
    (onSave)="addNewVersion($event.activityId, $event.documentId, $event.versionId)"></new-version>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="newVersionModalOpen = false; newVersion.reset();">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      form="newVersionForm"
      type="submit">{{ 'accept' | translate }}</button>
  </div>
</modal>

<modal [(open)]="versionHistoricModalOpen"
  [title]="'version-historic' | translate">

  <version-historic #versionHistoric
    (onDownload)="ds.download($event)"
    (onDownloadVersion)="ds.downloadVn($event.documentId, $event.vn)"></version-historic>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="versionHistoricModalOpen = false;">{{ 'close' | translate }}</button>
  </div>
</modal>

<modal [(open)]="documentDetailsModalOpen"
  [title]="'details' | translate">

  <document-details #documentDetails
    [active]="documentDetailsModalOpen"
    (onSave)="saveDocumentDetails($event.documentId, $event.location, $event.description)"></document-details>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="documentDetailsModalOpen = false; documentDetails.reset();">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      form="documentDetailsForm"
      type="submit">{{ 'accept' | translate }}</button>
  </div>
</modal>