import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IStudent } from '../../../../../../interfaces/IStudent';
import { StudentFileService } from '../student-file.service';


@Component({
  selector: 'student-file-thesis-view',
  templateUrl: 'student-file-thesis-view.component.html'
})
export class StudentFileThesisViewComponent implements OnInit, OnDestroy {
  student: IStudent;

  sub: Subscription;

  constructor(private sfs: StudentFileService) { }

  ngOnInit() {
    this.sub = this.sfs.studentObs.pipe(filter(x => x !== null)).subscribe((student) => {
      this.student = student;
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}