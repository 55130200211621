<form #f="ngForm"
  (keydown.enter)="$event.preventDefault()"
  autocomplete="off"
  id="newVersionForm"
  (ngSubmit)="save()"
  *ngIf="active && document && activity">

  <div class="form-group">
    <file-icon [fileName]="document.fileName"></file-icon>
    {{ document.fileName }}
  </div>

  <div class="form-group">
    <label>{{ 'document' | translate }}</label>
    <file-input [(ngModel)]="docRef"
      required
      name="docRef"
      id="docRef"
      #docRefInput="ngModel"
      [invalid]="f.submitted && docRefInput.invalid"></file-input>
    <validation-errors [control]="docRefInput"
      [show]="f.submitted"></validation-errors>
  </div>

</form>