<div class="list-group">
  <ng-container *ngFor="let task of tasks">
    <div class="list-group-item list-group-item-action c-pointer"
      (click)="onClick.emit(task)">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h6 class="m-0 text-nowrap text-truncate"
          style="text-decoration: underline; line-height: 1.6;">
          {{ hs.processName(task.processKey) | translate }}
        </h6>
        <div class="text-right small text-nowrap">
          {{ task.createdAt | momentFromNow }}
        </div>
      </div>
      <h6 class="mb-2">
        {{ hs.name(task) | translate }}
      </h6>
      <div class="pre"
        [innerHtml]="hs.descKey(task) | translate:hs.descParams(task) | truncate:140">
      </div>
    </div>
  </ng-container>
</div>