<div class="card">
  <div class="card-body">

    <legend>
      {{ 'personal-data' | translate }}
    </legend>

    <text-info icon="user">
      {{ student | fullname }}
    </text-info>

    <text-info icon="id-card"
      mb="3">
      {{ student.user.idNum }}
    </text-info>

    <legend>
      {{ 'contact' | translate }}
    </legend>

    <text-info icon="envelope">
      <email-link [user]="student.user"></email-link>
    </text-info>


  </div>
</div>