import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'file-icon',
  templateUrl: 'file-icon.component.html'
})
export class FileIconComponent implements OnInit, OnChanges {

  @Input() fileName: string;
  @Input() big = false;

  tipo;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {
    this.setTipo();
  }

  setTipo() {
    if (!this.fileName) {
      this.tipo = 'FILE';
      return;
    }
    const ext = this.fileName.substring(this.fileName.lastIndexOf('.') + 1).toLocaleLowerCase();
    switch (ext) {
      case 'pdf':
        this.tipo = 'PDF'
        break;
      case 'xls':
      case 'xlsx':
      case 'ods':
        this.tipo = 'EXCEL'
        break;
      case 'doc':
      case 'docx':
      case 'odt':
        this.tipo = 'WORD'
        break;
      case 'ppt':
      case 'pptx':
      case 'odp':
        this.tipo = 'POWERPOINT'
        break;
      case 'zip':
      case 'rar':
      case '7z':
      case 'gz':
      case 'bz2':
        this.tipo = 'ZIP'
        break;
      case 'txt':
      case 'rtf':
      case 'wpd':
        this.tipo = 'TEXT'
        break;
      case 'jpeg':
      case 'jpg':
      case 'gif':
      case 'png':
      case 'bmp':
      case 'tiff':
      case 'psd':
        this.tipo = 'IMAGE'
        break;
      case 'webm':
      case 'mpg':
      case 'mp2':
      case 'mpeg':
      case 'mpe':
      case 'mpv':
      case 'mp4':
      case 'm4p':
      case 'm4v':
      case 'avi':
      case 'wmv':
      case 'mov':
      case 'qt':
      case 'flv':
      case 'swf':
        this.tipo = 'VIDEO'
        break;
      case 'mp3':
      case 'pcm':
      case 'wav':
      case 'ogg':
        this.tipo = 'AUDIO'
        break;
      case 'html':
      case 'java':
      case 'js':
      case 'ts':
      case 'c':
      case 'py':
      case 'rb':
      case 'class':
      case 'cpp':
      case 'css':
      case 'scss':
      case 'less':
        this.tipo = 'CODE'
        break;

      default:
        this.tipo = 'FILE'
        break;
    }
  }
}