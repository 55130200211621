<form #f="ngForm"
  (ngSubmit)="send()">

  <div class="card mb-3">
    <div class="card-body">

      <h5 class="card-title">
        {{ 'student-info' | translate }}
      </h5>

      <text-info icon="user">
        {{ student.user | fullname }}
      </text-info>

      <text-info icon="id-card"
        mb="3">
        {{ student.user.idNum }}
      </text-info>


      <div class="form-group">
        <label>
          {{ 'student-cv' | translate }}
        </label>
        <file-input name="studentCV"
          required
          #studentCVInput="ngModel"
          [invalid]="studentCVInput.invalid && f.submitted"
          [(ngModel)]="data.cv"></file-input>
        <validation-errors [show]="f.submitted"
          [control]="studentCVInput"></validation-errors>
      </div>

    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">
        {{ 'thesis-info' | translate }}
      </h5>
      <div class="form-group">
        <label for="title">{{ 'thesis-title' | translate }}</label>

        <input class="form-control"
          name="title"
          id="title"
          required
          [class.is-invalid]="titleInput.invalid && f.submitted"
          #titleInput="ngModel"
          [(ngModel)]="data.thesisTitle"
          [placeholder]="'thesis-title' | translate">
        <validation-errors [control]="titleInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="row">
        <div class="col-md-6 pr-md-1">
          <div class="form-group">
            <label>
              {{ 'thesis-doc' | translate }}
            </label>
            <file-input name="thesisDoc"
              required
              #thesisDocInput="ngModel"
              [invalid]="thesisDocInput.invalid && f.submitted"
              [(ngModel)]="data.thesisDoc"></file-input>
            <validation-errors [show]="f.submitted"
              [control]="thesisDocInput"></validation-errors>
          </div>
        </div>
        <div class="col-md-6 pl-md-1">
          <div class="form-group">
            <label>
              {{ 'thesis-summary' | translate }}
            </label>
            <file-input name="thesisSummary"
              required
              #thesisSummaryInput="ngModel"
              [invalid]="thesisSummaryInput.invalid && f.submitted"
              [(ngModel)]="data.thesisSummaryDoc"></file-input>
            <validation-errors [show]="f.submitted"
              [control]="thesisSummaryInput"></validation-errors>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>
          {{ 'thesis-publications' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="publicationsInput"></validation-errors>
        <files-queue name="publicationsDocs"
          required
          #publicationsInput="ngModel"
          [(ngModel)]="data.publications"></files-queue>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">{{ 'thesis-cotutelle' | translate }}</h5>

      <div class="form-group form-check">
        <input type="checkbox"
          class="form-check-input"
          name="thesisCotutelleCheck"
          id="thesisCotutelleCheck"
          [(ngModel)]="data.cotutelle">
        <label class="form-check-label"
          for="thesisCotutelleCheck">
          {{ 'check-if-thesis-cotutulle' | translate }}
        </label>
      </div>

      <div class="form-group"
        *ngIf="data.cotutelle"
        [@appear]>
        <label for="cotutelleUniversity">{{ 'cotutelle-university' | translate }}</label>

        <input class="form-control"
          name="cotutelleUniversity"
          id="cotutelleUniversity"
          required
          [class.is-invalid]="cotutelleUniversityInput.invalid && f.submitted"
          #cotutelleUniversityInput="ngModel"
          [(ngModel)]="data.cotutelleUniversity"
          [placeholder]="'cotutelle-university' | translate">
        <validation-errors [control]="cotutelleUniversityInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group"
        *ngIf="data.cotutelle"
        [@appear]>
        <label>
          {{ 'cotutelle-stay-evidence' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="cotutelleStayEvidenceInput"></validation-errors>
        <files-queue name="cotutelleStayEvidence"
          [required]="true"
          #cotutelleStayEvidenceInput="ngModel"
          [(ngModel)]="data.cotutelleStayEvidence"></files-queue>
        <small class="form-text text-muted">
          {{ 'cotutelle-stay-evidence-note' | translate }}
        </small>
      </div>
      <div class="form-group"
        *ngIf="data.cotutelle"
        [@appear]>
        <label>
          {{ 'cotutelle-stay-evidence-out' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="cotutelleStayEvidenceOutInput"></validation-errors>
        <files-queue name="cotutelleStayEvidenceOut"
          [required]="true"
          #cotutelleStayEvidenceOutInput="ngModel"
          [(ngModel)]="data.cotutelleStayEvidenceOut"></files-queue>
        <small class="form-text text-muted">
          {{ 'cotutelle-stay-evidence-out-note' | translate }}
        </small>
      </div>
      <div class="form-group"
        *ngIf="data.cotutelle"
        [@appear]>
        <label>
          {{ 'cotutelle-defence-place' | translate }}
        </label>
        <ng-select name="cotutelleDefencePlace"
          [(ngModel)]="data.cotutelleDefenceOut"
          [placeholder]="'choose-cotutelle-defence-place' | translate"
          [clearable]="false"
          [required]="true"
          [searchable]="false"
          #cotutelleDefenceOutSelect="ngModel"
          style="width: 100%">
          <ng-option [value]="false">
            {{ 'university-of-malaga' | translate }}
          </ng-option>
          <ng-option [value]="true">
            {{ 'foreign-university' | translate }}
          </ng-option>
        </ng-select>
        <validation-errors [show]="f.submitted"
          [control]="cotutelleDefenceOutSelect"></validation-errors>
      </div>
      <div class="form-group"
        *ngIf="data.cotutelle && data.cotutelleDefenceOut">
        <label>
          {{ 'cotutelle-thesis-committe-composition' | translate }}
        </label>
        <file-input name="cotutelleComposition"
          required
          #cotutelleCompositionInput="ngModel"
          [invalid]="cotutelleCompositionInput.invalid && f.submitted"
          [(ngModel)]="data.cotutelleComposition"></file-input>
        <validation-errors [show]="f.submitted"
          [control]="cotutelleCompositionInput"></validation-errors>
        <small class="form-text text-muted">
          {{ 'cotutelle-thesis-committe-composition-note' | translate }}
        </small>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">{{ 'thesis-compendium' | translate }}</h5>

      <div class="form-group form-check">
        <input type="checkbox"
          class="form-check-input"
          name="thesisCompendiumCheck"
          id="thesisCompendiumCheck"
          [(ngModel)]="data.compendium">
        <label class="form-check-label"
          for="thesisCompendiumCheck">
          {{ 'check-if-thesis-compendium' | translate }}
        </label>
      </div>

      <div class="form-group"
        *ngIf="data.compendium"
        [@appear]>
        <label>
          {{ 'compendium-coauthors-autorization' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="coauthorsAutorizationInput"></validation-errors>
        <files-queue name="coauthorsAutorization"
          [required]="true"
          #coauthorsAutorizationInput="ngModel"
          [(ngModel)]="data.coauthorsAutorization"></files-queue>
        <small class="form-text text-muted">
          {{ 'compendium-coauthors-autorization-note' | translate }}
        </small>
      </div>
      <div class="form-group"
        *ngIf="data.compendium && allowCompendiumDirectorReport"
        [@appear]>
        <label>
          {{ 'compendium-director-report' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="compendiumDirectorReportInput"></validation-errors>
        <files-queue name="compendiumDirectorReport"
          [required]="true"
          #compendiumDirectorReportInput="ngModel"
          [(ngModel)]="data.compendiumDirectorReport"></files-queue>
      </div>
    </div>
  </div>

  <div class="row"
    *ngIf="!data.cotutelle || !data.cotutelleDefenceOut">
    <div class="col-md-6 pr-md-1">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">{{ 'international-mention' | translate }}</h5>

          <div class="form-group form-check">
            <input type="checkbox"
              class="form-check-input"
              name="internationalMentionCheck"
              id="internationalMentionCheck"
              [(ngModel)]="data.internationalMention">
            <label class="form-check-label"
              for="internationalMentionCheck">
              {{ 'check-if-international-mention' | translate }}
            </label>
          </div>

          <div class="form-group"
            *ngIf="data.internationalMention"
            [@appear]>
            <label>
              {{ 'international-stay-evidence' | translate }}
            </label>
            <validation-errors [show]="f.submitted"
              [control]="internationalStayEvidenceInput"></validation-errors>
            <files-queue name="internationalStayEvidence"
              [required]="true"
              #internationalStayEvidenceInput="ngModel"
              [(ngModel)]="data.internationalStayEvidence"></files-queue>
            <small class="form-text text-muted">
              {{ 'international-stay-evidence-note' | translate }}
            </small>
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-6 pl-md-1">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">{{ 'industrial-mention' | translate }}</h5>

          <div class="form-group form-check">
            <input type="checkbox"
              class="form-check-input"
              name="industrialMentionCheck"
              id="industrialMentionCheck"
              [(ngModel)]="data.industrialMention">
            <label class="form-check-label"
              for="industrialMentionCheck">
              {{ 'check-if-industrial-mention' | translate }}
            </label>
          </div>

          <div class="form-group"
            *ngIf="data.industrialMention"
            [@appear]>
            <label>
              {{ 'industrial-mention-evidence' | translate }}
            </label>
            <validation-errors [show]="f.submitted"
              [control]="industrialMentionEvidenceInput"></validation-errors>
            <files-queue name="industrialMentionEvidence"
              [required]="true"
              #industrialMentionEvidenceInput="ngModel"
              [(ngModel)]="data.industrialMentionEvidence"></files-queue>
            <small class="form-text text-muted"
              [innerHTML]="'industrial-mention-evidence-note' | translate">
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">{{ 'ethics-report' | translate }}</h5>

      <div class="form-group form-check">
        <input type="checkbox"
          class="form-check-input"
          name="needsEthicsReportCheck"
          id="needsEthicsReportCheck"
          [(ngModel)]="data.needsEthicsReport">
        <label class="form-check-label"
          for="needsEthicsReportCheck"
          [innerHTML]="'check-if-needs-ethics-report' | translate">
        </label>
      </div>

      <div class="form-group"
        *ngIf="data.needsEthicsReport"
        [@appear]>
        <label>
          {{ 'ethics-committee-report' | translate }}
        </label>
        <file-input name="ethicsReport"
          required
          #ethicsReportInput="ngModel"
          [invalid]="ethicsReportInput.invalid && f.submitted"
          [(ngModel)]="data.ethicsReport"></file-input>
        <validation-errors [show]="f.submitted"
          [control]="ethicsReportInput"></validation-errors>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!data.cotutelle || !data.cotutelleDefenceOut">
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">{{ 'thesis-committee' | translate }}</h5>

        <div class="form-group row">
          <div class="col-md-4 pr-md-0">
            <label>{{ 'president' | translate }}</label>
            <user-info-selector [(ngModel)]="data.thesisCommittee.president"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="president"></user-info-selector>
          </div>
          <div class="col-md-4 px-md-2">
            <label>{{ 'secretary' | translate }}</label>
            <user-info-selector [(ngModel)]="data.thesisCommittee.secretary"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="secretary"></user-info-selector>
          </div>
          <div class="col-md-4 pl-md-0">
            <label>{{ 'member' | translate }}</label>
            <user-info-selector [(ngModel)]="data.thesisCommittee.member"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="member"></user-info-selector>
          </div>
        </div>

      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">{{ 'thesis-committee-alt' | translate }}</h5>

        <div class="form-group row">
          <div class="col-md-4 pr-md-0">
            <label>{{ 'first-alternate' | translate }}</label>
            <user-info-selector [(ngModel)]="data.thesisCommittee.alt1"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="alt1"></user-info-selector>
          </div>
          <div class="col-md-4 px-md-2">
            <label>{{ 'second-alternate' | translate }}</label>
            <user-info-selector [(ngModel)]="data.thesisCommittee.alt2"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="alt2"></user-info-selector>
          </div>
          <div class="col-md-4 pl-md-0">
            <label>{{ 'third-alternate' | translate }}</label>
            <user-info-selector [(ngModel)]="data.thesisCommittee.alt3"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="alt3"></user-info-selector>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">{{ 'external-evaluators' | translate }}</h5>
        <div class="alert alert-info"
          *ngIf="data.internationalMention"
          [@appear]>
          <i class="far fa-info mr-2"></i>
          {{ 'international-mention-evaluators-note' | translate }}
        </div>
        <div class="form-group row">
          <div class="col-md-4 pr-md-0">
            <label>{{ 'first-evaluator' | translate }}</label>
            <user-info-selector [(ngModel)]="data.externalEvaluators.ev1"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="ev1"></user-info-selector>
          </div>
          <div class="col-md-4 px-md-2">
            <label>{{ 'second-evaluator' | translate }}</label>
            <user-info-selector [(ngModel)]="data.externalEvaluators.ev2"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="ev2"></user-info-selector>
          </div>
          <div class="col-md-4 pl-md-0">
            <label>{{ 'third-evaluator' | translate }}</label>
            <user-info-selector [(ngModel)]="data.externalEvaluators.ev3"
              [showErrors]="f.submitted"
              [sm]="true"
              [showCountry]="true"
              [showCV]="true"
              [required]="true"
              [externals]="true"
              name="ev3"></user-info-selector>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="form-group text-right mt-2">
    <div class="d-block invalid-feedback mb-2"
      *ngIf="f.invalid && f.submitted">
      {{ 'check-errors' | translate }}
    </div>

    <button [class]="'btn btn-' + sendBtnClass"
      type="submit">
      {{ sendBtnText | translate}}
    </button>
  </div>


</form>