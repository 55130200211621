import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { IIndustrialMentionInfo } from '../../../../../../interfaces/IStudent';
import { StudentFileService } from '../student-file.service';
import { IndustrialMentionHttpService } from '../../../../data-services/industrial-mention-http.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'industrial-mention-launch',
  templateUrl: './industrial-mention-launch.component.html',
})
export class IndustrialMentionLaunchComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('f') f: NgForm;

  requested = false;

  data: IIndustrialMentionInfo = {
    industrialMention: true,
    agreementDocs: [],
    agreementDate: null,
    organization: '',
    organizationContact: ''
  }

  constructor(private ts: TranslateService,
    private cds: ConfirmationDialogService,
    private industrial: IndustrialMentionHttpService,
    private sfs: StudentFileService,
    private refresh: RefreshService) {
    super();
  }

  ngOnInit() {
    if (this.sfs.student.industrialMentionInfo) {
      this.data = this.sfs.student.industrialMentionInfo;
    }
  }

  manageSend(data: IIndustrialMentionInfo) {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    console.log(data);
    this.industrial.launch(this.sfs.student._id, data).subscribe(() => {
      this.requested = true;
      this.sendEnd.next();
      this.refresh.onStudentChange.next();
    })
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate() {
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

}
