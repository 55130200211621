<div class="row"
  *ngIf="program">

  <div class="col-12 col-md-6">
    <h3>
      {{ 'MARCH' | translate }}
    </h3>
    <h5>
      <form class="form-inline">
        <label class="mr-1">
          {{ 'march1to' | translate }}
        </label>

        <ng-select [(ngModel)]="end1date"
          name="end1date"
          [placeholder]="'day' | translate"
          [items]="dates1"
          [searchable]="false"
          [clearable]="false"
          style="min-width: 100px;"
          [disabled]="!edit1">
        </ng-select>

        <label class="mx-2">
          de
        </label>

        <ng-select [(ngModel)]="end1month"
          (ngModelChange)="setDates()"
          name="end1month"
          [placeholder]="'month' | translate"
          [searchable]="false"
          [clearable]="false"
          style="min-width: 150px;"
          [disabled]="!edit1">
          <ng-option [value]="3">{{ 'MARCH' | translate}} </ng-option>
          <ng-option [value]="4">{{ 'APRIL' | translate }} </ng-option>
        </ng-select>

        <a [routerLink]="null"
          role="button"
          class="ml-2 text-info"
          [tooltip]="'edit-evaluation-ending-date' | translate"
          (click)="edit1 = true"
          *ngIf="!edit1">
          <i class="far fa-edit"></i>
        </a>

        <div *ngIf="edit1"
          class="w-100 text-right mt-2">
          <button class="btn btn-outline-success ml-2"
            role="button"
            (click)="save1()">
            <i class="far fa-check"></i>
            {{ 'accept' | translate }}
          </button>
          <button class="btn btn-outline-dark ml-2"
            role="button"
            (click)="init1(); setDates(); edit1 = false;">
            <i class="far fa-undo"></i>
          </button>
        </div>
      </form>
    </h5>

    <div class="alert alert-info"
      *ngIf="isInEvaluationPeriod1">
      <i class="fas fa-info-circle"></i>
      <span>
        {{ 'current-evaluation-period-change-dates-info' | translate }}
      </span>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <h3>
      {{ 'SEPTEMBER' | translate }}
    </h3>
    <h5>
      <form class="form-inline">
        <label class="mr-1">
          {{ 'september1to' | translate }}
        </label>

        <ng-select [(ngModel)]="end2date"
          name="end2date"
          [placeholder]="'day' | translate"
          [items]="dates2"
          [searchable]="false"
          [clearable]="false"
          style="min-width: 100px;"
          [disabled]="!edit2">
        </ng-select>

        <label class="mx-2">
          de
        </label>

        <ng-select [(ngModel)]="end2month"
          (ngModelChange)="setDates()"
          name="end2month"
          [placeholder]="'month' | translate"
          style="min-width: 150px;"
          [searchable]="false"
          [clearable]="false"
          [disabled]="!edit2">
          <ng-option [value]="9">{{ 'SEPTEMBER' | translate}} </ng-option>
          <ng-option [value]="10">{{ 'OCTOBER' | translate }} </ng-option>
        </ng-select>

        <a [routerLink]="null"
          role="button"
          class="ml-2 text-info"
          [tooltip]="'edit-evaluation-ending-date' | translate"
          (click)="edit2 = true"
          *ngIf="!edit2">
          <i class="far fa-edit"></i>
        </a>

        <div *ngIf="edit2"
          class="w-100 text-right mt-2">
          <button class="btn btn-outline-success ml-2"
            role="button"
            (click)="save2()">
            <i class="far fa-check"></i>
            {{ 'accept' | translate }}
          </button>
          <button class="btn btn-outline-dark ml-2"
            role="button"
            (click)="init2(); setDates(); edit2 = false;"
            *ngIf="edit2">
            <i class="far fa-undo"></i>
          </button>
        </div>
      </form>
    </h5>
    <div class="alert alert-info"
      *ngIf="isInEvaluationPeriod2">
      <i class="fas fa-info-circle mr-2"></i>
      <span>
        {{ 'current-evaluation-period-change-dates-info' | translate }}
      </span>
    </div>
  </div>

</div>