import { IProgram } from './IProgram';
import { IStudent } from './IStudent';

export interface IEvaluationControl {
  _id?: string,
  year: string,
  period: string,
  programId: string,

  included: IEvaluationStudent[],
  excluded: IEvaluationStudent[],

  // populate
  program?: IProgram
}

export interface IEvaluationStudent {
  _id?: string,
  studentId: string,
  reason: EvaluationReason
  // Populate
  student?: IStudent
}


export enum EvaluationReason {
  // Must evaluate reasons:
  firstEvaluation = "firstEvaluation",
  oneYear = "oneYear",
  leaveMustEvaluate = "leaveMustEvaluate",
  forceInclude = "forceInclude",

  // Excluding reasons:
  noEvaluationData = "noEvaluationData",
  leaveDuringEvaluation = "leaveDuringEvaluation",
  lastEvaluationPositive = "lastEvaluationPositive",
  forceExclude = "forceExclude",
  lastEvaluationNegative = "lastEvaluationNegative",
  firstYear = "firstYear"
}