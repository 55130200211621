import { Component, OnInit } from '@angular/core';
import { IMailTimeEmail } from '../../../../../../../interfaces/IMailTimeEmail';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'print-email',
  templateUrl: 'print-email-view.component.html'
})
export class PrintEmailViewComponent implements OnInit {
  email: IMailTimeEmail;

  constructor(private http: HttpClient,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const mailId = params.mailId;
      this.http.get<IMailTimeEmail>(`/api/emails/find/${mailId}`).subscribe((email) => {
        this.email = email;

        setTimeout(() => {
          window.print();
        }, 2000);
      })
    })
  }
}