import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { IStudent } from '../../../../../../../interfaces/IStudent';
import {
  IThesisCommitteeMemberData,
  IThesisProcessData,
  ThesisReportEvaluation,
} from '../../../../../../../interfaces/IThesisProcess';
import { UserService } from '../../../../../user/user.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';

@Component({
  selector: 'thesis-data-view',
  templateUrl: './thesis-data-view.component.html'
})
export class ThesisDataViewComponent implements OnInit {

  @Input() student: IStudent;
  @Input() data: IThesisProcessData;
  @Input() selectedEvaluators: {
    ev1: IThesisCommitteeMemberData,
    ev2: IThesisCommitteeMemberData
  }
  @Input() reports: {
    memberId: string,
    memberName: string,
    evaluation: ThesisReportEvaluation,
    docRef: IDocumentRef
  }[]

  @Input() showThesisCommittee = true;
  @Input() showAll = true;
  @Input() hideCompendiumDirectorReport = false;

  @Output() onSaveExpenseDocDate = new EventEmitter<{
    ev: number,
    evId: string,
    expenseDocDate: Date,
    expenseDocNote: string,
  }>()
  @Output() onEditReports = new EventEmitter<any>();

  ThesisReportEvaluation = ThesisReportEvaluation;

  constructor(public us: UserService, public sh: ServiceHttpService) { }

  ngOnInit() {
  }

}
