<h5 class="card-title"
  *ngIf="showTitle">
  {{ hs.name(task) | translate }}
</h5>

<h6 class="card-subtitle mb-2"
  *ngIf="showTitle && task.external && task.assigneeName">
  {{ task.assigneeName }}
</h6>

<div class="pre"
  *ngIf="shorDesc"
  [innerHtml]="hs.descKey(task) | translate:hs.descParams(task)">
</div>