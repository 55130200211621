import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { IDocumentRef } from '../../../../interfaces/IDocument';
import { ITask } from '../../../../interfaces/ITask';
import { ThesisReportEvaluation } from '../../../../interfaces/IThesisProcess';
import { ExternalTaskHttpService } from '../../data-services/external-task-http.service';
import { AbstractTaskComponent } from '../../root/components/process/abstract-task.component';

@Component({
  selector: 'external-evaluator-report',
  templateUrl: './external-evaluator-report.component.html',
})
export class ExternalEvaluatorReportComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;
  @Input() token: string;

  @Output() onComplete = new EventEmitter();

  evaluation: ThesisReportEvaluation;
  docRef: IDocumentRef;

  ThesisReportEvaluation = ThesisReportEvaluation

  constructor(private et: ExternalTaskHttpService) {
    super();
  }

  ngOnInit() {

  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    this.et.thesisReport(this.task._id, this.token, { send: true, evaluation: this.evaluation, docRef: this.docRef }).subscribe(() => {
      this.sendEnd.next();
      this.onComplete.emit();
    })
  }

}
