import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';

import { EvaluationPState, IEvaluationProcess } from '../../../../../../interfaces/IEvaluationProcess';
import { PermissionsType } from '../../../../../../interfaces/IPermissions';
import { EvaluationPeriodType, IStudent, RDType } from '../../../../../../interfaces/IStudent';
import { Processes } from '../../../../../../interfaces/ITask';
import { DADUtils } from '../../../../../../utils/DADUtils';
import { EvaluationControlHttpService } from '../../../../data-services/evaluation-control-http.service';
import { EvaluationHttpService } from '../../../../data-services/evaluation-http.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { UserService } from '../../../../user/user.service';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { Subscription } from 'rxjs';
import { StudentRootService } from '../../../routes/student/student-root/student-root.service';

@Component({
  selector: 'student-evaluation-view',
  templateUrl: './student-evaluation-view.component.html',
})
export class StudentEvaluationViewComponent implements OnInit, OnDestroy {

  @Input() student: IStudent;

  @Input() requestEnabled: { enabled: boolean, reasons: string[] };

  processes: IEvaluationProcess[] = [];
  ready = false;

  mustEvaluate;

  EvaluationPState = EvaluationPState;
  DADUtils = DADUtils;
  PermissionsType = PermissionsType;
  Processes = Processes;
  RDType = RDType;

  sub: Subscription;

  constructor(private evs: EvaluationHttpService,
    private ecs: EvaluationControlHttpService,
    private rs: RefreshService,
    public us: UserService,
    public sh: ServiceHttpService,
    public sr: StudentRootService,
    public hs: HelperService) { }

  ngOnInit() {
    this.load();
    this.sub = this.rs.onEvaluationProcessChange.subscribe(() => {
      this.load();
    })
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  private load() {
    this.evs.getTables(this.student._id).subscribe((res) => {
      this.processes = res;
      if (DADUtils.isInEvaluation(this.student.program)) {
        const ev = DADUtils.lastEvaluation();
        this.ecs.mustEvaluate(this.student._id, this.student.programId, ev.year, ev.period).subscribe((res) => {
          this.mustEvaluate = res.mustEvaluate;
          this.ready = true
        })
      } else {
        this.ready = true;
      }
    })
  }

  isStarted() {
    return this.processes.findIndex(p => p.year === this.getCurrentYear() && p.period === this.getCurrentPeriod()) > -1
  }

  getCurrentYear() {
    return `${moment().year() - 1}${moment().year()}`
  }

  getCurrentPeriod() {
    return moment().month() >= 8 ? EvaluationPeriodType.september : EvaluationPeriodType.march;
  }

  canModify(p: IEvaluationProcess) {
    return p.state === EvaluationPState.PendingFirstReport && DADUtils.isInEvaluation(this.student.program);
  }

}
