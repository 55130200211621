import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CdsReason, ICDSProcess, ICDSRequestData } from '../../../interfaces/ICDSProcess';
import { IDocumentRef } from '../../../interfaces/IDocument';

@Injectable()
export class CDSHttpService {

  constructor(private http: HttpClient) { }

  request(studentId: string, data: ICDSRequestData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/cds/request/${studentId}`, data);
  }

  academicCommitteeValidation(taskId: string, data: {
    lineOfResearchId: string;
    tutorReason: CdsReason[];
    supervisor1Reason: CdsReason[];
    supervisor2Reason?: CdsReason[];
    supervisor3Reason?: CdsReason[];
    needsSupplement: boolean;
    supplement: {
      credits: number;
      document?: IDocumentRef;
      notes?: string;
    }
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cds/academicCommitteeValidation/${taskId}`, data);
  }

  academicCommitteeDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cds/academicCommitteeDeny/${taskId}`, data);
  }

  studentRequestReview(taskId: string, data: ICDSRequestData): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/cds/studentRequestReview/${taskId}`, data);
  }

  serviceDenyValidation(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cds/serviceDenyValidation/${taskId}`, data)
  }

  serviceValidation(taskId: string, data: ICDSRequestData): Observable<{ ok: boolean, followUpTaskId?: string }> {
    return this.http.post<{ ok: boolean, followUpTaskId?: string }>(`/api/cds/serviceValidation/${taskId}`, data);
  }

  pgcDenyValidation(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cds/pgcDenyValidation/${taskId}`, data);
  }

  pgcValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cds/pgcValidation/${taskId}`, {});
  }

  serviceDirectionTeamIds(taskId: string, data: {
    directionIds:
    {
      tutor: string,
      supervisor1: string,
      supervisor2?: string,
      supervisor3?: string
    },
    isNew: {
      supervisor1: boolean,
      supervisor2: boolean,
      supervisor3: boolean
    }
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cds/serviceDirectionTeamIds/${taskId}`, data);
  }

  signDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cds/signDeny/${taskId}`, data);
  }

  sign(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/cds/sign/${taskId}`, {});
  }

  findProcess(taskId: string): Observable<ICDSProcess> {
    return this.http.get<ICDSProcess>(`/api/cds/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<ICDSProcess> {
    return this.http.get<ICDSProcess>(`/api/cds/process/${studentId}`);
  }

}