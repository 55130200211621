<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="evaluationCommittees"
        icon="users">
      </section-title>

    </div>
  </div>

  <evaluation-committees [programId]="us.selectedRole.groupId"></evaluation-committees>
</div>