<table class="table m-0">
  <thead>
    <tr>
      <th class="border-top-0">{{ 'pending-tasks' | translate }}</th>
      <th class="border-top-0">{{ 'responsible' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let task of tasks; trackBy:hs.trackById">
      <td>
        <a [routerLink]="'/app/task/' + task._id"
          *ngIf="!task.external && us.hasPermission(task); else plainTaskName">
          {{ hs.name(task) | translate }}
        </a>
        <ng-template #plainTaskName>
          {{ hs.name(task) | translate }}
        </ng-template>
        <div *ngIf="task.external && us.isService() && task.taskKey !== TKThesis.cumLaudeVote">
          <button class="btn btn-sm btn-link mt-2"
            (click)="onExternalLink.emit(task)">
            <i class="far fa-external-link mr-1"></i>
            {{ 'check-external-link' | translate }}
          </button>
        </div>
      </td>
      <td *ngIf="!task.assigneeName">
        <span
          *ngIf="task.processKey === Processes.thesis && (task.taskKey === TKThesis.postgraduateCommitteeValidation || task.taskKey === TKThesis.defencePostgraduateCommitteeValidation); else roleName">
          {{ 'postgraduateCommittee' | translate}}
        </span>
        <ng-template #roleName>
          <ul *ngIf="task.roles"
            class="list-unstyled m-0">
            <li *ngFor="let role of task.roles">
              {{ hs.roleName(role) | translate }}
              <span *ngIf="tasksRoleNames[task._id]">
                <span *ngFor="let u of tasksRoleNames[task._id]; let i=index">
                  <span *ngIf="i === 0"
                    class="mr-1">
                    :
                  </span>
                  {{ u }}
                  <span class="mx-1"
                    *ngIf="i !== tasksRoleNames[task._id].length - 1">
                    -
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </ng-template>
        <span *ngIf="task.assigneeId && task.assignee">
          {{ task.assignee | fullname }}
        </span>
      </td>
      <td *ngIf="task.assigneeName">
        <span *ngFor="let role of task.roles">
          {{ hs.roleName(role) | translate }} -
        </span>
        {{ task.assigneeName }}

        <ng-container
          *ngIf="task.external && us.isService() && ss.editMode && (task.taskKey === TKThesis.externalEvaluatorAcceptance || task.taskKey === TKThesis.externalEvaluatorReport)">
          <br>

          <button class="btn btn-sm btn-outline-dark mt-2"
            type="button"
            (click)="onChangeExternalEvaluator.emit(task)">
            {{ 'change-external-evaluator' | translate }}
          </button>
        </ng-container>

      </td>
    </tr>
  </tbody>

</table>