import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDischargeProcess } from '../../../interfaces/IDischargeProcess';
import { IDocumentRef } from '../../../interfaces/IDocument';

@Injectable()
export class DischargeHttpService {

  constructor(private http: HttpClient) { }

  request(studentId: string, data: { justification: IDocumentRef[], end: Date, leaveId: string }): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/discharge/request/${studentId}`, data);
  }

  academicCommitteeValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/discharge/academicCommitteeValidation/${taskId}`, {});
  }

  academiccommitteeDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/discharge/academiccommitteeDeny/${taskId}`, data);
  }

  serviceValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/discharge/serviceValidation/${taskId}`, {});
  }

  serviceDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/discharge/serviceDeny/${taskId}`, data);
  }

  findProcess(taskId: string): Observable<IDischargeProcess> {
    return this.http.get<IDischargeProcess>(`/api/discharge/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<IDischargeProcess[]> {
    return this.http.get<IDischargeProcess[]>(`/api/discharge/process/${studentId}`);
  }

}