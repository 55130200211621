import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { IUser } from '../../../interfaces/IUser';
import { PopAlertsService } from '../UI/pop-alerts/pop-alerts.service';

@Injectable()
export class ImpersonatingService {
  impersonating: IUser;

  ready = new BehaviorSubject<boolean>(undefined);

  constructor(private http: HttpClient) {
    this.isImpersonated().subscribe((res) => {
      if (res.user) {
        this.impersonating = res.user;
      } else {
        this.impersonating = null;
      }
      this.ready.next(true);
    })
  }

  impersonate(userId: string): Observable<{ ok: string }> {
    return this.http.post<{ ok: string }>(`/api/login/impersonate/${userId}`, {});
  }

  stopImpersonate(): Observable<{ ok: string }> {
    return this.http.post<{ ok: string }>(`/api/login/stopImpersonate`, {});
  }

  isImpersonated(): Observable<{ user: IUser }> {
    return this.http.get<{ user: IUser }>(`/api/login/isImpersonated`);
  }

}