import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IActivity } from '../../../interfaces/IActivity';

@Injectable()
export class ActivityValidationHttpService {

  constructor(private http: HttpClient) { }

  getActivity(taskId: string): Observable<IActivity> {
    return this.http.get<IActivity>(`/api/validation/task/${taskId}/activity`);
  }

  requestValidation(activityId: string): Observable<any> {
    return this.http.post<any>(`/api/validation/request/${activityId}`, {});
  }

  validate(taskId: string): Observable<any> {
    return this.http.post<any>(`/api/validation/validate/${taskId}`, {});
  }

  deny(taskId: string, validationMsg: string): Observable<any> {
    return this.http.post<any>(`/api/validation/deny/${taskId}`, { validationMsg });
  }

  resendValidation(taskId: string): Observable<any> {
    return this.http.post<any>(`/api/validation/resend/${taskId}`, {});
  }

  resendValidationActivity(activityId: string): Observable<any> {
    return this.http.post<any>(`/api/validation/resend/activity/${activityId}`, {});
  }

}