<ul class="nav flex-column">
  <student-menu *ngIf="us.selectedRole.role === Role.student"></student-menu>
  <tutor-menu *ngIf="us.selectedRole.role === Role.tutor"></tutor-menu>
  <evaluation-committee-menu
    *ngIf="us.selectedRole.role === Role.evaluationCommitteeMember"></evaluation-committee-menu>
  <supervisor-menu *ngIf="us.selectedRole.role === Role.supervisor"></supervisor-menu>
  <service-menu *ngIf="us.selectedRole.role === Role.service"></service-menu>
  <qa-menu *ngIf="us.selectedRole.role === Role.qa"></qa-menu>
  <coordinator-menu *ngIf="us.selectedRole.role === Role.coordinator"></coordinator-menu>
  <academic-committee-menu *ngIf="us.selectedRole.role === Role.academicCommittee"></academic-committee-menu>
  <secretary-menu
    *ngIf="us.selectedRole.role === Role.secretary || us.selectedRole.role === Role.chiefSecretary"></secretary-menu>
</ul>