export class StringUtils {

  static diacriticsRegex(string = '') {
    return string
      .replace(/a/g, '[a,á,à,ä,â]')
      .replace(/A/g, '[A,a,á,à,ä,â]')
      .replace(/e/g, '[e,é,ë,è]')
      .replace(/E/g, '[E,e,é,ë,è]')
      .replace(/i/g, '[i,í,ï,ì]')
      .replace(/I/g, '[I,i,í,ï,ì]')
      .replace(/o/g, '[o,ó,ö,ò]')
      .replace(/O/g, '[O,o,ó,ö,ò]')
      .replace(/u/g, '[u,ü,ú,ù]')
      .replace(/U/g, '[U,u,ü,ú,ù]');
  }

  static firstLetterToUpperCase(str) {
    if (!str) return '';
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
  }

  static guidGenerator() {
    const S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
  }

  static replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }


}