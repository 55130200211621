import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ITask } from '../../../../../../../interfaces/ITask';
import { IThesisProcess } from '../../../../../../../interfaces/IThesisProcess';
import { Group, Role } from '../../../../../../../interfaces/IUser';
import { ThesisHttpService } from '../../../../../data-services/thesis-http.service';
import { HelperService } from '../../../../../shared/helper-service/helper.service';
import { UserService } from '../../../../../user/user.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'thesis-direction-team-validation',
  templateUrl: './thesis-direction-team-validation.component.html'
})
export class ThesisDirectionTeamValidationComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IThesisProcess;
  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  constructor(private thesis: ThesisHttpService,
    private router: Router,
    private us: UserService,
    private hs: HelperService) {
    super();
  }

  ngOnInit() {
    this.thesis.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      this.hs.scrollToInvalidFeedback();
      return;
    }

    if (!this.validation.validate) {
      this.thesis.directionTeamDeny(this.task._id, { denialMsg: this.validation.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.thesis.directionTeamValidate(this.task._id, this.process.data.compendiumDirectorReport).subscribe((res) => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

  isSupervisor() {
    return this.us.user.roles.includes(`${Role.supervisor}-${Group.student}-${this.process.studentId}`)
  }

}
