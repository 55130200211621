import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProcess } from '../../../../../../interfaces/IProcess';
import { Processes } from '../../../../../../interfaces/ITask';
import { RDType } from '../../../../../../interfaces/IStudent';
import { Router } from '@angular/router';
import { CDSPState, ICDSProcess } from '../../../../../../interfaces/ICDSProcess';
import { IValidationProcess, VPState } from '../../../../../../interfaces/IValidationProcess';
import { EvaluationPState, IEvaluationProcess } from '../../../../../../interfaces/IEvaluationProcess';
import { IThesisProcess, ThesisPState } from '../../../../../../interfaces/IThesisProcess';
import { DedicationPState, IDedicationProcess } from '../../../../../../interfaces/IDedicationProcess';
import { DirectionPState, IDirectionProcess } from '../../../../../../interfaces/IDirectionProcess';
import { ExtensionPState, IExtensionProcess } from '../../../../../../interfaces/IExtensionProcess';
import { ILeaveProcess, LeavePState } from '../../../../../../interfaces/ILeaveProcess';
import { DischargePState, IDischargeProcess } from '../../../../../../interfaces/IDischargeProcess';
import { IPermanentLeaveProcess, PermanentLeavePState } from '../../../../../../interfaces/IPermanentLeaveProcess';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';
import { RenewalPState } from '../../../../../../interfaces/IRenewalProcess';
import { IndustrialPState } from '../../../../../../interfaces/IIndustrialProcess';
import { IProfessorProcess, PPState, TKProfessor } from '../../../../../../interfaces/IProfessorProcess';
import { UserService } from '../../../../user/user.service';
import { Role } from '../../../../../../interfaces/IUser';

@Component({
  selector: 'processes-search',
  templateUrl: 'processes-search.component.html'
})
export class ProcessesSearchComponent implements OnInit {

  @Input() processType: Processes;
  @Output() processTypeChange = new EventEmitter<Processes>();
  @Input() dataFn: (params: any) => Promise<ICDSProcess[] | IValidationProcess[] | IEvaluationProcess[] | IThesisProcess[] | IDedicationProcess[] | IDirectionProcess[] | IExtensionProcess[] | ILeaveProcess[] | IDischargeProcess[] | IPermanentLeaveProcess[] | IProfessorProcess[]>;
  @Input() showFilters = true;
  @Input() program = true;
  @Input() idNum = true;
  @Input() rd = true;

  @Input() title = '';

  processes: ICDSProcess[] | IValidationProcess[] | IEvaluationProcess[] | IThesisProcess[] | IDedicationProcess[] | IDirectionProcess[] | IExtensionProcess[] | ILeaveProcess[] | IDischargeProcess[] | IPermanentLeaveProcess[] = [];

  states: CDSPState[] | VPState[] | EvaluationPState[] | ThesisPState[] | DedicationPState[] | DirectionPState[] | ExtensionPState[] | LeavePState[] | DischargePState[] | PermanentLeavePState[] | PPState[] = [];

  filters: { programs: string[], states: string[], searchText: string, rd?: RDType } = {
    programs: [],
    states: [],
    searchText: '',
    rd: null
  };

  selecteableProcesses: Processes[] = [Processes.thesis, Processes.cds, Processes.direction, Processes.industrial, Processes.dedication, Processes.evaluation, Processes.leave, Processes.extension, Processes.discharge, Processes.renewal, Processes.permanentLeave, Processes.professor];

  RDType = RDType;
  Processes = Processes;
  TKProfessor = TKProfessor;
  Role = Role;

  constructor(private router: Router,
    public ps: ProgramHttpService,
    public hs: HelperService,
    public us: UserService) { }

  ngOnInit() {
    if (!this.processType) {
      this.processType = Processes.thesis;
    }
    this.updateStates();
  }

  updateStates() {
    this.filters.states = [];
    switch (this.processType) {
      case Processes.cds:
        this.states = Object.keys(CDSPState).map(k => CDSPState[k]);
        break;

      case Processes.validation:
        this.states = Object.keys(VPState).map(k => VPState[k]);
        break;

      case Processes.evaluation:
        this.states = Object.keys(EvaluationPState).map(k => EvaluationPState[k]);
        break;

      case Processes.thesis:
        this.states = Object.keys(ThesisPState).map(k => ThesisPState[k]);
        break;

      case Processes.dedication:
        this.states = Object.keys(DedicationPState).map(k => DedicationPState[k]);
        break;

      case Processes.direction:
        this.states = Object.keys(DirectionPState).map(k => DirectionPState[k]);
        break;

      case Processes.industrial:
        this.states = Object.keys(IndustrialPState).map(k => IndustrialPState[k]);
        break;

      case Processes.extension:
        this.states = Object.keys(ExtensionPState).map(k => ExtensionPState[k]);
        break;

      case Processes.leave:
        this.states = Object.keys(LeavePState).map(k => LeavePState[k]);
        break;

      case Processes.discharge:
        this.states = Object.keys(DischargePState).map(k => DischargePState[k]);
        break;

      case Processes.renewal:
        this.states = Object.keys(RenewalPState).map(k => RenewalPState[k]);
        break;

      case Processes.permanentLeave:
        this.states = Object.keys(PermanentLeavePState).map(k => PermanentLeavePState[k]);
        break;

      case Processes.professor:
        this.states = Object.keys(PPState).map(k => PPState[k]);
        break;

      default:
        break;
    }
  }

  fetchFn() {
    return (params) => {
      // combine params
      params = {
        ...params,
        ...this.filters
      }
      return this.dataFn(params);
    }
  }

  setProcesses(processes: ICDSProcess[] | IValidationProcess[] | IEvaluationProcess[] | IThesisProcess[] | IDedicationProcess[] | IDirectionProcess[] | IExtensionProcess[] | ILeaveProcess[] | IDischargeProcess[] | IPermanentLeaveProcess[] | IProfessorProcess[]) {
    this.processes = processes.map((process) => {
      if (this.processType !== Processes.professor && this.ps.programsMap[process.student.programId])
        process.student.program = this.ps.programsMap[process.student.programId];
      return process;
    })
    console.log(this.processes);
  }

  gotToStudent(studentId) {
    let url = '';
    switch (this.processType) {
      case Processes.cds:
      case Processes.dedication:
      case Processes.direction:
      case Processes.industrial:
        url = '/cds';
        break;

      case Processes.evaluation:
        url = '/evaluation';
        break;

      case Processes.thesis:
        url = '/thesis';
        break;

      case Processes.extension:
      case Processes.leave:
      case Processes.discharge:
      case Processes.renewal:
        url = '/processes';
        break;
    }

    void this.router.navigate(['/app/st/' + studentId + url]);
  }


}