<div *ngIf="isThemeLight" class="tooltip-arrow"></div>

<div *ngIf="options['contentType'] === 'template' else htmlOrStringTemplate">

	<ng-container *ngTemplateOutlet="value"></ng-container>
</div>

<ng-template #htmlOrStringTemplate>
	<div [innerHTML]="value"></div>
</ng-template>
