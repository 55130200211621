import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'section-title',
  templateUrl: 'section-title.component.html',
  styleUrls: ['section-title.component.scss']
})

export class SectionTitleComponent implements OnInit {

  @Input() text;
  @Input() additionalText;
  @Input() iconType = 'r';
  @Input() icon;

  @Input() btnIcon;
  @Input() btnText;
  @Input() btnClasses;
  @Input() btnShow = true;

  @Output() btnClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }
}