<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="evaluationCommittees"
        icon="users">
      </section-title>

    </div>
  </div>

  <div class="row">

    <ng-container *ngFor="let com of committees; trackBy:hs.trackById">

      <div class="col-12 mb-4 mt-4"
        *ngIf="!com.deleted">

        <h5>
          {{ hs.ecCode(com) }}
        </h5>

        <div class="row">
          <div class="col-12 col-md-6 pr-md-0">
            <div class="card">
              <div class="card-header">
                <strong>
                  {{ 'members' | translate }}
                </strong>
              </div>
              <div class="list-group">
                <div class="list-group-item"
                  *ngFor="let member of com.members; trackBy:hs.trackById">
                  <strong *ngIf="member._id === us.user._id">
                    {{ member | fullname }}
                  </strong>
                  <span *ngIf="member._id !== us.user._id">
                    {{ member | fullname }}
                  </span>
                </div>

                <div class="list-group-item"
                  *ngIf="com.members.length <= 0">
                  <i>
                    {{ 'empty-evaluation-committee' | translate }}
                  </i>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 pl-md-0">
            <div class="card">
              <div class="card-header">
                <strong>
                  {{ 'students' | translate }}
                </strong>
              </div>

              <div class="list-group">
                <cdk-virtual-scroll-viewport #scrollViewport
                  [style.height]="Math.min(500, (com.students ? com.students.length : 0) * 50) + 'px'"
                  itemSize="50">
                  <div class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    *cdkVirtualFor="let st of com.students; trackBy:hs.trackById"
                    (click)="goToStudent(st._id)">
                    <span>
                      {{ st | fullname }}
                    </span>
                    <span class="nowrap">
                      <student-state [state]="st.state"></student-state>
                      <student-state *ngFor="let state of st.altStates"
                        [state]="state"
                        [ml]="true"></student-state>
                    </span>
                  </div>
                </cdk-virtual-scroll-viewport>
                <div class="list-group-item"
                  *ngIf="!com.students || com.students.length <= 0">
                  <i>{{ 'no-students-assigned-to-ec' | translate }}</i>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>

    </ng-container>

  </div>

</div>