<div class="list-group"
  *ngIf="disabilities && disabilities.length > 0">
  <div class="list-group-item d-flex justify-content-between align-items-center flex-wrap"
    *ngFor="let disability of disabilities">
    <div *ngIf="disability.justification"
      style="max-width: 250px;">
      <div *ngFor="let doc of disability.justification">
        <document-link [document]="doc"></document-link>
      </div>
    </div>
    <div class="nowrap mt-1">
      {{ disability.start | moment:'D/M/YY' }}
      <span *ngIf="disability.end">
        <i class="far fa-angle-double-right mx-2"></i>
        {{ disability.end | moment:'D/M/YY' }}
      </span>

      <a role="button"
        [routerLink]="null"
        class="text-muted ml-2"
        (click)="onEdit.emit(disability._id)"
        *ngIf="editable">
        <i class="far fa-edit"></i>
      </a>

      <a role="button"
        [routerLink]="null"
        class="text-muted ml-2"
        (click)="onRemove.emit(disability._id)"
        *ngIf="editable">
        <i class="far fa-trash"></i>
      </a>
    </div>
  </div>
</div>
<div class="card-body pt-0"
  *ngIf="disabilities && disabilities.length <= 0">
  <i>
    {{ 'no-disability' | translate }}
  </i>
</div>