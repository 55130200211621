<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="sent-emails"
        icon="envelope">
      </section-title>

    </div>
  </div>

  <form>
    <div class="form-group">
      <search-input name="search"
        [ngModel]="params.searchText"
        (ngModelChange)="params.searchText = $event; list.search()"
        [placeholder]="'search'"></search-input>
    </div>
  </form>

  <infinite-list #list
    [data]="fetchFn()"
    [(array)]="emails">

    <mail-time-email *ngFor="let email of emails; trackBy:tbFn"
      [email]="email"
      (onForward)="sems.forward($event)"></mail-time-email>

  </infinite-list>

</div>