import { Component, OnInit } from '@angular/core';
import { ProgramFileService } from '../program-file.service';

@Component({
  selector: 'program-file-professors',
  templateUrl: './program-file-professors.component.html',
})
export class ProgramFileProfessorsComponent implements OnInit {

  constructor(public pfs: ProgramFileService) { }

  ngOnInit(): void {
  }

}
