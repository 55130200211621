<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="documental-supervision-compromise"
        icon="passport">
      </section-title>

    </div>
  </div>

  <student-cds [student]="sr.student"
    [cdsRequestEnabled]="sr.procStates?.cds"
    [directionRequestEnabled]="sr.procStates?.direction"
    [dedicationRequestEnabled]="sr.procStates?.dedication"></student-cds>

</div>