import { invalid } from '@angular/compiler/src/render3/view/util';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { IDocumentRef } from '../../../../interfaces/IDocument';
import { DocumentService } from '../../shared/document-service/document.service';
import { PopAlertsService } from '../pop-alerts/pop-alerts.service';

@Component({
  selector: 'file-input',
  templateUrl: 'file-input.component.html',
  styleUrls: ['file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true
    }
  ]
})
export class FileInputComponent implements OnInit, ControlValueAccessor, Validator {
  @ViewChild('inputTypeFile', { static: true }) inputTypeFile: ElementRef;

  @Output() complete = new EventEmitter<any>();

  @Input() placeholder;
  @Input() invalid = false;
  @Input() invalidMsg = '';
  @Input() sm = false;

  state = 'EMPTY'; // SELECTED, UPLOADING, COMPLETED

  // File info
  selectedFileName: string = null;
  progress = 0;
  documentRef: IDocumentRef = null;

  sub: Subscription;

  constructor(private ds: DocumentService, private translate: TranslateService,
    private alerts: PopAlertsService) { }

  ngOnInit() {
    if (!this.placeholder) {
      this.translate.get('choose-file').subscribe(() => {
        this.placeholder = this.translate.instant('choose-file');
      })
    }
  }

  fileChange(event) {
    let fileList: FileList;
    if (event.target)
      fileList = event.target.files;
    else
      fileList = event;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      if (file.size > 512 * 1024 * 2014) {
        this.alerts.error(this.translate.instant('maxFileSizeExceeded'));
        return;
      }

      this.state = 'UPLOADING';

      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      this.selectedFileName = file.name;
      this.invalid = false;
      this.onTouch();
      this.sub = this.ds.upload(formData).subscribe(event => {
        switch (event.status) {
          case 'none':
            break;
          case 'uploading':
            this.progress = event.progress;
            break;
          case 'uploaded':
            this.state = "COMPLETED";
            this.documentRef = {
              documentRef: (<any>event.res).documentId,
              fileName: (<any>event.res).name
            }
            this.onChange(this.documentRef);
            this.complete.emit();
            break;
        }
      });
    }
  }

  download() {
    if (this.documentRef && this.documentRef.documentRef) {
      this.ds.download(this.documentRef.documentRef);
    }
  }

  reset() {
    this.documentRef = null;
    this.onChange(this.documentRef);
    this.state = 'EMPTY';
    this.progress = 0;
    this.selectedFileName = null;
    this.inputTypeFile.nativeElement.value = '';
  }

  onChange = (_: any) => { };
  onTouch = () => { };

  writeValue(value: any): void {
    if (value !== undefined) {
      this.documentRef = value;
      if (this.documentRef && this.documentRef.documentRef) {
        this.selectedFileName = this.documentRef.fileName;
        this.state = 'COMPLETED';
      }
      // this.onChange(this.documentRef);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  abort() {
    if (this.sub) this.sub.unsubscribe();
    this.reset();
  }

  validate(control: AbstractControl): ValidationErrors {
    if (this.state === 'UPLOADING') {
      return { uploading: true }
    }
    return null;
  }

}