import { Component, Input, OnInit } from '@angular/core';

import { IStudent } from '../../../../../../../interfaces/IStudent';

@Component({
  selector: 'personal-data',
  templateUrl: 'personal-data.component.html'
})
export class PersonalDataComponent implements OnInit {
  @Input() student: IStudent;

  constructor() { }

  ngOnInit() {

  }
}