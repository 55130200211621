import { Component, Input, OnInit } from '@angular/core';

import { IThesisCommitteeMemberData } from '../../../../../../../interfaces/IThesisProcess';

@Component({
  selector: 'thesis-committee-view',
  templateUrl: './thesis-committee-view.component.html',
})
export class ThesisCommitteeViewComponent implements OnInit {
  @Input() thesisCommittee: {
    president: IThesisCommitteeMemberData,
    secretary: IThesisCommitteeMemberData,
    member: IThesisCommitteeMemberData,
    alt1: IThesisCommitteeMemberData,
    alt2: IThesisCommitteeMemberData,
    alt3: IThesisCommitteeMemberData
  }

  @Input() showCV = true;

  constructor() { }

  ngOnInit() {
  }

}
