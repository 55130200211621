<div class="list-group"
  *ngIf="extensions && extensions.length > 0">
  <div class="list-group-item d-flex justify-content-between align-items-center"
    *ngFor="let ext of extensions">
    <div style="overflow-x: hidden;">
      <div *ngIf="showOrder">
        <strong>
          {{ ext.order | translate }}
        </strong>
      </div>
      <div *ngIf="ext.justification">
        <div *ngFor="let doc of ext.justification">
          <document-link [document]="doc"></document-link>
        </div>
      </div>
    </div>
    <div>
      {{ ext.date | moment:'D/M/YY' }}

      <a role="button"
        [routerLink]="null"
        class="text-muted ml-2"
        (click)="onEdit.emit(ext._id)"
        *ngIf="editable">
        <i class="far fa-edit"></i>
      </a>

      <a role="button"
        [routerLink]="null"
        class="text-muted ml-2"
        (click)="onRemove.emit(ext._id)"
        *ngIf="editable">
        <i class="far fa-trash"></i>
      </a>
    </div>
  </div>
</div>
<div class="card-body pt-0"
  *ngIf="extensions && extensions.length <= 0">
  <i *ngIf="showOrder">
    {{ 'no-extensions' | translate }}
  </i>
  <i *ngIf="!showOrder">
    {{ 'no-extension' | translate }}
  </i>
</div>