<ng-container *ngIf="activity">
  <div class="d-flex justify-content-between align-items-center">
    <div class="flex-fill text-primary"
      [class.c-pointer]="clickableType"
      (click)="onTypeClick.emit();">
      {{ activity.order }}. {{ activity.activityType | translate }}
    </div>
    <button class="btn btn-link btn-sm text-info"
      type="button"
      *ngIf="showEdit"
      [routerLink]="['/app/student/activities']"
      [queryParams]="{ id: activity._id}">
      {{ 'edit-activity' | translate }}
    </button>
  </div>
  <div class="text-nowrap text-muted small mb-1 mt-2"
    *ngIf="full">
    <ng-container *ngIf="activity.startDate">
      {{ activity.startDate | moment:'DD/MM/YY'}}
    </ng-container>
    <span class="mx-1"
      *ngIf="activity.startDate && activity.endDate">
      -
    </span>
    <ng-container *ngIf="activity.endDate">
      {{ activity.endDate | moment:'DD/MM/YY'}}
    </ng-container>
  </div>
  <div class="mb-1"
    style="text-decoration: underline;">
    {{ activity.title }}
  </div>
  <div class="pre"
    *ngIf="full">
    {{ activity.description }}
  </div>
  <div class="list-group list-group-flush mt-2"
    *ngIf="full && activity.attached.length > 0">
    <div class="list-group-item list-group-item-action small d-flex justify-content-between align-items-center"
      *ngFor="let document of activity.attached; trackBy:hs.trackById">
      <document-link [document]="document"></document-link>
    </div>
  </div>
</ng-container>