<div class="row">
  <div class="col text-right mb-4">
    <button class="btn btn-primary"
      (click)="doOpen(null)">
      <i class="fas fa-plus"></i>
      <span class="d-none d-sm-inline">
        Agregar profesor
      </span>
    </button>
  </div>
</div>

<infinite-list #ifList
  [data]="fetchFn()"
  [(array)]="professors"
  [elementsHeight]="50">

  <form>
    <div class="row mb-2">
      <div class="col">
        <search-input name="search"
          [ngModel]="searchText"
          (ngModelChange)="searchText = $event; ifList.search()"
          [placeholder]="'search-professor-by-name-or-id-num'"></search-input>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-8">
        <ng-select id="lineOfResearchFilter"
          name="lineOfResearchFilter"
          [items]="linesOfResearch"
          bindValue="_id"
          bindLabel="name"
          style="width: 100%;"
          [ngModel]="lineOfResearchId"
          (ngModelChange)="lineOfResearchId = $event; ifList.search()"
          [placeholder]="'line-of-research' | translate"
          [searchable]="true"
          [clearable]="true">
        </ng-select>
      </div>
      <div class="col-4"
        *ngIf="us.isService()">
        <ng-select id="confirmedFilter"
          name="confirmedFilter"
          style="width: 100%;"
          [ngModel]="confirmed"
          (ngModelChange)="confirmed = $event; ifList.search()"
          [placeholder]="'reviewed-state' | translate"
          [searchable]="false"
          [clearable]="true">
          <ng-option [value]="true">
            {{ 'only-reviewed' | translate }}
          </ng-option>
          <ng-option [value]="false">
            {{ 'only-not-reviewed' | translate }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col text-right">
        <button class="btn btn-link"
          (click)="export()">
          <i class="far fa-file-excel text-success"></i>
          Exportar a Excel
        </button>
      </div>
    </div>
  </form>


  <div class="card"
    *ngIf="professors.length > 0">
    <div class="table-responsive">
      <table class="table mb-0 small">
        <thead>
          <tr>
            <th *ngIf="us.isService()"></th>
            <th class="text-center">NIF</th>
            <th>Nombre</th>
            <th class="text-center">Email</th>
            <th>Linea de investigación</th>
            <th>Inicio</th>
            <th>Fin</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let professor of professors; trackBy:hs.trackById">
            <td *ngIf="us.isService()">
              <button class="btn btn-link"
                (click)="toggleConfirmed(professor)">
                <span *ngIf="professor.confirmed">
                  <i class="far fa-check text-success"></i>
                </span>
                <span *ngIf="!professor.confirmed">
                  <i class="far fa-square"></i>
                </span>
              </button>
            </td>
            <td class="text-center">
              <span *ngIf="professor.user">
                {{ professor.user.idNum }}
              </span>
              <span *ngIf="!professor.user">
                {{ professor.idNum }}
              </span>
            </td>
            <td>
              <a [routerLink]="['/app/service/users/' + professor.userId]"
                *ngIf="professor.user && us.isService()">
                {{ professor.user | fullname }}
              </a>
              <span *ngIf="professor.user && !us.isService()">
                {{ professor.user | fullname }}
              </span>
              <span *ngIf="!professor.user">
                {{ professor.name }}
              </span>
            </td>
            <td class="text-center">
              <email-link *ngIf="professor.user"
                [user]="professor.user"></email-link>
            </td>
            <td>
              {{ professor.lineOfResearch?.name}}
            </td>
            <td>
              <span>
                {{ professor.startYear | academicYear }}
              </span>
            </td>
            <td>
              <span *ngIf="professor.endYear">
                {{ professor.endYear | academicYear }}
              </span>
            </td>
            <td>
              <span *ngIf="!professor.userId"
                class="badge badge-warning">
                Usuario no encontrado en DUMA
              </span>
            </td>
            <td>
              <span class="nowrap">
                <button class="btn btn-link btn-sm text-muted mr-1"
                  (click)="doOpen(professor)">
                  <i class="far fa-edit"></i>
                </button>
                <button class="btn btn-link btn-sm text-muted"
                  (click)="doRemove(professor)">
                  <i class="far fa-trash"></i>
                </button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</infinite-list>

<modal [(open)]="open"
  [title]="(professor && professor._id ? 'edit-professor' : 'add-professor') | translate">
  <form #f="ngForm"
    id="professorForm"
    *ngIf="open"
    (ngSubmit)="save()">

    <div class="form-group">
      <label>{{ 'program' | translate }}</label>
      <input readonly
        [value]="program.name"
        class="form-control">
    </div>

    <div class="form-group">
      <label for="lineOfResearchSelect">
        {{ 'line-of-research' | translate }}
      </label>
      <ng-select id="lineOfResearchSelect"
        name="lineOfResearchSelect"
        #lineOfResearchSelect="ngModel"
        [items]="linesOfResearch"
        bindValue="_id"
        bindLabel="name"
        [(ngModel)]="professor.lineOfResearchId"
        [class.is-invalid]="f.submitted && lineOfResearchSelect.invalid"
        [placeholder]="'select-line-of-research' | translate"
        [searchable]="false"
        [clearable]="true">
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="lineOfResearchSelect"></validation-errors>
    </div>

    <div class="form-group">
      <label>{{ 'user' | translate }}</label>
      <user-selector [(ngModel)]="professor.user"
        (ngModelChange)="onUserChange($event)"
        name="userSelector"></user-selector>
    </div>

    <ng-container *ngIf="!professor.userId">
      <div class="form-group">
        <label for="professorName">{{ 'name' | translate }}</label>
        <input type="text"
          name="professorName"
          id="professorName"
          #professorNameInput="ngModel"
          [placeholder]="'name' | translate"
          [class.is-invalid]="f.submitted && professorNameInput.invalid"
          class="form-control"
          [(ngModel)]="professor.name">
        <validation-errors [control]="professorNameInput"
          [show]="f.submitted"></validation-errors>
      </div>
      <div class="form-group">
        <label for="professorIdNum">{{ 'idNum' | translate }}</label>
        <input type="text"
          name="professorIdNum"
          id="professorIdNum"
          #professorIdNumInput="ngModel"
          [placeholder]="'idNum' | translate"
          [class.is-invalid]="f.submitted && professorIdNumInput.invalid"
          class="form-control"
          [(ngModel)]="professor.idNum">
        <validation-errors [control]="professorIdNumInput"
          [show]="f.submitted"></validation-errors>
      </div>
    </ng-container>

    <div class="form-group">
      <label for="startYearSelect">
        {{ 'start-year' | translate }}
      </label>
      <ng-select id="startYearSelect"
        name="startYearSelect"
        #startYearSelect="ngModel"
        [items]="years"
        [(ngModel)]="professor.startYear"
        [class.is-invalid]="f.submitted && startYearSelect.invalid"
        [placeholder]="'select-start-year' | translate"
        [searchable]="false"
        [clearable]="true">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item | academicYear }}
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | academicYear }}
        </ng-template>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="startYearSelect"></validation-errors>
    </div>

    <div class="form-group">
      <label for="endYearSelect">
        {{ 'end-year' | translate }}
      </label>
      <ng-select id="endYearSelect"
        name="endYearSelect"
        #endYearSelect="ngModel"
        [items]="years"
        [(ngModel)]="professor.endYear"
        [class.is-invalid]="f.submitted && endYearSelect.invalid"
        [placeholder]="'select-end-year' | translate"
        [searchable]="false"
        [clearable]="true">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item | academicYear }}
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item | academicYear }}
        </ng-template>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="endYearSelect"></validation-errors>
    </div>


  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="professorForm">{{ 'save' | translate }}</button>
  </div>


</modal>