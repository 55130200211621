import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { HttpErrorObservable } from '../../../UI/http-error-interceptor/http-error-observable';

@Component({
  template: ''
})
export abstract class AbstractTaskComponent implements OnDestroy {

  @Input() disabled = false;

  sending = false;

  sendStart = new Subject();
  sendEnd = new Subject();

  sendEndSub: Subscription;
  errorSub: Subscription;

  constructor() {
    this.sendEndSub = this.sendEnd.subscribe(() => {
      this.sending = false;
    })

    this.errorSub = HttpErrorObservable.getInstance().errors.subscribe(() => {
      this.sending = false;
    })
  }

  ngOnDestroy(): void {
    if (this.sendEndSub) this.sendEndSub.unsubscribe();
  }

  send(data?: any) {
    if (!this.disabled) {
      this.sending = true;
      this.sendStart.next();
      this.manageSend(data);
    }
  }


  abstract manageSend(data?: any);

}