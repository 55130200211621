<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <dedication-process-data-view [process]="process"></dedication-process-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()"
    *ngIf="canComplete">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>

  <div *ngIf="canComplete === false"
    class="alert alert-info">
    <i class="far fa-info"></i>
    {{ 'only-coordinator-can-complete' | translate }}
  </div>

</ng-container>