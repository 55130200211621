<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="evaluation-periods"
        icon="calendar">
      </section-title>

    </div>
  </div>

  <evaluation-periods *ngIf="program"
    [program]="program"></evaluation-periods>

</div>