import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserHttpService } from '../../../data-services/user-http.service';
import { PopAlertsService } from '../../../UI/pop-alerts/pop-alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'accept-good-practice-code-modal',
  templateUrl: './accept-good-practice-code-modal.component.html',
})
export class AcceptGoodPracticeCodeModalComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() open = false;

  gpcPDFLinkClicked = false;
  checkboxClicked = false;

  accept = false;



  constructor(private uhs: UserHttpService,
    private alerts: PopAlertsService,
    private ts: TranslateService) { }

  ngOnInit() {
  }

  save() {
    if (!this.f.valid || !this.gpcPDFLinkClicked || !this.accept) return;
    this.uhs.acceptGoodPracticeCode(this.accept).subscribe(() => {
      this.alerts.pop({
        msg: this.ts.instant('gpcAccepted'),
        styleClass: 'success'
      });
      this.open = false;
    });

  }

}
