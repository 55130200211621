<ng-container *ngIf="task">
  <task-header [task]="task"></task-header>

  <br>

  <thesis-committee-view [thesisCommittee]="task.thesisProcess.data.thesisCommittee"
    [showCV]="false"></thesis-committee-view>

  <div class="w-100 mb-4"></div>

  <form #f="ngForm">

    <div class="form-group">
      <label for="defenceType">
        {{ 'defence-type' | translate }}
      </label>

      <ng-select [(ngModel)]="defenceType"
        name="defenceType"
        #defenceTypeInput="ngModel"
        [searchable]="false"
        [clearable]="false"
        style="width: 100%">
        <ng-option [value]="'presential'">
          {{ 'presential' | translate }}
        </ng-option>
        <ng-option [value]="'videoconference'">
          {{ 'videoconference' | translate }}
        </ng-option>
        <ng-option [value]="'hybrid'">
          {{ 'hybrid' | translate }}
        </ng-option>
      </ng-select>

      <validation-errors [control]="defenceTypeInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <div class="form-group">
      <label for="defenceDate">
        {{ 'defence-date' | translate }}
      </label>
      <date-picker id="defenceDate"
        name="defenceDate"
        [(ngModel)]="defenceDate"
        #defenceDateInput="ngModel"
        [isInvalid]="(defenceDateInput.invalid || dateError()) && f.submitted"
        [closeOnSelect]="false"
        [time]="true"
        required="true"></date-picker>
      <validation-errors [show]="f.submitted"
        [control]="defenceDateInput"></validation-errors>
      <small [class.text-muted]="!dateError() || !f.submitted"
        [class.text-danger]="dateError() && f.submitted">
        {{ 'cant-be-earlier-than' | translate }}
        {{ minDefenceDate | moment:'DD/MM/YYYY' }}
      </small>
    </div>

    <div class="form-group"
      *ngIf="defenceType === 'presential' || defenceType === 'hybrid'">
      <label for="defencePlace">
        {{ 'defence-place' | translate }}
      </label>
      <textarea class="form-control"
        name="defencePlace"
        id="defencePlace"
        #defencePlaceInput="ngModel"
        rows="3"
        maxlength="400"
        required="true"
        [placeholder]="'defence-place-description' | translate"
        [(ngModel)]="defencePlace"
        [class.is-invalid]="defencePlaceInput.invalid && f.submitted"></textarea>
      <div class="text-right small text-muted">
        {{ defencePlace ? defencePlace.length : 0}}/400
      </div>
      <validation-errors [control]="defencePlaceInput"
        [show]="f.submitted"></validation-errors>
    </div>

    <ng-container *ngIf="defenceType === 'hybrid'">
      <div class="alert alert-info mb-2">
        {{ 'hybrid-defence-info' | translate }}
      </div>

      <div class="form-group">
        <label for="president">
          {{ 'president' | translate }}
        </label>
        <div class="mb-1">
          <strong>
            {{ presidentName }}
          </strong>
        </div>
        <!-- <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="president"
          name="president"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-president' | translate"
          #presidentSelect="ngModel"
          [class.is-invalid]="presidentSelect.invalid && f.submitted"
          [ngModel]="presidentId"
          (ngModelChange)="assignPresident($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="presidentSelect"
          [show]="f.submitted"></validation-errors> -->

        <ng-select id="presidentModalitySelect"
          name="presidentModalitySelect"
          #presidentModalitySelect="ngModel"
          [(ngModel)]="presidentModality"
          [required]="true"
          [class.is-invalid]="f.submitted && presidentModalitySelect.invalid"
          [placeholder]="'Modalidad'"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="ThesisDefenceModality.inPerson">PRESENCIAL</ng-option>
          <ng-option [value]="ThesisDefenceModality.online">ONLINE</ng-option>
        </ng-select>
        <validation-errors [control]="presidentModalitySelect"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="secretary">
          {{ 'secretary' | translate }}
        </label>
        <div class="mb-1">
          <strong>
            {{ secretaryName }}
          </strong>
        </div>
        <!-- <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="secretary"
          name="secretary"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-secretary' | translate"
          #secretarySelect="ngModel"
          [class.is-invalid]="secretarySelect.invalid && f.submitted"
          [(ngModel)]="secretaryId"
          (ngModelChange)="assignSecretary($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="secretarySelect"
          [show]="f.submitted"></validation-errors> -->

        <ng-select id="secretaryModalitySelect"
          name="secretaryModalitySelect"
          #secretaryModalitySelect="ngModel"
          [(ngModel)]="secretaryModality"
          [required]="true"
          [class.is-invalid]="f.submitted && secretaryModalitySelect.invalid"
          [placeholder]="'Modalidad'"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="ThesisDefenceModality.inPerson">PRESENCIAL</ng-option>
          <ng-option [value]="ThesisDefenceModality.online">ONLINE</ng-option>
        </ng-select>
        <validation-errors [control]="secretaryModalitySelect"
          [show]="f.submitted"></validation-errors>

      </div>

      <div class="form-group">
        <label for="member">
          {{ 'member' | translate }}
        </label>
        <div class="mb-1">
          <strong>
            {{ memberName }}
          </strong>
        </div>
        <!-- <ng-select [items]="members"
          required="true"
          bindValue="_id"
          bindLabel="firstName"
          [multiple]="false"
          [searchable]="false"
          id="member"
          name="member"
          style="width: 100%;"
          [closeOnSelect]="true"
          [placeholder]="'choose-thesis-committee-member' | translate"
          #memberSelect="ngModel"
          [class.is-invalid]="memberSelect.invalid && f.submitted"
          [(ngModel)]="memberId"
          (ngModelChange)="assignMember($event)">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item | fullname }}
            <span class="small text-muted"
              *ngIf="presidentId === item._id">
              {{ 'president' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="secretaryId === item._id">
              {{ 'secretary' | translate }}
            </span>
            <span class="small text-muted"
              *ngIf="memberId === item._id">
              {{ 'member' | translate }}
            </span>
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item | fullname }}
          </ng-template>
        </ng-select>
        <validation-errors [control]="memberSelect"
          [show]="f.submitted"></validation-errors> -->

        <ng-select id="memberModalitySelect"
          name="memberModalitySelect"
          #memberModalitySelect="ngModel"
          [(ngModel)]="memberModality"
          [required]="true"
          [class.is-invalid]="f.submitted && memberModalitySelect.invalid"
          [placeholder]="'Modalidad'"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="ThesisDefenceModality.inPerson">PRESENCIAL</ng-option>
          <ng-option [value]="ThesisDefenceModality.online">ONLINE</ng-option>
        </ng-select>
        <validation-errors [control]="memberModalitySelect"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="invalid-feedback d-inline"
        *ngIf="f.submitted && isHybridError()">
        {{ 'hybrid-defence-type-error' | translate }}
      </div>
    </ng-container>

    <div class="w-100 text-right">
      <button (click)="send()"
        class="btn btn-primary"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'accept' | translate }}
      </button>

    </div>
  </form>
</ng-container>