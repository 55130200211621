<div class="footer"
  style="background-color: white;">
  <div class="container">
    <div class="row text-center text-md-left justify-content-center">
      <div class="col-6 col-md-2">
        <h5 class="text-primary mb-2 mt-3">
          <a [routerLink]="['/']">
            DAD
          </a>
        </h5>
        <p class="small text-muted mt-3 mb-5">
          Aplicación de Gestión del Documento de Actividades del Doctorando
        </p>
      </div>
      <div class="col-12 col-md-2"></div>
      <div class="col-12 col-md-2">
        <h6 class="mt-3">
          AYUDA
        </h6>
        <ul class="list-unstyled text-muted">
          <li class="mb-2">
            <a href="https://www.uma.es/doctorado/info/123361/preguntas-frecuentes-sobre-doctorado/"
              target="_blank"
              class="footer-link small">
              Preguntas frequentes
            </a>
          </li>
          <li class="mb-2">
            <a href="https://www.uma.es/secretaria-general-uma/navegador_de_ficheros/boletin/descargar/2023/octubre2/20231031_72219.pdf"
              target="_blank"
              class="footer-link small">
              Reglamento de doctorado
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-2">
        <h6 class="mt-3">
          CONTACTO
        </h6>
        <ul class="list-unstyled text-muted">
          <li class="mb-2">
            <a href="https://gp.uma.es/doctorado/"
              class="footer-link small">
              Gestor de peticiones
            </a>
          </li>
          <li class="mb-2">
            <a href="mailto:doctorado@uma.es"
              class="footer-link small">
              Correo electrónico
            </a>
          </li>
          <li class="mb-2">
            <a href="https://www.uma.es/ed-uma/info/12399/instalaciones/"
              target="_blank"
              class="footer-link small">
              Teléfonos
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-2">
        <h6 class="mt-3">
          ENLACES
        </h6>
        <ul class="list-unstyled text-muted">
          <li class="mb-2">
            <a href="https://www.uma.es"
              target="_blank"
              class="footer-link small">
              Web de la UMA
            </a>
          </li>
          <li class="mb-2">
            <a href="https://www.uma.es/doctorado"
              target="_blank"
              class="footer-link small">
              Doctorado
            </a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-2">
        <h6 class="mt-3">
          LEGAL
        </h6>
        <ul class="list-unstyled text-muted">
          <!-- <li class="mb-2">
            <a href="#"
              target="_blank"
              class="footer-link small">
              Aviso legal
            </a>
          </li> -->
          <li class="mb-2">
            <a href="https://www.uma.es/secretaria-general-uma/info/133115/informacion-basica-sobre-proteccion-de-datos/"
              target="_blank"
              class="footer-link small">
              Protección de datos
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col small text-muted mb-5 mt-2 text-center">
        © 2022 Escuela de Doctorado - Pabellón de Gobierno. Campus El Ejido s/n, 29071, Málaga.
        Primera Planta.
      </div>
    </div>
  </div>
</div>