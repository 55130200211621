import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { IProcess } from '../../../../../../interfaces/IProcess';
import { ITask, Processes } from '../../../../../../interfaces/ITask';
import { IUser, Role } from '../../../../../../interfaces/IUser';
import { DADUtils } from '../../../../../../utils/DADUtils';
import { UserHttpService } from '../../../../data-services/user-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';
import { UserService } from '../../../../user/user.service';
import { TKThesis } from '../../../../../../interfaces/IThesisProcess';
import { ServiceHttpService } from '../../../../data-services/service-http.service';

@Component({
  selector: 'tasks-table',
  templateUrl: 'tasks-table.component.html'
})
export class TasksTableComponent implements OnInit {
  @Input() process: IProcess;
  @Input() tasks: ITask[];

  @Output() onExternalLink = new EventEmitter<ITask>();
  @Output() onChangeExternalEvaluator = new EventEmitter<ITask>();

  showUserNames: Role[] = [
    Role.tutor,
    Role.supervisor1,
    Role.supervisor2,
    Role.supervisor3,
    Role.coordinator,
    Role.doctoralSchoolPrincipal
  ]

  tasksRoleNames: { [taskId: string]: string[] } = {};

  Processes = Processes;
  TKThesis = TKThesis;

  constructor(public hs: HelperService, public us: UserService, public ss: ServiceHttpService, private userHttp: UserHttpService) { }

  ngOnInit() {
    this.tasks.forEach(t => {
      if (t.roles && t.roles.length > 0) {
        this.tasksRoleNames[t._id] = [];
        t.roles.forEach(r => {
          if (!this.showUserNames.includes(<Role>this.hs.roleName(r))) {
            return;
          }
          this.roleUserNames(r).subscribe((users) => {
            users.forEach(u => {
              this.tasksRoleNames[t._id].push(DADUtils.fullname(u));
            })
          })
        })
      }
    })
  }

  roleUserNames(role: string): Observable<IUser[]> {
    let roleObj = DADUtils.parseRole(role);
    return this.userHttp.findByRole(roleObj);
  }

}