import { IDocumentRef } from './IDocument';
import { IValidationMsgs, IProcess } from './IProcess';
import { IStudent } from './IStudent';

export interface IThesisProcess extends IProcess {
  state: ThesisPState,
  studentId: string,
  pgcCounter?: number,

  data: IThesisProcessData,

  selectedEvaluators?: {
    ev1: IThesisCommitteeMemberData,
    ev2: IThesisCommitteeMemberData
  }

  reports?: {
    memberId: string,
    memberName: string,
    evaluation: ThesisReportEvaluation,
    docRef: IDocumentRef
  }[]

  directionTeamDecisionNegativeReports?: {
    userId: string,
    doContinue: boolean
  }[]

  thesisFinal?: IDocumentRef,
  changesReport?: IDocumentRef,

  pgcDefenceApprobalDate?: Date,
  acceptedForProcessingDate?: Date,

  defenceDate?: Date,
  defencePlace?: string,

  secretaryEvaluation?: ThesisFinalEvaluation;
  defenceLanguage?: string,

  thesisFinalEvaluation?: ThesisFinalEvaluation;
  cumlaudeMention?: boolean;

  cumlaudeVotes?: boolean[];
  cumlaudeVotesSent?: boolean;

  thesisDefenceRecord?: IDocumentRef;
  internationalMention?: boolean;
  industrialMention?: boolean;
  finalThesisCommittee?: {
    president: IThesisCommitteeMemberData,
    presidentModality?: ThesisDefenceModality,
    secretary: IThesisCommitteeMemberData,
    secretaryModality?: ThesisDefenceModality,
    member: IThesisCommitteeMemberData,
    memberModality?: ThesisDefenceModality,
  };

  specialAdward?: boolean;
  specialAdwardDocs?: IDocumentRef[];
  videoConference?: boolean;
  videoConferenceDocs?: IDocumentRef[];
  hybridDefence?: boolean;
  hybridDefenceDocs?: IDocumentRef[];

  riuma?: IDocumentRef;
  teseo?: IDocumentRef;
  designation?: IDocumentRef;
  embargo?: IDocumentRef;
  additionalReports?: IDocumentRef[];

  denialMsgs?: IValidationMsgs,
  validationMsgs?: IValidationMsgs,

  // Populate
  student?: IStudent
}

export interface IThesisProcessData {
  thesisTitle: string,
  thesisDoc: IDocumentRef,
  thesisSummaryDoc: IDocumentRef,
  cv: IDocumentRef,
  publications: IDocumentRef[],
  cotutelle: boolean,
  cotutelleStayEvidence?: IDocumentRef[],
  cotutelleStayEvidenceOut?: IDocumentRef[],
  cotutelleDefenceOut?: boolean,
  cotutelleComposition?: IDocumentRef,
  cotutelleUniversity?: string,
  compendium: boolean,
  coauthorsAutorization?: IDocumentRef[],
  compendiumDirectorReport?: IDocumentRef[],
  internationalMention: boolean,
  internationalStayEvidence?: IDocumentRef[],
  industrialMention: boolean,
  industrialMentionEvidence?: IDocumentRef[],
  needsEthicsReport: boolean,
  ethicsReport?: IDocumentRef,
  thesisCommittee?: {
    president: IThesisCommitteeMemberData,
    secretary: IThesisCommitteeMemberData,
    member: IThesisCommitteeMemberData,
    alt1: IThesisCommitteeMemberData,
    alt2: IThesisCommitteeMemberData,
    alt3: IThesisCommitteeMemberData
  },
  externalEvaluators?: {
    ev1: IThesisCommitteeMemberData,
    ev2: IThesisCommitteeMemberData,
    ev3: IThesisCommitteeMemberData
  },
}

export interface IThesisCommitteeMemberData {
  _id?: string,
  userId?: string,
  idNum: string,
  email: string,
  firstName: string,
  lastName: string,
  university: string,
  nationality?: string,
  cv: IDocumentRef,
  lang: string,
  expenseDocDate?: Date,
  expenseDocNote?: string
}

export enum ThesisReportEvaluation {
  accept = "ACCEPT",
  acceptMinorChanges = "ACCEPT_MINOR",
  acceptMajorChanges = "ACCEPT_MAJOR",
  reject = "REJECT"
}

export enum ThesisFinalEvaluation {
  fail = "FAIL",
  pass = "PASS",
  bgrade = "BGRADE",
  agrade = "AGRADE",
}

export enum ThesisDefenceModality {
  inPerson = "IN_PERSON",
  online = "ONLINE",
}

export enum ThesisPState {
  PendingSecretaryValidation = "PendingSecretaryValidation",
  PendingDirectionTeamValidation = "PendingDirectionTeamValidation",
  PendingAcademicCommitteeValidation = "PendingAcademicCommitteeValidation",
  PendingDoctoralSchoolPrincipalValidation = "PendingDoctoralSchoolPrincipalValidation",
  PendingServiceValidation = "PendingServiceValidation",
  PendingPostgraduateCommitteeValidation = "PendingPostgraduateCommitteeValidation",
  PendingThesisCommitteeUserCreation = "PendingThesisCommitteeUserCreation",
  PendingExternalEvaluatorsReports = "PendingExternalEvaluatorsReports",
  PendingDirectionTeamDecisionRejectReport = "PendingDirectionTeamDecisionRejectReport",
  PendingThesisDefenceRequest = "PendingThesisDefenceRequest",
  PendingStudentRequestReview = "PendingStudentRequestReview",
  PendingDirectionTeamDefenceValidation = "PendingDirectionTeamDefenceValidation",
  PendingPostgraduateCommitteeDefenceValidation = "PendingPostgraduateCommitteeDefenceValidation",
  PendingDefenceDate = "PendingDefenceDate",
  PendingServiceRegisterDefence = "PendingServiceRegisterDefence",
}


export enum TKThesis {
  studentReviewRequest = "studentReviewRequest",
  secretaryValidation = "secretaryValidation",
  directionTeamValidation = "directionTeamValidation",
  academicCommitteeValidation = "academicCommitteeValidation",
  doctoralSchoolPrincipalValidation = "doctoralSchoolPrincipalValidation",
  serviceValidation = "serviceValidation",
  postgraduateCommitteeValidation = "postgraduateCommitteeValidation",
  postgraduateCommitteeInfo = "postgraduateCommitteeInfo",
  thesisCommitteeAcceptance = "thesisCommitteeAcceptance",
  thesisCommitteeAltAcceptance = "thesisCommitteeAltAcceptance",
  externalEvaluatorAcceptance = "externalEvaluatorAcceptance",
  externalEvaluatorReport = "externalEvaluatorReport",
  directionTeamDecisionRejectReport = "directionTeamDecisionRejectReport",
  defenceRequest = "defenceRequest",
  defenceDirectionTeamValidation = "defenceDirectionTeamValidation",
  defencePostgraduateCommitteeValidation = "defencePostgraduateCommitteeValidation",
  defenceDate = "defenceDate",
  serviceRegisterDefence = "serviceRegisterDefence",
  defenceInfo = "defenceInfo",
  cumLaudeVote = "cumLaudeVote",
  secretaryEvaluation = "secretaryEvaluation"
}