<ng-container *ngIf="!loading">
  <div class="row"
    *ngIf="student && student.cds">
    <div class="col-12 col-lg-6 mb-lg-0 mb-3">
      <div class="card">
        <div class="card-body">

          <h5 class="card-title">{{ 'current-cds' | translate }}</h5>

          <div class="mb-3">
            <div *ngIf="currentCDS">
              <document-link [document]="currentCDS"></document-link>
            </div>
          </div>

          <div class="mb-3">
            <label class="small text-muted">
              {{ 'date' | translate }}
            </label>
            <div *ngIf="currentCDSDate">
              {{ currentCDSDate | moment:'DD/MM/YYYY'}}
            </div>
          </div>

          <ng-container *ngIf="student.cds.startDedication !== student.dedication; else sameDedication">
            <div class="mb-3">
              <label class="small text-muted">
                {{ 'starting-dedication' | translate }}
              </label>
              <div>
                {{ student.cds.startDedication | translate }}
              </div>
            </div>

            <div class="mb-3">
              <label class="small text-muted">
                {{ 'current-dedication' | translate }}
              </label>
              <div>
                {{ student.dedication | translate }}
              </div>
            </div>
          </ng-container>
          <ng-template #sameDedication>
            <div class="mb-3">
              <label class="small text-muted">
                {{ 'dedication' | translate }}
              </label>
              <div>
                {{ student.dedication | translate }}
              </div>
            </div>
          </ng-template>

          <ng-container *ngIf="student.cds.partialTimeDoc && student.cds.partialTimeDoc.length > 0">
            <div class="mb-3">
              <label class="small text-muted">{{ 'part-time-justification' | translate }}</label>
              <div *ngFor="let doc of student.cds.partialTimeDoc">
                <document-link [document]="doc"></document-link>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="student.cds.needsSupplement && student.cds.supplement">
            <div class="mb-3">
              <label class="small text-muted">
                {{ 'education-supplement' | translate }}
              </label>
              <div class="mb-2">
                {{ student.cds.supplement.credits }} {{ 'credits' | translate }}
              </div>
              <div class="mb-2"
                *ngIf="student.cds.supplement.document">
                <document-link [document]="student.cds.supplement.document"></document-link>
              </div>
              <div *ngIf="student.cds.supplement.notes"
                [innerHTML]="student.cds.supplement.notes">
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="student.cds.thirdSupervisorDoc">
            <div class="mb-3">
              <document-link [document]="student.cds.thirdSupervisorDoc"></document-link>
            </div>
          </ng-container>


        </div>
      </div>

      <div class="w-100 mb-4"></div>

      <industrial-mention-card [student]="student"></industrial-mention-card>

    </div>

    <div class="col-12 col-lg-6 pl-lg-0">
      <div class="card">
        <div class="card-body">

          <h5 class="card-title">{{ 'cds-history' | translate }}</h5>

          <table class="table"
            *ngIf="student.cds.changes.length > 0 || student.cds">
            <tbody>
              <tr *ngFor="let change of changes">
                <td>
                  {{ change.date | moment: 'DD/MM/YY'}}
                </td>
                <td>
                  <span *ngIf="change.dedicationChanged">
                    {{ 'change-to' | translate }}
                    {{ change.dedicationChangedTo | translate }}
                  </span>
                  <span *ngIf="change.directionChanged">
                    {{ 'direction-changed' | translate }}
                  </span>
                  <span *ngIf="change.industrialChanged">
                    {{ 'industrial-phd' | translate}}
                  </span>
                </td>
                <td>
                  <ul class="m-0 list-unstyled">
                    <li>
                      <document-link [document]="change.document"></document-link>
                    </li>
                    <li *ngFor="let doc of change.partialTimeDoc">
                      <document-link [document]="doc"></document-link>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr *ngIf="student.cds">
                <td>
                  {{ student.cds.date | moment: 'DD/MM/YY'}}
                </td>
                <td>
                  {{ 'initial-cds' | translate }}
                </td>
                <td>
                  <document-link [document]="student.cds.document"></document-link>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="student.cds.changes.length <= 0 && !student.cds"
            class="mb-4">
            <i>
              {{ 'no-cds-changes' | translate }}
            </i>
          </div>

          <div class="w-100 text-right mt-2"
            *ngIf="us.isStudent()">
            <button class="btn btn-block btn-outline-primary"
              [routerLink]="['direction/request']"
              [disableButton]="!directionRequestEnabled?.enabled"
              *ngIf="!directionProcess">
              {{ 'request-direction-team-change' | translate }}
            </button>
            <request-disabled-reasons [requestEnabled]="directionRequestEnabled"></request-disabled-reasons>

            <button class="btn btn-block mt-2 btn-outline-primary"
              [routerLink]="['dedication/request']"
              [disableButton]="!dedicationRequestEnabled?.enabled"
              *ngIf="!dedicationProcess">
              {{ 'request-dedication-change' | translate }}
            </button>
            <request-disabled-reasons [requestEnabled]="dedicationRequestEnabled"></request-disabled-reasons>
          </div>

          <ng-container *ngIf="us.isService()">
            <button class="btn btn-block btn-outline-primary"
              [routerLink]="['industrial/launch']"
              *ngIf="!industrialProcess">
              {{ 'cds-mod-industrial-doctor' | translate }}
            </button>
          </ng-container>
        </div>
      </div>

      <div class="card mt-4"
        *ngIf="directionProcess">
        <div class="card-body">
          <h5 class="card-title">
            {{ 'process.direction.name' | translate }}

            <span *ngIf="!directionProcess.active"
              class="badge badge-danger ml-1">
              {{ 'process-inactive' | translate}}
            </span>
          </h5>

          <tasks-table [tasks]="directionProcess.tasks"
            [process]="directionProcess"></tasks-table>

          <ng-container *ngIf="us.isService()">
            <hr>

            <div class="mb-3">
              <div class="mb-1 small text-muted">
                {{ 'request-date' | translate }}
              </div>
              {{ directionProcess.createdAt | moment:'DD/MM/YYYY HH:mm' }}
            </div>

            <div *ngIf="directionProcess.requestDoc">
              <document-link [document]="directionProcess.requestDoc"
                styleClass="mb-2"></document-link>
              <document-link [document]="doc"
                styleClass="mb-2"
                *ngFor="let doc of directionProcess.olderRequestDocs"></document-link>
            </div>
          </ng-container>

          <end-process-button [processId]="directionProcess._id"
            [processType]="Processes.direction"></end-process-button>
        </div>
      </div>

      <div class="card mt-4"
        *ngIf="dedicationProcess">
        <div class="card-body">
          <h5 class="card-title">
            {{ 'process.dedication.name' | translate }}

            <span *ngIf="!dedicationProcess.active"
              class="badge badge-danger ml-1">
              {{ 'process-inactive' | translate}}
            </span>
          </h5>

          <tasks-table [tasks]="dedicationProcess.tasks"
            [process]="dedicationProcess"></tasks-table>

          <ng-container *ngIf="us.isService()">
            <hr>

            <div class="mb-3">
              <div class="mb-1 small text-muted">
                {{ 'request-date' | translate }}
              </div>
              {{ dedicationProcess.createdAt | moment:'DD/MM/YYYY HH:mm' }}
            </div>

            <div *ngIf="dedicationProcess.requestDoc">
              <document-link [document]="dedicationProcess.requestDoc"
                styleClass="mb-2"></document-link>
              <document-link [document]="doc"
                styleClass="mb-2"
                *ngFor="let doc of dedicationProcess.olderRequestDocs"></document-link>
            </div>
          </ng-container>

          <end-process-button [processId]="dedicationProcess._id"
            [processType]="Processes.dedication"></end-process-button>
        </div>
      </div>

      <div class="card mt-4"
        *ngIf="industrialProcess">
        <div class="card-body">
          <h5 class="card-title">
            {{ 'process.industrial.name' | translate }}

            <span *ngIf="!industrialProcess.active"
              class="badge badge-danger ml-1">
              {{ 'process-inactive' | translate}}
            </span>
          </h5>

          <tasks-table [tasks]="industrialProcess.tasks"
            [process]="industrialProcess"></tasks-table>

          <ng-container *ngIf="us.isService()">
            <hr>

            <div class="mb-3">
              <div class="mb-1 small text-muted">
                {{ 'date' | translate }}
              </div>
              {{ industrialProcess.createdAt | moment:'DD/MM/YYYY HH:mm' }}
            </div>

            <div *ngIf="industrialProcess.requestDoc">
              <document-link [document]="industrialProcess.requestDoc"
                styleClass="mb-2"></document-link>
              <document-link [document]="doc"
                styleClass="mb-2"
                *ngFor="let doc of industrialProcess.olderRequestDocs"></document-link>
            </div>
          </ng-container>

          <end-process-button [processId]="industrialProcess._id"
            [processType]="Processes.industrial"></end-process-button>
        </div>
      </div>

    </div>
  </div>

  <div class="row"
    *ngIf="student && !student.cds">

    <div class="col-12"
      *ngIf="!cdsProcess">

      <alert-icon *ngIf="us.isStudent()"
        icon="fas fa-exclamation-triangle"
        type="warning">
        {{ 'no-cds-warning' | translate }}
      </alert-icon>
      <alert-icon *ngIf="!us.isStudent()"
        icon="fas fa-exclamation-triangle"
        type="warning">
        {{ 'student-no-cds' | translate }}
      </alert-icon>

      <div class="w-100 text-right"
        *ngIf="us.isStudent()">
        <button class="btn btn-outline-primary"
          type="button"
          [routerLink]="['request']"
          [disableButton]="!cdsRequestEnabled?.enabled">
          {{ 'request-cds' | translate }}
        </button>
        <request-disabled-reasons [requestEnabled]="cdsRequestEnabled"></request-disabled-reasons>
      </div>

    </div>

    <ng-container *ngIf="cdsProcess">

      <div class="col-12">
        <alert-icon icon="fas fa-info-circle"
          type="info">
          {{ 'cds-in-process' | translate }}
        </alert-icon>
      </div>

      <div class="col-12 mt-2">
        <div class="card">
          <div *ngIf="!cdsProcess.active"
            class="badge badge-danger ml-1">
            {{ 'process-inactive' | translate}}
          </div>

          <tasks-table [tasks]="cdsProcess.tasks"
            [process]="cdsProcess"></tasks-table>

          <ng-container *ngIf="us.isService()">
            <hr>

            <div class="mb-3">
              <div class="mb-1 small text-muted">
                {{ 'request-date' | translate }}
              </div>
              {{ cdsProcess.createdAt | moment:'DD/MM/YYYY HH:mm' }}
            </div>

            <div *ngIf="cdsProcess.requestDoc">
              <document-link [document]="cdsProcess.requestDoc"
                styleClass="mb-2"></document-link>
              <document-link [document]="doc"
                styleClass="mb-2"
                *ngFor="let doc of cdsProcess.olderRequestDocs"></document-link>
            </div>
          </ng-container>

          <end-process-button [processId]="cdsProcess._id"
            [processType]="Processes.cds"></end-process-button>

        </div>
      </div>
    </ng-container>

  </div>
</ng-container>