<div class="card"
  *ngIf="student.leaves.length > 0 || (us.isService() && sh.editMode)">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <span>
        {{ 'leaves' | translate }}
      </span>
      <button type="button"
        class="btn btn-link"
        *ngIf="us.isService() && sh.editMode"
        (click)="reset(); open = true">
        {{ 'add' | translate }}
      </button>
    </legend>
  </div>

  <leaves-list [leaves]="student.leaves"
    [editable]="us.isService() && sh.editMode"
    (onRemove)="remove($event)"
    (onEdit)="openEdit($event)"
    [leaveDischargeProcMap]="leaveDischargeProcMap"
    [leaveRenewalProcMap]="leaveRenewalProcMap"></leaves-list>
</div>

<modal [(open)]="open"
  [title]="(leaveId ? 'edit-leave' : 'add-leave') | translate">
  <form #f="ngForm"
    id="leavesForm"
    (ngSubmit)="save()"
    *ngIf="open">
    <div class="form-group">
      <label for="typeSelect">
        {{ 'leave-type' | translate }}
      </label>
      <ng-select id="typeSelect"
        name="typeSelect"
        #typeSelect="ngModel"
        [(ngModel)]="type"
        [disabled]="leaveId"
        required
        [class.is-invalid]="f.submitted && typeSelect.invalid"
        [placeholder]="'select-leave-type' | translate"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="LeaveType.temporal">{{ LeaveType.temporal | translate }}</ng-option>
        <ng-option [value]="LeaveType.medical">{{ LeaveType.medical | translate }}</ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="typeSelect"></validation-errors>
    </div>

    <div class="alert alert-danger"
      *ngIf="type === LeaveType.temporal && temporalLeavesLimitReached && !leaveId">
      {{ 'temporal-leaves-limit-reached' | translate }}
    </div>

    <ng-container *ngIf="type && (leaveId || !temporalLeavesLimitReached || type === LeaveType.medical)">
      <div class="form-group">
        <label for="startDate">
          {{ 'start-date' | translate }}
        </label>

        <date-picker id="startDate"
          name="startDate"
          [placeholder]="'start-date' | translate"
          required="true"
          [isInvalid]="startDateInput.invalid && f.submitted"
          #startDateInput="ngModel"
          [time]="false"
          [(ngModel)]="start"> </date-picker>
        <validation-errors [control]="startDateInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="endDate">
          {{ 'end-date' | translate }}
        </label>

        <date-picker id="endDate"
          name="endDate"
          required="false"
          [placeholder]="'end-date' | translate"
          [isInvalid]="endDateInput.invalid && f.submitted"
          #endDateInput="ngModel"
          [time]="false"
          [required]="type === LeaveType.temporal"
          [(ngModel)]="end"> </date-picker>
        <validation-errors [control]="endDateInput"
          [show]="f.submitted"></validation-errors>
        <span class="invalid-feedback d-inline"
          *ngIf="start && end && !validDateRange() && type === LeaveType.temporal">
          {{ 'temporal-leave-min-max-duration-msg' | translate }}
        </span>
        <span class="invalid-feedback d-inline"
          *ngIf="start && end && !validDateRange() && type === LeaveType.medical">
          {{ 'end-date-must-be-after-start-date' | translate }}
        </span>
      </div>

      <div class="form-group"
        *ngIf="type === LeaveType.medical && !end">
        <label for="estimatedEndDate">
          {{ 'estimated-end-date' | translate }}
        </label>

        <date-picker id="estimatedEndDate"
          name="estimatedEndDate"
          required="true"
          [placeholder]="'estimated-end-date' | translate"
          [isInvalid]="estimatedEndDateInput.invalid && f.submitted"
          #estimatedEndDateInput="ngModel"
          [time]="false"
          [(ngModel)]="estimatedEnd"> </date-picker>
        <validation-errors [control]="estimatedEndDateInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'justification' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="justificationInput"></validation-errors>
        <files-queue name="justification"
          required
          #justificationInput="ngModel"
          [(ngModel)]="justification"></files-queue>
        <small class="form-text text-muted">
          {{ 'leave-justification-note' | translate }}
        </small>
      </div>
    </ng-container>

  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="leavesForm">{{ 'save' | translate }}</button>
  </div>

</modal>