<ng-container *ngIf="process">
  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'student' | translate }}
    </div>
    <div>
      {{ process.student.user.fullName }}
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'program' | translate }}
    </div>
    <div>
      {{ process.student.program.name }}
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'dedication-change-to' | translate }}
    </div>
    <div>
      {{ process.dedication | translate }}
    </div>
  </div>

  <div *ngIf="process.dedication === 'PART' && process.partialTimeDoc"
    class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'part-time-justification' | translate }}
    </div>
    <div *ngFor="let doc of process.partialTimeDoc">
      <document-link [document]="doc"></document-link>
    </div>
  </div>
</ng-container>