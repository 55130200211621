<div class="card"
  *ngIf="student.industrialMentionInfo && student.industrialMentionInfo.industrialMention === true">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <span>
        {{ 'industrial-phd' | translate }}
      </span>
      <!-- <button type="button"
        class="btn btn-link"
        *ngIf="us.isService() && sh.editMode"
        (click)="doOpen()">
        {{ 'edit' | translate }}
      </button> -->
    </legend>

    <div class="my-4">
      <div class="small text-muted mb-1">
        {{ 'industrial-phd' | translate }}
      </div>
      <div>
        {{ (student.industrialMentionInfo?.industrialMention ? 'Yes' : 'No') | translate }}
      </div>
    </div>

    <ng-container *ngIf="student.industrialMentionInfo?.industrialMention === true">

      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'industrial-mention-agreement' | translate }}
        </div>
        <div class="mb-1">
          <ng-container *ngIf="student.industrialMentionInfo.agreementDocs">
            <document-link *ngFor="let doc of student.industrialMentionInfo.agreementDocs"
              [document]="doc"></document-link>
          </ng-container>
        </div>
      </div>

      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'industrial-mention-agreement-date' | translate }}
        </div>
        <div class="mb-1">
          <span *ngIf="student.industrialMentionInfo.agreementDate">
            {{ student.industrialMentionInfo.agreementDate | moment:'D/M/YY' }}
          </span>
        </div>
      </div>

      <div class="mb-4"
        *ngIf="student.industrialMentionInfo.organization">
        <div class="small text-muted mb-1">
          {{ 'organization' | translate }}
        </div>
        <div>
          {{ student.industrialMentionInfo.organization }}
        </div>
      </div>

      <div class="mb-4"
        *ngIf="student.industrialMentionInfo.organizationContact">
        <div class="small text-muted mb-1">
          {{ 'organization-contact' | translate}}
        </div>
        <div>
          {{ student.industrialMentionInfo.organizationContact }}
        </div>
      </div>
    </ng-container>
  </div>
</div>

<modal [(open)]="open"
  [title]="'industrial-mention' | translate">
  <form #f="ngForm"
    id="industrialMentionForm"
    (ngSubmit)="save()"
    *ngIf="open">

    <div class="form-group">
      <label for="industrialMention">
        {{ 'industrial-mention' | translate }}
      </label>
      <ng-select id="industrialMention"
        name="industrialMention"
        style="width: 100%"
        [required]="true"
        [searchable]="false"
        [clearable]="false"
        [(ngModel)]="industrialMentionInfo.industrialMention">
        <ng-option [value]="true">
          {{ 'Yes' | translate }}
        </ng-option>
        <ng-option [value]="false">
          {{ 'No' | translate }}
        </ng-option>
      </ng-select>
    </div>

    <ng-container *ngIf="industrialMentionInfo.industrialMention === true">

      <div class="form-group">
        <label for="industrialMentionAgreementDoc">
          {{ 'industrial-mention-agreement' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="documentsInput"></validation-errors>
        <files-queue name="documents"
          [required]="false"
          #documentsInput="ngModel"
          [(ngModel)]="industrialMentionInfo.agreementDocs"></files-queue>
      </div>

      <div class="form-group">
        <label for="industrialMentionAgreementDate">
          {{ 'industrial-mention-agreement-date' | translate }}
        </label>
        <date-picker id="industrialMentionAgreementDate"
          name="industrialMentionAgreementDate"
          [placeholder]="'industrial-mention-agreement-date' | translate"
          [required]="false"
          [isInvalid]="industrialMentionAgreementDateInput.invalid && f.submitted"
          #industrialMentionAgreementDateInput="ngModel"
          [time]="false"
          [(ngModel)]="industrialMentionInfo.agreementDate"> </date-picker>
        <validation-errors [control]="industrialMentionAgreementDateInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="">
          {{ 'organization' | translate }}
        </label>
        <input id="organization"
          name="organization"
          type="text"
          class="form-control"
          required="false"
          [placeholder]="'organization' | translate"
          #organizationInput="ngModel"
          required
          [(ngModel)]="industrialMentionInfo.organization">
        <validation-errors [control]="organizationInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label>
          {{ 'organization-contact' | translate}}
        </label>
        <input id="organizationContact"
          name="organizationContact"
          type="text"
          class="form-control"
          required="false"
          [placeholder]="'organization-contact' | translate"
          #organizationContactInput="ngModel"
          required
          [(ngModel)]="industrialMentionInfo.organizationContact">
        <validation-errors [control]="organizationContactInput"
          [show]="f.submitted"></validation-errors>
      </div>
    </ng-container>
  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="industrialMentionForm">{{ 'save' | translate }}</button>
  </div>

</modal>