<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card">
      <div class="card-body">
        <legend>
          {{ 'preferences' | translate}}
        </legend>
        <form #f="ngForm"
          (ngSubmit)="save()">

          <div class="form-group">
            <div class="d-flex justify-content-between align-items-center">
              <label>
                {{ 'prefered-email' | translate }}
              </label>
              <button class="btn btn-link"
                type="button"
                (click)="editFavEmail()"
                *ngIf="!favEmail">
                {{ 'modify' | translate }}
              </button>
              <button class="btn btn-link"
                type="button"
                (click)="defaultEmail()"
                *ngIf="favEmail">
                {{ 'default' | translate }}
              </button>
            </div>
            <input *ngIf="favEmail"
              type="text"
              class="form-control"
              [placeholder]="'email' | translate"
              name="email"
              [(ngModel)]="favEmail.address"
              (ngModelChange)="notFound = false"
              [required]="true"
              [pattern]="hs.EMAIL_REGEX"
              #emailInput="ngModel"
              [class.is-invalid]="f.submitted && emailInput.invalid">
            <input *ngIf="!favEmail"
              class="form-control"
              type="text"
              [placeholder]="us.user.email.address"
              readonly>
          </div>

          <div class="form-group text-right">
            <button class="btn btn-primary"
              type="submit">
              {{ 'save' | translate }}
            </button>
          </div>

        </form>
      </div>
    </div>


  </div>
</div>