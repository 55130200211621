import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ITask } from '../../../interfaces/ITask';
import { Group, IUser, Role } from '../../../interfaces/IUser';
import { IEvaluationCommittee } from '../../../interfaces/IEvaluationCommittee';
import { IStudent } from '../../../interfaces/IStudent';

@Injectable()
export class UserHttpService {

  constructor(private http: HttpClient) { }

  acceptGoodPracticeCode(accept: boolean): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>(`/api/me/acceptGoodPracticeCode`, { accept });
  }

  savePreferences(preferences: { emailNotifications: boolean, favEmailAddress: string }): Observable<{ ok: true }> {
    return this.http.post<{ ok: true }>('api/user/savePreferences', { emailNotifications: preferences.emailNotifications, favEmailAddress: preferences.favEmailAddress })
  }

  findByRole(role: { role: Role, group: Group, groupId?: string }): Observable<IUser[]> {
    return this.http.post<IUser[]>(`/api/user/findByRole`, {
      role: role.role,
      group: role.group,
      groupId: role.groupId
    });
  }

  fetchMyEvaluationCommittees(programId: string): Observable<{ committees: IEvaluationCommittee[], students: { [cId: string]: IStudent[] } }> {
    return this.http.get<{ committees: IEvaluationCommittee[], students: { [cId: string]: IStudent[] } }>(`/api/user/findMyEvaluationCommittees/${programId}`);
  }

  findByEmail(email: string, externals = false): Observable<IUser> {
    return this.http.get<IUser>(`/api/user/find/${encodeURIComponent(email)}/${externals}`);
  }

  createUserFromUID(uid: string): Observable<string> {
    return this.http.post<string>(`/api/user/createFromUID`, { uid });
  }

  fetchTasks(params: { page?: number, size?: number }): Observable<ITask[]> {
    return this.http.get<ITask[]>(`/api/me/tasks`, { params });
  }

  count(): Observable<number> {
    return this.http.get<{ res: number }>(`/api/me/tasksCount`).pipe(
      map(r => r.res)
    );
  }

}