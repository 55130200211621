import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IActivity } from '../../../../../../interfaces/IActivity';
import { IStudent } from '../../../../../../interfaces/IStudent';
import { StudentHttpService } from '../../../../data-services/student-http.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { StudentFileService } from '../student-file.service';

@Component({
  selector: 'student-file-activities',
  templateUrl: 'student-file-activities.component.html'
})
export class StudentFileActivitiesComponent implements OnInit, OnDestroy {
  activities: IActivity[];
  student: IStudent;

  sub: Subscription;

  params = {
    searchText: ''
  }

  constructor(private ss: StudentHttpService,
    public ds: DocumentService,
    private sfs: StudentFileService) { }

  ngOnInit() {
    this.sub = this.sfs.studentObs.pipe(filter(x => x !== null)).subscribe((student) => {
      this.student = student;
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  fetchFn() {
    return (params) => {
      params = {
        ...params,
        ...this.params,
        studentId: this.student._id
      }
      return this.ss.fetchActivities(params);
    }
  }

}