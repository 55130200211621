import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IProgram } from '../../../../../../interfaces/IProgram';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { Subscription } from 'rxjs';
import { ProgramFileService } from './program-file.service';
import { LoadingIndicatorService } from '../../../../UI/loading-indicator/loading-indicator.service';

@Component({
  selector: 'program-file',
  templateUrl: './program-file.component.html',
})
export class ProgramFileComponent implements OnInit, OnDestroy {

  sub: Subscription;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public pfs: ProgramFileService,
    private loading: LoadingIndicatorService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (!params.programId) {
        void this.router.navigate(['../../'], { relativeTo: this.route });
        return;
      }
      this.loading.start();
      this.pfs.fetch(params.programId);
      this.sub = this.pfs.programObs.subscribe(() => {
        this.loading.stop();
      })
    })
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    this.pfs.reset();
  }

}
