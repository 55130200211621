<ng-container *ngIf="process">

  <task-header [task]="task"></task-header>

  <br>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <div class="form-group">
      <label for="doContinueSelect">
        {{ 'decision' | translate }}
      </label>
      <ng-select id="doContinueSelect"
        name="doContinueSelect"
        #doContinueSelect="ngModel"
        [(ngModel)]="doContinue"
        [placeholder]="'select-reject-report-decision' | translate"
        required
        [class.is-invalid]="f.submitted && doContinueSelect.invalid"
        [searchable]="false"
        [clearable]="false">
        <ng-option [value]="true">
          {{ 'continue-thesis-defence' | translate }}
        </ng-option>
        <ng-option [value]="false">
          {{ 'do-not-continue-thesis-defence' | translate }}
        </ng-option>
      </ng-select>
      <validation-errors [show]="f.submitted"
        [control]="doContinueSelect"></validation-errors>
    </div>

    <div class="form-group text-right">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>

</ng-container>