<div class="list-group">
  <ng-container *ngFor="let message of messages">
    <div class="list-group-item">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h6 class="m-0 text-nowrap text-truncate c-pointer"
          (click)="toggleRead(message); ">
          <span *ngIf="!message.read"
            class="small mr-1">
            <span>
              <i class="fas fa-circle text-danger"></i>
            </span>
          </span>
          <span *ngIf="message.read"
            class="small mr-1">
            <span>
              <i class="far fa-circle text-muted"></i>
            </span>
          </span>
          {{ message.title }}
        </h6>
        <div class="text-right small text-nowrap">
          {{ message.createdAt | momentFromNow }}
        </div>
      </div>
      <div [class.d-block]="!expanded[message._id]"
        [class.d-none]="expanded[message._id]">
        <span [innerHtml]="message.content | truncate:140">
        </span>

        <a *ngIf="message.content.length > 140"
          [routerLink]="null"
          [queryParamsHandling]="'preserve'"
          role="button"
          (click)="expand(message._id)">{{ 'read-more' | translate}}</a>
      </div>
      <div [class.d-none]="!expanded[message._id]"
        [class.d-block]="expanded[message._id]"
        [innerHtml]="message.content">
      </div>
      <div *ngIf="message.taskId"
        class="mt-2">
        <a [routerLink]="['/app/task/' + message.taskId]"
          (click)="onGoToTask.emit(message.taskId); onMarkAsRead.emit(message._id)">
          {{ 'go-to-task' | translate }}
        </a>
      </div>
    </div>
  </ng-container>
</div>