<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <div class="mt-2 mb-3">
    <div class="small text-muted mb-1 mt-2">
      <span *ngIf="process.student.rd === RDType.rd99Mod">
        {{ 'research-and-formation-plan' | translate }}
      </span>
      <span *ngIf="process.student.rd === RDType.rd99">
        {{ 'research-plan' | translate }}
      </span>
    </div>
    <document-link [document]="process.researchPlan"></document-link>
  </div>

  <div class="small text-muted mb-1 mt-2">
    {{ 'activities' | translate }}
  </div>
  <div style="width: 100%; max-height: 600px; overflow-y: auto"
    class="border rounded">
    <div class="list-group">
      <div class="list-group-item"
        *ngFor="let activity of process.activities">
        <activity-view [activity]="activity"></activity-view>
      </div>
    </div>
  </div>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <div class="form-group">
      <label>
        {{ 'report' | translate }}
      </label>
      <file-input name="report"
        required
        #reportInput="ngModel"
        [(ngModel)]="report"></file-input>
      <validation-errors [show]="f.submitted"
        [control]="reportInput"></validation-errors>
    </div>

    <div class="form-group text-right mt-2">
      <div class="d-block invalid-feedback mb-2"
        *ngIf="f.invalid && f.submitted">
        {{ 'check-errors' | translate }}
      </div>

      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate}}
      </button>
    </div>

  </form>

</ng-container>