import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'navbar',
  templateUrl: 'navbar.component.html'
})
export class NavbarComponent implements OnInit {
  @Input() active;
  @Output() activeChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  toggleActive() {
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }

}