import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { IProfessorProcessData, ProfessorReason } from '../../../../../../interfaces/IProfessorProcess';
import { HelperService } from '../../../../shared/helper-service/helper.service';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { UserService } from '../../../../user/user.service';
import { ILineOfResearch } from '../../../../../../interfaces/ILineOfResearch';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { NgForm } from '@angular/forms';
import { ProfessorHttpService } from '../../../../data-services/professor-http.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { Observable } from 'rxjs';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'coordinator-professor-request',
  templateUrl: './coordinator-professor-request.component.html',
})
export class CoordinatorProfessorRequestComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {
  requested = false;
  doc: IDocumentRef;

  programId: string;

  linesOfResearch: ILineOfResearch[] = [];

  ProfessorReason = ProfessorReason;

  constructor(public hs: HelperService,
    private ps: ProgramHttpService,
    private prs: ProfessorHttpService,
    private ds: DocumentService,
    private cds: ConfirmationDialogService,
    private ts: TranslateService,
    private us: UserService) {
    super();
  }

  ngOnInit() {
    this.programId = this.us.selectedRole.groupId;

    this.ps.linesOfResearch(this.programId).subscribe((lines) => {
      this.linesOfResearch = lines;
    });
  }

  manageSend(data: IProfessorProcessData) {
    this.prs.request(this.programId, data).subscribe((docRef) => {
      this.sendEnd.next();
      this.doc = docRef;
      this.requested = true;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
    });
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    console.log('canDeactivate');
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

}
