import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { ILeaveProcess, LeaveType } from '../../../../../../../interfaces/ILeaveProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { LeaveHttpService } from '../../../../../data-services/leave-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { DADUtils } from '../../../../../../../utils/DADUtils';
import { UserService } from '../../../../../user/user.service';

@Component({
  selector: 'leave-ac-validation',
  templateUrl: './leave-ac-validation.component.html'
})
export class LeaveAcValidationComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  process: ILeaveProcess;

  justification: IDocumentRef[] = [];
  type: LeaveType;
  start: Date;
  end: Date;

  denialMsg = '';
  validate = null;

  LeaveType = LeaveType;
  DADUtils = DADUtils;

  canComplete: boolean = null;

  constructor(private leave: LeaveHttpService,
    private router: Router,
    private us: UserService) {
    super();
  }

  ngOnInit() {
    this.canComplete = this.us.canComplete(this.task);
    this.leave.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      this.justification = this.process.justification;
      this.type = this.process.type;
      this.start = this.process.start;
      this.end = this.process.end;
    })
  }

  manageSend() {
    if (this.f.invalid) {
      this.sendEnd.next();
      return;
    }

    if (this.validate) {
      this.leave.academicCommitteeValidation(this.task._id).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.leave.academiccommitteeDeny(this.task._id, { denialMsg: this.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
