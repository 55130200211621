import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ICDSRequestData } from '../../../../../../interfaces/ICDSProcess';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { CDSHttpService } from '../../../../data-services/cds-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'cds-request',
  templateUrl: 'cds-request.component.html'
})
export class CDSRequestComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {

  requested = false;
  doc: IDocumentRef;

  constructor(public sr: StudentRootService,
    private cds: CDSHttpService,
    private ds: DocumentService,
    private ts: TranslateService,
    private confirmation: ConfirmationDialogService,
    private refresh: RefreshService) {
    super();
  }

  ngOnInit() {
  }

  manageSend(data: ICDSRequestData) {
    this.cds.request(this.sr.student._id, data).subscribe((doc: IDocumentRef) => {
      this.sendEnd.next();
      this.refresh.onStudentChange.next();
      this.requested = true;
      this.doc = doc;
      this.sr.cdsRequested = true;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
    });
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.confirmation.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }
}