import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuService } from '../../root/menu/menu.service';

@Component({
  selector: 'menu-item',
  templateUrl: 'menu-item.component.html'
})
export class MenuItemComponent implements OnInit {
  @Input() linkActiveOptions = {};
  @Input() link;
  @Input() iconType = 's';
  @Input() icon;
  @Input() name;

  @Input() dot = false
  @Input() disabled = false;

  @Output() onClick = new EventEmitter<string>();

  active = false;

  constructor(public ms: MenuService) { }

  ngOnInit() { }
}