import { Component, OnInit } from '@angular/core';

import { StudentsSearchService } from '../../components/students/students-search/students-search.service';

@Component({
  selector: 'service-menu',
  templateUrl: 'service-menu.component.html'
})
export class ServiceMenuComponent implements OnInit {
  constructor(public ss: StudentsSearchService) { }

  ngOnInit() { }
}