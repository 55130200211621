import { Component, OnInit } from '@angular/core';

import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'student-evaluation',
  templateUrl: './student-evaluation.component.html',
})
export class StudentEvaluationComponent implements OnInit {

  constructor(public sr: StudentRootService) { }

  ngOnInit() {
  }

}
