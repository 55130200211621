import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { NgForm } from '@angular/forms';
import { RefreshService } from '../../../../../data-services/refresh.service';

@Component({
  selector: 'video-conference-thesis-modal',
  templateUrl: './video-conference-thesis-modal.component.html',
  styles: [
  ]
})
export class VideoConferenceThesisModalComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() open = false;

  @Input() studentId: string;
  @Input() videoConference: boolean;
  videoConferenceDocs: IDocumentRef[];

  constructor(private ss: ServiceHttpService,
    private alerts: PopAlertsService,
    private refresh: RefreshService) { }

  ngOnInit(): void {
  }

  save() {
    if (!this.f.valid) return;

    this.ss.saveVideoConference({
      studentId: this.studentId,
      videoConference: this.videoConference,
      videoConferenceDocs: this.videoConferenceDocs
    }).subscribe(() => {
      this.open = false;
      this.refresh.onStudentChange.next();
      this.alerts.saveSuccess();
    })
  }

}
