import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'momentToNow'
})
export class MomentToNowPipe implements PipeTransform {
  constructor(private ts: TranslateService) { }

  transform(value: Date | moment.Moment, ...args: any[]): any {
    moment.locale(this.ts.currentLang);
    return moment(value).toNow();
  }
}