<form *ngIf="showFilters">
  <div class="row mb-2">
    <div class="col">
      <search-input name="search"
        [ngModel]="filters.searchText"
        (ngModelChange)="filters.searchText = $event; emitFilters()"
        [placeholder]="'search-student-by-name-or-id-num'"></search-input>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-12 col-md pr-md-0"
      *ngIf="program">
      <ng-select id="programFilter"
        name="programFilter"
        [items]="programs"
        bindValue="_id"
        bindLabel="name"
        style="width: 100%;"
        [ngModel]="filters.programs"
        (ngModelChange)="filters.programs = $event; emitFilters()"
        [placeholder]="'program' | translate"
        [searchable]="true"
        [clearable]="true"
        [multiple]="true">

        <ng-template ng-label-tmp
          let-item="item"
          let-clear="clear">
          <span class="ng-value-label">
            {{ item.code }}
          </span>
          <span class="ng-value-icon right"
            (click)="clear(item)"
            aria-hidden="true">x</span>
        </ng-template>

        <ng-template ng-option-tmp
          let-item="item">
          {{ item.code }} - {{ item.name }}
        </ng-template>
      </ng-select>
    </div>
    <div class="col-12 col-md"
      [class.p-md-0]="program"
      *ngIf="state">
      <ng-select id="stateFilter"
        name="stateFilter"
        [items]="states"
        [ngModel]="filters.states"
        (ngModelChange)="filters.states = $event; emitFilters()"
        [placeholder]="'student-state' | translate"
        style="width: 100%;"
        [searchable]="false"
        [clearable]="true"
        [multiple]="true">

        <ng-template ng-label-tmp
          let-item="item"
          let-clear="clear">
          <span class="ng-value-label">
            <student-state [state]="item"></student-state>
          </span>
          <span class="ng-value-icon right"
            (click)="clear(item)"
            aria-hidden="true">x</span>
        </ng-template>

        <ng-template ng-option-tmp
          let-item="item">
          <student-state [state]="item"></student-state>
        </ng-template>

      </ng-select>
    </div>
    <div class="col-12 col-md p-md-0"
      *ngIf="rd">
      <ng-select id="rdFilter"
        name="rdFilter"
        [ngModel]="filters.rd"
        (ngModelChange)="filters.rd = $event; emitFilters()"
        [placeholder]="'filter-rd' | translate"
        style="width: 100%;"
        [searchable]="false"
        [clearable]="true">
        <ng-option [value]="RDType.rd99Mod">
          {{ RDType.rd99Mod | translate }}
        </ng-option>
        <ng-option [value]="RDType.rd99">
          {{ RDType.rd99 | translate }}
        </ng-option>
        <ng-option [value]="RDType.rd1393">
          {{ RDType.rd1393 | translate }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-12 col-md p-md-0"
      *ngIf="deadline">
      <ng-select id="deadlineFilter"
        name="deadlineFilter"
        [ngModel]="filters.deadline"
        (ngModelChange)="filters.deadline = $event; emitFilters()"
        [placeholder]="'filter-deadline' | translate"
        style="width: 100%;"
        [searchable]="false"
        [clearable]="true">
        <ng-option [value]="-1">
          {{ 'one-month-for-deadline' | translate }}
        </ng-option>
        <ng-option [value]="-2">
          {{ 'two-months-for-deadline' | translate }}
        </ng-option>
        <ng-option [value]="-6">
          {{ 'six-months-for-deadline' | translate }}
        </ng-option>
        <ng-option [value]="1">
          {{ 'one-month-since-deadline' | translate }}
        </ng-option>
        <ng-option [value]="2">
          {{ 'two-months-since-deadline' | translate }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-12 col-md-2 mt-2 m-md-auto text-right"
      *ngIf="totalCount !== undefined && totalCount !== null">
      <span *ngIf="totalCount === 0">
        {{ 'no-results' | translate }}
      </span>
      <span *ngIf="totalCount > 0">
        {{ totalCount }} {{ 'students' | translate | lowercase }}
      </span>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col text-right">
      <button class="btn btn-link"
        type="button"
        (click)="downloadReport()">
        <i class="far fa-file-csv text-success mr-1"></i>
        {{ 'export-students-list-data' | translate }}
      </button>
    </div>
  </div>

</form>

<div class="card"
  style="overflow-x: auto;"
  *ngIf="students.length > 0">
  <table class="table table-hover m-0 table-sm">
    <tbody>
      <tr *ngFor="let student of students"
        class="c-pointer"
        (click)="onClick.emit(student)">
        <td *ngIf="program && student.program"
          class="text-center">
          <span class="text-secondary">
            {{ student.program.code }}
          </span>
        </td>
        <td *ngIf="program && student.rd === RDType.rd1393"
          class="text-center">
          <span class="text-info">
            RD1393
          </span>
        </td>
        <td *ngIf="idNum">
          {{ student.idNum }}
        </td>
        <td>
          {{ student | fullname }}
        </td>
        <td *ngIf="email">
          <email-link [user]="student.user"
            (click)="$event.stopPropagation()"></email-link>
        </td>
        <td>
          <span *ngIf="deadline && student.deadline && student.state !== StudentStateType.defended"
            [class]="deadlineColor(student.deadline)">
            {{ student.deadline | moment:'DD/MM/YY' }}
          </span>
          <span *ngIf="student.state === StudentStateType.defended && student.thesis && student.thesis.defenceDate">
            <small>Defensa:</small> {{ student.thesis.defenceDate | moment:'DD/MM/YY' }}
          </span>
        </td>
        <td *ngIf="state"
          class="text-left">
          <student-state [state]="student.state"></student-state>
          <student-state *ngFor="let state of student.altStates"
            [state]="state"
            [ml]="true"></student-state>
        </td>
      </tr>
    </tbody>
  </table>
</div>