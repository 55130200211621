import { Component, OnInit } from '@angular/core';

import { UserService } from '../../user/user.service';
import { Role } from '../../../../interfaces/IUser';

@Component({
  selector: 'dad-menu',
  templateUrl: 'menu.component.html'
})
export class MenuComponent implements OnInit {
  Role = Role

  constructor(public us: UserService) { }

  ngOnInit() { }
}