import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDocumentRef } from '../../../interfaces/IDocument';
import { ITask } from '../../../interfaces/ITask';
import { ThesisDefenceModality, ThesisFinalEvaluation, ThesisReportEvaluation } from '../../../interfaces/IThesisProcess';
import { IActivity } from '../../../interfaces/IActivity';

@Injectable()
export class ExternalTaskHttpService {

  constructor(private http: HttpClient) { }

  findExternal(taskId: string, token: string): Observable<ITask> {
    return this.http.get<ITask>(`/api/etask/${taskId}/${token}`);
  }

  findOld(taskId: string, token: string): Observable<ITask> {
    return this.http.get<ITask>(`/api/etask/findOld/${taskId}/${token}`);
  }

  thesisAcceptance(taskId: string, token: string): Observable<{ taskId: string, token: string }> {
    return this.http.post<{ taskId: string, token: string }>(`/api/etask/thesisAcceptance/${taskId}/${token}`, {});
  }

  thesisReport(taskId: string, token: string, data: { send: boolean, evaluation?: ThesisReportEvaluation, docRef?: IDocumentRef }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/etask/thesisReport/${taskId}/${token}`, data);
  }

  defenceDate(taskId: string, token: string, data: {
    defenceDate: Date,
    defencePlace: string,
    defenceType: "videoconference" | "presential" | "hybrid",
    presidentId?: string,
    presidentModality?: ThesisDefenceModality,
    secretaryId?: string,
    secretaryModality?: ThesisDefenceModality,
    memberId?: string,
    memberModality?: ThesisDefenceModality
  }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/etask/defenceDate/${taskId}/${token}`, data);
  }

  fetchStudentActivities(taskId: string, token: string, params: { searchText?: string, page?: number, size?: number }): Observable<IActivity[]> {
    return this.http.get<IActivity[]>(`/api/etask/activities/${taskId}/${token}`, { params });
  }

  thesisCumLaudeVote(taskId: string, token: string, data: { cumLaude: boolean }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/etask/thesisCumLaudeVote/${taskId}/${token}`, data);
  }

  secretaryEvaluation(taskId: string, token: string, data: { evaluation: ThesisFinalEvaluation, defenceLanguage?: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/etask/secretaryEvaluation/${taskId}/${token}`, data);
  }

}