<menu-item [link]="['/app/service/home']"
  [linkActiveOptions]="{exact: true}"
  icon="home"
  [disabled]="true"
  name="start"></menu-item>

<menu-item [link]="['/app/service/students']"
  (onClick)="ss.reset()"
  icon="users-class"
  name="students"></menu-item>

<menu-item [link]="['/app/service/processes']"
  icon="project-diagram"
  name="processes"></menu-item>

<menu-item [link]="['/app/service/faculties']"
  icon="building"
  name="faculties"></menu-item>

<menu-item [link]="['/app/service/programs']"
  icon="book-alt"
  name="programs"></menu-item>

<menu-item [link]="['/app/qa']"
  icon="file"
  name="reports"></menu-item>

<!-- <menu-item [link]="['/app/service/certificates']"
  [disabled]="true"
  icon="file-certificate"
  name="certificates"></menu-item> -->

<menu-item [link]="['/app/service/emails']"
  icon="envelope"
  name="sent-emails"></menu-item>

<menu-item [link]="['/app/service/users']"
  icon="users"
  name="users"></menu-item>

<menu-item [link]="['/app/service/externals']"
  icon="user-friends"
  name="externals"></menu-item>

<menu-item [link]="['/app/service/roles']"
  icon="user"
  name="main-roles"></menu-item>