<div class="container-lg container-fluid">
  <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-12 mt-3">

    <div class="card">
      <div class="card-body text-center">

        <h5 class="card-title m-0">
          <i class="far fa-check text-success mr-1"></i>
          {{ 'task-completed' | translate }}
        </h5>

        <div *ngIf="doc"
          class="mt-4 w-100 text-left">
          <document-link [document]="doc"></document-link>
        </div>

        <div *ngIf="showMsg"
          class="mt-3">
          <i class="far fa-info text-info mr-2"></i>
          {{ 'task-completed-follow-up-msg' | translate }}
        </div>

      </div>

    </div>
  </div>