<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card"
      *ngIf="!task && notFound">
      <div class="card-body">
        <!-- Loading -->
        <i class="fas fa-info-circle text-info mr-1"></i>
        {{ 'task-not-found-or-completed' | translate }}
      </div>
    </div>

    <div class="alert alert-danger"
      *ngIf="completeTaskPermission === false">
      {{ 'following-function-is-limited' | translate }}:
      <ul class="mt-1">
        <li>
          <span [innerHTML]="'complete-pending-tasks' | translate"></span>
        </li>
      </ul>
    </div>

    <div [class.card]="showCard()"
      *ngIf="task && completeTaskPermission !== null && completeTaskPermission !== undefined">
      <div [class.card-body]="showCard()">

        <ng-container [ngSwitch]="task.processKey">

          <ng-container *ngSwitchCase="Processes.validation">

            <div [ngSwitch]="task.taskKey">
              <validation-validation-task *ngSwitchCase="TKValidation.validation"
                [task]="task"
                [disabled]="!task.active"></validation-validation-task>
              <validation-review-task *ngSwitchCase="TKValidation.review"
                [task]="task"
                [disabled]="!task.active"
                [disabled]="!completeTaskPermission"></validation-review-task>
            </div>

          </ng-container>

          <ng-container *ngSwitchCase="Processes.cds">

            <div [ngSwitch]="task.taskKey">
              <cds-academic-committee-validation-task *ngSwitchCase="TKCDS.academicCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></cds-academic-committee-validation-task>
              <cds-student-request-review *ngSwitchCase="TKCDS.studentReviewRequest"
                [task]="task"
                [disabled]="!task.active"></cds-student-request-review>
              <cds-service-validation-task *ngSwitchCase="TKCDS.serviceValidation"
                [task]="task"
                [disabled]="!task.active"></cds-service-validation-task>
              <cds-service-direction-team-user-creation *ngSwitchCase="TKCDS.directionTeamUserCreation"
                [task]="task"
                [disabled]="!task.active"></cds-service-direction-team-user-creation>
              <cds-pgc-validation *ngSwitchCase="TKCDS.postgraduateCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></cds-pgc-validation>
              <cds-sign *ngSwitchCase="TKCDS.studentSign"
                [task]="task"
                [disabled]="!task.active"></cds-sign>
              <cds-sign *ngSwitchCase="TKCDS.directionTeamSign"
                [task]="task"
                [disabled]="!task.active"></cds-sign>
              <cds-sign *ngSwitchCase="TKCDS.postgraduateCommitteePresidentSign"
                [task]="task"
                [disabled]="!task.active"></cds-sign>
              <cds-sign *ngSwitchCase="TKCDS.doctoralSchoolPrincipalSign"
                [task]="task"
                [disabled]="!task.active"></cds-sign>
            </div>

          </ng-container>

          <ng-container *ngSwitchCase="Processes.dedication">
            <div [ngSwitch]="task.taskKey">
              <dedication-direction-team-validation *ngSwitchCase="TKDedication.directionTeamValidation"
                [task]="task"
                [disabled]="!task.active"></dedication-direction-team-validation>
              <dedication-ac-validation *ngSwitchCase="TKDedication.academicCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></dedication-ac-validation>
              <dedication-pgc-validation *ngSwitchCase="TKDedication.postgraduateCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></dedication-pgc-validation>
            </div>
          </ng-container>


          <ng-container *ngSwitchCase="Processes.direction">
            <div [ngSwitch]="task.taskKey">
              <direction-academic-committee-validation-task *ngSwitchCase="TKDirection.academicCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></direction-academic-committee-validation-task>
              <direction-student-request-review *ngSwitchCase="TKDirection.studentReviewRequest"
                [task]="task"
                [disabled]="!task.active"></direction-student-request-review>
              <direction-service-validation-task *ngSwitchCase="TKDirection.serviceValidation"
                [task]="task"
                [disabled]="!task.active"></direction-service-validation-task>
              <direction-service-direction-team-user-creation *ngSwitchCase="TKDirection.directionTeamUserCreation"
                [task]="task"
                [disabled]="!task.active"></direction-service-direction-team-user-creation>
              <direction-pgc-validation *ngSwitchCase="TKDirection.postgraduateCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></direction-pgc-validation>
              <direction-sign *ngSwitchCase="TKDirection.studentSign"
                [task]="task"
                [disabled]="!task.active"></direction-sign>
              <direction-sign *ngSwitchCase="TKDirection.directionTeamSign"
                [task]="task"
                [disabled]="!task.active"></direction-sign>
              <direction-sign *ngSwitchCase="TKDirection.postgraduateCommitteePresidentSign"
                [task]="task"
                [disabled]="!task.active"></direction-sign>
              <direction-sign *ngSwitchCase="TKDirection.doctoralSchoolPrincipalSign"
                [task]="task"
                [disabled]="!task.active"></direction-sign>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.extension">
            <div [ngSwitch]="task.taskKey">
              <extension-ac-validation *ngSwitchCase="TKExtension.academicCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></extension-ac-validation>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.leave">
            <div [ngSwitch]="task.taskKey">
              <leave-ac-validation *ngSwitchCase="TKLeave.academicCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></leave-ac-validation>
              <leave-service-validation *ngSwitchCase="TKLeave.serviceValidation"
                [task]="task"
                [disabled]="!task.active"></leave-service-validation>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.permanentLeave">
            <div [ngSwitch]="task.taskKey">
              <permanent-leave-coordinator-report *ngSwitchCase="TKPermanentLeave.coordinatorReport"
                [task]="task"
                [disabled]="!task.active"></permanent-leave-coordinator-report>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.discharge">
            <div [ngSwitch]="task.taskKey">
              <discharge-ac-validation *ngSwitchCase="TKDischarge.academicCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></discharge-ac-validation>
              <discharge-service-validation *ngSwitchCase="TKDischarge.serviceValidation"
                [task]="task"
                [disabled]="!task.active"></discharge-service-validation>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.renewal">
            <div [ngSwitch]="task.taskKey">
              <renewal-service-validation *ngSwitchCase="TKRenewal.serviceValidation"
                [task]="task"
                [disabled]="!task.active"></renewal-service-validation>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.evaluation">
            <div [ngSwitch]="task.taskKey">
              <evaluation-direction-team-report *ngSwitchCase="TKEvaluation.directionTeamReport"
                [task]="task"
                [disabled]="!task.active"></evaluation-direction-team-report>
              <evaluation-committee-confirmation *ngSwitchCase="TKEvaluation.evaluationCommitteeConfirmation"
                [task]="task"
                [disabled]="!task.active"></evaluation-committee-confirmation>
              <evaluation-committee-evaluation *ngSwitchCase="TKEvaluation.evaluationCommitteeEvaluation"
                [task]="task"
                [disabled]="!task.active"></evaluation-committee-evaluation>
              <evaluation-committee-evaluation *ngSwitchCase="TKEvaluation.confirmationBySecretary"
                [task]="task"
                [disabled]="!task.active"></evaluation-committee-evaluation>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.thesis">
            <div [ngSwitch]="task.taskKey">
              <thesis-secretary-validation *ngSwitchCase="TKThesis.secretaryValidation"
                [task]="task"
                [disabled]="!task.active"></thesis-secretary-validation>
              <thesis-direction-team-validation *ngSwitchCase="TKThesis.directionTeamValidation"
                [task]="task"
                [disabled]="!task.active"></thesis-direction-team-validation>
              <thesis-student-review-request *ngSwitchCase="TKThesis.studentReviewRequest"
                [task]="task"
                [disabled]="!task.active"></thesis-student-review-request>
              <thesis-ac-validation *ngSwitchCase="TKThesis.academicCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></thesis-ac-validation>
              <thesis-ds-principal-validation *ngSwitchCase="TKThesis.doctoralSchoolPrincipalValidation"
                [task]="task"
                [disabled]="!task.active"></thesis-ds-principal-validation>
              <thesis-service-validation *ngSwitchCase="TKThesis.serviceValidation"
                [task]="task"
                [disabled]="!task.active"></thesis-service-validation>
              <thesis-pgc-info *ngSwitchCase="TKThesis.postgraduateCommitteeInfo"
                [task]="task"></thesis-pgc-info>
              <thesis-pgcs-validation *ngSwitchCase="TKThesis.postgraduateCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></thesis-pgcs-validation>
              <thesis-defence-request *ngSwitchCase="TKThesis.defenceRequest"
                [task]="task"
                [disabled]="!task.active"
                [disabled]="!completeTaskPermission"></thesis-defence-request>
              <thesis-defence-direction-team-validation *ngSwitchCase="TKThesis.defenceDirectionTeamValidation"
                [task]="task"
                [disabled]="!task.active"></thesis-defence-direction-team-validation>
              <thesis-defence-pgc-validation *ngSwitchCase="TKThesis.defencePostgraduateCommitteeValidation"
                [task]="task"
                [disabled]="!task.active"></thesis-defence-pgc-validation>
              <thesis-service-register *ngSwitchCase="TKThesis.serviceRegisterDefence"
                [task]="task"
                [disabled]="!task.active"></thesis-service-register>
              <thesis-direction-team-decision-reject-report *ngSwitchCase="TKThesis.directionTeamDecisionRejectReport"
                [task]="task"
                [disabled]="!task.active"></thesis-direction-team-decision-reject-report>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.industrial">
            <div>
              <industrial-sign [task]="task"
                [disabled]="!task.active"></industrial-sign>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="Processes.professor">
            <div [ngSwitch]="task.taskKey">
              <professor-service-validation [task]="task"
                *ngSwitchCase="TKProfessor.serviceValidation"
                [disabled]="!task.active"></professor-service-validation>
              <professor-coordinator-review [task]="task"
                *ngSwitchCase="TKProfessor.coordinatorReview"
                [disabled]="!task.active"></professor-coordinator-review>
              <professor-pgc-validation [task]="task"
                *ngSwitchCase="TKProfessor.postgraduateCommitteeValidation"
                [disabled]="!task.active"></professor-pgc-validation>
            </div>
          </ng-container>

        </ng-container>

        <div class="alert alert-danger"
          *ngIf="!task.active">
          <h5 class="badge badge-danger text-center d-inline-block">
            {{ 'process-inactive' | translate }}
          </h5>
          <div>
            {{ 'process-inactive-info' | translate }}
          </div>
        </div>

      </div>
    </div>



  </div>
</div>