import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IIndustrialMentionInfo } from '../../../interfaces/IStudent';
import { Observable } from 'rxjs';
import { IIndustrialProcess } from '../../../interfaces/IIndustrialProcess';

@Injectable()
export class IndustrialMentionHttpService {
  constructor(private http: HttpClient) { }

  saveIndustrialMentionInfo(studentId: string, industrialMentionInfo: IIndustrialMentionInfo): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/industrialMention/${studentId}`, industrialMentionInfo);
  }

  launch(studentId: string, data: IIndustrialMentionInfo): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/industrialMention/launch/${studentId}`, data);
  }

  sign(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/industrialMention/sign/${taskId}`, {});
  }

  signDeny(taskId: string, denialMsg: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/industrialMention/signDeny/${taskId}`, { denialMsg });
  }

  findProcess(taskId: string): Observable<IIndustrialProcess> {
    return this.http.get<IIndustrialProcess>(`/api/industrialMention/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<IIndustrialProcess> {
    return this.http.get<IIndustrialProcess>(`/api/industrialMention/process/${studentId}`);
  }

}