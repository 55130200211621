import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { IEvaluationProcess } from '../../../../../../../interfaces/IEvaluationProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { EvaluationHttpService } from '../../../../../data-services/evaluation-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { RDType } from '../../../../../../../interfaces/IStudent';

@Component({
  selector: 'evaluation-direction-team-report',
  templateUrl: './evaluation-direction-team-report.component.html',
})
export class EvaluationDirectionTeamReportComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  process: IEvaluationProcess;

  report: IDocumentRef;

  RDType = RDType;

  constructor(private evs: EvaluationHttpService, private router: Router) {
    super();
  }

  ngOnInit() {
    this.evs.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    this.evs.directionTeamReport(this.task._id, this.report).subscribe(() => {
      this.sendEnd.next();
      void this.router.navigate(['/app/task-completed']);
    })
  }

}
