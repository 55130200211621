import { Component, OnInit } from '@angular/core';

import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'student-processes',
  templateUrl: './student-processes.component.html'
})
export class StudentProcessesComponent implements OnInit {

  constructor(public sr: StudentRootService) { }

  ngOnInit() {
  }

}
