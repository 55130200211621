<div class="container">
  <div class="row">
    <div class="col">
      <section-title text="students"
        icon="users-class">
      </section-title>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <students-search [dataFn]="dataFn()"
        [reportURL]="'service/students/report'"
        [rd]="true"></students-search>
    </div>
  </div>
</div>