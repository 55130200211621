import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { appear } from '../animations/animations';
import { Alert, PopAlertsService } from './pop-alerts.service';

@Component({
  selector: 'pop-alerts',
  templateUrl: 'pop-alerts.component.html',
  styleUrls: ['pop-alerts.component.scss'],
  animations: [appear]
})
export class PopAlertsComponent implements OnInit, OnDestroy {

  sub: Subscription;

  alert: Alert;

  timeout;

  constructor(private pas: PopAlertsService) { }

  ngOnInit() {
    this.sub = this.pas.alerts.subscribe((alert) => {
      this.alert = alert;
      if (this.alert.dismissable === undefined) {
        this.alert.dismissable = true;
      }

      let time = 5000;
      if (alert.dismissTime !== undefined) {
        time = alert.dismissTime;
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      if (time > 0) {
        this.timeout = setTimeout(() => {
          this.alert = undefined;
        }, time);
      }
    });
  }

  ngOnDestroy() {
    if (this.sub)
      this.sub.unsubscribe();
  }

  emitAction() {
    this.pas.emitAction(this.alert);
    if (this.alert.action && this.alert.action.fn) {
      this.alert.action.fn();
    }
    this.alert = undefined;
  }
}