import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppService } from './app.service';
import { DropdownService } from './UI/dropdown-service/dropdown.service';

import * as moment from 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'DAD3';

  constructor(private translate: TranslateService, public dds: DropdownService, public appService: AppService) {
    this.translate.addLangs(['es', 'en']);
    this.translate.setDefaultLang('es');

    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang.match(/es|en/) ? browserLang : 'es');

    moment.tz.setDefault('Europe/Madrid');
  }

  ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
  closeDropdowns(event) {
    this.dds.closeDropdowns(event);
  }
}
