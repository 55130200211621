import { Component, Input, OnInit } from '@angular/core';

import { ITask } from '../../../../../interfaces/ITask';
import { HelperService } from '../../../shared/helper-service/helper.service';

@Component({
  selector: 'task-header',
  templateUrl: 'task-header.component.html'
})
export class TaskHeaderComponent implements OnInit {
  @Input() task: ITask;
  @Input() showTitle = true;
  @Input() shorDesc = true;

  constructor(public hs: HelperService) { }

  ngOnInit() { }
}