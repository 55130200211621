<form #f="ngForm"
  (ngSubmit)="send()">

  <legend>
    {{ 'professor-data' | translate }}
  </legend>

  <div class="form-group">
    <label for="externalSelect">
      {{ 'external' | translate }}
    </label>
    <ng-select id="externalSelect"
      name="externalSelect"
      #externalSelect="ngModel"
      [(ngModel)]="data.external"
      required
      [class.is-invalid]="f.submitted && externalSelect.invalid"
      [searchable]="false"
      [clearable]="false">
      <ng-option [value]="false">{{ 'no' | translate }}</ng-option>
      <ng-option [value]="true">{{ 'yes' | translate }}</ng-option>
    </ng-select>
    <validation-errors [show]="f.submitted"
      [control]="externalSelect"></validation-errors>
  </div>

  <div class="form-group"
    *ngIf="data.external === true">
    <label for="university">
      Universidad/Centro de investigación
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'Universidad/Centro de investigación'"
      name="university"
      [(ngModel)]="data.university"
      [required]="true"
      #universityInput="ngModel"
      [class.is-invalid]="f.submitted && universityInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="universityInput"></validation-errors>
  </div>

  <div class="form-group">
    <label for="idNum">
      {{ 'idNum' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'idNum' | translate"
      name="idNum"
      [(ngModel)]="data.idNum"
      (blur)="checkIdNum()"
      [required]="true"
      #idNumInput="ngModel"
      [class.is-invalid]="f.submitted && idNumInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="idNumInput"></validation-errors>

    <div class="alert alert-danger my-2"
      *ngIf="idNumExists">
      Ya existe un profesor con este NIF/NIE/Pasaporte en el programa de doctorado.
    </div>
    <div class="alert alert-warning my-2"
      *ngIf="us.isService() && idNumExistsInAnotherProgram">
      Ya existe un profesor con este NIF/NIE/Pasaporte en otro programa de doctorado ({{idNumOtherProgramName}}).
    </div>
  </div>
  <div class="form-group">
    <label for="firstName">
      {{ 'firstName' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'firstName' | translate"
      name="firstName"
      [(ngModel)]="data.firstName"
      [required]="true"
      #firstNameInput="ngModel"
      [class.is-invalid]="f.submitted && firstNameInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="firstNameInput"></validation-errors>
  </div>
  <div class="form-group">
    <label for="idNum">
      {{ 'lastName' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'lastName' | translate"
      name="lastName"
      [(ngModel)]="data.lastName"
      [required]="true"
      #lastNameInput="ngModel"
      [class.is-invalid]="f.submitted && lastNameInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="lastNameInput"></validation-errors>
  </div>

  <div class="form-group">
    <label for="email">
      {{ 'email' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'search-by-email' | translate"
      name="email"
      [(ngModel)]="data.email"
      (blur)="checkEmail()"
      [required]="true"
      [pattern]="hs.EMAIL_REGEX"
      #emailInput="ngModel"
      [class.is-invalid]="f.submitted && emailInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="emailInput"></validation-errors>

    <div class="alert alert-danger my-2"
      *ngIf="emailExists">
      Ya existe un profesor con este correo electrónico en el programa de doctorado.
    </div>
    <div class="alert alert-warning my-2"
      *ngIf="us.isService() && emailExistsInAnotherProgram">
      Ya existe un profesor con este correo electrónico en otro programa de doctorado ({{emailOtherProgramName}}).
    </div>
  </div>

  <div class="form-group">
    <label for="category">
      {{ 'category' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'category' | translate"
      name="category"
      [(ngModel)]="data.category"
      [required]="true"
      #categoryInput="ngModel"
      [class.is-invalid]="f.submitted && categoryInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="categoryInput"></validation-errors>
  </div>

  <div class="form-group">
    <label for="lineOfResearchSelect">
      {{ 'line-of-research' | translate }}
    </label>
    <ng-select id="lineOfResearchSelect"
      name="lineOfResearchSelect"
      #lineOfResearchSelect="ngModel"
      [items]="linesOfResearch"
      bindValue="_id"
      bindLabel="name"
      required
      [(ngModel)]="data.lineOfResearchId"
      [class.is-invalid]="f.submitted && lineOfResearchSelect.invalid"
      [placeholder]="'select-line-of-research' | translate"
      [searchable]="false"
      [clearable]="false">
    </ng-select>
    <validation-errors [show]="f.submitted"
      [control]="lineOfResearchSelect"></validation-errors>
  </div>

  <div class="form-group">
    <label for="cv">
      {{ 'cv-link' | translate }}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'cv-link' | translate"
      name="cv"
      [(ngModel)]="data.curriculumLink"
      [required]="true"
      #cvInput="ngModel"
      urlValidator
      [class.is-invalid]="f.submitted && cvInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="cvInput"></validation-errors>
  </div>

  <div class="form-group">
    <label for="professorReasonSelect">
      {{ 'professor-reason' | translate }}
    </label>
    <ng-select id="professorReasonSelect"
      name="professorReasonSelect"
      #professorReasonSelect="ngModel"
      [(ngModel)]="data.reason"
      [required]="true"
      [class.is-invalid]="f.submitted && professorReasonSelect.invalid"
      [placeholder]="'supervisor-requirement' | translate"
      [multiple]="false"
      [searchable]="false"
      [clearable]="false">
      <ng-option [value]="ProfessorReason.sixyearPeriod">{{ 'sixyearPeriod' | translate }}</ng-option>
      <ng-option [value]="ProfessorReason.other">{{ 'other-merits' | translate }}</ng-option>
    </ng-select>
    <validation-errors [show]="f.submitted"
      [control]="professorReasonSelect"></validation-errors>
  </div>

  <div class="form-group"
    *ngIf="data.external === true && data.reason === ProfessorReason.sixyearPeriod">
    <label>
      {{ 'sixyear-period-justification' | translate }}
    </label>
    <file-input name="sixyearDoc"
      #sixyearDocInput="ngModel"
      required
      [(ngModel)]="data.sixyearDoc"></file-input>
    <validation-errors [show]="f.submitted"
      [control]="sixyearDocInput"></validation-errors>
  </div>

  <div class="form-group">
    <label for="numThesis">
      {{ 'num-thesis' | translate}}
    </label>
    <input type="number"
      class="form-control"
      [placeholder]="'num-thesis' | translate"
      name="numThesis"
      [(ngModel)]="data.thesisCount"
      [required]="true"
      #numThesisInput="ngModel"
      [class.is-invalid]="f.submitted && numThesisInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="numThesisInput"></validation-errors>
  </div>

  <div class="form-group">
    <label for="orcid">
      {{ 'orcid-profile' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'orcid-profile' | translate"
      name="orcid"
      [(ngModel)]="data.orcid"
      #orcidInput="ngModel"
      [required]="true"
      [class.is-invalid]="f.submitted && orcidInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="orcidInput"></validation-errors>
  </div>

  <div class="form-group">
    <label for="otherProfile">
      {{ 'other-profile' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'other-profile' | translate"
      name="otherProfile"
      [(ngModel)]="data.otherProfile"
      #otherProfileInput="ngModel"
      [class.is-invalid]="f.submitted && otherProfileInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="otherProfileInput"></validation-errors>
  </div>

  <div class="form-group">
    <label for="currentProgram">
      {{ 'other-program' | translate}}
    </label>
    <ng-select id="currentProgram"
      name="currentProgram"
      #currentProgram="ngModel"
      [(ngModel)]="data.otherProgram"
      [class.is-invalid]="f.submitted && currentProgram.invalid"
      [searchable]="false"
      [clearable]="false">
      <ng-option [value]="false">{{ 'no' | translate }}</ng-option>
      <ng-option [value]="true">{{ 'yes' | translate }}</ng-option>
    </ng-select>
    <validation-errors [show]="f.submitted"
      [control]="currentProgram"></validation-errors>
  </div>

  <div class="form-group"
    *ngIf="data.otherProgram === true">
    <label for="currentProgramName">
      {{ 'other-program-name' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'other-program-name' | translate"
      name="currentProgramName"
      [(ngModel)]="data.otherProgramName"
      [required]="true"
      #currentProgramNameInput="ngModel"
      [class.is-invalid]="f.submitted && currentProgramNameInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="currentProgramNameInput"></validation-errors>
  </div>

  <div class="form-group"
    *ngIf="data.otherProgram === true">
    <label for="currentProgramUniversity">
      {{ 'other-program-university' | translate}}
    </label>
    <input type="text"
      class="form-control"
      [placeholder]="'other-program-university' | translate"
      name="currentProgramUniversity"
      [(ngModel)]="data.otherProgramUniversity"
      [required]="true"
      #currentProgramUniversityInput="ngModel"
      [class.is-invalid]="f.submitted && currentProgramUniversityInput.invalid">
    <validation-errors [show]="f.submitted"
      [control]="currentProgramUniversityInput"></validation-errors>
  </div>

  <div class="form-group text-right">
    <button class="btn btn-primary"
      type="submit"
      [disableButton]="sending"
      [disableButtonIcon]="sending ? 'spin' : 'ban'">
      {{ 'send' | translate}}
    </button>
  </div>

</form>