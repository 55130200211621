import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ICDSProcess, ICDSRequestData } from '../../../../../../../interfaces/ICDSProcess';
import { ILineOfResearch } from '../../../../../../../interfaces/ILineOfResearch';
import { ITask } from '../../../../../../../interfaces/ITask';
import { CDSHttpService } from '../../../../../data-services/cds-http.service';
import { ProgramHttpService } from '../../../../../data-services/program-http.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { UserService } from '../../../../../user/user.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'cds-service-validation-task',
  templateUrl: './cds-service-validation-task.component.html',
})
export class CdsServiceValidationTaskComponent extends AbstractTaskComponent implements OnInit {

  @Input() task: ITask;

  linesOfResearch: ILineOfResearch[] = [];

  process: ICDSProcess;

  denialMsg = '';

  data: ICDSRequestData = {
    dedication: null,
    validate: null,
    tutor: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor1: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor2: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor3: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    needsSupplement: false,
    supplement: {
      credits: null,
      document: null,
      notes: ''
    }
  };

  enableSupervisor2 = false;
  enableSupervisor3 = false;


  constructor(private cds: CDSHttpService, private ps: ProgramHttpService,
    private us: UserService,
    private rs: RefreshService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.cds.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      this.data.dedication = process.dedication;
      if (process.tutor) {
        this.data.tutor = process.tutor;
      }
      if (process.partialTimeDoc) {
        this.data.partialTimeDoc = process.partialTimeDoc;
      }
      if (process.supervisor1) {
        this.data.supervisor1 = process.supervisor1;
      }
      if (process.supervisor2) {
        this.data.supervisor2 = process.supervisor2;
        this.enableSupervisor2 = true;
      }
      if (process.supervisor3) {
        this.data.supervisor3 = process.supervisor3;
        this.enableSupervisor3 = true;
      }
      if (process.thirdSupervisorDoc) {
        this.data.thirdSupervisorDoc = process.thirdSupervisorDoc;
      }
      if (process.needsSupplement) {
        this.data.needsSupplement = process.needsSupplement;
      }
      if (process.supplement) {
        this.data.supplement = process.supplement;
      }
      if (process.lineOfResearchId) {
        this.data.lineOfResearchId = process.lineOfResearchId;
      }

      this.ps.linesOfResearch(process.student.programId).subscribe((lines) => {
        this.linesOfResearch = lines;
      });

    })
  }

  manageSend(data: ICDSRequestData) {
    if (!data.validate) {
      this.cds.serviceDenyValidation(this.task._id, { denialMsg: data.denialMsg }).subscribe(() => {
        void this.router.navigate(['/app/task-completed']);
        this.sendEnd.next();
      });
    } else {
      this.cds.serviceValidation(this.task._id, data).subscribe((res) => {
        this.sendEnd.next();
        if (res.followUpTaskId) {
          void this.router.navigate([`/app/task/${res.followUpTaskId}`]);
          this.rs.onMessagesChange.next();
          this.rs.onTasksChange.next();
        } else {
          void this.router.navigate(['/app/task-completed']);
        }
      });
    }
  }

}
