<form #f="ngForm"
  autocomplete="off"
  id="activityEditForm"
  (ngSubmit)="save()"
  *ngIf="active && activity">

  <div class="form-group row">
    <label for="activityType"
      class="col-md-3 col-form-label">
      {{ 'activity-type' | translate }}
    </label>
    <div class="col-md-9">
      <ng-select [items]="activityTypes"
        bindLabel="name"
        bindValue="value"
        required="true"
        [multiple]="false"
        id="activityType"
        name="activityType"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-activity-type' | translate"
        #activityType="ngModel"
        [class.is-invalid]="activityType.invalid && f.submitted"
        [(ngModel)]="activity.activityType">
      </ng-select>
      <validation-errors [control]="activityType"
        [show]="f.submitted"></validation-errors>
    </div>
  </div>

  <div class="form-group row">
    <label for="title"
      class="col-md-3 col-form-label">{{ 'title' | translate }}</label>

    <div class="col-md-9">
      <input class="form-control"
        name="title"
        id="title"
        required
        (keydown.enter)="$event.preventDefault()"
        [class.is-invalid]="titleInput.invalid && f.submitted"
        #titleInput="ngModel"
        [(ngModel)]="activity.title"
        [placeholder]="'activity-title' | translate">
      <validation-errors [control]="titleInput"
        [show]="f.submitted"></validation-errors>
    </div>
  </div>

  <div class="form-group row">
    <label for="description"
      class="col-md-3 col-form-label">
      {{ 'description' | translate }}
    </label>
    <div class="col-md-9">
      <textarea class="form-control"
        name="description"
        id="description"
        #descriptionInput="ngModel"
        rows="4"
        maxlength="400"
        [placeholder]="'short-activity-description' | translate"
        [(ngModel)]="activity.description"></textarea>
      <div class="text-right small text-muted">
        {{ activity.description ? activity.description.length : 0}}/400
      </div>
      <validation-errors [control]="descriptionInput"
        [show]="f.submitted"></validation-errors>
    </div>
  </div>

  <div class="form-group row">
    <label for="startDate"
      class="col-md-3 col-form-label">
      {{ 'start-date' | translate }}
    </label>

    <div class="col-md-9">
      <date-picker id="startDate"
        name="startDate"
        [placeholder]="'start-date' | translate"
        [required]="activity.endDate !== undefined && activity.endDate !== null"
        [isInvalid]="startDateInput.invalid && f.submitted"
        #startDateInput="ngModel"
        [time]="false"
        (keydown.enter)="$event.preventDefault()"
        [(ngModel)]="activity.startDate"> </date-picker>
      <validation-errors [control]="startDateInput"
        [show]="f.submitted"></validation-errors>
    </div>
  </div>

  <div class="form-group row">
    <label for="endDate"
      class="col-md-3 col-form-label">
      {{ 'end-date' | translate }}
    </label>

    <div class="col-md-9">
      <date-picker id="endDate"
        name="endDate"
        [placeholder]="'end-date' | translate"
        [required]="activity.startDate !== undefined && activity.startDate !== null"
        [isInvalid]="endDateInput.invalid && f.submitted"
        #endDateInput="ngModel"
        [time]="false"
        (keydown.enter)="$event.preventDefault()"
        [(ngModel)]="activity.endDate"> </date-picker>
      <validation-errors [control]="endDateInput"
        [show]="f.submitted"></validation-errors>
    </div>
  </div>

  <ng-container *ngIf="!editing()">
    <div class="form-group row">
      <label class="col-md-3 col-form-label">{{ 'evidence-files' | translate }}</label>
      <div class="col-md-9">
        <files-queue [(ngModel)]="attachedFiles"
          name="attachedFiles"
          #attachedFilesInput="ngModel"
          [required]="false"></files-queue>
        <validation-errors [control]="attachedFilesInput"
          [show]="f.submitted"></validation-errors>
      </div>

    </div>
  </ng-container>

</form>