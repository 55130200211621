import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { RefreshService } from '../../../../data-services/refresh.service';

@Component({
  selector: 'task-completed',
  templateUrl: 'task-completed.component.html'
})
export class TaskCompletedComponent implements OnInit {

  doc: IDocumentRef;

  showMsg = true;

  constructor(private rs: RefreshService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.rs.onTasksChange.next();
    this.route.queryParams.subscribe((queryParams) => {
      const docRef = queryParams.docRef;
      if (docRef) {
        this.doc = {
          documentRef: docRef,
          fileName: 'Justificante.pdf'
        }
      }

      let m = queryParams.m;
      if (m === '0') {
        this.showMsg = false;
      } else {
        this.showMsg = true;
      }
    })
  }
}