import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'service-home',
  templateUrl: './service-home.component.html'
})
export class ServiceHomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    void this.router.navigate(['/app/service/students'])
  }

}
