import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ITask } from '../../../interfaces/ITask';
import { Group, IUser, Role, RolePriority } from '../../../interfaces/IUser';
import { DADUtils } from '../../../utils/DADUtils';

@Injectable()
export class UserService {

  selectableRoles: {
    role: Role,
    group: Group,
    groupId?: string,
    groupName?: string
  }[] = [];

  selectedRole: {
    role: Role,
    group: Group,
    groupId?: string,
    groupName?: string
  } = { role: null, group: Group.global };

  user: IUser;

  roleObs = new ReplaySubject<{
    role: Role,
    group: Group,
    groupId?: string,
    groupName?: string
  }>(1);

  roleRefresh = new Subject<{
    role: Role,
    group: Group,
    groupId?: string,
    groupName?: string
  }>();

  constructor(private http: HttpClient, private router: Router) {

  }

  login() {
    window.location.href = `${window.location.origin}/api/login`;
  }

  isLoggedIn(): Observable<boolean> {
    return this.http.get<{ loggedIn: boolean }>('/api/login/loggedIn').pipe(map(res => res.loggedIn));
  }

  fetchUser(): Observable<IUser> {
    return this.http.get<IUser>('/api/me');
  }

  name() {
    if (this.user) {
      return `${this.user.lastName}, ${this.user.firstName}`;
    }

    return '';
  }

  setUser(usr) {
    this.user = usr;
    this.selectableRoles = [];
    this.user.roles.forEach(roleStr => {
      const role = DADUtils.parseRole(roleStr);

      if (role.group === Group.program) {
        this.getProgramName(role.groupId).subscribe((name) => {
          role.groupName = name;
        });
      } else if (role.group === Group.faculty) {
        this.getFacultyName(role.groupId).subscribe((name) => {
          role.groupName = name;
        })
      }

      if (this.isSelectable(role)) {
        this.selectableRoles.push(role);
      }
    });

    this.selectableRoles = _.orderBy(this.selectableRoles, [
      role => RolePriority[role.role] || Infinity,
      'role'
    ], ['asc', 'asc']);

    if (this.user.selectedRole) {
      const urole = DADUtils.parseRole(this.user.selectedRole);
      const find = this.selectableRoles.find(r => {
        return r.role === urole.role
          && r.group === urole.group
          && r.groupId == urole.groupId
      });
      if (find) {
        this.selectRole(find);
        return;
      }
    }

    if (this.selectableRoles.length > 0) {
      this.selectRole(this.selectableRoles[0]);
    }
  }

  selectRole(role, navigateHome = false) {
    this.selectedRole = role;
    this.roleObs.next(this.selectedRole);
    this.roleRefresh.next(this.selectedRole);
    this.saveSelectedRole();
    if (navigateHome) {
      this.navigateRoleHome(role);
    }
  }

  private roleStr(role: {
    role: Role,
    group: Group,
    groupId?: string,
    groupName?: string
  }) {
    if (!role) return null;
    let roleStr = `${role.role}-${role.group}`;
    if (role.groupId) {
      roleStr += `-${role.groupId}`
    }
    return roleStr;
  }

  private isSelectable(role: { role: Role, group: Group, groupId?: string }): boolean {
    if (role.role === Role.modifyEvaluations) return false;
    return role.group === Group.program || role.group === Group.faculty || role.group === Group.global;
  }

  getProgramName(programId: string): Observable<string> {
    return this.http.get<{ res: string }>(`/api/program/${programId}/name`).pipe(
      map(r => r.res)
    );
  }

  getFacultyName(facultyId: string): Observable<string> {
    return this.http.get<{ res: string }>(`/api/secretary/${facultyId}/name`).pipe(
      map(r => r.res)
    );
  }

  private saveSelectedRole() {
    if (!this.selectedRole) return;

    if (this.user.selectedRole && this.roleStr(this.selectedRole) === this.user.selectedRole) {
      return;
    }

    this.http.put<{ ok: boolean }>('/api/me/saveSelectedRole', { role: this.roleStr(this.selectedRole) }).pipe(
      map(r => r.ok)
    ).subscribe(() => { });
  }

  hasPermission(task: ITask): boolean {
    if (!this.user) return false;

    if (task.assigneeId && task.assigneeId === this.user._id) {
      return true;
    }

    if (task.completeRoles && task.completeRoles.some(cr => this.user.roles.includes(cr))) {
      return true;
    }

    if (task.roles) {
      return task.roles.some(cr => this.user.roles.includes(cr));
    }

    return false;
  }

  canComplete(task: ITask): boolean {
    if (!this.user) return false;
    // if (this.user.roles.find(r => r === `${Role.service}-${Group.global}`)) return true;

    if (task.assigneeId && task.assigneeId === this.user._id) {
      return true;
    }

    if (task.completeRoles && task.completeRoles.some(cr => this.user.roles.includes(cr))) {
      return true;
    }

    if (task.roles && (!task.completeRoles || task.completeRoles.length <= 0)) {
      return task.roles.some(cr => this.user.roles.includes(cr));
    }

    return false;
  }

  navigateRoleHome(role: {
    role: Role,
    group: Group,
    groupId?: string,
    groupName?: string
  }) {
    switch (role.role) {
      case Role.student:
        void this.router.navigate(['/app/student']);
        break;

      case Role.service:
        void this.router.navigate(['/app/service']);
        break;

      case Role.qa:
        void this.router.navigate(['/app/qa']);
        break;

      case Role.tutor:
        void this.router.navigate(['/app/tutor/students']);
        break;

      case Role.evaluationCommitteeMember:
        void this.router.navigate(['/app/ecMember/students']);
        break;

      case Role.supervisor:
        void this.router.navigate(['/app/supervisor/students']);
        break;

      case Role.coordinator:
        void this.router.navigate(['/app/coordinator']);
        break;

      case Role.academicCommittee:
        void this.router.navigate(['/app/academic-committee']);
        break;

      case Role.secretary:
      case Role.chiefSecretary:
        void this.router.navigate(['/app/secretary/students']);
        break;

      default:
        break;
    }
  }

  isService(): boolean {
    return this.selectedRole.role === Role.service && this.selectedRole.group === Group.global;
  }

  isAuthModifyEvaluations(programId: string): boolean {
    return this.user.roles.includes(`${Role.modifyEvaluations}-${Group.program}-${programId}`)
  }

  isStudent(): boolean {
    return this.selectedRole.role === Role.student;
  }

}