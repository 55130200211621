import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRenewalProcess } from '../../../interfaces/IRenewalProcess';
import { IDocumentRef } from '../../../interfaces/IDocument';

@Injectable()
export class RenewalHttpService {
  constructor(private http: HttpClient) { }

  request(studentId: string, data: { justification: IDocumentRef[], newEstimatedEnd: Date, leaveId: string }): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/renewal/request/${studentId}`, data);
  }

  serviceValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/renewal/serviceValidation/${taskId}`, {});
  }

  serviceDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/renewal/serviceDeny/${taskId}`, data);
  }

  findProcess(taskId: string): Observable<IRenewalProcess> {
    return this.http.get<IRenewalProcess>(`/api/renewal/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<IRenewalProcess[]> {
    return this.http.get<IRenewalProcess[]>(`/api/renewal/process/${studentId}`);
  }


}