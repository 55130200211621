import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IStudent } from '../../../../../../../interfaces/IStudent';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { UserService } from '../../../../../user/user.service';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { NgForm } from '@angular/forms';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';

@Component({
  selector: 'transfer-card',
  templateUrl: './transfer-card.component.html',
})
export class TransferCardComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() student: IStudent;

  open: boolean;

  data: { studentId: string, transfer: boolean, transferDocs?: IDocumentRef[], transferFirstEnrollment?: Date, transferUniversity?: string }

  constructor(public ss: ServiceHttpService,
    public us: UserService,
    private alerts: PopAlertsService,
    private rs: RefreshService) { }

  ngOnInit() {
    this.resetData();
  }

  resetData() {
    this.data = {
      studentId: this.student._id,
      transfer: this.student.transfer,
      transferDocs: this.student.transferDocs,
      transferFirstEnrollment: this.student.transferFirstEnrollment,
      transferUniversity: this.student.transferUniversity
    }
  }

  doOpen() {
    this.resetData();
    this.open = true;
  }

  save() {
    if (!this.f.valid) return;

    this.ss.editTransfer(this.data).subscribe(() => {
      this.open = false;
      this.alerts.saveSuccess();
      this.rs.onStudentChange.next();
    })
  }

}
