import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'qa-menu',
  templateUrl: './qa-menu.component.html',
})
export class QaMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
