<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <professor-process-data-view [process]="process"></professor-process-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="manageSend()">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>
</ng-container>