import { Component, Input, OnInit } from '@angular/core';
import { IIndustrialProcess } from '../../../../../../../interfaces/IIndustrialProcess';

@Component({
  selector: 'industrial-process-data-view',
  templateUrl: './industrial-process-data-view.component.html',
})
export class IndustrialProcessDataViewComponent implements OnInit {
  @Input() process: IIndustrialProcess;

  constructor() { }

  ngOnInit() {
  }

}
