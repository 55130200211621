<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <thesis-data-view [data]="process.data"
    [student]="process.student"></thesis-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()"
    *ngIf="canComplete">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <div class="alert alert-warning"
      *ngIf="validation.validate === true">
      <i class="far fa-exclamation-triangle mr-2"></i>
      {{ 'process.thesis.tasks.academicCommitteeValidation.info1' | translate }}
    </div>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        <span *ngIf="validation.validate === true">
          {{ 'accept' | translate }}
        </span>
        <span *ngIf="validation.validate === false">
          {{ 'send' | translate }}
        </span>
      </button>
    </div>

  </form>

  <div *ngIf="canComplete === false"
    class="alert alert-info">
    <i class="far fa-info"></i>
    {{ 'only-coordinator-can-complete' | translate }}
  </div>

</ng-container>