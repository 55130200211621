import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDirectionProcess } from '../../../../../../../interfaces/IDirectionProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { DirectionHttpService } from '../../../../../data-services/direction-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'direction-pgc-validation',
  templateUrl: './direction-pgc-validation.component.html'
})
export class DirectionPgcValidationComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IDirectionProcess;
  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  constructor(private direction: DirectionHttpService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.direction.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    if (!this.validation.validate) {
      this.direction.pgcDenyValidation(this.task._id, { denialMsg: this.validation.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.direction.pgcValidation(this.task._id).subscribe((res) => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
