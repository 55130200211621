<div class="container">

  <div class="row">
    <div class="col">
      <section-title text="programs"
        icon="book-alt">
      </section-title>
    </div>
  </div>

  <div class="card">
    <div class="list-group">

      <div class="list-group-item list-group-item-action c-pointer"
        *ngFor="let program of ps.programs"
        [routerLink]="[program._id]">
        <span class="d-inline-block mr-2 text-secondary text-center"
          style="width: 80px">
          {{ program.code }}
        </span>

        <span>
          {{ program.name }}
        </span>

      </div>

    </div>
  </div>




</div>