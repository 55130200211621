import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { ITask } from '../../../../../../../interfaces/ITask';
import {
  IThesisCommitteeMemberData,
  IThesisProcess,
  ThesisFinalEvaluation,
} from '../../../../../../../interfaces/IThesisProcess';
import { ThesisHttpService } from '../../../../../data-services/thesis-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'thesis-service-register',
  templateUrl: './thesis-service-register.component.html',
})
export class ThesisServiceRegisterComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IThesisProcess;

  members: IThesisCommitteeMemberData[] = [];

  thesisFinalEvaluation: ThesisFinalEvaluation;
  cumlaudeMention: boolean;
  thesisDefenceRecord: IDocumentRef;
  defenceDate: Date;
  internationalMention: boolean;
  industrialMention: boolean;
  presidentId: string;
  secretaryId: string;
  memberId: string;

  ThesisFinalEvaluation = ThesisFinalEvaluation;

  constructor(private thesis: ThesisHttpService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.thesis.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
      if (process.defenceDate)
        this.defenceDate = process.defenceDate;

      if (!(process.data.cotutelle && process.data.cotutelleDefenceOut)) {
        this.members.push(process.data.thesisCommittee.president);
        this.members.push(process.data.thesisCommittee.secretary);
        this.members.push(process.data.thesisCommittee.member);
        this.members.push(process.data.thesisCommittee.alt1);
        this.members.push(process.data.thesisCommittee.alt2);
        this.members.push(process.data.thesisCommittee.alt3);

        this.presidentId = process.data.thesisCommittee.president._id;
        this.secretaryId = process.data.thesisCommittee.secretary._id;
        this.memberId = process.data.thesisCommittee.member._id;
      }

      if (process.secretaryEvaluation) {
        this.thesisFinalEvaluation = process.secretaryEvaluation;
      }

      if (this.thesisFinalEvaluation === ThesisFinalEvaluation.agrade && process.cumlaudeVotes && process.cumlaudeVotes.length === 3) {
        this.cumlaudeMention = this.process.cumlaudeVotes.every(vote => vote === true);
      }

      if (!process.data.internationalMention) {
        this.internationalMention = false;
      }

      if (!process.data.industrialMention) {
        this.industrialMention = false;
      }
    })
  }


  assignPresident(userId) {
    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.presidentId = userId;
  }

  assignSecretary(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.memberId === userId) {
      this.memberId = null;
    }

    this.secretaryId = userId;
  }

  assignMember(userId) {
    if (this.presidentId === userId) {
      this.presidentId = null;
    }

    if (this.secretaryId === userId) {
      this.secretaryId = null;
    }

    this.memberId = userId;
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    }

    if (this.thesisFinalEvaluation !== ThesisFinalEvaluation.agrade) {
      this.cumlaudeMention = false;
    }

    if (!this.internationalMention) this.internationalMention = false;
    if (!this.industrialMention) this.industrialMention = false;

    this.thesis.serviceRegisterDefence(this.task._id, {
      thesisFinalEvaluation: this.thesisFinalEvaluation,
      cumlaudeMention: this.cumlaudeMention,
      thesisDefenceRecord: this.thesisDefenceRecord,
      defenceDate: this.defenceDate,
      internationalMention: this.internationalMention,
      industrialMention: this.industrialMention,
      presidentId: this.presidentId,
      secretaryId: this.secretaryId,
      memberId: this.memberId
    }).subscribe(() => {
      this.sendEnd.next();
      void this.router.navigate(['/app/task-completed'])
    })

  }

}
