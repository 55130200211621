import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { IExtraExtension, IStudent } from '../../../../../../../interfaces/IStudent';
import { UserService } from '../../../../../user/user.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogService } from '../../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';

@Component({
  selector: 'extra-extensions-list',
  templateUrl: './extra-extensions-list.component.html',
})
export class ExtraExtensionsListComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() student: IStudent;
  @Input() extensions: IExtraExtension[];

  open = false;
  extensionId: string;

  startDate: Date;
  endDate: Date;
  description: string;
  justification: IDocumentRef[];

  constructor(public us: UserService,
    public sh: ServiceHttpService,
    private refresh: RefreshService,
    private alerts: PopAlertsService,
    private confirmation: ConfirmationDialogService,
    private ts: TranslateService) { }

  ngOnInit() {
  }

  reset() {
    this.extensionId = null;
    this.startDate = null;
    this.endDate = null;
    this.description = null;
    this.justification = [];
  }

  openEdit(extensionId) {
    this.reset();
    const extension = this.extensions.find(e => e._id === extensionId);
    if (extension) {
      this.extensionId = extensionId;
      this.startDate = extension.start;
      this.endDate = extension.end;
      this.description = extension.description;
      this.justification = extension.justification;
      this.open = true;
    }
  }

  save() {
    if (!this.f.valid) {
      return;
    }
    if (this.extensionId) {
      this.sh.editExtraExtension({
        studentId: this.student._id,
        extensionId: this.extensionId,
        start: this.startDate,
        end: this.endDate,
        description: this.description,
        justification: this.justification
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    } else {
      this.sh.addExtraExtension({
        studentId: this.student._id,
        start: this.startDate,
        end: this.endDate,
        description: this.description,
        justification: this.justification
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    }
  }

  remove(extensionId: string) {
    this.confirmation.confirm({
      title: this.ts.instant('remove-extra-extension'),
      message: this.ts.instant('are-you-sure-to-remove-extra-extension'),
      btnOkText: this.ts.instant('remove')
    }, () => {
      this.sh.removeExtraExtension({
        studentId: this.student._id,
        extensionId,
      }).subscribe(() => {
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    })
  }

}
