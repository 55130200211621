<div class="card"
  *ngIf="student.cotutelleInfo && student.cotutelleInfo.cotutelle === true || (us.isService() && sh.editMode)">
  <div class="card-body">
    <legend class="m-0 d-flex justify-content-between align-items-center"
      style="border: 0">
      <span>
        {{ 'cotutelle' | translate }}
      </span>
      <button type="button"
        class="btn btn-link"
        *ngIf="us.isService() && sh.editMode"
        (click)="doOpen()">
        {{ 'edit' | translate }}
      </button>
    </legend>

    <div class="my-4">
      <div class="small text-muted mb-1">
        {{ 'thesis-cotutelle' | translate }}
      </div>
      <div>
        {{ (student.cotutelleInfo?.cotutelle ? 'Yes' : 'No') | translate }}
      </div>
    </div>

    <ng-container *ngIf="student.cotutelleInfo?.cotutelle === true">
      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-university' | translate }}
        </div>
        <div class="mb-1">
          <span *ngIf="student.cotutelleInfo.cotutelleUniversity">
            {{ student.cotutelleInfo.cotutelleUniversity }}
          </span>
        </div>
      </div>

      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-supervisors' | translate }}
        </div>
        <div class="mb-1">
          <span *ngIf="student.cotutelleInfo.cotutelleDirectors">
            <pre style="font-family: 'Open Sans', sans-serif; font-size: 100%;">
              {{ student.cotutelleInfo.cotutelleDirectors }}
            </pre>
          </span>
        </div>
      </div>

      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-agreement' | translate }}
        </div>
        <div class="mb-1"
          *ngIf="student.cotutelleInfo.cotutelleAgreementDoc">
          <div *ngFor="let doc of student.cotutelleInfo.cotutelleAgreementDoc">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="small text-muted mb-1">
          {{ 'cotutelle-agreement-date' | translate }}
        </div>
        <div class="mb-1">
          <span *ngIf="student.cotutelleInfo.cotutelleAgreementDate">
            {{ student.cotutelleInfo.cotutelleAgreementDate | moment:'D/M/YY' }}
          </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<modal [(open)]="open"
  [title]="'cotutelle' | translate">
  <form #f="ngForm"
    id="cotutelleForm"
    (ngSubmit)="save()"
    *ngIf="open">

    <div class="form-group">
      <label for="cotutelle">
        {{ 'thesis-cotutelle' | translate }}
      </label>
      <ng-select id="cotutelle"
        name="cotutelle"
        style="width: 100%"
        [required]="true"
        [searchable]="false"
        [clearable]="false"
        [(ngModel)]="cotutelleInfo.cotutelle">
        <ng-option [value]="true">
          {{ 'Yes' | translate }}
        </ng-option>
        <ng-option [value]="false">
          {{ 'No' | translate }}
        </ng-option>
      </ng-select>
    </div>

    <ng-container *ngIf="cotutelleInfo.cotutelle === true">
      <div class="form-group">
        <label for="cotutelleUniversity">
          {{ 'cotutelle-university' | translate }}
        </label>
        <input id="cotutelleUniversity"
          name="cotutelleUniversity"
          type="text"
          class="form-control"
          [placeholder]="'cotutelle-university' | translate"
          #cotutelleUniversityInput="ngModel"
          [(ngModel)]="cotutelleInfo.cotutelleUniversity">
        <validation-errors [control]="cotutelleUniversityInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="cotutelleDirectors">
          {{ 'cotutelle-supervisors' | translate }}
        </label>
        <textarea id="cotutelleDirectors"
          name="cotutelleDirectors"
          class="form-control"
          [placeholder]="'cotutelle-supervisors' | translate"
          #cotutelleDirectorsInput="ngModel"
          rows="3"
          [(ngModel)]="cotutelleInfo.cotutelleDirectors"></textarea>
        <validation-errors [control]="cotutelleDirectorsInput"
          [show]="f.submitted"></validation-errors>
      </div>

      <div class="form-group">
        <label for="cotutelleAgreementDoc">
          {{ 'cotutelle-agreement' | translate }}
        </label>
        <validation-errors [show]="f.submitted"
          [control]="cotutelleAgreementDocInput"></validation-errors>
        <files-queue name="cotutelleAgreementDoc"
          [required]="false"
          #cotutelleAgreementDocInput="ngModel"
          [(ngModel)]="cotutelleInfo.cotutelleAgreementDoc"></files-queue>
      </div>

      <div class="form-group">
        <label for="cotutelleAgreementDate">
          {{ 'cotutelle-agreement-date' | translate }}
        </label>
        <date-picker id="cotutelleAgreementDate"
          name="cotutelleAgreementDate"
          [placeholder]="'cotutelle-agreement-date' | translate"
          [required]="false"
          [isInvalid]="cotutelleAgreementDateInput.invalid && f.submitted"
          #cotutelleAgreementDateInput="ngModel"
          [time]="false"
          [(ngModel)]="cotutelleInfo.cotutelleAgreementDate"> </date-picker>
        <validation-errors [control]="cotutelleAgreementDateInput"
          [show]="f.submitted"></validation-errors>
      </div>
    </ng-container>
  </form>

  <div footer>
    <button class="btn btn-outline-dark mr-1"
      type="button"
      (click)="open = false">{{ 'cancel' | translate }}</button>
    <button class="btn btn-primary"
      type="submit"
      form="cotutelleForm">{{ 'save' | translate }}</button>
  </div>

</modal>