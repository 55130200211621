import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: 'progress-bar.component.html'
})
export class ProgressBarComponent implements OnInit {

  @Input() height: number;
  @Input() visibility: string;
  @Input() progress: number;
  @Input() color: string;

  constructor() { }

  ngOnInit() { }

}