<div class="mb-3"
  [class.small]="sm"
  *ngIf="userInfo.userId">

  <div class="d-flex justify-content-between align-items-center"
    [class.small]="sm">
    <text-info icon="user">
      {{ userInfo | fullname }}
    </text-info>
    <a [routerLink]="null"
      role="button"
      [queryParamsHandling]="'merge'"
      class="text-muted ml-2"
      (click)="resetFind()">
      <small class="nowrap">
        <i class="far fa-times"></i>
        <span class="d-none d-md-inline">
          {{ 'modify' | translate }}
        </span>
      </small>
    </a>
  </div>

  <text-info icon="envelope">
    <email-link [user]="userInfo"></email-link>
  </text-info>

  <text-info icon="id-card"
    *ngIf="!hideIdNum">
    {{ userInfo.idNum }}
  </text-info>

  <div *ngIf="usr.isService() && onlyCoSupervisor">
    <span class="badge badge-danger">
      Solo co-director
    </span>
  </div>

  <div *ngIf="usr.isService() && cpAgreement"
    class="mb-2">
    <div class="small text-muted mb-1">
      {{ 'cp-agreement' | translate }}
    </div>
    <div>
      <document-link [document]="cpAgreement"></document-link>
    </div>
  </div>

  <div *ngIf="usr.isService()">
    <a target="_blank"
      [routerLink]="['/app/service/users/' + userInfo.userId]">
      Ver ficha
    </a>
  </div>

</div>

<ng-container *ngIf="!userInfo.userId">
  <div [class.mb-1]="notFound"
    [class.form-group]="!notFound">
    <div class="input-group"
      [class.input-group-sm]="sm">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="far fa-envelope fa-fw"></i>
        </span>
      </div>
      <input type="text"
        class="form-control"
        [placeholder]="'search-by-email' | translate"
        name="email"
        [(ngModel)]="userInfo.email"
        (ngModelChange)="onChange(userInfo)"
        [required]="isRequired()"
        [pattern]="hs.EMAIL_REGEX"
        #emailInput="ngModel"
        (blur)="search()"
        (keydown.enter)="$event.preventDefault()"
        (keyup.enter)="$event.preventDefault(); search()"
        [class.is-invalid]="showErrors && emailInput.invalid">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary"
          type="button"
          (click)="search()">
          <i class="far fa-search fa-fw"></i>
        </button>
      </div>
    </div>
    <small class="form-text text-info"
      *ngIf="notFound">
      <i class="far fa-info-circle mr-1"></i>
      {{ 'email-not-found-complete-manually' | translate }}
    </small>
  </div>

  <ng-container *ngIf="notFound">
    <div class="mb-1">
      <div class="input-group"
        [class.input-group-sm]="sm">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="far fa-id-card fa-fw"></i>
          </span>
        </div>
        <input type="text"
          class="form-control"
          [placeholder]="'idNum' | translate"
          name="idNum"
          [(ngModel)]="userInfo.idNum"
          (ngModelChange)="onChange(userInfo)"
          [required]="isRequired()"
          #idNumInput="ngModel"
          [class.is-invalid]="showErrors && idNumInput.invalid">
      </div>
    </div>

    <div class="mb-1">
      <div class="input-group"
        [class.input-group-sm]="sm">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="far fa-user fa-fw"></i>
          </span>
        </div>
        <input type="text"
          class="form-control"
          [placeholder]="'firstName' | translate"
          name="firstName"
          [(ngModel)]="userInfo.firstName"
          (ngModelChange)="onChange(userInfo)"
          [required]="isRequired()"
          #firstNameInput="ngModel"
          [class.is-invalid]="showErrors && firstNameInput.invalid">
        <input type="text"
          class="form-control"
          [placeholder]="'lastName' | translate"
          name="lastName"
          [(ngModel)]="userInfo.lastName"
          (ngModelChange)="onChange(userInfo)"
          [required]="isRequired()"
          #lastNameInput="ngModel"
          [class.is-invalid]="showErrors && lastNameInput.invalid">
      </div>
    </div>

  </ng-container>

</ng-container>

<div [class.form-group]="!showCountry && !showCV && !showCdsReason"
  [class.mb-1]="showCountry || showCV || showCdsReason">
  <div class="input-group"
    [class.input-group-sm]="sm">
    <div class="input-group-prepend">
      <span class="input-group-text">
        <i class="far fa-school fa-fw"></i>
      </span>
    </div>
    <input type="text"
      class="form-control"
      [placeholder]="'university-organization' | translate"
      name="university"
      [(ngModel)]="userInfo.university"
      (ngModelChange)="onChange(userInfo)"
      [required]="isRequired()"
      #universityInput="ngModel"
      [class.is-invalid]="showErrors && universityInput.invalid">
  </div>
</div>

<div class="mb-1"
  *ngIf="showCountry">
  <ng-select id="langSelect"
    class="ng-select-sm"
    name="langSelect"
    #langSelect="ngModel"
    [(ngModel)]="userInfo.lang"
    [required]="true"
    [class.is-invalid]="showErrors && langSelect.invalid"
    [multiple]="false"
    [searchable]="false"
    [clearable]="false"
    [placeholder]="'prefered-language-ph' | translate">
    <!-- TODO change when en is available -->
    <!-- <ng-option [value]="'both'">{{ 'english-and-spanish' | translate }}</ng-option> -->
    <ng-option [value]="'es'">{{ 'spanish' | translate }}</ng-option>
    <!-- <ng-option [value]="'en'">{{ 'english' | translate }}</ng-option> -->
  </ng-select>
  <validation-errors [show]="showErrors"
    [control]="langSelect"></validation-errors>

</div>

<div class="form-group"
  *ngIf="showCV">
  <file-input name="cv"
    required
    #cvInput="ngModel"
    [sm]="sm"
    [placeholder]="'cv' | translate"
    [invalid]="showErrors && cvInput.invalid"
    [(ngModel)]="userInfo.cv"
    (ngModelChange)="onChange(userInfo)"></file-input>
</div>

<div class="form-group"
  *ngIf="showCdsReason">
  <ng-select id="supervisorRequirementSelect"
    name="supervisorRequirementSelect"
    #supervisorRequirementSelect="ngModel"
    [ngModel]="userInfo.reason"
    (ngModelChange)="onChangeReason($event)"
    [required]="true"
    [class.is-invalid]="showErrors && supervisorRequirementSelect.invalid"
    [placeholder]="'supervisor-requirement' | translate"
    [multiple]="true"
    [searchable]="false"
    [clearable]="false">
    <ng-option [value]="'sixyearPeriod'">{{ 'sixyearPeriod' | translate }}</ng-option>
    <ng-option [value]="'supervisedThesis'">{{ 'supervisedThesis' | translate }}</ng-option>
    <ng-option [value]="'authorizedByPC'">{{ 'authorizedByPC' | translate }}</ng-option>
    <ng-option [value]="'none'">{{ 'none-of-the-above' | translate }}</ng-option>
  </ng-select>
  <small class="form-text text-muted"
    *ngIf="userInfo.reason && userInfo.reason.includes('sixyearPeriod')"
    [innerHTML]="'sixyearPeriod-note' | translate">
  </small>
  <small class="form-text text-muted"
    *ngIf="userInfo.reason && userInfo.reason.includes('none')"
    [innerHTML]="'none-requirement-note' | translate">
  </small>
  <validation-errors [show]="showErrors"
    [control]="supervisorRequirementSelect"></validation-errors>

</div>