<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <thesis-data-view [data]="process.data"
    [student]="process.student"></thesis-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <div class="form-group"
      *ngIf="validation.validate === true && !(process.data.cotutelle && process.data.cotutelleDefenceOut)">
      <label>{{ 'select-two-evaluators' | translate }}</label>
      <div class="list-group">
        <div class="list-group-item list-group-item-action"
          (click)="firstEvaluator = !firstEvaluator">
          <span *ngIf="!firstEvaluator">
            <i class="far fa-square text-muted mr-2"></i>
          </span>
          <span *ngIf="firstEvaluator">
            <i class="fas fa-check-square text-primary mr-2"></i>
          </span>
          {{ process.data.externalEvaluators.ev1 | fullname }}
        </div>
        <div class="list-group-item list-group-item-action"
          (click)="secondEvaluator = !secondEvaluator">
          <span *ngIf="!secondEvaluator">
            <i class="far fa-square text-muted mr-2"></i>
          </span>
          <span *ngIf="secondEvaluator">
            <i class="fas fa-check-square text-primary mr-2"></i>
          </span>
          {{ process.data.externalEvaluators.ev2 | fullname }}
        </div>
        <div class="list-group-item list-group-item-action"
          (click)="thirdEvaluator = !thirdEvaluator">
          <span *ngIf="!thirdEvaluator">
            <i class="far fa-square text-muted mr-2"></i>
          </span>
          <span *ngIf="thirdEvaluator">
            <i class="fas fa-check-square text-primary mr-2"></i>
          </span>
          {{ process.data.externalEvaluators.ev3 | fullname }}
        </div>
      </div>
      <div class="d-block invalid-feedback"
        *ngIf="f.submitted && selectedEvaluators() !== 2">
        {{ 'must-select-two-evaluators' | translate }}
      </div>
    </div>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>

</ng-container>