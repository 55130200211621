<div class="container"
  style="min-height: calc(100vh - 272px)">

  <div class="row">

    <div class="col mb-1">

      <div class="pt-4 pb-4">
        <a href="https://www.uma.es"
          target="_blank">
          <img src="assets/UMA_logo.png"
            alt="Universidad de Málaga"
            height="77">
        </a>

        <div class="d-none d-md-inline-block float-right">
          <a href="https://www.uma.es/doctorado"
            target="_blank">
            <img height="80"
              src="assets/ED-UMA.png" />
          </a>
        </div>
      </div>

    </div>

  </div>


  <div class="row justify-content-center">

    <div class="col-lg-6 col-md-8 mt-3"
      style="padding-bottom: 3.7rem;">
      <div>
        <button class="btn btn-link"
          [routerLink]="['/']">
          <i class="far fa-arrow-left mr-1"></i>
          {{ 'back' | translate }}
        </button>
      </div>
      <div class="card">
        <div class="card-body"
          *ngIf="!verified">
          <legend>
            {{ 'document-verification' | translate }}
          </legend>
          <form #f="ngForm"
            (ngSubmit)="verify()">
            <div class="form-group">
              <label for="csvInput">
                {{ 'secure-verification-code' | translate }}
              </label>
              <input type="text"
                class="form-control"
                [(ngModel)]="csv"
                name="csvInput"
                id="csvInput"
                #csvInput="ngModel"
                [class.is-invalid]="csvInput.invalid && f.submitted"
                [placeholder]="'type-verification-code' | translate"
                [required]="true">
              <validation-errors [control]="csvInput"
                [show]="f.submitted"></validation-errors>
            </div>

            <div class="text-right">
              <button class="btn btn-primary"
                type="submit"
                [disabled]="loading">
                <span *ngIf="loading">
                  <i class="far fa-circle-notch fa-spin"></i>
                </span>
                {{ 'verify' | translate }}
              </button>
            </div>
          </form>

          <div class="alert alert-danger mt-4"
            *ngIf="notFound">
            {{ 'document-with-csv-not-found' | translate }}
          </div>
        </div>
        <div class="card-body"
          *ngIf="verified">

          <div class="alert alert-success mb-4">
            <h4 class="m-0">
              <i class="far fa-check"></i>
              {{ 'document-verified' | translate }}
            </h4>
          </div>

          <document-link [document]="doc"></document-link>

        </div>
      </div>

    </div>


  </div>

</div>

<public-footer></public-footer>