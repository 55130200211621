import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IMailTimeEmailOptions } from '../../../../../../interfaces/IMailTimeEmail';
import { EmailsHttpService } from '../../../../data-services/emails-http.service';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { NgForm } from '@angular/forms';
import { IEmail, IUser } from '../../../../../../interfaces/IUser';
import { IStudent } from '../../../../../../interfaces/IStudent';
import { SendEmailModalService } from './send-email-modal.service';
import { Subscription } from 'rxjs';
import { IThesisCommitteeMemberData } from '../../../../../../interfaces/IThesisProcess';

@Component({
  selector: 'send-email-modal',
  templateUrl: 'send-email-modal.component.html'
})
export class SendEmailModalComponent implements OnInit, OnDestroy {
  @ViewChild('f') f: NgForm;
  @Input() open = false;

  to: string;
  toName: string;
  subject: string;
  html: string;

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link']                         // link and image, video
    ]
  };

  sub1: Subscription;
  sub2: Subscription;

  constructor(private es: EmailsHttpService,
    private alerts: PopAlertsService,
    private sems: SendEmailModalService) { }

  ngOnInit() {
    this.sub1 = this.sems.openSendEmailModal.subscribe((user) => {
      this.doOpen(user);
    });
    this.sub2 = this.sems.forwardSendEmailModal.subscribe((mail) => {
      this.forward(mail);
    });
  }

  ngOnDestroy() {
    if (this.sub1) this.sub1.unsubscribe();
    if (this.sub2) this.sub2.unsubscribe();
  }

  doOpen(user: IUser | IStudent | IThesisCommitteeMemberData) {
    if (user.email && (<IEmail>user.email).address) {
      this.to = (<IEmail>user.email).address;
    } else if (user.email) {
      this.to = <string>user.email;
    }
    this.toName = user.lastName + ', ' + user.firstName;
    this.subject = '';
    this.html = '';
    this.open = true;
  }

  forward(mail: IMailTimeEmailOptions) {
    if (mail.to.indexOf('<') > -1) {
      const to = mail.to.split('<');
      this.toName = to[0];
      this.toName = this.toName.replace('"', '');
      this.toName = this.toName.replace('"', '');
      this.toName = this.toName.trim();
      this.to = to[1].replace('>', '');
    } else {
      this.to = mail.to;
      this.toName = '';
    }
    this.subject = mail.subject;
    this.html = mail.html;

    this.open = true;
  }

  send() {
    if (!this.f.valid) {
      return;
    }
    this.es.send(this.to, this.toName, this.subject, this.html).subscribe(() => {
      this.alerts.pop({
        styleClass: 'success',
        msg: 'Correo enviado'
      })
      this.open = false;
      this.sems.onSent.next('ok');
    });
  }
}