<div class="mb-3"
  [class.small]="sm">
  <div class="d-flex justify-content-between align-items-center">
    <text-info icon="user">
      {{ userInfo | fullname }}
    </text-info>
  </div>

  <text-info icon="envelope">
    <email-link [user]="userInfo"></email-link>
  </text-info>

  <text-info icon="id-card"
    *ngIf="showIdNum">
    {{ userInfo.idNum }}
  </text-info>

  <text-info icon="school"
    *ngIf="userInfo.university">
    {{ userInfo.university }}
  </text-info>

  <text-info icon="globe-europe"
    *ngIf="showCountry && userInfo.nationality">
    {{ userInfo.nationality }}
  </text-info>
  <text-info icon="flag"
    *ngIf="showCountry && userInfo.lang">
    <ng-container [ngSwitch]="userInfo.lang">
      <ng-container *ngSwitchCase="'both'">
        {{ 'english-and-spanish' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="'es'">
        {{ 'spanish' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="'en'">
        {{ 'english' | translate }}
      </ng-container>
    </ng-container>
  </text-info>

  <div class="mb-2"
    *ngIf="showCV && userInfo.cv">
    <document-link [document]="userInfo.cv"></document-link>
  </div>

  <div *ngIf="showReason && userInfo.reason && userInfo.reason.length > 0">
    <div class="mb-1 small text-muted">
      {{ 'supervisor-requirement' | translate }}
    </div>
    <div>
      <span class="badge badge-light mr-1"
        *ngFor="let reason of userInfo.reason">
        {{ reason | translate }}
      </span>
    </div>

  </div>

</div>