import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { IActivity } from '../../../../../../interfaces/IActivity';

@Component({
  selector: 'add-documents',
  templateUrl: 'add-documents.component.html'
})
export class AddDocumentsComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() activity: IActivity;
  @Input() active = false;

  @Output() onSave = new EventEmitter<{ activityId: string, documents: { documentRef: string, fileName: string }[] }>();


  attachedFiles: {
    documentRef: string,
    fileName: string
  }[] = [];

  constructor(public translate: TranslateService, private zone: NgZone) { }

  ngOnInit() {
  }

  reset() {
    this.zone.run(() => {
      this.attachedFiles = [];
    });
  }

  save() {
    if (!this.f.valid) return;

    this.onSave.emit({
      activityId: this.activity._id,
      documents: this.attachedFiles
    });
  }

}