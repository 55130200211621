import { Component, Input, OnInit } from '@angular/core';

import { ITask } from '../../../../interfaces/ITask';
import { IThesisProcess } from '../../../../interfaces/IThesisProcess';

@Component({
  selector: 'thesis-pgc-info',
  templateUrl: './thesis-pgc-info.component.html',
})
export class ThesisPgcInfoComponent implements OnInit {

  @Input() task: ITask;
  process: IThesisProcess;

  constructor() { }

  ngOnInit() {
    this.process = this.task.thesisProcess;
  }

}
