import { IDocumentRef } from './IDocument';
import { IStudent } from './IStudent';

export interface IActivity {
  _id?: string,
  studentId: string,
  order: number,
  title: string,
  description?: string,
  activityType: ActivityType,
  startDate?: Date,
  endDate?: Date,
  createdAt: Date,
  validated: boolean,
  evaluated: boolean,
  validationState: ActivityValidationStateType,
  validationMsg?: string,
  attached?: IDocumentRef[],
  deleted: boolean,

  // Populate
  student?: IStudent,
}

export enum ActivityType {
  Educational = "Educational",
  InternationalConference = "InternationalConference",
  NationalConference = "NationalConference",
  SeminarConference = "SeminarConference",
  ThesisDefence = "ThesisDefence",
  StayNational = "StayNational",
  StayInternational = "StayInternational",
  HighQPub = "HighQPub",
  UncreditedPub = "UncreditedPub",
  Others = "Others",
  AnnualEvaluation = "AnnualEvaluation",
  AcceptanceForProcessing = "AcceptanceForProcessing",
}

export enum ActivityValidationStateType {
  Draft = "Draft",
  PendingTutorValitation = "PendingTutorValidation",
  PendingStudentReview = "PendingStudentReview",
  Validated = "Validated"
}
