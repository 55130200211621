<form #f="ngForm"
  (keydown.enter)="$event.preventDefault()"
  autocomplete="off"
  id="addDocumentsForm"
  (ngSubmit)="save()"
  *ngIf="active && activity">

  <div class="form-group">
    <files-queue [(ngModel)]="attachedFiles"
      name="attachedFiles"
      #attachedFilesInput="ngModel"
      [required]="false"></files-queue>
    <validation-errors [control]="attachedFilesInput"
      [show]="f.submitted"></validation-errors>
  </div>

</form>