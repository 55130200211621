<ng-container *ngIf="process">

  <task-header [task]="task"></task-header>

  <br>

  <denial-msgs-view [denialMsgs]="task.directionProcess.denialMsgs"></denial-msgs-view>

  <direction-request-form [data]="data"
    [showTitle]="false"
    [directionRequired]="true"
    [enableSupervisor2]="enableSupervisor2"
    [enableSupervisor3]="enableSupervisor3"
    [canComplete]="canComplete"
    (onSend)="send($event)"
    [sending]="sending"></direction-request-form>


</ng-container>