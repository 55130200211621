<span [class.mr-1]="mr"
  [class.ml-1]="ml"
  [ngSwitch]="state">
  <span *ngSwitchCase="StudentStateType.initial"
    class="badge badge-secondary">
    <i class="fas fa-passport"></i>
    <span *ngIf="!cdsRequested">
      {{ stateStr | translate }}
    </span>
    <span *ngIf="cdsRequested">
      {{ 'cds-in-process' | translate }}
    </span>
  </span>
  <span *ngSwitchCase="StudentStateType.active"
    class="badge badge-light">
    <i class="far fa-check-circle"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.temporalLeave"
    class="badge badge-warning">
    <i class="fas fa-pause"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.medicalLeave"
    class="badge badge-warning">
    <i class="fas fa-notes-medical"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.negativeEvaluations"
    class="badge badge-danger">
    <i class="fas fa-file-times"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.missingEnrollments"
    class="badge badge-danger">
    <i class="fas fa-list"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.deadline"
    class="badge badge-danger">
    <i class="fas fa-hourglass"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.permanentLeave"
    class="badge badge-dark">
    <i class="fas fa-times-circle"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.finalLeave"
    class="badge badge-dark">
    <i class="fas fa-times-circle"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.processingRequested"
    class="badge badge-info">
    <i class="fas fa-circle"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.processingPendingRectification"
    class="badge badge-info">
    <i class="fas fa-times-circle"></i>
    {{ stateStr | translate }}
  </span>
  <span
    *ngIf="[StudentStateType.processing, StudentStateType.processingAccepted, StudentStateType.defenceRequested, StudentStateType.defenceAccepted].includes(state)"
    class="badge badge-info">
    <i class="fas fa-dot-circle"></i>
    {{ stateStr | translate }}
  </span>
  <span *ngSwitchCase="StudentStateType.defended"
    class="badge badge-success">
    <i class="fas fa-check-circle"></i>
    {{ stateStr | translate }}
  </span>
</span>