import { Component, Input, OnInit } from '@angular/core';

import { CdsReason } from '../../../../interfaces/ICDSProcess';
import { IDocumentRef } from '../../../../interfaces/IDocument';

@Component({
  selector: 'user-info-view',
  templateUrl: './user-info-view.component.html',
})
export class UserInfoViewComponent implements OnInit {

  @Input() userInfo: {
    userId?: string,
    email: string,
    idNum: string,
    firstName: string,
    lastName: string,
    university: string,
    nationality?: string,
    reason?: CdsReason[],
    cv?: IDocumentRef,
    lang?: string
  } = {
      email: null,
      idNum: null,
      firstName: null,
      lastName: null,
      university: null,
      reason: []
    };

  @Input() showIdNum = false;
  @Input() showReason = false;
  @Input() showCountry = false;
  @Input() showCV = false;

  @Input() sm = false;

  constructor() { }

  ngOnInit() {
  }

}
