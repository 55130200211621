import { Component, OnInit } from '@angular/core';
import { FacultyHttpService } from '../../../../data-services/faculty-http.service';
import { IFaculty } from '../../../../../../interfaces/IFaculty';
import { LoadingIndicatorService } from '../../../../UI/loading-indicator/loading-indicator.service';

@Component({
  selector: 'service-faculties',
  templateUrl: 'service-faculties.component.html'
})

export class ServiceFacultiesComponent implements OnInit {

  faculties: IFaculty[];

  constructor(private fs: FacultyHttpService, private loading: LoadingIndicatorService) { }

  ngOnInit() {
    this.loading.start();
    this.fs.fetchFaculties().subscribe((res) => {
      this.faculties = res;
      this.loading.stop();
    })
  }
}