<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <direction-process-data-view [process]="process"></direction-process-data-view>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>

</ng-container>