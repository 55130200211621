import { Component, OnInit } from '@angular/core';

import { UserService } from '../../../../user/user.service';

@Component({
  selector: 'coordinator-evaluation',
  templateUrl: './coordinator-evaluation.component.html',
})
export class CoordinatorEvaluationComponent implements OnInit {

  programId: string;

  constructor(private us: UserService) { }

  ngOnInit() {
    this.programId = this.us.selectedRole.groupId;
  }

}
