import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { IUser } from '../../../../../../interfaces/IUser';
import { ImpersonatingService } from '../../../../data-services/impersonating.service';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';

@Component({
  selector: 'service-users-list',
  templateUrl: './service-users-list.component.html',
})
export class ServiceUsersListComponent implements OnInit {
  users: IUser[] = [];

  params = {
    searchText: ''
  }

  constructor(private sh: ServiceHttpService, public hs: HelperService) { }

  fetchFn() {
    return (params) => {
      params = {
        ...params,
        ...this.params
      }
      return this.sh.fetchUsers(params);
    }
  }

  ngOnInit() {
  }

}
