<div *ngIf="student"
  class="container-fluid container-md">
  <div class="mb-2">
    <a class="text-muted"
      [routerLink]="['../']">
      <i class="far fa-angle-left"></i>
      {{ 'go-back' | translate }}
    </a>
  </div>
  <h2 class="mb-4 d-flex justify-content-between align-items-center">
    <div>
      <span class="badge badge-dark mr-1"
        *ngIf="student.rd === RDType.rd1393">
        RD1393
      </span>

      {{ student.user | fullname }}

      <button class="btn btn-sm btn-outline-primary ml-2"
        *ngIf="us.isService()"
        (click)="impersonate()">
        {{ 'impersonate-user' | translate }}
      </button>
    </div>

    <div *ngIf="us.isService()">
      <ng-toggle [(ngModel)]="ss.editMode"
        [labels]="{ checked: 'edit-mode' | translate, unchecked: 'edit-mode' | translate}"
        [width]="108"></ng-toggle>
    </div>
  </h2>

  <ng-template #navItems>
    <li class="nav-item">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./home']">
        <i class="far fa-home"></i>
        {{ 'situation' | translate }}
      </a>
    </li>
    <li class="nav-item"
      *ngIf="student.rd !== RDType.rd1393">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./cds']">
        <i class="far fa-passport"></i>
        {{ 'cds' | translate }}
        <span
          *ngIf="sfs.activeProcesses.cds > 0 || sfs.activeProcesses.dedication > 0 || sfs.activeProcesses.direction > 0 || sfs.activeProcesses.industrial > 0"
          class="text-info"
          style="position: relative; top: -2px">
          <i class="fas fa-circle fa-xs"></i>
        </span>
      </a>
    </li>
    <li class="nav-item"
      *ngIf="student.rd !== RDType.rd1393">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./activities']">
        <i class="far fa-list"></i>
        {{ 'activities' | translate }}
      </a>
    </li>
    <li class="nav-item"
      *ngIf="student.rd !== RDType.rd1393">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./processes']">
        <i class="far fa-project-diagram"></i>
        {{ 'processes' | translate }}
        <span *ngIf="sfs.activeProcesses.extension > 0 || sfs.activeProcesses.leave > 0"
          class="text-info"
          style="position: relative; top: -2px">
          <i class="fas fa-circle fa-xs"></i>
        </span>
      </a>
    </li>
    <li class="nav-item"
      *ngIf="student.rd !== RDType.rd1393">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./evaluation']">
        <i class="far fa-file-search"></i>
        {{ 'evaluation' | translate }}
        <span *ngIf="sfs.activeProcesses.evaluation > 0"
          class="text-info"
          style="position: relative; top: -2px">
          <i class="fas fa-circle fa-xs"></i>
        </span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./thesis']">
        <i class="far fa-diploma"></i>
        {{ 'thesis' | translate }}
        <span *ngIf="sfs.activeProcesses.thesis > 0"
          class="text-info"
          style="position: relative; top: -2px">
          <i class="fas fa-circle fa-xs"></i>
        </span>
      </a>
    </li>
    <li class="nav-item"
      *ngIf="us.isService()">
      <a class="nav-link text-nowrap"
        [routerLinkActive]="'active'"
        [routerLink]="['./emails']">
        <i class="far fa-envelope"></i>
        {{ 'emails' | translate }}
      </a>
    </li>
  </ng-template>

  <ul class="nav nav-tabs nav-justified mb-4 d-none d-lg-flex">
    <ng-container *ngTemplateOutlet="navItems"></ng-container>
  </ul>

  <ul class="nav nav-pills nav-justified mb-4 d-flex d-lg-none">
    <ng-container *ngTemplateOutlet="navItems"></ng-container>
  </ul>

  <router-outlet></router-outlet>
</div>

<ng-container *ngIf="!student && notFound">

  <div class="container-lg container-fluid px-0 px-md-2">
    <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

      <div class="mb-2">
        <a class="text-muted"
          [routerLink]="['../']">
          <i class="far fa-angle-left"></i>
          {{ 'go-back' | translate }}
        </a>
      </div>

      <div class="card">
        <div class="card-body">
          <i class="fas fa-info-circle text-info mr-1"></i>
          {{ 'not-allowed-or-student-not-found' | translate }}
        </div>
      </div>
    </div>
  </div>

</ng-container>