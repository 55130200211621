<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="program-students"
        icon="users-class">
      </section-title>

    </div>
  </div>

  <div class="row">
    <div class="col text-right">
      <button class="btn btn-link mb-2"
        type="button"
        (click)="downloadReport()">
        <i class="far fa-file-excel text-success mr-1"></i>
        Descargar datos de todos los estudiantes
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <students-search [dataFn]="dataFn()"
        [reportURL]="'program/' + us.selectedRole.groupId + '/students/report'"
        [program]="false"></students-search>
    </div>
  </div>

</div>