<menu-item [link]="['/app/coordinator/students']"
  (onClick)="ss.reset()"
  icon="users-class"
  name="students"></menu-item>

<menu-item [link]="['/app/coordinator/professors']"
  icon="users"
  name="professors"></menu-item>

<menu-item [link]="['/app/coordinator/evaluation']"
  icon="file-search"
  name="evaluation"></menu-item>

<menu-item [link]="['/app/coordinator/committees']"
  icon="users"
  name="committees"></menu-item>

<menu-item [link]="['/app/coordinator/periods']"
  icon="calendar"
  name="evaluation-periods"></menu-item>