<div class="container">

  <div class="row">
    <div class="col">
      <section-title text="reports"
        icon="file">
      </section-title>
    </div>
  </div>

  <!-- CSV or JSON selector -->
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="reportFormat">Formato</label>
        <ng-select id="reportFormat"
          style="width: 200px"
          [(ngModel)]="format"
          [searchable]="false"
          [clearable]="false">
          <ng-option [value]="'excel'">EXCEL</ng-option>
          <ng-option [value]="'csv'">CSV</ng-option>
          <ng-option [value]="'json'">JSON</ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <button class="btn btn-link d-block"
    type="button"
    (click)="activitiesReport()">
    <span *ngIf="format === 'excel'">
      <i class="far fa-file-excel text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'csv'">
      <i class="far fa-file-csv text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'json'">
      <i class="far fa-file-code text-warning mr-1"></i>
    </span>
    Actividades
  </button>

  <button class="btn btn-link  d-block mt-2"
    type="button"
    (click)="studentsReport()">
    <span *ngIf="format === 'excel'">
      <i class="far fa-file-excel text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'csv'">
      <i class="far fa-file-csv text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'json'">
      <i class="far fa-file-code text-warning mr-1"></i>
    </span>
    Matriculaciones y evaluaciones de estudiantes
  </button>

  <button class="btn btn-link d-block mt-2"
    type="button"
    (click)="studentsSingleLineReport()">
    <span *ngIf="format === 'excel'">
      <i class="far fa-file-excel text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'csv'">
      <i class="far fa-file-csv text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'json'">
      <i class="far fa-file-code text-warning mr-1"></i>
    </span>
    Estudiantes
  </button>

  <button class="btn btn-link d-block mt-2"
    type="button"
    (click)="studentsLinksReport()">
    <span *ngIf="format === 'excel'">
      <i class="far fa-file-excel text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'csv'">
      <i class="far fa-file-csv text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'json'">
      <i class="far fa-file-code text-warning mr-1"></i>
    </span>
    Enlaces a fichas de estudiantes
  </button>

  <button class="btn btn-link d-block mt-2"
    type="button"
    (click)="defendedThesisReport()">
    <span *ngIf="format === 'excel'">
      <i class="far fa-file-excel text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'csv'">
      <i class="far fa-file-csv text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'json'">
      <i class="far fa-file-code text-warning mr-1"></i>
    </span>
    Tesis defendidas
  </button>

  <button class="btn btn-link d-block mt-2"
    type="button"
    (click)="studentsThesisProcessesReport()">
    <span *ngIf="format === 'excel'">
      <i class="far fa-file-excel text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'csv'">
      <i class="far fa-file-csv text-success mr-1"></i>
    </span>
    <span *ngIf="format === 'json'">
      <i class="far fa-file-code text-warning mr-1"></i>
    </span>
    Estudiantes con proceso de tesis iniciado
  </button>



</div>