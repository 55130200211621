import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { ITask } from '../../../../../../../interfaces/ITask';
import { IThesisProcess } from '../../../../../../../interfaces/IThesisProcess';
import { ThesisHttpService } from '../../../../../data-services/thesis-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'thesis-pgcs-validation',
  templateUrl: './thesis-pgcs-validation.component.html'
})
export class ThesisPgcsValidation extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IThesisProcess;
  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  firstEvaluator = false;
  secondEvaluator = false;
  thirdEvaluator = false;

  constructor(private thesis: ThesisHttpService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.thesis.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  selectedEvaluators(): number {
    let res = 0;
    if (this.firstEvaluator) res++;
    if (this.secondEvaluator) res++;
    if (this.thirdEvaluator) res++;
    return res;
  }

  manageSend() {
    if (!this.f.valid) {
      this.sendEnd.next();
      return;
    };
    if (this.validation.validate === true
      && this.selectedEvaluators() !== 2
      && !(this.process.data.cotutelle && this.process.data.cotutelleDefenceOut)) {
      this.sendEnd.next();
      return;
    }

    if (!this.validation.validate) {
      this.thesis.pgcsDeny(this.task._id, { denialMsg: this.validation.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.thesis.pgcsValidate(this.task._id, {
        firstEvaluator: this.firstEvaluator,
        secondEvaluator: this.secondEvaluator,
        thirdEvaluator: this.thirdEvaluator
      }).subscribe((res) => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
