<div class="container">

  <div class="row">
    <div class="col">

      <section-title text="thesis"
        icon="diploma">
      </section-title>

    </div>
  </div>

  <ng-container *ngIf="sr.student">
    <student-thesis-view [student]="sr.student"
      [requestEnabled]="sr.procStates?.thesis"></student-thesis-view>
  </ng-container>

</div>