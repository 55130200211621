import { NgTemplateOutlet } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ConfirmationDialogService {

  title;
  message;
  btnOkText;
  btnOkClass;
  btnCancelText;
  dialogSize;
  template;

  open = false;

  okAction;
  cancelAction;
  dismissAction;

  constructor(private translate: TranslateService) { }

  public confirm(
    ui: {
      title: string,
      message: string,
      btnOkText?: string,
      btnOkClass?: string,
      btnCancelText?: string,
      dialogSize?: 'sm' | 'lg',
      template?: NgTemplateOutlet
    },
    okAction,
    cancelAction?,
    dismissAction?) {

    this.title = ui.title;
    this.message = ui.message;

    if (!ui.btnOkText) this.btnOkText = this.translate.instant('accept');
    else this.btnOkText = ui.btnOkText;

    if (!ui.btnOkClass) this.btnOkClass = 'danger';
    else this.btnOkClass = ui.btnOkClass;

    if (!ui.btnCancelText) this.btnCancelText = this.translate.instant('cancel');
    else this.btnCancelText = ui.btnCancelText;

    if (!ui.dialogSize) this.dialogSize = 'sm';
    else this.dialogSize = ui.dialogSize;

    this.okAction = okAction;

    if (!cancelAction) {
      this.cancelAction = () => { };
    } else {
      this.cancelAction = cancelAction;
    }

    if (!dismissAction) {
      this.dismissAction = () => { };
    } else {
      this.dismissAction = dismissAction;
    }

    if (ui.template) {
      this.template = ui.template
    } else {
      this.template = null;
    }

    this.open = true;

  }

}