import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ICotutelleInfo, IStudent } from '../../../../../../../interfaces/IStudent';
import { NgForm } from '@angular/forms';
import { CotutelleHttpService } from '../../../../../data-services/cotutelle-http.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { UserService } from '../../../../../user/user.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import * as _ from 'lodash';

@Component({
  selector: 'cotutelle-card',
  templateUrl: './cotutelle-card.component.html',
})
export class CotutelleCardComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() student: IStudent;

  cotutelleInfo: ICotutelleInfo = {
    cotutelle: false,
    cotutelleUniversity: '',
    cotutelleDirectors: '',
    cotutelleAgreementDate: null,
    cotutelleAgreementDoc: [],
  }

  open = false;

  constructor(private cs: CotutelleHttpService,
    private refresh: RefreshService,
    private alerts: PopAlertsService,
    public us: UserService,
    public sh: ServiceHttpService) { }

  ngOnInit() {
  }

  doOpen() {
    this.cotutelleInfo = _.cloneDeep(this.student.cotutelleInfo);
    if (!this.cotutelleInfo) {
      this.cotutelleInfo = {
        cotutelle: false,
        cotutelleUniversity: '',
        cotutelleDirectors: '',
        cotutelleAgreementDate: null,
        cotutelleAgreementDoc: [],
      }
    }
    this.open = true;
  }

  save() {
    if (this.f.invalid) return;

    this.cs.saveCotutelleInfo(this.student._id, this.cotutelleInfo).subscribe(() => {
      this.refresh.onStudentChange.next();
      this.alerts.saveSuccess();
      this.open = false;
    })
  }

}
