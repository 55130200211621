import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'user-file-link',
  templateUrl: './user-file-link.component.html',
})
export class UserFileLinkComponent implements OnInit {

  @Input() user: any;

  constructor(public us: UserService) { }

  ngOnInit() {
  }

}
