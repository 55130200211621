import { Component, Input, OnInit } from '@angular/core';

import { ICDSProcess } from '../../../../../../../interfaces/ICDSProcess';

@Component({
  selector: 'cds-process-data-view',
  templateUrl: './cds-process-data-view.component.html'
})
export class CDSProcessDataViewComponent implements OnInit {
  @Input() process: ICDSProcess;

  constructor() { }

  ngOnInit() {
  }

}
