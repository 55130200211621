import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[dropFiles]'
})
export class DropFileDirective {
  
  @HostBinding('style.background') private background = '#fff';
  
  @Output()
  private filesChangeEmitter: EventEmitter<FileList> = new EventEmitter();

  @HostListener('dragover', ['$event']) public onDragOver(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#eee';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff'
  }

  @HostListener('drop', ['$event']) public onDrop(evt){
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
    let files = evt.dataTransfer.files;
    if(files.length > 0){
      this.filesChangeEmitter.emit(files);
    }
  }

}