import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExternalTaskComponent } from './external-task/external-task.component';
import { LoginComponent } from './login/login.component';
import { OldTaskComponentComponent } from './old-task-component/old-task-component.component';
import { LoggedInGuard } from './user/logged-in.guard';
import { DocumentVerificationComponent } from './document-verification/document-verification.component';
import { PrintEmailViewComponent } from './root/routes/service/sent-emails/print-email-view/print-email-view.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'oldtask',
    component: OldTaskComponentComponent
  },
  {
    path: 'task/:taskId/:token',
    component: ExternalTaskComponent
  },
  {
    path: 'verificar',
    component: DocumentVerificationComponent
  },
  {
    path: 'app',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./root/root.module').then(m => m.RootModule)
  },
  {
    path: 'print-email/:mailId',
    canActivate: [LoggedInGuard],
    component: PrintEmailViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
