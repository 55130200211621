<infinite-list #list
  [data]="fetchFn()"
  [array]="students"
  [elementsHeight]="35"
  (arrayChange)="processStudents($event)">

  <students-list [students]="students"
    [reportURL]="reportURL"
    [totalCount]="list.totalCount"
    [programs]="ps.programs"
    [filters]="sss.filters"
    [idNum]="idNum"
    [program]="program"
    [state]="state"
    [email]="email"
    [rd]="rd"
    [deadline]="deadline"
    [showFilters]="showFilters"
    (filtersChange)="sss.filters = $event; list.search()"
    (onClick)="gotToStudent($event._id)">
  </students-list>

  <span *ngIf="!sss.filters && (list.totalCount === 0 || (list.allLoaded && students.length <= 0))">
    <i>{{ 'no-results' | translate }}</i>
  </span>

</infinite-list>