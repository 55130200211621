import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { IDirectionRequestData } from '../../../../../../interfaces/IDirectionProcess';
import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { DirectionHttpService } from '../../../../data-services/direction-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'direction-request',
  templateUrl: 'direction-request.component.html'
})
export class DirectionRequestComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {

  requested = false;
  doc: IDocumentRef;

  data: IDirectionRequestData = {
    validate: null,
    tutor: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor1: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor2: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor3: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    }
  };

  enableSupervisor2 = false;
  enableSupervisor3 = false;

  constructor(public sr: StudentRootService,
    private direction: DirectionHttpService,
    private ds: DocumentService,
    private ts: TranslateService,
    private cds: ConfirmationDialogService,
    private refresh: RefreshService) {
    super();
  }

  ngOnInit() {

    if (this.sr.student.tutor) {
      this.data.tutor = {
        userId: this.sr.student.tutor._id,
        email: this.sr.student.tutor.email.address,
        idNum: this.sr.student.tutor.idNum,
        firstName: this.sr.student.tutor.firstName,
        lastName: this.sr.student.tutor.lastName,
        university: '',
      }
    }

    if (this.sr.student.supervisor1) {
      this.data.supervisor1 = {
        userId: this.sr.student.supervisor1._id,
        email: this.sr.student.supervisor1.email.address,
        idNum: this.sr.student.supervisor1.idNum,
        firstName: this.sr.student.supervisor1.firstName,
        lastName: this.sr.student.supervisor1.lastName,
        university: '',
      }
    }

    if (this.sr.student.supervisor2) {
      this.data.supervisor2 = {
        userId: this.sr.student.supervisor2._id,
        email: this.sr.student.supervisor2.email.address,
        idNum: this.sr.student.supervisor2.idNum,
        firstName: this.sr.student.supervisor2.firstName,
        lastName: this.sr.student.supervisor2.lastName,
        university: '',
      }
      this.enableSupervisor2 = true;
    }

    if (this.sr.student.supervisor3) {
      this.data.supervisor3 = {
        userId: this.sr.student.supervisor3._id,
        email: this.sr.student.supervisor3.email.address,
        idNum: this.sr.student.supervisor3.idNum,
        firstName: this.sr.student.supervisor3.firstName,
        lastName: this.sr.student.supervisor3.lastName,
        university: '',
      }
      this.enableSupervisor3 = true;
    }
  }

  manageSend(data: IDirectionRequestData) {
    this.direction.request(this.sr.student._id, data).subscribe((doc: IDocumentRef) => {
      this.sendEnd.next();
      this.refresh.onStudentChange.next();
      this.requested = true;
      this.doc = doc;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
    });
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }
}