import { Component, Input, OnInit } from '@angular/core';
import { ITask } from '../../../../../../../interfaces/ITask';
import { IDirectionProcess, IDirectionRequestData } from '../../../../../../../interfaces/IDirectionProcess';
import { DirectionHttpService } from '../../../../../data-services/direction-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { UserService } from '../../../../../user/user.service';
import { Router } from '@angular/router';
import { DocumentService } from '../../../../../shared/document-service/document.service';

@Component({
  selector: 'direction-student-request-review',
  templateUrl: 'direction-student-request-review.component.html'
})

export class DirectionStudentRequestReviewComponent extends AbstractTaskComponent implements OnInit {
  @Input() task: ITask;

  process: IDirectionProcess;

  denialMsg = '';

  data: IDirectionRequestData = {
    tutor: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor1: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor2: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    },
    supervisor3: {
      email: '',
      idNum: '',
      firstName: '',
      lastName: '',
      university: '',
    }
  };

  enableSupervisor2 = false;
  enableSupervisor3 = false;

  canComplete = true;

  constructor(private direction: DirectionHttpService,
    private us: UserService,
    private router: Router,
    private ds: DocumentService) {
    super();
  }

  ngOnInit() {
    this.canComplete = this.us.canComplete(this.task);
    this.direction.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      if (process.tutor) {
        this.data.tutor = process.tutor;
      }
      if (process.supervisor1) {
        this.data.supervisor1 = process.supervisor1;
      }
      if (process.supervisor2) {
        this.data.supervisor2 = process.supervisor2;
        this.enableSupervisor2 = true;
      }
      if (process.supervisor3) {
        this.data.supervisor3 = process.supervisor3;
        this.enableSupervisor3 = true;
      }
      if (process.thirdSupervisorDoc) {
        this.data.thirdSupervisorDoc = process.thirdSupervisorDoc;
      }
    })
  }

  manageSend(data: IDirectionRequestData) {
    this.direction.studentRequestReview(this.task._id, data).subscribe((docRef) => {
      this.sendEnd.next();
      setTimeout(() => {
        if (docRef) {
          this.ds.download(docRef.documentRef);
        }
      }, 1000);

      void this.router.navigate(['/app/task-completed'], { queryParams: { docRef: docRef.documentRef } });
    });
  }
}