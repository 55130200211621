import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IStudent, RDType } from '../../../../../../../interfaces/IStudent';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { IEvaluationProcess } from '../../../../../../../interfaces/IEvaluationProcess';
import * as _ from 'lodash';
import { IActivity } from '../../../../../../../interfaces/IActivity';
import { StudentHttpService } from '../../../../../data-services/student-http.service';
import { IEvaluationCommittee } from '../../../../../../../interfaces/IEvaluationCommittee';
import { ProgramHttpService } from '../../../../../data-services/program-http.service';
import { HelperService } from '../../../../../shared/helper-service/helper.service';

@Component({
  selector: 'evaluation-process-edit-modal',
  templateUrl: 'evaluation-process-edit-modal.component.html'
})
export class EvaluationProcessEditModalComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() open = false;
  @Input() student: IStudent;
  @Input() process: IEvaluationProcess;

  @Input() data: {
    researchPlan: IDocumentRef,
    activitiesIds: string[],
    evaluationCommitteeId?: string,
    reports: IDocumentRef[],
  }

  sending = false;

  activities: IActivity[] = [];

  evaluationCommittees: IEvaluationCommittee[] = [];
  selectedEC: IEvaluationCommittee;
  originalEC: IEvaluationCommittee;

  RDType = RDType;

  constructor(private ss: ServiceHttpService,
    private st: StudentHttpService,
    private ps: ProgramHttpService,
    private alerts: PopAlertsService,
    private rs: RefreshService,
    public hs: HelperService) { }

  ngOnInit() {
    this.st.fetchAssessableActivities(this.student._id).subscribe((activities) => {
      this.activities = activities;
    })
    this.ps.evaluationCommittees(this.student.programId).subscribe((res) => {
      this.evaluationCommittees = res.map(r => {
        return {
          ...r,
          code: this.hs.ecCode(r)
        }
      });

      if (this.student.evaluationCommitteeId) {
        this.selectedEC = this.evaluationCommittees.find(ec => ec._id === this.student.evaluationCommitteeId);
        this.originalEC = this.evaluationCommittees.find(ec => ec._id === this.student.evaluationCommitteeId);
      }
    })
  }

  doOpen(process: IEvaluationProcess) {
    this.process = process;
    this.data = _.pick(process, ['researchPlan', 'activitiesIds', 'evaluationCommitteeId', 'reports']);
    this.data = _.cloneDeep(this.data);

    if (this.data.evaluationCommitteeId) {
      this.selectedEC = this.evaluationCommittees.find(ec => ec._id === this.data.evaluationCommitteeId);
    }
    this.open = true;
    this.sending = false;
  }

  save() {
    if (this.f && this.f.valid) {
      if (this.selectedEC) {
        this.data.evaluationCommitteeId = this.selectedEC._id;
      }
      this.sending = true;
      this.ss.editEvaluationProcess(this.process._id, this.data).subscribe(() => {
        this.rs.onEvaluationProcessChange.next(this.process._id);
        this.open = false;
        this.alerts.saveSuccess();
        this.sending = false;
      }, () => {
        this.sending = false;
      });
    }
  }

  toggle(activityId) {
    if (this.data.activitiesIds.indexOf(activityId) > -1) {
      this.data.activitiesIds = this.data.activitiesIds.filter(aId => aId !== activityId);
    } else {
      this.data.activitiesIds.push(activityId);
    }
  }

  toggleSelectAll() {
    if (this.data.activitiesIds.length === this.activities.length) {
      this.data.activitiesIds = [];
    } else {
      this.data.activitiesIds = this.activities.map(a => a._id);
    }
  }

}