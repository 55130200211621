import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

export interface Alert {
  msg: string,
  styleClass: string,
  dismissTime?: number,
  dismissable?: boolean,
  action?: {
    id: any,
    label: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    fn?: Function
  }
}

@Injectable()
export class PopAlertsService {

  constructor(private translate: TranslateService) {
  }

  alerts = new Subject<Alert>();

  action = new Subject<Alert>();

  pop(alert: Alert) {
    this.alerts.next(alert);
  }

  saveSuccess() {
    this.pop({
      msg: this.translate.instant('changes-saved'),
      styleClass: 'success'
    });
  }

  error(msg) {
    this.pop({
      msg,
      styleClass: 'danger'
    });
  }

  info(msg) {
    this.pop({
      msg,
      styleClass: 'info'
    });
  }

  emitAction(alert) {
    this.action.next(alert);
  }

}