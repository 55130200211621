<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <ng-container *ngIf="process.student && !reviewData">
    <thesis-request-form [student]="process.student"
      [data]="process.data"
      [sendBtnText]="'to-review'"
      [sendBtnClass]="'outline-primary'"
      [allowCompendiumDirectorReport]="true"
      (onSend)="reviewStep($event); hs.scrollToTop();"></thesis-request-form>
  </ng-container>

  <div class="card"
    *ngIf="process.student && reviewData">
    <div class="card-body">

      <thesis-data-view [data]="process.data"
        [student]="process.student"></thesis-data-view>

      <form #f="ngForm"
        class="mt-4"
        (ngSubmit)="manageSend()">

        <div class="mb-4 text-right"
          *ngIf="process.student && reviewData">
          <button class="btn btn-outline-dark"
            type="button"
            (click)="reviewData = false; hs.scrollToTop()">
            {{ 'modify' | translate}}
          </button>
        </div>

        <validation-buttons [(ngModel)]="validation"
          name="validation-buttons"
          [showValidationText]="true"
          [showErrors]="f.submitted"></validation-buttons>

        <div class="form-group text-right"
          *ngIf="validation.validate !== null">
          <button class="btn btn-primary"
            type="submit">
            {{ 'send' | translate }}
          </button>
        </div>

      </form>

    </div>

  </div>

</ng-container>