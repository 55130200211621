import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IMailTimeEmail } from '../../../interfaces/IMailTimeEmail';

@Injectable()
export class EmailsHttpService {
  constructor(private http: HttpClient) { }

  fetch(params: { searchText?: string, page?: number, size?: number }): Observable<IMailTimeEmail[]> {
    return this.http.get<IMailTimeEmail[]>('/api/emails/list', { params });
  }

  find(mailId: string): Observable<IMailTimeEmail> {
    return this.http.get<IMailTimeEmail>(`/api/emails/find/${mailId}`);
  }

  forward(mailId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>('/api/emails/forward', { mailId });
  }

  send(to: string, toName: string, subject: string, html: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>('/api/emails/send', { to, toName, subject, html });
  }

}