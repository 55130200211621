<div class="small text-muted mb-1">
  {{ 'tutor' | translate }}
</div>
<text-info icon="user"
  mb="4">
  {{ student.tutor | fullname }}
</text-info>

<ng-container *ngIf="student.supervisor1 || student.supervisor2 || student.supervisor3">
  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'supervisors' | translate }}
    </div>
    <text-info icon="user"
      *ngIf="student.supervisor1">
      {{ student.supervisor1 | fullname }}
    </text-info>
    <text-info icon="user"
      *ngIf="student.supervisor2">
      {{ student.supervisor2 | fullname }}
    </text-info>
    <text-info icon="user"
      *ngIf="student.supervisor3">
      {{ student.supervisor3 | fullname }}
    </text-info>
  </div>
</ng-container>