<div class="modal fade"
  [ngClass]="{show: open, 'modal-open': open}"
  [@appear]="open ? 'in' : 'void'"
  [style.display]="open ? 'block' : 'none'"
  ngDraggable
  [handle]="modalHeader"
  role="dialog">

  <div [class]="'modal-dialog modal-' + size "
    [class.modal-dialog-centered]="centered"
    role="document">

    <div class="modal-content">
      <div #modalHeader
        class="modal-header"
        [class.d-none]="!title">
        <h5 class="modal-title">
          {{ title }}
        </h5>
        <button type="button"
          (click)="close()"
          class="close"
          *ngIf="closable">
          <span>&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <ng-content></ng-content>
      </div>

      <div class="modal-footer"
        *ngIf="showFooter">
        <ng-content select="[footer]"></ng-content>
      </div>

    </div>
  </div>
</div>

<div class="modal-backdrop fade"
  [ngClass]="{show: open}"
  [style.display]="open ? 'block' : 'none'">
</div>