<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <br>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-title' | translate }}
    </div>
    <div>
      {{ process.data.thesisTitle }}
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'thesis-final-doc' | translate }}
    </div>
    <div>
      <document-link [document]="process.thesisFinal"></document-link>
    </div>
  </div>

  <div class="mb-4">
    <div class="small text-muted mb-1">
      {{ 'changes-report' | translate }}
    </div>
    <div>
      <document-link [document]="process.changesReport"></document-link>
    </div>
  </div>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <validation-buttons [(ngModel)]="validation"
      name="validation-buttons"
      [showErrors]="f.submitted"></validation-buttons>

    <div class="form-group"
      *ngIf="validation.validate === true">
      <label>
        {{ 'pgc-approbal-date' | translate }}
      </label>
      <date-picker name="pgcApprobalDate"
        [(ngModel)]="pgcApprobalDate"
        #pgcApprobalDateInput="ngModel"
        [placeholder]="'pgc-approbal-date' | translate"
        [isInvalid]="pgcApprobalDateInput.invalid && f.submitted"
        [time]="false"
        required="true"></date-picker>
      <validation-errors [show]="f.submitted"
        [control]="pgcApprobalDateInput"></validation-errors>
    </div>

    <div class="form-group text-right"
      *ngIf="validation.validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        <span *ngIf="validation.validate === true">
          {{ 'accept' | translate }}
        </span>
        <span *ngIf="validation.validate === false">
          {{ 'send' | translate }}
        </span>
      </button>
    </div>

  </form>

</ng-container>