<modal [(open)]="open"
  [title]="'edit-evaluation' | translate">

  <form #f="ngForm"
    (ngSubmit)="save()"
    id="editEvaluationForm"
    *ngIf="open">

    <div class="form-group">
      <label>
        <span *ngIf="student.rd === RDType.rd99Mod">
          {{ 'research-and-formation-plan' | translate }}
        </span>
        <span *ngIf="student.rd === RDType.rd99">
          {{ 'research-plan' | translate }}
        </span>
      </label>
      <file-input name="researchPlan"
        required
        #researchPlanInput="ngModel"
        [(ngModel)]="data.researchPlan"></file-input>
      <validation-errors [show]="f.submitted"
        [control]="researchPlanInput"></validation-errors>
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between align-items-center">
        <label>
          {{ 'select-activities-to-evaluate' | translate }}
        </label>
        <a role="button"
          class="small text-right"
          (click)="toggleSelectAll()"
          *ngIf="activities.length > 0"
          [routerLink]="null">
          <span *ngIf="data.activitiesIds.length < activities.length">
            {{ 'select-all' | translate }}
          </span>
          <span *ngIf="data.activitiesIds.length == activities.length">
            {{ 'deselect-all' | translate }}
          </span>
        </a>
      </div>
      <div style="width: 100%; max-height: 600px; overflow-y: auto"
        class="border rounded">
        <div class="list-group">
          <div class="list-group-item"
            *ngIf="activities.length <= 0"
            [innerHTML]="'no-assessable-activites' | translate">
          </div>
          <div class="list-group-item d-flex justify-content-start"
            *ngFor="let activity of activities">
            <div class="c-pointer"
              (click)="toggle(activity._id)">
              <span *ngIf="!data.activitiesIds.includes(activity._id)">
                <i class="far fa-square text-muted mr-2"></i>
              </span>
              <span *ngIf="data.activitiesIds.includes(activity._id)">
                <i class="fas fa-check-square text-primary mr-2"></i>
              </span>
            </div>
            <div class="flex-fill">
              <activity-view [activity]="activity"
                [clickableType]="true"
                (onTypeClick)="toggle(activity._id)"></activity-view>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="f.submitted && data.activitiesIds.length <= 0"
        class="invalid-feedback d-block mb-2">
        {{ 'must-select-at-least-one-activity' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label>
        {{ 'direction-team-reports' | translate }}
      </label>
      <validation-errors [show]="f.submitted"
        [control]="reportsInput"></validation-errors>
      <files-queue name="reports"
        [required]="false"
        #reportsInput="ngModel"
        [(ngModel)]="data.reports"></files-queue>
    </div>

    <div class="form-group">
      <label for="ec">
        {{ 'evaluation-committee' | translate }}
      </label>
      <ng-select [items]="evaluationCommittees"
        bindLabel="code"
        [required]="false"
        [multiple]="false"
        [searchable]="false"
        id="ec"
        name="ec"
        style="width: 100%;"
        [closeOnSelect]="true"
        [placeholder]="'choose-evaluation-committee' | translate"
        #ec="ngModel"
        [class.is-invalid]="ec.invalid && f.submitted"
        [(ngModel)]="selectedEC">
        <ng-template ng-option-tmp
          ng-label-tmp
          let-item="item">
          {{ item.code }}
          <span class="small text-muted"
            *ngIf="originalEC && originalEC._id == item._id">
            asignado actualmente
          </span>
        </ng-template>
        <ng-template ng-label-tmp
          let-item="item">
          {{ item.code }}
          <span class="small text-muted"
            *ngIf="originalEC && originalEC._id == item._id">
            asignado actualmente
          </span>
        </ng-template>
      </ng-select>
      <validation-errors [control]="ec"
        [show]="f.submitted"></validation-errors>
    </div>

    <div *ngIf="selectedEC"
      class="list-group">
      <div *ngIf="selectedEC.membersIds.length <= 0"
        class="list-group-item">
        <span class="d-block invalid-feedback">
          {{ 'empty-evaluation-committee' | translate }}
        </span>
      </div>
      <div *ngFor="let member of selectedEC.members"
        class="list-group-item">
        {{ member | fullname }}
      </div>
    </div>




  </form>

  <div footer>
    <button class="btn btn-primary"
      type="submit"
      form="editEvaluationForm"
      [disableButton]="sending"
      [disableButtonIcon]="sending ? 'spin' : 'ban'">
      {{ 'save' | translate}}
    </button>
  </div>

</modal>