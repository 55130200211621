import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IProfessorProcess } from '../../../../../../../interfaces/IProfessorProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { ProfessorHttpService } from '../../../../../data-services/professor-http.service';
import { Router } from '@angular/router';
import { AbstractTaskComponent } from '../../abstract-task.component';

@Component({
  selector: 'professor-pgc-validation',
  templateUrl: './professor-pgc-validation.component.html',
})
export class ProfessorPgcValidationComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IProfessorProcess;
  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  constructor(private pfs: ProfessorHttpService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.pfs.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
    })
  }

  manageSend() {
    if (!this.f.valid)
      return;

    if (this.validation.validate === false) {
      this.pfs.postgraduateCommitteeDeny(this.task._id, this.validation.denialMsg).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      })
    } else {
      this.pfs.postgraduateCommitteeValidate(this.task._id).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      })
    }
  }

}
