<li class="nav-item dropdown mr-2 messages-dropdown-btn"
  #tasksDropdown>
  <a class="nav-link"
    role="button"
    (click)="open()">
    <span class="fa-layers fa-fw">
      <span class="fa-layers fa-fw">
        <i class="far fa-tasks fa-fw"></i>
        <span class="fa-layers-counter"
          *ngIf="count > 0"
          style="background: #e50000">
          {{ count }}
        </span>
      </span>
    </span>
  </a>
  <div class="dropdown-menu dropdown-menu-right p-0 tasks-dropdown"
    [@dropdownAnimation]="dds.show('tasksDropdown') ? 'in' : 'out'"
    [class.show]="dds.show('tasksDropdown')">
    <h6 class="dropdown-header"
      *ngIf="tasks.length <= 0">{{ 'no-pending-tasks' | translate }}</h6>
    <h6 class="dropdown-header"
      *ngIf="tasks.length > 0">{{ 'pending-tasks' | translate }}</h6>
    <div style="max-height: 600px; overflow-y: auto !important;">
      <div class="w-100">
        <ng-select [items]="processKeys"
          style="width: 100%"
          [(ngModel)]="processKey"
          (ngModelChange)="filter()"
          bindLabel="label"
          bindValue="value"
          [searchable]="false"
          class="ng-select-sm"
          [clearable]="true"
          [placeholder]="'Proceso'"
          appendTo="body">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item.label }} ({{ processKeysFilterCount[item.value] }})
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item.label }} ({{ processKeysFilterCount[item.value] }})
          </ng-template>
        </ng-select>
      </div>
      <div class="w-100">
        <ng-select [items]="roles"
          style="width: 100%"
          [(ngModel)]="role"
          (ngModelChange)="filter()"
          bindLabel="label"
          bindValue="value"
          [searchable]="false"
          class="ng-select-sm"
          [clearable]="true"
          [placeholder]="'Rol'"
          appendTo="body">
          <ng-template ng-option-tmp
            ng-label-tmp
            let-item="item">
            {{ item.label }} ({{ rolesFilterCount[item.value] }})
          </ng-template>
          <ng-template ng-label-tmp
            let-item="item">
            {{ item.label }} ({{ rolesFilterCount[item.value] }})
          </ng-template>
        </ng-select>
      </div>

      <tasks-list [tasks]="tasks"
        (onClick)="goToTask($event)"></tasks-list>
      <a class="dropdown-item text-center"
        [routerLink]="null"
        role="button"
        *ngIf="count > tasks.length"
        (click)="loadMore()">
        {{ 'load-more' | translate }}
      </a>
    </div>
  </div>
</li>