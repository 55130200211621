import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { IExtensionProcess } from '../../../../../../../interfaces/IExtensionProcess';
import { ITask } from '../../../../../../../interfaces/ITask';
import { ExtensionHttpService } from '../../../../../data-services/extension-http.service';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { UserService } from '../../../../../user/user.service';
import { RDType } from '../../../../../../../interfaces/IStudent';

@Component({
  selector: 'extension-ac-validation',
  templateUrl: './extension-ac-validation.component.html'
})
export class ExtensionAcValidationComponent extends AbstractTaskComponent implements OnInit {
  @ViewChild('f') f: NgForm;
  @Input() task: ITask;

  process: IExtensionProcess;

  justification: IDocumentRef[] = [];

  denialMsg = '';
  validate = null;

  canComplete: boolean = null;

  RDType = RDType;

  constructor(private extension: ExtensionHttpService,
    private us: UserService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.canComplete = this.us.canComplete(this.task);
    this.extension.findProcess(this.task._id).subscribe((process) => {
      this.process = process;

      this.justification = this.process.justification;
    })
  }

  manageSend() {
    if (this.f.invalid) {
      this.sendEnd.next();
      return;
    }

    if (this.validate) {
      this.extension.academicCommitteeValidation(this.task._id).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    } else {
      this.extension.academiccommitteeDeny(this.task._id, { denialMsg: this.denialMsg }).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      });
    }
  }

}
