import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EvaluationPeriodType, EvaluationType, IEvaluation, IStudent, RDType } from '../../../../../../../interfaces/IStudent';
import { IDocumentRef } from '../../../../../../../interfaces/IDocument';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogService } from '../../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { PopAlertsService } from '../../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../../data-services/service-http.service';
import { UserService } from '../../../../../user/user.service';
import { EvaluationControlService } from '../../../../../../../server/services/EvaluationControlService';
import { EvaluationControlHttpService } from '../../../../../data-services/evaluation-control-http.service';
import * as moment from 'moment-timezone';
import { IUser } from '../../../../../../../interfaces/IUser';
import { StudentHttpService } from '../../../../../data-services/student-http.service';
import { DADUtils } from '../../../../../../../utils/DADUtils';

@Component({
  selector: 'evaluations-card',
  templateUrl: './evaluations-card.component.html',
  styles: [
  ]
})
export class EvaluationsCardComponent implements OnInit {

  @ViewChild('f') f: NgForm;
  @Input() student: IStudent;

  studentId: string;
  evaluationId: string;
  registeredAt: Date;
  year: string;
  period: EvaluationPeriodType;
  evaluation: EvaluationType;
  reports: IDocumentRef[];
  committeeReport: IDocumentRef;
  researchPlan: IDocumentRef;
  recordFile: IDocumentRef;

  president: IUser;
  secretary: IUser;
  member: IUser;

  open = false;

  EvaluationType = EvaluationType;
  EvaluationPeriodType = EvaluationPeriodType;

  years: number[] = [];

  RDType = RDType;

  constructor(public sh: ServiceHttpService,
    private ecs: EvaluationControlHttpService,
    public us: UserService,
    private refresh: RefreshService,
    private alerts: PopAlertsService,
    private confirmation: ConfirmationDialogService,
    private ts: TranslateService,
    private ss: StudentHttpService) { }

  ngOnInit(): void {
    const currentYear = moment().year();
    for (let i = currentYear - 20; i < currentYear + 2; i++) {
      this.years.push(i);
    }
  }

  alreadyExists(): boolean {
    if (!this.evaluationId && this.year && this.period) {
      const ev = this.student.evaluations.find(e => e.period === this.period && e.year === this.year);
      if (ev) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  fullYearString(year: number) {
    return `${year - 1}${year}`;
  }

  openEdit(evaluationId) {
    this.reset();
    const evaluation = this.student.evaluations.find(e => e._id === evaluationId);
    if (evaluation) {
      this.evaluationId = evaluationId;
      this.registeredAt = evaluation.registeredAt;
      this.year = evaluation.year;
      this.period = evaluation.period;
      this.evaluation = evaluation.evaluation;
      if (evaluation.reports) {
        this.reports = [...evaluation.reports];
      } else {
        this.reports = [];
      }
      this.committeeReport = evaluation.committeeReport;
      this.researchPlan = evaluation.researchPlan;
      this.recordFile = evaluation.recordFile;
      this.ss.fetchEvaluationCommittee(this.student._id, evaluationId).subscribe((result) => {
        console.log(result)
        this.president = result.president;
        this.secretary = result.secretary;
        this.member = result.member;
        this.open = true;
      })

    }
  }

  reset() {
    this.evaluationId = null;
    this.registeredAt = null;
    this.year = this.fullYearString(moment().year());
    this.period = null;
    this.evaluation = null;
    this.reports = [];
    this.committeeReport = null;
    this.researchPlan = null;
    this.recordFile = null;
    this.president = null;
    this.secretary = null;
    this.member = null;
  }

  save() {
    if (!this.f.valid || this.alreadyExists()) {
      return;
    }

    if (this.evaluationId) {
      this.ecs.editEvaluation({
        studentId: this.student._id,
        evaluationId: this.evaluationId,
        registeredAt: this.registeredAt,
        evaluation: this.evaluation,
        presidentId: this.president ? this.president._id : null,
        secretaryId: this.secretary ? this.secretary._id : null,
        memberId: this.member ? this.member._id : null,
        reports: this.reports,
        committeeReport: this.committeeReport,
        researchPlan: this.researchPlan,
        recordFile: this.recordFile
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    } else {
      this.ecs.addEvaluation({
        studentId: this.student._id,
        registeredAt: this.registeredAt,
        year: this.year,
        period: this.period,
        evaluation: this.evaluation,
        presidentId: this.president ? this.president._id : null,
        secretaryId: this.secretary ? this.secretary._id : null,
        memberId: this.member ? this.member._id : null,
        reports: this.reports,
        committeeReport: this.committeeReport,
        researchPlan: this.researchPlan,
        recordFile: this.recordFile
      }).subscribe(() => {
        this.open = false;
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    }
  }

  remove(evaluation: IEvaluation) {
    this.confirmation.confirm({
      title: this.ts.instant('remove-evaluation'),
      message: this.ts.instant('are-you-sure-to-remove-evaluation', { period: this.ts.instant(evaluation.period), year: DADUtils.getEvaluationYear(evaluation.year) }),
      btnOkText: this.ts.instant('remove')
    }, () => {
      this.ecs.removeEvaluation({
        studentId: this.student._id,
        evaluationId: evaluation._id,
      }).subscribe(() => {
        this.refresh.onStudentChange.next()
        this.alerts.saveSuccess();
      })
    })
  }

  isEditable(): boolean {
    return (this.us.isService() && this.sh.editMode) || this.us.isAuthModifyEvaluations(this.student.programId)
  }

}
