import { Component, OnInit } from '@angular/core';

import { LoadingIndicatorService } from './loading-indicator.service';

@Component({
  selector: 'loading-indicator',
  templateUrl: 'loading-indicator.component.html',
  styleUrls: ['loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {

  constructor(public lis: LoadingIndicatorService) { }

  ngOnInit() { }
}