<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <form #f="ngForm"
    (ngSubmit)="send()">

    <div class="form-group">
      <label>
        {{ 'report' | translate }}
      </label>
      <file-input name="report"
        required
        #reportInput="ngModel"
        [invalid]="reportInput.invalid && f.submitted"
        [(ngModel)]="report"></file-input>
      <validation-errors [show]="f.submitted"
        [control]="reportInput"></validation-errors>
    </div>


    <div class="form-group text-right">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>
  </form>

</ng-container>