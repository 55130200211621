import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IStudent } from '../../../../../../interfaces/IStudent';
import { StudentHttpService } from '../../../../data-services/student-http.service';
import { UserService } from '../../../../user/user.service';
import { StudentsSearchComponent } from '../../../components/students/students-search/students-search.component';
import { Subscription } from 'rxjs';
import { Role } from '../../../../../../interfaces/IUser';

@Component({
  selector: 'tutor-students',
  templateUrl: 'tutor-students.component.html'
})
export class TutorStudentsComponent implements OnInit, OnDestroy {
  @ViewChild('studentsSearch') studentsSearch: StudentsSearchComponent;
  students: IStudent[] = [];

  sub: Subscription;

  constructor(public ss: StudentHttpService,
    public us: UserService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.us.roleRefresh.subscribe((role) => {
      if (role.role === Role.tutor) {
        this.studentsSearch.doSearch();
      }
    });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  dataFn() {
    return (params) => {
      return this.ss.fetchTutorStudents(this.us.selectedRole.groupId, params);
    }
  }

  goToStudent(studentId) {
    void this.router.navigate([`${studentId}`], { relativeTo: this.route });
  }
}