import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { StudentStateType } from '../../../../../../interfaces/IStudent';

@Component({
  selector: 'student-state',
  templateUrl: './student-state.component.html',
})
export class StudentStateComponent implements OnInit, OnChanges {

  @Input() state: StudentStateType;
  @Input() long = false;
  @Input() cdsRequested = false;
  @Input() ml = false;
  @Input() mr = false;
  stateStr;

  StudentStateType = StudentStateType;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      this.init();
    }
  }

  ngOnInit() {
  }

  init() {
    this.stateStr = this.state;
    if (this.long) {
      this.stateStr += '_INFO';
    }
  }

}
