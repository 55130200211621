<div class="container">

  <div class="row">
    <div class="col">

      <h5>Lanzar COMPROBACIÓN DE RENOVACIONES DE BAJA</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="launchCheckLeavesRenewals()">COMPROBAR</button>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col">
      <section-title text="Check">
      </section-title>
    </div>
  </div>

  <div class="row">
    <div class="col">

      <h5>Importar profesores</h5>
      <div class="form-group">
        <file-input name="professorsData"
          id="professorsData"
          [(ngModel)]="professorsData">
        </file-input>
      </div>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="importProfessors()">IMPORTAR PROFESORES</button>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col">

      <h5>SET PROFESSORS USERS</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="setProfessorsUsers()">SET PROFESSORS USERS</button>
      </div>

    </div>
  </div>



  <div class="row">
    <div class="col">

      <h5>Suspender procesos alumnos de BAJA</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="deactivatePermanentLeaveStudentsProcesses()">SUSPENDER PROCESOS</button>
      </div>

    </div>
  </div>


  <div class="row d-none">
    <div class="col">

      <h5>Actualizar fechas límite</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="checkDeadlinesAndUpdate()">UPDATE DEADLINES</button>
      </div>

    </div>
  </div>

  <div class="row d-">
    <div class="col">

      <h5>Actualizar estado de todos los estudiantes</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="updateStudentsStates()">ACTUALIZAR ESTADOS</button>
      </div>

    </div>
  </div>

  <div class="row d-none">
    <div class="col">

      <h5>Enviar correo de matrículas pendientes (solo a partir del 1 de diciembre)</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="checkAnnualEnrollments()">ENVIAR CORREOS MATRÍCULAS PENDIENTES</button>
      </div>

    </div>
  </div>

  <div class="row d-none">
    <div class="col">

      <h5>Lanzar proceso evaluación</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="launchEvaluationList()">LANZAR EVALUACIÓN</button>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col">

      <h5>Enviar recordatorios de solicitud de evaluación actual</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="sendMidEvaluationReminders()">ENVIAR</button>
      </div>

      <h5>Enviar recordatorios de evaluaciones pendientes</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="sendPendingEvaluationReminders()">ENVIAR</button>
      </div>

    </div>
  </div>


  <div class="row">
    <div class="col">
      <h5>Recordatorios de fecha límite</h5>
      <div class="form-group">
        <date-picker id="startDate"
          name="startDate"
          [(ngModel)]="deadlinesRemindersDate"
          [closeOnSelect]="true"
          [time]="false"></date-picker>
      </div>

      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          (click)="deadlinesRemindersLaunch()">ENVIAR</button>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col">
      <h5>Renovar tokens externos</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="renewExternalTasksToken()">RENOVAR</button>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col">

      <h5>Regenerar justificante de solicitud de tesis</h5>
      <div class="form-group">
        <input type="text"
          [(ngModel)]="processId"
          name="processId"
          placeholder="processId"
          class="form-control">
      </div>

      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="regenerateThesisProcessRequestDoc()">REGENERAR</button>
      </div>

    </div>
  </div>


  <div class="row d-none">
    <div class="col">

      <h5>Evaluar como no presentados para la última evaluación</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="evaluateNoShow()">EVALUAR NO PRESENTADOS</button>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col">

      <h5>Test Email</h5>
      <div class="form-group">
        <input type="text"
          [(ngModel)]="testEmailTo"
          name="testEmailTo"
          placeholder="testEmailTo"
          class="form-control">
      </div>

      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          (click)="sendTestEmail()">SEND EMAIL</button>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col">

      <h5>LDAP search</h5>
      <div class="form-group">
        <input type="text"
          [(ngModel)]="params.email"
          name="email"
          placeholder="email"
          class="form-control">
      </div>
      <div class="form-group">
        <input type="text"
          [(ngModel)]="params.firstName"
          name="firstName"
          placeholder="firstName"
          class="form-control">
      </div>
      <div class="form-group">
        <input type="text"
          [(ngModel)]="params.lastName"
          name="lastName"
          placeholder="lastName"
          class="form-control">
      </div>
      <div class="form-group">
        <input type="text"
          [(ngModel)]="params.idNum"
          name="idNum"
          placeholder="idNum"
          class="form-control">
      </div>
      <div class="form-group">
        <input type="text"
          [(ngModel)]="params.uid"
          name="uid"
          placeholder="uid"
          class="form-control">
      </div>

      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          (click)="searchLdap()">SEARCH</button>
      </div>

      <div class="list-group">
        <div class="list-group-item"
          *ngFor="let entry of results">
          <pre>
            {{ entry | json }}
          </pre>
        </div>
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col">

      <h5>Enrollments</h5>
      <div class="form-group">
        <date-picker id="startDate"
          name="startDate"
          [(ngModel)]="start"
          [closeOnSelect]="true"
          [time]="false"></date-picker>
      </div>
      <div class="form-group">
        <input type="number"
          id="recordNum"
          name="recordNum"
          [(ngModel)]="recordNum"
          [placeholder]="'número de expediente: 900XXXX'"
          class="form-control">
      </div>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          (click)="getEnrollments()">GET BY DATE</button>
        <button class="btn btn-primary"
          type="button"
          (click)="getStudentEnrollments()">GET BY STUDENT</button>
      </div>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          (click)="importEnrollments()">IMPORT</button>
      </div>

      {{ enrollmentsData | json }}

      {{ importData | json }}

    </div>
  </div>

  <div class="row d-">
    <div class="col">
      <h5>De estado fecha límite a baja en la aplicación</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          (click)="deadlineStudentsToPermanentLeave()">LAUNCH</button>
      </div>
    </div>
  </div>

  <div class="row d-none">
    <div class="col">
      <h5>Leaves reminders</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          (click)="leavesReminders()">LAUNCH</button>
      </div>
    </div>
  </div>

  <div class="row d-none">
    <div class="col">
      <h5>Copia dataos entre estudiantes</h5>
      <p>
        Copiará el último CDS, las bajas activas y las actividades (exceptuando evaluaciones, admisión a trámite y
        defensa)
      </p>
      <div class="form-group">
        <label>From ID</label>
        <input type="text"
          [(ngModel)]="fromStudentId"
          name="source"
          placeholder="fromStudentId"
          required
          class="form-control">
      </div>
      <div class="form-group">
        <label>To ID</label>
        <input type="text"
          [(ngModel)]="toStudentId"
          name="target"
          placeholder="toStudentId"
          required
          class="form-control">
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-primary"
        type="button"
        (click)="copyStudentsData()">COPY</button>
    </div>
  </div>

  <div class="row d-none">
    <div class="col">
      <h5>permanentLeaveToNegativeEvaluationsStudents</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="permanentLeaveToNegativeEvaluationsStudents()">LAUNCH</button>
      </div>
    </div>
  </div>

  <div class="row d-none">
    <div class="col">
      <h5>updateTUTroles</h5>
      <div class="form-group">
        <button class="btn btn-primary"
          type="button"
          [disabled]="launchClicked"
          (click)="updateTUTroles()">LAUNCH</button>
      </div>
    </div>
  </div>


</div>