import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExternalTaskComponent } from './external-task/external-task.component';
import { LoginComponent } from './login/login.component';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { HttpErrorInterceptor } from './UI/http-error-interceptor/http-error.interceptor';
import { WatermarkComponent } from './UI/watermark/watermark.component';
import { OldTaskComponentComponent } from './old-task-component/old-task-component.component';
import { DocumentVerificationComponent } from './document-verification/document-verification.component';
import { PrintEmailViewComponent } from './root/routes/service/sent-emails/print-email-view/print-email-view.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ExternalTaskComponent,
    WatermarkComponent,
    OldTaskComponentComponent,
    DocumentVerificationComponent,
    PrintEmailViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
