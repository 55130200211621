import { Component, OnInit } from '@angular/core';
import { Processes } from '../../../../../../interfaces/ITask';
import { ProcessesHttpService } from '../../../../data-services/processes-http.service';
import { HelperService } from '../../../../shared/helper-service/helper.service';

@Component({
  selector: 'service-processes',
  templateUrl: './service-processes.component.html'
})
export class ServiceProcessesComponent implements OnInit {

  processType = Processes.thesis;

  Processes = Processes;

  constructor(private ps: ProcessesHttpService,
    public hs: HelperService) { }

  ngOnInit() {

  }

  dataFn() {
    return (params) => {
      params = {
        ...params,
        processType: this.processType
      }
      return this.ps.fetchProcesses(params);
    }
  }

}
