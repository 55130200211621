import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IDocumentRef } from '../../../interfaces/IDocument';
import { IExtensionProcess } from '../../../interfaces/IExtensionProcess';

@Injectable()
export class ExtensionHttpService {

  constructor(private http: HttpClient) { }

  request(studentId: string, data: { justification: IDocumentRef[] }): Observable<IDocumentRef> {
    return this.http.post<IDocumentRef>(`/api/extension/request/${studentId}`, data);
  }

  academicCommitteeValidation(taskId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/extension/academicCommitteeValidation/${taskId}`, {});
  }

  academiccommitteeDeny(taskId: string, data: { denialMsg: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/extension/academiccommitteeDeny/${taskId}`, data);
  }

  findProcess(taskId: string): Observable<IExtensionProcess> {
    return this.http.get<IExtensionProcess>(`/api/extension/findProcess/${taskId}`);
  }

  getTasksTable(studentId: string): Observable<IExtensionProcess> {
    return this.http.get<IExtensionProcess>(`/api/extension/process/${studentId}`);
  }

}