import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractTaskComponent } from '../../abstract-task.component';
import { NgForm } from '@angular/forms';
import { ITask } from '../../../../../../../interfaces/ITask';
import { IProfessorProcess } from '../../../../../../../interfaces/IProfessorProcess';
import { ProfessorHttpService } from '../../../../../data-services/professor-http.service';
import { Router } from '@angular/router';
import { IProfessor } from '../../../../../../../interfaces/IProfessor';

@Component({
  selector: 'professor-service-validation',
  templateUrl: './professor-service-validation.component.html',
})
export class ProfessorServiceValidationComponent extends AbstractTaskComponent implements OnInit {

  @ViewChild('f') f: NgForm;

  @Input() task: ITask;

  process: IProfessorProcess;
  end = null;
  validation: {
    validate: boolean;
    denialMsg: string;
  } = {
      validate: null,
      denialMsg: null
    }

  idNumExists = false;
  idNumExistsInAnotherProgram = false;
  idNumOtherProgramName = '';
  idNumEndYear = '';
  emailExists = false;
  emailExistsInAnotherProgram = false;
  emailOtherProgramName = '';
  emailEndYear = '';

  constructor(private pfs: ProfessorHttpService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.pfs.findProcess(this.task._id).subscribe((process) => {
      this.process = process;
      this.checkEmail();
      this.checkIdNum();
    })
  }

  manageSend() {
    if (!this.f.valid)
      return;

    if (this.validation.validate === false) {
      this.pfs.serviceDeny(this.task._id, this.validation.denialMsg, this.end).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      })
    } else {
      this.pfs.serviceValidate(this.task._id).subscribe(() => {
        this.sendEnd.next();
        void this.router.navigate(['/app/task-completed']);
      })
    }
  }

  checkIdNum() {
    if (!this.process.idNum || this.process.idNum.length < 0) {
      return;
    }
    this.idNumExists = false;
    this.idNumExistsInAnotherProgram = false;
    this.pfs.professorCheckIdNum(this.process.idNum, this.process.programId).subscribe((res: IProfessor[]) => {
      if (res && res.length > 0) {
        // check if the professor is already in the program
        for (const p of res) {
          if (p.programId === this.process.programId) {
            this.idNumExists = true
          } else {
            this.idNumExistsInAnotherProgram = true;
            this.idNumOtherProgramName = p.program.name;
          }
          this.idNumEndYear = p.endYear;
        }
      }
    });
  }

  checkEmail() {
    if (!this.process.email || this.process.email.length < 0) {
      return;
    }
    this.emailExists = false;
    this.emailExistsInAnotherProgram = false;
    this.pfs.professorCheckEmail(this.process.email, this.process.programId).subscribe((res: IProfessor[]) => {
      if (res && res.length > 0) {
        // check if the professor is already in the program
        for (const p of res) {
          if (p.programId === this.process.programId) {
            this.emailExists = true
          } else {
            this.emailExistsInAnotherProgram = true;
            this.emailOtherProgramName = p.program.name;
          }
          this.emailEndYear = p.endYear;
        }
      }
    });
  }

}
