import { Component, OnInit } from '@angular/core';
import { ProgramFileService } from '../program-file.service';

@Component({
  selector: 'program-file-evaluation',
  templateUrl: './program-file-evaluation.component.html',
  styles: [
  ]
})
export class ProgramFileEvaluationComponent implements OnInit {

  constructor(public pfs: ProgramFileService) { }

  ngOnInit(): void {
  }

}
