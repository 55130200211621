import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IExtensions } from '../../../../../../../interfaces/IStudent';

@Component({
  selector: 'extensions-list',
  templateUrl: './extensions-list.component.html'
})
export class ExtensionsListComponent implements OnInit {

  @Input() extensions: IExtensions[];
  @Input() editable = false;
  @Input() showOrder = true;

  @Output() onRemove = new EventEmitter<string>();
  @Output() onEdit = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

}
