<div class="container-lg container-fluid px-0 px-md-2">
  <div class="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 mt-3 px-0 px-md-2">

    <div class="card">
      <div class="card-body"
        *ngIf="sr.student">
        <ng-container *ngIf="!requested">

          <h5 class="card-title">
            {{ 'process.leave.request.name' | translate }}
          </h5>

          <p [innerHTML]="'process.leave.request.desc' | translate">
          </p>

          <form #f="ngForm"
            (ngSubmit)="send()">

            <div class="form-group">
              <label for="typeSelect">
                {{ 'leave-type' | translate }}
              </label>
              <ng-select id="typeSelect"
                name="typeSelect"
                #typeSelect="ngModel"
                [(ngModel)]="type"
                required
                [class.is-invalid]="f.submitted && typeSelect.invalid"
                [placeholder]="'select-leave-type' | translate"
                [searchable]="false"
                [clearable]="false">
                <ng-option [value]="LeaveType.temporal">{{ LeaveType.temporal | translate }}</ng-option>
                <ng-option [value]="LeaveType.medical">{{ LeaveType.medical | translate }}</ng-option>
              </ng-select>
              <validation-errors [show]="f.submitted"
                [control]="typeSelect"></validation-errors>
            </div>

            <div class="alert alert-danger"
              *ngIf="type === LeaveType.temporal && temporalLeavesLimitReached">
              {{ 'temporal-leaves-limit-reached' | translate }}
            </div>

            <ng-container *ngIf="type && (!temporalLeavesLimitReached || type === LeaveType.medical)">
              <div class="form-group">
                <label for="startDate">
                  {{ 'start-date' | translate }}
                </label>

                <date-picker id="startDate"
                  name="startDate"
                  [placeholder]="'start-date' | translate"
                  required="true"
                  [isInvalid]="startDateInput.invalid && f.submitted"
                  #startDateInput="ngModel"
                  [time]="false"
                  [future]="type === LeaveType.temporal"
                  [(ngModel)]="start"> </date-picker>
                <validation-errors [control]="startDateInput"
                  [show]="f.submitted"></validation-errors>
                <span class="invalid-feedback d-inline"
                  *ngIf="start && isPastStart() && type === LeaveType.temporal">
                  {{ 'temporal-leave-start-must-be-future-msg' | translate }}
                </span>
              </div>

              <div class="form-group"
                *ngIf="type === LeaveType.temporal">
                <label for="endDate">
                  {{ 'end-date' | translate }}
                </label>

                <date-picker id="endDate"
                  name="endDate"
                  required="true"
                  [placeholder]="'end-date' | translate"
                  [isInvalid]="endDateInput.invalid && f.submitted"
                  #endDateInput="ngModel"
                  [time]="false"
                  [(ngModel)]="end"> </date-picker>
                <validation-errors [control]="endDateInput"
                  [show]="f.submitted"></validation-errors>
                <span class="invalid-feedback d-inline"
                  *ngIf="start && end && !validDateRange()">
                  {{ 'temporal-leave-min-max-duration-msg' | translate }}
                </span>
              </div>

              <div class="form-group"
                *ngIf="type === LeaveType.medical">
                <label for="estimatedEndDate">
                  {{ 'estimated-end-date' | translate }}
                </label>

                <date-picker id="estimatedEndDate"
                  name="estimatedEndDate"
                  required="true"
                  [placeholder]="'estimated-end-date' | translate"
                  [isInvalid]="estimatedEndDateInput.invalid && f.submitted"
                  #estimatedEndDateInput="ngModel"
                  [time]="false"
                  [(ngModel)]="estimatedEnd"> </date-picker>
                <validation-errors [control]="estimatedEndDateInput"
                  [show]="f.submitted"></validation-errors>
                <small class="form-text text-muted">
                  {{ 'estimated-end-date-note' | translate }}
                </small>
              </div>

              <div class="form-group">
                <label>
                  {{ 'justification' | translate }}
                </label>
                <validation-errors [show]="f.submitted"
                  [control]="justificationInput"></validation-errors>
                <files-queue name="justification"
                  required
                  #justificationInput="ngModel"
                  [(ngModel)]="justification"></files-queue>
                <small class="form-text text-muted">
                  {{ 'leave-justification-note' | translate }}
                </small>
              </div>
            </ng-container>

            <div class="form-group text-right">
              <div class="d-block invalid-feedback mb-2"
                *ngIf="f.invalid && f.submitted">
                {{ 'check-errors' | translate }}
              </div>

              <button class="btn btn-primary"
                [disableButton]="(type === LeaveType.temporal && temporalLeavesLimitReached) || disabled || sending"
                [disableButtonIcon]="sending ? 'spin' : 'ban'"
                type="submit">
                {{ 'send' | translate}}
              </button>
            </div>
          </form>
        </ng-container>
        <div class="w-100 text-center"
          *ngIf="requested">
          <h5 class="card-title m-0">
            <i class="far fa-check text-success mr-1"></i>
            {{ 'request-sended' | translate }}
          </h5>

          <div *ngIf="doc"
            class="mt-4 w-100 text-left">
            <document-link [document]="doc"></document-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>