<div class="modal fade"
  [ngClass]="{show: cds.open}"
  [@appear]="cds.open ? 'in' : 'void'"
  [style.display]="cds.open ? 'block' : 'none'"
  role="dialog">

  <div class="modal-dialog"
    role="document">

    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ cds.title }}
        </h5>
        <button type="button"
          (click)="dismiss()"
          class="close">
          <span>&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <p [innerHTML]="cds.message"></p>

        <ng-container *ngIf="cds.template">
          <ng-container *ngTemplateOutlet="cds.template"></ng-container>
        </ng-container>

      </div>

      <div class="modal-footer">
        <button class="btn btn-outline-dark mr-1"
          type="button"
          (click)="cancel()">{{cds.btnCancelText}}</button>
        <button [class]="'btn btn-' + cds.btnOkClass"
          type="button"
          (click)="accept()">{{cds.btnOkText}}</button>
      </div>

    </div>
  </div>
</div>

<div class="modal-backdrop fade"
  [ngClass]="{show: cds.open}"
  [style.display]="cds.open ? 'block' : 'none'"></div>