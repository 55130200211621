<div class="card">
  <ng-container *ngFor="let mailOption of email.mailOptions">
    <div class="card-body small">
      <div class="d-flex justify-content-between align-items-center">
        <strong class="nowrap">
          {{ mailOption.to }}
        </strong>
        <div>
          <span class="text-muted mr-2">
            {{ email.sendAt | moment:'D/M/YY HH:mm' }}
          </span>
          <span class="text-secondary p-2 c-pointer"
            *ngIf="expanded"
            (click)="expanded = false">
            <i class="fas fa-chevron-up"></i>
          </span>
          <span class="text-secondary p-2 c-pointer"
            *ngIf="!expanded"
            (click)="expanded = true">
            <i class="fas fa-chevron-down"></i>
          </span>
        </div>
      </div>
      <div class="nowrap mb-1 one-line">
        {{ mailOption.subject }}
      </div>
      <div class="text-muted nowrap one-line"
        *ngIf="!expanded"
        (click)="expanded = true">
        {{ plain(mailOption.html) }}
      </div>
      <div [innerHTML]="mailOption.html"
        *ngIf="expanded"></div>

      <div class="mt-3 w-100 text-right"
        *ngIf="expanded && !printMode">
        <a type="button"
          class="btn btn-sm btn-outline-dark mr-2"
          target="_blank"
          [href]="printUrl(email._id)">
          <i class="far fa-print mr-1"></i>
          Imprimir
        </a>
        <button type="button"
          class="btn btn-sm btn-outline-primary"
          (click)="onForward.emit(email.mailOptions[0])">
          <i class="far fa-play mr-1"></i>
          Reenviar
        </button>
      </div>
    </div>
  </ng-container>
</div>