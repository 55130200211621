<ng-container *ngIf="process">
  <task-header [task]="task"></task-header>

  <h5>
    T-{{process.createdAt | moment:'YY'}}-{{process.pgcCounter}}
  </h5>
  <br>

  <div class="small text-muted mb-1">
    {{ 'program' | translate }}
  </div>
  <text-info icon="book-alt"
    mb="4">
    {{ process.student.program.name }}
  </text-info>

  <div class="small text-muted mb-1">
    {{ 'faculty' | translate }}
  </div>
  <text-info icon="school"
    mb="4">
    {{ process.student.faculty.name }}
  </text-info>

  <thesis-data-view [data]="process.data"
    [student]="process.student"></thesis-data-view>
</ng-container>