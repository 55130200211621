import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';

import { IDocumentRef } from '../../../../../../interfaces/IDocument';
import { ILeaves } from '../../../../../../interfaces/IStudent';
import { RenewalHttpService } from '../../../../data-services/renewal-http.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { DocumentService } from '../../../../shared/document-service/document.service';
import { CanComponentDeactivate } from '../../../../UI/can-deactivate-guard/can-deactivate-guard.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import { AbstractTaskComponent } from '../../../components/process/abstract-task.component';
import { StudentRootService } from '../student-root/student-root.service';

@Component({
  selector: 'renewal-request',
  templateUrl: './renewal-request.component.html'
})
export class RenewalRequestComponent extends AbstractTaskComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('f') f: NgForm;

  requested = false;
  justification: IDocumentRef[] = [];
  newEstimatedEnd: Date;
  leaveId: string;
  leave: ILeaves;

  doc: IDocumentRef;

  constructor(public sr: StudentRootService,
    private renewal: RenewalHttpService,
    private ds: DocumentService,
    private route: ActivatedRoute,
    private ts: TranslateService,
    private cds: ConfirmationDialogService,
    private refresh: RefreshService) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.leaveId = params.leaveId;
      this.leave = this.sr.student.leaves.find(l => l._id === this.leaveId);
    });
  }

  manageSend() {
    if (!this.f.valid || !this.validDateRange()) {
      this.sendEnd.next();
      return;
    }

    this.renewal.request(this.sr.student._id, {
      justification: this.justification,
      newEstimatedEnd: this.newEstimatedEnd,
      leaveId: this.leaveId
    }).subscribe((doc: IDocumentRef) => {
      this.sendEnd.next();
      this.refresh.onStudentChange.next();
      this.requested = true;
      this.doc = doc;
      setTimeout(() => {
        if (this.doc) {
          this.ds.download(this.doc.documentRef);
        }
      }, 1000);
    });
  }

  validDateRange(): boolean {
    if (!this.leave) return true;
    const start = this.leave.start;
    const newEstimatedEnd = this.newEstimatedEnd;
    if (!newEstimatedEnd) return false;

    return moment(start).isSameOrBefore(newEstimatedEnd, 'day');
  }

  @HostListener('window:beforeunload')
  onBeforeUnload() {
    if (this.requested) return true;
    return confirm(this.ts.instant('request-unload-confirmation'));
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.requested) return true;
    let p = new Promise<boolean>((resolve, reject) => {
      this.cds.confirm({
        title: this.ts.instant('abandon-request'),
        message: this.ts.instant('request-unload-confirmation'),
      }, () => {
        resolve(true);
      }, () => {
        resolve(false)
      })
    });

    return p;
  }

}
