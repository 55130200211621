<span *ngIf="tipo === 'FILE'">
  <i class="fad fa-file fa-inverse fa-fw"
    style="color: #d1cf69; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'PDF'">
  <i class="fad fa-file-pdf fa-inverse fa-fw"
    style="color: #ff0000; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'WORD'">
  <i class="fad fa-file-word fa-inverse fa-fw"
    style="color: #0078d7; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'EXCEL'">
  <i class="fad fa-file-excel fa-inverse fa-fw"
    style="color: #1D6F42; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'POWERPOINT'">
  <i class="fad fa-file-powerpoint fa-inverse fa-fw"
    style="color: #D04423; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'ZIP'">
  <i class="fad fa-file-archive fa-inverse fa-fw"
    style="color: #490a80; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'TEXT'">
  <i class="fad fa-file-alt fa-inverse fa-fw"
    style="color: #d1cf69; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'IMAGE'">
  <i class="fad fa-file-image fa-inverse fa-fw"
    style="color: #29cf95; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'VIDEO'">
  <i class="fad fa-file-video fa-inverse fa-fw"
    style="color: #cf8f29; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'AUDIO'">
  <i class="fad fa-file-audio fa-inverse fa-fw"
    style="color: #e041c6; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>
<span *ngIf="tipo === 'CODE'">
  <i class="fad fa-file-code fa-inverse fa-fw"
    style="color: #abe041; --fa-secondary-opacity: 0.5;"
    [class.fa-3x]="big"></i>
</span>