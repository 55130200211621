import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[empty]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: EmptyValidatorDirective,
    multi: true
  }]
})
export class EmptyValidatorDirective implements Validator {
  @Input('empty') condition: boolean = false;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors {
    if (this.condition === false) return null;

    if (control.value != null && typeof control.value === 'string' && control.value.length !== 0) {
      return {
        empty: true
      };
    }

    return null;
  }

}
