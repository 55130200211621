import { IDocumentRef } from './IDocument';
import { IProcess } from './IProcess';
import { IStudent } from './IStudent';

export interface ILeaveProcess extends IProcess {
  state: LeavePState,
  studentId: string,

  type: LeaveType,
  justification: IDocumentRef[],
  start: Date,
  end?: Date,
  estimatedEnd?: Date,
  wantToEvaluate: boolean,
  mustEvaluate: boolean,

  // Populate
  student?: IStudent;
}

export enum LeavePState {
  PendingAcademicCommitteeValidation = "PendingAcademicCommitteeValidation",
  PendingServiceValidation = "PendingServiceValidation"
}


export enum TKLeave {
  academicCommitteeValidation = "academicCommitteeValidation",
  serviceValidation = "serviceValidation"
}


export enum LeaveType {
  temporal = "TEMPORAL",
  medical = "MEDICAL",
  permanent = "PERMANENT",
  final = "FINAL"
}