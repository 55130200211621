import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IScholarship, IStudent } from '../../../../../../interfaces/IStudent';
import { ScholarshipHttpService } from '../../../../data-services/scholarship-http.service';
import { NgForm } from '@angular/forms';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { RefreshService } from '../../../../data-services/refresh.service';
import { ServiceHttpService } from '../../../../data-services/service-http.service';
import { UserService } from '../../../../user/user.service';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';
import * as _ from 'lodash';

@Component({
  selector: 'scholarship-card',
  templateUrl: './scholarship-card.component.html',
})
export class ScholarshipCardComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  @Input() student: IStudent;

  scholarship: IScholarship = {
    type: 'FPU',
    startDate: null,
    endDate: null,
    documents: [],
  }

  open = false;

  constructor(private ss: ScholarshipHttpService,
    private refresh: RefreshService,
    private alerts: PopAlertsService,
    public us: UserService,
    public sh: ServiceHttpService,
    private cds: ConfirmationDialogService
  ) { }

  ngOnInit() {
  }

  doOpen(scholarshipId: string) {
    this.scholarship = null;
    if (this.student.scholarships) {
      this.scholarship = this.student.scholarships.find(s => s._id === scholarshipId);
      if (this.scholarship) this.scholarship = _.cloneDeep(this.scholarship);
    }
    if (!this.scholarship) {
      this.scholarship = {
        type: 'FPU',
        startDate: null,
        endDate: null,
        documents: [],
      }
    }
    this.open = true;
  }

  save() {
    if (this.f.invalid) return;

    if (this.scholarship._id) {
      this.ss.editScholarship(this.student._id, this.scholarship._id, this.scholarship).subscribe(() => {
        this.refresh.onStudentChange.next();
        this.alerts.saveSuccess();
        this.open = false;
      })
    } else {
      this.ss.addScholarship(this.student._id, this.scholarship).subscribe(() => {
        this.refresh.onStudentChange.next();
        this.alerts.saveSuccess();
        this.open = false;
      })
    }
  }

  remove(scholarshipId: string) {
    this.cds.confirm({
      title: 'Eliminar beca o ayuda',
      message: '¿Estás seguro de que quieres eliminar esta beca o ayuda?',
    }, () => {
      this.ss.removeScholarship(this.student._id, scholarshipId).subscribe(() => {
        this.refresh.onStudentChange.next();
        this.alerts.pop({
          styleClass: 'info',
          msg: 'Beca o ayuda eliminada',
        });
        this.open = false;
      })
    })
  }

}
