<div class="container-fluid">

  <div class="row">
    <div class="col">

      <section-title text="processes"
        [additionalText]="hs.processName(processType)"
        icon="project-diagram">
      </section-title>

    </div>
  </div>

  <div class="row">
    <div class="col">

      <processes-search [dataFn]="dataFn()"
        [(processType)]="processType"></processes-search>

    </div>
  </div>

</div>