import { Injectable } from '@angular/core';
import { IProgram } from '../../../../../../interfaces/IProgram';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgramFileService {

  program: IProgram;
  programObs = new Subject<IProgram>();

  constructor(private ps: ProgramHttpService) { }

  fetch(programId: string) {
    this.ps.programInfo(programId).subscribe((program) => {
      this.program = program;
      this.programObs.next(this.program);
    })
  }

  reset() {
    this.program = null;
  }

}