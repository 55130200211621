import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IProgram } from '../../../../../../interfaces/IProgram';
import { ProfessorHttpService } from '../../../../data-services/professor-http.service';
import { IProfessor } from '../../../../../../interfaces/IProfessor';
import { HelperService } from '../../../../shared/helper-service/helper.service';
import { UserService } from '../../../../user/user.service';
import * as _ from 'lodash';
import { DADUtils } from '../../../../../../utils/DADUtils';
import { ProgramHttpService } from '../../../../data-services/program-http.service';
import { ILineOfResearch } from '../../../../../../interfaces/ILineOfResearch';
import { PopAlertsService } from '../../../../UI/pop-alerts/pop-alerts.service';
import { InfiniteListComponent } from '../../../../UI/infinite-list/infinite-list.component';
import { ConfirmationDialogService } from '../../../../UI/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'program-professors',
  templateUrl: './program-professors.component.html',
})
export class ProgramProfessorsComponent implements OnInit {
  @ViewChild('ifList') ifList: InfiniteListComponent;

  @Input() program: IProgram;

  professors: IProfessor[] = [];
  linesOfResearch: ILineOfResearch[] = [];

  professor: IProfessor;

  open = false;

  years = [];

  searchText = '';
  lineOfResearchId = null;
  confirmed = null;

  constructor(private prfs: ProfessorHttpService,
    private ps: ProgramHttpService,
    public hs: HelperService,
    public us: UserService,
    private cds: ConfirmationDialogService,
    private alerts: PopAlertsService) { }

  ngOnInit() {
    const currentYear = DADUtils.getAcademicYear(new Date());
    let numericYears = _.range(currentYear, currentYear - 20, -1);
    numericYears.push(currentYear + 1);
    numericYears.push(currentYear + 2);
    numericYears.push(currentYear + 3);
    numericYears = _.orderBy(numericYears, [], ['desc']);
    this.years = numericYears.map((year) => {
      return year + '' + (year + 1);
    });

    this.ps.linesOfResearch(this.program._id).subscribe((lines) => {
      this.linesOfResearch = lines;
    });
  }

  fetchFn() {
    return (params) => {
      // combine params
      params = {
        ...params,
        searchText: this.searchText,
        lineOfResearchId: this.lineOfResearchId,
        confirmed: this.confirmed
      }
      return this.prfs.fetchProfessors(this.program._id, params);
    }
  }

  doOpen(professor: IProfessor) {
    if (professor) {
      this.professor = _.cloneDeep(professor);
    } else {
      this.professor = {
        programId: this.program._id,
        _id: null,
        name: '',
        idNum: '',
        lineOfResearchId: null,
        confirmed: false,
        active: false,
        external: false,
        startYear: DADUtils.getFullAcademicYear(new Date()),
        university: '',
      }
    }
    this.open = true;
  }

  doRemove(professor: IProfessor) {
    this.cds.confirm({
      title: 'Eliminar Profesor',
      message: '¿Está seguro que desea eliminar este profesor?',
    }, () => {
      this.prfs.deleteProfessor(professor._id).subscribe(() => {
        this.alerts.pop({
          styleClass: 'info',
          msg: 'Profesor eliminado'
        });
        this.professors = this.professors.filter((p) => p._id !== professor._id);
      });
    })
  }

  onUserChange(user) {
    if (user) {
      this.professor.userId = user._id;
    } else {
      this.professor.userId = null;
    }
  }

  save() {
    if (this.professor._id) {
      this.prfs.updateProfessor(this.program._id, this.professor._id, this.professor).subscribe(() => {
        this.alerts.saveSuccess();
        this.open = false;
        this.ifList.search();
      });
    } else {
      this.prfs.addProfessor(this.program._id, this.professor).subscribe(() => {
        this.alerts.saveSuccess();
        this.open = false;
        this.ifList.search();
      });
    }
  }

  toggleConfirmed(professor: IProfessor) {
    if (professor.confirmed) {
      this.prfs.markAsNotConfirmed(professor._id).subscribe(() => {
        professor.confirmed = false;
        if (this.confirmed === true) {
          this.professors = this.professors.filter((p) => p._id !== professor._id);
        }
      });
    } else {
      this.prfs.markAsConfirmed(professor._id).subscribe(() => {
        professor.confirmed = true;
        if (this.confirmed === false) {
          this.professors = this.professors.filter((p) => p._id !== professor._id);
        }

      });
    }
  }

  export() {
    location.href = `${window.location.origin}/api/professors/professorsReport/${this.program._id}?format=excel`;
  }

}
