import { Component, OnInit } from '@angular/core';

import { StudentRootService } from '../../routes/student/student-root/student-root.service';

@Component({
  selector: 'student-menu',
  templateUrl: 'student-menu.component.html'
})

export class StudentMenuComponent implements OnInit {
  constructor(public sr: StudentRootService) { }

  ngOnInit() { }
}