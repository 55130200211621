import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IScholarship } from '../../../interfaces/IStudent';
import { Observable } from 'rxjs';

@Injectable()
export class ScholarshipHttpService {
  constructor(private http: HttpClient) { }

  addScholarship(studentId: string, scholarship: IScholarship): Observable<string> {
    return this.http.post<string>(`/api/scholarship/${studentId}/add`, scholarship);
  }

  editScholarship(studentId: string, scholarshipId: string, scholarship: IScholarship): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/scholarship/${studentId}/edit`, { scholarshipId, ...scholarship });
  }

  removeScholarship(studentId: string, scholarshipId: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`/api/scholarship/${studentId}/remove`, { scholarshipId });
  }

}