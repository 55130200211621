import { IActivity } from './IActivity';
import { IDocumentRef } from './IDocument';
import { IEvaluationCommittee } from './IEvaluationCommittee';
import { IProcess } from './IProcess';
import { IProgram } from './IProgram';
import { EvaluationPeriodType, EvaluationType, IStudent } from './IStudent';
import { IUser } from './IUser';

export interface IEvaluationProcess extends IProcess {
  state: EvaluationPState,
  studentId: string,
  programId: string,
  year: string,
  period: EvaluationPeriodType,
  researchPlan: IDocumentRef,
  activitiesIds: string[],
  evaluationCommitteeId?: string,
  presidentId?: string,
  secretaryId?: string,
  memberId?: string,
  evaluation?: EvaluationType,
  committeeReport?: IDocumentRef,
  reports: IDocumentRef[],

  // populate
  student?: IStudent,
  program?: IProgram,
  activities?: IActivity[],
  president?: IUser,
  secretary?: IUser,
  member?: IUser,
  evaluationCommittee?: IEvaluationCommittee
}

export enum EvaluationPState {
  PendingFirstReport = "PendingFirstReport",
  PendingReports = "PendingReports",
  PendingEvaluation = "PendingEvaluation",
  PendingSecretaryConfirmation = "PendingSecretaryConfirmation",
}

export enum TKEvaluation {
  evaluationCommitteeConfirmation = "evaluationCommitteeConfirmation",
  directionTeamReport = "directionTeamReport",
  evaluationCommitteeEvaluation = "evaluationCommitteeEvaluation",
  confirmationBySecretary = "confirmationBySecretary"
}