<div class="container">

  <div class="row">
    <div class="col">
      <section-title text="faculties"
        icon="building">
      </section-title>
    </div>
  </div>

  <div class="card">
    <div class="list-group">

      <div class="list-group-item list-group-item-action c-pointer"
        *ngFor="let faculty of faculties"
        [routerLink]="[faculty._id]">
        <span>
          {{ faculty.name }}
        </span>

      </div>

    </div>
  </div>




</div>