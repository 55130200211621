import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { zip } from 'rxjs';

import { EvaluationReason, IEvaluationControl } from '../../../../../interfaces/IEvaluationControl';
import { IEvaluationProcess } from '../../../../../interfaces/IEvaluationProcess';
import { EvaluationPeriodType, EvaluationType, IEvaluation, IStudent } from '../../../../../interfaces/IStudent';
import { ITask } from '../../../../../interfaces/ITask';
import { DADUtils } from '../../../../../utils/DADUtils';
import { EvaluationControlHttpService } from '../../../data-services/evaluation-control-http.service';
import { ProgramHttpService } from '../../../data-services/program-http.service';
import { ConfirmationDialogService } from '../../../UI/confirmation-dialog/confirmation-dialog.service';
import { LoadingIndicatorService } from '../../../UI/loading-indicator/loading-indicator.service';
import { PopAlertsService } from '../../../UI/pop-alerts/pop-alerts.service';
import { UserService } from '../../../user/user.service';
import { HelperService } from '../../../shared/helper-service/helper.service';

@Component({
  selector: 'evaluations-view',
  templateUrl: 'evaluations-view.component.html'
})
export class EvaluationsViewComponent implements OnInit {
  @Input() programsIds: string[] = [];
  @Input() edit = true;
  @Input() showProgramName = true;

  programId: string;

  selected: {
    year: string,
    period: EvaluationPeriodType,
    date: Date
  }

  prev: {
    year: string,
    period: EvaluationPeriodType,
    date: Date
  }

  next: {
    year: string,
    period: EvaluationPeriodType,
    date: Date
  }

  control: IEvaluationControl;
  processes: IEvaluationProcess[];
  evaluated: IStudent[];
  confirmTasks: ITask[];
  evaluatedMap: { [studentId: string]: IEvaluation } = {};
  projected: { included: { studentId: string, student: IStudent, reason: EvaluationReason }[], excluded: { studentId: string, student: IStudent, reason: EvaluationReason }[] } = {
    included: [],
    excluded: []
  };

  loadingProjected = false;
  projectedLoaded = false;

  localLoading = false;
  loaded = false;

  EvaluationType = EvaluationType;
  EvaluationReason = EvaluationReason;
  Math = Math;

  open = false;

  showExcluded = false;

  constructor(public ps: ProgramHttpService,
    private ecs: EvaluationControlHttpService,
    public loading: LoadingIndicatorService,
    private route: ActivatedRoute,
    private router: Router,
    private cds: ConfirmationDialogService,
    private alerts: PopAlertsService,
    private ts: TranslateService,
    private us: UserService,
    public hs: HelperService) { }

  ngOnInit() {
    if (this.programsIds.length > 0) {
      this.programId = this.programsIds[0];
    }
    if (!this.programId) return;

    this.route.queryParams.subscribe((params) => {
      this.loaded = false;
      this.control = null;
      const { y, p } = params;
      if (!y || !p) {
        this.selected = DADUtils.lastEvaluation(new Date());
      } else {
        this.selected = {
          year: y,
          period: p,
          date: moment().year(DADUtils.getEvaluationYear(y)).month(p === EvaluationPeriodType.march ? 2 : 8).date(1).startOf('day').toDate()
        }
      }

      this.calcPrevAndNext();
      this.fetch();
    })
  }

  fetch() {
    this.loading.start();
    this.localLoading = true;
    zip(this.ecs.find(this.programId, this.selected.year, this.selected.period),
      this.ecs.processes(this.programId, this.selected.year, this.selected.period),
      this.ecs.evaluatedStudets(this.programId, this.selected.year, this.selected.period),
      this.ecs.findConfirmTasks(this.programId, this.selected.year, this.selected.period)).subscribe((res) => {
        this.control = res[0];
        this.processes = res[1];
        this.evaluated = res[2];
        this.evaluatedMap = {};
        if (this.control) {
          const studentsIds = this.processes.map(p => p.studentId);
          this.evaluated.forEach(s => studentsIds.push(s._id));
          this.control.included = this.control.included.filter(i => !studentsIds.includes(i.studentId));
          this.control.included = _.sortBy(this.control.included, ['student.lastName', 'student.firstName'])
          this.control.excluded = _.sortBy(this.control.excluded, ['student.lastName', 'student.firstName'])
        }

        this.evaluated.forEach(st => {
          this.evaluatedMap[st._id] = this.getSelectedEvaluation(st);
          (<any>st).evaluation = this.evaluatedMap[st._id].evaluation;
        });
        this.evaluated = _.sortBy(this.evaluated, ['evaluation', 'lastName', 'firstName']);
        this.confirmTasks = res[3];

        this.loading.stop();
        this.localLoading = false;
        this.loaded = true;
      })
  }

  getProjected() {
    this.loadingProjected = true;
    this.ecs.projected(this.programId).subscribe((res) => {
      console.log(res);
      this.projected = res;
      this.projected.included = _.sortBy(this.projected.included, ['reason', 'student.lastName', 'student.firstName'])
      this.loadingProjected = false;
      this.projectedLoaded = true;
    });
  }

  showNext() {
    const lastEvaluation = DADUtils.lastEvaluation(new Date());
    return moment(this.next.date).subtract(6, 'months').isSameOrBefore(lastEvaluation.date);
  }

  calcPrevAndNext() {
    const prevDate = moment(this.selected.date).subtract(6, 'months').toDate();
    this.prev = {
      year: DADUtils.getFullEvaluationYear(prevDate),
      period: DADUtils.getEvaluationPeriod(prevDate),
      date: prevDate
    }

    const nextDate = moment(this.selected.date).add(6, 'months').toDate();
    this.next = {
      year: DADUtils.getFullEvaluationYear(nextDate),
      period: DADUtils.getEvaluationPeriod(nextDate),
      date: nextDate
    }

    if (!this.showNext()) {
      this.getProjected();
    }
  }

  selectNext() {
    void this.router.navigate(['./'], { queryParams: { y: this.next.year, p: this.next.period }, relativeTo: this.route })
  }

  selectPrev() {
    void this.router.navigate(['./'], { queryParams: { y: this.prev.year, p: this.prev.period }, relativeTo: this.route })
  }

  private getSelectedEvaluation(student: IStudent) {
    return student.evaluations.find(e => e.year === this.selected.year && e.period === this.selected.period)
  }

  removeFromEvaluation(student: IStudent) {
    this.cds.confirm({
      title: this.ts.instant('remove-from-evaluation-control'),
      message: this.ts.instant('remove-from-evaluation-control-info', { student: DADUtils.fullname(student) }),
    }, () => {
      this.ecs.removeStudent(this.programId, this.control._id, student._id).subscribe(() => {
        this.control.included = this.control.included.filter(i => i.studentId !== student._id);
        this.control.excluded.push({
          reason: EvaluationReason.forceExclude,
          studentId: student._id,
          student
        });
        this.control.excluded = _.sortBy(this.control.excluded, ['student.lastName', 'student.firstName'])

        this.alerts.pop({
          styleClass: 'info',
          msg: this.ts.instant('removed-from-evaluation', { student: `${student.firstName} ${student.lastName}` }),
          dismissTime: 20000,
          action: {
            id: student._id,
            label: this.ts.instant('undo'),
            fn: () => {
              this.addToEvaluation(student)
            }
          }
        })
      })
    })

  }

  addToEvaluation(student: IStudent) {
    this.ecs.addStudent(this.programId, this.control._id, student._id).subscribe(() => {
      this.control.excluded = this.control.excluded.filter(i => i.studentId !== student._id);
      this.control.included.push({
        reason: EvaluationReason.forceInclude,
        studentId: student._id,
        student
      });
      this.control.included = _.sortBy(this.control.included, ['student.lastName', 'student.firstName'])
      this.alerts.pop({
        styleClass: 'info',
        msg: this.ts.instant('added-to-evaluation', { student: DADUtils.fullname(student) }),
        dismissTime: 20000,
      })
    })
  }

  goToStudent(student: IStudent) {
    if (this.us.isService()) {
      void this.router.navigate(['/app/service/students/' + student._id]);
    } else {
      void this.router.navigate(['/app/coordinator/students/' + student._id]);
    }
  }


}