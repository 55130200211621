<ng-container *ngIf="activity">
  <task-header [task]="task"></task-header>

  <div class="border border-dark rounded  mt-2">
    <div class="p-2">
      <activity-view [activity]="activity"></activity-view>
    </div>
  </div>

  <div *ngIf="task.validationProcess.validationMsg"
    class="mt-3">
    <div>{{ 'previously-denied-validation-for' | translate }}:</div>

    <div class="pre alert alert-light"
      [innerHTML]="task.validationProcess.validationMsg"></div>
  </div>

  <form #f="ngForm"
    class="mt-4"
    (ngSubmit)="send()">

    <div class="form-group text-center">
      <div class="btn-group btn-block">
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-danger]="validate === null || validate === true"
          [class.btn-danger]="validate === false"
          (click)="validate = false">
          {{ 'deny' | translate }}
        </button>
        <button type="button"
          class="btn btn-sm"
          [class.btn-outline-success]="validate === null || validate === false"
          [class.btn-success]="validate === true"
          (click)="validate = true">
          {{ 'validate' | translate }}
        </button>
      </div>
    </div>

    <div class="form-group"
      *ngIf="validate === false">
      <label for="validationMsg">
        {{ 'denial-reason' | translate }}
      </label>
      <textarea id="validationMsg"
        name="validationMsg"
        #validationMsgInput="ngModel"
        [(ngModel)]="validationMsg"
        [required]="true"
        [placeholder]="'denial-reason-placeholder' | translate"
        [class.is-invalid]="f.submitted && validationMsgInput.invalid"
        class="form-control"></textarea>
      <div class="text-right small text-muted">
        {{ validationMsg ? validationMsg.length : 0}}/400
      </div>
      <validation-errors [show]="f.submitted"
        [control]="validationMsgInput"></validation-errors>
    </div>

    <div class="form-group text-right"
      *ngIf="validate !== null">
      <button class="btn btn-primary"
        type="submit"
        [disableButton]="disabled || sending"
        [disableButtonIcon]="sending ? 'spin' : 'ban'">
        {{ 'send' | translate }}
      </button>
    </div>

  </form>
</ng-container>